<!--
    file type .....: HTML file: addpurchaseorder.component.html
    Created By ....: ianday
    Date ..........: 5/16/23 
-->
<script src="../purchaseorders.component.ts"></script>
<div class="addpurchaseorder-container">

    <div class="dwform top-half">
        <div class="dwform-row">
            <label class="label-field" >PO Number:</label>
            <span class="form-required"> </span>
            <label class="label-field label-field-bold">{{purchaseOrder?.poNumber}}</label>

            <div class="dwform-row-gap"></div>

            <label class="label-field" >PO Date:</label>
            <span class="form-required"> </span>
            <label class="label-field label-field-bold">{{purchaseOrder?.dateCreated}}</label>

            <div class="dwform-row-gap"></div>

            <label class="label-field" >Supplier:</label>
            <span class="form-required"> </span>
            <label class="label-field label-field-bold"
                   style="text-transform: capitalize; width:250px;">{{purchaseOrder?.supplierName}}</label>

            <div class="dwform-row-gap"></div>

            <!-- CONTACT START --->
            <label class="label-field" >Contact:</label>
            <span class="form-required">*</span>
            <mat-select id="contactId" #cbContact
                        [ngModel]="selectedContact" ngDefaultControl
                        disableOptionCentering
                        style="text-transform: capitalize; width:180px;"
                        panelClass="local-drop-down-panel" class="dw-mat-select"
                        (keydown)="onContactDropDownKeyDown($event)"
                        (selectionChange)="onContactSelected($event)"
                        placeholder="<Contacts>" type="text">
                <mat-option *ngFor="let contact of contactsList"
                            [value]="contact">{{contact.fullName}}
                </mat-option>
            </mat-select>
            <button mat-button style="width: 20px!important; scale: 80%;"
                    (click)="quickAddContact()"
                    matTooltip="Add a contact"><mat-icon>add_circle_outline</mat-icon></button>

            <div class="dwform-row-gap"></div>
            <label class="label-field" >Email:</label>
            <span class="form-required"> </span>
            <label class="label-field label-field-bold">{{selectedContact?.emailAddress}}</label>

            <div class="dwform-row-gap"></div>

            <label class="label-field" >Telephone:</label>
            <span class="form-required"> </span>
            <label class="label-field" style="width: 280px;font-size: 9pt">
                <b>{{selectedContact?.telephone}}</b> /
                Cell: <b>{{selectedContact?.cellPhone}}</b>
            </label>
            <!----------------------------------->
            <!-- Second Column in the top half -->
            <!----------------------------------->

            <div class="dwform-cell">

                <label class="label-field" >Status:</label>
                <span class="form-required"> </span>
                <label class="label-field label-field-bold">{{purchaseOrder?.orderStatus}}</label>

                <div style="border-bottom: 12px solid transparent"></div>
                <!-- OVER TO NEXT CELL -->
                <label class="label-field" >Reference:</label>
                <span class="form-required"> </span>
                <input class="input-field-short" id="referenceId" matInput
                       [(ngModel)]="purchaseOrder.reference"
                       ngDefaultControl
                       style="text-transform: capitalize; width:345px;"
                       placeholder="<Reference>" type="text">

                <div class="dwform-row-gap"></div>

                <label class="label-field" style="vertical-align:top; " >Comments:</label>

                <span class="form-required"> </span>
                <TextArea class="input-field-short" id="commentsId"
                          [(ngModel)]="purchaseOrder.poComments"
                          ngDefaultControl
                          style="font-family: Roboto; height: 65px;
                             width: 345px;resize: none"
                          placeholder="<Comments>" >
                </TextArea>

                <div class="dwform-row-gap"></div>

                <div *ngIf="dispDeaForm222 === true">
                    <label class="label-field" style="vertical-align:middle; " >Dea Form 222:</label>
                    <span class="form-required">*</span>
                    <input class="input-field-short" id="deaForm222" matInput
                           [(ngModel)]="purchaseOrder.deaForm222"
                           ngDefaultControl
                           style="text-transform: capitalize; width:345px;"
                           placeholder="<Dea Form 222>" type="text">
                </div>
        </div>



        </div>
    </div>

    <div class="mat-elevation-z2 control-strip" >
        <button mat-button [disabled]="disableItems"
                matTooltipPosition="above" style="margin-top: 5px;"
                class="command-button" matTooltip="Add items "
                (click)="addUpdateItems(true)" mat-button>
            <mat-icon>add_circle_outline</mat-icon>
            Add
        </button>
        <button mat-button [disabled]="disableItems"
                matTooltipPosition="above" style="margin-top: 5px;"
                class="command-button" matTooltip="Update items"
                (click)="addUpdateItems(false)"
                mat-button>
            <mat-icon>edit</mat-icon>
            Update
        </button>
        <button mat-button [disabled]="disableItems"
                matTooltipPosition="above" style="margin-top: 5px;"
                class="command-button" matTooltip="Remove items"
                (click)="removeSelectedItemRequest()"
                mat-button>
            <mat-icon>remove_circle_outline</mat-icon>
            Remove
        </button>
    </div>
    <div class="purchase-order-items-container"
            (keydown)="tableKeyDown($event)" tabindex="-1">
            <table mat-table [dataSource]="dataSource" tabindex="-1"
                   matSort style="font-size: 9pt;width:100%"
                   (matSortChange)="onSortChange($event)">

                <!-- NDC / part number -->
                <ng-container matColumnDef="ndcNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style" style="width:80px;">Part/NDC
                    </th>
                    <td mat-cell style="width:80px!important;font-size: 9pt;"
                        *matCellDef="let element">{{element.ndcNumber  }}</td>
                </ng-container>

                <!-- quantity -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style" style="width:80px;">Qty
                    </th>
                    <td mat-cell style="width:70px!important;font-size: 9pt;"
                        *matCellDef="let element">{{element.quantity}}</td>
                </ng-container>

                <!-- description -->
                <ng-container matColumnDef="itemDescription">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style" style="width:180px;">Item
                    </th>
                    <td mat-cell style="width:180px!important;font-size: 9pt;"
                        *matCellDef="let element">{{element.itemDescription}}</td>
                </ng-container>

                <!-- package size -->
                <ng-container matColumnDef="packageSize" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style mat-table-header-override" >Pkg Size
                    </th>
                    <td mat-cell
                        [hidden]="false"
                        style="font-size: 9pt; vertical-align: middle;
                    text-align: left; width:100px!important;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.packageSize + ' '+ element.uom}}</td>
                </ng-container>

                <!-- unit price -->
                <ng-container matColumnDef="unitCost">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style" style="width:80px;">Unit
                    </th>
                    <td mat-cell style="width:80px!important;font-size: 9pt;"
                        *matCellDef="let element">{{element.unitCost | currency}}</td>
                </ng-container>

                <!-- extended -->
                <ng-container matColumnDef="extendedCost">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style" style="width:80px;">Extended
                    </th>
                    <td mat-cell style="width:80px!important;font-size: 9pt;"
                        *matCellDef="let element">{{element.extendedCost | currency}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row syle="z-index: 1"
                    *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                    [ngClass]="{'row-selected': tableRowIdx == Idx}"
                    (click)="onRowClicked(row)">
                </tr>
            </table>
    </div>

    <div class="dwform purchase-order-total-strip">
        <div class="dwform-row-gap-half">
            <label class="label-field" >Total: </label>
            <span class="form-required"></span>
            <p-inputNumber  [readonly]="true"
                   [inputStyle]="{'width':'130px'}"
                   mode="currency" currency="USD"
                   [(ngModel)]="purchaseOrder.orderTotal"
                   placeholder="0" >
            </p-inputNumber>

            <div class="dwform-row-gap-half"></div>

            <label class="label-field" >Tax: </label>
            <span class="form-required"></span>
            <p-inputNumber  [readonly]="false"
                            [inputStyle]="{'width':'130px'}"
                            mode="currency" currency="USD"
                            (onBlur)="updateTotals()"
                            [(ngModel)]="purchaseOrder.salesTax"
                            placeholder="0" >
            </p-inputNumber>

            <div class="dwform-row-gap-half"></div>

            <label class="label-field" >Shipping: </label>
            <span class="form-required"> </span>
            <p-inputNumber  [readonly]="false"
                            [inputStyle]="{'width':'130px'}"
                            mode="currency" currency="USD"
                            (onBlur)="updateTotals()"
                            [(ngModel)]="purchaseOrder.shippingTotal"
                            placeholder="0" >
            </p-inputNumber>
            <div class="dwform-row-gap-half"></div>

            <label class="label-field" >Grand Total:</label>
            <span class="form-required"> </span>
            <p-inputNumber  [readonly]="true"
                            [inputStyle]="{'width':'130px'}"
                            mode="currency" currency="USD"
                            [(ngModel)]="purchaseOrder.grandTotal"
                            placeholder="0" >
            </p-inputNumber>
        </div>

    </div>
    <div class="error-line" style="margin-left: 13px;" *ngIf="strErr.length > 0">
        {{strErr}}
    </div>
    <div class="save-close-strip">
        <button mat-button class="command-button" [disabled]="!(records.length >0)"
                (click)="onSubmitClicked()">
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()">
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
<p-dialog header="Please choose either"
          [appendTo]="'body'"
          [(visible)]="displaySelectDlg"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '900px', height: '545px'}"
          [resizable]="false" [closable]="true">
    <app-selectcreatepo #appselectcreatepo
        (submitSelectCreatePOEvent)="submitFromSelectCreatePO($event)"
        (cancelSelectCreatePOEvent)="cancelFromSelectCreatePO()"
    ></app-selectcreatepo>
</p-dialog>
<p-dialog header="New Contact"
          [(visible)]="displayAddContactDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '605px', height: '352px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
    <app-add-contact #addContact
                     [parentId]="purchaseOrder?.supplierId"
                     [callingComponent]="'suppliers'"
                     (cancelAddContact)="displayAddContactDlg=false"
                     (submitAddContact)="newContactCreated($event)">
    </app-add-contact>
</p-dialog>
<p-dialog header="Products for: {{purchaseOrder?.supplierName}}"

          [(visible)]="displayMedItemSelectionDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '625px', height: '440px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
   <app-addmedpoitem #addMedPOItem
        [supplierId]="purchaseOrder.supplierId"
        (submitAddPOItemEvent)="doAddUpdatePOItem($event)"
        (cancelAddPOItemEvent)="displayMedItemSelectionDlg=false">
   </app-addmedpoitem>
</p-dialog>
<p-dialog header="Products for: {{purchaseOrder?.supplierName}}"

          [(visible)]="displayMatItemSelectionDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '625px', height: '440px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
    <app-addmatpoitem #addMatPOItem
                      [supplierId]="purchaseOrder.supplierId"
                      (submitAddPOItemEvent)="doAddUpdatePOItem($event)"
                      (cancelAddPOItemEvent)="displayMatItemSelectionDlg=false">
    </app-addmatpoitem>
</p-dialog>
<p-dialog header="Submit Purchase Order: {{purchaseOrder?.poNumber}}"

          [(visible)]="displaySubmitPOOptionsDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '425px', height: '240px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">

    <div style="display:table-row; width:100%;padding-left: 5px;padding-top:5px;">
        <label style="display:table-row; padding-bottom:10px; ">Please select an option:</label>
        <mat-radio-group class="rb-options-style"
                    ngDefaultControl
                    [(ngModel)]="valSubmitOption" >
            <mat-radio-button
                    (change)="radiobuttonChange($event)"
                    [checked]="true" [value]=1>Submit this Purchase Order via email
            </mat-radio-button>
            <mat-radio-button ngDefaultControl
                    [(ngModel)]="valSubmitOption"
                    (change)="radiobuttonChange($event)"
                    [value]=2>Submit this Purchase Order via
                telephone</mat-radio-button>
            <mat-radio-button
                    ngDefaultControl
                    [(ngModel)]="valSubmitOption"
                    (change)="radiobuttonChange($event)"
                    [value]=3>Just save this Purchase Order</mat-radio-button>
        </mat-radio-group>
        <div>
            <div class="save-close-strip">
                <button mat-button class="command-button"
                        (click)="onSubmitProcessClicked()">
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="onCancelSubmitProcessClicked()">
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</p-dialog>
