<!--
    file type .....: HTML file: add-license.component.html
    Created By ....: ianday
    Date ..........: 2/23/23 
-->
<div class="add-address-container" tabindex="0">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;"
             (keyup)="this.strErr = ''">

            <div class="dwform-row">
                <label class="label-field">Description:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="entityDescription"
                       [(ngModel)]="entityDescription"
                       style="text-transform: capitalize"
                       placeholder="<Description>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Address Section ---->
            <!----------------------->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Address:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="address1"
                       [(ngModel)]="address1"
                       style="text-transform: capitalize"
                       placeholder="<Street Address>"
                       type="text">
            </div>
            <!-- Address 2 --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field"></label>
                <span class="form-required"></span>
                <input class="input-field-longest" id="address2"
                       [(ngModel)]="address2"
                       style="text-transform: capitalize"
                       placeholder="<Suite number / floor>"
                       type="text">
            </div>

            <!-- City --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">City:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="city"
                       [(ngModel)]="city"
                       style="text-transform: capitalize"
                       placeholder="<City>"
                       type="text">
            </div>

            <!-- State --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">State:</label>
                <span class="form-required">*</span>
                       <input class="input-field" id="state" matInput
                           [formControl]="stateControl"
                           [matAutocomplete]="auto"
                           style="text-transform: capitalize;"
                           placeholder="<State>" type="text">
                        <mat-autocomplete autoActiveFirstOption
                                          class="state-auto-complete"
                                          [classList]="['state-auto-complete']"
                                          #auto="matAutocomplete" >
                            <mat-option *ngFor="let state of filteredOptions | async " [value]="state">
                                    {{state}}
                            </mat-option>
                        </mat-autocomplete>
            </div>

            <!-- zip code  --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Zip Code:</label>
                <span class="form-required">*</span>
                <input class="input-field-short" id="zipCode"
                       [(ngModel)]="zipCode"
                       placeholder="<ZipCode>"
                       type="text">
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
