<div style="background: white; overflow:hidden">
	<div mat-dialog-content style="min-width: 20rem; max-width: 80rem;"
		[innerHTML]="data?.message"	>
	</div>
	<div style="text-align: center!important; margin-top: .5rem; bottom:5px;">
	  <button mat-button class="dialog-button" *ngIf="data?.showCancel"
	          (click)="onCancelClick()" cdkFocusInitial>Cancel</button>
	  <button mat-button class="dialog-button"
	          *ngIf="data?.showOk" (click)="onOkClick()" >Ok</button>
	  <button mat-button class="dialog-button" *ngIf="data?.showNo"
	          (click)="onNoClick()"cdkFocusInitial>No</button>
	  <button mat-button class="dialog-button" *ngIf="data?.showYes"
	          (click)="onYesClick()" >Yes</button>
	  <button mat-button class="dialog-button" *ngIf="data?.showConfirm"
	          (click)="onConfirmClick()" >Confirm</button>
	  <button mat-button class="dialog-button" *ngIf="data?.showClose"
	          (click)="onCloseClick()" cdkFocusInitial>Close</button>
	</div>
</div>
