/************
 * Model Class
 * File Name ...: ingredients.components.ts
 * Generated By : ianday on 3/23/23
 * Date ........: 3/23/23
 /************/
import {ValidationResult} from './validation-result';

export interface Ingredients {
    id: number | null;
    medicationFamilyId: number;
    listSequence: number;
    ingredient: string;
    quantity: number;
    uom: string;
    abcxyiCode: string | null;


}

export class Ingredients {
    constructor() {
        this.id = 0;
        this.medicationFamilyId = 0;
        this.listSequence = 0;
        this.ingredient = '';
        this.quantity = 0;
        this.uom = '';
        this.abcxyiCode = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.ingredient.length === 0) {
            vr.focus_field = 'ingredient';                              /* this should be the id value of the
             field */
            vr.error = 'Error: an ingredient name is required';
            return vr;
        }

        return vr;
    }
}
