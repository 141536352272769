/************
 * Model Class
 * File Name ...: stations.components.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/
import {ValidationResult} from './validation-result';

export interface Stations {
    id: number | null;
    siteId: number | null
    handle: string | null;
    description: string | null;
    activeStatus: string | null;
}

export class Stations {
    constructor() {
        this.id = 0;
        this.siteId = 0;
        this.handle = '';
        this.description = '';
        this.activeStatus = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.handle.length === 0) {
            vr.focus_field = 'stationHandle';                              /* this should be the id value of the
             field */
            vr.error = 'Error: a station name is required';
            return vr;
        }

        return vr;
    }
    
    public dumpRecord(): void {
        
        console.log('ID: ', this.id);
        console.log('Site ID: ', this.siteId);
        console.log('Handle: ', this.handle);
        console.log('Description: ', this.handle);
        console.log('ActiveStatus: ', this.handle);
    }
}
