import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MedicationService } from '../../../../../../services/medication.service';
import { DwUtils } from '../../../../../../components/utils/dw-utils';
import { Medication } from 'src/app/models/medication';
import { Settings } from 'src/app/services/settings';
import { SupplierMedication } from 'src/app/models/supplier-medication';
import { Suppliers } from 'src/app/models/suppliers';

@Component({
	selector: 'app-addeditmedicationsupplier',
	templateUrl: './addeditmedicationsupplier.component.html',
	styleUrls: ['./addeditmedicationsupplier.component.css']
})

export class AddeditmedicationsupplierComponent implements OnInit {

	public record: SupplierMedication;
	public _selectedRecord: SupplierMedication;
	public _medication: Medication;
	public id = 0;
	public valSelectedSupplierId = 0;
	public valCostPerDose = 0.00;
	public valUnitPrice = 0.00;
	public valLeadTimeDays = 5;
	public strErr = '';
	public addEditFlag: boolean;
	_suppliersList: Suppliers[];
	_medicationSuppliers: SupplierMedication[];

	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();

	@Input() set selectedRecord(value: any) {
		this._selectedRecord = value;
		this.doShow();
	}

	get selectedRecord(): any {
		return this._selectedRecord;
		this.doShow();
	}

	@Input() set medication(value: any) {
		this._medication = value;
	}

	get medication(): any {
		return this._medication;
	}

	@Input() set suppliersList(value: any) {
		this._suppliersList = value;
		this.doShow();
	}

	get suppliersList(): any {
		return this._suppliersList;
	}

	@Input() set medicationSuppliers(value: any) {
		this._medicationSuppliers = value;
		this.doShow();
	}

	get medicationSuppliers(): any {
		return this._medicationSuppliers;
	}

	constructor(private medicationService: MedicationService) {

	}

	ngOnInit() {

	}
	doShow() {

		DwUtils.setFocus('desc');
		if (this._selectedRecord !== undefined && this._medication !== undefined && this._suppliersList !== undefined) {
			this.clearVals();
			this.valSelectedSupplierId = this._selectedRecord.supplierId;
			this.valCostPerDose = this._selectedRecord.costPerDose;
			this.valUnitPrice = this._selectedRecord.unitPrice;
			this.valLeadTimeDays = this._selectedRecord.leadTimeDays;
		}
	}
	clearVals(): void {
		this.valCostPerDose = 0;
		this.valUnitPrice = 0;
		this.valLeadTimeDays = 0;
	}
	doCancel() {
		this.cancelClicked.emit();
	}

	setRecord(): void {
		this.record = new SupplierMedication();
		if (this._selectedRecord !== undefined) {
			this.record.id = this._selectedRecord.id;
		} else {
			this.record.id = 0;
		}
		this.record.supplierId = this.valSelectedSupplierId;
		this.record.medicationId = this._medication.id;
		this.record.unitPrice = this.valUnitPrice;
		this.record.costPerDose = this.valCostPerDose;
		this.record.leadTimeDays = this.valLeadTimeDays;
	}
	validate() {
		if (this.valSelectedSupplierId === undefined || this.valSelectedSupplierId === 0) {
			this.strErr = 'Error: Supplier not selected - please select a supplier';
			DwUtils.setFocus('supplier');
			return false;
		}
		if (this.valCostPerDose === undefined) {
			this.strErr = 'Error: Cost per Dose not specified - please enter a cost per dose';
			DwUtils.setFocus('cost');
			return false;
		}
		if (this.valUnitPrice === undefined) {
			this.strErr = 'Error: Unit price not specified - please enter a unit price';
			DwUtils.setFocus('unitPrice');
			return false;
		}
		if (this.valLeadTimeDays === undefined) {
			this.strErr = 'Error: Lead Time not specified - please enter a lead time.';
			DwUtils.setFocus('leadTimeDays');
			return false;
		}
		return true;
	}
	doSubmit() {
		// this is where we stuff the new address and emit it back.
		this.setRecord();

		this.strErr = '';
		if (!this.validate()) {
			return;
		}

		if (this.record.id === 0) {
			this.medicationService.createMedicationonSupplier(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		} else {
			delete this.record['medication'];
			this.medicationService.updateMedicationonSupplier(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			)
		}
	}
	costFocusOutHandler() {
		if (this.valCostPerDose !== undefined && this.valCostPerDose < 0) {
			this.valCostPerDose = 0;
		}
	}
	unitPriceFocusOutHandler() {
		if (this.valUnitPrice !== undefined && this.valUnitPrice < 0) {
			this.valUnitPrice = 0;
		}
	}
	LeadTimeDaysFocusOutHandler() {
		if (this.valLeadTimeDays !== undefined && this.valLeadTimeDays < 0) {
			this.valLeadTimeDays = 0;
		}
	}
}


