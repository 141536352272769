import {Injectable} from '@angular/core';

export class Dw2dScanCode {
    gtin: string;
    ndc: string;
    serialNumber: string;
    expirationDate: string;
    lotNumber: string;
    error: string;

    constructor() {
        this.gtin = '';
        this.ndc = '';
        this.serialNumber = '';
        this.expirationDate = '';
        this.lotNumber = '';
        this.error = '';
    }
}
@Injectable({providedIn: 'root'})
export class DwCodes  {

    public static parse2DScanCode(inp: string): Dw2dScanCode {

        const record: Dw2dScanCode = new Dw2dScanCode();
        let i = 0;
        let nextStart = 2;
        let incr = 0;

        if (inp === undefined) {
            record.error = 'Error: The scanned item was not defined';
            return record;
        }
        /** Get the GTIN Number first **/

        for (i = nextStart; i < inp.length; i++) {
            /* Bail out if you find a '21' */
            if (inp[i] + inp[i + 1] === '21') {
                nextStart = i + 2;
                break;
            }
            record.gtin += inp[i];
        }
        /** take the NDC number from the GTIN **/

        record.ndc = this.returnNDCFromGTIN(record.gtin);

        /** Next get the serial number **/
        for (i = nextStart; i < inp.length; i++) {
            if (inp[i] + inp[i + 1] === '02') {                     /* was 02 */
                nextStart = i + 5;
                break;
            }
            record.serialNumber += inp[i];
        }
        /** Get the expiration Date **/
        record.expirationDate = '20';
        for (i = nextStart; i < inp.length; i++) {

            if (inp[i] + inp[i + 1] === '10') {
                nextStart = i + 2;
                break;
            }
            incr ++;
            record.expirationDate += inp[i];
            if(incr === 2 || incr === 4) {
                record.expirationDate += '-';
            }
        }
        /** finally get the Lot number **/
        for (i = nextStart; i < inp.length; i++) {
            record.lotNumber += inp[i];
        }

        return record;
    }
    public static returnNDCFromGTIN(inp: string): string {

        let tmpStr = '';
        let retVal = '';
        let counter = -1;

        if (inp === undefined) { return retVal; }

        /** first, strip off the leading 003 **/
        tmpStr = inp.replace('003', '');

        /** finally wind backwards, strip off the last character and add formatting **/

        for (let i = tmpStr.length - 2; i > - 1; i--) {
            counter++;
            if  (counter === 2 || counter === 5) {
                retVal += '-';
            }
            retVal += tmpStr[i];

        }
        /** reverse the string so that it is the right way round **/
        return retVal.split('').reverse().join('');
    }
}
