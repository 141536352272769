import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Contacts } from '../../models/contacts';
import { retry, catchError } from 'rxjs/operators';
import { Settings } from '../settings';
import {Addresses} from '../../models/addresses';

@Injectable({
    providedIn: 'root'
})

export class ContactsService {
    constructor(private http: HttpClient) {}

    public getContactById(id: number): Observable<any> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/contacts/' + id)
            .pipe(
                retry(1)
            );
    }

    public getContactsByFK(foreignKeyTable: string, foreignKeyId: number): Observable<Addresses[]> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/contacts/' + foreignKeyTable + '/' + foreignKeyId)
            .pipe(
                retry(1)
            );
    }
    public getContactsForAddressByForeignKey(foreignKeyTable: string, foreignKeyId: number): Observable<Addresses[]> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/contacts/byAddress/' + foreignKeyTable + '/' + foreignKeyId)
            .pipe(
                retry(1)
            );
    }
    public createContact(record: Contacts): Observable <any> {
        return this.http.post(Settings.getInstance().BASE_URL + 'general/contacts', record)
            .pipe(
                retry(1)
            );
    }
    public updateContact(record: Contacts): Observable <any> {
        return this.http.put(Settings.getInstance().BASE_URL + 'general/contacts/' + record.id, record)
            .pipe(
                retry(1)
            );
    }
    public removeContacts(id: number): Observable<any> {
        return this.http.delete(Settings.getInstance().BASE_URL + 'general/contacts/' + id)
            .pipe(
                retry(1)
            );
    }

}
