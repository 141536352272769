import { AppFunctionGroup } from './app-function-group';
import { AppFunction } from './app-function';

export interface User {
  id: number;
  username: string;
  password: string;
  name: string;
  lastLogin: string;
  email: string;
  status: number;
  appFunctionGroups: Array<AppFunctionGroup>;
  appFunctions: Array<AppFunction>;
  displayStatus: string;
  organization_id: number;
  invitation_count: number;
  last_invitation_date: string;
  invitation_status: number;
  invitation_token: string;
  app_functions: Array<any>;
  dashboard_settings: string;
}

export const ELEMENT_DATA: User[] = [
  {id: 1, username: 'iday', password: '1234', name: 'Ian Day', status: 1, email:'a@b.com', appFunctionGroups:[], appFunctions:[], displayStatus: '',lastLogin:'', organization_id: 0, invitation_count: 0, last_invitation_date:'', invitation_status: 0, invitation_token: '', dashboard_settings: '', app_functions: []},
  {id: 2, username: 'vishnu', password: '1234', name: 'Vishnu SV', status: 1, email:'a@b.com', appFunctionGroups:[], appFunctions:[], displayStatus: '',lastLogin:'', organization_id: 0, invitation_count: 0, last_invitation_date:'', invitation_status: 0, invitation_token: '', dashboard_settings: '', app_functions: []},
  {id: 3, username: 'tmathew', password: '1234', name: 'Toji Mathew', email:'a@b.com', status: 1, appFunctionGroups:[], appFunctions:[], displayStatus: '',lastLogin:'', organization_id: 0, invitation_count: 0, last_invitation_date:'', invitation_status: 0, invitation_token: '', dashboard_settings: '', app_functions: []},
  {id: 4, username: 'jsmith', password: '1234', name: 'John Smith', email:'a@b.com', status: 1, appFunctionGroups:[], appFunctions:[], displayStatus: '',lastLogin:'', organization_id: 0, invitation_count: 0, last_invitation_date:'', invitation_status: 0, invitation_token: '', dashboard_settings: '', app_functions: []}
];

export class User {

  constructor(withZero: boolean=false) {

    if(withZero === true) {
        this.id = 0;
    }
    else {
      this.id = null;
    }
    this.username = '';
    this.password = null;
    this.name = '';
    this.status = 1;
    this.organization_id = null;
  }
}
