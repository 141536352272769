/**
 *  Filename ....: bulkcheckin.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/20/23
 **/
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SuppliersService } from '../../../../services/suppliers.service';
import { Settings } from '../../../../services/settings';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Suppliers } from '../../../../models/suppliers';
import { DwUtils } from '../../../../components/utils/dw-utils';
import { DwCodes, Dw2dScanCode } from '../../../../components/utils/dw-codes';
import { MatTableDataSource } from '@angular/material/table';
import { ValidationResult } from '../../../../models/validation-result';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MedicationService } from '../../../../services/medication.service';
import { QuickAddSupplierComponent } from '../../suppliers-inv/qasupplier/qasupplier.component';
import { ImgVerifyComponent } from './imgverify/imgverify.component';
import { BulkUnits } from '../../../../models/bulkunits';
import { BulkUnitsService } from '../../../../services/bulkunits.service';
import { InventoryControlNumbers } from '../../../../models/icn';
import { MaterialService } from '../../../../services/material.service';
import { Catalogues } from '../../../../models/catalogues';
import { TransactionHistoryService } from '../../../../services/transactionhistory.service';
import { DwDates } from '../../../../components/utils/dw-dates';
import { InputMask } from 'primeng/inputmask';
import { PurchaseOrders } from 'src/app/models/purchaseorders';
import { PurchaseOrderItems } from 'src/app/models/purchaseorderitems';

/** used to hold array elements for checkin purposes **/
@Component({
	selector: 'app-bulkcheckin',
	templateUrl: './bulkcheckin.component.html',
	styleUrls: ['./bulkcheckin.component.css']
})

export class BulkCheckInComponent implements OnInit {

	/*** General ***/
	public strErr: string;
	public MEDICATIONS = 'Medications';

	/*** Services from Server ***/
	public supplierService: SuppliersService;
	public medicationService: MedicationService;
	public materialService: MaterialService;
	public bulkUnitsService: BulkUnitsService;
	public txHistoryService: TransactionHistoryService;

	/*** Record Structures ***/
	public suppliersList: any;
	public suppliers: any;
	public poList: Array<PurchaseOrders>;
	public poItemsList: Array<PurchaseOrderItems>;
	public selectedSupplier: any;                 /*** holds the selected supplier ***/
	public selectedDetail: any;                   /*** holds medication or material record ***/
	public records: Array<BulkUnits>;
	public selectedRecord: BulkUnits;
	public dataSource: MatTableDataSource<BulkUnits>;
	public tabletURL: string;
	public containerURL: string;
	public gtin: string;
	public icn: string;
	public itemNumber: string;
	public _selectedCatalogue: Catalogues;
	public selectedPO: PurchaseOrders;


	/*** filtered control ***/
	filteredSupplierOptions: Observable<string[]>;

	/*** Label Specific ***/
	public medMaterial = this.MEDICATIONS;
	public verifyStateIcon: string;
	public unverifiedState = '../../../../assets/greyQuestion.png';
	public verifiedState = '../../../../assets/greenTick.png';

	/*** field / form control ***/
	public supplierControl = new FormControl('');
	public invoiceNumberControl = new FormControl('');
	public invoiceDateControl = new FormControl('');
	public scanCodeControl = new FormControl('');
	public serialNumberControl = new FormControl('');
	public ndcNumberControl = new FormControl('');
	public lotNumberControl = new FormControl('');
	public expirationDateControl = new FormControl('');
	public locationControl = new FormControl('');

	/*** Dialog flags ***/
	public quickAddDisplayDialog = false;
	public imgVerificationDisplayDialog = false;
	public displayBarCodeDlg = false;

	/*** table column defs ***/
	public materialsColumns = ['icn', 'location', 'ndcNumber', 'poItem', 'delete'];
	public medicationColumns = ['icn', 'location', 'ndcNumber', 'expirationDate', 'poItem', 'delete'];
	public displayedColumns = [];

	/*** iteratives ***/
	public Idx = -1;
	public Itr = -1;

	@HostListener('keydown', ['$event.target'])
	onKeyDownGlobal($event) {
		this.strErr = '';
	}
	@Input() set selectedCatalogue(val: Catalogues) {
		this._selectedCatalogue = val;
	}
	get selectedCatalogue(): Catalogues {
		return this._selectedCatalogue;
	}

	/*** Events ***/
	@Output() cancelBulkCheckInEvent = new EventEmitter();
	@Output() submitBulkCheckInEvent = new EventEmitter();

	@ViewChild('quickAddSupplier', { static: true }) quickAddSupplier: QuickAddSupplierComponent;
	@ViewChild('imgVerify', { static: true }) imgVerify: ImgVerifyComponent;
	@ViewChild('expDate') public expDate: InputMask;

	private _supplierFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		const strRet = this.suppliersList.filter(option => option.toLowerCase().includes(filterValue));
		return strRet;
	}
	constructor(spService: SuppliersService, buService: BulkUnitsService,
		public dialog: MatDialog,
		matservices: MaterialService,
		medservices: MedicationService, txhist: TransactionHistoryService) {
		this.supplierService = spService;
		this.bulkUnitsService = buService;
		this.medicationService = medservices;
		this.materialService = matservices;
		this.txHistoryService = txhist;
		this.suppliersList = new Array<string>();
		this.suppliers = new Array<Suppliers>();
		this.verifyStateIcon = this.unverifiedState;
		this.strErr = '';
	}
	ngOnInit() {
		this.querySupplierRecords();
	}
	showDialog(): void {
		this.doClearSupplier();         // clear out the supplier section.
		this.doClearEntry();            // refresh the entry section
		this.doClearTable();            // clear out the table
		this.enableEntryFields(false);
		this.strErr = '';
		this.tabletURL = '';            // cl;ear
		this.containerURL = '';

		/*** Initialize records ***/
		this.records = new Array<BulkUnits>();
		this.selectedRecord = new BulkUnits();
		this.selectedDetail = null;       // null out any previous selectedDetail

		DwUtils.setFocus('supplierId');
	}
	enableEntryFields(enable: boolean): void {

		if (enable === true) {
			this.scanCodeControl.enable();
			this.serialNumberControl.enable();
			this.ndcNumberControl.enable();
			this.lotNumberControl.enable();
			this.expirationDateControl.enable();
			this.locationControl.enable();
		}
		else {
			this.scanCodeControl.disable();
			this.serialNumberControl.disable();
			this.ndcNumberControl.disable();
			this.lotNumberControl.disable();
			this.expirationDateControl.disable();
			this.locationControl.disable();
		}
	}
	querySupplierRecords(): void {
		this.suppliersList = new Array();
		this.supplierService.getAllSuppliers().subscribe(
			(data) => {
				this.suppliers = data;

				for (let i = 0; i < this.suppliers.length; i++) {
					if (this.suppliers[i].activeStatus === 'Active') {
						this.suppliersList.push(this.suppliers[i].companyName);
					}
				}
				// set up the autofilter.

				this.filteredSupplierOptions = this.supplierControl.valueChanges.pipe(
					startWith(''),
					map(value => this._supplierFilter(value || '')),
				);


			},
			(error) => {
				Settings.getInstance().handleError(error, 'Bulk Check In - Selecting suppliers');
			});
	}
	doClearSupplier(): void {
		this.supplierControl.setValue('');
		this.invoiceNumberControl.setValue('');
		this.invoiceDateControl.setValue('');
		this.doClearEntry();
		this.enableEntryFields(false);
		DwUtils.setFocus('supplierId');
	}
	supplierSelectVal(val: any): void {

		const selectedVal = this.supplierControl.value;
		this.doClearError();
		this.selectedSupplier = this.suppliers.find(x => x.companyName === selectedVal);

		this.medMaterial = this.selectedSupplier.supplierType;
		if (this.medMaterial === this.MEDICATIONS) {
			this.displayedColumns = this.medicationColumns;
		}
		else {
			this.displayedColumns = this.materialsColumns;
		}
		this.enableEntryFields(true);
		this.invoiceDateControl.setValue(DwUtils.returnCurrentDateTime(true));
		DwUtils.setFocus('invoiceNumber');
	}
	doClearEntry(): void {
		this.strErr = '';
		this.scanCodeControl.setValue('');
		this.serialNumberControl.setValue('');
		this.ndcNumberControl.setValue('');
		this.lotNumberControl.setValue('');
		this.expirationDateControl.setValue('');
		this.locationControl.setValue('');
		this.itemNumber = '';
		this.icn = null;
		this.verifyStateIcon = this.unverifiedState;
		DwUtils.setFocus('scanCode');
	}
	doClearTable(): void {
		this.records = new Array<BulkUnits>();
		this.dataSource = new MatTableDataSource(this.records);
	}
	doClearError(): void {
		this.strErr = '';
	}
	setRecord(): void {

		/*** Must have this initial check first ***/
		if (this.selectedSupplier === undefined) {
			this.strErr = 'Error: A supplier must be selected first';
			DwUtils.setFocus('supplierId');
			return;
		}
		this.selectedRecord = new BulkUnits();
		this.selectedRecord.supplierId = this.selectedSupplier.id;
		this.selectedRecord.invoiceNumber = this.invoiceNumberControl.value;
		this.selectedRecord.invoiceDate = this.invoiceDateControl.value;
		this.selectedRecord.tabletUrl = this.tabletURL;
		this.selectedRecord.containerUrl = this.containerURL;
		this.selectedRecord.itemNumber = this.itemNumber;
		this.selectedRecord.cataloguesId = this.selectedCatalogue.id;
		this.selectedRecord.foreignKeyTable = this.medMaterial === this.MEDICATIONS ? 'medications' : 'materials';
		this.selectedRecord.foreignKeyId = this.selectedDetail.id;
		this.selectedRecord.originalDoses = this.selectedDetail.packageSize;
		this.selectedRecord.remainingDoses = this.selectedDetail.packageSize;
		this.selectedRecord.containerStatus = 'Sealed';
		this.selectedRecord.inventoryStatus = 'Active';
		this.selectedRecord.awp = this.selectedDetail.awp;
		this.selectedRecord.costBase = this.selectedDetail.costBase;
		this.selectedRecord.unitCost = this.selectedDetail.unitCost;
		this.selectedRecord.gtin = this.gtin;
		this.selectedRecord.serialNumber = this.serialNumberControl.value;
		this.selectedRecord.ndcNumber = this.ndcNumberControl.value;
		this.selectedRecord.lotNumber = this.lotNumberControl.value;
		this.selectedRecord.expirationDate = this.expirationDateControl.value;
		this.selectedRecord.location = this.locationControl.value.toUpperCase();
		this.selectedRecord.icn = this.icn;
		if (this.selectedPO) { //Execute only if a Purchase order is selected
		this.selectedRecord.purchaseOrderId = this.selectedPO.id;
			const itemId = this.getPOItemIdByNDC(this.selectedRecord.ndcNumber);
			if (itemId > 0) {
				this.selectedRecord.purchaseOrderItemId = itemId;
			}
		}
	}
	doAddNewEntry(): void {

		this.doClearError();
		this.setRecord();

		/** Perform Validation check on the fields **/
		const vr: ValidationResult = this.selectedRecord.validateBulkCheckinRecord(this.medMaterial);

		if (vr.error.length > 0) {
			this.strErr = vr.error;
			DwUtils.setFocus(vr.focus_field);
			return;
		}
		/*** check to make sure we haven't got this in the list already ***/

		for (const item of this.records) {
			if (item.serialNumber === this.selectedRecord.serialNumber) {
				this.strErr = 'Error: This item is already in the pending items list ';
				return;
			}
		}

		this.records.push(this.selectedRecord);
		this.dataSource = new MatTableDataSource(this.records);
		// now clear the current form
		this.doClearEntry();
		DwUtils.setFocus('scanCode');
	}
	disableAddItemButton(): boolean {
		if (this.supplierControl.value === undefined)
			return false;
		if (this.supplierControl.value.length === 0) { return true; }
		if (this.invoiceNumberControl.value.length === 0) { return true; }
		if (this.invoiceDateControl.value.length === 0) { return true; }
		if (this.serialNumberControl.value.length === 0) { return true; }
		if (this.ndcNumberControl.value.length === 0) { return true; }
		if (this.lotNumberControl.value.length === 0) { return true; }
		if ((this.medMaterial === this.MEDICATIONS) && (this.expirationDateControl.value.length === 0)) { return true; }

		return false;

	}
	enabledCheckinButton(): boolean {
		if (this.records === undefined)
			return false;

		return this.records.length > 0 ? true : false;
	}
	tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.data.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				++this.Idx;
				//    this.selectedRecord = this.dataSource.data[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				--this.Idx;
				//  this.selectedRecord = this.dataSource.data[--this.Idx];
			}
		}
	}
	onRowClicked(event: any): void {

	}
	deleteRecord(idx: number): void {
		this.records.splice(idx, 1);
		this.dataSource = new MatTableDataSource(this.records);
	}
	doStartCheckin(): void {
		const message = 'Start the Check In?: ';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				this.Itr = 0;

				if (this.records.length > 0) {
					this.createBulkUnits();
				}
			}
		});
	}
	createBulkUnits(): void {
		if (this.Itr < this.records.length) {
			this.insertBulkUnitRecords();
		}
		else {
			/*** ALL DONE! ***/
			// Now we need to update the PO items quantity and status

			this.updatePurchaseOrderEntities();

			/*** throw the submit completed event ***/
			this.submitBulkCheckInEvent.emit();
		}

	}
	insertBulkUnitRecords(): void {

		this.bulkUnitsService.createBulkUnits(this.records[this.Itr]).subscribe(
			(data) => {
				this.records[this.Itr].id = data.id;
				this.txHistoryService.createTransactionHistory('bulkunits', data.id, 'Check-In', 'Scanned into Inventory');
				this.Itr++;
				this.createBulkUnits();
			}, (error) => {
				Settings.getInstance().handleError(error, 'Inserting bulk Units');
			});
	}
	updatePurchaseOrderEntities() {
		if (this.selectedPO) {
			let rcvdQuantity = 0;
			for (const item of this.poItemsList) {
				rcvdQuantity = this.getItemQuantityReceived(item.id);
				if (rcvdQuantity > item.quantity) {
					rcvdQuantity = item.quantity;
				}
				if (rcvdQuantity === 0) {
					continue;
				}
				if (item.quantity === rcvdQuantity) {
					item.itemStatus = 'Received';
					}
					item.quantityReceived = item.quantityReceived + rcvdQuantity;
			}
			// Now Let's check whether all items in the PO are received.
			if (this.ifAllItemsInPOReceived(this.poItemsList)) {
				this.selectedPO.orderStatus = 'Completed';
			}
			//Persisting PO entities
			for (const item of this.poItemsList) {
				this.bulkUnitsService.updatePurchaseOrderItem(item).subscribe(
					(data) => {
					},
					(error) => {
						Settings.getInstance().handleError(error, 'Updating a purchase order item');
					});
			}
			this.bulkUnitsService.updatePurchaseOrder(this.selectedPO).subscribe(
				(data) => {
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Updating a purchase order');
				});
		}
	}
	cancelSubmitRecord(): void {
		this.cancelBulkCheckInEvent.emit();
	}
	returnNDCLabel(): string {

		if (this.medMaterial === this.MEDICATIONS)
			return 'NDC Number';

		return 'Part Number';
	}
	doScanCode(): void {
		if (this.scanCodeControl.value.length < 45) {
			return;
		}
		if (this.icn.length > 0) {
			return;
		}
		let dwc = new Dw2dScanCode();
		dwc = DwCodes.parse2DScanCode(this.scanCodeControl.value);

		if (dwc.error.length > 0) {
			this.strErr = dwc.error;
			return;
		}

		this.bulkUnitsService.bulkUnitChkUniqueSerialNumber(dwc.serialNumber).subscribe(
			(result) => {
				if (result > 0) {
					this.strErr = 'Error: this serial number already exists in the system';
					DwUtils.highlightFocus('scanCode');
					return;
				}
				else {

					this.serialNumberControl.setValue(dwc.serialNumber);
					this.expirationDateControl.setValue(dwc.expirationDate);
					this.lotNumberControl.setValue(dwc.lotNumber);
					this.ndcNumberControl.setValue(dwc.ndc);
					this.gtin = dwc.gtin;
					this.doNDCChange(true);
				}
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Checking for a duplicate serial number');
			});

	}
	fetchLastInventoryLocationCode(): void {
		this.bulkUnitsService.fetchInvLocation(this.selectedCatalogue.id, this.ndcNumberControl.value).subscribe(
			(data) => {
				if (data !== undefined) {
					this.locationControl.setValue(data.location);
				}
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Fetching the last inventory location');
			});
	}
	doSerialNumberChange(): void {
		const sn = this.serialNumberControl.value;

		if (sn === undefined)
			return;

		this.bulkUnitsService.bulkUnitChkUniqueSerialNumber(sn).subscribe(
			(result) => {
				if (result > 0) {
					this.strErr = 'Error: this serial number already exists in the system';
					DwUtils.highlightFocus('serialNumber');
					return;
				}
				else {

				}
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Checking for a duplicate serial number');
			});
	}
	doExpirationDateCheck(): void {

		const exp = this.expirationDateControl.value;
		if (exp === undefined) {
			return;
		}
		if (DwDates.chkDateIsInThePast(exp) === true) {
			this.strErr = 'Error: This expiration date is in the past.';
			this.expDate.focus();
		}
	}
	returnICNNumber(): void {
		const locICN = new InventoryControlNumbers();
		locICN.dateRequested = DwUtils.returnCurrentDateTime();
		locICN.serialNumber = this.serialNumberControl.value;

		this.bulkUnitsService.createICN(locICN).subscribe(
			(data) => {
				this.icn = data.icn;
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Generating an ICN');
			}
		);
	}
	doNDCChange(autoOpen: boolean): void {

		if (this.ndcNumberControl.value.length === 0) {
			return;
		}

		if (this.medMaterial === this.MEDICATIONS) {

			this.medicationService.getMedicationByNDC(this.ndcNumberControl.value).subscribe(
				(data) => {
					this.selectedDetail = data;
					if (data === null) {
						this.strErr = 'Error: Unable to identify this medication - please check the NDC Number';
						this.icn = null;
						this.locationControl.setValue('');
						DwUtils.highlightFocus('ndcNumber');
						return;
					}
					this.itemNumber = data.medicationFamily.itemPrefix;
					this.fetchLastInventoryLocationCode();
					this.returnICNNumber();
					this.doImageVerify(autoOpen);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Retrieving medication by NDC');
				});
		}
		else {
			// select from materials goes here...
			this.materialService.getMaterialByPartNumber(this.ndcNumberControl.value).subscribe(
				(data) => {
					this.selectedDetail = data;
					if (data === null) {
						this.strErr = 'Error: Unable to identify this item - please check the Part Number';
						this.icn = null;
						this.locationControl.setValue('');
						DwUtils.highlightFocus('ndcNumber');
						return;
					}
					this.itemNumber = data.itemPrefix;
					this.fetchLastInventoryLocationCode();
					this.returnICNNumber();
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Retrieving materials by Part Number');
				});
		}
	}
	doImageVerify(autoOpen: boolean): void {
		const NDC = this.ndcNumberControl.value;

		if (NDC.length === 0) {
			this.strErr = 'Error: Please scan an item first';
			return;
		}

		this.imgVerificationDisplayDialog = true;
		this.imgVerify.doShow(NDC);

	}
	doInvLocFocusOut(event: any): void {

		this.doShowICNBarCode();
	}
	doShowICNBarCode(): void {
		this.displayBarCodeDlg = true;
	}
	doPrintICNBarCode(): void {
		window.print();
		this.displayBarCodeDlg = false;
	}
	doShowQuickAddSupplier(): void {
		this.quickAddDisplayDialog = true;
		this.quickAddSupplier.doShow();
	}
	submitQuickAddSupplier(event: any): void {
		this.suppliers.push(event);                         /* Add it to the suppliers array */
		this.suppliersList.push(event.companyName);         /* push the company name to the list */
		this.supplierControl.setValue(event.companyName);   /* set it in the control area */
		this.supplierSelectVal(event.companyName);          /* set the selected supplier */
		this.quickAddDisplayDialog = false;                 /* close the dialog box */
	}
	submitImgVerify(event: any): void {
		this.tabletURL = event.tabletUrl;
		this.containerURL = event.containerUrl;

		this.imgVerificationDisplayDialog = false;
		this.verifyStateIcon = this.verifiedState;

		if (this.scanCodeControl.value.length > 0) {
			DwUtils.setFocus('location');
		}
		else {
			DwUtils.setFocus('lotNumber');
		}
	}
	onSupplierBlur() {
		if (!this.selectedSupplier) {
			return;
		}
		this.bulkUnitsService.getPurchaseOrdersBySupplierIdAndStatus(this.selectedSupplier.id, 'All').subscribe(
			(data) => {
				this.poList = data;
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Bulk Check In - Selecting PO');
			});
	}
	puchaseOrderSelecttionHandler() {
		if (!this.selectedPO) {
			return;
		}
		this.bulkUnitsService.getPurchaseOrderItemsForPO(this.selectedPO.id).subscribe(
			(data) => {
				this.poItemsList = data;
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Bulk Check In - Selecting PO items');
			});
	}
	getPOItemIdByNDC(ndcNumber: String) {
		for (const rec of this.poItemsList) {
			if (rec.ndcNumber === ndcNumber) {
				return rec.id;
			}
		}
		return 0;
	}
	getPOItemDescriptionByNDC(ndcNumber: String) {
		for (const item of this.poItemsList) {
			if (item.ndcNumber === ndcNumber) {
				return item.itemDescription + '-' + item.packageSize;
			}
		}
		return 0;
	}
	getItemQuantityReceived(poItemId: number) {
		let ret = 0;
		for (const rec of this.records) {
			if (rec.purchaseOrderItemId === poItemId) {
				ret++;
			}
		}
		return ret;
	}
	ifAllItemsInPOReceived(poItems: PurchaseOrderItems[]) {
		for (const item of poItems) {
			if (item.itemStatus !== 'Received') {
				return false;
			}
		}
		return true;
	}
}


