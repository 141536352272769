import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { SupplierLicenses } from '../../../../../models/supplierlicenses';
import { ValidationResult } from '../../../../../models/validation-result';
import { SuppliersService } from '../../../../../services/suppliers.service';
import { DwUtils } from '../../../../../components/utils/dw-utils';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { States } from '../../../../../models/lists/states';
import { LicenseTypes } from '../../../../../models/lists/licensetypes';
import { Settings } from '../../../../../services/settings';
import { LicenseStateURLs } from '../../../../../models/licensestateurls';
import { AddLicenseStateURLsComponent } from '../../../../forming/customermaster/customerlicenses/add-license/add-licensestateurl/add-licensestateurl.component'
import {FileSelectorComponent} from '../../../../../components/shared/file-selector/file-selector.component';
import { CustomersService } from 'src/app/services/customers.service';

@Component({
  selector: 'app-add-supplierlicense',
  templateUrl: './add-supplierlicense.component.html',
  styleUrls: ['./add-supplierlicense.component.css']
})

export class AddSupplierlicenseComponent implements OnInit {

	public record: SupplierLicenses;
	public strErr = '';
	public _parentId: number;
	selectedFile: any;

	/*** Screen field elements ***/
	public id = 0;
	public licenseType = '';
	public licenseNumber = '';
	public expirationDate = '';
	public practiceState = '';
	public documentPath = '';
	public file_url = '';
	public addEditFlag: boolean;
	public quickSaveURL: boolean;
	public stateList: States;
	public licenseTypesList: LicenseTypes;
	public licenseStateURL: LicenseStateURLs;
	public defaultTargetURL = 'https://www.dea.gov';

	public urlToStore: string;

	/**** Filtered Controls ****/
	filteredStateOptions: Observable<string[]>;
	stateControl = new FormControl('');

	filteredLicenseTypeOptions: Observable<string[]>;
	licenseControl = new FormControl('');

	frmExpiryDate = new FormControl('');

	@ViewChild(AddLicenseStateURLsComponent)
	public addLicenseStateURL: AddLicenseStateURLsComponent;

	@ViewChild(FileSelectorComponent)
	public fileSelector: FileSelectorComponent;
	@Output() cancelAddLicense = new EventEmitter();
	@Output() submitAddLicense = new EventEmitter<SupplierLicenses>();

	private _stateFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.stateList.names.filter(option => option.toLowerCase().includes(filterValue));
	}

	private _licenseTypeFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.licenseTypesList.names.filter(option => option.toLowerCase().includes(filterValue));
	}

	@Input() set parentId(value: number) {
		this._parentId = value;
	}

	get parentId(): number {
		return this._parentId;
	}

	constructor(private supplierService: SuppliersService, private customerService: CustomersService) {
		this.stateList = new States();
		this.licenseTypesList = new LicenseTypes();
		this.licenseStateURL = new LicenseStateURLs();
		this.quickSaveURL = false;
	}

	ngOnInit() {
		this.filteredStateOptions = this.stateControl.valueChanges.pipe(
			startWith(''),
			map(value => this._stateFilter(value || '')),
		);
		this.filteredLicenseTypeOptions = this.licenseControl.valueChanges.pipe(
			startWith(''),
			map(value => this._licenseTypeFilter(value || '')),
		);
	}
	initializeRecord(l: SupplierLicenses): void {
		this.id = l.id;
		this.licenseControl.setValue(l.licenseType);
		this.licenseNumber = l.licenseNumber;
		if (l.expirationDate.length > 0) {
			this.expirationDate = l.expirationDate.split('T')[0];
		}
		else {
			this.expirationDate = '';
		}
		this.stateControl.setValue(l.practiceState);
		this.documentPath = l.documentPath;
		this.fileSelector.fileName = this.documentPath;
		console.log(this.documentPath);
		this.file_url = Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses/file/' + this.id;
	}
	displayLicenseTypeFn(type): string {
		return type ? type : '';
	}
	displayStateFn(state): string {
		return state ? state : '';
	}
	afterOpenDialog(inputRec: SupplierLicenses, aeFlag: boolean): void {
		this.addEditFlag = aeFlag;
		if (this.addEditFlag === true) {
			inputRec = new SupplierLicenses();
		}
		this.quickSaveURL = false;
		this.initializeRecord(inputRec);
		DwUtils.setFocus('licenseType');
	}
	displayFn(state): string {
		return state ? state : '';
	}

	doCancel() {
		this.cancelAddLicense.emit();
	}

	setRecord(): void {
		this.record = new SupplierLicenses();
		this.record.id = this.id;
		this.record.supplierId = this.parentId;
		this.record.licenseType = this.licenseControl.value;
		this.record.licenseNumber = this.licenseNumber;
		this.record.expirationDate = this.expirationDate;
		this.record.practiceState = this.stateControl.value;
		this.record.documentPath = this.documentPath;
	}
	retrieveLicenseStateURL(nextAction: string): void {
		const licenseType = DwUtils.evaluateCoalesce(this.licenseControl.value, 'NA');
		const practiceState = DwUtils.evaluateCoalesce(this.stateControl.value, 'NA');

		/* select any pre-saved URLs */
		this.customerService.getLicenseStateURL(licenseType, practiceState).subscribe(
			(result) => {
				if (result === null) {
					this.licenseStateURL = new LicenseStateURLs();
					this.licenseStateURL.licenseType = licenseType;
					this.licenseStateURL.practiceState = practiceState;
					this.licenseStateURL.url = this.defaultTargetURL;
				}
				else {
					this.licenseStateURL = result as LicenseStateURLs;
				}

				if (nextAction === 'browseWindow') {
					window.open(this.licenseStateURL.url, 'LicenseWindow');
				}
				else {
					this.quickSaveURL = true;
					this.addLicenseStateURL.afterDialogOpen(this.licenseStateURL);      // trigger the set and view.
				}
			},
			() => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			});
	}
	doBrowseInternet(): void {
		this.licenseStateURL = new LicenseStateURLs();
		this.retrieveLicenseStateURL('browseWindow');
	}

	doSaveURLValue(): void {

		if (this.stateControl.value.length === 0 || this.licenseControl.value.length === 0) {
			this.strErr = 'Error: You must specify a practice state and license type first';
			return;
		}
		this.retrieveLicenseStateURL('saveURL');

	}
	doSubmit() {
		// this is where we stuff the new address and emit it back.
		this.setRecord();

		this.strErr = '';
		const vr: ValidationResult = this.record.validateRecord();

		if (vr.error.length > 0) {
			this.strErr = vr.error;
			DwUtils.setFocus(vr.focus_field);
			return;
		}
		// add the record ...
		if (this.addEditFlag === true) {
			this.supplierService.createSupplierLicense(this.record).subscribe(
				(addData) => {
					this.record.id = addData.id;        // get the Id ...
					// Upload license file (if it has been defined)
					if(this.selectedFile !== undefined) {
						this.supplierService.uploadLicenseFile(this.record, this.selectedFile).subscribe(
							(addDataPlus) => {
								this.record.documentPath = addDataPlus.data.documentPath;
								this.submitAddLicense.emit(this.record);
							},
							(err) => {
								Settings.getInstance().handleError(err, 'createSupplierLicense()');
								return;
							});
					}
					else {
						this.submitAddLicense.emit(this.record);
					}
				},
				() => {
					this.strErr = 'Unable to create / save this record ';
					return;
				});
		}
		else {
			this.supplierService.updateSupplierLicense(this.record).subscribe(
				(updateData) => {
					if(this.selectedFile !== undefined) {
						this.supplierService.uploadLicenseFile(this.record, this.selectedFile).subscribe(
							(updateDataPlus) => {
								this.record.documentPath = updateDataPlus.data.documentPath;
								this.submitAddLicense.emit(this.record);
							},
							(err) => {
								Settings.getInstance().handleError(err, 'updateSupplierLicense()');
								return;
							});
						}
						else {
							this.submitAddLicense.emit(this.record);
						}
					},
				() => {
					this.strErr = 'Unable to update this record';
					return;
				});
		}
	}
	newLicenseStateURLCanceled(event: any): void {
		this.quickSaveURL = false;
	}
	newLicenseStateURLCreated(event: any): void {
		this.quickSaveURL = false;
	}
	onFileSelectedHandler(file: any) {
		this.selectedFile = file;
		this.strErr = '';
		if (this.selectedFile === undefined || this.selectedFile === null) {
			return;
		}
	}
	onFileErrorHandler(errMsg:any): void {
		this.strErr = errMsg;
	}
	openLicensePDF() {
		window.open(this.file_url, '_blank');
	}
	deleteLicenceFile() {
		this.supplierService.deleteLicenseFile(this.id).subscribe(
			() => {
				this.documentPath = '';
			},
			() => {
				this.strErr = 'Unable to delete file';
				return;
			});
	}
}


