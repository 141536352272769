/************
 * Service Class
 * File Name ...: sites.service.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {Sites} from '../models/sites';
import {Stations} from '../models/stations';
import {Settings} from './settings';
import { Catalogues } from '../models/catalogues';

@Injectable({
    providedIn: 'root'
})
export class SitesService {

    public URLSitesPath: string = Settings.getInstance().BASE_URL + 'sites/sites';
    public URLStationsPath: string = Settings.getInstance().BASE_URL + 'sites/stations';
    public URLCataloguesPath: string = Settings.getInstance().BASE_URL + 'sites/catalogues';
    constructor(private http: HttpClient) {
    }

    getAllSites(): Observable<any> {
        return this.http.get<any>(this.URLSitesPath)
            .pipe(
                retry(1)
            );
    }
    createSites(record: Sites): Observable<any> {
        return this.http.post(this.URLSitesPath, record)
            .pipe(
                retry(1)
            );
    }

    updateSites(record: Sites): Observable<any> {
        return this.http.put(this.URLSitesPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteSites(id: number): Observable<any> {
        return this.http.delete(this.URLSitesPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    getAllStations(siteId: number): Observable<any> {
        return this.http.get<any>(this.URLStationsPath + '/' + siteId)
            .pipe(
                retry(1)
            );
    }
    createStations(record: Stations): Observable<any> {
        return this.http.post(this.URLStationsPath, record)
            .pipe(
                retry(1)
            );
    }

    updateStations(record: Stations): Observable<any> {
        return this.http.put(this.URLStationsPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteStations(id: number): Observable<any> {
        return this.http.delete(this.URLStationsPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    /* Catalogues */
    getAllCatalogues(): Observable<any> {
        return this.http.get<any>(this.URLCataloguesPath)
            .pipe(
                retry(1)
            );
    }
    getAllCataloguesBySiteId(siteId: number): Observable<any> {
        return this.http.get<any>(this.URLCataloguesPath + '/' + siteId)
            .pipe(
                retry(1)
            );
    }
    createCatalogues(record: Catalogues): Observable<any> {
        return this.http.post(this.URLCataloguesPath, record)
            .pipe(
                retry(1)
            );
    }

    updateCatalogues(record: Catalogues): Observable<any> {
        return this.http.put(this.URLCataloguesPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteCatalogues(id: number): Observable<any> {
        return this.http.delete(this.URLCataloguesPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
}
