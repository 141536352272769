/**
 *  Filename ....: selectcreatepo.component.ts
 *  Created by ..: ianday
 *  on Date .....: 5/18/23
 **/
import {Component, EventEmitter, Output, OnInit, ViewChild, HostListener, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {map, startWith} from 'rxjs/operators';
import {Settings} from '../../../../services/settings';
import {SuppliersService} from '../../../../services/suppliers.service';
import {Observable} from 'rxjs';
import {QuickAddSupplierComponent} from '../../suppliers-inv/qasupplier/qasupplier.component';
import {MedicationService} from '../../../../services/medication.service';
import {MatTableDataSource} from '@angular/material/table';
import {SupplierItems} from '../../../../models/supplieritems';

@Component({
    selector: 'app-selectcreatepo',
    templateUrl: './selectcreatepo.component.html',
    styleUrls: ['./selectcreatepo.component.css']
})

export class SelectCreatePOComponent implements OnInit {

    filteredSupplierOptions: Observable<string[]>;
    public supplierControl = new FormControl('');
    public quickAddDisplayDialog = false;
    public suppliersList: any;
    public suppliers: any;
    public selectedSupplier: any;
    public supplierService: SuppliersService;

    public submitDisabled = true;
    /**** Medication Family ****/
    filteredMFOptions: Observable<string[]>;
    public medFamilyList: any;
    public medFamilies: any;
    public selectedMedFamily: any;
    public mfControl = new FormControl('');

    /*** Supplier Medication ***/
    public dataSource: MatTableDataSource<SupplierItems>;
    public supplierMedsList: any;
    public selectedSupplierMeds: SupplierItems;

    public Idx = -1;
    public displayedColumns = ['companyName', 'packageSize', 'unitPrice', 'costBase', 'costPerDose', 'leadTimeDays', 'lastUpdated'];

    public medFamilyService: MedicationService;

    private _supplierFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const strRet = this.suppliersList.filter(option => option.toLowerCase().includes(filterValue));
        return strRet;
    }
    private _mfFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const strRet = this.medFamilyList.filter(option => option.toLowerCase().includes(filterValue));
        return strRet;
    }

    @Output() submitSelectCreatePOEvent: EventEmitter<SupplierItems> = new EventEmitter();
    @Output() cancelSelectCreatePOEvent = new EventEmitter();

    @ViewChild('quickAddSupplier', {static: true}) quickAddSupplier: QuickAddSupplierComponent;

    constructor(supService: SuppliersService, mService: MedicationService) {
        this.supplierService = supService;
        this.medFamilyService = mService;
    }

    ngOnInit() {
        this.querySupplierRecords();
        this.queryMedFamilyRecords();
    }
    @HostListener('matSortChange', ['$event'])
    onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.supplierMedsList.sort((a, b) => a[event.active].toString().localeCompare(b[event.active].toString()));
        } else {
            this.supplierMedsList.sort((a, b) => b[event.active].toString().localeCompare(a[event.active].toString()));
        }

        this.dataSource = new MatTableDataSource(this.supplierMedsList);
        this.selectRow();
    }
    public tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.data.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                ++this.Idx;
                //    this.selectedRecord = this.dataSource.data[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                --this.Idx;
                //  this.selectedRecord = this.dataSource.data[--this.Idx];
            }
        }
    }

    /*** Entry Point ***/
    public doInitialize(): void {
        this.clearForm();
    }
    /*** Form Functions ***/
    public clearForm(): void {
        this.submitDisabled = true;
        this.clearSuppliers();
        this.clearMedications();
    }
    public clearSuppliers(): void {
        this.supplierControl.setValue('');
    }
    public clearMedications(): void {
        this.mfControl.setValue('');
        this.dataSource = new MatTableDataSource<SupplierItems>();
        this.Idx = -1;
    }
    /*** Suppliers selection ***/
    public querySupplierRecords(): void {
        this.suppliersList = new Array();
        this.supplierService.getAllSuppliers().subscribe(
            (data) => {
                this.suppliers = data;

                for (let i = 0; i < this.suppliers.length; i++) {
                    if (this.suppliers[i].activeStatus === 'Active') {
                        this.suppliersList.push(this.suppliers[i].companyName);
                    }
                }
                // set up the autofilter.
                this.filteredSupplierOptions = this.supplierControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._supplierFilter(value || '')),
                );
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Med Family selection / create PO');
            });
    }
    public supplierSelectVal(): void {

        const selectedVal = this.supplierControl.value;

        if (this.supplierControl.value === '') {
            this.submitDisabled = true;
        }
        else {
            // make sure the medications section is clear.
            this.clearMedications();
            this.selectedSupplier = this.suppliers.find(x => x.companyName === selectedVal);
            this.selectedSupplierMeds = new SupplierItems();
            this.selectedSupplierMeds.setSupplier(this.selectedSupplier);
            this.submitDisabled = false;
        }
    }
    public doShowQuickAddSupplier(): void {
        this.quickAddDisplayDialog = true;
        this.quickAddSupplier.doShow();
    }
    public submitQuickAddSupplier(event: any): void {
        this.suppliers.push(event);                         /* Add it to the suppliers array */
        this.suppliersList.push(event.companyName);         /* push the company name to the list */
        this.supplierControl.setValue(event.companyName);   /* set it in the control area */
        this.supplierSelectVal();          /* set the selected supplier */
        this.quickAddDisplayDialog = false;                 /* close the dialog box */
    }
    /**** Medication selection ***/
    public queryMedFamilyRecords(): void {
        this.medFamilyList = new Array();
        this.medFamilyService.getAllMedicationFamily().subscribe(
            (data) => {
                this.medFamilies = data;
                for (let i = 0; i < this.medFamilies.length; i++) {
                    this.medFamilyList.push(this.medFamilies[i].itemPrefix + ': ' + this.medFamilies[i].description);
                }
                // set up the autofilter.
                this.filteredMFOptions = this.mfControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._mfFilter(value || '')),
                );
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Bulk Check In - Selecting suppliers');
            });
    }
    public medFamilySelectVal(val: any): void {
        const selectedVal = this.mfControl.value;
        const selectedItemPref = selectedVal.split(':')[0];
        this.selectedMedFamily = this.medFamilies.find(x => x.itemPrefix === selectedItemPref);

        if(this.mfControl.value !== '') {
            this.clearSuppliers();
            this.submitDisabled = true;
        }

        this.queryMedSupplierRecords();
    }
    public queryMedSupplierRecords(): void {

        this.supplierMedsList = [];

        this.medFamilyService.getMedicationSuppliersByMedFamilyId(this.selectedMedFamily.id).subscribe(
            (data) => {
                for (let i = 0; i < data.length; i++) {
                    const sm = new SupplierItems();
                    sm.id = data[i].id;
                    sm.supplierId = data[i].supplier.id;
                    sm.companyName = this.suppliers.find(x => x.id === data[i].supplier.id).companyName;
                    sm.supplierType = 'Medications';
                    sm.itemId = data[i].medication.id;
                    sm.unitPrice = data[i].unitPrice;
                    sm.packageSize = data[i].medication.packageSize;
                    sm.unitOfMeasure = data[i].medication.unitOfMeasure;
                    sm.description = this.selectedMedFamily.description;
                    sm.origNdcNumber = data[i].medication.origNdcNumber;
                    sm.quantity = 1;                            // default
                    sm.extended = data[i].unitPrice;
                    sm.costBase = data[i].medication.costBase;
                    sm.leadTimeDays = data[i].leadTimeDays;
                    sm.lastUpdated = data[i].lastUpdated.toLocaleString();
                    sm.schedule = data[i].medication.schedule;
                    this.supplierMedsList.push(sm);
                }

                this.dataSource = new MatTableDataSource(this.supplierMedsList);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'retrieving supplier medication - create po');
            }
        )
    }
    public doClearMedication(): void {
        this.mfControl.setValue('');
        this.dataSource = new MatTableDataSource<SupplierItems>();
        DwUtils.setFocus('medFamilyId');
    }
    /*** Supplier medications ***/
    public onRowClicked(selected: any): void {
        this.selectedSupplierMeds = selected;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedSupplierMeds.itemId === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedSupplierMeds.id);

        if (this.Idx >= 0) {
            this.submitDisabled = false;
        }
        else {
            this.submitDisabled = true;
        }
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedSupplierMeds = new SupplierItems();

    }
    /*** Save Strip ***/
    public onSubmittedClicked(): void {

        this.submitSelectCreatePOEvent.emit(this.selectedSupplierMeds);
    }
    public onCancelClicked(): void {
        this.cancelSelectCreatePOEvent.emit();
    }
    public calcCostPerDose(unit:number, pkgSize: number): number {
        return Math.round((unit / pkgSize) * 100) / 100;
    }
}


