/************
 * Model Class
 * File Name ...: suppliers.components.ts
 * Generated By : ianday on 2/17/23
 * Date ........: 2/17/23
 /************/
import {ValidationResult} from './validation-result';

export interface Suppliers {
    id: number | null;
    companyName: string | null;
    logoImage: string | null;
    webSiteAddress: string | null;
    supplierType: string | null;
    activeStatus: string | null;

}

export class Suppliers {

    constructor() {

        this.id = 0;
        this.companyName = '';
        this.logoImage = '';
        this.webSiteAddress = '';
        this.supplierType = '';
        this.activeStatus = '';
    }
    validateRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.companyName.length === 0) {
            vr.focus_field = 'companyName';
            vr.error = 'Error: A company name is required';
            return vr;
        }

        if (this.webSiteAddress.length > 0) {
            if (this.webSiteAddress.includes(' ') === true) {

                vr.focus_field = 'webSiteAddress';
                vr.error = 'Error: Spaces are not allowed ';
                return vr;
            }

        }
        return vr;
    }
}
