/************
 * Model Class
 * File Name ...: customers.components.ts
 * Generated By : ianday on 3/1/23
 * Date ........: 3/1/23
 /************/
import {ValidationResult} from './validation-result';

export interface Customers {
    id: number | null;
    customerName: string | null;
    activeStatus: string | null;
    customerType: string | null;
    groupNpiNumber: string | null;
    ncpdpNumber: string | null;
    customerGroup: string | null;
    fillSiteId: number | null;
}

export class Customers {

    constructor() {
        this.id = 0;
        this.customerName = '';
        this.activeStatus = '';
        this.customerType = '';
        this.groupNpiNumber = '';
        this.ncpdpNumber = '';
        this.customerGroup = '';
        this.fillSiteId = 0;
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.id === 0 || this.id === null) {
            vr.focus_field = 'id';                              /* this should be the id value of the field */
            vr.error = 'Error: a customer id must be specified - or use \"generate\" to create one';
            return vr;
        }

        if (this.customerName.length === 0) {
            vr.focus_field = 'customerName';                              /* this should be the id value of the field */
            vr.error = 'Error: a customer name must be specified';
            return vr;
        }

        if (this.customerType.length === 0) {
            vr.focus_field = 'customerType';
            vr.error = 'Error: a customer type must be specified';
            return vr;
        }

        return vr;
    }
}
