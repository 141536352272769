<mat-card class="metric-card-item">
    <mat-card-content class="metric-card-text-body-content">
	    <table class="metric-card-table" >
		    <tr>
		    	<td width="92%" style="text-align: center;">
					{{selectedCard.content}}: <br />{{selectedCard.current_result}}
		    	</td>
		      	<td width="6%" style="vertical-align: middle;" >
		        	<img *ngIf="selectedCard.previous_result != -1 && selectedCard.current_result > selectedCard.previous_result"
						 src="assets/arrow-up-green.png"
						 [matTooltip]="formatToolTip(selectedCard)">
					<img *ngIf="selectedCard.previous_result != -1 && selectedCard.current_result < selectedCard.previous_result"
						 src="assets/arrow-down-red.png" [matTooltip]="formatToolTip(selectedCard)">
					<img *ngIf="selectedCard.previous_result != -1 && selectedCard.current_result === selectedCard.previous_result"
						 src="assets/arrow-gray-flat.png" [matTooltip]="formatToolTip(selectedCard)">
				</td>
		    </tr>
	    </table>
    </mat-card-content>
</mat-card>
