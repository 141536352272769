export interface ChartDetails
{
    id: number;
    title: string;
    type: string;
    data: any;
    dataSource: string;
    options: any;

    _selected: boolean;
}

export const CHART_DETAILS_LIST: ChartDetails[] = [{ id: 1, title: 'Active Projects ', type: 'bar', dataSource: '', data: {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
                    {
                        label: 'Projects Starting',
                        backgroundColor: ['#42A5F5', '#42A5F5', '#42A5F5'],
                        borderColor: ['#1E88E5'],
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: 'Projects Ending',
                        backgroundColor: ['#9CCC65', '#9CCC65', '#9CCC65'],
                        borderColor: ['#7CB342'],
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }
                ]
            }, options: {}, _selected:true}

       ,{ id: 4, title: 'Active Projects ', type: 'bar', dataSource: '',  data: {
           labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
           datasets: [
                           {
                               label: 'Projects Staring',
                               backgroundColor: '#42A5F5',
                               borderColor: '#1E88E5',
                               data: [65, 59, 80, 81, 56, 55, 40]
                           },
                           {
                               label: 'Projects Ending',
                               backgroundColor: '#9CCC65',
                               borderColor: '#7CB342',
                               data: [28, 48, 40, 19, 86, 27, 90]
                           }
                       ]
                   }, options: {}, _selected:true}
       ,{ id: 5, title: 'Active Projects ', type: 'bar',  dataSource: '', data: {
           labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
           datasets: [
                           {
                               label: 'Projects Staring',
                               backgroundColor: '#42A5F5',
                               borderColor: '#1E88E5',
                               data: [65, 59, 80, 81, 56, 55, 40]
                           },
                           {
                               label: 'Projects Ending',
                               backgroundColor: '#9CCC65',
                               borderColor: '#7CB342',
                               data: [28, 48, 40, 19, 86, 27, 90]
                           }
                       ]
                   }, options: {}, _selected:true}
       ,{ id: 6, title: 'Active Projects ', type: 'bar',  dataSource: '', data: {
           labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
           datasets: [
                           {
                               label: 'Projects Staring',
                               backgroundColor: '#42A5F5',
                               borderColor: '#1E88E5',
                               data: [65, 59, 80, 81, 56, 55, 40]
                           },
                           {
                               label: 'Projects Ending',
                               backgroundColor: '#9CCC65',
                               borderColor: '#7CB342',
                               data: [28, 48, 40, 19, 86, 27, 90]
                           }
                       ]
                   }, options: {}, _selected:true}
       ,{ id: 7, title: 'Active Projects ', type: 'bar',  dataSource: '', data: {
           labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
           datasets: [
                           {
                               label: 'Projects Staring',
                               backgroundColor: '#42A5F5',
                               borderColor: '#1E88E5',
                               data: [65, 59, 80, 81, 56, 55, 40]
                           },
                           {
                               label: 'Projects Ending',
                               backgroundColor: '#9CCC65',
                               borderColor: '#7CB342',
                               data: [28, 48, 40, 19, 86, 27, 90]
                           }
                       ]
                   }, options: {}, _selected:false}
       ];