/****************************************************************
 * This class contains a number of static functions that manage
 * many of the date calculations.
 ***************************************************************/
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DwDates  {

    public static chkValidDate(inpDt: string): boolean {
        try {
            const testDate: Date = new Date(inpDt);
            if (testDate.toDateString() === 'Invalid Date')
                return false;

        } catch (error) {
            return false;
        }

        return true;
    }
    public static chkDateIsInThePast(inpDt: string): boolean {

        if (DwDates.chkValidDate(inpDt) === false) {
            return false;
        }

        const passedDate: Date = new Date(inpDt);
        const currentDate: Date = new Date();
        if (passedDate < currentDate) {
            return true;
        }
        return false;
    }
    public static chkDateIsToday(inpDt: string): boolean {
        if (DwDates.chkValidDate(inpDt) === false) {
            return false;
        }

        const passedDate: Date = new Date(inpDt);
        const currentDate: Date = new Date();
        if (passedDate === currentDate) {
            return true;
        }
        return false;
    }
    public static calculateDaysBeforeDate(inpDt: string): number {
        if (DwDates.chkValidDate(inpDt) === false) {
            return 0;
        }
        const oneDay: number = 24 * 60 * 60 * 1000;
        const compareToDate: any = new Date(inpDt);
        const currentDate: any = new Date();

        // is the date in the past ....
        if (compareToDate < currentDate) {
            return 0;
        }
        return Math.round(Math.abs(compareToDate - currentDate) / oneDay);
    }
}
