import {SupplierItems} from './supplieritems';

export class SupplierMedication
{
     public id: number;
	 public costPerDose: number;
	 public medicationId: number;
	 public supplierId: number;
	 public supplierName: string;
	 public lastUpdated: number;
	 public leadTimeDays: number;
	 public unitPrice: number;

	 constructor() {

	 }
	 public setFromSupplierMeds(rec: SupplierItems, costPerDose: number ): void {
		 this.id = rec.id;
		 this.costPerDose = costPerDose;
		 this.medicationId = rec.itemId;
		 this.supplierId = rec.supplierId;
		 this.lastUpdated = new Date().getTime();
		 this.leadTimeDays = rec.leadTimeDays;
		 this.unitPrice = rec.unitPrice;
	 }
}
