/**
 *  Filename ....: globalsearch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/28/23
 **/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CustomersService} from '../../../../services/customers.service';
import {Settings} from '../../../../services/settings';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {FormingService} from '../../../../services/forming.service';
import {ValidationResult} from '../../../../models/validation-result';
import { FormingQBE } from '../../../../models/forming-qbe';

@Component({
    selector: 'app-globalsearch',
    templateUrl: './globalsearch.component.html',
    styleUrls: ['./globalsearch.component.css']
})

export class GlobalSearchComponent implements OnInit {

    public MAX_RECORDS = 300;
    public strErr: string;
    public formingQBE: FormingQBE = new FormingQBE();
    public disabledFlag: boolean | null = false;
    public dataSource: MatTableDataSource<any>;
    public customerRecords: any;
    public selectedRecords: any;
    public statusList: any;
    public enableSubmit: boolean | null = false;
    public selected: any;
    public records: any;
    public pagination: string;
    public Idx: number | null = -1;

    public datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    public displayedColumns = ['select', 'customerName', 'orderNumber', 'description', 'dateCompleted', 'extendedPrice', 'itemStatus'];

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<any>();

    constructor(public customerService: CustomersService,
                public formingService: FormingService) {

        this.strErr = '';
    }

    public doInitialize(): void {
        this.initializeQueryParams();
        DwUtils.setFocus('customerNameParamId');

    }
    public initializeQueryParams(): void {
        this.formingQBE = new FormingQBE();
    }

    ngOnInit() {
        this.queryCustomers();
        this.setPagination(0, 0);

        this.statusList = new Array();
        this.statusList.push({id: 'ALL', description: 'Any Status'});
        this.statusList.push({id: 'New', description: 'New Items'});
        this.statusList.push({id: 'Shipped', description: 'Shipped Items'});
        this.statusList.push({id: 'In-Process', description: 'In Process Items'});
        this.formingQBE.itemStatusParam = 'ALL';
    }

    public queryCustomers(): void {
        this.customerService.getActiveCustomers().subscribe(
            (results) => {
                this.customerRecords = results;
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching customers');
            });

    }
    public onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public toggleAllSelected(event): void {

        if (this.dataSource === undefined) {
            return;
        }

        for (const item of this.dataSource.filteredData) {
            item.select = event.checked;
        }

        if (event.checked === true) {
            this.enableSubmit = true;
        }
    }
    public runChecked(event: any): void {

        if (event.checked === true) {
            this.enableSubmit = true;
        }
        else {
            this.dataSource.filteredData.filter(x => x.select === true).length > 1 ? this.enableSubmit = true : this.enableSubmit = false;
        }
    }
    public onItemStatusChange(event: any): void {

        this.formingQBE.itemStatusParam = event.value;
    }
    public selectRow() {
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selected.id);
    }
    public onRowClicked(row: any): void {
        this.Idx = this.dataSource.filteredData.findIndex(s => s.itemId === row.itemId);
        this.selected = this.dataSource.filteredData[this.Idx];
    }
    public setPagination(idx: number, total: number) {
        if (idx === -1) {
            this.pagination = 'No results';
        }
        this.pagination = (idx + 1) + '/' + total;
    }
    public tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selected = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selected = this.dataSource.filteredData[--this.Idx];
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public onSubmitClicked(): void {
        this.selectedRecords = new Array();
        this.selectedRecords = this.dataSource.filteredData.filter(x => x.select === true);
        this.submitClicked.emit(this.selectedRecords);
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
    public searchButtonClicked(): void {

        this.strErr = '';
        if (this.validateSearchFields() === false) {
            return;
        }

        this.formingService.getByQueryByExample(this.formingQBE).subscribe(
            (results) => {
                if (results.length > 0) {

                    this.records = new Array();
                    this.enableSubmit = false;
                    for (const item of results) {
                        item["select"] = false;
                        this.records.push(item);
                        if (this.records.length >= this.MAX_RECORDS) {
                            break;
                        }
                    }
                    this.Idx = 0;
                    this.dataSource = new MatTableDataSource(this.records);
                    this.setPagination(this.Idx, this.records.length);
                }
                else {
                    this.dataSource = new MatTableDataSource();
                    this.records = new Array();
                    this.strErr = 'No Results Found';
                }

            },
            (error) => {
                Settings.getInstance().handleError(error, 'Querying by parameters');
            }
        );
    }
    public resetQueryCriteria(): void {
        this.clearSearchFields();
        this.Idx = -1;
        this.records = new Array();
        this.dataSource = new MatTableDataSource<any>();
        this.strErr = '';
        this.setPagination(this.Idx, this.records.length);
    }
    public validateSearchFields(): boolean {

        const vr: ValidationResult = this.formingQBE.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return false;
        }

        return true;
    }
    public clearSearchFields(): void {
        this.initializeQueryParams();
    }
}
