<div class="query-strip-container mat-elevation-z2" >
  	<table height="100%"  width="100%" >
		<TR> 
			<TD class="table-title-cell"><i class="fa fa-cog table-icon-header" style="width: 2em"></i>{{formTitle}}</TD>
		</TR>
	</table>
</div>

<div style="margin-left: 20px; margin-bottom: 20px; ">
	<div class="dwform">
		<div class="dwform-row-gap"></div>
		<div class="dwform-row">
			<label class="label-field">Banner Text:</label>
			<input class="input-field" style="width:500px"type="text" [(ngModel)]="valBannerText" [disabled]="!editMode">
			<label for="favcolor">Background:&nbsp;&nbsp;</label>
			<input style="width: 30px; vertical-align: middle;" type="color" id="backcolor" name="backcolor" [(ngModel)]="valBackColor" [disabled]="!editMode"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<!-- <p-checkbox [(ngModel)]="valEnableBanner" binary="true" inputId="binary"></p-checkbox>&nbsp; -->
			<mat-checkbox [(ngModel)]="valEnableBanner" binary="true" style="padding-left:10px;" [disabled]="!editMode">Show banner</mat-checkbox>
		  
			<div class="dwform-row-gap"></div>
			   
			<div class="dwform-row-gap"></div>
		</div>
	</div>
</div>
<div style="width: 70% !important; bottom: 60px; position: absolute;">
	<label class="label-field" style="color: blue">{{strMessage}}</label>
</div>
<!-- save strip -->
<div *ngIf="editMode" class="save-close-strip" style="bottom: 20px;">

		<button mat-button class="command-button" (click)="doSubmit()"
			matTooltip="Submit data" mat-button [disabled]="!editMode">
			<mat-icon>check_circle_outline</mat-icon>
			Submit
		</button>
		<button mat-button class="command-button" (click)="doCancel()"
			matTooltip="Cancel changes" mat-button [disabled]="!editMode">
			<mat-icon>highlight_off</mat-icon>
			Cancel
		</button>
	</div>
	<div *ngIf="!editMode" class="save-close-strip" style="bottom: 20px;">

		<button mat-button class="command-button" (click)="enterEditMode()"
			matTooltip="Edit data" mat-button>
			<mat-icon>edit</mat-icon>
			Modify
		</button>
	</div>

<p-dialog header="Settings" [(visible)]="displaySavedMessage" [modal]="true" [style]="{width: '20vw'}"  appendTo="body"
	[draggable]="false" [resizable]="false">
	<app-message-box [data]="{message: 'Settings has been saved!', showOk: true}" (onClose)="displaySavedMessage=false"> </app-message-box>
</p-dialog>	
