/************
 * Model Class
 * File Name ...: siteentries.components.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/
import {ValidationResult} from './validation-result';

export interface StationEntries {
    id: number | null;
    description: string | null;
    activeStatus: string | null;
    edit: string;
    delete: string;
}

export class StationEntries {
    constructor() {
        this.id = 0;
        this.description = '';
        this.activeStatus = '';
        this.edit = '' ;
        this.delete = '';
    }
}
