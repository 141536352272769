import {ValidationResult} from './validation-result';

export interface Material {
	id: number;
	handle: string;
	description: string | null;
	serialNumber: string | null;
	itemType: string | null;
	activeStatus: string | null;
	packageSize: number | null;
	unitOfMeasure: string | null;
	costBase: number | null;
	awp: number | null;
	invWarningThreshold: number | null;
	partNumber: string | null;
}

export class Material {

	constructor() {
		this.id = 0;
		this.handle = '';
		this.description = '';
		this.serialNumber = '';
		this.itemType = '';
		this.activeStatus = '';
		this.packageSize = 0;
		this.unitOfMeasure = '';
		this.costBase = 0;
		this.awp = 0;
		this.invWarningThreshold = 0;
		this.partNumber = '';
	}
	validateRecord(): ValidationResult {

		const vr: ValidationResult = new ValidationResult();

		if (this.handle.length === 0) {
			vr.focus_field = 'handle';
			vr.error = 'Error: a short description is required';
			return vr;
		}
	}

}

