<div id="rendered_notes_container_id" [ngClass]="viewClass" [ngStyle]="{'height':containerHeight}">
	<div *ngFor="let noteRec of noteList; let idx = index;" class="note-container">
		 <div class="note-container">
			<div style="margin-top: 0px;">
				<div class="avatar-img" [ngStyle]="{  'background-image':
				'url('+getSanitizedURL(noteRec.userUid)+ '), url(/assets/default_user.png)'}" >
				</div>
			</div>
			<div class="note-rhs-container">
				<div class="notes-rhs-top-band">
					<b>{{noteRec.userFullName}}</b>&nbsp;{{noteRec.dateAdded}}
				</div>
				<div class="notes-rhs-bottom-band"
					 (mouseover)="messageMouseOver($event, idx)"
					 (mouseout)="messageMouseOut($event)"
					 [innerHtml]="noteRec.commentText">
				</div>
			</div>

			<!-- menu for edit/remove -->
			<div class="note-lhs-container" >
			  <span [matMenuTriggerFor]="menu"
					[hidden]="!validateEditNote(noteRec.userUid)"
					aria-label="Example icon-button with a menu">
				<mat-icon class="vertical-dots-style">more_vert</mat-icon>
			  </span>
				<mat-menu #menu="matMenu">
					<button mat-menu-item class="popup-menu-item-class"
							(click)="editDisplayedMessage(idx)">
						<mat-icon>edit</mat-icon>
						<span>Edit</span>
					</button>
					<button mat-menu-item class="popup-menu-item-class"
							(click)="deleteDisplayedMessage(idx)">
						<mat-icon>delete</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</div>
		</div>
	</div>
</div>

<div id="id-submit-div" class="submit-div">
	<table class="notes-submit-table" >
		<tr>
			<TD style="padding-left:3px">

				<div #taNote id="ta-note-text"
					 [contentEditable]="!disabledFlag"
					 class="note-text-area-class "
					 mat-input rows="4" wrap="soft"
					 placeholder="<Add a note>">
				</div>
			</TD>
		</tr>
	</table>

	<!-- COMMAND BUTTONS -->

	<table class="submit-button-table" >
		<tr >
			<TD style=" text-align: right;">

			</TD>
			<TD style="padding-left: 0; width: 40px">
				<button mat-button [disabled]="disabledFlag"
						class="command-button command-button-override"
						matTooltip="Submit a note"
						(click)="this.submitNewNote()">
					<mat-icon class="icon-styles">send_outline</mat-icon>
				</button>
			</TD>
			<TD class="submit-button-cells">
				<button mat-button
						[disabled]="disabledFlag"
						class="command-button command-button-override"
						matTooltip="Cancel this edit"
						(click)="cancelEditClicked($event)">
					<mat-icon class="icon-styles">cancel</mat-icon>
				</button>
			</TD>
		</tr>
	</table>
</div>


