<!------------------------------------->
<!-- List tab detail layout.
 * File Name ..: preparation.component.html
 * Generated by: ianday
 * Date .......: 7/5/23
--------------------------------------->
<div class="preparation-form-container" xmlns="http://www.w3.org/1999/html">
    <div class="query-strip-container mat-elevation-z2">
        <table height="100%">
            <TR>
                <TD class="table-title-cell" style="min-width:120px!important;">
                    <i class="fas fa-flag-checkered fa-fw table-icon-header"
                       style="width: 2em"></i>{{formTitle}}
                </TD>
                <!-- SITES DROP DOWN LIST -->
                <TD class="qs-label-cell" style="width: 60px; text-align: left;">Sites:</TD>
                <TD style="width: 250px">
                    <mat-select
                            disableOptionCentering
                            [(ngModel)]="selectedSite" #cbSites floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 10pt;width: 180px;"
                            panelClass="local-drop-down-panel"
                            (selectionChange)="txtFilter.value=''; onChangeSitesFilterValue($event)">
                        <mat-option *ngFor="let site of sitesList"
                                    [value]="site">{{site.handle}}</mat-option>
                    </mat-select>
                </TD>
                <TD align="left" style="min-width: 400px;">
                    <input text id="txtFilter" #txtFilter
                           class="qs_filter-field" [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </TD>
                <TD class="qs-label-cell" style="width: 60px; text-align: center;">Stations:</TD>
                <TD style="width: 150px">
                    <mat-select
                            disableOptionCentering
                            [(ngModel)]="selectedStation" #cbStation floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 10pt;width: 180px;"
                            panelClass="local-drop-down-panel"
                            (selectionChange)="txtFilter.value=''; onChangeStationsFilterValue($event)">
                        <mat-option *ngFor="let station of stationsList"
                                    [value]="station">{{station.handle}}</mat-option>
                    </mat-select>
                </TD>
                <TD class="table-pagination-cell" align="right">{{pagination}} Record(s) ...</TD>
            </TR>
        </table>
    </div>

    <mat-drawer-container autosize>
        <!-------------------------->
        <!-- Right hand drawer    -->
        <!-- Open state           -->
        <!-------------------------->
        <mat-drawer position="end" opened="true" class="mat-elevation-z2"
                    style="border: .5px solid #e1e1e1" #drawer
                    mode="side" disableClose="true">
            <div style="max-width: 400px; padding: 2px;">

                <table [hidden]="!propContainerState"
                       class="prop-notes-outer-container">
                    <TR>
                        <TD align="left" style="width: 35px; padding: 0;">
                            <button id="preparation-toggle-drawer-close-btn" mat-icon-button
                                    (click)="onPropertiesToggle()">
                                <mat-icon>arrow_right</mat-icon>
                            </button>
                        </TD>
                        <TD width="100%" style="text-align: left;">
                            <b>Additional Properties</b>
                        </TD>
                        <TD width="35px"></TD>
                    </TR>
                </table>
                <div [hidden]="!propContainerState"
                     class="prop-menu-container" style="width:395px;">
                    <mat-tab-group class="prop-tab-group" style="height: calc(100% - 2px)">
                        <mat-tab label="BPR">
                           <app-bpr [selectedFormingRecord]="selectedRecord" [stationsList]="stationsList"></app-bpr>
                        </mat-tab>

                        <mat-tab label="Forms">
                            <app-finishedforms [selectedForm]="selectedRecord">

                            </app-finishedforms>
                        </mat-tab>

                        <mat-tab label="Notes" *ngIf="visibleNotesTab">
                            <app-notes style="border-top: .5px solid #e1e1e1;"
                                       [callingComponent]="'forming'"
                                       [parentId]="selectedRecord?.id">
                            </app-notes>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div>
                <!----------------------->
                <!-- Right hand Drawer -->
                <!-- retracted state ---->
                <!----------------------->
                <div [hidden]="propContainerState" width="100%">
                    <button id="preparation-toggle-drawer-open-btn" mat-icon-button (click)="onPropertiesToggle()">
                        <mat-icon>arrow_left</mat-icon>
                    </button>
                </div>

                <div class="rotate-text" [hidden]="propContainerState">
                    Additional Properties
                </div>
            </div>
        </mat-drawer>

        <div class="main-client-area">
            <!--------------------------->
            <!-- RECORDS TABLE         -->
            <!--------------------------->
            <div #tb1 [ngClass]="contentClassName" class="table-container mat-elevation-z1"
                 (keydown)="tableKeyDown($event)" tabindex="-1">
                <table mat-table [dataSource]="dataSource" matSort
                       (matSortChange)="onSortChange($event)">

                    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on
                  the row definition" -->

                    <!-- Customer -->
                    <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="min-width: 90px;">Customer
                        </th>
                        <td mat-cell
                            class="table-detail-style-override"
                            *matCellDef="let element;let tableRowIdx = index;">
                            {{element.customerName}}</td>
                    </ng-container>
                    <!-- Item Prefix Column -->
                    <ng-container matColumnDef="itemPrefix">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="min-width:50px">Item
                        </th>
                        <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
                            class="table-detail-style-override">
                            {{element.itemPrefix}}</td>
                    </ng-container>

                    <!-- Description -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="min-width:250px;">Description
                        </th>
                        <td mat-cell *matCellDef="let element"
                            class="table-detail-style-override">{{element.description}}</td>
                    </ng-container>

                    <!-- Schedule -->
                    <ng-container matColumnDef="schedule">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="text-align: center; width: 30px;">Schedule
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align: center"
                            class="table-detail-style-override">{{element.schedule}}</td>
                    </ng-container>

                    <!-- quantity -->
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="text-align: center; min-width: 30px">Quantity
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align: center"
                            class="table-detail-style-override">{{element.quantity}}</td>
                    </ng-container>

                    <!-- Station -->
                    <ng-container matColumnDef="station">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="min-width: 65px">Station
                        </th>
                        <td mat-cell *matCellDef="let element"

                            class="table-detail-style-override">{{element.station}}</td>
                    </ng-container>

                    <!-- DaysInStatus -->
                    <ng-container matColumnDef="daysInStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="text-align: center;min-width: 20px;">Days in Status
                        </th>
                        <td mat-cell
                            style="text-align: center"
                            *matCellDef="let element"
                            class="table-detail-style-override">{{element.daysInStatus}}</td>
                    </ng-container>

                    <ng-container matColumnDef="itemStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="table-header-style-override"
                            style="min-width: 80px">Status
                        </th>
                        <td mat-cell *matCellDef="let element"
                            class="table-detail-style-override">
                            <span style="padding-left: 3px;">{{element.itemStatus}}</span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                        [ngClass]="{'row-selected': tableRowIdx == Idx}"
                        (click)="onRowClicked(row)" (dblclick)="onRowDoubleClicked(row)">
                    </tr>
                </table>
            </div>
            <!-------------------------------------->
            <!-- Control Strip                    -->
            <!-------------------------------------->
            <div class="mat-elevation-z2 control-strip">
                <button *ngIf="visibleAdd" mat-button [disabled]="(Idx < 0)"
                        matTooltipPosition="above"
                        class="command-button" matTooltip="Complete batch process"
                        (click)="finishBatchClicked()" mat-button>
                    <mat-icon>flag</mat-icon>
                    Finish
                </button>
                <button *ngIf="visibleModify" mat-button class="command-button"
                        matTooltipPosition="above"
                        style="margin-top: 5px;" [disabled]="(Idx < 0)"
                        matTooltip="UChange / assign a site and station" (click)="changeAssignmentsClicked()"
                        mat-button>
                    <mat-icon>assignment</mat-icon>
                    Change
                </button>
            </div>
            <!------------------------------->
            <!--  Detail panel             -->
            <!------------------------------->
            <div id="preparation-details-panel" class="preparation-detail-container mat-elevation-z2"
                 style="margin-bottom: 2px;">
                <mat-tab-group animationDuration="0ms" (selectedTabChange)="doTabChange($event)"
                               [(selectedIndex)]=selectedTabIndex>
                    <mat-tab label="Order Details" [disabled]="isEditing">

                        <div class="sub-tab-container-class detail-tab-additional" style="padding-left:13px">
                            <div class="dwform" style="padding-right: 15px;">

                                <div class="dwform-cell-nopadding item-detail-columns">

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Customer:</label>
                                        <span class="form-required"> </span>

                                        <input mat-input [disabled]="true"
                                               class="input-field-shortest" style="width:70px"
                                               [(ngModel)]="selectedRecord.customerNumber">

                                        <input mat-input [disabled]="true"
                                               class="input-field" style="width: 200px"
                                               [(ngModel)]="selectedRecord.customerName">
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row" >
                                        <label class="label-field">Ordered By:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true"
                                               class="input-field"
                                               [(ngModel)]="selectedRecord.orderedBy">
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row" >
                                        <label class="label-field">Telephone:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true"
                                               class="input-field"
                                               [(ngModel)]="selectedRecord.customerTelephone">
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row" >
                                        <label class="label-field">Email:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true"
                                               class="input-field"
                                               [(ngModel)]="selectedRecord.customerEmail">
                                    </div>
                                </div>

                                <!-- Second column --->
                                <div class="dwform-cell-nopadding item-detail-columns">

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Order Number:</label>
                                        <span class="form-required"> </span>

                                        <input mat-input [disabled]="true"
                                               class="input-field-shortest" style="width:70px"
                                               [(ngModel)]="selectedOrder.orderNumber">

                                        <input mat-input [disabled]="true"
                                               class="input-field" style="width: 200px"
                                               [(ngModel)]="selectedOrder.orderStatus">
                                    </div>

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Order Reference:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true" class="input-field"
                                               [(ngModel)]="selectedOrder.customerReference">
                                    </div>


                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field"
                                               style="vertical-align: top;padding-top: 8px;">Order Comments:</label>
                                        <span class="form-required"> </span>

                                        <textarea mat-input [disabled]="true"
                                                  class="input-field"
                                                  style="height: 100px; font-family: roboto;resize: none"
                                                  [(ngModel)]="selectedOrder.orderComments">
                                        </textarea>
                                    </div>
                                </div>

                                <!-- third column --->
                                <div class="dwform-cell-nopadding item-detail-columns">

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Submit Method:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true"
                                               class="input-field-short"
                                               [(ngModel)]="selectedOrder.submitMethod">
                                    </div>

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Created Date :</label>
                                        <span class="form-required"> </span>

                                        <input mat-input [disabled]="true" class="input-field-short"
                                               [(ngModel)]="selectedOrder.dateCreated">
                                    </div>

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Date Submitted:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true" class="input-field-short"
                                               [(ngModel)]="selectedOrder.dateSubmitted">
                                    </div>

                                    <div class="dwform-row-gap"></div>
                                    <div class="dwform-row" >
                                        <label class="label-field">Date Closed:</label>
                                        <span class="form-required"> </span>
                                        <input mat-input [disabled]="true" class="input-field-short"
                                               [(ngModel)]="selectedOrder.dateClosed">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="History" [disabled]="isEditing" >
                        <div class="sub-tab-container-class detail-tab-additional">
                            <transaction-history #history></transaction-history>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-drawer-container>
</div>

<p-dialog header="Finish Batch: {{selectedRecord.bprId}}"
          [appendTo]="'body'"
          [(visible)]="displayFinishBatchDlg"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '1000px', height: '740px'}"
          [minY]="75" [resizable]="false" [closable]="false">
    <app-finishbatch #finishBatch
          [orderNumber]="selectedOrder.orderNumber"
          [selectedForm]="selectedRecord"
          [stationsList]="stationsList"
          (cancelClicked)="displayFinishBatchDlg=false"
          (submitClicked)="finishBatchSubmitted()">
    </app-finishbatch>
</p-dialog>

<p-dialog header="Change Assignments: {{selectedRecord.customerName}}"
          [appendTo]="'body'"
          [(visible)]="displayChangeAssignmentsDlg"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '500px', height: '300px'}"
          [minY]="75" [resizable]="false" [closable]="true">
    <app-changeassignments #changeAssignments
          [sitesList]="sitesList"
          [stationsList]="stationsList"
          (cancelClicked)="displayChangeAssignmentsDlg=false"
          (submitClicked)="changeAssignmentsSubmitted($event)">
    </app-changeassignments>
</p-dialog>
