/************
 * Model Class
 * File Name ...: states.components.ts
 * Generated By : ianday on 2/24/23
 * Date ........: 2/24/23
 /************/

export class CustomerTypes {
     public types = [
        'Dental Practice',
        'Retail Pharmacy',
        'Sports Medicine',
        'Clinic / Practice',
        'Non Profit Clinic',
        'Other'];
}
