import { Component, OnInit } from '@angular/core';
import { Settings } from '../../../services/settings';
import { GeneralSetting } from '../../../models/generalsetting';
import { UserService } from '../../../services/user.service';
import { GlobalService } from '../../../services/global-service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-generalsettings',
	templateUrl: './generalsettings.component.html',
	styleUrls: ['./generalsettings.component.css']
})
export class GeneralsettingsComponent implements OnInit {
	formTitle = 'General Settings';
	valBannerText = '';
	valBackColor = '#ffff00';
	bannerSetting: GeneralSetting;
	bgColorSetting: GeneralSetting;
	bannerEnableSetting: GeneralSetting;
	valEnableBanner = false;
	displaySavedMessage = false;
	strMessage = '';
	editMode = false;
	constructor(private datePipe: DatePipe, private userService: UserService, private globalService: GlobalService) { }

	ngOnInit() {
		this.queryRecords();
	}
	queryRecords() {
		/* if (Settings.getInstance().strBannerText === '' || Settings.getInstance().strBannerText === undefined) {
			 const date = new Date();
			 Settings.getInstance().strBannerText = this.datePipe.transform(date, 'MM/dd/yyyy HH:mm:ss');
		 }
		 */
		this.bannerSetting = Settings.getInstance().getGeneralSettingByKey('bannertext');
		this.bgColorSetting = Settings.getInstance().getGeneralSettingByKey('bannerbgcolor');
		this.bannerEnableSetting = Settings.getInstance().getGeneralSettingByKey('bannerenable');
		this.valEnableBanner = Settings.getInstance().enableMessageBanner;
		if (this.bannerSetting === undefined || this.bannerSetting === null) {
			this.bannerSetting = new GeneralSetting();
		}
		if (this.bgColorSetting === undefined || this.bgColorSetting === null) {
			this.bgColorSetting = new GeneralSetting();
		}
		if (this.bannerEnableSetting === undefined || this.bannerEnableSetting === null) {
			this.bannerEnableSetting = new GeneralSetting();
		}

		this.valBannerText = this.bannerSetting.value;
		this.valBackColor = this.bgColorSetting.value;
		if (this.bannerEnableSetting.value === 'true') {
			this.valEnableBanner = true;
		} else {
			this.valEnableBanner = false;
		}
		this.strMessage = '';
	}
	doSubmit() {
		this.strMessage = '';
		this.bannerSetting.key = 'bannertext';
		this.bannerSetting.value = this.valBannerText;
		this.userService.createUpdateSetting(this.bannerSetting).subscribe(
			(data1) => {
				this.bgColorSetting.key = 'bannerbgcolor';
				this.bgColorSetting.value = this.valBackColor;
				this.userService.createUpdateSetting(this.bgColorSetting).subscribe(
					(data2) => {
						this.bannerEnableSetting.key = 'bannerenable';
						this.bannerEnableSetting.value = this.valEnableBanner.toString();
						this.userService.createUpdateSetting(this.bannerEnableSetting).subscribe(
							(data3) => {
								if (this.valEnableBanner) {
									Settings.getInstance().enableMessageBanner = true;
								} else {
									Settings.getInstance().enableMessageBanner = false;
								}
								this.globalService.globalEvent.next('SETTINGS_REFRESH');
								//this.displaySavedMessage = true;
								this.strMessage = "Settings saved."
								this.editMode = false;
							},
							(error3) => {

							}
						);
					},
					(error2) => {

					}
				);
			},
			(error1) => {

			}
		);

		Settings.getInstance().strBannerText = this.valBannerText;
	}
	doCancel() {
		this.queryRecords();
		this.editMode = false;
	}
	enterEditMode() {
		this.editMode = true;
		this.strMessage = '';
	}
}
