/************
 * Service Class
 * File Name ...: customers.service.ts
 * Generated By : ianday on 3/1/23
 * Date ........: 3/1/23
 /************/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Customers } from '../models/customers';
import { CustomerLicenses } from '../models/customerlicenses';
import { Settings } from './settings';
import { LicenseStateURLs } from '../models/licensestateurls';

@Injectable({
	providedIn: 'root'
})
export class CustomersService {

	public CustomerURLPath: string = Settings.getInstance().BASE_URL + 'customers/customers';
	public CustomerLicenseURLPath: string = Settings.getInstance().BASE_URL + 'customers/customerlicenses';
	public LicenseStateURLURLPath: string = Settings.getInstance().BASE_URL + 'customers/licensestateurl';

	constructor(private http: HttpClient) {
	}

	getAllCustomers(): Observable<any> {
		return this.http.get<any>(this.CustomerURLPath)
			.pipe(
				retry(1)
			);
	}

	getActiveCustomers(): Observable<any> {
		return this.http.get<any>(this.CustomerURLPath + '/active')
			.pipe(
				retry(1)
			);
	}
	createCustomers(record: Customers): Observable<any> {
		return this.http.post(this.CustomerURLPath, record)
			.pipe(
				retry(1)
			);
	}

	updateCustomers(record: Customers): Observable<any> {
		return this.http.put(this.CustomerURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}

	deleteCustomers(id: number): Observable<any> {
		return this.http.delete(this.CustomerURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}

	getAllCustomerLicenses(id: number): Observable<any> {
		return this.http.get<any>(this.CustomerLicenseURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	createCustomerLicense(record: CustomerLicenses): Observable<any> {
		return this.http.post(this.CustomerLicenseURLPath, record)
			.pipe(
				retry(1)
			);
	}
	updateCustomerLicense(record: CustomerLicenses): Observable<any> {
		return this.http.put(this.CustomerLicenseURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteCustomerLicense(id: number): Observable<any> {
		return this.http.delete(this.CustomerLicenseURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	getLicenseStateURL(type: string, state: string): Observable<any> {
		return this.http.get<any>(this.LicenseStateURLURLPath + '/' + type + '/' + state)
			.pipe(
				retry(1)
			);
	}
	createLicenseStateURL(record: LicenseStateURLs): Observable<any> {
		return this.http.post(this.LicenseStateURLURLPath, record)
			.pipe(
				retry(1)
			);
	}
	updateLicenseStateURL(record: LicenseStateURLs): Observable<any> {
		return this.http.put(this.LicenseStateURLURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteLicenseStateURL(id: number): Observable<any> {
		return this.http.delete(this.LicenseStateURLURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	uploadLicenseFile(record: CustomerLicenses, license: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('license', license, license.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'customers/customerlicenses/file/' + record.id, formData)
			.pipe(
				retry(0)
			);
	}
	deleteLicenseFile(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'customers/customerlicenses/file/' + id)
			.pipe(
				retry(1)
			);
	}
	getLicenseFile(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'customers/customerlicenses/file/' + id)
			.pipe(
				retry(1)
			);
	}
}
