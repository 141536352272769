/************
 * Model Class
 * File Name ...: licensestateurls.components.ts
 * Generated By : ianday on 3/10/23
 * Date ........: 3/10/23
 /************/
import {ValidationResult} from './validation-result';

export interface LicenseStateURLs {
    id: number | null;
    licenseType: string | null;
    practiceState: string | null;
    url: string | null;
}

export class LicenseStateURLs {
    constructor() {
        this.id = 0;
        this.licenseType = '';
        this.practiceState = '';
        this.url = '';
    }
}
