/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: medication-family.component.ts
 * Generated By : Toji Mathew on 3/8/2023
 /************/

import { Component, OnInit, ViewChild, HostListener, AfterContentInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Settings } from '../../../services/settings';
import { MedicationFamily } from '../../../models/medicationfamily';
import { MedicationService } from '../../../services/medication.service';
import { ValidationResult } from '../../../models/validation-result';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TransactionHistoryService } from '../../../services/transactionhistory.service';
import { TransactionHistoryComponent } from '../../../components/transaction-history/transaction-history';
import { DwUtils } from '../../../components/utils/dw-utils';
export class ClassRecord {
	public id: number;
	public description: string;
	public itemPrefix: string;
}

@Component({
	selector: 'app-medication-family',
	templateUrl: './medication-family.component.html',
	styleUrls: ['./medication-family.component.css']
})


export class MedicationFamilyComponent implements OnInit, AfterContentInit {

	/*** Header / Query Strip Variables ****/
	public formTitle = 'Medication Family';
	public pagination: string;

	/*** Editing variables ***/
	public isEditing: boolean | null = false;
	public isAdd: boolean | null = false;
	public records: MedicationFamily[];
	public selectedRecord: MedicationFamily;
	public historyRecord: MedicationFamily;
	public Idx: number | null = -1;
	public strErr: string | null = '';
	public dataSource: MatTableDataSource<MedicationFamily>;
	public renderedData: Array<any>;
	public result: boolean | null = false;
	public disabledFlag: boolean | null = true;
	public activeCellId: string | null = '';

	/**** Query strip filter settings ***/
	public selectedStatusFilter: string | null = 'Active';
	public selectedViewFilter: string | null = 'List';
	public contentClassWithBanner: string | null = 'dwtable-container';
	public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
	public contentClassName: string | null = 'dwtable-container';

	/*** Record Table / card table  ***/
	public displayedColumns: string[] = ['id', 'itemPrefix', 'description', 'invWarningThreshold', 'expirationGraceDays'];

	/***** Control Strip Button Visibility ***/
	public visibleAdd: boolean | null = false;
	public visibleModify: boolean | null = false;
	public visibleDelete: boolean | null = false;

	/***** Tab Detail Section *****/
	public selectedTabIndex: number | null = 0;
	public id: any | null = '';
	public description: any | null = '';
	public itemPrefix: any | null = '';
	public invWarningThreshold: number | null = 0;
	public expirationGraceDays: number | null = 0;
	/***** Side drawer ****/
	public propContainerState: boolean | null = false;
	public visiblePropertiesTab: boolean | null = true;
	public visibleNotesTab: boolean | null = true;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('history', { static: true }) history: TransactionHistoryComponent;

	constructor(private medicationService: MedicationService, private txService: TransactionHistoryService,
		public dialog: MatDialog) {
		this.selectedRecord = new MedicationFamily();
	}

	ngOnInit() {
		this.visibleAdd = true;
		this.visibleModify = true;
		this.visibleDelete = true;
	}
	ngAfterContentInit() {

		this.queryRecords();

		if (Settings.getInstance().enableMessageBanner) {
			this.contentClassName = this.contentClassWithBanner;
		} else {
			this.contentClassName = this.contentClassWithoutBanner;
		}
	}

	/*** Query Records from the Service ***/
	queryRecords() {

		this.medicationService.getAllMedicationFamily().subscribe(
			(result) => {
				this.records = result;
				this.dataSource = new MatTableDataSource(this.records);
				this.dataSource.sort = this.sort;
				this.setPagination(-1, this.dataSource.data.length);
			},
			(error) => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			}
		);
	}
	/*** Display the number of records from the total ***/
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}

	onStatusChange() {

		this.medicationService.updateMedicationFamily(this.selectedRecord).subscribe(
			(data) => {
				// no user feedback, just apply the filter ...
			});
	}
	doTabChange(event): void {

	}

	@HostListener('matSortChange', ['\$event'])
	onSortChange(event: any) {

		if (event.direction === 'asc') {
			this.records.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.records.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSource.data = this.records;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	clearFilter() {
		if (this.isEditing) {
			return;
		}
		this.unSelectRow();
		this.dataSource.filter = '';
		this.selectRow();
		this.setPagination(-1, this.dataSource.data.length);
	}

	applyFilter(filterValue: string) {
		if (this.isEditing) {
			return;
		}
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.unSelectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}

	selectRow() {
		if (this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
	}

	unSelectRow() {

		this.Idx = -1;
		this.selectedRecord = new MedicationFamily();
		this.setPagination(this.Idx, this.dataSource.data.length);
		this.displayDetail(this.selectedRecord);

	}
	displayDetail(record: MedicationFamily) {
		if (record === undefined) {
			return;
		}
		this.id = record.id;
		this.description = record.description;
		this.itemPrefix = record.itemPrefix;
		this.invWarningThreshold = record.invWarningThreshold;
		this.expirationGraceDays = record.expirationGraceDays;

		this.history.onShowHandler('medication-family', 'id', record.id.toString());
	}
	clear() {
		this.id = '';
		this.description = '';
		this.invWarningThreshold = 0;
		this.expirationGraceDays = 0;
		this.itemPrefix = '';

	}

	addModifyRecord(isAdd: boolean) {
		this.isAdd = isAdd;
		this.isEditing = true;
		this.selectedTabIndex = 0;

		if (this.isAdd) {
			this.clear();
			this.unSelectRow();
			this.expirationGraceDays = 30;
			this.invWarningThreshold = 20;
			this.id = 0;
		}
		else {
			// store the history record
			this.historyRecord = this.selectedRecord;
		}
		DwUtils.setFocus('description');
	}

	removeRecord() {
		const message = 'Remove Medication Family: "' + this.selectedRecord.itemPrefix + '" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {
				this.medicationService.deleteMedicationFamily(this.selectedRecord.id).subscribe(
					(data) => {
						// remove the row from the table.

						this.records.splice(this.Idx, 1);
						this.dataSource.data = this.records;
						this.clear();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					});
			}
		});
	}
	onPropertiesToggle() {
		this.propContainerState = !this.propContainerState;
	}
	tableKeyDown(event: KeyboardEvent) {
		if (this.isEditing) {
			return;
		}

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		}
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}

	validateFields(): boolean {

		this.strErr = '';
		const vr: ValidationResult = this.selectedRecord.validateRecord();

		if (vr.error.length > 0) {
			this.strErr = vr.error;
			DwUtils.setFocus(vr.focus_field);
			return false;
		}
		if (this.isDuplicateItemNumber(this.selectedRecord.itemPrefix)) {
			this.strErr = 'Error: a duplicate Item number was entered';
			DwUtils.setFocus('itemPrefix');
			return false;
		}
		return true;
	}
	isDuplicateItemNumber(paramItamNumber: any) {
		for (const rec of this.records) {
			if (this.isAdd) {
				if (rec.itemPrefix === paramItamNumber) {
					return true;
				}
			} else {
				if ((rec.id !== this.selectedRecord.id) && rec.itemPrefix === paramItamNumber) {
					return true;
				}
			}
		}
		return false;
	}
	setRecord(): void {
		this.selectedRecord = new MedicationFamily();
		this.selectedRecord.id = this.id;
		this.selectedRecord.itemPrefix = this.pad(this.itemPrefix, 4);
		this.selectedRecord.description = this.description;
		this.selectedRecord.invWarningThreshold = this.invWarningThreshold;
		this.selectedRecord.expirationGraceDays = this.expirationGraceDays;
	}
	submitRecord() {

		this.setRecord();
		this.strErr = '';

		if (!this.validateFields()) {
			return;
		}
		if (this.isAdd === true) {
			this.medicationService.createMedicationFamily(this.selectedRecord).subscribe(
				(data) => {
					this.selectedRecord.id = data.id;
					this.displayDetail(this.selectedRecord);
					this.isEditing = false;
					/*** add a transaction history record ***/
					this.txService.createTransactionHistory('medication-family', this.selectedRecord.id);
					this.updateSelectedRow();
				},
				(error) => {
					this.strErr = 'Error: The server could not add/submit this record';
					return;
				}
			);
		}
		else {

			this.medicationService.updateMedicationFamily(this.selectedRecord).subscribe(
				(data) => {
					this.isEditing = false;
					this.updateSelectedRow();
				},
				(error) => {
					this.strErr = 'Error: The server could not update this record';
					this.selectedRecord = this.historyRecord;
					return;
				});

			/*** add a transaction history record ***/
			this.txService.updateTransactionHistory(this.historyRecord,
				this.selectedRecord, 'medication-family', this.selectedRecord.id);
		}

	}

	/* Update the record in the table's data source - without refreshing it */
	updateSelectedRow(): void {
		if (this.isAdd === true) {
			// add the new record to the top.
			this.records.unshift(this.selectedRecord);
			this.dataSource.data = this.records;
			this.Idx = 0;
		}
		else {
			const i: number = this.records.findIndex(r => r.id === this.selectedRecord.id);
			this.records[i] = this.selectedRecord;
			this.dataSource.data = this.records;
		}
		this.dataSource.sort = this.sort;
	}

	onRowClicked(selected: any) {

		if (this.isEditing === true || Settings.getInstance().isEditing === true)
			return;

		this.selectedRecord = selected;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
		this.displayDetail(this.selectedRecord);
	}

	cancelSubmitRecord() {
		this.strErr = '';
		this.isEditing = false;
		this.displayDetail(this.selectedRecord);
	}
	addLeadingZero(event: any) {
		// get maxlength attr
		const maxLength = parseInt(event.target.getAttribute("maxlength"));
		// "0".repeat(maxLength) <-- create default pad with maxlength given
		// append zero and slice last of attr maxlength value
		const newValue = ("0".repeat(maxLength) + event.target.value.toString()).slice(-maxLength);
		// change the value of input
		event.target.value = newValue;
	}
	pad(num: number, size: number): string {
		let s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	}
}
