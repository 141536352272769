import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Notes } from '../models/notes';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private http: HttpClient) { }

  createNote(record: Notes): Observable<any> {
      return this.http.post(Settings.getInstance().BASE_URL + 'notes/notes', record)
      .pipe(
        retry(1)
      );
  }
  updateNote(record: Notes): Observable<any> {
        return this.http.put(Settings.getInstance().BASE_URL + 'notes/notes/' + record.id , record)
            .pipe(
                retry(1)
            );
    }
  deleteNote(id: number): Observable<any> {
      return this.http.delete(Settings.getInstance().BASE_URL + 'notes/notes/' + id)
      .pipe(
        retry(1)
      );
  }
  getNotesByForeignKeyId(foreignKeyId: number, foreignKeyTable: string): Observable<Notes> {
      return this.http.get<Notes>(Settings.getInstance().BASE_URL + 'notes/notes/' + foreignKeyTable + '/' + foreignKeyId)
      .pipe(
        retry(1)
      );
  }
}
