import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../authservice/auth.service';

@Injectable({providedIn: 'root'})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                if(err.url.indexOf("users/login") == -1 && err.url.indexOf("users/me") == -1) {
                    this.authenticationService.logout();
                    location.reload();
                }
            }
			if(err.status === 404 && err.url.indexOf("users/me") != -1) {
				//location.reload();
			}
            //const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}