/************
 * Model Class
 * File Name ...: purchaseorders.components.ts
 * Generated By : ianday on 5/12/23
 * Date ........: 5/12/23
 /************/


export interface OrderNumbers {
    id: number;
    orderNumber: string;
    dateRequested: string;
}

export class OrderNumbers {

    constructor() {
        this.id = 0;
        this.orderNumber = '';
        this.dateRequested = new Date().toISOString();
    }
}
