<div class="workspace-container">
	<div class="query-strip-container mat-elevation-z2">
		<table height="100%" width="100%">
			<TR>
				<TD class="table-title-cell"><i
					class="fa  fa-medkit fa-fw table-icon-header" style="width: 2em"></i>{{formTitle}}
				</TD>
				<TD style="width: 250px; min-width: 250px;"></TD>
				<TD align="left" style="min-width: 400px;"><input text
					id="txtFilter" #txtFilter class="qs_filter-field"
					[disabled]="this.isEditing"
					(keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
					<button mat-button matSuffix mat-icon-button
						matTooltip="Clear the filter" aria-label="Clear"
						(click)="txtFilter.value=''; clearFilter();">
						<mat-icon>close</mat-icon>
					</button></TD>
				<TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
				<TD style="width: 150px">
						<mat-select disableOptionCentering
							[(ngModel)]="selectedStatusFilter" #cbStatus floatLabel="never"
							style="font-size: 10pt;width: 120px;"
							panelClass="local-drop-down-panel" class="dw-mat-select"
							(selectionChange)="onChangeStatusFilterValue($event)"
							(keydown)="onStatusFilterKeyDown($event)">
						<mat-option *ngFor="let item of statusFilterList"
								[value]="item">{{item.description}}
						</mat-option>
						</mat-select>

					</TD>
				<TD class="table-pagination-cell" align="right">{{pagination}}
					Record(s) ...</TD>
			</TR>
		</table>
	</div>

	<mat-drawer-container autosize> <mat-drawer
		position="end" opened="true" class="mat-elevation-z2 drawer-form"
		style="border: .5px solid #e1e1e1" #drawer mode="side"
		disableClose="true">
	<div style="max-width: 455px; padding: 2px;">
		<!-- tab closed state -->
		<table [hidden]="!propContainerState"
			class="prop-notes-outer-container">
			<TR>
				<TD align="left" style="width: 35px; padding: 0;">
					<button id="contract-toggle-drawer-close-btn" mat-icon-button
						(click)="onPropertiesToggle()">
						<mat-icon>arrow_right</mat-icon>
					</button>
				</TD>
				<TD width="100%" style="text-align: left;"><b>Additional
						Properties</b></TD>
				<TD width="35px"></TD>
			</TR>
		</table>
		<!-- tab open State -->
		<div [hidden]="!propContainerState"
			style="width: 450px; height: calc(100% - 60px);">
			<mat-tab-group class="prop-tab-group"> 
			 <mat-tab label="Notes">
                         <app-notes style="border-top: .5px solid #e1e1e1"
                              [callingComponent]="'materials'"
                              [parentId]="selectedRecord?.id">
                         </app-notes>
                    </mat-tab>
			<mat-tab
				label="Suppliers" *ngIf="visibleSuppliersTab"> <app-material-suppliers
				[Material]="selectedRecord"></app-material-suppliers> </mat-tab> </mat-tab-group>
		</div>
	</div>
	<div>
		<!-- retracted state -->
		<div [hidden]="propContainerState" width="100%">
			<button id="contract-toggle-drawer-open-btn" mat-icon-button
				(click)="onPropertiesToggle()">
				<mat-icon>arrow_left</mat-icon>
			</button>
		</div>

		<div class="rotate-text" [hidden]="propContainerState">
			Additional Properties</div>
	</div>
	</mat-drawer>

	<div class="main-client-area">
		<div #tb1 class="table-container mat-elevation-z1"
			(keydown)="tableKeyDown($event)" tabindex="-1">
			<table  mat-table
				[dataSource]="dataSource" (keydown)="tableKeyDown($event)"
				(matSortChange)="onSortChange($event)" matSort>

				<!--- Note that these columns can be defined in any order.
		          The actual rendered columns are set as a property on
		          the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Id</th>
				<td mat-cell style="width: 4% !important;"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.id}}</td>
				</ng-container>

				<ng-container matColumnDef="partNumber">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style">Part Number</th>
					<td mat-cell style="width: 8% !important;" *matCellDef="let element">{{element.partNumber}}</td>
				</ng-container>

				<ng-container matColumnDef="handle">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Handle</th>
				<td mat-cell style="width: 25% !important;" *matCellDef="let element">{{element.handle}}</td>
				</ng-container>

				<ng-container matColumnDef="serialnumber">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style">Serial Number</th>
					<td mat-cell style="width: 10%;" *matCellDef="let element">{{element.serialNumber}}</td>
				</ng-container>

				<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Type</th>
				<td mat-cell style="width: 8%;" *matCellDef="let element">{{element.itemType}}</td>
				</ng-container>

				<ng-container matColumnDef="packageSize">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Pkg size</th>
				<td mat-cell style="width: 5%; text-align: center;"
					*matCellDef="let element">{{element.packageSize}}</td>
				</ng-container>

				<ng-container matColumnDef="costBase">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Cost Base</th>
				<td mat-cell style="text-align: right; padding-right: 65px; width: 5%;" *matCellDef="let element">$
					{{element.costBase}}</td>
				</ng-container>

				<ng-container matColumnDef="awp">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">AWP</th>
				<td mat-cell style="text-align: right; padding-right: 75px; width: 5%; " *matCellDef="let element">$
					{{element.awp}}</td>
				</ng-container>

				<ng-container matColumnDef="activeStatus">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style">Status</th>
					<td mat-cell style="width: 10%;" *matCellDef="let element">{{element.activeStatus}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">
				</tr>
			</table>
		</div>
		<div class="mat-elevation-z2 control-strip">
			<button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
				class="command-button" matTooltip="Add a new record"
				(click)="addModifyRecord(true)" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add New
			</button>
			<button *ngIf="visibleModify" mat-button class="command-button"
				style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
				matTooltip="Update a selected record"
				(click)="addModifyRecord(false)" mat-button>
				<mat-icon>edit</mat-icon>
				Update
			</button>
			<button *ngIf="visibleDelete" mat-button class="command-button"
				style="margin-top: 5px;"
				[disabled]="(Idx < 0 || this.isEditing || this.selectedRecord.activeStatus === 'Deleted')"
				matTooltip="Remove a selected record" (click)="removeRecord()"
				mat-button>
				<mat-icon>remove_circle_outline</mat-icon>
				Remove
			</button>
		</div>
		<!-- details pane -->
		<div id="medication-details-panel"
			class="medication-inv-container mat-elevation-z2"
			style="margin-bottom: 2px;">
			<mat-tab-group animationDuration="0ms"
				(selectedTabChange)="doTabChange($event)"
				[(selectedIndex)]=selectedTabIndex> <mat-tab
				label="Details" [disabled]="isEditing">
			<div class="sub-tab-container-class detail-tab-additional">
				<div class="dwform" style="padding-right: 15px;padding-left: 5px;">

					<div class="dwform-cell-nopadding" style="min-width: 500px;">
						<div class="dwform-row-double-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Record ID:</label> <span
								class="form-required"> </span> <input class="input-field-short"
								style="width: 80px" disabled="true" [(ngModel)]="valId"
								type="text">
						</div>

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Part Number:</label> <span
								class="form-required"></span> <input class="input-field"
									style="width: 357px; text-transform: uppercase" id="partNumber"
									[disabled]="!isEditing" [(ngModel)]="valPartNumber"
									placeholder="<part number>" type="text" >
						</div>

						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Handle:</label>
							<span class="form-required"></span>
							<input class="input-field-long" id="handle"
								style="width: 357px; text-transform: uppercase"
								[disabled]="!isEditing" [(ngModel)]="valHandle"
								placeholder="<handle>" type="text" >
						</div>

						<div class="dwform-row-gap"></div>
					  	<div class="dwform-row">
							<label class="label-field">Description:</label> <span
								class="form-required" style="display: table-cell; vertical-align: top"></span>

							<textarea class="input-field" placeholder="<description>"
								[disabled]="!isEditing"
								style="font-family: Roboto; height: 60px; width: 357px;margin-left:4px; resize: none"
								[(ngModel)]="valDesc"></textarea>
						</div>
						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Serial No:</label> <span
								></span> <input class="input-field"
								[disabled]="!isEditing" [(ngModel)]="valSerialNumber"
								placeholder="<serial number>" type="text" style="width: 200px;;margin-left:8px;text-transform: uppercase">
						</div>
						
						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Item Type:</label> <span
								></span>
							<mat-select [disabled]="!isEditing" [(ngModel)]="valItemType"
								id="status" placeholder="- Please Select -"
								disableOptionCentering floatLabel="never"
								panelClass="local-drop-down-panel" class="dw-mat-select"
								style="width: 167px;font-size: 10pt;margin-left:8px;"
								[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
								 <mat-option *ngFor="let item of itemTypeList" [value]="item.type" [matTooltip]="item.eg">
	                				{{item.type}} 
	              				 </mat-option>   
              				</mat-select>
						</div>
					</div>

					<div class="dwform-cell">

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Status:</label> <span
								class="form-required">*</span>

							<mat-select [disabled]="!isEditing" [(ngModel)]="valStatus"
										id="status" placeholder="- Please Select -"
										disableOptionCentering floatLabel="never"
										panelClass="local-drop-down-panel" class="dw-mat-select"
										style="width: 167px;font-size: 10pt;"
										[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
								<mat-option value="Active">Active</mat-option>
								<mat-option	value="InActive">InActive</mat-option>
								<mat-option	value="Deleted">Deleted</mat-option>
							</mat-select>
						</div>


						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Pkg Size:</label> <span
								class="form-required">*</span> <input class="input-field"
								[disabled]="!isEditing" id="packagesize" placeholder="<size>"
								[(ngModel)]="valPkgSize" type="text" style="width: 200px">
						</div>
						<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Cost Base:</label> <span
									class="form-required"></span>
								<p-inputNumber [inputStyleClass]="'input-field'"
									[disabled]="!isEditing" placeholder="<cost base>"
									[(ngModel)]="valCostBase" mode="currency" currency="USD"
									locale="en-US" type="text"
									[inputStyle]="{'width': '200px', 'background': isEditing?'white':'inherit'}"></p-inputNumber>
							</div>

							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Avg Wholesale Price:</label> <span
									class="form-required"></span>
								<p-inputNumber [inputStyleClass]="'input-field'"
									[disabled]="!isEditing" placeholder="<price>"
									[(ngModel)]="valAWP" mode="currency" currency="USD"
									locale="en-US" type="text"
									[inputStyle]="{'width': '200px', 'background': isEditing?'white':'inherit'}"></p-inputNumber>
							</div>
							<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Threshold Level:</label>
							<span class="form-required">*</span>
							<input id="inv_warning_threshold" class="input-field-shortest"
								   style="width: 55px;"
								   [disabled]="!isEditing"
								   matTooltip="Show a warning when inventory reaches this level"
								   [(ngModel)]="valInvWarningThreshold"
								   placeholder="<Number>"
								   type="number" max="100" min="0"><span>%</span>
						</div>
						<div class="dwform-row-gap"></div>
					</div>
				</div>
				<!-- ends the form -->
				<div [hidden]='!strErr.length' style="left: 10px;"
					class="error-line">{{strErr}}</div>
				<!-- save strip -->
				<div class="save-close-strip" style="bottom: 5px;">

					<button mat-button class="command-button" *ngIf="this.isEditing"
						(click)="submitRecord()" matTooltip="Submit the selected record"
						mat-button>
						<mat-icon>check_circle_outline</mat-icon>
						Submit
					</button>
					<button mat-button class="command-button" *ngIf="this.isEditing"
						(click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
						<mat-icon>highlight_off</mat-icon>
						Cancel
					</button>
				</div>
			</div>
			</mat-tab> 
			 <mat-tab label="History" [disabled]="isEditing">
			<div class="sub-tab-container-class detail-tab-additional">
				<transaction-history #history></transaction-history>
			</div>
			</mat-tab> </mat-tab-group>
		</div>
	</div>
	</mat-drawer-container>
</div>

<div [hidden]='!strErr.length' style="left: 10px;"
		class="error-line">{{strErr}}</div>
	<!-- save strip -->
	<div class="save-close-strip" style="bottom: 5px;">

		<button mat-button class="command-button" *ngIf="this.isEditing"
			(click)="submitRecord()" matTooltip="Submit the selected record"
			mat-button>
			<mat-icon>check_circle_outline</mat-icon>
			Submit
		</button>
		<button mat-button class="command-button" *ngIf="this.isEditing"
			(click)="cancelSubmitRecord()" matTooltip="Cancel edit"
			mat-button>
			<mat-icon>highlight_off</mat-icon>
			Cancel
		</button>
</div>
