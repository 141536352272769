<div>
	<label class="control-label text-align-right" style="font-size: 13px; margin-right: 1rem;" for="listSearch">Filter:</label>

	<input mat-input id="listSearch" type="text" style="width:250px;"
		   [(ngModel)]="searchString"
           (keyup)="applyFilter($event.target.value)"
           [autofocus]="true" />&nbsp;

	<button type="button" class="btn" id="btnClear"
		(click)="searchString=''; applyFilter('');"></button>

	<mat-checkbox
				value="true"
				[(ngModel)]="showNotInvitedFlag"
                class="pull-right"
				(change)="toggleNotInvitedCheckBox($event)">
		Show 'Not Invited' Only
	</mat-checkbox>
</div>

<div class="user-grid-pane">
	<div class="table-container mat-elevation-z1"
	(keydown)="tableKeyDown($event)" tabindex="-1">

		<table mat-table [dataSource]="dataSource"  class="table-header-style-override">
			<!-- Select -->
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef
					class="table-header-style-override" style="width: 5px;">
					<mat-checkbox (click)="$event.stopPropagation()"
								  (change)="toggleAllSelected($event)"></mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let element;let tableRowIdx = index;"
					style="width:5px !important;font-size: 9pt">
					<mat-checkbox (click)="$event.stopPropagation();"
								  (change)="runChecked($event)"
								  [disabled]="false"
								  [value]="element.select" [(ngModel)]="element.select" >
					</mat-checkbox>
				</td>
			</ng-container>

			<!-- Name -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef
					class="table-header-style-override"
					style="width: 130px;">User Name
				</th>
				<td mat-cell
					class="table-detail-style-override"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.name}}</td>
			</ng-container>

			<!-- email Address -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef
					class="table-header-style-override"
					style="width: 130px;">Email Address
				</th>
				<td mat-cell
					class="table-detail-style-override"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.email}}</td>
			</ng-container>

			<!-- Invitation Status -->
			<ng-container matColumnDef="invitationStatus">
				<th mat-header-cell *matHeaderCellDef
					class="table-header-style-override"
					style="width: 80px;">Status
				</th>
				<td mat-cell
					class="table-detail-style-override"
					*matCellDef="let element;let tableRowIdx = index;">
					{{this.invitationStatusLabeler(element)}}</td>
			</ng-container>
			<!-- LastActivityDate -->
			<ng-container matColumnDef="LastActivityDate">
				<th mat-header-cell *matHeaderCellDef
					class="table-header-style-override"
					style="width: 130px;">Last Activity
				</th>
				<td mat-cell
					class="table-detail-style-override"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.LastActivityDate}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row
				*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
				[ngClass]="{'row-selected': tableRowIdx == Idx}"
				(click)="onRowClicked(row)" >
			</tr>
		</table>

	</div>

	<!-- ERROR LINE -->
	<div [hidden]='!strErr.length' class="error-line error-line-override">{{strErr}}</div>

	<!-- SAVE STRIP -->
    <div style="width:100%; text-align: center; margin-top: 1.3rem;">
      
      <button mat-button class="command-button" matTooltip="Send invitation to users"
			  [disabled]="!buttonsDisabled" (click)="sendInvite()">
		  <mat-icon>mail </mat-icon>
		  Send
	  </button>
      <button mat-button class="command-button" matTooltip="Close window"
			   (click)="doCancel()">
		  <mat-icon>highlight_off</mat-icon>
		  Close
	  </button>
    </div>
</div>
