<div>
	<img class="ui-image-dialog-titlebar"
		 style="margin-left: -23px;background: #337e89"
		 src="assets/prescript_icon_logo.png">
</div>

<div class="ui-g-9" style="width:100%; ">
	<div class="ui-g section-part">
		<div class="ui-g-4 ui-md-4">
			<label	style="color:Gray">VERSION:</label>
		</div>
		<div class="ui-g-8 ui-md-8">
			<label class="text-values">{{this.version}}</label>
		</div>
	</div>
	<div class="ui-g section-part">
		<div class="ui-g-4 ui-md-4">
			<label style="color:Gray">LAST UPDATED:</label>
		</div>
		<div class="ui-g-8 ui-md-8">
			<label class="text-values">{{this.last_updated}}</label>
		</div>
	</div>
	<div class="ui-g section-part">
		<div class="ui-g-4 ui-md-4">
			<label	style="color:Gray">RELEASE:</label>
		</div>
		<div class="ui-g-8 ui-md-8">
			<label class="text-values">{{this.release}}</label>
		</div>
	</div>

	<div class="ui-g" ></div>
	<br>
	<div style="width: 100%; color: grey; text-align:center;">
		DayWorks &copy; 2022/2023 - All rights reserved
	</div>
</div>

<hr>
<div class="buttonHolder">
		<button mat-button  style="width: 85px;" class="dialog-button" matTooltip="Close window" (click)="doCancel()">CLOSE</button>
</div>
