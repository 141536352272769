<!------------------------------------->
<!-- List tab detail layout.
 * File Name ..: customermaster.component.html
 * Generated by: ianday
 * Date .......: 3/1/23
--------------------------------------->
<div class="customermaster-form-container">
    <div class="query-strip-container mat-elevation-z2">
        <table height="100%">
            <TR>
                <TD class="table-title-cell">
                    <i class="fa fa-medkit fa-fw table-icon-header"
                       style="width: 2em"></i>{{formTitle}}
                </TD>

                <TD align="left" style="min-width: 400px;">
                    <input text id="txtFilter" #txtFilter
                           class="qs_filter-field" [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </TD>
                <TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
                <TD style="width: 150px">
                    <mat-select disableOptionCentering
                                [(ngModel)]="selectedStatusFilter" #cbStatus floatLabel="never"
                                style="font-size: 10pt;width: 120px;"
                                panelClass="local-drop-down-panel" class="dw-mat-select"
                                (selectionChange)="onChangeStatusFilterValue($event)"
                                (keydown)="onStatusFilterKeyDown($event)">
                        <mat-option *ngFor="let item of statusFilterList"
                                    [value]="item">{{item.description}}
                        </mat-option>
                    </mat-select>

                </TD>
                <TD class="table-pagination-cell" align="right">{{pagination}} Record(s) ...</TD>
            </TR>
        </table>
    </div>

    <mat-drawer-container autosize>
        <!-------------------------->
        <!-- Right hand drawer    -->
        <!-- Open state           -->
        <!-------------------------->
        <mat-drawer position="end" opened="true" class="mat-elevation-z2"
                    style="border: .5px solid #e1e1e1" #drawer
                    mode="side" disableClose="true">
            <div style="max-width: 400px; padding: 2px;">

                <table [hidden]="!propContainerState"
                       class="prop-notes-outer-container">
                    <TR>
                        <TD align="left" style="width: 35px; padding: 0;">
                            <button id="customermaster-toggle-drawer-close-btn" mat-icon-button
                                    (click)="onPropertiesToggle()">
                                <mat-icon>arrow_right</mat-icon>
                            </button>
                        </TD>
                        <TD width="100%" style="text-align: left;">
                            <b>Additional Properties</b>
                        </TD>
                        <TD width="35px"></TD>
                    </TR>
                </table>
                <div [hidden]="!propContainerState"
                     class="prop-menu-container" style="width:395px;">
                    <mat-tab-group class="prop-tab-group" style="height: calc(100% - 2px)">
                          <mat-tab label="Address Book" *ngIf="visiblePropertiesTab">
                            <app-addressbook style="border-top: .5px solid #e1e1e1"
                                   [callingComponent]="'customers'"
                                   [parentId]="selectedRecord?.id">
                            </app-addressbook>
                        </mat-tab>
                        <mat-tab label="Licenses" *ngIf="visibleLicensesTab">
                            <app-customerlicenses style="border-top: .5px solid #e1e1e1"
                                   [parentId]="selectedRecord?.id">
                            </app-customerlicenses>
                        </mat-tab>
                        <mat-tab label="Notes" *ngIf="visibleNotesTab">
                            <app-notes style="border-top: .5px solid #e1e1e1"
                                   [callingComponent]="'customers'"
                                   [parentId]="selectedRecord?.id">
                            </app-notes>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div>
                <!----------------------->
                <!-- Right hand Drawer -->
                <!-- retracted state ---->
                <!----------------------->
                <div [hidden]="propContainerState" width="100%">
                    <button id="customermaster-toggle-drawer-open-btn" mat-icon-button (click)="onPropertiesToggle()">
                        <mat-icon>arrow_left</mat-icon>
                    </button>
                </div>

                <div class="rotate-text" [hidden]="propContainerState">
                    Additional Properties
                </div>
            </div>
        </mat-drawer>

        <div class="main-client-area">
            <!--------------------------->
            <!-- RECORDS TABLE         -->
            <!--------------------------->
            <div #tb1 [ngClass]="contentClassName" class="mat-elevation-z1"
                 (keydown)="tableKeyDown($event)" tabindex="-1">
                <table mat-table [dataSource]="dataSource" matSort
                       (matSortChange)="onSortChange($event)">

                    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on
                  the row definition" -->

                    <!-- Position Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Id
                        </th>
                        <td mat-cell style="width:8% !important;"
                            *matCellDef="let element;let tableRowIdx = index;">
                            {{element.id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Customer Name
                        </th>
                        <td mat-cell style="width:35% !important;"
                            *matCellDef="let element">{{element.customerName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="customerType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Customer Type
                        </th>
                        <td mat-cell style="width:15% !important;"
                            *matCellDef="let element">{{element.customerType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="customerGroup">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Customer Group
                        </th>
                        <td mat-cell style="width:15% !important;"
                            *matCellDef="let element">{{element.customerGroup}}</td>
                    </ng-container>

                    <ng-container matColumnDef="activeStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-select *ngIf="selectedRecord?.id == element.id"
                                        disableOptionCentering
                                        [(value)]="element.activeStatus" #cbStatus floatLabel="never"
                                        class="dw-mat-select"
                                        panelClass="local-drop-down-panel"
                                        style="width:95px!important;"
                                        (selectionChange)="onStatusChange()"
                                        [disabled]="selectedRecord?.id !== element.id">
                                <mat-option value="Active">Active</mat-option>
                                <mat-option value="Prospect">Prospect</mat-option>
                                <mat-option value="InActive">InActive</mat-option>
                            </mat-select>
                            <span *ngIf="selectedRecord?.id !== element.id"
                                  style="padding-left: 3px;">{{element.activeStatus}}</span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                        [ngClass]="{'row-selected': tableRowIdx == Idx}"
                        (click)="onRowClicked(row)">
                    </tr>
                </table>
            </div>
            <!-------------------------------------->
            <!-- Control Strip                    -->
            <!-------------------------------------->
            <div class="mat-elevation-z2 control-strip">
                <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
                        class="command-button" matTooltip="Add a new record"
                        matTooltipPosition="above"
                        (click)="addModifyRecord(true)" mat-button>
                    <mat-icon>add_circle_outline</mat-icon>
                    Add New
                </button>
                <button *ngIf="visibleModify" mat-button class="command-button"
                        matTooltipPosition="above"
                        style="margin-top: 5px;" [disabled]="(Idx < 0 || this.isEditing)"
                        matTooltip="Update a selected record" (click)="addModifyRecord(false)"
                        mat-button>
                    <mat-icon>edit</mat-icon>
                    Update
                </button>
                <button *ngIf="visibleFormulary" mat-button class="command-button"
                        matTooltipPosition="above"
                        style="margin-top: 5px;" [disabled]="(Idx < 0 || this.isEditing)"
                        matTooltip="Show customer formulary" (click)="doShowFormulary()"
                        mat-button>
                    <mat-icon>table_chart</mat-icon>
                    Formulary
                </button>
            </div>
            <!------------------------------->
            <!--  Detail panel             -->
            <!------------------------------->
            <div id="customermaster-details-panel" class="customermaster-detail-container mat-elevation-z2"
                 style="margin-bottom: 2px;">
                <mat-tab-group animationDuration="0ms" (selectedTabChange)="doTabChange($event)"
                               [(selectedIndex)]=selectedTabIndex>
                    <mat-tab label="Details" [disabled]="isEditing">
                        <div class="sub-tab-container-class detail-tab-additional" style="padding-left: 13px;">
                            <div class="dwform" style="padding-right: 15px;">

                                <div class="dwform-cell" style="min-width: 400px;">

                                    <div class="dwform-row-double-gap"></div>
                                    <div class="dwform-row">
                                        <label class="label-field">ID:</label> <span
                                            class="form-required"> </span>
                                        <input class="input-field-short" id="id" style="width:80px"
                                        [disabled]="!isEditing"
                                               [(ngModel)]="id"
                                               type="text">
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                        <label class="label-field">Customer Name:</label>
                                        <span class="form-required">*</span>
                                        <input class="input-field-long" id="customerName" [disabled]="!isEditing"
                                               [(ngModel)]="customerName"
                                               style="text-transform:capitalize"
                                               placeholder="<Name>"
                                               type="text">
                                    </div>
                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                            <label class="label-field">Customer Type:</label>
                                            <span class="form-required">*</span>
                                            <mat-select [disabled]="!isEditing"
                                                        [(ngModel)]="customerType"
                                                        placeholder="- Please Select -"
                                                        disableOptionCentering
                                                        floatLabel="never"
                                                        panelClass="local-drop-down-panel"
                                                        class="dw-mat-select"
                                                        style="width: 230px;font-size: 10pt;"
                                                        [style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
                                                <mat-option *ngFor="let cType of customerTypes.types" [value]="cType">
                                                    {{cType}}
                                                </mat-option>
                                            </mat-select>
                                    </div>
                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                                <label class="label-field">Customer Group:</label>
                                                <span class="form-required">*</span>
                                                <mat-select [disabled]="!isEditing" [(ngModel)]="customerGroup"
                                                            placeholder="- Please Select -"
                                                            disableOptionCentering
                                                            floatLabel="never"
                                                            panelClass="local-drop-down-panel"
                                                            class="dw-mat-select"
                                                            style="width: 230px;font-size: 10pt;"
                                                            [style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
                                                    <mat-option value="Priority">Priority</mat-option>
                                                    <mat-option value="Regular">Regular</mat-option>

                                                </mat-select>
                                            </div>
                                    <div class="dwform-row-gap"></div>

                                    <!-- Fill Site ID -->
                                    <div class="dwform-row">
                                        <label class="label-field">Fill Site:</label> <span
                                            class="form-required">*</span>

                                        <mat-select [disabled]="!isEditing" [(ngModel)]="fillSiteId"
                                                    placeholder="- Please Select -"
                                                    disableOptionCentering
                                                    floatLabel="never"
                                                    panelClass="local-drop-down-panel"
                                                    class="dw-mat-select"
                                                    style="width: 230px;font-size: 10pt;"
                                                    [style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
                                            <mat-option *ngFor="let item of sites"
                                                        [value]="item.id">{{item.handle}}
                                            </mat-option>
                                        </mat-select>
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <!-- STATUS -->
                                    <div class="dwform-row">
                                        <label class="label-field">Status:</label> <span
                                            class="form-required">*</span>

                                        <mat-select [disabled]="!isEditing" [(ngModel)]="activeStatus"
                                                    placeholder="- Please Select -"
                                                    disableOptionCentering
                                                    floatLabel="never"
                                                    panelClass="local-drop-down-panel"
                                                    class="dw-mat-select"
                                                    style="width: 230px;font-size: 10pt;"
                                                    [style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
                                            <mat-option value="Active">Active</mat-option>
                                            <mat-option value="Prospect">Prospect</mat-option>
                                            <mat-option value="InActive">InActive</mat-option>

                                        </mat-select>
                                    </div>
                                </div>
                            <div class="dwform-cell " style="min-width: 320px;">
                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                        <label class="label-field">Group NPI Number:</label>
                                        <span class="form-required"></span>
                                        <input class="input-field" [disabled]="!isEditing"
                                               [(ngModel)]="groupNpiNumber"
                                               style="text-transform:uppercase"
                                               placeholder="<NPI Number>"
                                               type="text">
                                    </div>

                                    <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                        <label class="label-field">NCPDP Number:</label>
                                        <span class="form-required"></span>
                                        <input class="input-field" [disabled]="!isEditing"
                                               [(ngModel)]="ncpdpNumber"
                                               style="text-transform:uppercase"
                                               placeholder="<NCPDP/NPDS Number>"
                                               type="text">
                                    </div>
                            </div>
                            <!----------------------->
                            <!-- ERROR LINE --------->
                            <!----------------------->
                            <div class="error-line" [hidden]='!strErr.length'
                                 style="text-align:left">{{strErr}}</div>
                            <!----------------------->
                            <!-- SAVE STRIP        -->
                            <!----------------------->
                            <div class="save-close-strip" style="bottom: 3px;">

                                <button mat-button class="command-button" *ngIf="this.isEditing"
                                        (click)="submitRecord()" matTooltip="Submit the selected record"
                                        mat-button>
                                    <mat-icon>check_circle_outline</mat-icon>
                                    Submit
                                </button>
                                <button mat-button class="command-button" *ngIf="this.isEditing"
                                        (click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
                                    <mat-icon>highlight_off</mat-icon>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>

                    </mat-tab>
                    <mat-tab label="History" [disabled]="isEditing">
                        <div class="sub-tab-container-class detail-tab-additional">
                            <transaction-history #history></transaction-history>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-drawer-container>
</div>

<p-dialog header="Formulary for: ({{this.selectedRecord.id}}) - {{this.selectedRecord.customerName}}"
          [(visible)]="displayFormularyWindow"
          *ngIf="displayFormularyWindow"
          [modal]="true"
          appendTo="body"
          [responsive]="true"
          [style]="{width: '1400px', height: '1130px'}"
          [minY]="5"
          [resizable]="false" [closable]="false">
   <app-formulary-main #formulary 
         [CustomerId]="this.selectedRecord.id"
                       (cancelClicked)="this.cancelClickedFromFormulary()">
   </app-formulary-main>
</p-dialog>
