<!--
    file type .....: HTML file: finishbatch.component.html
    Created By ....: ianday
    Date ..........: 8/4/23 
-->
<div class="dialog-container">
    <div class="dwform">
        <!-- Station Cleaning Completed -->
        <div id="dvarianceId">

        <div class="dwform-row-gap"> </div>
        <label class="label-field">Remaining:</label>
        <span class="form-required"> </span>
        <label class="label-field-data" style="font-weight: bold">
            {{selectedForm.bpr?.dosesRemoved}}
        </label>

        <div class="dwform-row-gap"> </div>
        <label class="label-field">Variance:</label>
        <span class="form-required"> </span>
        <label class="label-field-data" style="font-weight: bold">
            {{selectedForm.bpr?.dosesVariance}}%
        </label>

        <div class="dwform-row-gap"> </div>
        <label class="label-field">Return to Stock:</label>
        <span class="form-required"> </span>
        <input class="input-field-shortest" [readonly]="true"
            [formControl]="dosesATSActualControl" type="text">

        <!-- Variance Reason -->
        <div *ngIf="showExplanation === true">
            <div class="dwform-row-gap"> </div>
            <label class="label-field" style="vertical-align: top;">Variance Reason:</label>
            <span class="form-required">*</span>

            <textarea class="input-field text-area-override" id="varianceReasonId"
                      [readonly]="true"
                      [formControl]="explanationControl"
                      [disabled]="false"
                      [(ngModel)]="selectedForm.bpr.explanation" type="text">
                </textarea>
        </div>

        <div class="dwform-row-gap" > </div>
        <mat-divider> </mat-divider>
        <div class="dwform-row-gap" > </div>
        <!-- Station Cleaning Completed -->

        <mat-checkbox id="visualLabelMatchId"
                      name="visualLabelMatch" binary="true"
                      [(ngModel)]="visualLabelMatch"
                      [ngModelOptions]="{standalone: true}">
           Visual Label Match Completed?
        </mat-checkbox>

        <!-- Print the labels Now? -->
        <div class="dwform-row-double-gap"> </div>
        <mat-checkbox id="itemNumberValidationId"
                      name="itemNumberValidation" binary="true"
                      [(ngModel)]="itemNumberValidation"
                      [checked]="true"
                      [ngModelOptions]="{standalone: true}">
           Item Number Validation Completed?
        </mat-checkbox>

        <!-- Print the labels Now? -->
        <div class="dwform-row-double-gap"> </div>
        <mat-checkbox id="seqNumberRangeVerifyId"
                      name="seqNumberRangeVerify" binary="true"
                      [(ngModel)]="seqNumberRangeVerify"
                      [checked]="true"
                      [ngModelOptions]="{standalone: true}">
          Sequence Number Range Verified?
        </mat-checkbox>

        <!-- Print the labels Now? -->
        <div class="dwform-row-double-gap"> </div>
        <mat-checkbox id="releaseApprovalId"
                      name="releaseApproval" binary="true"
                      [(ngModel)]="releaseApproval"
                      [checked]="true"
                      [ngModelOptions]="{standalone: true}">
           Release Approval?
        </mat-checkbox>

    </div>

<!----------------------->
<!-- ERROR LINE --------->
<!----------------------->
<div class="error-line" [hidden]='!strErr.length'
     style="text-align:left; bottom: 50px; left: 15px;">{{strErr}}</div>
<!----------------------->
<!-- SAVE STRIP        -->
<!----------------------->
<div style="text-align:center; margin-top: 70px;">
    <button mat-button class="command-button"
            (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
            mat-button>
        <mat-icon>check_circle_outline</mat-icon>
        Submit
    </button>
    <button mat-button class="command-button"
            (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
        <mat-icon>highlight_off</mat-icon>
        Cancel
    </button>
</div>
    </div>
