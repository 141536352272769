<div tabindex="0">
    <div class="dwform" style="padding-right: 15px;">
        <div class="dwform-cell-nopadding" style="min-width: 500px;">

            <div class="dwform-row">
                <label class="label-field">Item Number:</label>
                <span class="form-required"></span>
                <label style="font-weight: bold;">{{valItemNumber}}</label>
            </div>
            <div class="dwform-row-gap"></div>
             <div class="dwform-row-gap"></div>
             <div class="dwform-row">
                <label class="label-field">Medication:</label>
                <span class="form-required"></span>
                <label style="font-weight: bold;">{{valMedicationFamily}}</label>
            </div>
            <div class="dwform-row-gap"></div>
            <div class="dwform-row-gap"></div>
             <div class="dwform-row">
                <label class="label-field">Status:</label>
                <span class="form-required"></span>
                <label style="font-weight: bold;">{{valStatus}}</label>
            </div>
            <div class="dwform-row-gap"></div>
            <div class="dwform-row-gap"></div>
             <div class="dwform-row">
                <label class="label-field">New Status:</label>
                <span class="form-required">*</span>
                 <mat-select placeholder="Select" style="width: 120px"  id="supplier"
					  		panelClass="local-drop-down-panel"
                            class="dw-mat-select"
                            #newStatus
                            id="newStatus"
                            [(ngModel)]="valNewStatus">
						    <mat-option *ngFor="let status of statusList" [value]="status" >{{ status }}</mat-option>
				 </mat-select>
            </div>
              <div class="dwform-row-gap"></div>
             <div class="dwform-row">
                <label class="label-field" style="vertical-align: top">Comments:</label>
                <span class="form-required">*</span>
                <textarea #comment id="comment" class="input-field" placeholder="<comments>"
								style="font-family: Roboto; height: 55px; width: 345px;resize: none"
								[(ngModel)]="valComments"></textarea>
            </div>
            <div class="dwform-row-gap"></div>
             <div class="dwform-row">
                <label class="label-field">Upload File:</label>
                <div style="margin-left: 140px;margin-top: 0px;">
               		<app-file-selector [file_types]="'PNG, JPEG, GIF, PDF, MSG'" [header]="'Upload File'" (onFileSelected)="onFileSelectedHandler($event)">
               		</app-file-selector>
               	</div>
            </div>
           
          

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
