/**
 *  Filename ....: invtransfer.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/18/23
 **/
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Catalogues} from '../../../../../../models/catalogues';
import {Sites} from '../../../../../../models/sites';
import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {BulkUnitsService} from '../../../../../../services/bulkunits.service';
import {Settings} from '../../../../../../services/settings';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SitesService} from '../../../../../../services/sites.service';
import {TransactionHistoryService} from '../../../../../../services/transactionhistory.service';

@Component({
    selector: 'app-invtransfer',
    templateUrl: './invtransfer.component.html',
    styleUrls: ['./invtransfer.component.css']
})

export class InventoryTransferComponent implements OnInit {

    public strErr = '';
    public numUnits: number;
    public _invItem: string;
    public _selectedItems: any;
    public _medOrMat = 'Medication';
    public catalogues: Catalogues[];
    public sites: Sites[];
    public filteredCatalogues: Catalogues[];

    public selectedSite: string;
    public selectedCatalogue: string;
    public bulkUnitsService: BulkUnitsService;
    public sitesService: SitesService;
    public txService: TransactionHistoryService;

    /*** Target Items ***/
    public transferSiteId: number;
    public transferCataloguesId: number;
    public Itr: number;

    @ViewChild('selectCat', {static: true}) selectCat;
    @Input() set medOrMat(val: any) {
        this._medOrMat = val;
    }
    get medOrMat(): any {
        return this._medOrMat;
    }

    @Input() set InventoryItem(val: any) {
        this._invItem = val;
    }
    get InventoryItem(): string {
        return this._invItem;
    }
    @Input() set selectedItems(val: any) {
        if (val === '' || val.length === 0) {
            return;
        }
        this._selectedItems = val;
        this.numUnits = this._selectedItems.length;
    }
    get selectedItems(): any {
        return this._selectedItems;
    }
    @Output() transferInvItems = new EventEmitter();
    @Output() cancelTransferInvItems = new EventEmitter();

    constructor(buService: BulkUnitsService,
                sService: SitesService,
                tService: TransactionHistoryService,
                private dialog: MatDialog) {
        this.bulkUnitsService = buService;
        this.sitesService = sService;
        this.txService = tService;
    }
    ngOnInit() {

    }
    setValues(): void {
        this.fetchCatalogues();

    }
    setUpDefauls(): void {

        if (this.catalogues === undefined || this.selectedItems === undefined)
            return;


        /*** get the catalogue id from the selected Items ***/
        const idx = this.catalogues.findIndex(x => x.id === this.selectedItems[0].cataloguesId);
        const cat = this.catalogues[idx];
        this.selectedCatalogue = cat.handle;
        /*** now remove the existing catalogue ***/
        this.catalogues.splice(idx, 1);

        /*** get the selectedSite from the catalogue-site  ***/
        this.selectedSite = this.sites.find(x => x.id === cat.siteId).handle;
        /*** Assume it is the same site we are transferring to ***/
        this.transferSiteId = cat.siteId;
        /*** Now fetch a list of eligible catalogues for the selected site ***/
        /*** And change them if the site Id changes ***/
        this.filteredCatalogues = this.returnFilteredCatalogues(cat.siteId);
    }
    fetchCatalogues(): void {

        this.sitesService.getAllCatalogues().subscribe(
            (data) => {
                this.catalogues = data;
                this.fetchSites();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching catalogues in Transfer');
            });
    }
    fetchSites(): void {

        this.sitesService.getAllSites().subscribe(
            (data) => {
                this.sites = data;
                this.setUpDefauls();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching sites in Transfer');
            });
    }
    returnFilteredCatalogues(siteId: number): any {
        let ret = [];
        ret = this.catalogues.filter(x => x.siteId === siteId);
        return ret;
    }
    onChangeSiteItem(event): void {
        this.filteredCatalogues = this.returnFilteredCatalogues(event.value);
    }
    onChangeCatalogueItem(event): void {

    }
    returnCatDesc(id: number): string {
        if(id === undefined)
            return '';

        return this.catalogues.find(x => x.id === id).handle;
    }
    transferRecords(): void {
        let result;
        const message = 'Transfer selected items to "' +
            this.returnCatDesc(this.transferCataloguesId) + '" <br /> Are you sure? ';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            result = dialogResult;
            if (result === true) {
                this.Itr = 0;
                this.performInventoryMove();
            }
        });
    }
    performInventoryMove(): void {

        if (this.Itr <= this.selectedItems.length - 1) {
            this.updateToNewLocation();
        }
        else {
            this.transferInvItems.emit();
        }
    }
    updateToNewLocation(): void {
        this.selectedItems[this.Itr].cataloguesId = this.transferCataloguesId;
        this.bulkUnitsService.updateBulkUnits(this.selectedItems[this.Itr]).subscribe(
           (data) => {
               this.createTxRecord(this.selectedItems[this.Itr].id, this.returnCatDesc(this.transferCataloguesId));
               this.Itr++;
               this.performInventoryMove();
           },
         (error) => {
               Settings.getInstance().handleError(error, 'Transferring items');
          });
    }
    createTxRecord(keyId: number, catDesc: string): void {

        this.txService.createTransactionHistory('bulkunits', keyId, 'Transfer',
            'Transferred Item To: ' + catDesc);
    }
    cancelSubmitRecord(): void {
        this.cancelTransferInvItems.emit();
    }
}


