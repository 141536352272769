export interface GeneralSetting {
id: number;
key: string;
value: string;
}

export class GeneralSetting {
id = 0;

}
