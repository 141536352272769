import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormularyMedicationLabel } from 'src/app/models/formulary-label';
import { FormularyMedication } from 'src/app/models/formulary-medication';
import { FormularyService } from 'src/app/services/formulary.service';
import { Settings } from 'src/app/services/settings';

@Component({
	selector: 'app-add-label',
	templateUrl: './add-label.component.html',
	styleUrls: ['./add-label.component.css']
})
export class AddLabelComponent {

	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();
	_formularyMedication: FormularyMedication;
	selectedFile: any;
	selectedLabel: FormularyMedicationLabel;
	@Input() set formularyMedication(value: any) {
		this._formularyMedication = value;
		if (this._formularyMedication) {
			this.onShowHandler();
		}
	}
	strErr = '';
	constructor(private formularyService: FormularyService) {

	}

	ngOnInit() {

	}
	onShowHandler() {
		this.selectedFile = undefined;
	}
	doCancel() {
		this.cancelClicked.emit();
	}
	validate() {
		if (!this.selectedFile) {
			this.strErr = 'Please select label image';
			return false;
		}
		return true;
	}

	doSubmit() {
		if (!this.validate()) {
			return;
		}
		this.selectedLabel = new FormularyMedicationLabel();
		this.selectedLabel.id = 0;
		this.selectedLabel.formularyMedicationId = this._formularyMedication.id;
		this.selectedLabel.customerId = this._formularyMedication.customerId;
		this.selectedLabel.uploadedBy = Settings.getInstance().loggedInUser.username;
		this.selectedLabel.uploadedDate = new Date();
		this.selectedLabel.defaultFlag=1;

		this.formularyService.createFormularyMedicationLabel(this.selectedLabel).subscribe(
			(result) => {
				this.strErr = '';
				this.selectedLabel.id = result.id;
				this.formularyService.uploadformularyMedicationLabel(this.selectedLabel,this.selectedFile).subscribe(
					(result2) => {
						this.submitClicked.emit();
						//this.queryRecords();
					},
					(error2) => {
					}
				);
			},
			(error) => {
			}
		);
	}

	onFileSelectedHandler(file: any) {
		this.strErr = '';
		this.selectedFile = file;
		if (this.selectedFile === undefined || this.selectedFile === null) {
			return;
		}
		const str = URL.createObjectURL(file);
		if (this.selectedLabel === undefined) {
			this.selectedLabel = new FormularyMedicationLabel();
		}
	}
	
	onFileError(event) {
		this.strErr = event;
	}

}
