import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'app-file-selector',
	templateUrl: './file-selector.component.html',
	styleUrls: ['./file-selector.component.css']
})
export class FileSelectorComponent {

	_header = '';
	_file_types = '';
	selectedFile: any;
	fileName = '';
	public strErr = '';
	@Input() set header(value: any) {
		this._header = value;
	}

	get header(): any {
		return this._header;
	}

	@Input() set file_types(value: any) {
		this._file_types = value;
	}

	get file_types(): any {
		return this._file_types;
	}

	@Output() onFileSelected = new EventEmitter();
	@Output() onFileError = new EventEmitter();

	onFileDropped(fileInputEvent: any) {


		if (fileInputEvent[0] === undefined || fileInputEvent[0] === null) {
			return;
		}
		this.selectedFile = fileInputEvent[0];
		// check that the suffix is correct.
		if(!this.chkValidSuffix(this.selectedFile.name))
			return;

		this.fileName = this.selectedFile.name;
		this.onFileSelected.emit(this.selectedFile);
	}
	fileInputChange(fileInputEvent: any) {
		if (fileInputEvent.target.files[0] === undefined || fileInputEvent.target.files[0] === null) {
			return;
		}
		this.selectedFile = fileInputEvent.target.files[0];

		// check that the suffix is correct.
		if(!this.chkValidSuffix(this.selectedFile.name))
			return;

		this.fileName = this.selectedFile.name;
		this.onFileSelected.emit(this.selectedFile);
	}
	chkValidSuffix(filename: string): boolean
	{
		let suffix = '';
		this.strErr = '';

		suffix = this.getFileSuffix(filename);
		if(this.file_types.toLowerCase().includes(suffix.toLowerCase()) === false) {
			this.onFileError.emit('Error: This file type is not supported');
			return false;
		}
		return true;
	}
	getFileSuffix(filename: string): string {

		if(filename === undefined)
			return '';

		const fileParts = filename.split('.');
		const suffix = fileParts[fileParts.length - 1 ];

		return suffix;
	}
}
