<!--
    file type .....: HTML file: changeassignments.component.html
    Created By ....: ianday
    Date ..........: 7/14/23 
-->
<div class="changeassignments-container">
    <div class="change-assignments-form-content">

        <div class="dwform" style="padding-right: 15px;">

            <div class="dwform-row-double-gap"></div>
            <div class="dwform-row" >
                <label class="label-field">Selected Site:</label>
                <span class="form-required">*</span>
                <mat-select
                        disableOptionCentering
                        [(ngModel)]="selectedSite" #cbSites floatLabel="never"
                        class="dw-mat-select"
                        style="font-size: 10pt;width: 180px;"
                        panelClass="local-drop-down-panel"
                        (selectionChange)="onChangeSites($event)">
                    <mat-option *ngFor="let site of sitesList"
                                [value]="site">{{site.handle}}</mat-option>
                </mat-select>
            </div>
            <div class="dwform-row-double-gap"></div>
            <div class="dwform-row" >
                <label class="label-field">Selected Station:</label>
                <span class="form-required">*</span>
            <mat-select
                    disableOptionCentering
                    [(ngModel)]="selectedStation" #cbStation floatLabel="never"
                    class="dw-mat-select"
                    style="font-size: 10pt;width: 180px;"
                    panelClass="local-drop-down-panel"
                    (selectionChange)="onChangeStations($event)">
                <mat-option *ngFor="let station of stationsList"
                            [value]="station">{{station.handle}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div style="text-align:center; margin-top: 80px;">
        <button mat-button class="command-button"
                (click)="onSubmitClicked()" matTooltip="Submit Assignments"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without submitting assignments" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
