/**
 *  Filename ....: finishbatch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/4/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Forming} from '../../../../models/forming';
import {LabelGenComponent} from '../../../../components/label-gen/label-gen';
import {FormControl} from '@angular/forms';
import {Stations} from '../../../../models/stations';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {MatTableDataSource} from '@angular/material/table';
import {BprBulkItems} from '../../../../models/bprbulkitems';
import {Settings} from '../../../../services/settings';
import {FormingService} from '../../../../services/forming.service';
import {Bpr} from '../../../../models/bpr';
import {BulkUnitsService} from '../../../../services/bulkunits.service';

@Component({
    selector: 'app-finishbatch',
    templateUrl: './finishbatch.component.html',
    styleUrls: ['./finishbatch.component.css']
})

export class FinishBatchComponent implements OnInit {

    public _stationsList: any;
    public _selectedForm: Forming;
    public strErr = '';
    public ordNumber: string;
    public selectedStation: Stations;
    public records: Array<BprBulkItems>;
    public dataSource: MatTableDataSource<BprBulkItems>;
    public pkgSize: number | null;
    public selectedBPRItem: BprBulkItems;

    public iterator: number | null;

    public Idx = -1;
    public daysRemaining: number;
    public dosesBalance: number;
    public dosesRequired: number;
    public totalDiscarded: number;
    public totalRTSDoses: number;
    public showExplanation: boolean;

    /*** Form Controls ***/
    public packageTempControl: FormControl;
    public packageHumidityControl: FormControl;
    public varianceReasonControl: FormControl;
    public displayedColumns = ['icn', 'ndcNumber', 'expirationDate', 'ordered', 'starting', 'used', 'discarded', 'returning'];

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @HostListener('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }

    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
    }

    get selectedForm(): Forming {
        return this._selectedForm;
    }

    @Input() set stationsList(inp: any) {
        if (inp === null) {
            this._stationsList = new Array();
            return;
        }
        this._stationsList = inp?.filter(x => x.id > 0);
    }

    get stationsList(): any {
        return this._stationsList;
    }

    @Input() set orderNumber(inp: string) {
        this.ordNumber = inp;
    }

    constructor(public formingService: FormingService,
                public bulkUnitsService: BulkUnitsService) {
        this.packageTempControl = new FormControl();
        this.packageHumidityControl = new FormControl();
        this.varianceReasonControl = new FormControl();
    }

    ngOnInit() {}

    public doInitialize(): void {
        this.strErr = '';

        this.pkgSize = Number(DwUtils.returnPackageSizeCode(this.selectedForm.itemPrefix));
        this.totalDiscarded = 0;
        this.totalRTSDoses = 0;
        this.showExplanation = false;
        this.records = new Array();
        this.dataSource = new MatTableDataSource<BprBulkItems>();
        this.packageTempControl.setValue(this.selectedForm.bpr.packageTemperature);
        this.packageHumidityControl.setValue(this.selectedForm.bpr.packageHumidity);
        this.selectedStation = this.stationsList.find(x => x.id === this.selectedForm.stationId);

        /*** set up the basic calculations ***/
        this.queryBprItems();
    }
    public queryBprItems(): void {

        this.dataSource = new MatTableDataSource<BprBulkItems>();
        this.records = new Array();
        this.dosesRequired = this.selectedForm.bpr.dosesRequired;

        this.formingService.getAllBprBulkItemsByBprId(this.selectedForm.bprId).subscribe(
            (data) => {

               for (const item of data) {

                    this.selectedBPRItem = new BprBulkItems();
                    this.selectedBPRItem.setBulkItem(item);

                    this.selectedBPRItem.ordered = this.selectedForm.bpr.dosesRequired;
                    this.selectedBPRItem.starting = item.starting;
                    this.selectedBPRItem.ordered = this.dosesRequired;

                    if (this.dosesRequired >= this.selectedBPRItem.starting) {
                        this.selectedBPRItem.used = this.selectedBPRItem.starting;
                        this.selectedBPRItem.returning = 0;
                        this.dosesRequired -= this.selectedBPRItem.used;
                    }
                    else if (Number(this.dosesRequired) < Number(this.selectedBPRItem.starting)) {
                        this.selectedBPRItem.used = this.dosesRequired;
                        this.dosesRequired = 0;
                        this.selectedBPRItem.returning = Number(this.selectedBPRItem.starting) -
                            (Number(this.selectedBPRItem.used) + Number(this.selectedBPRItem.discarded));
                    }

                    this.totalDiscarded += this.selectedBPRItem.discarded;
                    this.records.push(this.selectedBPRItem);
               }

               this.dataSource = new MatTableDataSource(this.records);
               this.calcDosages(0);
               this.calculateVariance();
               this.selectedBPRItem = new BprBulkItems();

            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching BPR Items for finalizing');
            });
    }
    public calcDosages(remainder: number): void {
       this.dosesBalance = Number(this.selectedForm.bpr.dosesRequired) - (Number(this.selectedForm.bpr.dosesSelected) - this.totalDiscarded);
    }
    public calculateVariance(): number {

        let variance = 0;
        // the variance is the number of doses discarded divided by the doses required
        if (this.totalDiscarded === 0) {
            return variance;
        }

        variance = this.totalDiscarded / this.selectedForm.bpr.dosesRequired;

        if ((variance >= 0.25) || (this.totalDiscarded >= 3)) {
            this.showExplanation = true;
        } else {
            this.showExplanation = false;
        }
        return variance;

    }
    public runRecalculate(): void {
        this.totalDiscarded = 0;
        let remainder = 0;

        /*** then apply the discards again ***/
        for (let i = 0; i < this.records.length; i++) {

            this.totalDiscarded += Number(this.records[i].discarded);

            if (this.records[i].discarded > 0) {
                if (this.records[i].returning >= this.records[i].discarded) {
                    this.records[i].returning -= Number(this.records[i].discarded);
                } else {
                    remainder += this.records[i].discarded -= this.records[i].returning;
                    this.records[i].returning = 0;
                    this.records[i].used -= Number(this.records[i].discarded);
                }
            }
        }
        this.dataSource = new MatTableDataSource(this.records);
        if (remainder > 0) {
            this.strErr = 'Error: Number of variant doses exceeds order requirement!';
        }
        this.calcDosages(this.totalDiscarded);
    }
    public lblDescription(): string {

        return this.selectedForm.quantity + ' X [' + this.selectedForm.itemPrefix + '] ' + this.selectedForm.description;
    }
    public onChangeStations(event: any): void {

    }
    public tableKeyDown(event: any): void {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedBPRItem = this.dataSource.filteredData[++this.Idx];

            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedBPRItem = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    public lbFmt(inp: string): string {
        return DwUtils.formatDTFromString(inp);
    }
    public lbFmtDate(inp: string): string {
        return DwUtils.formatDateFromString(inp);
    }
    public onSubmitClicked(): void {

        this.setBPRRecord();
        const bpr: Bpr | null = new Bpr();
        bpr.setBpr(this.selectedForm.bpr);
        const vr = bpr.validateInProcessRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.highlightFocus(vr.focus_field);
            return;
        }

        if ((this.showExplanation === true) && (this.varianceReasonControl.value === '')) {
            this.strErr = 'Error: A variance reason is required';
            DwUtils.setFocus('varianceReasonId');
            return;
        }

        /*** update the batch production record ****/
        this.iterator = 0;
        this.saveBPRBulkItems();
    }
    public saveBPRBulkItems(): void {

        if (this.iterator < this.records.length) {
            this.updateBPRBulkItem(this.records[this.iterator]);
        }
        else {
            this.updateBatchProductionRecord(3);
        }
    }
    public updateBPRBulkItem(rec: BprBulkItems): void {

        this.formingService.updateBprItem(rec).subscribe(
            (result) => {

                /*** now update the Bulk Item - put it back into inventory or not ***/
                 this.iterator++;
                 this.saveBPRBulkItems();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'updating BPRItems in Finish-Batch');
            });
    }
    public updateBatchProductionRecord(stepToUpdate: number): void {
        this.selectedForm.bpr.currentStep = stepToUpdate;
        this.selectedForm.bpr.dosesAtsActual = this.dosesBalance;
        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
            (result) => {
                /*** Now finalize all the bulk items ***/
                this.submitClicked.emit();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Updating the batch production record in finishbatch');
            });
    }
    public setBPRRecord(): void {

        this.selectedForm.bpr.packageTemperature = this.packageTempControl.value;
        this.selectedForm.bpr.packageHumidity = this.packageHumidityControl.value;
        this.selectedForm.bpr.explanation = this.varianceReasonControl.value;
        this.selectedForm.bpr.unitsOrdered = this.selectedForm.quantity;
        this.selectedForm.bpr.dosesRemoved = this.totalDiscarded;
        this.selectedForm.bpr.dosesVariance = this.calculateVariance();
        this.selectedForm.bpr.labelsRequired = this.selectedForm.quantity;

    }
    public onRowClicked(selected: any): void {

        this.selectedBPRItem = selected;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedBPRItem.icn === '') {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.icn === this.selectedBPRItem.icn);

    }
    public doSetFocus(event: any): void {
        DwUtils.highlightFocus('inputDiscardedId');
    }
    public doInputChange(event: any): void {
        const newVal = event.target.value;

        this.records[this.Idx].discarded = newVal;
        this.selectedBPRItem.discarded = newVal;
        this.runRecalculate();
    }
    public showDaysRemaining(): string {

        const retVal = DwUtils.showDaysRemaining(this.selectedForm.bpr.createdDate);
        return retVal;
    }
    public unSelectRow() {
        this.Idx = -1;
        this.selectedBPRItem = new BprBulkItems();
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


