/************
 * Model Class
 * File Name ...: catalogues.components.ts
 * Generated By : ianday on 3/21/23
 * Date ........: 3/21/23
 /************/
import {ValidationResult} from './validation-result';

export interface Catalogues {
     id: number;
     siteId: number;
     handle: string;
     description: string | null;
     defaultFlag: number;
     visibility: number;
     activeStatus: string;
}

export class Catalogues {
    constructor(hndlParam = '') {
        this.id = 0;
        this.siteId = 0;
        this.handle = hndlParam;
        this.description = '';
        this.defaultFlag = 0;
        this.visibility = 0;
        this.activeStatus = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.handle.length === 0) {
            vr.focus_field = 'cataloguesHandler';                              /* this should be the id value of the
             field */
            vr.error = 'Error: an inventory name is required';
            return vr;
        }

        return vr;
    }
}
