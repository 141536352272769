import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Settings } from '../../services/settings';

@Component({
  selector: 'app-selectuser',
  templateUrl: './selectuser.component.html',
  styleUrls: ['./selectuser.component.css']
})
export class SelectuserComponent implements OnInit {
data: any;
selectedEmployee: any;
searchString: string;
cols: any[];
loading = false;
resourceList: any;
resourceListOriginal: any;
EnableLanguageMatch = false;
EnableQueueMatch = true;
EnablePCNMatch = true;
PCNQueueMatchVisible = false;

@ViewChild('searchbox', { static: true }) searchbox: ElementRef;

@Output() resourceSelected = new EventEmitter();
@Output() cancelClicked = new EventEmitter();
@Output() resourceSelectedforTransfer = new EventEmitter();
@Output() transferAllClicked = new EventEmitter();


@Input() HideWorkers: string[];
@Input() ShowRecordsHavingRoles: string[];
@Input() MaskUser: string;
@Input() MaskOrganization: any;

displayConfirmMsg = false;
strConfirmMessage: string;
stringColor = '';
 constructor(private userService: UserService) {
  }
 ngOnInit() {
     this.cols = [
                  { field: 'name', header: 'Name', width: '60%'},
                  { field: 'username', header: 'ID', width: '40%' },
              ];
 }
queryRecords() {
     this.loading = true;
     this.userService.getAllUsers().subscribe(
             ( data ) => {
                 Settings.getInstance().usersList = data;
                 for ( const rec of data ) {
                     if ( rec.status === 1 ) {
                         rec.displayStatus = 'Active';
                     } else {
                         rec.displayStatus = 'Inactive';
                     }
                 }
                 Settings.getInstance().usersList = data;
                 this.resourceListOriginal = data;
				 if (this.MaskOrganization !=null && this.MaskOrganization != undefined) {
					this.resourceList = [];
					data.forEach(user => {
						if (this.MaskOrganization.id != user.organization_id) {
							this.resourceList.push(user);
						}
					});
				 } else {
                 	this.resourceList = data;
				 }
                 this.loading = false;
             },
             ( error ) => {
             }
         );
 }

 onKeyPress(str: string) {
      this.searchString = str;
      if (str !== undefined) {
          str = str.toLowerCase();
      }
      const resList = Settings.filterResourceList(this.resourceListOriginal, this.searchString);
      this.resourceList = resList;
      //console.log (resList);
  }
  onRowSelect(event) {
      if (this.selectedEmployee.stringColor === 'foreground-green') {
          this.selectedEmployee.stringColor = 'foreground-yellow';
      }
      for (let i = 0; i < this.resourceList.length; i++) {
          if (this.resourceList[i] === this.selectedEmployee) {
              continue;
          }
          if (this.resourceList[i].stringColor === 'foreground-yellow') {
              this.resourceList[i].stringColor = 'foreground-green';
          }
      }
  }
  setFocusOnSearch() {
	  this.queryRecords();
      this.searchbox.nativeElement.focus();
  }
  onRowDblClick(event, rowData) {
      this.selectedEmployee = rowData;
      this.doSelect();
  }
  doSelect() {
      if (this.selectedEmployee === null || this.selectedEmployee === undefined) {
          return;
      }
      this.resourceSelected.emit(this.selectedEmployee);
}
  doCancel() {
      this.cancelClicked.emit();
  }
}