/************
 * Model Class
 * File Name ...: suppliers.components.ts
 * Generated By : ianday on 2/17/23
 * Date ........: 2/17/23
 /************/

export interface ValidationResult {
    error: string | null;
    focus_field: string | null;
}

export class ValidationResult {

    constructor() {
        this.focus_field = '';
        this.error = '';
    }

}
