<!--
    file type .....: HTML file: trans-history-detail.component.html
    Created By ....: ianday
    Date ..........: 3/20/23 
-->
<div class="trans-history-detail-container">
    <table style="width:100%">
        <tr>
            <td style="width: 50%; font-weight: bold; font-size: 14px;">
                Table:
                <span style="color: blue;">{{tableFunctionShow(selectedRecord?.tableName)}}</span>
            </td>
            <td style="width:50%; text-align:right;">
                <button mat-button style="width: 30px;"
                        matTooltip="Fetch the next Record"
                        (click)="attemptFetchNextRecord()">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                &nbsp;
                <button mat-button style="width: 30px; "
                        matTooltip="Fetch the previous Record"
                        (click)="attemptFetchPreviousRecord()">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </tr>
    </table>
    <div class="dwform" style="padding-right: 15px;" tabindex="-1">

        <div class="dwform-cell-nopadding"
            style="min-width: 500px;" tabindex="-1">

            <!-- add a dividing line for visual separation -->
            <div class="dwform-row-gap"></div>

            <!-- ID -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Transaction ID:</label>
                <span class="form-required"></span>
                <label class="label-field-data">{{selectedRecord.id}}</label>
            </div>

            <!-- Transaction date -->

            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Transaction Date:</label>
                <span class="form-required"></span>
                <label class="label-field-data">{{retDTF(selectedRecord.transactionDate)}}</label>
            </div>

            <!-- User ID -->

            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Created By:</label>
                <span class="form-required"></span>
                <label class="label-field-data">{{returnFullName(selectedRecord.userUid)}}</label>
            </div>

            <!-- Transaction Type -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Transaction Type:</label>
                <span class="form-required"></span>
                <label class="label-field-data">{{selectedRecord.transactionType}}</label>
            </div>

            <!-- Entity Value Type -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Key/Value:</label>
                <span class="form-required"></span>
                <label class="label-field-data">where {{selectedRecord.tableKey}} =
                    {{selectedRecord.entityKeyValue}}</label>
            </div>

            <!-- Action Type -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Action:</label>
                <span class="form-required"></span>
                <label class="label-field-data">{{selectedRecord.txAction}}</label>
            </div>

            <!-- Old Value -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field-text-area" style="">Old Value:</label>
                <span class="form-required"></span>
                <textarea [readOnly]="true"
                     class="text_area_value label-field-data">{{selectedRecord.oldValue}}</textarea>
            </div>

            <!-- New Value -->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field-text-area">New Value:</label>
                <span class="form-required"></span>
                <textarea [readOnly]="true"
                      class="text_area_value label-field-data">{{selectedRecord.newValue}}</textarea>
            </div>


            <!-- add a dividing line for visual separation -->
            <div class="dwform-row-gap"></div>

            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                    (click)="doClose()" matTooltip="Close the detail window" >
                    <mat-icon>highlight_off</mat-icon>
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
