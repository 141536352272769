<div class="add-address-container" tabindex="0">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell">

            <div class="dwform-row-gap"></div>
              <div class="dwform-row">
                <label class="label-field">1. Image Type:</label>
                <span class="form-required">*</span>
               <mat-select
				disableOptionCentering placeholder="- Please Select -"
				[(value)]="selectedImageType" #cbStatus floatLabel="never"
				style="font-size: 10pt;width: 180px;"
				panelClass="local-drop-down-panel" class="dw-mat-select"
				(selectionChange)="onChangeImageType($event)"> 
				<mat-option	value='Container'>Container</mat-option>
				<mat-option value='Dose'>Dose</mat-option>					
			</mat-select>
            </div>
            
          <div class="dwform-row-gap"></div>   
          <div class="dwform-row">
          	<span>2. Attach</span><br>
        <!-- 
        <input type="file" id="imgupload" style="display:none" (change)="fileInputChange($event)"/>
		<label for='imgupload'> <button id="OpenImgUpload" style="display: none"></button>
           <div #imageLoader dwDragDrop id="imageLoader" (onFileDropped)="onFileDropped($event)"
			style="width: 100%; height: 250px; text-align: center; padding: 10px; background: #fafafa; box-shadow: 0 0 25px 14px rgb(255 255 255) inset;"> 
			<div style="font-size: 22px;">Upload your images</div>
			<div>PNG, JPEG and GIF are allowed</div>
			<div style="margin: 1rem 3rem; height: 75%; box-shadow: inset 0px 0px 20px 0px rgb(238 242 243 / 20%); border-radius: 5px;">
				<div><mat-icon style="font-size: 5rem; width: 100%; height: fit-content; color: lightgrey;">cloud_upload</mat-icon></div>
				<div style="margin-top: 1rem">Drag and drop or click to choose a file</div>
			</div>			
			</div>
		</label>
		-->
		<div style="margin-left: 30px;margin-top: 8px;">
			<app-file-selector [file_types]="'PNG, JPEG, GIF'" [header]="'Upload your images'" (onFileSelected)="onFileSelectedHandler($event)">
			</app-file-selector>
		</div>
		<div style="text-align: center;margin-top: 5px;">
			<img *ngIf="previewSRC" style="width: 120px; height: 90px;" id="preview" [src]="previewSRC"  alt="preview" />
        </div>    
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
