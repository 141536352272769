<div class="footer-layout">
  <table width="100%">
    <tr>
    <td>
        <span >Powered by:&nbsp;<img class="footer-img-class" src="./assets/dayworks-white.png" height="13" alt="VM">
        </span>
    </td>
      <td style="text-align: right">
        <span (click)="showDialogAbout()" style="cursor: pointer;">Version: {{settings.version}}</span>
      </td>
    </tr>
  </table>
</div>
<p-dialog  header="About" (onShow)="abt.doLoadData()" [(visible)]="displayAbout" [modal]="true" [responsive]="true"  [style]="{width: '500px'}" [resizable]="false">
	<app-about #abt	(cancelClicked)="doCancelFromAbout()"></app-about>
</p-dialog>
