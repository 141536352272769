/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: neworders.components.ts
 * Generated By : ianday on 6/15/23
 * Date ........: 6/15/23
 /************/

import {Component, OnInit, ViewChild, HostListener, AfterContentInit, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Settings} from '../../../services/settings';
import {Orders} from '../../../models/Orders';
import {OrdersService} from '../../../services/Orders.service';
import {ValidationResult} from '../../../models/validation-result';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TransactionHistoryService} from '../../../services/transactionhistory.service';
import {TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import {DwUtils} from '../../../components/utils/dw-utils';
import {OrderItems} from '../../../models/orderitems';
import {AddOrderComponent} from './addorder/addorder.component';
import {AcceptOrderItemsComponent} from './acceptorderitems/acceptorderitems.component';

@Component({
    selector: 'app-neworders',
    templateUrl: './neworders.component.html',
    styleUrls: ['./neworders.component.css']
})
export class NewOrdersComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'New Orders';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: any;
    public selectedRecord: Orders;
    public selectedItemRecord: OrderItems;
    public historyRecord: Orders;
    public orderItems: any;
    public Idx: number | null = -1;
    public itemIdx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Orders> = new MatTableDataSource<Orders>();
    public itemDataSource: MatTableDataSource<OrderItems> = new MatTableDataSource<OrderItems>();
    public renderedData: Array<any>;
    public result: boolean | null = false;
    public disabledFlag: boolean | null = true;
    public activeCellId: string | null = '';

    /**** Query strip filter settings ***/
    public selectedStatusFilter: any;
    public statusFilterList = [];
    public statusFilterIdx: number | null = -1;
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['orderNumber', 'subCustomerName', 'orderByFullName', 'customerReference',  'submitMethod', 'dateSubmitted', 'dateClosed', 'grandTotal', 'activeStatus' ];
    public itemDisplayedColumns: string [] = ['quantity', 'itemPrefix', 'unitPrice', 'extendedPrice', 'itemStatus'];
    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;
    public visibleSendBtn: boolean | null = false;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: any | null = '';
    public customerName: string | null;
    public description: any | null = '';
    public activeStatus: any | null = '';
    public addUpdateFlag: boolean | null = true;
    public updatedId: number | null = 0;

    /***** Side drawer ****/
    public propContainerState: boolean | null = false;
    public visiblePropertiesTab: boolean | null = true;
    public visibleNotesTab: boolean | null = true;

    /*** dialogs ***/
    public displayNewOrderDialog = false;
    public displayAcceptDialog = false;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;
    @ViewChild('addOrder', {static: true}) addOrder: AddOrderComponent;
    @ViewChild('acceptOrderItems', {static: true}) acceptOrderItems: AcceptOrderItemsComponent;

    @Output() countChangedEvent = new EventEmitter<number>();
    @Output() orderCountChangedEvent = new EventEmitter<number>();

    constructor(private ordersService: OrdersService, private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Orders();
        this.selectedItemRecord = new OrderItems();

        this.statusFilterList = new Array();
        this.statusFilterList.push({ id: 'Active', description: 'Active' });
        this.statusFilterList.push({ id: 'On Hold', description: 'On Hold' });
        this.statusFilterList.push({ id: 'Cancelled', description: 'Cancelled' });
        this.statusFilterList.push({ id: 'All', description: 'All' });

        this.selectedStatusFilter = this.statusFilterList[0];
        this.statusFilterIdx = 0;
        this.records = new Array();

    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = true;
        this.visibleSendBtn = true;
    }
    ngAfterContentInit() {

        this.queryRecords(false);

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
    }
    get selectableStatuses(): any {
        return this.statusFilterList.filter( x => x.id !== 'All');

    }
    /*** Query Records from the Service ***/
    public queryRecords(requeryFlag: boolean) {

        this.ordersService.getAllOrders().subscribe(
            (result) => {

                this.records = result;
                this.setDataSource();

                if (requeryFlag === true) {
                    this.Idx = this.dataSource.filteredData.findIndex(x => x.id === this.updatedId);
                    this.selectedRecord = this.dataSource.filteredData[this.Idx];
                    this.selectRow();
                }
                else {
                    this.onChangeStatusFilterValue();
                }
                this.setPagination(-1, this.dataSource.data.length);

            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            });
    }
    public setDataSource(): void {
        this.dataSource = new MatTableDataSource(this.records);
        this.dataSource.sort = this.sort;
        this.countChangedEvent.emit(this.records.length);
    }
    /*** Display the number of records from the total ***/
    public setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    public onChangeStatusFilterValue() {
        const dataTemp: Orders[] = [];
        this.clearFilter();

        for (const rec of this.records) {
            if (rec.activeStatus === this.selectedStatusFilter.id || this.selectedStatusFilter.id === 'All') {
                dataTemp.push(rec);
            }
        }
        this.dataSource = new MatTableDataSource(dataTemp);
        this.setPagination(-1, this.dataSource.filteredData.length);
    }
    public onStatusChange() {

        this.ordersService.updateOrders(this.selectedRecord).subscribe(
            (data) => {
                // no user feedback, just apply the filter ...
                this.onChangeStatusFilterValue();
                this.orderCountChangedEvent.emit();
            });
    }
    public labelDateFmtDT(inp: any): string {
        if (inp === null) {
            return '';
        }
        return DwUtils.formatDTFromDate(inp);
    }
    public labelFmtDT(inp: string): string {
        if (inp === null) {
            return '';
        }
        return DwUtils.formatDTFromString(inp);
    }
    public doTabChange(event): void {

    }
    @HostListener('matSortChange', ['$event'])
    onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
        this.dataSource.filter = '';
        this.selectRow();
        this.setPagination(-1, this.dataSource.data.length);
    }
    public applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public selectRow() {
        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
        this.getOrderItems();
    }
    /*** Order Items ***/
    public onItemRowClicked(row: any): void {
     /*   if (this.selectedItemRecord.id === 0) {
            this.itemIdx = -1;
            this.selectedItemRecord = new OrderItems();
            return;
        } */
        this.itemIdx = this.itemDataSource.filteredData.findIndex(s => s.id === row.id);
        this.selectedItemRecord = this.itemDataSource.filteredData[this.itemIdx];
    }
    public getOrderItems(): void {

        this.ordersService.getOrderItemsByOrderId(this.selectedRecord.id).subscribe (
            (data) => {
                this.orderItems = data;
                this.itemDataSource = new MatTableDataSource(this.orderItems);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'in fetching order items');
            });
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = new Orders();
        this.setPagination(this.Idx, this.dataSource.data.length);
        this.displayDetail(this.selectedRecord);

    }
    public displayDetail(record: Orders) {
        if (record === undefined) {
            return;
        }
        this.id = record.id;
        this.activeStatus = record.activeStatus;
        this.history.onShowHandler('orders', 'id', record.id.toString());
    }
    public clear() {
        this.id = '';
        this.description = '';
        this.activeStatus = '';

    }
    public addModifyRecord(isAdd: boolean) {
        this.addUpdateFlag = isAdd;
        this.addOrder.doInitialize(this.addUpdateFlag);
        this.displayNewOrderDialog = true;
    }
    public onPropertiesToggle() {
        this.propContainerState = !this.propContainerState;
    }
    public tableKeyDown(event: KeyboardEvent) {
        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.selectRow();
                this.displayDetail(this.selectedRecord);
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.selectRow();
                this.displayDetail(this.selectedRecord);
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public validateFields(): boolean {

        this.strErr = '';
        const vr: ValidationResult = this.selectedRecord.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return false;
        }
        return true;
    }
    public submitRecord() {

        this.selectedRecord = new Orders();
        this.selectedRecord.id = this.id;
        this.selectedRecord.activeStatus = this.activeStatus;
        this.strErr = '';

        if (!this.validateFields()) {
            return;
        }
        if (this.isAdd === true) {
            this.ordersService.createOrders(this.selectedRecord).subscribe(
                (data) => {
                    this.selectedRecord.id = data.identifiers[0].id;
                    this.isEditing = false;
                    /*** add a transaction history record ***/
                    this.txService.createTransactionHistory('neworders', this.selectedRecord.id);
                },
                (error) => {
                    this.strErr = 'Error: The server could not add/submit this record';
                    return;
                }
            );
        } else {

            this.ordersService.updateOrders(this.selectedRecord).subscribe(
                (data) => {
                    this.isEditing = false;
                },
                (error) => {
                    this.strErr = 'Error: The server could not update this record';
                    this.selectedRecord = this.historyRecord;
                    return;
                });

            /*** add a transaction history record ***/
            this.txService.updateTransactionHistory(this.historyRecord,
                this.selectedRecord, 'neworders', this.selectedRecord.id);
        }
        this.updateSelectedRow();
    }
    /* Update the record in the table's data source - without refreshing it */
    public updateSelectedRow(): void {
        if (this.isAdd === true) {
            // add the new record to the top.
            this.records.unshift(this.selectedRecord);
            this.dataSource.data = this.records;
            this.Idx = 0;
        } else {
            const i: number = this.records.findIndex(r => r.id === this.selectedRecord.id);
            this.records[i] = this.selectedRecord;
            this.dataSource.data = this.records;
        }
        this.dataSource.sort = this.sort;
    }
    public onRowClicked(selected: any) {

        if (this.isEditing === true) {
            return;
        }
        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
        this.displayDetail(this.selectedRecord);
    }
    public onRowDoubleClicked(row: any): void {
        this.onRowClicked(row);
        this.addModifyRecord(false);
    }
    public cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
        this.displayDetail(this.selectedRecord);
    }
    public formatCurrency(inp: string): string {
        if (inp === undefined)
            return '';

        return DwUtils.formatCurrency(inp, 2);
    }
    /*** events from Add / Update Order ***/
    public addUpdateOrderClicked(event: any): void {
        this.updatedId = event as number;
        this.displayNewOrderDialog = false;
        // refresh the records to show the new item.
        this.queryRecords(true);

    }
    public cancelAddUpdateOrderClicked(): void {
        this.displayNewOrderDialog = false;
        /*** since the structures are passed by reference
         * we will need to re-query to remove any unwanted edits ***/
       // this.queryRecords(true);
    }
    public sendSelectedOrder() {
		const idx = this.records.findIndex(x => x.id === this.selectedRecord.id);
		const message = 'Send Receipt: \"' + this.selectedRecord.orderNumber + '\" for Order: \"' + this.selectedRecord.subCustomerName + '\" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				if (this.records[idx].id > 0) {
			this.ordersService.sendOrderByEmail(this.selectedRecord).subscribe(
				(data) => {
 
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Sending an order receipt');
				});
				}
			}
		});
	}
    public doAcceptOrderItems(): void {
        this.displayAcceptDialog = true;
        this.acceptOrderItems.doInitialize(this.selectedRecord.defaultFillSiteId);
    }
    public doAcceptOrderItemsClicked(): void {
        this.displayAcceptDialog = false;
        this.countChangedEvent.emit();
        this.queryRecords(true);
    }
}
