/**
 *  Filename ....: addpurchaseorder.component.ts
 *  Created by ..: ianday
 *  on Date .....: 5/16/23
 *  The Rule is that everything here in this module that relates to items ordered are purchaseOrderItems
 *  whereas in the addpoiitem.component.ts it is about supplierMeds (this will change when we introduce materials).
 **/
import { Component, EventEmitter, ViewChild, OnInit, Output, HostListener, Input } from '@angular/core';
import { SelectCreatePOComponent } from '../selectcreatepo/selectcreatepo.component';
import { SupplierItems } from '../../../../models/supplieritems';
import { Form, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Inventories } from '../../../../models/Inventories';
import { BulkUnitsService } from '../../../../services/bulkunits.service';
import { PurchaseOrderNumbers } from '../../../../models/purchaseordernumbers';
import { Settings } from '../../../../services/settings';
import { PurchaseOrderItems } from '../../../../models/purchaseorderitems';
import { PurchaseOrders } from '../../../../models/purchaseorders';
import { DwUtils } from '../../../../components/utils/dw-utils';
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactsService } from '../../../../services/Common/Contacts.service';
import { Contacts } from '../../../../models/contacts';
import { AddContactsComponent } from '../../../../components/addressbook/contacts/add-contact/add-contact.component';
import { AddMedPOItemComponent } from '../addmedpoitem/addmedpoitem.component';
import { TransactionHistoryService } from '../../../../services/transactionhistory.service';
import { AddMatPOItemComponent } from '../addmatpoitem/addmatpoitem.component';
import { __assign } from 'tslib';

export class ContactsShort {
	public id: number;
	public fullName: string;
	public emailAddress: string;
	public telephone: string;
	public cellPhone: string;
}

@Component({
	selector: 'app-addpurchaseorder',
	templateUrl: './addpurchaseorder.component.html',
	styleUrls: ['./addpurchaseorder.component.css']
})

export class AddPurchaseOrderComponent implements OnInit {

	public addEditFlag = false;
	public addEditPOItemFlag = false;
	public selectedRecord: PurchaseOrderItems;
	public records: Array<PurchaseOrderItems>;
	private _siteId: number;

	/*** Record structures ***/
	public purchaseOrder: PurchaseOrders;
	public purchaseOrderHist: PurchaseOrders;
	public contactsList: any;
	public selectedContact: ContactsShort;
	public contactIdx = -1;
	public valSubmitOption: number;

	/*** Services ***/
	public bulkUnitService: BulkUnitsService;
	public contactsService: ContactsService;
	public txService: TransactionHistoryService;
	/*** boolean flags etc. ***/
	public displayMedItemSelectionDlg = false;
	public displayMatItemSelectionDlg = false;
	public displayAddContactDlg = false;
	public displaySubmitPOOptionsDlg = false;

	public disableItems = true;
	public isEditing = true;

	public dataSource: MatTableDataSource<any>;
	public displayedColumns = ['ndcNumber', 'quantity', 'itemDescription', 'packageSize', 'unitCost', 'extendedCost'];
	public pagination = '';
	public Idx = -1;
	public updateIdx = -1;
	public dispDeaForm222 ;

	public strErr = '';
	public result: boolean | null = false;

	@Output() submitAddPOEvent = new EventEmitter();
	@Output() cancelAddPOEvent = new EventEmitter();

	@ViewChild('appselectcreatepo')
	appselectcreatepo: SelectCreatePOComponent;

	@ViewChild('addContact')
	addContact: AddContactsComponent;

	@ViewChild('addMedPOItem')
	addMedPOItem: AddMedPOItemComponent;

	@ViewChild('addMatPOItem')
	addMatPOItem: AddMatPOItemComponent;

	@Input() set siteId(val: number) {
		this._siteId = val;
	}
	get siteId(): number {
		return this._siteId;
	}
	public displaySelectDlg = false;
	constructor(bulkServices: BulkUnitsService,
		contactsService: ContactsService,
		txService: TransactionHistoryService,
		public dialog: MatDialog) {
		this.bulkUnitService = bulkServices;
		this.contactsService = contactsService;
		this.txService = txService;

		this.purchaseOrder = new PurchaseOrders();
		this.records = new Array<PurchaseOrderItems>();
	}

	ngOnInit() {
	}
	@HostListener('matSortChange', ['$event'])
	onSortChange(event: any) {

		if (this.dataSource === null) {
			return;
		}
		if (this.dataSource.data.length === 0) {
			return;
		}

		if (event.direction === 'asc') {
			this.records.sort((a, b) => a[event.active].toString().localeCompare(b[event.active].toString()));
		} else {
			this.records.sort((a, b) => b[event.active].toString().localeCompare(a[event.active].toString()));
		}

		this.dataSource = new MatTableDataSource(this.records);
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	/*** Form Functions ***/

	public showDeaForm222(): boolean {

		const num2s = this.records.filter(s => s.schedule === '2').length;

		if (num2s > 0) {
			return true;
		}
		return false;
	}
	public tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
			}
		}
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	public selectRow() {
		this.strErr = '';
		if (this.selectedRecord.foreignKeyId === 0) {
			this.unSelectRow();
			return;
		}
		/* use the foreign key Id */
		this.Idx = this.dataSource.filteredData.findIndex(s => s.foreignKeyId === this.selectedRecord.foreignKeyId);

	}
	public unSelectRow() {
		this.Idx = -1;
		this.selectedRecord = new PurchaseOrderItems();
		this.setPagination(this.Idx, this.dataSource.data.length);
	}
	public clearForm(): void {
		this.selectedContact = null;
		this.dataSource = null;
	}
	public onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);

	}
	public setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	/*** entry point ***/
	public doInitialize(adEditFlag: boolean): void {

		this.strErr = '';                   // clear error markers.
		this.addEditFlag = adEditFlag;
		this.appselectcreatepo.doInitialize();
		this.dataSource = new MatTableDataSource(this.records);

		if (this.addEditFlag === true) {
			this.displaySelectDlg = true;
			this.records = new Array<PurchaseOrderItems>();
			this.purchaseOrder = new PurchaseOrders();
			this.purchaseOrderHist = new PurchaseOrders();
			this.purchaseOrder.siteId = this.siteId;
			this.bulkUnitService.createPurchaseOrderNumber(new PurchaseOrderNumbers()).subscribe(
				(data) => {
					this.purchaseOrder.poNumber = data.purchaseOrderNumber;
					this.clearForm();
				},
				(error) => {
					Settings.getInstance().handleError(error, 'create purchase order number');
				});
		}
		else {
			/*** Existing PO so we need to load a few things ***/

			this.fetchContacts(true);       // and set the current one.
		}
		this.dispDeaForm222 = this.showDeaForm222();
	}
	public addUpdateItems(adEditFlag: boolean): void {

		this.strErr = '';
		this.addEditPOItemFlag = adEditFlag;

		if (this.addEditPOItemFlag === true) {
			this.selectedRecord = new PurchaseOrderItems();
			this.Idx = -1;
		}
		if (this.addEditPOItemFlag === false && this.Idx === -1) {
			this.strErr = 'Error: Please select an item first.';
			return;
		}

		const sm: SupplierItems = new SupplierItems();
		sm.setItemsFromPurchaseOrders(this.purchaseOrder, this.selectedRecord);

		if (this.purchaseOrder.supplierType === 'Medications') {
			this.displayMedItemSelectionDlg = true;
			this.addMedPOItem.doInitialize(sm, this.addEditPOItemFlag, this.records);
		}
		else {
			this.displayMatItemSelectionDlg = true;
			this.addMatPOItem.doInitialize(sm, this.addEditPOItemFlag, this.records);

		}
	}
	public removeSelectedItemRequest(): void {
		this.strErr = '';

		if (this.Idx === -1) {
			this.strErr = 'Error: Please select an item first.';
			return;
		}
		const idx = this.records.findIndex(x => x.ndcNumber === this.selectedRecord.ndcNumber);

		const message = 'Remove : "' + this.selectedRecord.itemDescription + '" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {

				if (this.records[idx].id > 0) {
					this.bulkUnitService.deletePurchaseOrderItem(this.records[idx].id).subscribe(
						(data) => {
							this.records.splice(idx, 1);
							this.dataSource = new MatTableDataSource<any>(this.records);
						},
						(error) => {
							Settings.getInstance().handleError(error, 'Deleting po item');
						});
				}
				else {
					this.records.splice(idx, 1);
					this.dataSource = new MatTableDataSource<any>(this.records);
				}
			}
		});
	}
	/*** entry point for add/update ***/
	public onSubmitClicked(): void {

		if ((this.dispDeaForm222 === true) &&
			(this.purchaseOrder.deaForm222.length === 0)) {
			this.strErr = 'Error: Must capture the form number for form 222';
			DwUtils.setFocus('deaForm222');
			return;
		}
		this.opentSubmiPOOptions();
	}
	/*** Submit dialog box ***/
	public opentSubmiPOOptions(): void {
		this.valSubmitOption = 1;
		this.displaySubmitPOOptionsDlg = true;
	}
	public radiobuttonChange(event: any): void {
		this.valSubmitOption = event.value;
	}
	public onSubmitProcessClicked(): void {
		this.displaySubmitPOOptionsDlg = false;
		/*** get the option that was selected ***/
		if (this.valSubmitOption < 3) {
			this.purchaseOrder.orderStatus = 'Submitted';
		}
		this.persistPurchaseOrder();
		this.throwSubmitEvent();
	}
	public onCancelSubmitProcessClicked(): void {
		this.displaySubmitPOOptionsDlg = false;
		this.cancelAddPOEvent.emit();
	}
	/*** Add / Update Records ***/
	public persistPurchaseOrder(): void {
		this.updateIdx = 0;
		if (this.addEditFlag === true) {
			this.bulkUnitService.createPurchaseOrder(this.purchaseOrder).subscribe(
				(data) => {
					// next create the purchase order items ...
					this.purchaseOrder.id = data.id;
					this.persistPurchaseOrderItems(this.purchaseOrder.id);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Creating a new purchase order');
				});
		}
		else {
			this.bulkUnitService.updatePurchaseOrder(this.purchaseOrder).subscribe(
				(data) => {
					this.persistPurchaseOrderItems(this.purchaseOrder.id);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Creating a new purchase order');
				});
		}
	}
	public persistPurchaseOrderItems(poId: number): void {

		if (this.updateIdx <= this.records.length - 1) {
			this.records[this.updateIdx].purchaseOrderId = poId;
			this.savePurchaseOrderItems(this.records[this.updateIdx++]);
			return;
		}
		if (this.valSubmitOption === 1) {
			this.bulkUnitService.sendPurchaseOrderByEmail(this.purchaseOrder).subscribe(
				(data) => {

				},
				(error) => {
					Settings.getInstance().handleError(error, 'Sending a purchase order');
				});
		}
		this.createTxHistoryRecord();
	}
	public savePurchaseOrderItems(poi: PurchaseOrderItems): void {

		/*** is it an add? ***/
		if (poi.id === 0) {
			this.bulkUnitService.createPurchaseOrderItem(poi).subscribe(
				(data) => {
					const id = data.id;
					/*** fetch the next item in the order ***/
					this.persistPurchaseOrderItems(this.purchaseOrder.id);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Adding a purchase order item');
				});
		}
		else {
			this.bulkUnitService.updatePurchaseOrderItem(poi).subscribe(
				(data) => {
					/*** fetch the next item in the order ***/
					this.persistPurchaseOrderItems(this.purchaseOrder.id);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Updating a purchase order item');
				});
		}
	}
	public createTxHistoryRecord(): void {

		if (this.addEditFlag === true) {
			this.txService.createTransactionHistory('purchaseorders',
				this.purchaseOrder.id, 'Create', 'Created Purchase Order: ' + this.purchaseOrder.poNumber);
		}
		else {
			this.txService.updateTransactionHistory(this.purchaseOrderHist, this.purchaseOrder, 'purchaseorders', this.purchaseOrder.id);
		}

		this.throwSubmitEvent();
	}
	public throwSubmitEvent(): void {
		this.submitAddPOEvent.emit();
	}
	/*** Add Update from CreatePO from Supplier or Meds ***/
	public submitFromSelectCreatePO(event: SupplierItems): void {
		const supplierMeds = event as SupplierItems;
		this.displaySelectDlg = false;
		this.purchaseOrder.supplierId = supplierMeds.supplierId;
		this.purchaseOrder.supplierName = supplierMeds.companyName;
		this.purchaseOrder.supplierType = supplierMeds.supplierType;

		this.fetchContacts(false);

		/*** If there is a medication in the supplierMeds structure add it. ***/
		if ((supplierMeds.supplierType === 'Medications') && (supplierMeds.itemId > 0)) {
			this.addEditPOItemFlag = true;
			this.doAddUpdatePOItem(supplierMeds);
		}
	}
	public cancelFromSelectCreatePO(): void {
		this.displaySelectDlg = false;
		this.onCancelClicked();
	}
	public onCancelClicked(): void {
		this.cancelAddPOEvent.emit();
	}
	/*** Quick Add Contacts ***/
	public quickAddContact(): void {
		this.displayAddContactDlg = true;
		this.addContact.afterOpenDialog(new Contacts(), true);
	}
	public setPurchaseOrderContactInfo(): void {
		this.purchaseOrder.contactId = this.selectedContact.id;
		this.purchaseOrder.contactName = this.selectedContact.fullName;
	}
	public newContactCreated(event: any): void {

		this.displayAddContactDlg = false;

		const sc: ContactsShort = new ContactsShort();

		sc.id = event.id;
		sc.fullName = event.fullName;
		sc.emailAddress = event.emailAddress;
		sc.telephone = event.telephone;
		sc.cellPhone = event.cellPhone;
		this.contactsList.push(sc);
		this.selectedContact = sc;
		this.disableItems = false;
	}
	public onContactDropDownKeyDown(event: any): void {
		const len: number = this.contactsList.length;
		if (event.key === 'ArrowDown') {
			if (this.contactIdx < (len - 1)) {
				this.selectedContact = this.contactsList[++this.contactIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.contactIdx > 0) {
				this.selectedContact = this.contactsList[--this.contactIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedContact = this.contactsList[this.contactIdx];
			this.disableItems = false;
		}
	}
	public onContactSelected(event: any): void {
		this.selectedContact = this.contactsList.find(x => x.id === event.value.id);
		this.setPurchaseOrderContactInfo();
		this.disableItems = false;
	}
	public fetchContacts(setFlag: boolean): void {

		this.contactsService.getContactsForAddressByForeignKey('suppliers', this.purchaseOrder.supplierId).subscribe(
			(data) => {

				this.contactsList = data;
				/*** set the current contact record (if it is known). ***/
				if (setFlag === true) {
					this.selectedContact = this.contactsList.find(x => x.id === this.purchaseOrder.contactId);
					if (this.selectedContact.id > 0) {
						this.setPurchaseOrderContactInfo();
						this.disableItems = false;
					}
				}
				else {
					/*** If there is just one contact,  we will just pre-select it ***/
					if (this.contactsList.length === 1) {
						this.selectedContact = this.contactsList[0];
						this.setPurchaseOrderContactInfo();
						this.disableItems = false;
					}
				}
			},
			(error) => {
				Settings.getInstance().handleError(error, 'getting contacts for addresses for supplier');
			});

	}
	/*** returned from the add / update of Purchase Order Items ***/
	public doAddUpdatePOItem(event: SupplierItems): void {
		const sm: SupplierItems = event as SupplierItems;
		const pi: PurchaseOrderItems = new PurchaseOrderItems();

		if (this.addEditPOItemFlag === true) {
			pi.setItemsFromSupplierMeds(sm, this.purchaseOrder.id);
			this.records.push(pi);
		}
		else {
			pi.setItemsFromSupplierMeds(sm, this.purchaseOrder.id);
			const idx = this.records.findIndex(x => x.foreignKeyId === pi.foreignKeyId);
			this.selectedRecord = pi;
			this.records[idx] = pi;
		}
		this.dispDeaForm222 = this.showDeaForm222();
		this.dataSource = new MatTableDataSource(this.records);
		this.displayMedItemSelectionDlg = false;
		this.displayMatItemSelectionDlg = false;
		this.updateTotals();
	}
	public resetTotals(): void {
		this.purchaseOrder.orderTotal = 0;
		this.purchaseOrder.grandTotal = 0;
	}
	public updateTotals(): void {
		if (this.records === undefined || this.records.length === 0) {
			return;
		}

		this.resetTotals();

		for (const item of this.records) {
			this.purchaseOrder.orderTotal += Number(item.extendedCost);
		}

		this.purchaseOrder.grandTotal = +
			this.purchaseOrder.orderTotal +
			Number(this.purchaseOrder.salesTax) +
			Number(this.purchaseOrder.shippingTotal);
	}

}


