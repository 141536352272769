import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Medication } from '../models/medication';
import { Settings } from './settings';
import { MedicationImage } from '../models/medicationimage';
import { PrecautionDirection } from '../models/precautiondirection';
import { Link } from '../models/link';
import { SupplierMedication } from '../models/supplier-medication';
import { MedicationFamily } from '../models/medicationfamily';
import {Ingredients } from '../models/ingredients';

@Injectable({
	providedIn: 'root'
})
export class MedicationService {

	constructor(private http: HttpClient) { }
	/** Medication Families */
	getAllMedicationFamily(): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medication-family')
			.pipe(
				retry(1)
			);
	}
	getMedicationFamilyByItemPrefix(itemPref: string): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medication-family/itemprefix/' + itemPref)
			.pipe(
				retry(1)
			);
	}
	createMedicationFamily(record: MedicationFamily): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/medication-family', record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationFamily(record: MedicationFamily): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medication-family/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteMedicationFamily(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/medication-family/' + id)
			.pipe(
				retry(1)
			);
	}
	/** Medications **/
	getAllMedications(): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medications')
			.pipe(
				retry(1)
			);
	}
	getMedicationsForOrderLookup(): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medications-for-order-lookup')
			.pipe(
				retry(1)
			);
	}
	getMedicationByNDC(ndc: string): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medications/ndc/' + ndc)
			.pipe(
				retry(1)
			);
	}
	getSupplierMedicationsBySupplierIdShort(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/supplier-medication-by-supplier-short/' + id)
			.pipe(
				retry(1)
			);
	}
	createMedication(record: Medication): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/medications', record)
			.pipe(
				retry(1)
			);
	}
	updateMedication(record: Medication): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medications/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationCostBase(id: number, costBase: number): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medications/costBase/' + id + '/' + costBase, null)
			.pipe(
				retry(1)
			);
	}
	deleteMedication(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/medications/' + id)
			.pipe(
				retry(1)
			);
	}
	/** Medication Images **/
	getAllMedicationImages(): Observable<MedicationImage[]> {
		return this.http.get<MedicationImage[]>(Settings.getInstance().BASE_URL + 'medications/medication-images')
			.pipe(
				retry(1)
			);
	}
	getMedicationImagesByMedicationId(medicationId: number): Observable<MedicationImage[]> {
		return this.http.get<MedicationImage[]>(Settings.getInstance().BASE_URL + 'medications/medication-images-by-medication/' + medicationId)
			.pipe(
				retry(1)
			);
	}
	getMedicationImagesByNDC(ndcNumber: string): Observable<MedicationImage[]> {
		return this.http.get<MedicationImage[]>(Settings.getInstance().BASE_URL + 'medications/medication-images/ndc/' + ndcNumber)
			.pipe(
				retry(1)
			);
	}
	createMedicationImage(record: MedicationImage, image: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('image', image, image.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'medications/medication-images/upload/image/' + record.medicationId + '/' + record.imageType, formData)
			.pipe(
				retry(0)
			);
	}
	updateMedicationImage(record: MedicationImage): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medication-images/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteMedicationImage(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/medication-images/' + id)
			.pipe(
				retry(1)
			);
	}
	/** Directions and precautions **/
	getMedicationDirectionsPrecautionsByMedicationFamilyId(medicationId: number): Observable<PrecautionDirection> {
		return this.http.get<PrecautionDirection>(Settings.getInstance().BASE_URL + 'medications/medication-directions-precautions-by-medication-family/' + medicationId)
			.pipe(
				retry(1)
			);
	}
	createMedicationDirectionsPrecaution(record: PrecautionDirection): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/medication-directions-precautions/', record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationDirectionsPrecaution(record: PrecautionDirection): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medication-directions-precautions/'+record.id, record)
			.pipe(
				retry(1)
			);
	}
	/** Medication Links **/
	getMedicationLinksByMedicationId(medicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/medication-links-by-medication/' + medicationId)
			.pipe(
				retry(1)
			);
	}
	createMedicationLink(record: Link): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/medication-links', record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationLink(record: Link): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/medication-links/'+record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteMedicationLink(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/medication-links/' + id)
			.pipe(
				retry(1)
			);
	}

	/** Medication Suppliers **/
	getMedicationSuppliersByMedicationId(medicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/supplier-medication-by-medication/' + medicationId)
			.pipe(
				retry(1)
			);
	}
	getMedicationSuppliersByMedFamilyId(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/supplier-medication-by-medfamily/' + id)
			.pipe(
				retry(1)
			);
	}
	createMedicationonSupplier(record: SupplierMedication): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/supplier-medication', record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationonSupplier(record: SupplierMedication): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/supplier-medication/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	updateMedicationonSupplierByFK(record: SupplierMedication): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/supplier-medication/FK/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteMedicationonSupplier(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/supplier-medication/' + id)
			.pipe(
				retry(1)
			);
	}

	/** Ingredients **/
	getIngredientsByMedicationFamilyId(medicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'medications/ingredients-by-medication/' + medicationId)
			.pipe(
				retry(1)
			);
	}
	createIngredients(record: Ingredients): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'medications/ingredients', record)
			.pipe(
				retry(1)
			);
	}
	updateIngredients(record: Ingredients): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'medications/ingredients/', record)
			.pipe(
				retry(1)
			);
	}
	deleteIngredients(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'medications/ingredients/' + id)
			.pipe(
				retry(1)
			);
	}
}
