<div style="display: table; width: 100%">
<mat-toolbar #toolbar class="card-toolbar" >
	<div style="width:33%; text-align:left;">
		  <button mat-icon-button (click)="menu.toggle($event, toolbar)">
			<mat-icon>menu</mat-icon>
		  </button>
	</div>

  <p-overlayPanel #menu [style]="{'left': '125px', 'top': '125px'}">
	  	<div style="width: 18rem; height: auto; display: inline-grid;">
			<span class="widget-title">Display Charts:</span>
			<div *ngFor="let item of this.totalMetricCardArr" class="widget-container">
				<p-checkbox name="metriccardsselection" [value]="item.id" [label]="item.title"
							[(ngModel)]="selectedMetricCards"></p-checkbox>
			</div>
			<div *ngFor="let item of this.totalChartArr" class="widget-container">
				<p-checkbox name="chartselection" [value]="item.id" [label]="item.title"
							[(ngModel)]="selectedCharts"></p-checkbox>
			</div>
		</div>
		<div style="width: auto; height: auto; display: block; margin-top: 1rem; margin-bottom: 3.5rem;">
			<button mat-stroked-button (click)="menu.toggle($event, toolbar); homeResetHandler($event)"
					class="widget-command-buttons"
					style="float: left">Reset</button>
			<button mat-stroked-button (click)="menu.toggle($event, toolbar); homeApplyHandler($event)" color="primary"
					class="widget-command-buttons"
					style="float: right; margin-left: 1rem;">Apply</button>
			<button mat-stroked-button (click)="menu.toggle($event, toolbar); homeCancelHandler($event)"
					class="widget-command-buttons"
					style="float: right">Cancel</button>
		</div>
  </p-overlayPanel>
	<div style="width:33%; text-align:center;">
		<span class="toolbar-options-span" style="text-align:right;">
			Site:
		</span>
		<mat-select id="site-filter-dropdown"
				class="mat-raised-button mat-drop-down-list dashboard-dropdown-lists"
				disableOptionCentering
				panelClass="dashboard-dropdown-panel"
				(keydown)="doSiteDropDownKeyDown($event);"
				(selectionChange)="doValueChange($event)"
				[(ngModel)]="selectedSite"	floatLabel="never">
			<mat-option *ngFor="let site of sitesList;" [value]="site">
				{{site.handle}}
			</mat-option>
		</mat-select>
	</div>

	<div style="width: 33%;">

	</div>
</mat-toolbar>
</div>

<div class="container-div">
	<!-- do we have metric cards? -->
	<div class="metric-card-container">
		<div *ngFor="let item of this.metricCardArr" style="display: inline-block;">
			<metric-card-component
					[ngStyle]="{'width':'270px'}"
			[selectedCard]="item"></metric-card-component>
	   	</div>
	</div>

  	<!-- regular graphs and charts -->
	<!--<app-gantt-chart-control [rows]="rows"></app-gantt-chart-control> -->

	<div class="card-container">
		<!--fxLayout="row wrap"	fxLayoutAlign="space-around center"
		 fxLayoutGap="4px grid">
		-->
		<div  *ngFor="let chart of this.chartArr"
			  style="display: inline-block; margin: 6px;border: none; box-shadow:none;">
			<!--
			 [ngClass]="{'inline-block' : chart.type === 'pie' || chart.type === 'bar'}">
				-->
				<!-- spacer to even out the horizontal channels -->
				<div *ngIf="chart.type === 'milestone-calendar' || chart.type === 'gantt-control'"
					 style="padding-top: 1px;" >
				</div>

			<card-component class="mat-elevation-z2" [refreshEvent]="refreshEvent"
							[selectedSite]="selectedSite"
							[selectedChart]="chart" >

			</card-component>
		</div>

	</div>
</div>
