import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Settings } from './settings';

@Injectable({
    providedIn: 'root'
  })
export class GlobalService {

   public globalEvent = new Subject();

}