<mat-card [ngClass]="selectedChart.type === 'pie' || selectedChart.type === 'bar' ?
		'half-size-card-item' : 'full-size-card-item'">
	<mat-card-header class="card-item-header" [style.justify-content]="selectedTitlePosition.code">
		<mat-card-title class="card-item-title">{{selectedChart.title}}</mat-card-title>
	</mat-card-header>

	<!--chart area-->

	<mat-card-content #cardContent class="card-body-content"
					  (window:resize)="onResizeWindow($event)">

		<app-graph #chart [selectedSite]="_selectedSite"
				   [CurrentChart]="selectedChart" [data]="data" > </app-graph>

		<!--
        <div *ngIf="selectedProduct.chart_type == 'custom-chart'">
          This is a custom chart
        </div>
        -->
	</mat-card-content>

	<mat-card-actions class="card-item-footer" fxLayout="row" fxLayoutAlign="end center">
		<!-- <a #configureLink style="float: right" [routerLink]="" (click)="configureClickHandler($event)">Configure</a> -->
		<mat-icon #configureLink style="float: right; color: dimgrey; cursor: pointer; outline: none !important;
    font-size: 20px;" matTooltip="Configure chart settings" [routerLink]=""
				  (click)="onLaunchConfigureSettings($event)">settings</mat-icon>
		<div #anchor style="width: 100%; height: 30px; margin-bottom: -7px !important;"></div>
	</mat-card-actions>
</mat-card>

<p-overlayPanel #configure appendTo="body" [dismissable]="false"
				[style]="{'width':'350px', 'contain':'content'}">
	<div style="width: 100%; height: auto;">
		<div style="margin-bottom:10px;font-size:12px;">
			<span class="chart-property-title">Chart Properties: </span>
			<Table width="100%" style="padding-top:13px;">

				<!--------------------------->
				<!-- GENERAL OPTIONS       -->
				<!--------------------------->
				<TR class="option_title" >
					<TD width="50%">Chart Legend Placement:</TD>
					<TD width="50%">

						<mat-select [(value)]="selectedLegendPosition"
									[(ngModel)]="selectedLegendPosition"
									[placeholder]="selectedLegendPosition.name"
									style="background: silver; padding: 5px; height: 25px;"
									(selectionChange)="onLegendPositionChange($event)">
							<mat-option *ngFor="let option of legendPositionOptions"
										[value]="option.code">{{option.name}}
							</mat-option>
						</mat-select>
					</TD>
				</TR>
				<TR class="option_title" >
					<TD width="50%">Data Label Placement:</TD>
					<TD width="50%">
						<mat-select [placeholder]="selectedChartData.name"
								[(ngModel)]="selectedChartData"
								[(value)]="selectedChartData"
								style="background: silver; padding: 5px; height: 25px;"
								(selectionChange)="onDataLabelPlacementChange($event)">
							<mat-option *ngFor="let option of chartDataOptions"
										[value]="option.code">{{option.name}}
							</mat-option>
						</mat-select>

					</TD>
				</TR>
				<!--------------------------->
				<!-- GANTT-CONTROL OPTIONS -->
				<!--------------------------->
				<TR class="option_title" *ngIf="selectedChart.type ==='gantt-control'">
					<TD width="50%">Time Period:</TD>

					<TD width="50%">
						<mat-select
								[(value)]="selectedTimeFrame"
								[(ngModel)]="selectedTimeFrame"
								[placeholder]="selectedTimeFrame.name"
								style="background: silver; padding: 5px; height: 25px;"
								(selectionChange)="onTimeFrameChange($event)">
							<mat-option *ngFor="let option of timeFrameOptions"
										[value]="option.code">{{option.name}}
							</mat-option>
						</mat-select>

					</TD>
				</TR>
				<!--------------------------->
				<!-- ROADMAP/GANTT OPTIONS -->
				<!--------------------------->
				<TR class="option_title" *ngIf="selectedChart.type ==='gantt'">
					<TD width="50%">Time granularity:</TD>

					<TD width="50%">
						<mat-select
								[(ngModel)]="selectedTimeLine"
								[(value)]="selectedTimeLine"
								[placeholder]="selectedTimeLine.name"
								style="background: silver; padding: 5px; height: 25px;"
								(selectionChange)="onTimelineChange($event)">
							<mat-option *ngFor="let option of timeLineOptions"
										[value]="option.code">{{option.name}}
							</mat-option>
						</mat-select>

					</TD>
				</TR>

				<!--------------------------->
				<!-- ROADMAP/GANTT CAPTURE -->
				<!--------------------------->
				<TR class="option_title" *ngIf="selectedChart.type ==='gantt' || selectedChart.type === 'gantt-control'">
					<TD width="50%">Capture Image:</TD>

					<TD width="50%">
						<button mat-button class="command-button" style="float: left; width: 150px; margin-left: 0px !important;"
								(click)="onCaptureClickHandler($event)"><mat-icon style="font-size: 1.2rem; padding-top: 2px;">
							camera</mat-icon>Capture</button>

					</TD>
				</TR>
			</Table>


		</div>
	</div>

	<div style="width: auto; height: auto; display: block; margin-top: 1rem; margin-bottom: 3.5rem;">
		<button mat-button class="command-button" style="float: left; width: 60px;"
				(click)="onResetHandler($event)">Reset
		</button>
		<button mat-button class="command-button" color="primary" style="float: right; margin-left: 1rem;
				width: 60px;" (click)="onApplyHandler($event)">Apply</button>
		<button mat-button class="command-button" style="float: right;; width: 60px;" (click)="onCancelHandler($event)">
			Cancel</button>
	</div>
</p-overlayPanel>
