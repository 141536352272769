import {ValidationResult} from "./validation-result";

export interface Contacts {

    id: number;
    foreignKeyId: number;
    foreignKeyTable: string;
    fullName: string | null;
    positionTitle: string | null;
    emailAddress: string | null;
    telephone: string | null;
    cellPhone: string | null;
    activeStatus: number | null;
}

export class Contacts {

    constructor() {
        this.id = 0;
        this.foreignKeyId = 0;
        this.foreignKeyTable = '';
        this.fullName = '';
        this.positionTitle = '';
        this.emailAddress = '';
        this.telephone = '';
        this.cellPhone = '';
        this.activeStatus = 0;
    }
    validateRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.fullName.length === 0) {
            vr.focus_field = 'fullName';
            vr.error = 'Error: a full name is required';
            return vr;
        }
        if (this.positionTitle.length === 0) {
            vr.focus_field = 'positionTitle';
            vr.error = 'Error: a position or title is required';
            return vr;
        }
        return vr;
    }
}
