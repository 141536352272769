import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[dwDragDrop]'
})
export class DragDropDirective {

    @Output() onFileDropped = new EventEmitter<any>();
    @Output() onFileDragOver = new EventEmitter<any>();
    @Output() onFileDragLeave = new EventEmitter<any>();
    
    @HostBinding('style.background-color') private background = '#f5fcff'
    @HostBinding('style.opacity') private opacity = '1'
      
    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#9ecbec';
      this.opacity = '0.8';
      this.onFileDragOver.emit();
    }
      
    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#f5fcff';
      this.opacity = '1';
      this.onFileDragLeave.emit();
    }
      
    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#f5fcff'
      this.opacity = '1'
      let files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.onFileDropped.emit(files)
      }
    }

}
