import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppComponent} from '../../../app.component';
import { AppFunctionGroup, APP_FUNCTION_GROUP_DATA } from 'src/app/models/app-function-group';
import {APP_FUNCTION_DATA, AppFunction} from 'src/app/models/app-function';
import { AppFunctionGroupMap } from 'src/app/models/app-function-group-map';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ElementRef } from '@angular/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Settings } from '../../../services/settings';

@Component( {
    selector: 'app-groupmanagement',
    templateUrl: './groupmanagement.component.html',
    styleUrls: ['./groupmanagement.component.css']
} )
export class GroupmanagementComponent implements OnInit {
    visibleAdd = false;
    visibleModify = false;
    visibleDelete = false;
    isEditing = false;
    isAdd = false;
    formContent: UntypedFormGroup;
    Idx = -1;
    selectedRecord: AppFunctionGroup;
    renderedData = Array<AppFunctionGroup>();
    displayedColumns: string[] = ['id', 'name', 'active'];
    dataSource: MatTableDataSource<AppFunctionGroup>; // = new MatTableDataSource(ELEMENT_DATA);
    pagination: string;
    formTitle = 'Group Management';
    result: boolean;
    disabledFlag = true;
    strErr = '';
    selectedFilterStatus = '1';
    enableDelete = false;
    appFunctionSearchStr = '';

    availableAppFunctionsList = Array<AppFunction>();
    assignedAppFunctionsList: any[];
    appFunctionsList: any[];
    filteredAppFunctionsList: any[];
    
    contentClassWithBanner = 'dwtable-container';
    contentClassWithoutBanner = 'dwtable-container-without-header';
    contentClassName = 'dwtable-container';

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('txtFilter', { static: true }) txtFilter: ElementRef;
    @ViewChild('groupname', { static: true }) groupname: ElementRef;

    constructor( private userService: UserService, public dialog: MatDialog ) {
        this.formContent = new UntypedFormGroup( {
            id: new UntypedFormControl( 'id' ),
            groupname: new UntypedFormControl( 'groupname' ),
            active: new UntypedFormControl( 'active' ),
        } );
        /* start out disabled in the detail form */

        this.formContent.get('id').setValue('');
        this.formContent.get('groupname').setValue('');
        this.formContent.disable();
    }

    @HostListener( 'matSortChange', ['$event'] )
    onSortChange( event ) {
        if (event.direction === 'asc') {
            this.renderedData.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.renderedData.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }
        this.dataSource.data = this.renderedData;
        this.Idx = this.renderedData.findIndex(s => s.id === this.selectedRecord.id);
    }
    applyFilter( filterValue: string ) {
        if ( this.isEditing ) return;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        this.unSelectRow( false );
        this.setPagination( -1, this.dataSource.filteredData.length );
    }
    clearFilter() {
        if ( this.isEditing ) return;
        this.dataSource.filter = '';

        this.unSelectRow( false );
        this.setPagination( -1, this.dataSource.data.length );
    }
    ngOnInit() {
      // Now let's set the access privilege level
      if (Settings.getInstance().hasPrivilege('MANAGE_ACCESS_GROUPS', false)) {
        this.visibleAdd = true;
      }
      if (Settings.getInstance().hasPrivilege('MANAGE_ACCESS_GROUPS', false)) {
        this.visibleModify = true;
      }
      if (Settings.getInstance().hasPrivilege('MANAGE_ACCESS_GROUPS', false)) {
        this.visibleDelete = true;
      }
      this.queryRecords();
        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
    }
    private queryRecords() {

      if(AppComponent.staticDataSource === true) {
        this.dataSource = new MatTableDataSource( APP_FUNCTION_GROUP_DATA );
        this.dataSource.sort = this.sort;
        this.setPagination( -1, this.dataSource.data.length );

        this.availableAppFunctionsList = APP_FUNCTION_DATA;

        return;
      }

      this.userService.getAllAppFunctionGroups().subscribe(
            ( data ) => {
                for (const rec of data){
                    if (rec['active'] === true) {
                        rec['displayStatus'] = 'Active';
                    } else {
                        rec['displayStatus'] = 'Inactive';
                    }
                }
                Settings.getInstance().completeAppFunctionGroupsList = data;
                this.dataSource = new MatTableDataSource( data );
                this.dataSource.sort = this.sort;
                this.setPagination( -1, this.dataSource.data.length );
                this.onChangeStatusFilterValue();
            },
            ( error ) => {
            }
        );
      this.userService.getAllAppFunctions().subscribe(
            ( data ) => {
                this.availableAppFunctionsList = data;
            },
            ( error ) => {
            }
        );
    }
    onRowClicked( SR: AppFunctionGroup ) {
        if (this.isEditing) return;
        this.selectedRecord = SR;
        this.enableDelete = false;
        this.userService.getUserAppFunctionGroupMapsByGroupId(this.selectedRecord.id).subscribe(
                ( data ) => {
                    if (data !== undefined && data.length>0) {  // No bounding- record can be removed safely
                        this.enableDelete = false;
                    } else {
                        // found binding; can't remove
                        this.enableDelete = true;
                    }
                },
                ( error ) => {
                }
            );
        
        
        this.renderedData = this.dataSource.connect().value;
        this.Idx = this.renderedData.indexOf( this.selectedRecord );
        this.setPagination( this.Idx, this.renderedData.length );
        this.displayDetail( this.selectedRecord );
    }
    unSelectRow( withZero: boolean ) {
        this.Idx = -1;
        this.selectedRecord = new AppFunctionGroup();
        //this.displayDetail( this.selectedRecord );
    }
    tableKeyDown( event: KeyboardEvent ) {
        if ( this.isEditing ) return;
        const len: number = this.dataSource.filteredData.length;
        if ( event.key === 'ArrowDown' ) {
            if ( this.Idx < ( len - 1 ) ) {
                this.selectedRecord = this.renderedData[++this.Idx];
                this.displayDetail( this.selectedRecord );
            }
        } else if ( event.key === 'ArrowUp' ) {
            if ( this.Idx > 0 ) {
                this.selectedRecord = this.renderedData[--this.Idx];
                this.displayDetail( this.selectedRecord );
            }
        }

        this.setPagination( this.Idx, this.renderedData.length );
    }
    setPagination( idx: number, total: number ) {
        this.pagination = ( idx + 1 ) + '/' + total;
    }
    displayDetail( rec: AppFunctionGroup ) {

        if (rec.id === null) return;

        this.assignedAppFunctionsList = [];

        this.appFunctionsList = [];
        this.userService.getAppFunctionGroupMapsByAppFunctionGroupId(this.selectedRecord.id).subscribe(
                ( data ) => {
                	this.appFunctionsList = [];
                    for ( const appFunRec of this.availableAppFunctionsList ) {
                    	let obj = {id: appFunRec.id, label: appFunRec.description, selected: false, readOnly: true};
                    	for(const rec of data) {
                    		if(appFunRec.id == rec.appFunctionId) {
                    			obj.selected = true;
                    			obj.readOnly = rec.readOnly;
                    		}
                    	}                        	
                        this.appFunctionsList.push(obj);
                    }
					this.appFunctionFilterChangehandler(null);
                },
                ( error ) => {
                }
        );
        this.formContent.get( 'id' ).setValue( rec.id );
        if ( rec.active === true ) {
            this.formContent.get( 'active' ).setValue( 'Active' );
        } else {
            this.formContent.get( 'active' ).setValue( 'InActive' );
        }
        this.formContent.get( 'groupname' ).setValue( rec.name );
    }
    addModifyRecord( isAdd: boolean ) {
        this.isEditing = true;
        this.disabledFlag = false;
        this.formContent.enable();

        this.formContent.get('id').disable();

        if ( isAdd === true ) {
            this.isAdd = true;
            this.unSelectRow( true );
            this.selectedRecord.id = 0;
            this.selectedRecord.active = true;
            this.setRecordValues(this.selectedRecord);
            this.assignedAppFunctionsList = [];
           // this.formContent.setValue( this.selectedRecord );

        } else {
            this.isAdd = false;

        }

        this.setFieldFocus();
    }
    setFieldFocus() {
      setTimeout(() => {
        this.groupname.nativeElement.focus(); },0);
    }
    setRecordValues(rec: AppFunctionGroup) {

      this.formContent.get('id').setValue(rec.id);
      this.formContent.get('groupname').setValue(rec.name);

      if (rec.active === true) {
        this.formContent.get('active').setValue('Active');
      } else {
        this.formContent.get('active').setValue('InActive');
      }
    }
    removeRecord() {
        const message = 'Remove this record - Are you sure?';
        const dialogData = new ConfirmDialogModel( 'Please Confirm', message );
        const dialogRef = this.dialog.open( ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        } );

        dialogRef.afterClosed().subscribe( dialogResult => {
            this.result = dialogResult;
            if ( this.result === true ) {
                this.userService.deleteAppFunctionGroup( this.selectedRecord.id ).subscribe(
                    ( data ) => {
                        this.queryRecords();
                    },
                    ( error ) => {
                        Settings.getInstance().handleError( error );
                    }
                );
            }
        } );
    }
    validateRecord(): boolean {
        this.strErr = '';
        if (this.formContent.get( 'groupname' ).value === null || this.formContent.get( 'groupname' ).value == undefined || this.formContent.get( 'groupname' ).value.length === 0) {
            this.strErr = 'Error: A group description is required';
            this.setFieldFocus();
            return false;
        }
        if (this.isAdd && this.getAppFunctionGroupRecordByName(this.formContent.get('groupname').value) !== null) {
            this.strErr = 'Error: A group with this description already exists';
            this.setFieldFocus();
            return false;
        }
        return true;
    }
    submitRecord() {
      if (this.validateRecord() === false) {
          return;
      }
      const SR: AppFunctionGroup = this.formContent.getRawValue();
      this.isEditing = false;
      const selectedAppFunctionsList: any[] = [];
      for ( let i = 0; i < this.appFunctionsList.length; i++ ) {
        if ( this.appFunctionsList[i].selected) {
            selectedAppFunctionsList.push({appFunctionId: this.appFunctionsList[i].id, readOnly:this.appFunctionsList[i].readOnly});
        }
      }
      if ( this.isAdd ) {
        // Let's add a new group now!
        const newRecord:any = {};
        newRecord.id = this.formContent.get( 'id' ).value;
        newRecord.name = this.formContent.get( 'groupname' ).value;
        newRecord.appFunctions = selectedAppFunctionsList;
        if ( this.formContent.get( 'active' ).value === 'Active' ) {
            newRecord.active = true;
        } else {
            newRecord.active = false;
        }
        this.userService.createAppFunctionGroups( newRecord ).subscribe(
            ( data ) => {
                const appFunctionsMapList: any[] = [];
                if (data !== null && data['raw']!==undefined) {
                     for (const rec of newRecord.appFunctions) {
                         appFunctionsMapList.push({appFunctionId: rec.appFunctionId, readOnly: rec.readOnly});
                     }
                     this.userService.createAppFunctionGroupMaps(data['raw'][0]['id'], appFunctionsMapList).subscribe(
                         ( data ) => {
                             console.log( 'group memberships created/updated' );
                             this.queryRecords();
                         },
                         ( error ) => {
                         }
                     );
                }
            },
            ( error ) => {
            }
          );
    	} else {
    		const newRecord:any = {};
            newRecord.id = this.selectedRecord.id;
            newRecord.name = this.formContent.get( 'groupname' ).value;
            newRecord.appFunctions = selectedAppFunctionsList;
	        if ( this.formContent.get( 'active' ).value === 'Active' ) {
	            this.selectedRecord.active = true;
	        } else {
	            this.selectedRecord.active = false;
	        }
	        this.userService.updateAppFunctionGroups( this.selectedRecord ).subscribe(
	            ( data ) => {
	                const appFunctionsMapList: any[] = [];
	                 for (const rec of newRecord.appFunctions) {
	                     const temp: AppFunctionGroupMap = new AppFunctionGroupMap ();
	                     appFunctionsMapList.push({appFunctionId: rec.appFunctionId, readOnly: rec.readOnly});
	                 }
	                 this.userService.createAppFunctionGroupMaps(this.selectedRecord.id, appFunctionsMapList).subscribe(
	                         ( data ) => {
	                             console.log( 'group memberships created/updated' );
	                             this.queryRecords();
	                         },
	                         ( error ) => {
	                         }
	                     );
	                console.log( 'group updated' );
	                this.queryRecords();
	            },
	            ( error ) => {
	            }
	        );
	    }
      	this.formContent.disable();
      	this.disabledFlag = true;
    }
    cancelSubmitRecord() {
      this.strErr = '';

      if(this.isAdd === false) {
         this.displayDetail(this.selectedRecord);
      }
      this.isEditing = false;
      this.formContent.disable();
      this.disabledFlag = true;
    }
    updateListTable( SR: AppFunctionGroup ) {
        if ( this.Idx > -1 ) {
            /* set the value at the index position */
            this.dataSource.data[this.Idx] = SR;
        } else {
            /* Append it to the bottom of the table */
            this.dataSource.data.push( SR );
        }
    }
    getAppFunctionGroupRecord( id: number ): AppFunctionGroup {
        for ( const rec of Settings.getInstance().completeAppFunctionGroupsList ) {
            if ( rec.id === id ) {
                return rec;
            }
        }
        return null;
    }
    getAppFunctionGroupRecordByName( param: string ): AppFunctionGroup {
        for ( const rec of Settings.getInstance().completeAppFunctionGroupsList ) {
            if ( rec.name === param ) {
                return rec;
            }
        }
        return null;
    }
    getAppFunctionRecord( id: number ): AppFunction {
        for ( const rec of this.availableAppFunctionsList ) {
            if ( rec.id === id ) {
                return rec;
            }
        }
        return null;
    }
    onChangeStatusFilterValue() {
        const dataTemp: AppFunctionGroup[] = [];
        if (this.selectedFilterStatus === '0') {
              for (const rec of Settings.getInstance().completeAppFunctionGroupsList){
                if (rec.active === false) {
                    dataTemp.push(rec);
                }
            }
        } else  if (this.selectedFilterStatus === '1') {
              for (const rec of Settings.getInstance().completeAppFunctionGroupsList){
                if (rec.active === true) {
                    dataTemp.push(rec);
                }
            }
        } else  if (this.selectedFilterStatus === '2') {
              for (const rec of Settings.getInstance().completeAppFunctionGroupsList){
                    dataTemp.push(rec);
            }
        }
        this.dataSource = new MatTableDataSource( dataTemp );
        this.dataSource.sort = this.sort;
        this.setPagination( -1, this.dataSource.data.length );
    }
    appFunctionFilterChangehandler(event) {
    	this.filteredAppFunctionsList = [];
        for ( const appFunRec of this.appFunctionsList ) {      	
    		if(appFunRec.label.toLowerCase().indexOf(this.appFunctionSearchStr) != -1) {
    			this.filteredAppFunctionsList.push(appFunRec);
    		}
    	}                        	
    }
    onAppFunctionSelectionChange(appFunction, event) {
    	
    }
    onAppFunctionReadOnlyChange(appFunction, event) {
    	
    }
}
