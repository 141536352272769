<!--
    file type .....: HTML file: globalsearch.component.html
    Created By ....: ianday
    Date ..........: 8/28/23 
-->
<div class="globalsearch-container">
    <!-- query criteria -->
    <div style="height: 150px;">
        <div class="dwform" style="padding-right:15px;">
            <div class="dwform-cell-nopadding dwform-cell-override">
                <div class="dwform-row-gap"></div>

                <div class="dwform-row" >
                    <label class="label-field">Customer:</label>
                    <span class="form-required"> </span>

                    <input mat-input [disabled]="disabledFlag"
                           matTooltip="Enter a customer name or part"
                           id="customerNameParamId"
                           class="input-field" style="width: 200px;"
                           [(ngModel)]="formingQBE.customerNameParam">
                </div>
                <!-- Medication -->
                <div class="dwform-row-gap"></div>
                <div class="dwform-row" >
                    <label class="label-field">Medication:</label>
                    <span class="form-required"> </span>

                    <input mat-input [disabled]="disabledFlag"
                           matTooltip="Enter a medication description or part"
                           class="input-field" style="width: 200px;"
                           [(ngModel)]="formingQBE.medicationParam">
                </div>
                <!-- Date Range -->
                <div class="dwform-row-gap"></div>
                <div class="dwform-row" >
                    <label class="label-field">Date From:</label>
                    <span class="form-required"> </span>

                    <input mat-input [disabled]="disabledFlag"
                           [textMask]="{mask: datemask}"
                           class="input-field-short"
                           matTooltip="Format: MM/DD/YYYY"
                           [(ngModel)]="formingQBE.dateFromParam">
                </div>
            </div>
            <!-- Second Column -->
            <div class="dwform-cell-nopadding dwform-cell-override">
                    <!-- Order Number -->
                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row" >
                        <label class="label-field">Order Number:</label>
                        <span class="form-required"> </span>

                        <input mat-input [disabled]="disabledFlag"
                               matTooltip="Order number (or part)"
                               class="input-field-short"
                               [(ngModel)]="formingQBE.orderParam">
                    </div>
                <!-- Order Number -->
                <div class="dwform-row-gap"></div>
                <div class="dwform-row" >
                    <label class="label-field">Item Status:</label>
                    <span class="form-required"> </span>

                    <mat-select
                            disableOptionCentering
                            [(ngModel)]="formingQBE.itemStatusParam" #cbStatus
                            floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 10pt;width: 180px;"
                            panelClass="local-drop-down-panel"
                            (selectionChange)="onItemStatusChange($event)">
                        <mat-option *ngFor="let item of statusList"
                                    [value]="item.id">{{item.description}}</mat-option>
                    </mat-select>
                </div>
                    <!-- Date To -->
                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row" >
                        <label class="label-field">Date To:</label>
                        <span class="form-required"> </span>
                        <input matInput [disabled]="disabledFlag"
                               [textMask]="{mask: datemask}"
                               class="input-field-short"
                               matTooltip="Format: MM/DD/YYYY"
                               [(ngModel)]="formingQBE.dateToParam">
                    </div>
                </div>

            </div>
    </div>
    <!-- Clear / Search Strip -->
    <div style="display: inline-block; width: 33%;"></div>
    <div style="display: inline-block; text-align:center; width: 33%;
            margin-top: 28px; padding-bottom: 10px;">
         <button mat-button class="command-button"
                (click)="searchButtonClicked()"
                matTooltipPosition="above"
                matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Search
        </button>
        <button mat-button class="command-button"
                matTooltipPosition="above"
                (click)="resetQueryCriteria()"
                matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Reset
        </button>
    </div>

    <div style="display: inline-block; text-align: right; width:33%;font-size:9pt">
        Results: {{pagination}}
    </div>



    <mat-divider style="padding-bottom: 5px;"> </mat-divider>
    <!-- Results Table -->
    <div>
    <div class="table-container mat-elevation-z1" style="height:290px; width:100%"
         (keydown)="tableKeyDown($event)" tabindex="-1">

        <table mat-table [dataSource]="dataSource" matSort
               class="table-header-style-override"
               (matSortChange)="onSortChange($event)">
            <!-- Select -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override" style="width: 5px;">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="toggleAllSelected($event)"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
                    style="width:5px !important;">
                    <mat-checkbox (click)="$event.stopPropagation();"
                                  (change)="runChecked($event)"
                                  [disabled]="false"
                                  [value]="element.select" [(ngModel)]="element.select" >
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- Customer -->
            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 150px;">Customer
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.customerName}}</td>
            </ng-container>

            <!-- Order Number -->
            <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 50px;">Order
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.orderNumber}}</td>
            </ng-container>

            <!-- Order Item -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 190px;">Item
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.description}}</td>
            </ng-container>

            <!-- Submitted Date -->
            <ng-container matColumnDef="dateCompleted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 50px;">Date
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.dateCompleted | date: 'MM/dd/yyyy'}}</td>
            </ng-container>

            <!-- amount -->
            <ng-container matColumnDef="extendedPrice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 50px;">Amount
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.extendedPrice | currency: 'USD'}}</td>
            </ng-container>
            <!-- status -->
            <ng-container matColumnDef="itemStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 75px;">Status
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemStatus}}</td>
            </ng-container>
            <!-- status -->
            <ng-container matColumnDef="itemId" *ngIf="false">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-style-override"
                    style="width: 75px;">Id
                </th>
                <td mat-cell
                    class="table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemId}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (click)="onRowClicked(row)" >
            </tr>

        </table>
    </div>
    </div>
    <!----------------------->
    <!-- ERROR LINE --------->
    <!----------------------->
    <div class="error-line" [hidden]='!strErr.length'
         style="text-align:left; bottom:50px; left: 15px;">{{strErr}}</div>
    <!----------------------->
    <!-- SAVE STRIP        -->
    <!----------------------->
    <div style="text-align:center; margin-top: 28px;">
        <button mat-button class="command-button" [disabled]="!enableSubmit"
                (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Select
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
