import { Settings } from '../../../../services/settings';
import { SelectuserComponent } from '../../../../components/selectuser/selectuser.component';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-report-params',
  templateUrl: './report-params.component.html',
  styleUrls: ['./report-params.component.css']
})
export class ReportParamsComponent implements OnInit {

  @Input() ParamSpecs: any[];
  @Input() parent: any;
  @ViewChild('se', { static: true }) fEmployeeLookUp: SelectuserComponent;
  displayEmployeeList = false;
  displayOrganizationList = false;
  formDisableFlag = false;
  valResourceFullName = '';

  valSelectedEmployee = '';
  strSelectedOrganizationName = '';
  valStartDate: any;
  valEndDate: any;
  valLocation = '';
  valProgram = '';
  selectedProgram: any;
  selectedDateCategory: any;
  valSkipTeamTotal: Array<any>;
  lblMessageColor = 'foreground-red';
  lblMessage = '';
  programList: any;
  locationList: any;
  DateCategoryList: any;
  selectedLocation: any;
  @Output() SubmitClicked = new EventEmitter();
  @Output() CloseClicked = new EventEmitter();

  constructor() {

  }

  ngOnInit() {
    // this.programList = AppSettings.programList;
    // this.locationList = AppSettings.locationsList;
    this.DateCategoryList = [{data: 'create_date', label: 'Work Unit Create Date'}, {data: 'assigned_date', label: 'Work Unit Assigned Date'}, {data: 'completed_date', label: 'Work Unit Completed Date'}]
    this.queryRecords();
  }
  queryRecords() {
      /*
      this.billingService.getBillingImports().subscribe(
              ( data ) => {
                  this.importList = data['result'];
                  for (const rec of this.importList) {
                      rec['dateRange'] = rec.startDate + ' - ' + rec.endDate + ' (Batch: ' + rec.id + ')';
                      }
              },
      ( error ) => {
              }
      );
      */
  }
  showDialogEmployee() {
      this.displayEmployeeList = true;
      this.fEmployeeLookUp.queryRecords();
      this.formDisableFlag = true;
  }
  setResource(data) {
      this.valResourceFullName = data.name;
      this.valSelectedEmployee = data.username;
      this.displayEmployeeList = false;
      this.formDisableFlag = false;
    }
    CancelClickedfromSelectEmployee() {
      this.displayEmployeeList = false;
      this.formDisableFlag = false;
    }
  
  cancelClickedfromOrganizationSelector() {
      this.displayOrganizationList = false;
      this.formDisableFlag = false;
    }
  doRun() {
    if (this.validateFields()) {
      if (this.selectedLocation === undefined) {
        let obj = new Object();
        obj['LocationCode'] = 'ALL';
        this.selectedLocation = obj;
      }
      if (this.selectedProgram === undefined) {
        let obj = new Object();
        obj['ProgramCode'] = 'ALL';
        this.selectedProgram = obj;
      }
      this.SubmitClicked.emit();
      this.lblMessage = '';
    }
  }
  close() {
    this.lblMessage = '';
    this.CloseClicked.emit();
  }
  validateFields(): boolean {
    let ret = true;
    for (let i = 0; i < this.ParamSpecs.length; i++) {
      const param_obj = this.ParamSpecs[i];
      if (param_obj.isRequired===true && param_obj.name === 'UserUid' && this.valSelectedEmployee === '') {
        this.lblMessage = 'Please select Employee';
        ret = false;
        break;
      }
      if (param_obj.isRequired===true && param_obj.name === 'StartDate' && (this.valStartDate === undefined || this.valStartDate == null || this.valStartDate === '')) {
        this.lblMessage = 'Please select Start Date';
        ret = false;
        break;
      }
      if (param_obj.isRequired===true && param_obj.name === 'EndDate' && (this.valEndDate === undefined || this.valStartDate == null || this.valEndDate === '')) {
        this.lblMessage = 'Please select End Date';
        ret = false;
        break;
      }
      if(param_obj.isRequired===true && param_obj.name==='DateFieldTarget' && (this.selectedDateCategory === undefined || this.selectedDateCategory == null) && (this.valStartDate != null || this.valEndDate != null)) {
          this.lblMessage = 'Please select Date Category';
          ret=false;
          break;
      }
    }
    return ret;
  }
 

}




