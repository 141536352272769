/**
 *  Filename ....: add-contact.component.ts
 *  Created by ..: ianday
 *  on Date .....: 2/24/23
 **/
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Contacts } from '../../../../models/contacts';
import { ValidationResult } from '../../../../models/validation-result';
import { ContactsService } from '../../../../services/Common/Contacts.service';
import { DwUtils } from '../../../utils/dw-utils';
import {AddressesService} from '../../../../services/Common/Addresses.service';
import {Settings} from '../../../../services/settings';
import {Addresses} from '../../../../models/addresses';

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact.component.html',
    styleUrls: ['./add-contact.component.css']
})

export class AddContactsComponent implements OnInit {

    public record: Contacts;
    public _parentId: number;
    public _callingComponent: string;
    public id = 0;
    public fullName = '';
    public positionTitle = '';
    public telephone = '';
    public cellPhone = '';
    public emailAddress = '';
    public contactsService: ContactsService;
    public addressService: AddressesService;
    public strErr = '';
    public addEditFlag: boolean;

    @Output() cancelAddContact = new EventEmitter();
    @Output() submitAddContact = new EventEmitter<Contacts>();

    @Input() set parentId(value: number) {
        this._parentId = value;
    }

    get parentId(): number {
        return this._parentId;
    }

    @Input() set callingComponent(value: string) {
        this._callingComponent = value;
    }
    get callingComponent(): string {
        return this._callingComponent;
    }

    constructor(csvc: ContactsService, adsvc: AddressesService) {
        this.contactsService = csvc;
        this.addressService = adsvc;
    }

    ngOnInit() {

    }

    initializeRecord(c: Contacts): void {

        this.id = c.id;
        this.fullName = c.fullName;
        this.positionTitle = c.positionTitle;
        this.telephone = c.telephone;
        this.cellPhone = c.cellPhone;
        this.emailAddress = c.emailAddress;

        if(this.callingComponent !== 'addresses') {
            this.fetchOldestAddress();
        }
    }
    afterOpenDialog(inputRec: Contacts, aeFlag: boolean): void    {
        this.addEditFlag = aeFlag;
        this.initializeRecord(inputRec);

        setTimeout(() => {

            DwUtils.setFocus('fullName');
        },400);
    }
    doCancel() {
        this.cancelAddContact.emit();
    }

    public fetchOldestAddress(): void    {
       /*** Problem - sometimes contacts can be created when no address exists **/
       /*** So we will look for the oldest address and use it.   If there isn't one **/
       /*** we will create a default one for the customer/supplier/site and use its keys **/

        this.addressService.getAddressesByFK(this.callingComponent, this.parentId).subscribe(
            (data) => {
                if (data.length > 0) {
                    /*** we've found an address - fetch the first one and use the keys. ***/
                    this.setCallingElements('addresses', data[0].id);
                }
                else {
                    /*** We've got to create one :( ***/
                    this.createNewAddress();
                }
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching Address in contact module');
            });
    }

    public createNewAddress(): void {
        /*** instantiate a local Addresses record - set it to the default values ***/
        const address = new Addresses();
        address.setDefaultValues(this.callingComponent, this.parentId);

        this.addressService.createAddress(address).subscribe (
            (data) => {
               this.setCallingElements('addresses', data.id);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Creating default address in contacts module');
            });
    }
    setCallingElements(comp: string, id: number): void {
        this.callingComponent = comp;
        this.parentId = id;
    }
    setRecord(): void {
        this.record = new Contacts();
        this.record.id = this.id;
        this.record.foreignKeyTable = this.callingComponent;
        this.record.foreignKeyId = this.parentId;
        this.record.fullName = DwUtils.capitalize(this.fullName).trim();
        this.record.positionTitle = DwUtils.capitalize(this.positionTitle).trim();
        this.record.telephone = this.telephone.trim();
        this.record.cellPhone = this.cellPhone.trim();
        this.record.emailAddress = this.emailAddress.trim();
        this.record.activeStatus = 1;
    }
    doSubmit() {
        // this is where we stuff the new address and emit it back.
        this.setRecord();

        this.strErr = '';
        const vr: ValidationResult = this.record.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return;
        }
        // add the record ...
        if (this.addEditFlag === true) {
            this.contactsService.createContact(this.record).subscribe(
                (data) => {
                    if (data !== undefined) {
                        this.record.id = data.id;        // get the Id ...
                    }
                    this.submitAddContact.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to create / save this record ';
                    return;
                });
        }
        else {
            this.contactsService.updateContact(this.record).subscribe(
                (data) => {
                    this.submitAddContact.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to update this record ';
                    return;
                });
        }
    }
}


