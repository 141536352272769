  export interface PrecautionDirection {
  id: number;
  precautions: string | null;
  altPrecautions: string | null;
  directions: string | null;
  altDirections: string | null;
  active: number | null;
  dateAdded: number | null;
  addedBy: string | null;
  medicationFamilyId: number;
}


export class PrecautionDirection {

  constructor() {
    this.id = 0;
    this.precautions = '';
    this.altPrecautions = '';
    this.directions = '';
    this.altDirections = '';
    this.active = 0;
    this.dateAdded = null;
    this.addedBy = '';
    this.medicationFamilyId = 0;
  }
}
