	<link rel="stylesheet" href="medication-ingredients.component.css">
	<div class="med-ingredients-container">
		<div style="width: 100%; margin-top: 15px; margin-bottom: 25px;padding-left: 20px;padding-right: 2px;">
			<button mat-button style="float: right;" [disabled]="this._medication===undefined || this._medication.id===0"
				class="command-button" matTooltip="Add a new record"
				(click)="addRecord()" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add
			</button>
		</div>

		<div class="med-ingredients-table-container" (keydown)="tableKeyDown($event)" tabindex="-1">
			<table *ngIf=!isEditing #table id="table"
				   mat-table [dataSource]="medicationIngredients" style="width: 100%"
				    cdkDropList
  					[cdkDropListData]="dataSource"
  					(cdkDropListDropped)="dropTable($event)"
  			>

				<ng-container matColumnDef="listSequence">
					<th mat-header-cell *matHeaderCellDef 
						style="width: 3%; min-width: 10px;padding-left: 0px; border-bottom: .5px solid #e1e1e1!important;
					font-size: 9pt; z-index: 99999;background-color: white;">  </th>
					<td mat-cell *matCellDef="let medingredient" minWidth="100px;"
						style="width: 3%; min-width: 10px;padding-left: 0px; font-size: 9pt"><span>
						  <mat-icon style="pointer-events: all;"
      						cdkDragHandle>reorder</mat-icon>
					</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="ingredient">`
					<th mat-header-cell *matHeaderCellDef  style="width: 45%;
					min-width: 80px;font-size: 9pt; border-bottom: .5px solid #e1e1e1!important;
					z-index: 99999;background-color: white;"> Ingredient </th>
					<td mat-cell *matCellDef="let medingredient" fxLayout="column wrap"  style="
					width: 45%; min-width: 80px">
					<span>{{medingredient.ingredient}}</span></td>
				</ng-container>
				<ng-container matColumnDef="quantity">`
					<th mat-header-cell *matHeaderCellDef 
						style="width: 8%; min-width: 10px;    border-bottom: .5px solid #e1e1e1!important;
						font-size: 9pt;	z-index: 99999;background-color: white;"> Qty </th>
					<td mat-cell *matCellDef="let medingredient" fxLayout="column wrap"
						style=" font-size: 9pt; width: 8%; min-width:10px">
					<span>{{medingredient.quantity}}</span></td>
				</ng-container>
					<ng-container matColumnDef="uom">`
					<th mat-header-cell *matHeaderCellDef 
						style="width: 10%; min-width: 10px;    border-bottom: .5px solid #e1e1e1!important;
						font-size: 9pt;	z-index: 99999;background-color: white;"> Unit </th>
					<td mat-cell *matCellDef="let medingredient" fxLayout="column wrap"
						style=" font-size: 9pt; width: 10%; min-width:10px">
					<span>{{medingredient.uom}}</span></td>
				</ng-container>
					<ng-container matColumnDef="abcxyiCode">`
					<th mat-header-cell *matHeaderCellDef 
						style="width: 5%; min-width: 20px;    border-bottom: .5px solid #e1e1e1!important;
						font-size: 9pt;	z-index: 99999;background-color: white;"> Code </th>
					<td mat-cell *matCellDef="let medingredient" fxLayout="column wrap"
						style=" font-size: 9pt; width: 5%; min-width:20px">
					<span>{{medingredient.abcxyiCode}}</span></td>
				</ng-container>

				<ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef 
						style="width: 10%; min-width: 10px;background-color: white;z-index: 99999;
						   border-bottom: .5px solid #e1e1e1!important;"> </th>
					<td mat-cell *matCellDef="let medingredient" style="width: 10%; min-width: 30px;padding-right: 3px;;
					text-align: right;">
						<span>
							<mat-icon class="edit-icon-ingredients" (click)="this.selectedRecord = medingredient;this.showDialogAddEditRecord()">edit</mat-icon>
							<img style="width: 12px;cursor: pointer" class="edit-delete-button" alt="remove" src="assets/delete-icon.png" (click)="deleteIngredient(medingredient)">
						</span></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
					<tr mat-row
						*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
						cdkDrag [cdkDragData]="row">

				</tr>
			</table>
		</div>
	</div>

<p-dialog header="Add/Edit ingredient"
          [(visible)]="displayAddEditIngredient"
          *ngIf="displayAddEditIngredient"
          [modal]="true" [responsive]="true"
          [style]="{width: '605px', height: '330px'}"
          [minY]="75"
          appendTo="body"
          [resizable]="false" [closable]="false">
   <app-addeditmedicationingredients #addeditingredient [Medication]="_medication" [selectedRecord]="selectedRecord" 
         (cancelClicked)="cancelClickedFromAddeditRecord()"
         (submitClicked)="submitClickedFromAddeditRecord()">
   </app-addeditmedicationingredients>
</p-dialog>
