import { Settings } from './settings';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class ReportsService {

  public reportDataLoadedEvent$: EventEmitter<any>;
  public reportSpecificationsLoadedEvent$: EventEmitter<any>;
  public reportResultErrorEvent$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.reportDataLoadedEvent$ = new EventEmitter();
    this.reportResultErrorEvent$ = new EventEmitter();
    this.reportSpecificationsLoadedEvent$ = new EventEmitter();
  }

  getReportSpecifications(): Observable<any> {
    return this.http.get(Settings.getInstance().BASE_URL + 'reports/specifications')
                .pipe(
                     retry(1)
                 );
  }

  getReportData(operationName: string, params: any[]) {
    if (params == null || params.length === 0) {
      this.http.get(Settings.getInstance().BASE_URL + 'reports/' + operationName).
        subscribe(
                  data => this.getReportDataResultHandler(data),
                  error => this.getReportDataErrorHandler(error)
                );
    } else {
      let queryString = '?';
      for (let i = 0; i < params.length; i++) {
        queryString = queryString + params[i].name + '=' + params[i].value + (((i + 1) === params.length) ? '' : '&');
      }
      this.http.get(Settings.getInstance().BASE_URL + 'reports/' + operationName + queryString).
        subscribe(
                  data => this.getReportDataResultHandler(data),
                  error => this.getReportDataErrorHandler(error)
                );
    }
  }

  getReportDataResultHandler(data: object) {
      this.reportDataLoadedEvent$.emit(data['result']);
  }
  getReportDataErrorHandler(error: HttpErrorResponse) {
      this.reportResultErrorEvent$.emit();
      Settings.getInstance().handleError(error);
  }

}
