/**
 *  Filename ....: addpoitem.component.ts
 *  Created by ..: ianday
 *  on Date .....: 5/24/23
 **/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {MedicationService} from '../../../../services/medication.service';
import {MatTableDataSource} from '@angular/material/table';
import {SupplierItems} from '../../../../models/supplieritems';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {Settings} from '../../../../services/settings';
import {map, startWith} from 'rxjs/operators';
import {PurchaseOrderItems} from '../../../../models/purchaseorderitems';
import { SupplierMedication } from '../../../../models/supplier-medication';
import {TransactionHistoryService} from '../../../../services/transactionhistory.service';
import {MaterialService} from '../../../../services/material.service';
import {SupplierMaterial} from '../../../../models/supplier-material';

@Component({
    selector: 'app-addmatpoitem',
    templateUrl: './addmatpoitem.component.html',
    styleUrls: ['./addmatpoitem.component.css']
})

export class AddMatPOItemComponent implements OnInit {

    public filteredMFOptions: Observable<string[]>;
    public _supplierId: number;
    public itemFamilyList: any;                      // used in the autocomplete filter
    public records: Array<SupplierItems>;
    public refList: Array<PurchaseOrderItems>;

    public unitPrice: number;
    public costBase: number;
    public costPerDose: number;
    public leadTimeDays: number;

    public addEditFlag = true;
    public strErr = '';

    public ndcDescription: string | null = '';
    public selectedItemFamily: SupplierItems = new SupplierItems();
    public mfControl = new FormControl('');
    public matService: MaterialService;
    public txService: TransactionHistoryService;
    public dataSource: MatTableDataSource<SupplierItems>;

    @Output() submitAddPOItemEvent = new EventEmitter<SupplierItems>();
    @Output() cancelAddPOItemEvent = new EventEmitter();

    private _mfFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const strRet = this.itemFamilyList.filter(option => option.toLowerCase().includes(filterValue));
        return strRet;
    }

    @Input() set supplierId(val: number) {
        this._supplierId = val;
    }
    get supplierId(): number {
        return this._supplierId;
    }

    constructor(matSvc: MaterialService, txService: TransactionHistoryService) {
        this.matService = matSvc;
        this.txService = txService;
    }

    ngOnInit() {

    }
    public clearForm(): void {
        this.selectedItemFamily = new SupplierItems();
        this.ndcDescription = '';
        this.mfControl.setValue('');
        this.strErr = '';
        this.costPerDose = 0;
    }
    createRefHistItems(sm: SupplierItems): void  {
        this.costBase = sm.costBase;
        this.unitPrice = sm.unitPrice;
        this.leadTimeDays = sm.leadTimeDays;
    }
    /*** entry point ***/
    public doInitialize(sVal: SupplierItems, aeFlag: boolean, recs: any): void {

        this.addEditFlag = aeFlag;
        this.selectedItemFamily = sVal;
        this.createRefHistItems(sVal);
        this.refList = recs;
        this.strErr = '';

        if (this.addEditFlag === true) {
            this.clearForm();
        }
        else {
            this.mfControl.setValue(this.fmtItemString(this.selectedItemFamily.origNdcNumber,
                this.selectedItemFamily.description));
            this.costPerDose = this.calcCostPerDose(this.selectedItemFamily.unitPrice, this.selectedItemFamily.packageSize)
            this.ndcDescription = this.mfControl.value;
        }

        this.matService.getSupplierMaterialsBySupplierIdShort(this.supplierId).subscribe(
            (data) => {
                this.itemFamilyList = new Array();
                this.records = new Array<SupplierItems>();

                for (const item of data) {
                    const sm = new SupplierItems();
                    sm.setFamilyMaterials(item, this.supplierId);
                    this.itemFamilyList.push(this.fmtItemString(sm.origNdcNumber, sm.description));
                    this.records.push(sm);
                }

                this.filteredMFOptions = this.mfControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._mfFilter(value || '')),
                );
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching materials for suppliers');
        });
    }
    public fmtItemString(ndc: string, desc: string): string {
        return ndc + ': ' + desc;
    }
    public itemFamilySelectVal(val: any): void {
        const selectedVal = this.mfControl.value;
        const selectedItemPref = selectedVal.split(':')[0];

        /** check to see if there are duplicate meds that have been selected */
        if (this.refList.findIndex(x => x.ndcNumber === selectedItemPref) > -1) {
            this.strErr = 'Error: This medication has already been specified for this PO';
            return;
        }
        else {
            this.selectedItemFamily = this.records.find(x => x.origNdcNumber === selectedItemPref);
            this.createRefHistItems(this.selectedItemFamily);
            this.costPerDose =
                this.calcCostPerDose(this.selectedItemFamily.unitPrice, this.selectedItemFamily.packageSize);
            this.ndcDescription = selectedVal;
            /*** set the cursor to the Quantity field and highlight the contents  ***/
            DwUtils.highlightFocus('PoQtyId');
        }
    }
    public doClearItem(): void {
        this.clearForm();
        DwUtils.setFocus('matFamilyId');
    }
    public doQuantityChange(event: any): void  {
        this.selectedItemFamily.extended =
            (Math.round(Number(this.selectedItemFamily.quantity) * Number(this.selectedItemFamily.unitPrice) * 100) / 100);
    }
    public doAddNewMaterialItem(): void {

    }
    public costBaseChanged(): boolean {
        if (this.costBase !== this.selectedItemFamily.costBase) {
            return true;
        }
        return false;
    }
    public keyPropertiesChanged(): boolean {
        if (this.costBaseChanged() === true) {
            return true;
        }
        if (this.leadTimeDays !== this.selectedItemFamily.leadTimeDays) {
            return true;
        }
        if (this.unitPrice !== this.selectedItemFamily.unitPrice) {
            return true;
        }

        return false;
    }
    public updateSupplierMaterialRecord(): void {
        const sm: SupplierMaterial = new SupplierMaterial();
        sm.setFromSupplierItems(this.selectedItemFamily, this.costPerDose);

        this.matService.updateMaterialonSupplierByFK(sm).subscribe(
            (data) => {
                this.submitAddPOItemEvent.emit(this.selectedItemFamily);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Updating supplier medication');
            });
    }
    public updateCostBaseRecord(): void {

        this.matService.updateMaterialCostBase(this.selectedItemFamily.itemId, this.selectedItemFamily.costBase).subscribe(
            (data) => {
                // done
                const res = data;
            },
            (error) => {
                Settings.getInstance().handleError(error, 'updating the cost base');
            });
    }
    public onSubmitPOItem(): void {
        if (this.costBaseChanged() === true ) {
            this.updateCostBaseRecord();
        }
        if (this.keyPropertiesChanged() === true) {
            this.updateSupplierMaterialRecord();
        }
        else {
            this.submitAddPOItemEvent.emit(this.selectedItemFamily);
        }
    }
    public onCancelPOItem(): void {

        this.cancelAddPOItemEvent.emit();
    }
    public calcCostPerDose(unit: number, pkgSize: number): number {

        if (unit === 0 && pkgSize === 0)
            return 0;

        return Math.round((unit / pkgSize) * 100) / 100;
    }
}


