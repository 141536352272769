<div class="transaction-history-container" style="outline: none!important;">

    <div class="mat-elevation-z1 tx-query-strip">
        <table style="width: 100%">
            <tr>
                <td>
                    <label style="vertical-align: middle; padding-left: 10px; margin-right: 12px;">
                        View:
                    </label>
                    <mat-select
                        disableOptionCentering
                        [(ngModel)]="selectedInterval"
                        floatLabel="never"
                        (keydown)="doIntervalDropDownKeyDown($event)"
                        (selectionChange)="doTimeFrameSelectionChanged($event)"
                        style="font-size: 10pt;width: 90px;"
                        panelClass="local-drop-down-panel" class="dw-mat-select">
                        <mat-option *ngFor="let interval of intervalList"
                                    [value]="interval">{{interval.description}}</mat-option>
                    </mat-select>
                </td>
                <td style="text-align:center;">
                    <input text
                           id="txtFilter" #txtFilter class="qs_filter-field"
                           [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
                <td class="table-pagination-cell" align="right">{{pagination}}
                    Record(s) ...
                </td>
            </tr>
        </table>

    </div>

    <div #txtbl1 class="mat-elevation-z1 tx-table-div" id="txTableId"
        (keydown)="tableKeyDown($event)"  tabindex="-1">
        <table mat-table [dataSource]="dataSource" matSort
               style="outline: none!important;"
               class="tx-table-properties"
               (matSortChange)="onSortChange($event)">

            <!-- Date of transaction -->
            <ng-container matColumnDef="transactionDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Date / Time</th>
                <td mat-cell style="width:8% !important; "
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{formatDateTime(element.transactionDate)}}</td>
            </ng-container>

            <!-- transaction by -->
            <ng-container matColumnDef="userUid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Transaction By</th>
                <td mat-cell style="width:8% !important; "
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{formatUserFN(element.userUid)}}</td>
            </ng-container>
            <!-- type of transaction -->
            <ng-container matColumnDef="txAction">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Details</th>
                <td mat-cell style="width:8% !important;"
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.txAction}}</td>
            </ng-container>
            <!-- column Name -->
            <ng-container matColumnDef="columnName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Column/Element</th>
                <td mat-cell style="width:8% !important;"
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.columnName}}</td>
            </ng-container>

            <!-- old value -->
            <ng-container matColumnDef="oldValue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Old Value</th>
                <td mat-cell style="width:8% !important;"
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{evaluateLength(element.oldValue)}}</td>
            </ng-container>
            <!-- new value -->
            <ng-container matColumnDef="newValue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">New Value</th>
                <td mat-cell style="width:8% !important;"
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{evaluateLength(element.newValue)}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row style="font-size: 8pt;"
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (dblclick)="onRowDoubleClicked(row)"
                (click)="onRowClicked(row)">
            </tr>
        </table>
    </div>
</div>
<!------------------------------>
<!-- DIALOGS and POPUPS       -->
<!------------------------------>

<p-dialog header="Transaction History Detail"
          [appendTo]="'body'"
          [(visible)]="displayTxDetail"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '605px', height: '520px'}"
          [minY]="75" [closable]="true"
          [resizable]="false" >
    <app-trans-history-detail #addLicense [selectedRecord]="selectedRecord"
                              (AttemptToFetchNext)="doFetchNextRecord()"
                              (AttemptToFetchPrevious)="doFetchPreviousRecord()"
                     (cancelTxDetail)="closeTxDetail($event)">
    </app-trans-history-detail>
</p-dialog>
