/************
 * Model Class
 * File Name ...: licensetypes.components.ts
 * Generated By : ianday on 3/8/23
 * Date ........: 3/8/23
 /************/

export class LicenseTypes {

    public names = [
      'DEA license',
      'State License',
      'Dental Practice License',
      'Nurse Practitioner License',
      'Physician Assistant License',
      'Sports Medicine License',
      'Other License'
    ];
}
