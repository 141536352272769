<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

 <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
 <div class="form-gap"></div>
 <br>

<div class="container" style="width: 400px;">
	<div class="row" style="text-align: center">
            <div class="panel panel-default">
              <div class="panel-body" *ngIf="showEmailPrompt">
                <div class="text-center">
                  <h4><i class="fa fa-lock fa-2x" style="color: gray;"></i></h4>
                  <h4 class="text-center">Forgot Password?</h4>
                  <p>Please enter your registered E-mail</p>
                  <div class="panel-body">
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                          <input id="email" name="email" placeholder="email address" class="form-control"  type="email" [(ngModel)]="strEmail">
                        </div>
                        <div style="height: 36px;padding-top:10px;">
      						<p class="error">{{ strMessage }} </p>
      						<span *ngIf="processing" style="color: blue">Please wait...</span>
      					</div>
                      </div>
                      <div class="form-group" >
                        <input name="recover-submit" class="btn btn-lg btn-primary btn-block"
                               value="Submit"
                               (click)="sendOtp()" [readOnly]="true">
                      </div>

                      <input type="hidden" class="hide" name="token" id="token" value="">
                  </div>
                </div>
              </div>
              <div class="panel-body" *ngIf="showOTPPrompt">
                <div class="text-center">
                  <h4><i class="fa fa-lock fa-2x" style="color: gray;"></i></h4>
                  <h4 class="text-center">Reset Password</h4>
                  <p>Please enter the One Time Password (OTP) and choose a new password:</p>

                  <table>
                      <tr style="width:100%;">
                          <td>
                          <code-input [isCodeHidden]="false"
                                      style="font-size: 14pt"
                                      [codeLength]="6"
                                      [(ngModel)]="strOTP"
                                      (codeChanged)="onCodeChanged($event)"
                                      (codeCompleted)="onCodeCompleted($event)">
                          </code-input>
                         </td>
                      </tr>
                      <tr>
                          <td style="width: 100%; text-align:right;padding:5px;">
                                <a *ngIf="!resentOTPFlag" (click)="sendOtp()">Resend OTP</a>
                                <span *ngIf="resentOTPFlag" style="color: green"> OTP sent!</span>
                          </td>
                      </tr>
                    </table>

                    <table style="width:100%; padding-top: 8px; text-align:left;">
                      <tr >
                      <td style="padding-top: 10px;">New Password: </td>
                          <td style="padding-top: 10px;">
                          <input id="password" name="password" placeholder="New password"
                                 style="padding-bottom:5px;"
                                 class="form-control btn-block"  type="password"
                                 [(ngModel)]="strPassword1">
                          </td>
                      </tr>
                      <tr>
                      <td style="padding-top: 10px;">Confirm Password: </td>
                          <td style="padding-top: 10px;">
                          <input id="confirmpassword" name="confirmpassword"
                                 placeholder="Retype password" class="form-control btn-block"  type="password"
                                 [(ngModel)]="strPassword2">
                          </td>
                      </tr>
                  </table>
                    <div style="height: 40px; text-align: initial; margin-top: 10px; margin-bottom: 10px;">
      			    	<p class="error">{{ strMessage }} </p>
      			    	<span *ngIf="processing" style="color: blue">Please wait...</span>
      			    	
      				</div>
                    <div class="form-group">
                        <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Submit" (click)="sendPasswordResetRequest()" [readOnly]="true" [disabled]="this.processing">
                      </div>
                  </div>
                </div>
                <div class="panel-body" *ngIf="showOTPPrompt || showEmailPrompt">
	                 <div class="text-center">
	                	<a routerLink="/login">Back</a>
	                </div>
                </div>
               <div class="panel-body" *ngIf="!showOTPPrompt && !showEmailPrompt">
	                 <div class="text-center">
	                	 <h4>Congratulations! </h4>
                         <p>Your Password has been changed successfully!</p>
                         <p><a routerLink="/login">Click Here to Login</a></p>
	                </div>
                </div>
              
            </div>
          </div>
