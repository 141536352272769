/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: sites-inv.components.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/

import {Component, OnInit, ViewChild, HostListener, AfterContentInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Settings} from '../../../services/settings';
import {Sites} from '../../../models/sites';
import {SitesService} from '../../../services/sites.service';
import {ValidationResult} from '../../../models/validation-result';
import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TransactionHistoryService} from '../../../services/transactionhistory.service';
import {TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import {DwUtils} from '../../../components/utils/dw-utils';

/* Note: Remove this class when ready to plumb in services */

@Component({
    selector: 'app-sites-inv',
    templateUrl: './sites-inv.component.html',
    styleUrls: ['./sites-inv.component.css']
})
export class SitesInvComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'Sites and Stations';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: Sites[];
    public selectedRecord: Sites;
    public historyRecord: Sites;
    public Idx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Sites>;
    public renderedData: Array<any>;
    public result: boolean | null = false;
    public disabledFlag: boolean | null = true;
    public activeCellId: string | null = '';

    /**** Query strip filter settings ***/
    public statusFilterList = [];
    public selectedStatusFilter: any;
    public statusFilterIdx: number | null = -1;

    public selectedViewFilter: string | null = 'List';
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['id', 'siteCode', 'handle', 'sitePrincipal', 'activeStatus'];

    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: any | null = '';
    public handle: any | null = '';
    public description: any | null = '';
    public siteCode: any | null = '';
    public sitePrincipal: any | null = '';
    public activeStatus: any | null = '';

    /***** Side drawer ****/
    public propContainerState: boolean | null = false;
    public visiblePropertiesTab: boolean | null = true;
    public visibleNotesTab: boolean | null = true;
    public sitesService: SitesService;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;

    constructor(private ssvc: SitesService, private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Sites();
        this.sitesService = ssvc;
        this.statusFilterList = new Array();
        this.statusFilterList.push({id: 'Active', description: 'Active'});
        this.statusFilterList.push({id: 'InActive', description: 'InActive'});
        this.statusFilterList.push({id: 'All', description: 'All Items'});
        this.selectedStatusFilter = this.statusFilterList[0];
        this.statusFilterIdx = 0;
    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = true;
    }

    ngAfterContentInit() {

        this.queryRecords();

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
    }
    public onStatusFilterKeyDown(event: any): void {
        const len: number = this.statusFilterList.length;
        if (event.key === 'ArrowDown') {
            if (this.statusFilterIdx < (len - 1)) {
                this.selectedStatusFilter = this.statusFilterList[++this.statusFilterIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.statusFilterIdx > 0) {
                this.selectedStatusFilter = this.statusFilterList[--this.statusFilterIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedStatusFilter = this.statusFilterList[this.statusFilterIdx];
            this.onChangeStatusFilterValue(this.selectedStatusFilter);
        }
    }
    /*** Query Records from the Service ***/
    queryRecords() {

        this.sitesService.getAllSites().subscribe(
            (result) => {
                this.records = result.filter(x => x.activeStatus !== 'Deleted');
                this.dataSource = new MatTableDataSource(this.records);
                this.dataSource.sort = this.sort;
                this.onChangeStatusFilterValue(this.selectedStatusFilter);
                this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }

    /*** Display the number of records from the total ***/
    setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }

    onChangeStatusFilterValue(event: any) {
        const dataTemp: Sites[] = [];

        if (event.value !== undefined) {
            this.selectedStatusFilter = event.value;
        }

        this.clearFilter();

        for (const rec of this.records) {
            if (rec.activeStatus === this.selectedStatusFilter.id || this.selectedStatusFilter.id === 'All') {
                dataTemp.push(rec);
            }
        }
        this.dataSource = new MatTableDataSource(dataTemp);
        this.setPagination(-1, this.dataSource.filteredData.length);
    }

    onStatusChange() {

        this.sitesService.updateSites(this.selectedRecord).subscribe(
            (data) => {
                // no user feedback, just apply the filter ...
                this.onChangeStatusFilterValue(this.selectedStatusFilter);
            });
    }
    doTabChange(event): void {

    }
    @HostListener('matSortChange', ['$event'])
    onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }

    clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
        this.dataSource.filter = '';
        this.selectRow();
        this.setPagination(-1, this.dataSource.data.length);
    }

    applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }

    selectRow() {
        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);

    }

    unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = new Sites();
        this.setPagination(this.Idx, this.dataSource.data.length);
        this.displayDetail(this.selectedRecord);

    }

    displayDetail(record: Sites) {
        if (record === undefined) {
            return;
        }
        this.id = record.id;
        this.handle = record.handle;
        this.description = record.description;
        this.siteCode = record.siteCode;
        this.sitePrincipal = record.sitePrincipal;
        this.activeStatus = record.activeStatus;
        this.history.onShowHandler('sites', 'id', record.id.toString());
    }

    clear() {
        this.id = '';
        this.handle = '';
        this.description = '';
        this.siteCode = '';
        this.sitePrincipal = '';
        this.activeStatus = '';

    }

    addModifyRecord(isAdd: boolean) {
        this.isAdd = isAdd;
        this.isEditing = true;
        this.selectedTabIndex = 0;

        if (this.isAdd) {
            this.clear();
            this.id = 0;
            this.activeStatus = 'Active';
        } else {
            // store the history record
            this.historyRecord = this.selectedRecord;
        }
        DwUtils.setFocus('siteCode');
    }

    removeRecord() {
        const message = 'Remove Sites: "' + this.selectedRecord.handle + '"<br /> - Are you sure?';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            if (this.result === true) {
                this.selectedRecord.activeStatus = 'Deleted';
                this.sitesService.updateSites(this.selectedRecord).subscribe(
                    (data) => {
                        this.clear();
                        this.queryRecords();

                    },
                    (error) => {
                        Settings.getInstance().handleError(error, "deleting site: deleteSites()");
                    });
            }
        });
    }

    onPropertiesToggle() {
        this.propContainerState = !this.propContainerState;
    }

    tableKeyDown(event: KeyboardEvent) {
        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }

    validateFields(): boolean {

        this.strErr = '';
        const vr: ValidationResult = this.selectedRecord.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return false;
        }
        return true;
    }

    submitRecord() {

        this.selectedRecord = new Sites();
        this.selectedRecord.id = this.id;
        this.selectedRecord.handle = DwUtils.capitalize(this.handle);
        this.selectedRecord.description = this.description;
        this.selectedRecord.siteCode = this.siteCode.toUpperCase();
        this.selectedRecord.sitePrincipal = DwUtils.capitalize(this.sitePrincipal);
        this.selectedRecord.activeStatus = this.activeStatus;
        this.strErr = '';

        if (!this.validateFields()) {
            return;
        }
        if (this.isAdd === true) {
            this.sitesService.createSites(this.selectedRecord).subscribe(
                (data) => {
                    this.selectedRecord.id = data.id;
                    this.isEditing = false;
                    /*** add a transaction history record ***/
                    this.txService.createTransactionHistory('sites', this.selectedRecord.id);
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Creating new Site: createSites()');
                    return;
                }
            );
        } else {

            this.sitesService.updateSites(this.selectedRecord).subscribe(
                (data) => {
                    this.isEditing = false;
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Updating Site: createSites()');
                    this.selectedRecord = this.historyRecord;
                    return;
                });

            /*** add a transaction history record ***/
            this.txService.updateTransactionHistory(this.historyRecord,
                this.selectedRecord, 'sites', this.selectedRecord.id);
        }
        this.updateSelectedRow();
    }

    /* Update the record in the table's data source - without refreshing it */
    updateSelectedRow(): void {
        if (this.isAdd === true) {
            // add the new record to the top.
            this.records.unshift(this.selectedRecord);
            this.dataSource.data = this.records;
            this.Idx = 0;
        } else {
            const i: number = this.records.findIndex(r => r.id === this.selectedRecord.id);
            this.records[i] = this.selectedRecord;
            this.dataSource.data = this.records;
        }
        this.dataSource.sort = this.sort;
    }

    onRowClicked(selected: any) {

        if (this.isEditing === true)
            return;

        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
        this.displayDetail(this.selectedRecord);
    }

    cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
        this.displayDetail(this.selectedRecord);
    }
}
