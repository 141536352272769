import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './options/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { SettingsComponent } from './options/settings/settings.component';
import { AuthGuard } from './services/authservice/authguard';
import { InventoryComponent } from './options/inventory/inventory.component';
import { FormingComponent } from './options/forming/forming.component';

export let routes: Routes = [
	{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent },
	{ path: 'recovery', component: ForgotpasswordComponent },
	{ path: 'user-registration/:token', component: RegisterUserComponent },
	{ path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Settings' } },
	{ path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard], data: { title: 'Inventory' } },
	{ path: 'forming', component: FormingComponent, canActivate: [AuthGuard], data: { title: 'Forming' } },
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: '**', redirectTo: '/home', pathMatch: 'full' }
];

export const AppRoutingModule = RouterModule.forRoot(routes, {});
