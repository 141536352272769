/**
 *  Filename ....: finishbatch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/4/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Forming} from '../../../../models/forming';
import {FormingService} from '../../../../services/forming.service';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {Bpr} from '../../../../models/bpr';
import {Settings} from '../../../../services/settings';

@Component({
    selector: 'app-releasebatch',
    templateUrl: './releasebatch.component.html',
    styleUrls: ['./releasebatch.component.css']
})

export class ReleaseBatchComponent implements OnInit {

    public _stationsList: any;
    public _selectedForm: Forming;
    public strErr = '';
    public ordNumber: string;
    public verifiedUnits: number | null;
    public approveToShip: number | null;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @HostListener ('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }
    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
    }
    get selectedForm(): Forming {
        return this._selectedForm;
    }
    @Input() set stationsList(inp: any) {
        this._stationsList = inp;
    }
    get stationsList(): any {
        return this._stationsList;
    }
    @Input() set orderNumber(inp: string) {
        this.ordNumber = inp;
    }

    constructor(public formingService: FormingService) {

    }

    ngOnInit() {

    }
    public doInitialize(): void {
        this.verifiedUnits = this.selectedForm.bpr.verifiedUnits;
        this.approveToShip = this.selectedForm.bpr.approveToShip;

    }
    public setBPRItems():void {
        this.selectedForm.bpr.verifiedUnits = DwUtils.boolToInt(this.verifiedUnits);
        this.selectedForm.bpr.approveToShip = DwUtils.boolToInt(this.approveToShip);
    }
    public onSubmitClicked(): void {

        this.setBPRItems();

        const bpr: Bpr | null = new Bpr();
        bpr.setBpr(this.selectedForm.bpr);
        const vr = bpr.validateReleaseRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            return;
        }
        this.selectedForm.bpr.currentStep = 5;
        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
            (result) => {
                /*** all good ***/
                this.submitClicked.emit();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Error updating BPR from approve batch')
            });

    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


