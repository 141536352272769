/**
 *  Filename ....: bulkunits.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/5/23
 **/
import {Component, Input, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {BulkUnitsService} from '../../../../services/bulkunits.service';
import {BulkUnits} from '../../../../models/bulkunits';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { DwDates } from '../../../../components/utils/dw-dates';
import {BulkUnitsDetailComponent} from './dialogs/bulkunitsdetail/bulkunitsdetail.component';
import {InventoryTransferComponent} from './dialogs/invtransfer/invtransfer.component';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {Catalogues} from '../../../../models/catalogues';
import {Sites} from '../../../../models/sites';
import {ReturnManuComponent} from './dialogs/returnmanu/returnmanu.component';

@Component({
    selector: 'app-bulkunits',
    templateUrl: './bulkunits.component.html',
    styleUrls: ['./bulkunits.component.css']
})

export class BulkUnitsComponent implements OnInit, AfterViewInit {

    public records: BulkUnits[];


    public selectedRecord: BulkUnits;
    public dataSource: MatTableDataSource<BulkUnits>;
    public displayedColumns = ['select', 'itemNumber', 'location', 'icn', 'serialNumber', 'lotNumber', 'expirationDate', 'shelfLife', 'originalDoses', 'dosesUsed', 'remainingDoses', 'containerStatus'];
    public pagination: string;
    public assetsPath = '../../../../assets/';
    public timeFrame = '30';
    public Idx = -1;
    public bulkUnitsService: BulkUnitsService;
    public isEditing = false;
    public fkTableHist: string;
    public fkIdHist: number;
    public catHist: number;
    public fKeyTable: string;
    public fKeyId: number;
    public catId: number;
    public itemPrefix: string;
    public itemHandle: string;
    public dosesPieces: string;
    public disabledHamburgerMenu = true;

    /** Dialog Display variables **/
    public displayInventoryTransfer = false;
    public displayDetail = false;
    public displayReturnToManufacturer = false;
    public displayProcessItemRecall = false;
    public displayRelocatePhysicalItem = false;
    public _catalogues: Catalogues[];
    public _sites: Sites[];

    public containerStateList = [];
    public containerStateIdx: number | null = -1;
    public selectedContainerStatus: any;

    @Input() set catalogues(val: any) {
        if (val === undefined)
            return;

       this._catalogues = val;
    }
    get catalogues(): any { return this._catalogues; }

    @Input() set sites(val: any) {
        if (val === undefined)
            return;

        this._sites = val;
    }
    get sites(): any { return this._sites; }

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('bulkUnitDtl', {static: true}) bulkUnitDtl: BulkUnitsDetailComponent;
    @ViewChild('invTransfer', {static: true}) invTransfer: InventoryTransferComponent;
    @ViewChild('returnManu', {static: true}) returnManu: ReturnManuComponent;

    constructor(buService: BulkUnitsService) {
        this.bulkUnitsService = buService;

        this.containerStateList = new Array();
        this.containerStateList.push({id: '1', description: 'Open/Sealed'});
        this.containerStateList.push({id: '2', description: 'Spent (used)'});
        this.containerStateList.push({id: '3', description: 'Recalled/Returned'});
        this.containerStateList.push({id: '0', description: 'All Statuses'});
        this.selectedContainerStatus = this.containerStateList[0];
        this.containerStateIdx = 0;
    }

    ngOnInit() {
        this.fkTableHist = '';
        this.fkIdHist = 0;
        this.dosesPieces = 'Doses';
    }
    ngAfterViewInit() {

    }
    /***** this is the entry point ****/
    public displayBulkUnits(tblKey: string, idKey: number, cId: number, itemPref: string, itemHandle: string): void     {
        this.fKeyTable = tblKey;
        this.fKeyId = idKey;
        this.catId = cId;
        this.itemPrefix = '(' + itemPref + ')';
        this.itemHandle = ' - ' + itemHandle;
        if(this.fKeyTable === 'materials')
            this.dosesPieces = 'Pieces';
        else
            this.dosesPieces = 'Doses';

        if ((this.fkTableHist === this.fKeyTable) && (this.fkIdHist === this.fKeyId) && (this.catHist === this.catId)) {
            return;
        }
        else {
            this.queryRecords();
            this.unSelectRow();
            this.fkTableHist = this.fKeyTable;
            this.fkIdHist = this.fKeyId;
            this.catHist = this.catId;
        }
    }
    public clearBulkUnitDetail(): void {
        this.records = new Array<BulkUnits>();
        this.itemPrefix = '';
        this.itemHandle = '';
        this.dataSource = new MatTableDataSource(this.records);
    }
    public queryRecords(): void {
        this.records = new Array<BulkUnits>();
        this.bulkUnitsService.getBulkUnitsByForeignKey(
            this.fKeyTable,
            this.fKeyId,
            this.catId,
            Number(this.selectedContainerStatus.id)).subscribe(

            (data) => {
                if (data.length === 0) {
                    this.records = new Array<BulkUnits>();
                } else {
                    this.records = data;
                }
                this.records.length ? this.disabledHamburgerMenu = false : this.disabledHamburgerMenu = true;
                this.dataSource = new MatTableDataSource(this.records);
                this.dataSource.sort = this.sort;
                this.setPagination(-1, this.records.length);
            },
            (error) => {

            });
    }
    public applyFilter(filterValue: string) {
        if (this.isEditing) return;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(-1, this.dataSource.filteredData.length);
    }
    public clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = '';

        this.unSelectRow();
        this.setPagination(-1, this.dataSource.filteredData.length);

    }
    public doChangeBUContainerStatusFilter(event: any): void {

        // if this method is called from KeyDown event then
        // the selectedContainerStatus is already set. (and value will be undefined).

        if (event.value !== undefined) {
            this.selectedContainerStatus = event.value;
        }
        this.unSelectRow();
        this.queryRecords();
    }
    public onContainerStatusDropDownKeyDown(event: any): void {
        const len: number = this.containerStateList.length;
        if (event.key === 'ArrowDown') {
            if (this.containerStateIdx < (len - 1)) {
                this.selectedContainerStatus = this.containerStateList[++this.containerStateIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.containerStateIdx > 0) {
                this.selectedContainerStatus = this.containerStateList[--this.containerStateIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedContainerStatus = this.containerStateList[this.containerStateIdx];
            this.doChangeBUContainerStatusFilter(this.selectedContainerStatus);
        }
    }
    public setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    public onRowClicked(selected: any): void {
        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public onRowDoubleClicked(selected: any): void {
        this.onRowClicked(selected);
        this.selectedRecord = selected;
        this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces, this.selectedRecord.id);
        this.displayDetail = true;
    }
    public selectRow(): void {
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
    }
    public unSelectRow(): void{
        this.Idx = -1;
        this.selectedRecord = new BulkUnits();
        this.toggleAllSelected(false);
    }
    public toggleAllSelected(event): void {

        if (this.dataSource === undefined)
            return;

        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            if(!this.evaluateForEnable(this.dataSource.filteredData[i].containerStatus) === true)
                this.dataSource.filteredData[i].select = event.checked;
        }
    }
    public hasSelectedItems(): boolean {

        if (this.dataSource === undefined)
            return false;

        const ret = this.dataSource.filteredData.find(s => s.select === true);
        return ret ? true : false;

    }
    public returnSelectedItems(): any {
        if (this.dataSource === undefined)
            return '';

        const ret = this.dataSource.filteredData.filter(s => s.select === true);
        return ret;
    }
    public returnMedOrMat(): string {
       if (this.records === undefined)
            return;

       if (this.records[0] === undefined)
           return;

       return DwUtils.capitalize(this.records[0].foreignKeyTable);
    }
    public onSortChange(event): void {

        if (event.direction === 'asc') {
              this.records.sort((a, b) =>
                  a[event.active].localeCompare(b[event.active]));
        } else {
               this.records.sort((a, b) =>
                 b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public labelFmtExpDate(inpDate: string): string {

        if (inpDate.includes('T') === false)
            return inpDate;

        return inpDate.split('T')[0];
    }
    public calculateShelfLife(inpVal: string): number {

        let shelfDays;
        shelfDays = DwDates.calculateDaysBeforeDate(inpVal);
        return shelfDays;
    }
    public doFetchNextRecord(): void {
        const len: number = this.dataSource.filteredData.length;
        if (this.Idx < (len - 1)) {
            this.selectedRecord = this.dataSource.filteredData[++this.Idx];
            this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces, this.selectedRecord.id);
        }
        else {
            return;
        }
       // this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces);
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public doFetchPreviousRecord(): void {
        if (this.Idx > 0) {
            this.selectedRecord = this.dataSource.filteredData[--this.Idx];
            this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces, this.selectedRecord.id);
        }
        else {
            return;
        }
       // this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces);
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public closeBUDetail(event: any): void {
        this.displayDetail = false;
    }
    public doInventoryTransfer(event: any): void {
        this.invTransfer.setValues();
        this.displayInventoryTransfer = true;
    }
    public cancelTransfer(): void {
        this.displayInventoryTransfer = false;
    }
    public transferInvItems(): void {
        this.displayInventoryTransfer = false;

        // remove these from the list ...
        for (let i = this.records.length - 1; i > -1; i--) {
            if (this.records[i].select === true) {
                this.records.splice(i, 1);
            }
        }
        // reset the data source...
        this.dataSource = new MatTableDataSource(this.records);
    }
    public doReturnManuItems(event: any): void {
        this.displayReturnToManufacturer = true;
        this.returnManu.setValues();
    }
    public cancelReturnInvItems(): void {
        this.displayReturnToManufacturer = false;
    }
    public returnInvItems(): void {
        this.displayReturnToManufacturer = false;
        this.queryRecords();
    }
    public returnDosesUsed(orig, defective, remaining): number {

        let ret: number;
        ret = Number(orig) - (Number(defective) + Number(remaining));
        return ret;
    }
    public fetchContainerStatusIcon(inp: string): string {
       return this.assetsPath + '/' + inp.toLowerCase() + '.png';
    }
    public evaluateForEnable(st: string): boolean {

        switch (st) {
            case 'Open':
            case 'Sealed':
                return false;
                break;
        }
        return true;
    }
}


