/**
 *  Filename ....: add-license.component.ts
 *  Created by ..: ianday
 *  on Date .....: 2/23/23
 **/
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Addresses } from '../../../../models/addresses';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl} from '@angular/forms';
import { ValidationResult } from '../../../../models/validation-result';
import { AddressesService } from '../../../../services/Common/Addresses.service';
import {States} from '../../../../models/lists/states';
import { DwUtils } from '../../../utils/dw-utils';

@Component({
    selector: 'app-add-address',
    templateUrl: './add-address.component.html',
    styleUrls: ['./add-address.component.css']
})

export class AddAddressComponent implements OnInit {

    public record: Addresses;
    public _parentId: number;
    public _callingComponent: string;
    public id = 0;
    public entityDescription = '';
    public address1 = '';
    public address2 = '';
    public city = '';
    public state = '';
    public zipCode = '';
    public addressesService: AddressesService;
    public strErr = '';
    public stateList: States;
    filteredOptions: Observable<string[]>;
    stateControl = new FormControl('');
    public addEditFlag: boolean;

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.stateList.names.filter(option => option.toLowerCase().includes(filterValue));
    }
    @Output() cancelAddAddress = new EventEmitter();
    @Output() submitAddAddress = new EventEmitter<Addresses>();
    @Input() set parentId(value: number) {
        this._parentId = value;
    }
    get parentId(): number {
        return this._parentId;
    }
    @Input() set callingComponent(value: string) {
        this._callingComponent = value;
    }
    get callingComponent(): string {
        return this._callingComponent;
    }
    constructor(asvc: AddressesService) {
        this.addressesService = asvc;
        this.stateList = new States();
    }
    public ngOnInit() {
        this.filteredOptions = this.stateControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }
    public initializeRecord(a: Addresses): void {

        this.id = a.id;
        this.entityDescription = a.entityDescription;
        this.address1 = a.address1;
        this.address2 = a.address2;
        this.city = a.city;
        this.stateControl.setValue(a.state);
        this.zipCode = a.zipCode;

    }
    public afterOpenDialog(inputRec: Addresses, aeFlag: boolean): void     {
        this.addEditFlag = aeFlag;
        this.initializeRecord(inputRec);
        DwUtils.setFocus('entityDescription');
    }
    public displayFn(state): string     {
        return state ? state : '';
    }
    public doCancel() {
        this.cancelAddAddress.emit();
    }
    public setRecord(): void {
        this.record = new Addresses();
        this.record.id = this.id;
        this.record.foreignKeyTable = this.callingComponent;
        this.record.foreignKeyId = this.parentId;
        this.record.entityDescription = DwUtils.capitalize(this.entityDescription).trim();
        this.record.address1 = DwUtils.capitalize(this.address1).trim();
        this.record.address2 = DwUtils.capitalize(this.address2).trim();
        this.record.city = DwUtils.capitalize(this.city).trim();
        this.record.state = this.stateControl.value;
        this.record.zipCode = this.zipCode.trim();
    }
    public doSubmit() {
        // this is where we stuff the new address and emit it back.
        this.setRecord();

        this.strErr = '';
        const vr: ValidationResult = this.record.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return;
        }
        // add the record ...
        if (this.addEditFlag === true) {
            this.addressesService.createAddress(this.record).subscribe(
                (data) => {
                    this.record.id = data.id;        // get the Id ...
                    this.submitAddAddress.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to create / save this record ';
                    return;
                });
        }
        else {
            this.addressesService.updateAddress(this.record).subscribe (
                (data) => {
                    this.submitAddAddress.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to update this record';
                    return;
                });
        }
    }
}


