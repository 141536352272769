<!--
    file type .....: HTML file: acceptorderitems.component.html
    Created By ....: ianday
    Date ..........: 7/7/23 
-->
<div class="acceptorderitems-container">
    <div class="mat-elevation-z2 table-container"
         (keydown)="tableKeyDown($event)" tabindex="-1" style="outline: none!important;">
        <table mat-table [dataSource]="dataSource" matSort
               style="width: 100%;outline:none!important;"
               (matSortChange)="onSortChange($event)">
            <!-- CheckBox Item -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleAllSelected($event)">

                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
                    style="width:3% !important;" class="mat-table-detail-style-override">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  matTooltip="Check this item to select it for processing"
                                  *ngIf="element.itemStatus === 'New'"
                                  [value]="element.select" [(ngModel)]="element.select" >
                    </mat-checkbox>

                    <span *ngIf="element.itemStatus !== 'New'"
                          matTooltip="This item is already accepted"
                          style="color: green;"><mat-icon>done</mat-icon></span>
                </td>
            </ng-container>
            <!-- ITEM NUMBER -->
            <ng-container matColumnDef="itemPrefix">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Item Prefix
                </th>
                <td mat-cell class="mat-table-detail-style mat-table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemPrefix}}</td>
            </ng-container>
            <!-- DESCRIPTION -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:300px;">Description
                </th>
                <td mat-cell class="mat-table-detail-style mat-table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.description}}</td>
            </ng-container>
            <!-- QUANTITY -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:50px;">Qty
                </th>
                <td mat-cell class="mat-table-detail-style mat-table-detail-style-override"
                    *matCellDef="let element;let tableRowIdx = index;">
                    <span style="padding-left: 3px;">{{element.quantity}}</span>
                </td>
            </ng-container>

            <!-- ItemStatus -->
            <ng-container matColumnDef="itemStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Status
                </th>
                <td mat-cell class="mat-table-detail-style mat-table-detail-style-override" style="padding-left:15px"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemStatus}}</td>
            </ng-container>

            <!-- Site drop down -->

           <ng-container matColumnDef="sites">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style"
                    style="background-color: white; z-index:99999; min-width: 180px;">Fill Site
                </th>
                <td mat-cell class="dw-row-class mat-table-detail-style-override" *matCellDef="let element">
                    <mat-select *ngIf="selectedOrderItem?.id === element.id && element.itemStatus === 'New'"
                                disableOptionCentering
                                [(value)]="element.siteId" #cbStatus
                                floatLabel="never"
                                class="dw-mat-select"
                                panelClass="local-drop-down-panel"
                                style="font-size: 9pt; width:180px!important;"
                                (selectionChange)="onSiteChange($event)"
                                [disabled]="selectedOrderItem?.id !== element.id">
                        <mat-option *ngFor="let item of sitesList"
                                    [value]="item.id">{{item.handle}}</mat-option>
                    </mat-select>

                    <span *ngIf="selectedOrderItem?.id !== element.id || element.itemStatus !== 'New'"
                          style="padding-left: 3px;font-size: 9pt;">{{returnSiteHandle(element)}}</span>

                </td>
            </ng-container>

            <!-- main table rendering -->


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (click)="onRowClicked(row)">
            </tr>
        </table>
    </div>

    <!------------------------->
    <!-- SAVE / CANCEL STRIP -->
    <!------------------------->
    <div style="text-align:center; margin-top: 12px;">

        <button mat-button class="command-button"
                (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
