import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Settings } from './settings';
import { ChartDetails } from '../models/chart-details';
import { MetricCard } from '../models/metric-card';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

    constructor(private http: HttpClient) { }

    public getOrderCountsForMetricCards(siteId: any): Observable<any> {
     return this.http.get<any>(Settings.getInstance().BASE_URL + 'home/getOrdersMetricCards/' + siteId)
        .pipe(
            retry(1)
        );
    }

    public getPurchaseOrderCountsForMetricCards(siteId: any): Observable<any> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'home/getPurchaseOrdersForMetricCards/' + siteId)
            .pipe(
                retry(1)
            );
    }
}
