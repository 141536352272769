import { Settings} from '../services/settings';

export interface TransactionHistory {
    id: number;
    transactionType: string;
    userUid: string;
    oldValue: string;
    newValue: string;
    transactionDate: string;
    tableName: string;
    columnName: string;
    tableKey: string;
    entityKeyValue: number;
    txAction: string;
}

export class TransactionHistory {

    constructor() {
    }

    setRecord(txType: string, tableName: string, entityKeyVal: number, txAction: string, newVal: string = ''): void{
        this.id = 0;
        this.transactionType = txType;
        this.userUid = Settings.getInstance().loggedInUser.username;
        this.tableName = tableName;

        /** note the transaction date is defaulted in the table definition
        so we don't have to worry about it here ... **/

        this.tableKey = 'id';       // always
        this.entityKeyValue = entityKeyVal;
        this.txAction = txAction;
        this.oldValue = '';
        this.newValue = newVal;
        this.columnName = '';

    }
    valueChange(oldVal: string, newVal: string, colName: string): void {

        this.oldValue = oldVal;
        this.newValue = newVal;
        this.columnName = colName;
        this.txAction = 'Changed Field Value';
    }

}
