<div id="header" class="d-flex justify-content-between">

	<div class="header-main">

		<div class="app-title header-content" id="left-side-header">
			<img src="assets/prescript_icon_logo.png"
			 style="padding-left: 10px;scale: 1; padding-top: 6.5px;">
		</div>

		<table class="right-side-header-container" >
			<tr>
				<td>
					<a id="btn-about" class="table-font" style=" vertical-align: super; width: 30px; padding-right: 0px;" mat-button *ngIf='IsAuthenticated' (click)="showDialogAbout()">About</a>
				</td>
				<td>
					<mat-icon style="vertical-align: middle !important;" *ngIf='IsAuthenticated'>more_vert</mat-icon>
				</td>
				<td>
					<a [matMenuTriggerFor]="menu"> <span class="avatar-user-name table-font username-font" *ngIf='IsAuthenticated'>{{fullName}}</span></a>
				</td>
				<td>
					<!--display a blue circle with initials -->
					<div style="bottom: 3px; cursor: pointer;">
						<div class="avatar-container" *ngIf="!imageAvatarFlag && IsAuthenticated" [matMenuTriggerFor]="menu" style="width: 50px; height: 50px;">
							<div style="text-align: center; border-radius: 100%; text-transform: uppercase; color: rgb(255, 255, 255); background-color: rgb(44, 62, 80); font: 16px / 50px Helvetica, Arial, sans-serif;"> {{ nameShortForm }} </div>
						</div>
					</div>
					<!-- display the uploaded image -->
					<div #avatarHeader id="avatarHeader" *ngIf="imageAvatarFlag" [matMenuTriggerFor]="menu" [ngStyle]="{'background-image': getUrl()}"></div>
				</td>
			</tr>
		</table>

		<div class="right-side-header-container" id="right-side-header" #RightSideHeader>
			<mat-menu #menu="matMenu">
			<button id="menu-btn-profile" mat-menu-item (click)="showDialogProfile()">
				<mat-icon (click)="showDialogProfile()">person</mat-icon>
				<span class="table-font" (click)="showDialogProfile()">Profile</span>
			</button>
			<button id="menu-btn-logout" mat-menu-item (click)="logout()">
				<mat-icon>exit_to_app</mat-icon>
				<span class="table-font">Logout</span>
			</button>
			</mat-menu>
		</div>
	</div>
</div>
<p-dialog id="dig-about" header="About" (onShow)="abt.doLoadData()"
	[(visible)]="displayAbout" [modal]="true" [responsive]="true"
	 [style]="{height: '310px', width: '500px'}"  [resizable]="false">
	<app-about #abt	(cancelClicked)="doCancelFromAbout()"></app-about>
</p-dialog>

<p-dialog id="dig-profile" header="My Profile" (onShow)="profile.doLoadData()"
	[(visible)]="displayProfile" [modal]="true" [responsive]="true"
	 [style]="{width: '500px'}" [resizable]="false">
	<app-profile #profile (cancelClicked)="doCancelFromProfile()"></app-profile>
</p-dialog>
