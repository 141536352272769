/************
 * Service Class
 * File Name ...: suppliers.service.ts
 * Generated By : ianday on 2/17/23
 * Date ........: 2/17/23
 /************/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {Suppliers} from '../models/suppliers';
import {Settings} from './settings';
import { SupplierLicenses } from '../models/supplierlicenses';

@Injectable({
    providedIn: 'root'
})
export class SuppliersService {

    public URLPath: string = Settings.getInstance().BASE_URL + 'suppliers/suppliers';
    public SupplierLicenseURLPath: string = Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses';

    constructor(private http: HttpClient) {
    }

    getAllSuppliers(): Observable<any> {
        return this.http.get<any>(this.URLPath)
            .pipe(
                retry(1)
            );
    }

    createSuppliers(record: Suppliers): Observable<any> {
        return this.http.post(this.URLPath, record)
            .pipe(
                retry(1)
            );
    }

    updateSuppliers(record: Suppliers): Observable<any> {
        return this.http.put(this.URLPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteSuppliers(id: number): Observable<any> {
        return this.http.delete(this.URLPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    
    /*License methods*/
    
    getAllSupplierLicenses(id: number): Observable<any> {
		return this.http.get<any>(this.SupplierLicenseURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	createSupplierLicense(record: SupplierLicenses): Observable<any> {
		return this.http.post(this.SupplierLicenseURLPath, record)
			.pipe(
				retry(1)
			);
	}
	updateSupplierLicense(record: SupplierLicenses): Observable<any> {
		return this.http.put(this.SupplierLicenseURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteSupplierLicense(id: number): Observable<any> {
		return this.http.delete(this.SupplierLicenseURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	
	uploadLicenseFile(record: SupplierLicenses, license: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('license', license, license.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses/file/' + record.id, formData)
			.pipe(
				retry(0)
			);
	}
	deleteLicenseFile(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses/file/' + id)
			.pipe(
				retry(1)
			);
	}
	getLicenseFile(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses/file/' + id)
			.pipe(
				retry(1)
			);
	}
    
    
}
