import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../../app.component';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService} from '../../services/authservice/auth.service';
import { AppMapComponent} from "../../app-map.component";
import { UserService } from '../../services/user.service';
import { GlobalService } from '../../services/global-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Settings } from '../../services/settings';

@Component({

  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  fullName: string;
  appTitle: string;
  IsAuthenticated: boolean;
  displayAbout = false;
  displayProfile = false;
  avatarURL: any;
  imageAvatarFlag = true;
  nameShortForm = 'VSV';

  @Input() sidenav: MatSidenav;
  @ViewChild('avatarHeader') avatarHeader: ElementRef;
  @Output() settingsLoaded: EventEmitter<any> = new EventEmitter();
  
  constructor(private authService: AuthService, private appMap: AppMapComponent, 
          private userService: UserService, private _sanitizer: DomSanitizer, 
          public dialog: MatDialog, private globalService: GlobalService) {
    this.doLoadData();
  }
  ngOnInit() {
		if(this.sidenav !=null && this.sidenav != undefined) {
			this.sidenav.open();
		}
		this.globalService.globalEvent.subscribe((event) => {this.doLoadAvatarImage(); });
    }

  doLoadData() {
      this.authService.isAuthenticated$.subscribe(value => {
          this.IsAuthenticated = value;
          this.fullName = (this.authService.loggedInUser !=null && this.authService.loggedInUser != undefined)?this.authService.loggedInUser.name:"";
          this.nameShortForm = (this.fullName==null || this.fullName.length==0) ? '??' : this.fullName.split(" ").map((n)=>n[0]).join("");
          if (this.authService.loggedInUser !== undefined && this.authService.loggedInUser != null) {
              this.userService.getAllSettings().subscribe(
                      ( data2 ) => {
                         Settings.getInstance().generalSettingsList = data2;
                         let bannerSettting = Settings.getInstance().getGeneralSettingByKey('bannertext');
                         if (bannerSettting !== null) {
                             Settings.getInstance().strBannerText = bannerSettting.value;
                         }
                         let bannerEnableSettting = Settings.getInstance().getGeneralSettingByKey('bannerenable');
                         if (bannerSettting !== null) {
                             if (bannerEnableSettting.value === 'true') {
                               Settings.getInstance().enableMessageBanner = true;
                             } else {
                               Settings.getInstance().enableMessageBanner = false;
                             }
                         }
                         this.settingsLoaded.emit(true);
                         this.doLoadAvatarImage();
                      },
                      ( error2 ) => {
                          this.imageAvatarFlag = false;
                      }
              );
          }
      });
      this.fullName = '';
      this.setTitle(this.appMap.appTitle);
  }

  setTitle(title: string) {
    this.appTitle = title;
  }
  doLoadAvatarImage() {
	  this.imageAvatarFlag = false;
      this.userService.getUserProfileImage(this.authService.loggedInUser.id).subscribe(
              ( data1 ) => {

                  const tempImagePath = URL.createObjectURL(data1);
                  this.avatarURL = 'url(' + tempImagePath + ')';
                  this.imageAvatarFlag = true;
              },
              ( error1 ) => {
                  this.imageAvatarFlag = false;
              }
      );
  }

  logout() {
      const noLogoutConfirmFlag = localStorage.getItem('do_not_confirm_logout');
      if (noLogoutConfirmFlag == null || noLogoutConfirmFlag === '0') {
          const message = 'Logout: Are you sure you want to exit the system?';
          const dialogData = new ConfirmDialogModel('Please Confirm', message, "do_not_confirm_logout");
          
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              width: '360px',
              height: '202px',
              data: dialogData,
              panelClass: 'custom-dialog-container'
          });
          dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult === true) {
                  this.userService.logout().subscribe(() => {
                	  Settings.getInstance().jwtToken = null;
                      //AppComponent.myApp.logout();
					  location.reload();
                  });
              } else {
                  return;
              }
          });
      } else {
          this.userService.logout().subscribe(() => {
        	  Settings.getInstance().jwtToken = null;
              //AppComponent.myApp.logout();
			  location.reload();
          });
      }
  }
  
 
  avatarClicked(event: any) {
    Settings.getInstance().handleError('click on avatar fetched from ' + event.sourceType);
  }
  showDialogAbout() {
      this.displayAbout = true;
    }
  doCancelFromAbout() {
      this.displayAbout = false;
    }
  showDialogProfile() {
      this.displayProfile = true;
    }
  doCancelFromProfile() {
      this.displayProfile = false;
    }
  getUrl()
  {
    return this.avatarURL;
  }
}
