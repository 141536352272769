import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { User, ELEMENT_DATA } from 'src/app/models/user';
import { AppFunctionGroup, APP_FUNCTION_GROUP_DATA } from 'src/app/models/app-function-group';
import { UserAppFunctionGroupMap } from 'src/app/models/user-app-function-group-map';
import { UserAppFunctionMap } from 'src/app/models/user-app-function-map';
import { AppFunctionGroupMap } from 'src/app/models/app-function-group-map';
import { APP_FUNCTION_DATA, AppFunction } from 'src/app/models/app-function';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ElementRef } from '@angular/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Settings } from '../../../services/settings';
import { AppComponent } from '../../../app.component';
import { SendInviteComponent } from './send-invite/send-invite.component';
import { DatePipe } from '@angular/common';
import { UserEntity } from 'src/app/models/user-entity';

@Component({
	selector: 'app-usermanagement',
	templateUrl: './usermanagement.component.html',
	styleUrls: ['./usermanagement.component.css']
})
export class UsermanagementComponent implements OnInit {

	visibleAdd = false;
	visibleModify = false;
	visibleDelete = false;
	isEditing = false;
	isAdd = false;
	formContent: UntypedFormGroup;
	Idx = -1;
	selectedRecord: User;
	renderedData = Array<User>();
	displayedColumns: string[] = ['id', 'username', 'name', 'email', 'status', 'invitation_status', 'last_login'];
	dataSource: MatTableDataSource<User>; // = new MatTableDataSource(ELEMENT_DATA);
	pagination: string;
	formTitle = 'User Management';
	result: boolean;
	disabledFlag = true;
	strErr = '';
	selectedFilterStatus = '1';
	displayOrganization = false;
	activeCellId = -1;
	selectedOrganization: any;
	displayInvitationDlg = false;

	availableAppFunctionGroupsList = Array<AppFunctionGroup>();
	availableAppFunctionsList = Array<AppFunction>();
	allAppFunctionGroupMapList = Array<AppFunctionGroupMap>();
	assignedAppFunctionGroupsList: string[];
	assignedAppFunctionsList: string[];
	assignedAppFunctionsListCopy: string[];
	usersList: User[];
	appFunctionsList: any[];

	contentClassWithBanner = 'dwtable-container';
	contentClassWithoutBanner = 'dwtable-container-without-header';
	contentClassName = 'dwtable-container';

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('txtFilter', { static: true }) txtFilter: ElementRef;
	@ViewChild('username', { static: true }) username: ElementRef;
	@ViewChild('fullname', { static: true }) fullname: ElementRef;
	@ViewChild('password', { static: true }) password: ElementRef;
	@ViewChild('email', { static: true }) email: ElementRef;
	@ViewChild('send_invite') sendInvite: SendInviteComponent;

	constructor(private userService: UserService, public dialog: MatDialog, private datePipe: DatePipe) {
		this.formContent = new UntypedFormGroup({
			id: new UntypedFormControl('id'),
			username: new UntypedFormControl('username'),
			password: new UntypedFormControl('password'),
			name: new UntypedFormControl('name'),
			status: new UntypedFormControl('status'),
			email: new UntypedFormControl('email'),
			organization_id: new UntypedFormControl('organization_id')
			// assignedAppFunctionGroupsList: new FormControl('assignedAppFunctionGroupsList'),
			// assignedAppFunctionsList: new FormControl('assignedAppFunctionsList')
		});
		/* start out disabled in the detail form */
		this.formContent.get('id').setValue('');
		this.formContent.get('username').setValue('');
		this.formContent.get('name').setValue('');
		this.formContent.get('password').setValue('');
		this.formContent.get('status').setValue('');
		this.formContent.get('email').setValue('');
		this.formContent.get('organization_id').setValue('');
		this.formContent.disable();
	}

	@HostListener('matSortChange', ['$event'])

	onSortChange(event) {
		if (event.direction === 'asc') {
			this.renderedData.sort((a, b) =>
				a[event.active].localeCompare(b[event.active]));
		} else {
			this.renderedData.sort((a, b) =>
				b[event.active].localeCompare(a[event.active]));
		}
		this.dataSource.data = this.renderedData;
		this.Idx = this.renderedData.findIndex(s => s.id === this.selectedRecord.id);
	}
	applyFilter(filterValue: string) {
		if (this.isEditing) return;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		this.unSelectRow(false);
		this.setPagination(-1, this.dataSource.filteredData.length);
	}
	clearFilter() {
		if (this.isEditing) return;
		this.dataSource.filter = '';

		this.unSelectRow(false);
		this.setPagination(-1, this.dataSource.data.length);
	}
	ngOnInit() {
		// Now let's set the access privilege level
		if (Settings.getInstance().hasPrivilege('MANAGE_USERS', false)) {
			this.visibleAdd = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_USERS', false)) {
			this.visibleModify = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_USERS', false)) {
			this.visibleDelete = true;
		}
		this.queryRecords();
		if (Settings.getInstance().enableMessageBanner) {
			this.contentClassName = this.contentClassWithBanner;
		} else {
			this.contentClassName = this.contentClassWithoutBanner;
		}
	}
	private queryRecords() {

		if (AppComponent.staticDataSource === true) {
			this.dataSource = new MatTableDataSource(ELEMENT_DATA);
			this.dataSource.sort = this.sort;
			this.setPagination(-1, this.dataSource.data.length);

			this.availableAppFunctionGroupsList = APP_FUNCTION_GROUP_DATA;
			this.availableAppFunctionsList = APP_FUNCTION_DATA;

			return;
		}
		this.userService.getAllAppFunctionGroups().subscribe(
			(data) => {
				this.availableAppFunctionGroupsList = data;
			},
			(error) => {
			}
		);
		this.userService.getAllUsers().subscribe(
			(data) => {
				Settings.getInstance().usersList = data;
				for (const rec of data) {
					if (rec.status === 1) {
						rec.displayStatus = 'Active';
					} else {
						rec.displayStatus = 'InActive';
					}
				}
				Settings.getInstance().usersList = data;
				this.usersList = data;
				this.dataSource = new MatTableDataSource(data);
				this.dataSource.sort = this.sort;
				this.setPagination(-1, this.dataSource.data.length);
				if (this.isAdd === true) {
					this.selectedRecord = this.dataSource.data[this.dataSource.data.length - 1];
					this.displayDetail(this.selectedRecord);
				}
			},
			(error) => {
			}
		);
		this.userService.getAllAppFunctions().subscribe(
			(data) => {
				this.availableAppFunctionsList = data;
				/*this.userService.getAllAppFunctionGroupMaps().subscribe(
					(data1) => {
						this.allAppFunctionGroupMapList = data1;
						this.onChangeStatusFilterValue();
					},
					(error) => {
					}
				);
				*/
				this.onChangeStatusFilterValue();
			},
			(error) => {
			}
		);
	}
	invitationStatusLabeler(row): string {
		return Settings.invitationStatusLabeler(row);
	}
	lastLoginLabeler(row): string {

		if(row.lastLogin === null || row.lastLogin === undefined)
			return 'Never';

		return row.lastLogin.toString();
	}
	onRowClicked(SR: User) {
		if (this.isEditing) return;
		this.selectedRecord = SR;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
		this.setPagination(this.Idx, this.renderedData.length);
		this.displayDetail(this.selectedRecord);
	}
	unSelectRow(withZero: boolean) {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = new User(withZero);
		this.setPagination(this.Idx, this.renderedData.length);
		this.displayDetail(this.selectedRecord);

	}
	tableKeyDown(event: KeyboardEvent) {
		if (this.isEditing) return;
		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.renderedData[++this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.renderedData[--this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		}

		this.setPagination(this.Idx, this.renderedData.length);
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	displayDetail(record: User) {

		if (record.id === null) return;
		this.assignedAppFunctionGroupsList = [];
		this.userService.getUserAppFunctionGroupMapsByUserId(this.selectedRecord.id).subscribe(
			(data) => {
				this.assignedAppFunctionGroupsList = [];
				for (const rec of data) {
					this.assignedAppFunctionGroupsList.push(this.getAppFunctionGroupRecordById(rec['appFunctionGroupId']).name);
				}
				this.userService.getUserAppFunctionMapsByUserId(this.selectedRecord.id).subscribe(
					(data1) => {
						this.appFunctionsList = [];
	                    for ( const appFunRec of this.availableAppFunctionsList ) {
	                    	let obj = {id: appFunRec.id, label: appFunRec.description, selected: false, readOnly: true};
	                    	for(const rec of data1) {
	                    		if(appFunRec.id == rec.appFunctionId) {
	                    			obj.selected = true;
	                    			obj.readOnly = rec.readOnly;
	                    		}
	                    	}                        	
	                        this.appFunctionsList.push(obj);
	                    }
						this.formContent.get('name').setValue(record.name);
						this.formContent.get('password').setValue(record.password);
						this.formContent.get('username').setValue(record.username);
						this.formContent.get('email').setValue(record.email);
						
						this.formContent.get('id').setValue(record.id);
						if (record.status === 1) {
							this.formContent.get('status').setValue('Active');
						} else {
							this.formContent.get('status').setValue('InActive');
						}
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			},
			(error) => {
				Settings.getInstance().handleError(error);
			}
		);
	}
	addModifyRecord(isAdd: boolean) {
		this.isEditing = true;
		this.formContent.enable();
		this.formContent.get('id').disable();
		this.disabledFlag = false;

		if (isAdd === true) {
			this.isAdd = true;

			this.unSelectRow(true);
			this.selectedRecord.id = 0;
			this.formContent.get('status').setValue('Active');
			this.formContent.get('id').setValue('0');
			this.formContent.get('email').setValue('');
		} else {
			this.isAdd = false;
		}

		/* set the focus to the username */
		this.setFieldFocus('username');
	}
	removeRecord() {
		const message = 'Remove this record - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {
				this.userService.deleteUser(this.selectedRecord.id).subscribe(
					(data) => {
						console.log('deleted record.');
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}
	validateRecord(): boolean {
		this.strErr = '';
		if (this.formContent.get('username').value.length === 0) {
			this.strErr = 'Error: A user name/login is required';
			this.setFieldFocus('username');
			return false;
		}
		if (this.isAdd && this.getUserRecordByUserName(this.formContent.get('username').value) !== null) {
			this.strErr = 'Error: This username already exists';
			this.setFieldFocus('username');
			return false;
		}
		if (!this.isblankOrUndefined(this.formContent.get('name').value)) {
			this.strErr = 'Error: A full name is required';
			this.setFieldFocus('name');
			return false;
		}
	
		if (!this.isblankOrUndefined(this.formContent.get('email').value)) {
			this.strErr = 'Error: A valid email is required';
			this.setFieldFocus('email');
			return false;
		}

		return true;
	}
	isblankOrUndefined(val: any): boolean {

		if (val === undefined || val === null || val === '') {
			return false;
		}
		return true;
	}
	submitRecord() {
		if (this.validateRecord() === false) {
			return;
		}
		const SR: User = this.formContent.getRawValue();
		this.isEditing = false;

		const selectedAppFunctionGroupsList: AppFunctionGroup[] = [];
		for (let i = 0; i < this.assignedAppFunctionGroupsList.length; i++) {
			for (const recFunction of this.availableAppFunctionGroupsList) {
				if (recFunction.name === this.assignedAppFunctionGroupsList[i]) {
					selectedAppFunctionGroupsList.push(recFunction);
				}
			}
		}
		// Now let's find out the selected AppFunctions for this user
		const selectedAppFunctionsList: any[] = [];
		for (let i = 0; i < this.appFunctionsList.length; i++) {
			if (this.appFunctionsList[i].selected) {
				selectedAppFunctionsList.push({appFunctionId: this.appFunctionsList[i].id, readOnly: this.appFunctionsList[i].readOnly});				
			}
		}

		if (this.isAdd) {
			// Let's add a new user now!
			const newUser = new User();
			newUser.id = this.formContent.get('id').value;
			newUser.username = this.formContent.get('username').value;
			newUser.password = this.formContent.get('password').value;
			newUser.email = this.formContent.get('email').value;
			newUser.name = this.toTitleCase(this.formContent.get('name').value);
			if (this.formContent.get('status').value === 'Active') {
				newUser.status = 1;
			} else {
				newUser.status = 0;
			}
			newUser.appFunctionGroups = selectedAppFunctionGroupsList;
			newUser.appFunctions = selectedAppFunctionsList;
			let userEntity = new UserEntity(newUser);
			this.userService.createUser(userEntity).subscribe(
				(data) => {
					if (data !== undefined && data !== null && data.id > 0) {
						const userAppFunctionsGroupMapList: any[] = [];
						const userAppFunctionsMapList: any[] = [];
						for (const rec of newUser.appFunctionGroups) {
							const temp: UserAppFunctionGroupMap = new UserAppFunctionGroupMap();
							userAppFunctionsGroupMapList.push(rec.id);
						}
						this.userService.createUserAppFunctionGroupMaps(data.id, userAppFunctionsGroupMapList).subscribe(
							(data1) => {
								this.userService.createUserAppFunctionMaps(data, newUser.appFunctions).subscribe(
									(data2) => {
										console.log('user group memberships created/updated');
										this.queryRecords();
									},
									(error) => {
									}
								);
							},
							(error) => {
							}
						);
					}
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		} else {
			// here, let's update the edited record
			this.selectedRecord.username = this.formContent.get('username').value;
			this.selectedRecord.password = this.formContent.get('password').value;
			this.selectedRecord.email = this.formContent.get('email').value;
			this.selectedRecord.name = this.toTitleCase(this.formContent.get('name').value);
			this.selectedRecord.appFunctionGroups = selectedAppFunctionGroupsList;
			this.selectedRecord.appFunctions = selectedAppFunctionsList;

			if (this.formContent.get('status').value === 'Active') {
				this.selectedRecord.status = 1;
			} else {
				this.selectedRecord.status = 0;
			}
			const userEntity = new UserEntity(this.selectedRecord);
			this.userService.updateUser(userEntity).subscribe(
				(data) => {
					const userAppFunctionsGroupMapList: any[] = [];
						const userAppFunctionsMapList: any[] = [];
					for (const rec of this.selectedRecord.appFunctionGroups) {
						const temp: UserAppFunctionGroupMap = new UserAppFunctionGroupMap();
						userAppFunctionsGroupMapList.push(rec.id);
					}
					this.userService.createUserAppFunctionGroupMaps(this.selectedRecord.id, userAppFunctionsGroupMapList).subscribe(
						(data1) => {
							this.userService.createUserAppFunctionMaps(this.selectedRecord.id, this.selectedRecord.appFunctions).subscribe(
								(data2) => {
									console.log('user group memberships created/updated');
									this.queryRecords();
								},
								(error) => {
								}
							);
						},
						(error) => {
						}
					);
				},
				(error) => {
				}
			);
		}
		this.isEditing = false;
		this.formContent.disable();
		this.disabledFlag = true;
	}
	cancelSubmitRecord() {
		this.strErr = '';
		this.displayDetail(this.selectedRecord);
		this.isEditing = false;
		this.formContent.disable();
		this.disabledFlag = true;
	}
	updateListTable(SR: User) {
		if (this.Idx > -1) {
			/* set the value at the index position */
			this.dataSource.data[this.Idx] = SR;
		} else {
			/* Append it to the bottom of the table */
			this.dataSource.data.push(SR);
		}
		// this.dataSource.data;
	}
	getUserRecord(id: number): User {
		for (const rec of Settings.getInstance().usersList) {
			if (rec.id === id) {
				return rec;
			}
		}
		return null;
	}
	getUserRecordByUserName(username: string): User {
		for (const rec of Settings.getInstance().usersList) {
			if (rec.username === username) {
				return rec;
			}
		}
		return null;
	}
	groupSelectionChanged(): any {
		if (this.assignedAppFunctionGroupsList === undefined) {
			return;
		}
		this.assignedAppFunctionsListCopy = [];
		for (let i = 0; i < this.assignedAppFunctionGroupsList.length; i++) {
			const groupRecord: AppFunctionGroup = this.getAppFunctionGroupRecord(this.assignedAppFunctionGroupsList[i]);
			if (groupRecord === null) {
				continue;
			}
			/*
			for ( const appFunctionRecord of this.getAppFunctionListByAppGroupId(groupRecord.id) ) {
				if ( !this.isExistingInAssignedAppFunctionsList( appFunctionRecord.description ) ) {
					this.assignedAppFunctionsListCopy.push( appFunctionRecord.description );
				}
			}
			*/
		}
		this.assignedAppFunctionsList = this.assignedAppFunctionsListCopy;
	}
	isExistingInAssignedAppFunctionsList(param: string): any {
		for (const rec of this.assignedAppFunctionGroupsList) {
			if (rec === param) {
				return true;
			}
		}
		return false;
	}
	setFieldFocus(fieldName: string) {

		setTimeout(() => {

			switch (fieldName) {
				case 'username':
					this.username.nativeElement.focus();
					break;
				case 'name':
					this.fullname.nativeElement.focus();
					break;
				case 'password':
					this.password.nativeElement.focus();
					break;
				case 'email':
					this.email.nativeElement.focus();
					break;
			}
		}, 0);
	}
	toTitleCase(str) {
		return str.replace(/\w\S*/g, function(txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
	getAppFunctionGroupRecord(param: string): AppFunctionGroup {
		for (const rec of this.availableAppFunctionGroupsList) {
			if (rec.name === param) {
				return rec;
			}
		}
		return null;
	}
	getAppFunctionGroupRecordById(id: number): AppFunctionGroup {
		for (const rec of this.availableAppFunctionGroupsList) {
			if (rec.id === id) {
				return rec;
			}
		}
		return null;
	}
	getAppFunctionRecord(id: number): AppFunction {
		for (const rec of this.availableAppFunctionsList) {
			if (rec.id === id) {
				return rec;
			}
		}
		return null;
	}
	getAppFunctionListByAppGroupId(paramId: number): AppFunction[] {
		let ret: AppFunction[] = Array<AppFunction>();
		ret = [];
		for (const rec of this.allAppFunctionGroupMapList) {
			if (rec.appFunctionGroupId === paramId) {
				ret.push(this.getAppFunctionRecord(rec.appFunctionId));
			}
		}
		return ret;
	}
	onChangeStatusFilterValue() {
		const dataTemp: User[] = [];
		if (this.selectedFilterStatus === '0') {
			for (const rec of Settings.getInstance().usersList) {
				if (rec.status === 0) {
					dataTemp.push(rec);
				}
			}
		} else if (this.selectedFilterStatus === '1') {
			for (const rec of Settings.getInstance().usersList) {
				if (rec.status === 1) {
					dataTemp.push(rec);
				}
			}
		} else if (this.selectedFilterStatus === '2') {
			for (const rec of Settings.getInstance().usersList) {
				dataTemp.push(rec);
			}
		}
		this.dataSource = new MatTableDataSource(dataTemp);
		this.dataSource.sort = this.sort;
		this.setPagination(-1, this.dataSource.data.length);
	}
	showDialogOrganization() {
		this.displayOrganization = true;
	}
	setOrganization(event) {
		this.selectedOrganization = event;
		if (this.selectedOrganization != null && this.selectedOrganization != undefined) {
			this.formContent.get('organization_id').setValue(this.selectedOrganization.name);
		} else {
			this.formContent.get('organization_id').setValue('');
		}
		this.displayOrganization = false;
	}
	cancelClickedfromOrganizationSelector() {
		this.displayOrganization = false;
	}
	statusCellReceivedFocus(id) {
		this.activeCellId = id;
	}
	onStatusChange() {
		this.selectedRecord.status = this.selectedRecord.status == 1 ? 0 : 1;
		if (this.selectedRecord.status === 1) {
			this.selectedRecord.displayStatus = 'Active';
		} else {
			this.selectedRecord.displayStatus = 'InActive';
		}

		const userEntity: UserEntity = new UserEntity(this.selectedRecord);
		this.userService.updateUser(userEntity).subscribe(
			(data) => {

			});
	}
	openInvitationDialog() {
		this.displayInvitationDlg = true;
		this.sendInvite.doInitialize(this.dataSource.data.filter(s => s.status === 1));

	}
	cancelClickedFromSendInvite() {
		this.displayInvitationDlg = false;
	}
	invitationSentHandler() {
		this.queryRecords();
		this.displayInvitationDlg = false;
	}
}
