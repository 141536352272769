import { Component, HostListener, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { BprBulkItems } from 'src/app/models/bprbulkitems';
import { FormingService } from 'src/app/services/forming.service';
import { Settings } from 'src/app/services/settings';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-bulkitemsview',
  templateUrl: './bulkitemsview.component.html',
  styleUrls: ['./bulkitemsview.component.css']
})
export class BulkitemsviewComponent implements OnInit {
  _bprId: number;
  records: BprBulkItems[];
  displayedColumns: string[] = [
     'icn', 'itemPrefix','ndcNumber', 'remaining','expirationDate', 'lotNumber', 'checkedStatus'];
  dataSource: MatTableDataSource<BprBulkItems>;
  Idx =1;
  
  @Output() cancelClicked = new EventEmitter();

  @Input() set bprId(value: any) {
    if (value) {
      this._bprId = value;
      this.onShowHandler();
    } else {
      // Handle the case when value is not provided
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private formingService: FormingService) {
    this.dataSource = new MatTableDataSource<BprBulkItems>();
  }

  ngOnInit() {
  }

  onShowHandler() {
    this.formingService.getAllBprBulkItemsByBprId(this._bprId).subscribe(
      (result) => {
        this.records = result;
        this.dataSource.data = this.records;
      },
      (error) => {
        Settings.getInstance().handleError('Error: unable to retrieve data from the service');
      }
    );
  }

  @HostListener('matSortChange', ['$event'])
  onSortChange(event: any) {
    if (event.direction === 'asc') {
      this.records.sort((a, b) =>
        Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
      );
    } else {
      this.records.sort((a, b) =>
        Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
      );
    }

    this.dataSource.data = this.records;
  }

  doClose() {
    this.cancelClicked.emit('');
  }
  tableKeyDown(event){
	
}
}
