/**
 *  Filename ....: bulkunitgraphics.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/17/23
 **/
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BulkUnits} from '../../../../../../../models/bulkunits';
import {TransactionHistoryComponent} from '../../../../../../../components/transaction-history/transaction-history';
import {MedicationService} from '../../../../../../../services/medication.service';
import {Settings} from '../../../../../../../services/settings';

@Component({
    selector: 'app-bulkunitgraphics',
    templateUrl: './bulkunitgraphics.component.html',
    styleUrls: ['./bulkunitgraphics.component.css']
})

export class BulkUnitGraphicsComponent implements OnInit {

    public bulkUnit: BulkUnits;
    public barDataSource: any;
    public stackedOptions: any;
    public dosesUsed = 0;
    public initialShow = false;
    public _selectedRecord: any;
    public assetsPath = '../../../../assets/';
    public remainingPercentLeft = 0;
    public dosesPieces;
    @ViewChild('usageChart', {static: true}) usageChart;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;

    @Input() set selectedRecord(val: any) {
        if (val === undefined)
            return;
        this._selectedRecord = val;
        this.onShowHandler();
    }
    get selectedRecord(): any {
        return this._selectedRecord;
    }
    constructor() {
        this.bulkUnit = new BulkUnits();
        this.dosesPieces = 'Doses';
    }
    ngOnInit() {
        this.setStackedOptions();
    }
    setStackedOptions(): void {

        this.stackedOptions = {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: { color: 'gray' }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: { color: 'gray' },
                    grid: { color: '#e1e1e1'
                    }
                },
                y: {
                    stacked: true,
                    ticks: { color: 'gray' },
                    grid: { color: '#e1e1e1'
                    }
                }
            }
        };
    }
    clearChart(): void {
        delete this.usageChart;
    }
    onShowHandler(): void {

        if (this._selectedRecord === undefined)
            return;
        this.clearChart()
        const used = Number(this._selectedRecord.originalDoses) - (Number(this._selectedRecord.defectiveDoses) + Number(this._selectedRecord.remainingDoses));
        this.setStackedOptions();
        this.showInitialUsageBar(Number(this._selectedRecord.remainingDoses), Number(this._selectedRecord.defectiveDoses), used);
        this.remainingPercentLeft = (Number(this.selectedRecord.remainingDoses) / this._selectedRecord.originalDoses) * 100;

        }
    showInitialUsageBar(remaining, defective, used): void {

        this.barDataSource = {
            labels: ['Consumption'],
            datasets: [
                {
                    label: this.dosesPieces + ' left',
                    backgroundColor: 'rgba(13, 234, 0, 0.69)',
                    opacity: .2,
                    data: [remaining]
                },
                {
                    label: 'Defective ' + this.dosesPieces,
                    backgroundColor: 'rgba(139, 0, 0, 0.69)',
                    opacity: .2,
                    data: [defective]
                },
                {
                    label: this.dosesPieces + ' Used',
                    backgroundColor: 'rgba(180, 179, 179, 0.69)',
                    opacity: .2,
                    data: [used]
                },
            ]
        };
    }
    fetchContainerStatusIcon(inp: string): string {
        if (inp === null)
            return '';
        return this.assetsPath + '/' + inp.toLowerCase() + '.png';
    }
}


