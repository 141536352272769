import {ValidationResult} from './validation-result';

/************
 * Model Class
 * File Name ...: inventories.components.ts
 * Generated By : ianday on 3/30/23
 * Date ........: 3/30/23
 /************/
export interface Inventories {

    id: number | null;
    catalogueId: number | null;          /* the id of the catalogue */
    catalogueHandle: string | null;     /* the handle of the catalogue */
    foreignKeyTable: string | null;
    foreignKeyId: number | null;
    itemHandle: string | null;
    itemType: string | null;
    itemPrefix: string | null;
    packageSize: string | null;
    unitOfMeasure: string | null;
    ndcNumber: string | null;
    initialQty: number | null;
    onHand: number | null;
    onOrder: number | null;
    consumedQty: number | null;
    reserved: number | null;
}

export class Inventories {
    constructor() {
        this.id = 0;
        this.catalogueId = 0;          /* the id of the catalogue */
        this.catalogueHandle = '';     /* the handle of the catalogue */
        this.foreignKeyTable = '';
        this.foreignKeyId = 0;
        this.itemHandle = '';
        this.itemType = '';
        this.itemPrefix = '';
        this.packageSize = '';
        this.unitOfMeasure = '';
        this.ndcNumber = '';
        this.initialQty = 0;
        this.onHand = 0;
        this.onOrder = 0;
        this.consumedQty = 0;
        this.reserved = 0;
    }
    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.id === 0 || this.id === null) {
            vr.focus_field = 'id';                              /* this should be the id value of the field */
            vr.error = 'Error: an id must be specified - or use \"generate\" to create one';
            return vr;
        }

        return vr;
    }
}
