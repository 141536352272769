import {CustomerFormulary} from './customer-formulary';
import {MedicationFamily} from './medicationfamily';
import {PrecautionDirection} from './precautiondirection';

export interface FormularyMedication {
	id: number;
	carrierServicesId: number | null;
	directions: string | null;
	altDirections: string | null;
	precautions: string | null;
	altPrecautions: string | null;
	approvalStatus: string | null;
	createdDate: Date | null;
	customerFormularyId: number;
	customerId: number;
	medicationId: number;
	medicationFamilyId: number;
	packageSize: number;
	itemNumber: string | null;
	seqNumber: number | null;
	medicationDescription: string;
}

export class FormularyMedication {

	constructor() {
		this.id = 0;
	}
	setFormularyMedicationFromPrepare(cf: CustomerFormulary, mf: MedicationFamily, pd: PrecautionDirection, pkgSize: number): void {
		this.id = 0;
		this.carrierServicesId = 0;
		this.directions = pd.directions;
		this.altPrecautions = pd.altDirections;
		this.precautions = pd.precautions;
		this.altPrecautions = pd.altPrecautions;
		this.approvalStatus = 'Draft';
		this.createdDate = new Date();
		this.customerFormularyId = cf.id;
		this.customerId = cf.customerId;
		this.medicationFamilyId = mf.id;
		this.seqNumber = 1;
		this.medicationDescription = mf.description;
		this.packageSize = pkgSize;
	}
}
