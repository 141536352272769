/************
 * Model Class
 * File Name ...: icn.components.ts
 * Generated By : ianday on 4/28/23
 * Date ........: 4/28/23
 /************/
import {ValidationResult} from './validation-result';

export interface InventoryControlNumbers {
    icn: number | null;
    serialNumber: string | null;
    dateRequested: string | null;
}

export class InventoryControlNumbers {
    constructor() {
        this.icn = 0;
        this.serialNumber = '';
        this.dateRequested = '';
    }

}
