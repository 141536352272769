/********
 *  Filename ....: addresses.component.ts
 *  Created by ..: ianday
 *  on Date .....: 2/22/23
********/
import {Component, Input, Output, EventEmitter, ViewChild, OnInit, } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AddressesService } from '../../../services/Common/Addresses.service';
import { Addresses } from '../../../models/addresses';
import { AddressEntries } from '../../../models/addressentries';
import { Settings } from '../../../services/settings';
import { MatSort } from '@angular/material/sort';
import { AddAddressComponent } from './add-address/add-address.component';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {  MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MessageBoxComponent} from '../../message-box/message-box.component';

@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.css']
})

export class AddressesComponent implements OnInit {

    public visibleAdd = true;
    public addEditFlag: boolean;
    public dataSource: MatTableDataSource<AddressEntries>;
    public displayedColumns = ['id', 'address', 'edit', 'delete'];
    private _parentId: number;
    private _maxAddresses: number;
    private _callingComponent: any;
    public addressService: AddressesService;
    public Idx: number | null = -1;
    public records: any;
    public _selectedRecord = new AddressEntries();
    public displayAddAddress = false;

    @Output() deleteMessage: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatSort, { static: true }) sort: MatSort ;
    @ViewChild(AddAddressComponent)
    public addAddress: AddAddressComponent;

    @Input() set parentId(value: number) {
        this._parentId = value;

        if (value === undefined || value <= 0) {
            return;
        } else {
            this.Idx = -1;
            this.addressRecordSelected.emit(0);
            this.queryRecords();
        }
    }
    get parentId(): number {
        return this._parentId;
    }
    @Input() set callingComponent(value: string) {
        this._callingComponent = value;
    }
        get callingComponent(): string {
        return this._callingComponent;
    }
    @Input() set maxAddresses(value: number) {
        this._maxAddresses = value;
    }
    get maxAddresses(): number {
        return this._maxAddresses;
    }
    // this is fired whenever an address record is selected.
    @Output () addressRecordSelected = new EventEmitter<number>();

    @Input() set selectedRecord(value: AddressEntries) {
        this._selectedRecord = value;
        this.addressRecordSelected.emit(this.selectedRecord.id);        // fire event.
    }
    get selectedRecord(): AddressEntries {
        return this._selectedRecord;
    }

    get disabledAddFlag(): boolean {

        if (this.records === undefined) {
            return true;
        }
        else {
            if ((this.parentId < 1) || (this.records.length >= this.maxAddresses)) {
                return true;
            }
        }
        return false;
    }

    constructor(asvc: AddressesService, public dialog: MatDialog) {
        this.addressService = asvc;
    }

    ngOnInit() {

    }
    queryRecords() {

        this.addressService.getAddressesByFK(this.callingComponent, this.parentId) .subscribe(
            (result) => {
                this.records = result;
                // this initialization forces an event to be thrown with a zero value in the id.
                this.selectedRecord = new AddressEntries();
                this.setDataSource();
                this.dataSource.sort = this.sort;
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    setDataSource(): void
    {
        this.dataSource = new MatTableDataSource(this.translate(this.records));
    }
    doSortChange(event): void {
        if (this.sort.direction === '') {
            this.sort.direction = 'asc';
        }
        this.dataSource.sortData(this.dataSource.data, this.sort);
        this.selectRow();

    }
    translate(inpRecords: any): any {

        const retRecords: any = new Array<AddressEntries>();

        if (inpRecords.length === 0) {
            return retRecords;
        }

        for (let i = 0; i < inpRecords.length; i++) {
            const src: Addresses = inpRecords[i];
            const ae: AddressEntries = new AddressEntries();
            ae.id = inpRecords[i].id;
            ae.address = '<b>' + inpRecords[i].entityDescription + '</b><br>'
                + inpRecords[i].address1 + ',<br>';
            if (inpRecords[i].address2 !== null) {

                if (inpRecords[i].address2.length > 0) {
                    ae.address += inpRecords[i].address2 + ',<br>';
                }
            }
            ae.address += inpRecords[i].city + ', ' + inpRecords[i].state + ' ' + inpRecords[i].zipCode;
            ae.edit = '';
            ae.delete = '';

            retRecords.push(ae);
        }
        return retRecords;
    }
    addNewRecord(): void {
        this.displayAddAddress = true;
        this.addEditFlag = true;
        this.addAddress.afterOpenDialog(new Addresses(), this.addEditFlag);
    }
    editRecord(idx: number): void {
        this.onRowClicked(idx);
        this.displayAddAddress = true;
        this.addEditFlag = false;
        this.addAddress.afterOpenDialog(this.records[this.Idx], this.addEditFlag);

    }
    newAddressCreated(event: any): void {

        if (this.addEditFlag === true) {
            // push the new record onto the top of the stack.
            this.records.unshift(event as Addresses);
        }
        else {
            this.records[this.Idx] = event as Addresses;
        }
        this.setDataSource();
        this.dataSource.sort = this.sort;
        this.Idx = 0;       // sets the currently selected row

        this.displayAddAddress = false;
    }
    newAddressCanceled(event): void {
        this.displayAddAddress = false;
    }
    deleteRecord(idx: number): void {

        this.Idx = idx;
        const localRecord = this.records[idx];
        const message = 'Remove Address for: "' + localRecord.entityDescription + '"<br/><b>Are you sure?</b>';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const result = dialogResult;
            if (result === true) {

                 this.addressService.removeAddress(localRecord.id).subscribe(
                    (data) => {

                        if (data.hasOwnProperty('severity') === true) {
                           // error the delete failed
                           if (data.severity === 'ERROR') {
                               const strMessage = 'Error: Cannot delete this record due to existing relationships';
                               this.deleteMessage.emit(strMessage);
                               return;
                           }
                        }

                        const localIdx = this.records.findIndex(r => r.id === localRecord.id);
                        this.records.splice(localIdx, 1);
                        this.setDataSource();
                        this.selectedRecord = new AddressEntries();
                        this.Idx = -1;              // unset selected records.
                    },
                    (error) => {
                        Settings.getInstance().handleError('Error: unable to remove this record.')
                    });
            }
        });
    }
    onRowClicked(idx: number): void {
        this.Idx = idx;                     // sets the row.
        this.selectedRecord = this.dataSource.data[idx];
    }
    tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.data.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.data[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.data[--this.Idx];
            }
        }
    }
    selectRow() {

        if (this.selectedRecord.id === 0) {
            return;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.selectedRecord.id === this.dataSource.data[i].id) {
                // set this record's index in the table.
                this.Idx = i;
                return;
            }
        }
    }
}


