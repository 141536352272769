import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { APP_BASE_HREF, Location } from '@angular/common';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './options/home/home.component';
import { SettingsComponent } from './options/settings/settings.component';
import { CustomMaterialModule } from './material.module';
import { AppMaterialModule } from './material-module';
import { BasicAuthInterceptor } from './services/interceptors/basic-auth.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { SidenavService } from './services/sidenav.service';
import { AppMapService } from './services/appmap.service';
import { AppMapComponent } from './app-map.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarEventManager } from './_common/NavbarEventManager';
import { ConfirmDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChartModule } from 'primeng/chart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { TurboTableKeysDirective } from './directives/turbo-table-keys.directive';
import { MatRowKeyboardSelectionDirective } from './directives/mat-row-keyboard-selection.directive';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DwCalendar } from './custom/dwcalendar/dwcalendar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { TreeTableModule } from 'primeng/treetable';
import { getBaseLocation } from './_common/common-functions.util';
import { CodeInputModule } from 'angular-code-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSortModule } from '@angular/material/sort';
import { TextMaskModule } from 'angular2-text-mask';

import { AddressBookComponent } from './components/addressbook/addressbook.component';
import { AddressesComponent } from './components/addressbook/addresses/addresses.component';
import { ContactsComponent } from './components/addressbook/contacts/contacts.component';
import { AngularSplitModule } from 'angular-split';
import { AddAddressComponent } from './components/addressbook/addresses/add-address/add-address.component';
import { AddContactsComponent } from './components/addressbook/contacts/add-contact/add-contact.component';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { AddLicenseComponent } from './options/forming/customermaster/customerlicenses/add-license/add-license.component';

/*** User and group management components **/
import { UsermanagementComponent } from './options/settings/usermanagement/usermanagement.component';
import { GroupmanagementComponent } from './options/settings/groupmanagement/groupmanagement.component';
import { GeneralsettingsComponent } from './options/settings/generalsettings/generalsettings.component';
import { NotesComponent } from './components/notes/notes.component';

import { DialogModule } from 'primeng/dialog';
import { AboutComponent } from './components/about/about.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { NgxColorsModule } from 'ngx-colors';
import { DwStepperComponent } from './custom/dw-stepper/dw-stepper.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history';
import { DwUtils } from './components/utils/dw-utils';
import { DragDropDirective } from './directives/drag-drop.directive';
import { DwTimelineComponent } from './custom/dw-timeline/dw-timeline.component';

import { HomeRendererComponent } from './options/home/home-renderer/home-renderer.component';
import { CardComponent } from './components/card/card.component';
import { MetricCardComponent } from './components/metric-card/metric-card.component';
import { GraphComponent } from './components/graph/graph.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { AlertDialogComponent } from './components/shared/alert-dialog/alert-dialog.component';

import { ReportsmainComponent } from './options/home/reports/reportsmain/reportsmain.component';
import { ReportRendererComponent } from './options/home/reports/report-renderer/report-renderer.component';
import { ReportRowComponent } from './options/home/reports/report-row/report-row.component';
import { ReportCellComponent } from './options/home/reports/report-cell/report-cell.component';
import { ReportParamsComponent } from './options/home/reports/report-params/report-params.component';
import { SelectuserComponent } from './components/selectuser/selectuser.component';
import { EmailsettingsComponent } from './options/settings/emailsettings/emailsettings.component';

import { SafeHTMLPipe } from './custom/safe-html.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { SendInviteComponent } from './options/settings/usermanagement/send-invite/send-invite.component';
import { DwSplitterComponent } from './custom/dw-splitter/dw-splitter.component';
import { InventoryComponent } from './options/inventory/inventory.component';
import { FormingComponent } from './options/forming/forming.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { LabelGenComponent } from './components/label-gen/label-gen';
/* Inventory modules */
import { InventoriesComponent } from './options/inventory/inventories-inv/inventories-inv.component';
import { MedicationsInvComponent } from './options/inventory/medications-inv/medications-inv.component';
import { MaterialsInvComponent } from './options/inventory/materials-inv/materials-inv.component';
import { SuppliersInvComponent } from './options/inventory/suppliers-inv/suppliers-inv.component';
import { SitesInvComponent } from './options/inventory/sites-inv/sites-inv.component';
import { MedicationPropertiesComponent } from './options/inventory/medications-inv/property-tabs/medication-properties/medication-properties.component';
import { MedicationImagesComponent } from './options/inventory/medications-inv/property-tabs/medication-images/medication-images.component';
import { MedicationLinksComponent } from './options/inventory/medications-inv/property-tabs/medication-links/medication-links.component';
import { MedicationSuppliersComponent } from './options/inventory/medications-inv/property-tabs/medication-suppliers/medication-suppliers.component';
import { StationsComponent } from './options/inventory/sites-inv/stations/stations.component';
import { PurchaseOrdersComponent } from './options/inventory/purchaseorders/purchaseorders.component';
import { AddPurchaseOrderComponent } from './options/inventory/purchaseorders/addpurchaseorder/addpurchaseorder.component';
import { BulkUnitsComponent } from './options/inventory/inventories-inv/bulkunits/bulkunits.component';
import { BulkUnitsDetailComponent } from './options/inventory/inventories-inv/bulkunits/dialogs/bulkunitsdetail/bulkunitsdetail.component';
import { BulkCheckInComponent } from './options/inventory/inventories-inv/bulkcheckin/bulkcheckin.component';

/* Forming modules */
import { NewOrdersComponent } from './options/forming/neworders/neworders.component';
import { PreparationComponent } from './options/forming/preparation/preparation.component';
import { ShippingComponent } from './options/forming/shipping/shipping.component';
import { CompletedordersComponent } from './options/forming/completedorders/completedorders.component';
import { CustomerMasterComponent } from './options/forming/customermaster/customermaster.component';
import { AddeditlinkComponent } from './options/inventory/medications-inv/property-tabs/medication-links/addeditlink/addeditlink.component';
import { MedicationFamilyComponent } from './options/inventory/medication-family/medication-family.component';
import { CustomerLicensesComponent } from './options/forming/customermaster/customerlicenses/customerlicenses.component';
import { AddimageComponent } from './options/inventory/medications-inv/property-tabs/medication-images/addimage/addimage.component';
import { AddLicenseStateURLsComponent } from './options/forming/customermaster/customerlicenses/add-license/add-licensestateurl/add-licensestateurl.component';
import { AddeditmedicationsupplierComponent } from './options/inventory/medications-inv/property-tabs/medication-suppliers/addeditmedicationsupplier/addeditmedicationsupplier.component';
import { FileSelectorComponent } from './components/shared/file-selector/file-selector.component';
import { AddStationComponent } from './options/inventory/sites-inv/stations/add-station/add-station.component';
import { TransHistoryDetailComponent } from './components/transaction-history/trans-history-detail/trans-history-detail.component';
import { MaterialSuppliersComponent } from './options/inventory/materials-inv/property-tabs/material-suppliers/material-suppliers.component';
import { AddeditmaterialsupplierComponent } from './options/inventory/materials-inv/property-tabs/material-suppliers/addeditmaterialsupplier/addeditmaterialsupplier.component';
import { AddCataloguesComponent } from './options/inventory/sites-inv/catalogues/add-catalogues/add-catalogues.component';
import {CataloguesComponent} from './options/inventory/sites-inv/catalogues/catalogues.component';
import {FormularyMainComponent} from './options/forming/customermaster/dialogs/formulary-main/formulary-main.component';
import { AddLabelComponent } from './options/forming/customermaster/dialogs/formulary-main/dialogs/add-label/add-label.component';
import { ChangeStatusComponent } from './options/forming/customermaster/dialogs/formulary-main/dialogs/change-status/change-status.component';
import { AddMaterialComponent } from './options/forming/customermaster/dialogs/formulary-main/dialogs/add-material/add-material.component';
import { BulkUnitGraphicsComponent } from './options/inventory/inventories-inv/bulkunits/dialogs/bulkunitsdetail/bulkunitgraphics/bulkunitgraphics.component';
import {InventoryTransferComponent} from './options/inventory/inventories-inv/bulkunits/dialogs/invtransfer/invtransfer.component';
import {ReturnManuComponent} from './options/inventory/inventories-inv/bulkunits/dialogs/returnmanu/returnmanu.component';
import {QuickAddSupplierComponent} from './options/inventory/suppliers-inv/qasupplier/qasupplier.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import { ImgVerifyComponent } from './options/inventory/inventories-inv/bulkcheckin/imgverify/imgverify.component';
import { MedicationIngredientsComponent } from './options/inventory/medication-family/tabs/medication-ingredients/medication-ingredients.component';
import { AddeditmedicationingredientsComponent } from './options/inventory/medication-family/tabs/medication-ingredients/addeditmedicationingredients/addeditmedicationingredients.component';
import {QualityControlComponent} from './options/forming/qualitycontrol/qualitycontrol.component';
import {InProcessComponent} from './options/forming/inprocess/inprocess.component';
import {ReleaseComponent} from './options/forming/release/release.component';
import {SelectCreatePOComponent} from './options/inventory/purchaseorders/selectcreatepo/selectcreatepo.component';
import {AddMedPOItemComponent} from './options/inventory/purchaseorders/addmedpoitem/addmedpoitem.component';
import { SupplierlicesesComponent } from './options/inventory/suppliers-inv/supplierlicenses/supplierlicenses.component';
import { AddSupplierlicenseComponent } from './options/inventory/suppliers-inv/supplierlicenses/add-supplierlicense/add-supplierlicense.component';
import {AddMatPOItemComponent} from './options/inventory/purchaseorders/addmatpoitem/addmatpoitem.component';
import {AddOrderComponent} from './options/forming/neworders/addorder/addorder.component';
import {LookupItemComponent} from './options/forming/neworders/lookupitem/lookupitem.component';
import {AcceptOrderItemsComponent} from './options/forming/neworders/acceptorderitems/acceptorderitems.component';
import {BprComponent} from './components/bpr/bpr.component';
import {PrepareBatchComponent} from './options/forming/preparation/preparebatch/preparebatch.component';
import {ChangeAssignmentsComponent} from './options/forming/preparation/changeassignments/changeassignments.component';
import {FinishBatchComponent} from './options/forming/inprocess/finishbatch/finishbatch.component';
import {ApproveBatchComponent} from './options/forming/qualitycontrol/approvebatch/approvebatch.component';
import { ReleaseBatchComponent } from './options/forming/release/releasebatch/releasebatch.component';
import { ShipBatchComponent } from './options/forming/shipping/shipbatch/shipbatch.component';
import { BulkitemsviewComponent } from './components/bulkitemsview/bulkitemsview.component';
import {FinishedFormsComponent} from './finishedforms/finishedforms.component';
import {GlobalSearchComponent} from './options/forming/completedorders/globalsearch/globalsearch.component';

export function get_settings(appMapService: AppMapService) {
	return () => appMapService.getSettings();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
        HomeComponent,
        NotesComponent,
        LoginComponent,
        SettingsComponent,
        ConfirmDialogComponent,
        TransactionHistoryComponent,
        UsermanagementComponent,
        GroupmanagementComponent,
        GeneralsettingsComponent,
        AboutComponent,
        ProfileComponent,
        TurboTableKeysDirective,
        MatRowKeyboardSelectionDirective,
        DwStepperComponent,
        DragDropDirective,
        DwTimelineComponent,
        HomeRendererComponent,
        CardComponent,
        MetricCardComponent,
        GraphComponent,
        MessageBoxComponent,
        ForgotpasswordComponent,
        AlertDialogComponent,
        ReportsmainComponent,
        ReportRendererComponent,
        ReportRowComponent,
        ReportCellComponent,
        ReportParamsComponent,
        SelectuserComponent,
        EmailsettingsComponent,
        DwCalendar,
        DwSplitterComponent,
        SafeHTMLPipe,
        TransHistoryDetailComponent,
        SendInviteComponent,
        RegisterUserComponent,
        InventoryComponent,
        FormingComponent,
        InventoriesComponent,
        MedicationsInvComponent,
        MaterialsInvComponent,
        SuppliersInvComponent,
        SitesInvComponent,
        FormingComponent,
        MedicationPropertiesComponent,
        MedicationImagesComponent,
        MedicationLinksComponent,
        MedicationSuppliersComponent,
        AddressBookComponent,
        AddressesComponent,
        ContactsComponent,
        AddAddressComponent,
        AddContactsComponent,
        NewOrdersComponent,
        PreparationComponent,
        InProcessComponent,
        QualityControlComponent,
        ReleaseComponent,
        ShippingComponent,
        CompletedordersComponent,
        CustomerMasterComponent,
        AddeditlinkComponent,
        MedicationFamilyComponent,
        CustomerLicensesComponent,
        AddLicenseComponent,
        AddimageComponent,
        AddLicenseStateURLsComponent,
        AddStationComponent,
        AddeditmedicationsupplierComponent,
        FileSelectorComponent,
        StationsComponent,
        MaterialSuppliersComponent,
        AddeditmaterialsupplierComponent,
        AddCataloguesComponent,
        FileSelectorComponent,
        StationsComponent,
        CataloguesComponent,
        BulkUnitsComponent,
        BulkUnitsDetailComponent,
        BulkUnitGraphicsComponent,
        FormularyMainComponent,
        AddLabelComponent,
        ChangeStatusComponent,
        AddMaterialComponent,
        InventoryTransferComponent,
        ReturnManuComponent,
        BulkCheckInComponent,
        QuickAddSupplierComponent,
        ImgVerifyComponent,
        MedicationIngredientsComponent,
        AddeditmedicationingredientsComponent,
        QualityControlComponent,
        PurchaseOrdersComponent,
        AddPurchaseOrderComponent,
        SelectCreatePOComponent,
        AddMedPOItemComponent,
        AddMatPOItemComponent,
        SupplierlicesesComponent,
        AddSupplierlicenseComponent,
        AddOrderComponent,
        LookupItemComponent,
        AcceptOrderItemsComponent,
        BprComponent,
        PrepareBatchComponent,
        ChangeAssignmentsComponent,
        LabelGenComponent,
        FinishBatchComponent,
        ApproveBatchComponent,
        ReleaseBatchComponent,
        ShipBatchComponent,
        BulkitemsviewComponent,
        FinishedFormsComponent,
        GlobalSearchComponent
    ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MatSortModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		CustomMaterialModule,
		HttpClientModule,
		MatTabsModule,
		AppMaterialModule,
		DialogModule,
		TableModule,
		CheckboxModule,
		CalendarModule,
		DropdownModule,
		CdkStepperModule,
		ToggleButtonModule,
		InputNumberModule,
		OverlayPanelModule,
		ProgressSpinnerModule,
		AutoCompleteModule,
		ChartModule,
		RadioButtonModule,
		CardModule,
		PanelModule,
		ContextMenuModule,
		TooltipModule,
		NgxColorsModule,
		MessageModule,
		MessagesModule,
		ToastModule,
		TreeTableModule,
		CodeInputModule,
		ProgressBarModule,
		AngularSplitModule,
		InputMaskModule,
        NgxBarcodeModule,
        TextMaskModule
	],
	providers: [SidenavService, NavbarEventManager, DwUtils, AppMapComponent, DatePipe, AppMapService,
		[{ provide: LOCALE_ID, useValue: 'en-US' }],
		{ provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppMapService], multi: true, },
		{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: APP_BASE_HREF,
			useFactory: getBaseLocation
		}
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
