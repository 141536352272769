/**
 *  Filename ....: add-licensestateurl.component.ts
 *  Created by ..: ianday
 *  on Date .....: 3/10/23
 **/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerLicenses} from "../../../../../../models/customerlicenses";
import {LicenseStateURLs} from "../../../../../../models/licensestateurls";
import {CustomersService} from "../../../../../../services/customers.service";
import {Settings} from "../../../../../../services/settings";

@Component({
    selector: 'app-add-licensestateurl',
    templateUrl: './add-licensestateurl.component.html',
    styleUrls: ['./add-licensestateurl.component.css']
})

export class AddLicenseStateURLsComponent implements OnInit {

    public strErr = '';
    public licenseType = '';
    public practiceState = '';
    public licenseStateURL: LicenseStateURLs;
    public url = '';
    public customerService: CustomersService;

    @Output() cancelAddLicenseStateURL = new EventEmitter();
    @Output() submitAddLicenseStateURL = new EventEmitter<LicenseStateURLs>();

    constructor(csvc: CustomersService) {
        this.customerService = csvc;
    }
    afterDialogOpen(licenseStateURL: LicenseStateURLs): void
    {
        this.licenseStateURL = licenseStateURL;
        this.licenseType = this.licenseStateURL.licenseType;
        this.practiceState = this.licenseStateURL.practiceState;
        this.url = this.licenseStateURL.url;
    }
    ngOnInit() {

    }

    doSubmit(): void {

        // we should have all the other fields in the
        // licenseStateURL structure populated.
        this.licenseStateURL.url = this.url;

        // this is an add.
        if (this.licenseStateURL.id === 0) {
            this.customerService.createLicenseStateURL(this.licenseStateURL).subscribe(
                (data) => {
                    this.licenseStateURL.id = data.id;
                    this.submitAddLicenseStateURL.emit(this.licenseStateURL);
                },
                (error) => {
                    Settings.getInstance().handleError('Unable to save the license / state URL');

                });
        }
        else {
            // if we save a blank URL - that means we should remove the record.
            if (this.licenseStateURL.url.length === 0 ) {
                this.customerService.deleteLicenseStateURL(this.licenseStateURL.id).subscribe(
                    (result) => {
                        // nothing
                        this.submitAddLicenseStateURL.emit(this.licenseStateURL);
                    },
                    (error) => {
                        Settings.getInstance().handleError('Unable to remove the license / state URL');
                });
            }
            else {
                this.customerService.updateLicenseStateURL(this.licenseStateURL).subscribe(
                    (result) => {
                        // nothing
                        this.submitAddLicenseStateURL.emit(this.licenseStateURL);
                    },
                    (error) => {
                        Settings.getInstance().handleError('Unable to update the license / state URL');
                        this.submitAddLicenseStateURL.emit(this.licenseStateURL);
                    });
            }
        }
    }
    doCancel(): void {
        this.cancelAddLicenseStateURL.emit();
    }
}


