export interface Link {
	id: number;
	linkDescription: string | null;
	linkUrl: string | null;
	active: number | null;
	medicationId: number | null;
}

export class Link {

}
