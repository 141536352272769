<!--
    file type .....: HTML file: selectcreatepo.component.html
    Created By ....: ianday
    Date ..........: 5/18/23 
-->
<div class="selectcreatepo-container">

    <div class="dwform top-half">
        <div class="dwform-row">
            <div class="instructions-set">(1). Identify a supplier:</div>
            <label class="label-field" >Supplier:</label>
            <span class="form-required"> </span>
            <input class="input-field" id="supplierId" matInput
                   [formControl]="supplierControl"
                   [matAutocomplete]="autoSupplier"
                   style="text-transform: capitalize; width:250px;"
                   placeholder="<Supplier>" type="text">
            <mat-autocomplete autoActiveFirstOption
                              (optionSelected)="supplierSelectVal()"
                              class="supplier-auto-complete"
                              #autoSupplier="matAutocomplete" >
                <mat-option [ngClass]="'mat-option-class'"
                            *ngFor="let supplier of filteredSupplierOptions | async "
                            [value]="supplier">{{supplier}}
                </mat-option>
            </mat-autocomplete>

            <button mat-button style="width: 100px!important; scale: 80%;"
                    (click)="clearSuppliers()"
                    matTooltip="Clear Search"><mat-icon>highlight_off</mat-icon> Clear </button>

            <button mat-button style="width: 100px!important;scale: 80%;"
                    (click)="doShowQuickAddSupplier()"
                    matTooltip="Quick Add"><mat-icon>add_circle_outline</mat-icon> Add </button>
        </div>
        <div class="dwform-row-gap"></div>
        <mat-divider></mat-divider>
    </div>
    <div class="dwform bottom-half">
      <div class="dwform-row">
       <div class="instructions-set">(2). Choose a medication and then a supplier:</div>
        <label class="label-field">Medication:</label>
        <span class="form-required"> </span>
        <input class="input-field" id="medFamilyId" matInput
               [formControl]="mfControl"
               [matAutocomplete]="autoMedFamily"
               style="text-transform: capitalize; width:350px;"
               placeholder="<Medication>" type="text">
        <mat-autocomplete autoActiveFirstOption
                          (optionSelected)="medFamilySelectVal($event)"
                          class="supplier-auto-complete"
                          #autoMedFamily="matAutocomplete" >
            <mat-option [ngClass]="'mat-option-class'"
                        *ngFor="let mf of filteredMFOptions | async "
                        [value]="mf">{{mf}}
            </mat-option>
        </mat-autocomplete>

          <button mat-button style="width: 100px!important; scale: 80%;"
                  (click)="doClearMedication()"
                  matTooltip="Clear Medication Search"><mat-icon>highlight_off</mat-icon> Clear </button>
    </div>
      <div class="dwform-row-gap"></div>
      <div (keydown)="tableKeyDown($event)" tabindex="-1"
         class="med-supplier-list-container">

        <table mat-table #selectPO [dataSource]="dataSource" matSort
               style="width: 100%; border: .5px solid #e1e1e1"
               (matSortChange)="onSortChange($event)">

            <!-- COMPANY NAME -->
           <ng-container matColumnDef="companyName" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override" >Supplier
                </th>
                <td mat-cell
                    [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle;
                    text-align: left; width:220px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.companyName}}</td>
            </ng-container>

            <!-- Package-size --->
            <ng-container matColumnDef="packageSize" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override" >Pkg Size
                </th>
                <td mat-cell
                    [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle;
                    text-align: left; width:100px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.packageSize + ' '+ element.unitOfMeasure}}</td>
            </ng-container>

            <!-- Cost Base -->
            <ng-container matColumnDef="costBase" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override"
                    style="text-align:center" >Cost Base
                </th>
                <td mat-cell [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle; text-align: left;
                            width:100px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">$
                    {{element.costBase}}</td>
            </ng-container>
            <!-- Cost per Dose -->
            <ng-container matColumnDef="costPerDose" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override"
                    style="text-align:center" >Cost/Dose
                </th>
                <td mat-cell [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle; text-align: left;
                            width:100px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">$
                    {{calcCostPerDose(element.unitPrice, element.packageSize)}}</td>
            </ng-container>

            <!-- Unit Price -->

            <ng-container matColumnDef="unitPrice" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override"
                    style="text-align:left" >Unit Price
                </th>
                <td mat-cell [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle; text-align: left;
                            width:100px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">$
                    {{element.unitPrice}}</td>
            </ng-container>

            <!-- Lead Time In Days -->
            <ng-container matColumnDef="leadTimeDays" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override" style="text-align:center" >
                    Lead Time
                </th>
                <td mat-cell [hidden]="false" style="font-size: 9pt;
                    vertical-align: middle; text-align: left; width:95px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.leadTimeDays + ' day(s)'}}</td>
            </ng-container>

            <!-- Last Updated -->
            <ng-container matColumnDef="lastUpdated" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style mat-table-header-override"
                    style="text-align:center" >Last Updated
                </th>
                <td mat-cell
                    [hidden]="false"
                    style="font-size: 9pt; vertical-align: middle; text-align: center;
                            width:110px!important;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.lastUpdated | date: 'yyyy-MM-dd'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row style="border: .5px solid green"
                 *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                   [ngClass]="{'row-selected': tableRowIdx == Idx}"
                    (click)="onRowClicked(row)"
                    (dblclick)="onRowClicked(row);onSubmittedClicked()">
                </tr>
            </table>

        </div>
    </div>

    <div class="save-close-strip">
        <button mat-button class="command-button"
                disabled="{{submitDisabled}}"
                (click)="onSubmittedClicked()">
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()">
               <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
<p-dialog header="Quick Add New Supplier"
          [appendTo]="'body'"
          [(visible)]="quickAddDisplayDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '550px', height: '300px'}"
          [minY]="75" [resizable]="false" [closable]="true">
    <app-qasupplier #quickAddSupplier [supplierList]="suppliersList"
                    (cancelQASupplier)="quickAddDisplayDialog=false;"
                    (submittedQASupplier)="submitQuickAddSupplier($event)"></app-qasupplier>
</p-dialog>
