<div class="add-address-container" tabindex="0">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;">
           <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Ingredient:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" style="width: 280px"
                       [(ngModel)]="valIngredient"  #ingredient id="ingredient"           
                       placeholder="<ingredient>"
                       type="text">
            </div>
            
             <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Quantity:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest"  style="width: 100px"
                       [(ngModel)]="valQuantity"  #quantity id="quantity"           
                       placeholder="<quantity>"
                       min="0"
                       type="number">
            </div>
             <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Unit:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" style="width: 100px"
                       [(ngModel)]="valUnit"  #unit id="unit"           
                       placeholder="<unit>"
                       type="text">
            </div>
            
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Code:</label>
                <span class="form-required">*</span>
                 <mat-select placeholder="Select" style="width: 100px" 
					  		panelClass="local-drop-down-panel"
                            class="dw-mat-select" #code id="code"
                            [(ngModel)]="valCode">
						    <mat-option value="A" >A</mat-option>
						    <mat-option value="B" >B</mat-option>
						    <mat-option value="C" >C</mat-option>
						    <mat-option value="X" >X</mat-option>
						    <mat-option value="Y" >Y</mat-option>
						    <mat-option value="I" >I</mat-option>
				 </mat-select>
            </div>
            
            <div class="dwform-row-gap"></div>
           

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
