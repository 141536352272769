/************
 * Model Class
 * File Name ...: bprbulkitems.components.ts
 * Generated By : ianday on 7/7/23
 * Date ........: 7/7/23
 /************/
import {ValidationResult} from './validation-result';
import {BulkUnits} from './bulkunits';
import {DwUtils} from '../components/utils/dw-utils';

export interface BprBulkItems {
    id: number;
    bprId: number;
    bulkUnitId: number;
    icn: string;
    ordered: number;
    starting: number;
    used: number;
    discarded: number;
    returning: number;
    variance: number;
    varianceReason: string | null;
    varianceComments: string | null;
    itemPrefix: string;
    ndcNumber: string;
    description: string;
    expirationDate: string;
    lotNumber: string;
}

export class BprBulkItems {
    constructor() {
        this.id = 0;
        this.bprId = 0;
        this.bulkUnitId = 0;
        this.icn = '';
        this.ordered = 0;
        this.starting = 0;
        this.used = 0;
        this.discarded = 0;
        this.returning = 0;
        this.variance = 0;
        this.varianceReason = '';
        this.varianceComments = '';
        this.itemPrefix = '';
        this.ndcNumber = '';
        this.description = '';
        this.expirationDate = '';
        this.lotNumber = '';
    }

    setFromBulkUnits(bprId, bu: BulkUnits, description: string): void {
        this.id = 0;
        this.bprId = bprId;
        this.bulkUnitId = bu.id;
        this.icn = bu.icn;
        this.ordered = 0;
        this.used = 0;
        this.discarded = 0;
        this.returning = bu.remainingDoses;
        this.starting = bu.remainingDoses;
        this.variance = 0;
        this.varianceReason = '';
        this.varianceComments = '';
        this.ndcNumber = bu.ndcNumber;
        this.itemPrefix = bu.itemNumber;
        this.description = description;
        this.expirationDate = DwUtils.formatDateFromString(bu.expirationDate);
        this.lotNumber = bu.lotNumber;

    }
    setBulkItem(rec: BprBulkItems): void {
        this.id = rec.id;
        this.bprId = rec.bprId;
        this.bulkUnitId = rec.bulkUnitId;
        this.icn = rec.icn;
        this.ordered = Number(rec.ordered);
        this.used = Number(rec.used);
        this.discarded = Number(rec.discarded);
        this.returning = Number(rec.returning);
        this.variance = Number(rec.variance);
        this.varianceReason = rec.varianceReason;
        this.ndcNumber = rec.ndcNumber;
        this.itemPrefix = rec.itemPrefix;
        this.description = rec.description;
        this.expirationDate = rec.expirationDate;
        this.lotNumber = rec.lotNumber;

    }
    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();
        return vr;
    }
}
