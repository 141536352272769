import { Component, OnInit } from '@angular/core';
import { Settings } from '../../../services/settings';
import { GeneralSetting } from '../../../models/generalsetting';
import { UserService } from '../../../services/user.service';
import { GlobalService } from '../../../services/global-service';
import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

@Component({
	selector: 'app-emailsettings',
	templateUrl: './emailsettings.component.html',
	styleUrls: ['./emailsettings.component.css']
})
export class EmailsettingsComponent implements OnInit {

	formTitle = 'Email Settings';
	displaySavedMessage = false;
	valSMTPHost: any;
	valSMTPPort: any;
	valSMTPUsername: any;
	valSMTPPassword: any;
	smtpHostSetting: GeneralSetting;
	smtpPortSetting: GeneralSetting;
	smtpUsernameSetting: GeneralSetting;
	smtpPasswordSetting: GeneralSetting;
	strErr = '';
	disabledFlag = false;
	loading = false;
	strMessage = '';
	editMode = false;
	constructor(private userService: UserService, private globalService: GlobalService) { }
	ngOnInit() {
		this.queryRecords();
	}
	queryRecords() {
		this.smtpHostSetting = Settings.getInstance().getGeneralSettingByKey('SMTPHost');
		this.smtpPortSetting = Settings.getInstance().getGeneralSettingByKey('SMTPPort');
		this.smtpUsernameSetting = Settings.getInstance().getGeneralSettingByKey('SMTPUsername');
		this.smtpPasswordSetting = Settings.getInstance().getGeneralSettingByKey('SMTPPassword');
		if (this.smtpHostSetting === undefined || this.smtpHostSetting === null) {
			this.smtpHostSetting = new GeneralSetting();
		}
		if (this.smtpPortSetting === undefined || this.smtpPortSetting === null) {
			this.smtpPortSetting = new GeneralSetting();
		}
		if (this.smtpUsernameSetting === undefined || this.smtpUsernameSetting === null) {
			this.smtpUsernameSetting = new GeneralSetting();
		}
		if (this.smtpPasswordSetting === undefined || this.smtpPasswordSetting === null) {
			this.smtpPasswordSetting = new GeneralSetting();
		}
		this.valSMTPHost = this.smtpHostSetting.value;
		this.valSMTPPort = this.smtpPortSetting.value;
		this.valSMTPUsername = this.smtpUsernameSetting.value;
		this.valSMTPPassword = this.smtpPasswordSetting.value;
	}
	doTestConnection() {
		this.disabledFlag = true;
		this.loading = true;
		this.strErr = '';
		this.strMessage = '';
		this.userService.testEmailConnection(this.valSMTPHost, this.valSMTPPort, this.valSMTPUsername, this.valSMTPPassword, true).subscribe(
			(data) => {
				if (data === true) {
					Settings.getInstance().handleError('Connection Success!');
					this.disabledFlag = false;
					this.loading = false;
				} else {
					Settings.getInstance().handleError('Connection Failed!');
					this.disabledFlag = false;
					this.loading = false;
				}
			},
			(error) => {
				Settings.getInstance().handleError('Connection Failed!');
				this.disabledFlag = false;
				this.loading = false;
			}
		);
	}
	validateRecord(): boolean {
		this.strErr = '';


		if (this.valSMTPHost === undefined || this.valSMTPHost === '' || this.valSMTPHost === null) {
			this.strErr = 'Error: SMTP Host name required';
			return false;
		}
		if (this.valSMTPPort === undefined || this.valSMTPPort === '' || this.valSMTPPort === null) {
			this.strErr = 'Error: SMTP Port required';
			return false;
		}
		if (this.valSMTPUsername === undefined || this.valSMTPUsername === '' || this.valSMTPUsername === null) {
			this.strErr = 'Error: User name required';
			return false;
		}
		if (this.valSMTPPassword === undefined || this.valSMTPPassword === '' || this.valSMTPPassword === null) {
			this.strErr = 'Error: Password required';
			return false;
		}

		return true;
	}
	doSubmit() {
		this.strErr = '';
		this.strMessage = '';
		if (!this.validateRecord()) {
			return;
		}
		this.disabledFlag = true;
		this.smtpHostSetting.key = 'SMTPHost';
		this.smtpHostSetting.value = this.valSMTPHost;
		this.userService.createUpdateSetting(this.smtpHostSetting).subscribe(
			(data1) => {
				this.smtpPortSetting.key = 'SMTPPort';
				this.smtpPortSetting.value = this.valSMTPPort;
				this.userService.createUpdateSetting(this.smtpPortSetting).subscribe(
					(data2) => {
						this.smtpUsernameSetting.key = 'SMTPUsername';
						this.smtpUsernameSetting.value = this.valSMTPUsername.toString();
						this.userService.createUpdateSetting(this.smtpUsernameSetting).subscribe(
							(data3) => {
								this.smtpPasswordSetting.key = 'SMTPPassword';
								this.smtpPasswordSetting.value = this.valSMTPPassword.toString();
								this.userService.createUpdateSetting(this.smtpPasswordSetting).subscribe(
									(data4) => {
										this.globalService.globalEvent.next('SETTINGS_REFRESH');
										this.strMessage = 'Email settings updated successfully!';
										this.disabledFlag = false;
										this.editMode = false;
									},
									(error4) => {
										this.disabledFlag = false;
									}
								);
							},
							(error3) => {
								this.disabledFlag = false;
							}
						);
					},
					(error2) => {
						this.disabledFlag = false;
					}
				);
			},
			(error1) => {
				this.disabledFlag = false;
			}
		);

	}
	doCancel() {
		this.queryRecords();
		this.editMode = false;
	}
	enterEditMode() {
		this.editMode = true;
		this.strMessage = '';
	}
}
