import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams,HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OStore,  OStoreStart, ObsValueReset, KeyObsValueReset } from '@fireflysemantics/slice';
import { Observable } from 'rxjs';
import { User } from '../../models/user';
import { UserService } from '../user.service';
import { Settings } from '../settings';
import { GeneralService } from '../../services/general.services';

/** The user key */
export const USER_KEY = 'USER_KEY';
export const AUTHENTICATION_ERROR_KEY = 'AUTHENTICATION_ERROR_KEY';
export const AUTHENTICATION_ERROR_MESSAGE = 'Invalid username or password';

export interface ISTART extends KeyObsValueReset {
	AUTHENTICATION_ERROR_KEY: ObsValueReset;
	USER_KEY: ObsValueReset;
}

/** Auth Service */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
	
  START: OStoreStart = {
	AUTHENTICATION_ERROR_KEY: { value: null, reset: null },
	USER_KEY: { value: null, reset: null },
  };

  //CREATE THE OBJECT STORE
  public ostore = new OStore(this.START);

  //REACTIVE STATE OBSERVABLES
  public loggedInUser: User;
  public user$: Observable<User>;
  public isAuthenticated$: Observable<boolean>;
  public authenticationError$: Observable<string | boolean>;
  public authorizePromise: Promise<boolean>;
  public sessionLoadAttempted = false;
  private isSettingsLoaded: boolean = false;

  private http: HttpClient;

  constructor(private router: Router, private userService: UserService,  private generalService: GeneralService, private route: ActivatedRoute, httpBackend: HttpBackend) {
		this.http = new HttpClient(httpBackend);																																	
		this.loadConfig();
		this.initializeStore();
	}
  async loadConfig() {
	  	const data = await this.http.get('./config/config.xml', { responseType: 'text' }).toPromise()
		if (data) {
			const parser = new DOMParser();
			const xmlData = parser.parseFromString(data, 'application/xml');
			Settings.getInstance().BASE_URL = xmlData.children[0].getElementsByTagName('BASE_URL')[0].textContent;
			if(Settings.getInstance().BASE_URL.indexOf('{HOST_NAME}') != -1) {
				Settings.getInstance().BASE_URL = Settings.getInstance().BASE_URL.replace('{HOST_NAME}', window.location.hostname); 
			}
			Settings.getInstance().version = xmlData.children[0].getElementsByTagName('Version')[0].textContent;
			Settings.getInstance().release = xmlData.children[0].getElementsByTagName('Release')[0].textContent;
			Settings.getInstance().lastUpdated = xmlData.children[0].getElementsByTagName('LastUpdated')[0].textContent;
			try {
				const enableInvestments: string = xmlData.children[0].getElementsByTagName('EnableInvestments')[0].textContent;
				if (enableInvestments != null && (enableInvestments.toLowerCase() === 'true' || enableInvestments.toLowerCase() === 'yes')) {
					Settings.getInstance().EnableInvestments = true;
				} else {
					Settings.getInstance().EnableInvestments = false;
				}
			} catch (err) {
				Settings.getInstance().EnableInvestments = false;
			}
			try {
				const CurrencySymbol: string = xmlData.children[0].getElementsByTagName('CurrencySymbol')[0].textContent;
				if (CurrencySymbol != null) {
					Settings.getInstance().CURRENCY_SYMBOL = CurrencySymbol;
				} else {
					Settings.getInstance().CURRENCY_SYMBOL = '';
				}
			} catch (err) {
				Settings.getInstance().CURRENCY_SYMBOL = '';
			}			
		}
		this.isSettingsLoaded = true;
			//.subscribe(data => {
				
			//});
  }
  
  initializeStore() {
      //INITIALIZE OBJECT STORE STATE
      this.ostore.post(USER_KEY, null);
      this.ostore.post(AUTHENTICATION_ERROR_KEY, false);
      //INITIALIZE THE isAuthenticated$ State
      this.isAuthenticated$ = this.ostore.observe(USER_KEY).pipe(map(u => !!u));
      this.authenticationError$ = this.ostore.observe(AUTHENTICATION_ERROR_KEY);
      this.user$ = this.ostore.observe(USER_KEY);
      this.authorizePromise = null;
  }

  authorize() {
		if (!this.authorizePromise) {
			console.log("Initializing authorizePromize");
      		this.authorizePromise =  this.userService.getLoggerInUser().toPromise().then(result => {
				if(result['data']===undefined || result['data'].user===undefined){
					return false;
				}
				this.sessionLoadAttempted = true;
				this.loggedInUser = result['data'].user;
				this.loggedInUser.app_functions = result['data'].app_functions;
				Settings.getInstance().loggedInUser = this.loggedInUser;
				this.ostore.put(USER_KEY, result['data'].user);
				this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
				//this.router.navigate(['/projects']);
					
				this.userService.getAllUsers().subscribe(
				        	(data4) => {
				          		Settings.getInstance().usersList = data4;
				       		 },
				        	(error4) => {
				        	}
					     );
			
				return true;
			})
			.catch(() => {					
					this.sessionLoadAttempted = true;
	                this.ostore.put(USER_KEY, null);                    
	                this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
					return false;
				});
		}
		return this.authorizePromise;
  }
  
  delay(ms: number) {
	    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  getLoggedinUser() {
		this.userService.getLoggerInUser().subscribe(
			result => {
				this.sessionLoadAttempted = true;
				this.loggedInUser = result['data'].user;
				this.loggedInUser.app_functions = result['data'].app_functions;
				Settings.getInstance().loggedInUser = this.loggedInUser;
				this.ostore.put(USER_KEY, this.loggedInUser);
				this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
				//this.router.navigate(['/projects']);
					
				this.userService.getAllUsers().subscribe(
				        	(data4) => {
				          		Settings.getInstance().usersList = data4;
				       		 },
				        	(error4) => {
				        	}
					     );
			
  			
            },
            (error) => {                    
                this.sessionLoadAttempted = true;
                this.ostore.put(USER_KEY, null);                    
                this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
                this.router.navigate(['/login']);
            }
      );
  }

  login(username: string, password: string) {
	this.authorizePromise = null;
    this.authenticate(username, password).subscribe(
      data => {
        this.loggedInUser = data.data;
        this.ostore.put(USER_KEY, data);
        this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
        Settings.getInstance().jwtToken = data.data.token
        this.router.navigate(['/']);
      },
      (error) => {
		  this.ostore.put(AUTHENTICATION_ERROR_KEY, error.error['message']);
      });
  }
  /*** Bypass the login mechanism ***/
  login_static(username: string, password: string) {
    this.loggedInUser = new User();
    this.loggedInUser.username = 'iday';
    this.loggedInUser.name = 'Ian Day';
    this.ostore.put(USER_KEY, this.loggedInUser);
    this.ostore.put(AUTHENTICATION_ERROR_KEY, false);
    Settings.getInstance().loggedInUser = this.loggedInUser;

    this.router.navigate(['/']);
  }
  logout() {
    this.ostore.put(USER_KEY, null);
    this.router.navigate(['/login']);
    Settings.getInstance().user = {id: -1, username: '', password: '', name: '', email:'a@b.com', status: 1, appFunctionGroups:[], appFunctions:[], displayStatus: '', lastLogin: '', organization_id: 0, invitation_count: 0, last_invitation_date:'', invitation_status: 0, invitation_token: '', dashboard_settings: '', app_functions: []};
    this.loggedInUser = null;
	this.authorizePromise = null;
  }

  private authenticate(username: string, password: string) {
    const user = new User();
    user.username = username;
    user.password = password;
    Settings.getInstance().user = user;
    return this.userService.login(username, password);
  }
  
  
}
