<!--
    file type .....: HTML file: bulkunitsdetail.component.html
    Created By ....: ianday
    Date ..........: 4/12/23 
-->
<div class="bulkunitsdetail-container">
   <table style="width:100%">
       <tr>
           <td style="width: 50%; font-weight: bold; font-size: 14px;">
               <span style="color: blue;">{{itemPrefix}}</span>&nbsp;<span>{{itemHandle}}</span>
           </td>
           <td style="width:50%; text-align:right;">
               <button mat-button style="width: 30px;"
                       matTooltip="Fetch the next Record"
                       (click)="attemptFetchNextRecord()">
                   <mat-icon>keyboard_arrow_down</mat-icon>
                   </button>
                &nbsp;
               <button mat-button style="width: 30px; "
                   matTooltip="Fetch the previous Record"
                   (click)="attemptFetchPreviousRecord()">
                   <mat-icon>keyboard_arrow_up</mat-icon>
                   </button>
           </td>
       </tr>
   </table>

    <table style="width:100%" >
        <tr >
            <td style="width:30%;">
            <div class="dwform" style="padding-right: 15px; font-size: 12px;" tabindex="-1">

                <div class="dwform-cell-nopadding"
                     style="min-width: 200px;" tabindex="-1">
                    <!-- ID -->
                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">ID:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.id}}</label>
                    </div>

                    <!-- Item Number -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Item Number:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.itemNumber}}</label>
                    </div>

                    <!-- Location -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Location:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.location}}</label>
                    </div>

                    <!-- Serial Number -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Serial No:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.serialNumber}}</label>
                    </div>

                    <!-- GTIN Number -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">GTIN:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.gtin}}</label>
                    </div>

                    <!-- ICN Number -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">ICN:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.icn}}</label>
                    </div>

                    <!-- lot Number -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Lot No:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.lotNumber}}</label>
                    </div>

                    <!-- Expiration Date -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Expiration Date:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{labelFmtDate(selectedRecord?.expirationDate)}}</label>
                    </div>

                    <!-- Orig Doses -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Original {{dosesPieces}}:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.originalDoses}}</label>
                    </div>

                    <!-- Defective Doses -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Defective {{dosesPieces}}:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.defectiveDoses}}</label>
                    </div>

                    <!-- Remaining Doses -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Remaining {{dosesPieces}}:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">{{selectedRecord?.remainingDoses}}</label>
                    </div>

                    <!-- Cost base  -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Cost Base:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">${{selectedRecord?.costBase}}</label>
                    </div>

                    <!-- Unit cost  -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">Unit Cost:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">${{selectedRecord?.unitCost}}</label>
                    </div>
                    <!-- AWP   -->

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row">
                        <label class="label-field">AWP:</label>
                        <span class="form-required"></span>
                        <label class="label-field-data">${{selectedRecord?.awp}}</label>
                    </div>
                </div>
            </div>
        </td>
        <td style="vertical-align:top; width:70%;background-color: white;">
            <mat-tab-group (selectedIndexChange)="onSelectedTabChange($event)"
                           style="height: 100%">
                <mat-tab label="Details" >
                    <app-bulkunitgraphics #graphics >

                    </app-bulkunitgraphics>
                </mat-tab>
                <mat-tab label="History">
                    <div style="background-color: #e1e1e1;height: 420px;">
                         <transaction-history #history></transaction-history>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </td>
        </tr>
    </table>
    <!----------------------->
    <!-- SAVE STRIP        -->
    <!----------------------->
    <div class="save-close-strip" style="bottom: 10px;">

        <button mat-button class="command-button"
                (click)="doClose()" matTooltip="Close the detail window" >
            <mat-icon>highlight_off</mat-icon>
            Close
        </button>
    </div>
</div>
