/************
 * Model Class
 * File Name ...: forming-qbe.components.ts
 * Generated By : ianday on 8/28/23
 * Date ........: 8/28/23
 /************/
import {ValidationResult} from './validation-result';

export interface FormingQBE {
    customerNameParam: string | null;
    medicationParam: string | null;
    orderParam: string | null;
    dateFromParam: string | null;
    dateToParam: string | null;
    itemStatusParam: string | null;
}
export class FormingQBE {
    constructor() {
     this.customerNameParam = '';
     this.medicationParam = '';
     this.orderParam = '';
     this.dateFromParam = '';
     this.dateToParam = '';
     this.itemStatusParam = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();
        let hasValue = 0;

        if (this.customerNameParam.length > 0) { hasValue = 1; }
        if (this.medicationParam.length > 0) { hasValue = 1; }
        if (this.orderParam.length > 0) { hasValue = 1; }
        if (this.dateFromParam.length > 0) { hasValue = 1; }
        if (this.dateToParam.length > 0) { hasValue = 1; }
        if (this.itemStatusParam !== 'ALL' ) { hasValue = 1; }

        if (hasValue < 1) {
            vr.focus_field = 'customerNameParamId';                              /* this should be the id value of the field */
            vr.error = 'Error: at least one item must be specified';
            return vr;
        }

        return vr;
    }
}
