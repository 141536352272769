<!--
    file type .....: HTML file: bulkcheckin.component.html
    Created By ....: ianday
    Date ..........: 4/20/23 
-->
<div class="bulkcheckin-container">
    <table style="width:100%; ">
        <tr>
            <td class="field-container">
                <div class="dwform" style="font-size: 12px;"
                     tabindex="-1">

                    <div class="dwform-row-gap"></div>

                    <!-- Supplier Auto Field -->
                    <div class="dwform-row">
                        <label class="label-field">Supplier:</label>
                        <span class="form-required">*</span>
                        <input class="input-field" id="supplierId" matInput
                               [formControl]="supplierControl"
                               [matAutocomplete]="autoSupplier"
                               style="text-transform: capitalize; width:250px;"
                               placeholder="<Supplier>" type="text" (blur)="onSupplierBlur()">
                        <mat-autocomplete autoActiveFirstOption
                              (optionSelected)="supplierSelectVal($event)"
                              class="supplier-auto-complete"
                              #autoSupplier="matAutocomplete" >
                            <mat-option [ngClass]="'mat-option-class'"
                               *ngFor="let supplier of filteredSupplierOptions | async "
                              [value]="supplier">{{supplier}}
                            </mat-option>
                        </mat-autocomplete>


                        <button mat-button style="width: 20px!important;scale: 80%;"
                                (click)="doClearSupplier()"
                                matTooltip="Clear entry"><mat-icon>highlight_off</mat-icon>
                        </button>

                        <button mat-button style="width: 20px!important;scale: 80%;"
                                (click)="doShowQuickAddSupplier()"
                                matTooltip="Quick add"><mat-icon>add_circle_outline</mat-icon>
                        </button>
                    </div>

                    <div class="dwform-row-gap"></div>
                    
                     <div class="dwform-row">
                        <label class="label-field">Purchase Order:</label>
                        <span class="form-required"></span>
                        <mat-select disableOptionCentering id="cbPurchaseOrder"
							[(ngModel)]="selectedPO" #cbPurchaseOrder floatLabel="never"
							style="font-size: 10pt;width: 130px;"
							panelClass="local-drop-down-panel" class="dw-mat-select .mat-input-element"
							(selectionChange)="puchaseOrderSelecttionHandler()">
							<mat-option *ngFor="let item of poList"
							[value]="item">{{item.poNumber}}</mat-option>
						</mat-select>       
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Invoice Number:</label>
                        <span class="form-required">*</span>
                        <input class="input-field-short" maxlength="10" id="invoiceNumber"
                               [formControl]="invoiceNumberControl"
                               placeholder="<Invoice Number>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Invoice Date: </label>
                        <span class="form-required">*</span>
                        <p-inputMask [style]="{'width' : '130px'}"
                                     mask="9999-99-99"
                                     id="invoiceDate"
                                     [formControl]="invoiceDateControl"
                                     placeholder="<YYYY-MM-DD>"
                                     type="text">
                        </p-inputMask>
                    </div>

                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row-gap"></div>
                    <mat-divider></mat-divider>
                    <div class="dwform-row-gap"></div>
                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Scan Code:</label>
                        <span class="form-required"></span>
                        <input class="input-field" style="width: 250px;" maxlength="60" id="scanCode"
                               (change)="doScanCode()"
                               [formControl]="scanCodeControl"
                               placeholder="<2D Bar Code>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Serial Number:</label>
                        <span class="form-required">*</span>
                        <input class="input-field" style="width: 250px;" maxlength="60" id="serialNumber"
                               (change)="doSerialNumberChange()"
                               [formControl]="serialNumberControl"
                               placeholder="<Serial Number>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">{{returnNDCLabel()}}:</label>
                        <span class="form-required">*</span>
                        <input class="input-field-short" maxlength="16" id="ndcNumber"
                               (change)="doNDCChange(true)"
                               [formControl]="ndcNumberControl"
                               placeholder="<NDC/Part Number>"
                               type="text">
                        <!-- image verification -->
                        <div style="height: 40px; width:40px;display: inline;
                        vertical-align: middle;">
                            <img [src]="verifyStateIcon" style="width: 24px;"/>
                        </div>

                        <span style="display: inline-block;width: 100px!important;"></span>
                        <button mat-button (click)="doImageVerify(false)" style="scale: 80%" tabindex="-1"
                                [disabled]="(ndcNumberControl.value.length === 0)"
                                matTooltipPosition="above"
                                matTooltip="Verify Images">
                            <mat-icon >image</mat-icon>
                        </button>

                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Item Number:</label>
                        <span class="form-required"> </span>
                        <label class="label-field-data" style="font-weight: bold"> {{itemNumber}}</label>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Lot Number:</label>
                        <span class="form-required"></span>
                        <input class="input-field-short" maxlength="15" id="lotNumber"
                               [formControl]="lotNumberControl"
                               placeholder="<Lot Number>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap" *ngIf="medMaterial === 'Medications'"></div>

                    <div class="dwform-row" *ngIf="medMaterial === 'Medications'">
                        <label class="label-field">Expiration Date:</label>
                        <span class="form-required">*</span>
                        <p-inputMask [style]="{'width' : '130px'}" #expDate
                                     mask="9999-99-99"
                                     id="expirationDate"
                                     (change)="doExpirationDateCheck()"
                                     (focusout)="doExpirationDateCheck()"
                                     [formControl]="expirationDateControl"
                                     placeholder="<YYYY-MM-DD>"
                                     type="text">
                        </p-inputMask>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Inventory Location:</label>
                        <span class="form-required"></span>
                        <input class="input-field-short" maxlength="8"
                               style="text-transform: uppercase"
                               (focusout)="doInvLocFocusOut($event)"
                               id="location"
                               [formControl]="locationControl"
                               placeholder="<Location>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row" >
                        <label class="label-field">Inv Control Number:</label>
                        <span class="form-required"></span>
                        <input class="input-field-long icn-style" style="width: 250px;"
                                   tabindex="-1"
                                   [(ngModel)]="icn"
                                   [readOnly]="true"
                                   maxlength="8"
                                   id="icn" type="text">
                            <!-- barcode goes here --->

                        <span style="width: 100px; height:35px"></span>
                        <button mat-button (click)="doShowICNBarCode()" style="scale: 80%"
                                [disabled]="! (icn?.length > 0)"
                                matTooltipPosition="above"
                                matTooltip="Show ICN barcode label">
                                <mat-icon>pageview</mat-icon>
                        </button>

                    </div>
                </div>

                <!----------------------->
                <!--- Add Clear Strip --->
                <!----------------------->
                <div class="error-line" *ngIf="strErr.length > 0">
                {{strErr}}
                </div>
                <div class="add-clear-strip">
                    <button mat-button class="command-button"
                            (click)="doClearEntry()"
                            matTooltipPosition="above"
                            matTooltip="Clear the current entry" mat-button>
                        <mat-icon>clear_all_outline</mat-icon>
                        Clear
                    </button>

                    <button mat-button class="command-button" [disabled]="disableAddItemButton()"
                            (click)="doAddNewEntry()"
                            matTooltipPosition="above"
                            matTooltip="Submit item for check-in."
                            mat-button>
                        <mat-icon>add_circle_outline</mat-icon>
                        Add
                    </button>

                </div>
            </td>
            <td class="list-container">
                <span style="font-weight: bold; font-size: 11pt;padding-bottom:8px;">Pending Items</span>
                <!------------------->
                <!-- Staging table -->
                <!------------------->
                <div (keydown)="tableKeyDown($event)" tabindex="-1"
                     class="checkin-list-container">

                    <table mat-table #checkInTable [dataSource]="dataSource"
                           class="table-pending-class">

                        <!-- Inventory Control Number -->
                        <ng-container matColumnDef="icn">
                            <th mat-header-cell *matHeaderCellDef
                                class="mat-table-header-style">ICN
                            </th>
                            <td mat-cell [hidden]="false"
                                style="font-size: 9pt; vertical-align: middle; text-align: left;
                                width: 80px!important;"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.icn}}</td>
                        </ng-container>

                        <!-- Inventory Location -->
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef
                                class="mat-table-header-style">Location
                            </th>
                            <td mat-cell
                                [hidden]="false"
                                style="font-size: 9pt; vertical-align: middle;
                                text-align: left; width: 20px!important;"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.location}}</td>
                        </ng-container>

                        <!-- NDC Number -->
                        <ng-container matColumnDef="ndcNumber">
                            <th mat-header-cell *matHeaderCellDef
                                class="mat-table-header-style">{{returnNDCLabel()}}
                            </th>
                            <td mat-cell
                                [hidden]="false"
                                style="font-size: 9pt; vertical-align: middle; text-align: left; width:20px!important;"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.ndcNumber}}</td>
                        </ng-container>

                        <!-- Expiration Date -->
                        <ng-container matColumnDef="expirationDate" >
                            <th mat-header-cell *matHeaderCellDef
                                class="mat-table-header-style" >Expiry Date
                            </th>
                            <td mat-cell
                                [hidden]="false"
                                style="font-size: 9pt; vertical-align: middle; text-align: center;
                                width:20px!important;"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.expirationDate}}</td>
                        </ng-container>
                        
                         <!-- PO Item -->
                        <ng-container matColumnDef="poItem" >
                            <th mat-header-cell *matHeaderCellDef [hidden]="!selectedPO"
                                class="mat-table-header-style" >PO Item
                            </th>
                           <td mat-cell
						        [hidden]="!selectedPO"
						        style="font-size: 9pt; vertical-align: middle; text-align: left;
						        width:60px!important;"
						        *matCellDef="let element;let tableRowIdx = index;">
						        {{ getPOItemDescriptionByNDC(element.ndcNumber) }}
						    </td>
                        </ng-container>

                        <!-- Delete -->
                        <ng-container matColumnDef="delete" >
                            <th mat-header-cell *matHeaderCellDef style="width: 5px;"
                                class="mat-table-header-style"> </th>
                            <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
                                class="button-cells-edit-delete" style="width:5px; text-align:right;">
                                 <span> <img class="edit-delete-button" matTooltip="Click to remove" alt=""
                                   src="../../../../assets/delete-icon.png"
                                   (click)="deleteRecord(tableRowIdx)">
                                </span></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row style="border: .5px solid green"
                            *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                            [ngClass]="{'row-selected': tableRowIdx == Idx}"
                            (click)="onRowClicked(row)">
                        </tr>
                    </table>
                </div>

                <!-- end Staging table -->

                <div class="add-clear-strip" >

                    <button mat-button class="command-button"
                            (click)="cancelSubmitRecord()"
                            matTooltipPosition="above"
                            matTooltip="Cancel the check in process" mat-button>
                        <mat-icon>highlight_off</mat-icon>
                        Cancel
                    </button>

                    <button mat-button class="command-button" [disabled]="!enabledCheckinButton()"
                            (click)="doStartCheckin()"
                            matTooltipPosition="above"
                            matTooltip="Submit items for check in"
                            mat-button>
                        <mat-icon>check_circle_outline</mat-icon>
                        Check In
                    </button>
                </div>
            </td>
        </tr>
    </table>
</div>

<p-dialog header="Quick Add New Supplier"
          [appendTo]="'body'"
          [(visible)]="quickAddDisplayDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '550px', height: '300px'}"
          [minY]="75" [resizable]="false" [closable]="true">
    <app-qasupplier #quickAddSupplier [supplierList]="suppliersList"
          (cancelQASupplier)="quickAddDisplayDialog=false;"
          (submittedQASupplier)="submitQuickAddSupplier($event)"></app-qasupplier>
</p-dialog>

<p-dialog header="Image Verification"
          [appendTo]="'body'"
          [(visible)]="imgVerificationDisplayDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '375px', height: '400px'}"
          [minY]="75" [resizable]="false" [closable]="false">
    <app-imgverify #imgVerify
          (cancelImgVerify)="imgVerificationDisplayDialog=false;"
          (submittedImgVerify)="submitImgVerify($event)"></app-imgverify>
</p-dialog>

<p-dialog header="{{icn}}" id="barcodeDlgId"
          [appendTo]="'body'"
          [showHeader]="false"
          [(visible)]="displayBarCodeDlg"
          [modal]="true" [responsive]="true"
          (onShow)="doPrintICNBarCode()"
          (close)="displayBarCodeDlg=false"
          [style]="{position: 'absolute', width: '300px', height: '175px',
          'box-shadow': 'none', border: 'none'}"
          [minY]="75" [resizable]="false" [closable]="true">
    <div style="width:100%; text-align:center; overflow: hidden" tabindex="-1">
        <table style="width:100%; text-align: center" tabindex="-1">
            <TR>
                <TD style="width: 50%; text-align:left">Date: <b>{{invoiceDateControl.value}}</b> </TD>
                <TD style="width:50%; text-align:right; padding-right:3px;">Loc: <b>{{locationControl.value}}</b></TD>
            </TR>
        </table>
        <ngx-barcode [bc-value]="icn" [bc-display-value]="true" id="barcodeId"
                     [bc-height]="40" tabindex="-1">
        </ngx-barcode>
        <button mat-button tabindex="-1"
                (click)="doPrintICNBarCode()"
                style="scale: 80%">
            <mat-icon>print</mat-icon>
        </button>
    </div>
</p-dialog>


