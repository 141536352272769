<div tabindex="0">
    <div class="dwform" style="padding-right: 15px;">
        <div class="dwform-cell-nopadding" style="min-width: 500px;">
 			<div class="dwform-row-gap"></div>
            <div class="dwform-row">
               <label class="label-field">Material:</label> <span></span>
							<mat-select  [(ngModel)]="valMaterial"
								id="materials" placeholder="- Please Select -"
								disableOptionCentering floatLabel="never"
								panelClass="local-drop-down-panel" class="dw-mat-select"
								style="width: 200px;font-size: 10pt;margin-left:8px;"
								(selectionChange)="fetchDetails()"
								[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
								 <mat-option *ngFor="let item of _materialsList" [value]="item" [matTooltip]="item.description">
	                				{{item.handle}} 
	              				 </mat-option>   
              				</mat-select>
            </div>
            <div class="dwform-row-gap"></div>
            <div class="dwform-row-gap"></div>
            <div class="dwform-row-gap"></div>
             <div class="dwform-row">
               <label class="label-field">Description:</label> <span></span>
							<label style="font-weight: bold">{{this.valDescription}}</label>
            </div>
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
               <label class="label-field">Item Type:</label> <span></span>
							<label style="font-weight: bold">{{this.valItemType}}</label>
            </div>
            
            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
