import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Material } from '../models/material';
import { Settings } from './settings';
import { SupplierMaterial } from '../models/supplier-material';
import {SupplierMedication} from '../models/supplier-medication';


@Injectable({
	providedIn: 'root'
})
export class MaterialService {

	constructor(private http: HttpClient) { }
	
	getAllMaterials(): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'materials/materials')
			.pipe(
				retry(1)
			);
	}
	getMaterialByPartNumber(partNumber:string): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'materials/materials/part-number/' + partNumber)
			.pipe(
				retry(1)
			);
	}
	createMaterial(record: Material): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'materials/materials', record)
			.pipe(
				retry(1)
			);
	}
	updateMaterial(record: Material): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'materials/materials/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteMaterial(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'materials/' + id)
			.pipe(
				retry(1)
			);
	}
	
	getMaterialSuppliersByMaterialId(materialId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'materials/supplier-material-by-material/' + materialId)
			.pipe(
				retry(1)
			);
	}
	getSupplierMaterialsBySupplierIdShort(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'materials/supplier-material-by-supplier-short/' + id)
			.pipe(
				retry(1)
			);
	}
	createMaterialSupplier(record: SupplierMaterial): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'materials/supplier-material', record)
			.pipe(
				retry(1)
			);
	}
	updateMaterialSupplier(record: SupplierMaterial): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'materials/supplier-material/'+ record.id, record)
			.pipe(
				retry(1)
			);
	}
	updateMaterialonSupplierByFK(record: SupplierMaterial): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'materials/supplier-material/FK/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	updateMaterialCostBase(id: number, costBase: number): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'materials/materials/costBase/' + id + '/' + costBase, null)
			.pipe(
				retry(1)
			);
	}
	deleteMaterialSupplier(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'materials/supplier-material/' + id)
			.pipe(
				retry(1)
			);
	}

	
}
