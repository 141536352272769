import { Component, OnInit, Input, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { MaterialService } from 'src/app/services/material.service';
import { Settings } from 'src/app/services/settings';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SupplierMaterial } from 'src/app/models/supplier-material';


@Component({
	selector: 'app-material-suppliers',
	templateUrl: './material-suppliers.component.html',
	styleUrls: ['./material-suppliers.component.css']
})
export class MaterialSuppliersComponent {

	suppliersList: any[];
	openSuppliersList: any[];
	materialSuppliers: SupplierMaterial[];
	displayedColumns: string[];
	displayedColumnsEditable: string[];
	selectedRecord: SupplierMaterial;
	Idx = -1;

	isEditing = false;
	_material: any;
	selectedSupplierMaterial: SupplierMaterial;
	pagination: string;

	renderedData = Array<SupplierMaterial>();
	dataSource: MatTableDataSource<SupplierMaterial>;

	BASE_URL = Settings.getInstance().BASE_URL;
	isAdd = false;
	isEdit = true;
	displayAddEditSupplier = false;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('table', { static: false }) table;

	get Material(): any {
		return this._material;
	}
	@Input() set Material(value: any) {
		this._material = value;
		this.queryRecords();
	}

	constructor(private materialService: MaterialService, private suppliersService: SuppliersService, private dialog: MatDialog) {
		this.displayedColumns = ['supplierName', 'costPerPiece', 'lastUpdated', 'delete'];
	}

	queryRecords() {
		if (this._material === undefined || this._material.id === undefined) {
			return;
		}
		this.suppliersService.getAllSuppliers().subscribe(
			(result) => {
				this.suppliersList = result;
				this.materialService.getMaterialSuppliersByMaterialId(this._material.id).subscribe(
					(result) => {
						if (result === undefined || result.length === undefined) {
							return;
						}
						this.materialSuppliers = result;
						for (const rec of this.materialSuppliers) {
							for (const supplier of this.suppliersList) {
								if (rec.supplierId === supplier.id) {
									rec.supplierName = supplier.companyName;
								}
							}
						}
						this.reviseOpenSuppliersList();
						this.dataSource = new MatTableDataSource(this.materialSuppliers);
						this.dataSource.sort = this.sort;
						this.setPagination(-1, this.dataSource.data.length);
					},
					(error) => {
					}
				);

			},
			(error) => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			}
		);
	}
	selectRow() {
		if (this.selectedRecord === undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = undefined;
		this.setPagination(this.Idx, this.dataSource.data.length);
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}

	reviseOpenSuppliersList() {
		this.openSuppliersList = [];
		{
			for (const rec of this.suppliersList) {
				let found = false;
				for (const mapping of this.materialSuppliers) {
					if (rec.id === mapping.supplierId && mapping.materialId === this._material.id) {
						found = true;
						break;
					}
				}
				if (!found) {
					this.openSuppliersList.push(rec);
				}
			}
		}
	}

	deleteSupplierMaterial(medSupplierMaterial: SupplierMaterial) {
		const message = 'Remove this Supplier - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {

				this.materialService.deleteMaterialSupplier(medSupplierMaterial.id).subscribe(
					(result) => {
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}

	lastUpdatedLabeler(data): string {
		if (data === null || data === undefined)
			return 'NA';
		let d = new Date(parseInt(data, 10));
		const ds = d.toString();
		return d.toLocaleDateString();
	}

	addRecord() {
		this.selectedRecord = undefined;
		this.showDialogAddEditSupplier();
	}

	showDialogAddEditSupplier() {
		this.displayAddEditSupplier = true;
	}
	cancelClickedFromAddeditSupplier() {
		this.displayAddEditSupplier = false;
	}
	submitClickedFromAddeditSupplier() {
		this.displayAddEditSupplier = false;
		this.queryRecords();
	}
	onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
	}
	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}
		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
			}
		}
	}
}
