/************
 * Model Class
 * File Name ...: purchaseorders.components.ts
 * Generated By : ianday on 5/12/23
 * Date ........: 5/12/23
 /************/
import {ValidationResult} from './validation-result';
import {Settings} from '../services/settings';

export interface PurchaseOrders {
    id: number;
    poNumber: string;
    siteId: number;
    supplierId: number;
    supplierName: string;
    supplierType: string;
    contactId: number;
    contactName: string;
    orderStatus: string;
    orderTotal: number;
    salesTax: number;
    shippingTotal: number;
    grandTotal: number;
    dateCreated: string;
    dateSubmitted: string;
    dateClosed: string;
    shippingService: string;
    orderedBy: string;
    orderedByName: string;
    reference: string;
    poComments: string;
    deaForm222: string;
}

export class PurchaseOrders {
    constructor() {
        this.id = 0;
        this.poNumber = '';
        this.siteId = 0;
        this.supplierId = 0;
        this.supplierName = '';
        this.supplierType = '';
        this.contactId = 0;
        this.contactName = '';
        this.orderStatus = 'Draft';
        this.orderTotal = 0;
        this.salesTax = 0;
        this.shippingTotal = 0;
        this.grandTotal = 0;
        this.dateCreated = new Date().toLocaleDateString();
        this.dateSubmitted = '';
        this.dateClosed = '';
        this.shippingService = '';
        this.orderedBy = Settings.getInstance().loggedInUser.username;
        this.orderedByName = Settings.getInstance().loggedInUser.name;
        this.reference = '';
        this.poComments = '';
        this.deaForm222 = '';
    }

}
