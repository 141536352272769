import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Settings } from '../../services/settings';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
    version;// =AppSettings.version;
    last_updated;// =AppSettings.last_updated;
    release;// =AppSettings.release;
    @Output() cancelClicked = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }
  doCancel() {
    this.cancelClicked.emit();
    }
  doLoadData() {
    this.version = Settings.getInstance().version;
    this.last_updated = Settings.getInstance().lastUpdated;
    this.release = Settings.getInstance().release;
  }
  }
