/**
 *  Filename ....: catalogues.component.ts
 *  Created by ..: ianday
 *  on Date .....: 3/16/23
 **/
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {StationEntries} from '../../../../models/stationentries';
import {SitesService} from '../../../../services/sites.service';
import {MatSort} from '@angular/material/sort';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Stations} from '../../../../models/stations';
import {Settings} from '../../../../services/settings';

import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {AddStationComponent} from './add-station/add-station.component';
import {BulkUnitsService} from '../../../../services/bulkunits.service';
import {AlertDialogComponent} from '../../../../components/shared/alert-dialog/alert-dialog.component';


@Component({
    selector: 'app-stations',
    templateUrl: './stations.component.html',
    styleUrls: ['./stations.component.css']
})

export class StationsComponent implements OnInit {


    public visibleAdd = true;
    public addEditFlag: boolean;
    public dataSource: MatTableDataSource<StationEntries>;
    public displayedColumns = ['id', 'description', 'edit', 'delete'];
    private _parentId: number;
    public sitesService: SitesService;
    public bulkUnitsService: BulkUnitsService;
    public Idx: number | null = -1;
    public records: any;
    public selectedRecord: any;
    public displayAddStation = false;
    public assetPath = '../../../../../assets';
    @ViewChild(MatSort, { static: true }) sort: MatSort ;

    @ViewChild(AddStationComponent)
    public addStationComponent: AddStationComponent;

    @Input() set parentId(value: number) {
        this._parentId = value;

        if (value === undefined || value <= 0) {
            return;

        } else {
            this.Idx = -1;
            this.queryRecords();
        }
    }
    get parentId(): number {
        return this._parentId;
    }

    constructor(ssvc: SitesService, bkService: BulkUnitsService, public dialog: MatDialog) {
        this.sitesService = ssvc;
        this.bulkUnitsService = bkService;
    }

    ngOnInit() {

    }
    initializeDataSource(): void
    {
        this.dataSource = new MatTableDataSource<StationEntries>();
        this.records = new Stations();
        this.Idx = -1;
    }
    queryRecords() {

        this.sitesService.getAllStations(this.parentId).subscribe(
            (result) => {

                if (result === null) {
                    return;
                }
                this.records = result.filter(x => x.activeStatus !== 'Deleted');
                this.setDataSource();
                this.dataSource.sort = this.sort;

                // this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    setDataSource(): void
    {
        this.dataSource = new MatTableDataSource(this.translate(this.records));
    }
    translate(inpRecords: any): any {

        const retRecords: any = new Array<StationEntries>();

        if (inpRecords.length === 0) {
            return retRecords;
        }

        for (let i = 0; i < inpRecords.length; i++) {
            const src: Stations = inpRecords[i];
            const ce: StationEntries = new StationEntries();
            ce.id = inpRecords[i].id;
            ce.description = '<b>' + inpRecords[i].handle + '</b><br>';
            ce.description += inpRecords[i].description + '<br>';
            ce.description += this.returnImageSrc(inpRecords[i].activeStatus);
            ce.edit = '';
            ce.delete = '';

            retRecords.push(ce);
        }
        return retRecords;
    }
    returnImageSrc(inp: string): string {
        let fn = '';
        switch (inp) {
            case 'InActive':
                fn = 'inactive.png';
                break;
            case 'Active':
                fn = 'active.png';
                break;
        }

        const retVal = '<img src="' + this.assetPath + '/' + fn + '">';
        return retVal;
    }
    addNewRecord(): void {
        this.displayAddStation = true;
        this.addEditFlag = true;
        this.addStationComponent.afterOpenDialog(new Stations(), this.addEditFlag);
    }
    editRecord(idx: number): void {
        this.onRowClicked(idx);
        this.displayAddStation = true;
        this.addEditFlag = false;
        this.addStationComponent.afterOpenDialog(this.records[this.Idx], this.addEditFlag);
    }
    newStationCreated(event: any): void {

        if (this.addEditFlag === true) {
            // push the new record onto the top of the stack.
            this.records.unshift(event as Stations);
        }
        else {
            this.records[this.Idx] = event as Stations;
        }
        this.setDataSource();
        this.dataSource.sort = this.sort;
        this.Idx = 0;       // sets the currently selected row

        this.displayAddStation = false;
    }
    newStationCanceled(event): void {
        this.displayAddStation = false;
    }
    deleteRecord(idx: number): void {

        this.Idx = idx;
        const localRecord = this.records[idx];
        const message = 'Remove contact record for: "' + localRecord.handle + '"<br><b>Are You Sure?</b>';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const result = dialogResult;
            if (result === true) {
                localRecord.activeStatus = 'Deleted';

                this.sitesService.updateStations(localRecord).subscribe(
                    (data) => {
                        this.queryRecords();
                        this.selectedRecord = new StationEntries();
                        this.Idx = -1;
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Error: unable to remove this catalogues record.');
                    });
            }
        });
    }
    onRowClicked(idx: number): void {
        this.Idx = idx;                     // sets the row.
        this.selectedRecord = this.dataSource.data[idx];
    }
    tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.data.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.data[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.data[--this.Idx];
            }
        }
    }
    selectRow() {

        if (this.selectedRecord.id === 0) {
            return;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.selectedRecord.id === this.dataSource.data[i].id) {
                // set this record's index in the table.
                this.Idx = i;
                return;
            }
        }
    }
}


