/**
 *  Filename ....: purchaseorders.component.ts
 *  Created by ..: ianday
 *  on Date .....: 5/12/23
 **/
import { Component, EventEmitter, OnInit, Input, Output, HostListener, ViewChild } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DwUtils } from '../../../components/utils/dw-utils';
import { Suppliers } from '../../../models/suppliers';
import { Sites } from '../../../models/sites';
import { PurchaseOrders } from '../../../models/purchaseorders';
import { BulkUnitsService } from '../../../services/bulkunits.service';
import { SuppliersService } from '../../../services/suppliers.service';
import { Settings } from '../../../services/settings';
import { PurchaseOrderItems } from '../../../models/purchaseorderitems';
import { AddPurchaseOrderComponent } from './addpurchaseorder/addpurchaseorder.component';
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { TransactionHistoryComponent } from '../../../components/transaction-history/transaction-history';
import { TransactionHistory } from '../../../models/transactionhistory';
import { TransactionHistoryService } from '../../../services/transactionhistory.service';
@Component({

	selector: 'app-purchaseorders',
	templateUrl: './purchaseorders.component.html',
	styleUrls: ['./purchaseorders.component.css']
})

export class PurchaseOrdersComponent implements OnInit {
	public formTitle = 'Purchase orders';
	private _selectedSite: Sites;
	private bSuggestedOrder: boolean | null = false;
	public isEditing: boolean | null = false;
	public visibleAdd: boolean | null = true;
	public visibleResendBtn  = false;
	public pagination: string;
	/** Services ***/
	public bulkService: BulkUnitsService;
	public supplierService: SuppliersService;
	public txHistService: TransactionHistoryService;

	/** Records and structures **/
	public dataSource: MatTableDataSource<PurchaseOrders>;
	public records: PurchaseOrders[];
	public selectedPO: PurchaseOrders;
	public selectedTabIndex = -1;
	public Idx = -1;

	public purchaseOrderItemsList: PurchaseOrderItems[];
	public POIIdx = -1;
	public detailDataSource: MatTableDataSource<PurchaseOrderItems>;
	public selectedPOI;
	public detailIdx = -1;

	/*** Timeframe / Interval variables ***/

	public intervalList = [];
	public intervalIdx: number | null = -1;
	public selectedInterval: any;

	/*** Status related variables ***/
	public statusFilterList = [];
	public selectedStatusFilter: any;
	public statusFilterIdx: number | null = -1;

	public supplierList = [];

	public displayedColumns: string[] = ['poNumber', 'supplierName', 'dateCreated', 'dateSubmitted', 'dateClosed', 'grandTotal', 'shippingService', 'orderStatus', 'orderedByName'];
	public displayedDetlColumns: string[] = ['ndcNumber', 'itemDescription', 'schedule', 'quantity', 'unitCost', 'extendedCost', 'itemStatus'];

	/*** add PO variables ***/
	public displayAddUpdatePurchaseOrdersDialog: boolean | null = false;

	@ViewChild('addpurchaseorders') addpurchaseorders: AddPurchaseOrderComponent;
	@ViewChild('history', { static: true }) history: TransactionHistoryComponent;

	@Output() closePurchaseOrderEvent = new EventEmitter();

	@Input() set selectedSite(val: Sites) {
		this._selectedSite = val;
	}
	get selectedSite(): Sites {
		return this._selectedSite;
	}

	constructor(public dialog: MatDialog, blkService: BulkUnitsService, suppService: SuppliersService,
		txHist: TransactionHistoryService) {
		this.bulkService = blkService;
		this.supplierService = suppService;
		this.txHistService = txHist;

		this.statusFilterIdx = 0;

		this.intervalList = new Array();
		this.intervalList.push({ id: '30', description: '30 days' });
		this.intervalList.push({ id: '90', description: '90 days' });
		this.intervalList.push({ id: '180', description: '180 days' });
		this.intervalList.push({ id: '0', description: 'All time' });
		this.selectedInterval = this.intervalList[0];
		this.intervalIdx = 0;

		this.statusFilterList = new Array();
		this.statusFilterList.push({ id: 'Draft', description: 'Draft' });
		this.statusFilterList.push({ id: 'Submitted', description: 'Submitted' });
		this.statusFilterList.push({ id: 'Closed', description: 'Closed' });
		this.statusFilterList.push({ id: 'Cancelled', description: 'Cancelled' });
		this.statusFilterList.push({ id: 'On-Hold', description: 'On Hold' });
		this.statusFilterList.push({ id: 'All', description: 'All Items' });
		this.selectedStatusFilter = this.statusFilterList[0];
	}

	ngOnInit() {

	}
	@HostListener('matSortChange', ['$event'])
	onSortChange(event: any) {

		if (event.direction === 'asc') {
			this.records.sort((a, b) =>
				a[event.active].localeCompare(b[event.active]));
		} else {
			this.records.sort((a, b) =>
				b[event.active].localeCompare(a[event.active]));
		}

		this.dataSource = new MatTableDataSource(this.records);
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	/*** Form Functions ***/
	public setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	public clearFilter() {
		if (this.isEditing) {
			return;
		}
		this.unSelectRow();
		this.dataSource.filter = '';
		this.selectRow();
		this.setPagination(-1, this.dataSource.data.length);
	}
	public doIntervalDropDownKeyDown(event: any): void {
		const len: number = this.intervalList.length;
		if (event.key === 'ArrowDown') {
			if (this.intervalIdx < (len - 1)) {
				this.selectedInterval = this.intervalList[++this.intervalIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.intervalIdx > 0) {
				this.selectedInterval = this.intervalList[--this.intervalIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedInterval = this.intervalList[this.intervalIdx];
			this.doIntervalValueChange(this.selectedInterval);
		}
	}
	public doIntervalValueChange(event: any) {
		if (event.value !== undefined) {
			this.selectedInterval = event.value;
		}
		this.queryRecords();
	}
	public showDialog(): void {

		this.queryRecords();
		/*** Remove these three lines when plumbing ***/
		// this.displayAddUpdatePurchaseOrdersDialog = true;
		// this.tabGroup.selectedIndex = 1;
	}
	public disableCancelButton(): boolean {

		if (this.selectedPO === undefined) {
			return true;
		}

		if (this.selectedPO.orderStatus === 'Cancelled' ||
			this.selectedPO.orderStatus === 'Closed') {
			return true;
		}

		/** No record is selected yet **/
		if (this.Idx < 0)
			return true;

		return false;
	}
	public disableUpdateButton(): boolean {
		if (this.selectedPO === undefined) {
			return true;
		}

		if (this.selectedPO.orderStatus === 'Draft')
			return false;

		return true;
	}
	public onStatusFilterKeyDown(event: any): void {
		const len: number = this.statusFilterList.length;
		if (event.key === 'ArrowDown') {
			if (this.statusFilterIdx < (len - 1)) {
				this.selectedStatusFilter = this.statusFilterList[++this.statusFilterIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.statusFilterIdx > 0) {
				this.selectedStatusFilter = this.statusFilterList[--this.statusFilterIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedStatusFilter = this.statusFilterList[this.statusFilterIdx];
			this.onChangeStatusFilterValue(this.selectedStatusFilter);
		}
	}
	public onChangeStatusFilterValue(event: any) {

		// const dataTemp: Suppliers[] = [];          // and this one!
		const dataTemp: any = [];
		if (event.value !== undefined) {
			this.selectedStatusFilter = event.value;
		}
		this.clearFilter();
		this.queryRecords();
		// this.dataSource = new MatTableDataSource(dataTemp);
		// this.setPagination(-1, this.dataSource.filteredData.length);
	}
	public applyFilter(filterValue: string) {
		if (this.isEditing) {
			return;
		}
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.unSelectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	public selectRow() {
		if (this.selectedPO.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedPO.id);
		if(this.selectedPO.orderStatus === 'Submitted') {
			this.visibleResendBtn = true;
		}

	}
	public unSelectRow() {

		this.Idx = -1;
		this.selectedPO = new PurchaseOrders();
		this.setPagination(this.Idx, this.dataSource.data.length);
		this.displayOrderItems();
		this.clearDetail();
		this.visibleResendBtn = false;

	}
	public clearDetail(): void {

	}
	public onRowClicked(selected: any) {

		if (this.isEditing === true)
			return;

		this.selectedPO = selected;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
		this.displayOrderItems();
	}
	public doDoubleClickRow(selected: any): void {
		this.onRowClicked(selected);
		this.updateExistingPO();
	}
	/*** Query chain ****/
	public queryRecords(): void {

		// connect to the service ...
		this.bulkService.getPurchaseOrdersBySiteAndStatus(this.selectedSite.id,
			this.selectedStatusFilter.id,
			this.selectedInterval.id).subscribe(
				(data) => {
					// do some stuff ...
					this.records = data;
					this.querySuppliers();
					this.setPagination(this.Idx, this.records.length);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'Retrieving purchase orders');
				}
			);
	}
	public querySuppliers(): void {

		/** if we have the list of suppliers then no need
		 * to fetch it again ***/
		if (this.supplierList.length > 0) {
			this.buildDataSets();
			return;
		}

		this.supplierService.getAllSuppliers().subscribe(
			(data) => {
				this.supplierList = data;
				this.buildDataSets();
			},
			(error) => {
				Settings.getInstance().handleError(error, 'Retrieving suppliers');
			});
	}
	public buildDataSets(): void {

		for (let i = 0; i < this.records.length; i++) {
			this.records[i].supplierName = this.supplierList.find(x => x.id === this.records[i].supplierId).companyName;
			this.records[i].orderedByName = Settings.getInstance().usersList.find(y => y.username === this.records[i].orderedBy).name;
		}
		this.dataSource = new MatTableDataSource(this.records);

		/*** check if an item has been selected ... ***/
		/*** if so then refresh the detail ***/
		if (this.Idx > -1) {
			this.displayOrderItems();
		}
	}
	/*** Purchase Order Items ***/
	public displayOrderItems(): void {

		if (this.selectedPO === undefined) {
			return;
		}

		// show history ...
		this.history.onShowHandler('purchaseorders', 'id', this.selectedPO.id.toString());

		this.bulkService.getPurchaseOrderItemsForPO(this.selectedPO.id).subscribe(
			(data) => {
				this.purchaseOrderItemsList = data;
				this.detailDataSource = new MatTableDataSource(this.purchaseOrderItemsList);
				this.detailIdx = -1;
			},
			(error) => {
				Settings.getInstance().handleError(error, 'fetching PO items');
			});

	}
	public tableKeyDown(event: any): void {
		if (this.isEditing) {
			return;
		}

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedPO = this.dataSource.filteredData[++this.Idx];
				this.displayOrderItems();
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedPO = this.dataSource.filteredData[--this.Idx];
				this.displayOrderItems();
			}
		}
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	public createNewPO(): void {
		this.displayAddUpdatePurchaseOrdersDialog = true;

		/** slight delay before opening selection dialog **/
		setTimeout(() => {
			this.addpurchaseorders.doInitialize(true);
		}, 300);
	}
	public doPurchaseOrderAddedUpdated(): void {
		this.displayAddUpdatePurchaseOrdersDialog = false;
		this.queryRecords();
	}
	public updateExistingPO(): void {
		this.displayAddUpdatePurchaseOrdersDialog = true;
		this.addpurchaseorders.purchaseOrder = this.selectedPO;

		this.addpurchaseorders.purchaseOrderHist = Object.assign({}, this.selectedPO);

		this.addpurchaseorders.records = this.purchaseOrderItemsList;
		this.addpurchaseorders.doInitialize(false);
	}
	public doTabChange(event: any): void {

	}
	public cancelSelectedPO(): void {

		const idx = this.records.findIndex(x => x.id === this.selectedPO.id);
		const message = 'Remove : \"' + this.selectedPO.poNumber + '\" for: \"' + this.selectedPO.supplierName + '\" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				if (this.records[idx].id > 0) {
					this.records[idx].orderStatus = 'Cancelled';
					this.bulkService.updatePurchaseOrder(this.records[idx]).subscribe(
						(data) => {
							this.createTxHistoryRecord('Cancel');
							this.queryRecords();
						},
						(error) => {
							Settings.getInstance().handleError(error, 'Cancelling Purchase Order');
						});
				}
			}
		});
	}
	public createTxHistoryRecord(action: string): void {
		this.txHistService.createTransactionHistory('purchaseorders', this.selectedPO.id, action, 'Purchase Order Cancelled');
	}
	public doClosePurchaseOrders(): void {
		this.Idx = -1;
		this.closePurchaseOrderEvent.emit();
	}
	public resendSelectedPO() {
		const idx = this.records.findIndex(x => x.id === this.selectedPO.id);
		const message = 'Resend : \"' + this.selectedPO.poNumber + '\" for: \"' + this.selectedPO.supplierName + '\" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				if (this.records[idx].id > 0) {
			this.bulkService.sendPurchaseOrderByEmail(this.selectedPO).subscribe(
				(data) => {

				},
				(error) => {
					Settings.getInstance().handleError(error, 'Sending a purchase order');
				});
				}
			}
		});
	}
}


