/************
 * Model Class
 * File Name ...: bpr.components.ts
 * Generated By : ianday on 7/7/23
 * Date ........: 7/7/23
 /************/
import {ValidationResult} from './validation-result';
import {Orders} from './orders';
import {OrderItems} from './orderitems';
import {Settings} from '../services/settings';

export interface Bpr {
    id: number;
    itemId: number;
    siteId: number;
    orderId: number;
    customerId: number;
    stationId: number;
    itemNumber: string;
    description: string;
    quantity: number;
    storageTemp: number | null;
    storageHumidity: number | null;
    prepApproveDirPrec: number | null;
    prepStationClean: number | null;
    prepLabelImgVerify: number | null;
    prepDosageImgVerify: number | null;
    dosesRequired: number | null;
    dosesSelected: number | null;
    dosesRemaining: number | null;

    packageTemperature: number | null;
    packageHumidity: number | null;
    unitsOrdered: number | null;
    dosesPerUnit: number | null;
    dosesRemoved: number | null;
    labelsRequired: number | null;
    visualLabelMatch: number | null;
    dosesAtsActual: number | null;
    dosesVariance: number | null;
    explanation: string | null;
    itemNumberValidation: number | null;
    seqNumberRangeVerify: number | null;
    releaseApproval: number | null;
    verifiedUnits: number | null;
    approveToShip: number | null;
    shippingCompleted: number | null;
    
    createdDate: string;
    createdBy: string;
    currentStep: number;
    activeStatus: string;

    trackingNumber: string;
    shippingDueDate: string;
}

export class Bpr {

    constructor() {
        this.initializeBpr();
    }

    private initializeBpr(): void {
        this.id = 0;
        this.itemId = 0;
        this.siteId = 0;
        this.orderId = 0;
        this.customerId = 0;
        this.stationId = 0;
        this.itemNumber = '';
        this.description = '';
        this.quantity = 0;
        this.storageTemp = 0;
        this.storageHumidity = 0;
        this.prepApproveDirPrec = 0;
        this.prepStationClean = 0;
        this.prepLabelImgVerify = 0;
        this.prepDosageImgVerify = 0;
        this.dosesRequired = 0;
        this.dosesSelected = 0;
        this.dosesRemaining = 0;

        this.packageTemperature = 0;
        this.packageHumidity = 0;
        this.unitsOrdered = 0;
        this.dosesPerUnit = 0;
        this.dosesRemoved = 0;
        this.labelsRequired = 0;
        this.visualLabelMatch = 0;
        this.dosesAtsActual = 0;
        this.dosesVariance = 0;
        this.explanation = '';
        this.itemNumberValidation = 0;
        this.seqNumberRangeVerify = 0;
        this.releaseApproval = 0;
        this.verifiedUnits = 0;
        this.approveToShip = 0;
        this.shippingCompleted = 0;

        this.createdDate = '';
        this.createdBy = '';
        this.currentStep = 0;
        this.activeStatus = '';
        this.trackingNumber = '';
        this.shippingDueDate = null;
    }

    public setBpr(record: any): void{
        this.id = record.id;
        this.itemId = record.itemId;
        this.siteId = record.siteId;
        this.orderId = record.orderId;
        this.customerId = record.customerId;
        this.stationId = record.stationId;
        this.itemNumber = record.itemNumber;
        this.description = record.description;
        this.quantity = record.quantity;
        this.storageTemp = record.storageTemp;
        this.storageHumidity = record.storageHumidity;
        this.prepStationClean = record.prepStationClean;
        this.prepApproveDirPrec = record.prepApproveDirPrec;
        this.prepLabelImgVerify = record.prepLabelImgVerify;
        this.prepDosageImgVerify = record.prepDosageImgVerify;

        this.dosesRequired = record.dosesRequired;
        this.dosesSelected = record.dosesSelected;
        this.dosesRemaining = record.dosesRemaining;

        this.packageTemperature = record.packageTemperature;
        this.packageHumidity = record.packageHumidity;
        this.unitsOrdered = record.unitsOrdered;
        this.dosesPerUnit = record.dosesPerUnit;
        this.dosesRemoved = record.dosesRemoved;
        this.labelsRequired = record.labelsRequired;
        this.visualLabelMatch = record.visualLabelMatch;
        this.dosesAtsActual = record.dosesAtsActual;
        this.dosesVariance = record.dosesVariance;
        this.explanation = record.explanation;
        this.itemNumberValidation = record.itemNumberValidation;
        this.seqNumberRangeVerify = record.seqNumberRangeVerify;
        this.releaseApproval = record.releaseApproval;
        this.verifiedUnits = record.verifiedUnits;
        this.approveToShip = record.approveToShip;
        this.shippingCompleted = record.shippingCompleted;

        this.createdDate = record.createdDate;
        this.createdBy = record.createdBy;
        this.currentStep = record.currentStep;
        this.activeStatus = record.activeStatus;
        this.trackingNumber = record.trackingNumber;
        this.shippingDueDate = record.shippingDueDate;
    }
    public setBprFromOrderItem(oi: OrderItems, o: Orders, siteId: number): void {

        this.initializeBpr();
        this.id = 0;
        this.itemId = oi.id;
        this.orderId = oi.orderId;
        this.customerId = o.customerId;
        this.siteId = siteId;
        this.itemNumber = oi.itemPrefix;
        this.description = oi.description;
        this.quantity = oi.quantity;
        this.createdDate = new Date().toISOString();
        this.createdBy = Settings.getInstance().loggedInUser.name;
        this.currentStep = 1;
        this.activeStatus = 'Active';
    }
    public duplicateSetOrderItem(rec: OrderItems, pkgSize: number): void {

        this.initializeBpr();
        this.id = 0;
        this.itemId = rec.id;
        this.quantity = rec.quantity;
        this.dosesSelected = 0;
        this.dosesRemaining = 0;
        this.storageTemp = 0;
        this.storageHumidity = 0;
        this.prepStationClean = 0;
        this.prepApproveDirPrec = 0;
        this.prepLabelImgVerify = 0;
        this.prepDosageImgVerify = 0;
        this.dosesRequired = pkgSize * this.quantity;

    }
    public validatePreparationRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (Number(this.storageTemp) === 0) {
            vr.error = 'Error: A Storage Temperature must be entered';
            vr.focus_field = 'storageTemp';
            return vr;
        }

        if (Number(this.storageHumidity) === 0) {
            vr.error = 'Error: A Storage Humidity must be entered';
            vr.focus_field = 'storageHumidity';
            return vr;
        }

        if (this.stationId < 1) {
            vr.error = 'Error: A station must be selected';
            vr.focus_field = 'stationId';
            return vr;
        }

        if (Number(this.prepApproveDirPrec)  === 0) {
            vr.error = 'Error: Directions and Precautions must be acknowledged first';
            vr.focus_field = 'prepApproveDirPrec';
            return vr;
        }

        if (Number(this.prepLabelImgVerify)  === 0) {
            vr.error = 'Error: Visual Label approval must be acknowledged first';
            vr.focus_field = 'prepLabelImgVerify';
            return vr;
        }
        if (Number(this.prepDosageImgVerify)  === 0) {
            vr.error = 'Error: Visual Dosage approval must be acknowledged first';
            vr.focus_field = 'prepDosageImgVerify';
            return vr;
        }
        /*if (Number(this.prepStationClean)  === 0) {
            vr.error = 'Error: Verification of station cleaned must be acknowledged first';
            vr.focus_field = 'prepStationClean';
            return vr;
        }*/

        return vr;
    }
    public validateInProcessRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (Number(this.packageTemperature === 0)) {
            vr.error = 'Error: A Package Temperature must be entered';
            vr.focus_field = 'packageTemp';
            return vr;
        }

        if (Number(this.packageHumidity) === 0) {
            vr.error = 'Error: A Package Humidity must be entered';
            vr.focus_field = 'packageHumidity';
            return vr;
        }

        return vr;

    }
    public validateQCRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.visualLabelMatch === 0) {
            vr.error = 'Error: Must validate the label match to continue';
            vr.focus_field = 'visualLabelMatchId';
            return vr;
        }

        if (this.itemNumberValidation === 0) {
            vr.error = 'Error: Item Number Validation must be completed and checked to continue';
            vr.focus_field = 'itemNumberValidationId';
            return vr;
        }

        if (this.seqNumberRangeVerify === 0) {
            vr.error = 'Error: Sequence Number must be verified and checked to continue';
            vr.focus_field = 'seqNumberRangeVerify';
            return vr;
        }

        if (this.releaseApproval === 0) {
            vr.error = 'Error: Release Approval is required to continue';
            vr.focus_field = 'releaseApprovalId';
            return vr;
        }

        return vr;
    }
    public validateReleaseRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.verifiedUnits === 0) {
            vr.error = 'Error: Must have a verification on the units';
            vr.focus_field = '';
            return vr;
        }

        if (this.approveToShip === 0) {
            vr.error = 'Error: Must have an approve to ship ';
            vr.focus_field = '';
            return vr;
        }
        return vr;
    }
    public validateShippingRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.trackingNumber.length === 0) {
            vr.error = 'Error: Must have a tracking number from the carrier ';
            vr.focus_field = 'trackingNumberId';
            return vr;
        }
        if (this.shippingCompleted === 0) {
            vr.error = 'Error: Must have a shipping Due Date';
            vr.focus_field = 'shippingDueDateId';
            return vr;
        }

        return vr;
    }
    public validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();
        return vr;
    }
}
