/**
 *  Filename ....: lookupitem.component.ts
 *  Created by ..: ianday
 *  on Date .....: 6/23/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {MedicationService} from '../../../../services/medication.service';
import {CustomersService} from '../../../../services/customers.service';
import {FormularyService} from '../../../../services/formulary.service';
import {MatTableDataSource} from '@angular/material/table';
import {Settings} from '../../../../services/settings';
import {OrderItems} from '../../../../models/orderitems';
import {DwUtils} from '../../../../components/utils/dw-utils';

@Component({
    selector: 'app-lookupitem',
    templateUrl: './lookupitem.component.html',
    styleUrls: ['./lookupitem.component.css']
})

export class LookupItemComponent implements OnInit {

    public records: any;
    public dataSource: MatTableDataSource<any>;
    public selectedMedication: any;
    public oi: OrderItems;

    public Idx = -1;
    public bUseCustomerFormulary: boolean | null = true;
    public _customerId: number;

    public displayedColumns = ['id', 'itemPrefix', 'packageSize', 'description', 'inStock', 'onOrder', 'awp'];

    @Input() set customerId(val: number) {
        this._customerId = val;
    }
    get customerId(): number {
        return this._customerId;
    }
    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<OrderItems>();

    constructor(public medServices: MedicationService, public formularyServices: FormularyService) {

    }

    ngOnInit() {

    }
    public doInitialize(): void {
        this.bUseCustomerFormulary = true;
        this.selectedMedication = null;
        this.queryCustomerFormulary();
        DwUtils.setFocus('txtLookupFilter');
    }
    public changeQuerySource(event): void {

        if (event.checked === true) {
            this.queryCustomerFormulary();
        }
        else {
            this.queryMedications();
        }
    }
    public queryCustomerFormulary(): void {
        this.records = new Array();
        this.formularyServices.getFormularyMedicationByCustomerId(this.customerId).subscribe(
            (data) => {
                let i = 0;
                for (const rec of data) {
                    rec.id = ++i;
                    this.records.push(rec);
                }
                this.dataSource = new MatTableDataSource(this.records);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'getting FormularyMeds by Customer Id');
            });
    }
    public queryMedications(): void {

        this.records = new Array();
        this.medServices.getMedicationsForOrderLookup().subscribe(
            (data) => {
                let i = 0;
                for (const rec of data) {
                    rec.id = i++;
                    this.records.push(rec);
                }
                this.dataSource = new MatTableDataSource(this.records);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'getting MedicationsForOrderLookup');
            });
    }
    /*** Functions ***/
    public applyFilter(filterValue: string) {

        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
    }
    public clearFilter() {

        this.unSelectRow();
        this.dataSource.filter = '';
        this.selectRow();

    }
    public formatDecimalToWhole(inp: any): string {
        return DwUtils.decimalToWhole(inp);
    }
    public formatCurrency(inp: any): string {

        return DwUtils.formatCurrency(inp, 2);
    }
    /*** Table Management ***/
    @HostListener('matSortChange', ['$event'])
    onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
    }
    public selectRow(): void {
        if (this.selectedMedication.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedMedication.id);
    }
    public unSelectRow() {
        this.Idx = -1;
        this.selectedMedication = null;
    }
    public onRowClicked(row: any): void {
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === row.id);
        this.selectedMedication = this.dataSource.filteredData[this.Idx];
    }
    public onRowDoubleClicked(row: any): void {
        this.onRowClicked(row);
        this.onSubmitClicked();
    }
    public tableKeyDown(event: any): void {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedMedication = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedMedication = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    /*** Egress ***/
    public setRecord(): void {

        this.oi = new OrderItems();
        this.oi.id = Math.random();
        this.oi.quantity = 1;
        this.oi.startingSequenceNumber = this.selectedMedication.startingSequenceNumber;
        this.oi.extendedPrice = this.selectedMedication.awp;
        this.oi.itemPrefix = this.selectedMedication.itemPrefix + '-'+ this.selectedMedication.packageSize;
        this.oi.description = this.selectedMedication.description;
        this.oi.unitPrice = this.selectedMedication.awp;

    }
    public onSubmitClicked(): void {
        this.setRecord();
        this.submitClicked.emit(this.oi);
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


