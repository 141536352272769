import {Component, Input, OnInit} from '@angular/core';
import { MetricCard } from '../../models/metric-card';

@Component({
  selector: 'metric-card-component',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.css']
})
export class MetricCardComponent implements OnInit {

  @Input() selectedCard: MetricCard;

  ngOnInit() {
  }
  formatToolTip(thisCard: MetricCard): string {
	if(thisCard.previous_result != -1) {
    	return 'Previous period was: ' + thisCard.previous_result;
	} else {
		return '';
	}
  }
}
