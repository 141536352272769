import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DwUtils } from 'src/app/components/utils/dw-utils';
import { FormularyMedication } from 'src/app/models/formulary-medication';
import { FormularyMedicationStatus } from 'src/app/models/formulary-medication-status';
import { FormularyService } from 'src/app/services/formulary.service';
import { Settings } from 'src/app/services/settings';
import { FormularyMainComponent } from '../../formulary-main.component';

@Component({
	selector: 'app-change-status',
	templateUrl: './change-status.component.html',
	styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent {
	valItemNumber = '';
	valMedicationFamily = '';
	valNewStatus = '';
	valStatus = '';
	valComments = '';
	selectedFile: any;
	strErr = '';
	_formularyMedication: FormularyMedication;
	statusList = ['Draft', 'Sent', 'Approved', 'Changed'];
	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();

	@Input() set formularyMedication(value: any) {
		this._formularyMedication = value;
		if (this._formularyMedication) {
			this.onShowHandeler();
		}
	}
	constructor(private formularyService: FormularyService) {

	}
	onShowHandeler() {
		this.valItemNumber = this._formularyMedication.itemNumber;
		this.valStatus = this._formularyMedication.approvalStatus;
		this.valMedicationFamily = this._formularyMedication.medicationDescription;
	}
	validate() {
		if (!this.valNewStatus) {
			this.strErr = 'Please select new status';
			DwUtils.setFocus('newStatus');
			return false;
		}
		if (!this.valComments) {
			this.strErr = 'Please enter comment';
			DwUtils.setFocus('comment');
			return false;
		}

		return true;
	}
	doSubmit() {
		this.strErr = '';
		if (!this.validate()) {
			return;
		}
		let status = new FormularyMedicationStatus();
		status.formularyMedicationId = this._formularyMedication.id;
		status.status = this.valNewStatus;
		status.statusBy = Settings.getInstance().loggedInUser.username;
		status.statusComment = this.valComments;
		status.statusDate = new Date();
		this.formularyService.createFormularyMedicationStatus(status).subscribe(
			(data2) => {
				this.strErr = '';
				status.id = data2.id;
				this._formularyMedication.approvalStatus = this.valNewStatus;
				delete this._formularyMedication['customerFormulary'];
				this.formularyService.updateFormularyMedication(this._formularyMedication).subscribe(
					(data2) => {
						if (!this.selectedFile) {
							this.submitClicked.emit();
						}
						else {
							this.formularyService.uploadFormularyMedicationStatusDocument(status, this.selectedFile).subscribe(
								(result2) => {
									this.submitClicked.emit();
									//this.queryRecords();
								},
								(error2) => {
								}
							);
						}
					},
					(error) => {
					}
				);
			},
			(error) => {
			}
		);

	}
	doCancel() {
		this.cancelClicked.emit();
	}
	onFileSelectedHandler(file: any) {
		this.selectedFile = file;
		if (this.selectedFile === undefined || this.selectedFile === null) {
			return;
		}
		const str = URL.createObjectURL(file);

	}
}
