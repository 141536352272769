import {Injectable} from '@angular/core';

@Injectable( {
  providedIn: 'root',
})

export class AppMapComponent {
  applicationName = 'App Title';
  navItems = [];
  workSpaces = [];

  appTitle = 'App Title';

  constructor() {

  }

}
