<!--
    file type .....: HTML file: bulkunits.component.html
    Created By ....: ianday
    Date ..........: 4/5/23
-->
<div class="bulkunits-container">

<div class="mat-elevation-z1 bu-query-strip">
      <table style="width: 100%">
         <tr>
            <td style="width: 40px">
               <!-- hamburger menu -->
               <button #menubutton mat-icon-button
                       matTooltipPosition="above"
                       matTooltip="Options for selected units."
                       [disabled]="disabledHamburgerMenu"
                       [matMenuTriggerFor]="menu">
                  <mat-icon>menu</mat-icon>
               </button>
               <mat-menu #menu="matMenu" class="mat-menu-style">
                  <button mat-menu-item class="mat-menu-item-style"
                     [disabled]="!hasSelectedItems()"
                     (click)="doInventoryTransfer($event)">Transfer To Another Inventory</button>
                  <button mat-menu-item class="mat-menu-item-style"
                     [disabled]="!hasSelectedItems()"
                     (click)="doReturnManuItems($event)">Return Item To Manufacturer</button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item class="mat-menu-item-style"
                      [disabled]="!hasSelectedItems()"
                     (click)="displayProcessItemRecall=true">Process Item Recall</button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item class="mat-menu-item-style"
                      [disabled]="!hasSelectedItems()"
                  (click)="displayRelocatePhysicalItem=true">Relocate Physical Item</button>
               </mat-menu>

            </td>

            <td style="width: 28%; font-size: 12pt; font-weight:bold;">
               <span style="color: blue">{{itemPrefix}}</span>&nbsp;
               <span>{{itemHandle}}</span>
            </td>
            <td style="text-align:center; width: 530px;">
               <input text
                      id="txtFilter" #txtFilter class="qs_filter-field"
                      [disabled]="this.isEditing"
                      (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
               <button mat-button matSuffix mat-icon-button
                       matTooltip="Clear the filter" aria-label="Clear"
                       (click)="txtFilter.value=''; clearFilter();">
                  <mat-icon>close</mat-icon>
               </button>
            </td>
            <td style="text-align: center; width: 300px;">
               <label style="vertical-align: middle; padding-left: 10px; margin-right: 12px;">
                  Status:
               </label>
               <mat-select
                       disableOptionCentering #containerStatus
                       [(ngModel)]="selectedContainerStatus"
                       floatLabel="never"
                       (keydown)="onContainerStatusDropDownKeyDown($event)"
                       (selectionChange)="doChangeBUContainerStatusFilter($event)"
                       style="font-size: 9pt;width: 140px;"
                       panelClass="local-drop-down-panel" class="dw-mat-select">
                  <mat-option *ngFor="let container of containerStateList"
                              [value]="container">{{container.description}}</mat-option>
               </mat-select>

            </td>
            <td class="table-pagination-cell" align="right" style="width: 140px">{{pagination}}
               Record(s) ...
            </td>
         </tr>
      </table>

   </div>

   <div #txtbl1 class="mat-elevation-z1 bu-table-div" id="buTableId"
        (keydown)="tableKeyDown($event)"  tabindex="-1">
      <table mat-table [dataSource]="dataSource" matSort
             style="outline: none!important;"
             class="bu-table-properties"
             (matSortChange)="onSortChange($event)">

         <!-- Select -->
         <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">
               <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleAllSelected($event)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
               style="width:3% !important;">
               <mat-checkbox (click)="$event.stopPropagation()"
                             [disabled]="evaluateForEnable(element.containerStatus)"
                             [value]="element.select" [(ngModel)]="element.select" >
               </mat-checkbox>
            </td>
         </ng-container>

         <!-- item Number -->
         <ng-container matColumnDef="itemNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Item Number</th>
            <td mat-cell style="width:8% !important; "
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.itemNumber}}</td>
         </ng-container>

         <!-- location -->
         <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Location</th>
            <td mat-cell style="width:8% !important; "
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.location}}</td>
         </ng-container>

         <!-- location -->
         <ng-container matColumnDef="icn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">ICN</th>
            <td mat-cell style="width:8% !important; "
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.icn}}</td>
         </ng-container>


         <!-- serial Number -->
         <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Serial Number</th>
            <td mat-cell style="width:8% !important; "
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.serialNumber}}</td>
         </ng-container>

         <!-- lot number -->
         <ng-container matColumnDef="lotNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Lot Number</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.lotNumber}}</td>
         </ng-container>

         <!-- expiration date -->
         <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Expiration Date</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{labelFmtExpDate(element.expirationDate)}}</td>
         </ng-container>

         <!-- shelft life -->
         <ng-container matColumnDef="shelfLife">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Shelf Life (days)</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{calculateShelfLife(element.expirationDate)}}</td>
         </ng-container>

         <!-- original doses -->
         <ng-container matColumnDef="originalDoses">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Orig. {{dosesPieces}}</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.originalDoses}}</td>
         </ng-container>

         <!-- doses used -->
         <ng-container matColumnDef="dosesUsed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">{{dosesPieces}} Used</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{returnDosesUsed(element.originalDoses, element.defectiveDoses, element.remainingDoses)}}</td>
         </ng-container>

         <!-- doses_remaining -->

         <ng-container matColumnDef="remainingDoses">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">{{dosesPieces}} Left</th>
            <td mat-cell style="width:8% !important;"
                class="bu-data-properties"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.remainingDoses}}</td>
         </ng-container>

         <!-- Container Status -->
         <ng-container matColumnDef="containerStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="mat-table-header-style">Status</th>
             <td mat-cell style="width: 6%"
                 *matCellDef="let element;let tableRowIdx = index;">
                 <img style="padding: 0px; height: 20px; width: 60px; margin:0; vertical-align: top;"
                     src="{{fetchContainerStatusIcon(element.containerStatus)}}" />
             </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
         <tr mat-row style="font-size: 8pt;"
             *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
             [ngClass]="{'row-selected': tableRowIdx == Idx}"
             (dblclick)="onRowDoubleClicked(row)"
             (click)="onRowClicked(row)">
         </tr>
      </table>
   </div>
</div>
<!------------------------------>
<!-- DIALOGS and POPUPS       -->
<!------------------------------>

<p-dialog header="Bulk Item Detail"
          [appendTo]="'body'" [(visible)]="displayDetail"
          [modal]="true" [responsive]="true" [style]="{position: 'absolute',
          width: '1000px', height: '630px'}"
          [minY]="75" [resizable]="false" [closable]="true">
   <app-bulkunitsdetail #bulkUnitDtl
            [selectedRecord]="selectedRecord"
            [itemPrefix]="itemPrefix"
            [itemHandle]="itemHandle"
                        (AttemptToFetchNext)="doFetchNextRecord()"
                        (AttemptToFetchPrevious)="doFetchPreviousRecord()"
                        (closeBulkItemDetail)="closeBUDetail($event)">
   </app-bulkunitsdetail>
</p-dialog>

<p-dialog header="Transfer to Another Inventory"
          [appendTo]="'body'"
          [(visible)]="displayInventoryTransfer"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '580px', height: '360px'}"
          [minY]="75" [resizable]="false" [closable]="true">
   <app-invtransfer #invTransfer
         [InventoryItem]="itemPrefix+itemHandle"
         [medOrMat]="returnMedOrMat()"
         [selectedItems]="returnSelectedItems()"
                    (cancelTransferInvItems)="cancelTransfer()"
                    (transferInvItems)="transferInvItems()"
   ></app-invtransfer>
</p-dialog>
<p-dialog header="Return to Manufacturer"
          [appendTo]="'body'"
          [(visible)]="displayReturnToManufacturer"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '600px', height: '400px'}"
          [minY]="75" [resizable]="false" [closable]="true">
   <app-returnmanu #returnManu
           [InventoryItem]="itemPrefix+itemHandle"
           [medOrMat]="returnMedOrMat()"
           [selectedItems]="returnSelectedItems()"
             (returnInvItems)="returnInvItems()"
             (cancelReturnInvItems)="cancelReturnInvItems()"
 ></app-returnmanu>
</p-dialog>
<p-dialog header="Process Item Recall"
          [appendTo]="'body'"
          [(visible)]="displayProcessItemRecall"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '800px', height: '400px'}"
          [minY]="75" [resizable]="false" [closable]="true">
   <div>Not Ready Yet!</div>
</p-dialog>
<p-dialog header="Relocate an Item Within The Inventory"
          [appendTo]="'body'"
          [(visible)]="displayRelocatePhysicalItem"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '800px', height: '400px'}"
          [minY]="75" [resizable]="false" [closable]="true">
   <div>Not Ready Yet!</div>
</p-dialog>
