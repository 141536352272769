import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { Settings } from '../../services/settings';
import { UserService } from '../../services/user.service';
import { GlobalService } from '../../services/global-service';
import { UserOTP } from 'src/app/models/user-otp';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
	
	strPassword1: string;
	strPassword2: string;
	strToken: string;
	strMessage: string;
	loading = false;
	fullName: string;
	userName: string;
	strEmail: string;
	userRecord: any;
	lastLogin = '';
	selectedFile: any;
	valFilePath: any;
	base64textStringAvatar: string;
	passwordUpdated: boolean;
	disableSubmit = false;
	srtSuccessMsg='';
	public static USERNAME: String;

	constructor(private route: ActivatedRoute, private userService: UserService, private globalService: GlobalService) { }

	ngOnInit(): void {
		this.strToken = this.route.snapshot.paramMap.get('token');
		this.userService.getUserDetailsUsingInvitationToken(this.strToken).subscribe(
			(result) => {
				if(result == null || result == undefined) {
					this.strMessage = "Invalid or expired invitation token. Please request the administrator to send" +
						" a new invite";
					this.disableSubmit = true;
				} else {
					this.fullName = result['data'].name;
					this.userName = result['data'].username;
					RegisterUserComponent.USERNAME = this.userName;
					this.disableSubmit = false;
				}
			},
			(error) => {
				this.strMessage = "Invalid or expired invitation token";
				this.disableSubmit = true;
			}
		);
		document.getElementById('avatar').style.backgroundImage = 'url(/assets/profile-img-generic.png)';
	}
	
	sendPasswordResetRequest() {
		
	}
	
	clearAvatar(evt: Event): void {


    }

	doSubmit() {
		this.strMessage = '';
		if (this.strPassword1 === undefined || this.strPassword1 === '') {
			this.strMessage = 'Please enter new password';
			return;
		}
		if (this.strPassword2 === undefined || this.strPassword2 === '') {
			this.strMessage = 'Please re-type password';
			return;
		}
		if (this.strPassword1 !== this.strPassword2) {
			this.strMessage = 'Passwords do not match!';
			return;
		}
		this.disableSubmit = true;
		let userOTPRecord: UserOTP;
		userOTPRecord = new UserOTP();
		userOTPRecord.email = this.strEmail;
		userOTPRecord.password = this.strPassword1;
		userOTPRecord.token = this.strToken.trim();
		
		if(this.selectedFile != null && this.selectedFile != undefined) {
			this.userService.updateUserProfileImageByToken(this.strToken, this.selectedFile).subscribe(
				( data ) => {
					this.userService.setNewPasswordWithToken(this.strToken, userOTPRecord).subscribe(
						(data) => {
							if (data.code === 200 && data.message==='User record updated successfully') {
								this.strMessage = '';
								this.srtSuccessMsg = 'Registration completed successfully. You are being redirected' +
									' to the login page (please wait) ...';
								setTimeout(function(userOTP: string) {
										window.top.location.href = '/login?u=' + RegisterUserComponent.USERNAME;
								}, 5000);
							}
							if (data === 0) {
								this.strMessage = 'Invalid or expired invitation token. Please ask your' +
									' administrator to send a new invitation';
							}
						},
						(error) => {
			
						}
					);
				},
				( error ) => {
					this.strMessage = 'Invalid or expired invitation token. Please request your administrator to send' +
						' a new invite';
					this.loading = false;
				}
			);
		} else {
			this.userService.setNewPasswordWithToken(this.strToken, userOTPRecord).subscribe(
				(data) => {
					if (data.code === 200 && data.message==='User record updated successfully') {
						this.strMessage = '';
						this.srtSuccessMsg = 'Registration completed successfully. You will be redirected to login' +
							' page in 5 seconds';
						setTimeout(function() {
								window.top.location.href = '/login?u=' + RegisterUserComponent.USERNAME;
						}, 5000);
					}
					if (data === 0) {
						this.strMessage = 'Invalid or expired invitation token. Please request your administrator to' +
							' send a new invite';
					}
				},
				(error) => {
	
				}
			);
		}
	}

	fileInputChange(fileInputEvent: any) {
		if (fileInputEvent.target.files[0] === undefined || fileInputEvent.target.files[0] === null) {
			return;
		}
		if (fileInputEvent.target.files[0].size > 409600) {
			Settings.getInstance().handleError('The image file size should not exceed 400KB');
			return;
		}
		this.selectedFile = fileInputEvent.target.files[0];
		this.valFilePath = this.selectedFile.name;
		this.userService.updateUserProfileImage(this.userName, this.selectedFile).subscribe(
			( data ) => {
				this.loading = false;
				this.globalService.globalEvent.next('PROFILE_REFRESH');
				document.getElementById('avatar').style.backgroundImage = 'url(' + Settings.getInstance().BASE_URL +'users/profile/image/'+this.userName+'?rand='+new Date().getTime()+')';
			},
			( error ) => {
				Settings.getInstance().handleError(error);
				this.loading = false;
			}
		);
	}
}
