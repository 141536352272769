/**
 *  Filename ....: changeassignments.component.ts
 *  Created by ..: ianday
 *  on Date .....: 7/14/23
 **/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Stations} from '../../../../models/stations';
import {Sites} from '../../../../models/sites';
import {SitesService} from '../../../../services/sites.service';
import {Settings} from '../../../../services/settings';

class ChgAssignmentDetails {
    public siteId: number;
    public stationId: number;

}
@Component({
    selector: 'app-changeassignments',
    templateUrl: './changeassignments.component.html',
    styleUrls: ['./changeassignments.component.css']
})

export class ChangeAssignmentsComponent implements OnInit {

    private _sitesList: any;
    private _stationsList: any;
    public stationIdx: number
    public selectedStationId: number;
    public selectedStation: Stations = new Stations();
    public selectedSite: Sites = new Sites();
    public chgAssignments: ChgAssignmentDetails;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<ChgAssignmentDetails>();

    @Input() set sitesList(inp: any) {
        this._sitesList = inp;
    }
    get sitesList(): any {
        return this._sitesList;
    }

    @Input() set stationsList(inp: any) {
        this._stationsList = inp;
    }
    get stationsList(): any {
        return this._stationsList;
    }

    constructor(public stationsService: SitesService) {

    }

    ngOnInit() {

    }
    public queryStations(): void {
        this.stationsService.getAllStations(this.selectedSite.id).subscribe (
            (data) => {
                this.stationsList = [];
                this.stationsList = data.filter(x => x.activeStatus === 'Active');
                this.stationsList.unshift({id: 0, handle: 'Unassigned Station'});
                this.stationIdx = this.stationsList.findIndex(x => x.id === this.selectedStationId);
                this.selectedStation = this.stationsList[this.stationIdx];
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Retrieving stations for prepare');
            });
    }
    public doInitialize(selectedStationId: number, selectedSite: Sites): void {
        this.selectedSite = selectedSite;
        this.selectedStationId = selectedStationId;
        this.queryStations();
    }

    public onChangeSites(event: any): void {
       this.selectedSite = event.value;
       this.selectedStationId = 0;
       this.selectedStation = new Stations();
       this.queryStations();
    }
    public onChangeStations(event: any): void {

    }
    public onSubmitClicked(): void {
        this.chgAssignments = new ChgAssignmentDetails();
        this.chgAssignments.siteId = this.selectedSite.id;
        this.chgAssignments.stationId = this.selectedStation.id;

        this.submitClicked.emit(this.chgAssignments);
    }
    public onCancelClicked(): void {
        this.submitClicked.emit();
    }
}


