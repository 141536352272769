import { ReportRowComponent } from '../report-row/report-row.component';
import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-report-renderer',
  templateUrl: './report-renderer.component.html',
  styleUrls: ['./report-renderer.component.css']
})
export class ReportRendererComponent implements OnInit {

  @ViewChild('reportContainer', { read: ViewContainerRef }) reportContainer;
  private componentRefs: ComponentRef<any>[];
  public _ReportData: Array<any>;
  private _HeaderData: Array<any>;
  @Input() HasHeader: boolean;
  constructor(private resolver: ComponentFactoryResolver) {

  }
  @Input('ReportData')
  set records(val: any[]) {
    if(this.HasHeader) {
      this._HeaderData = val[0];
      this._ReportData = val.slice(1);
    } else {
      this._ReportData = val;
    }
  }

  ngOnInit() {
  }

  getCellClass(value:object, index:number): string {
    let cellClass = 'ui-g-1';
    if(value['width'] === 'auto') {
      if(value['label'].length > 10) {
        cellClass = 'ui-g-2';
      } else if(value['label'].length > 20) {
        cellClass = 'ui-g-3';
      } else if(value['label'].length > 30) {
        cellClass = 'ui-g-4';
      } else if(value['label'].length > 40) {
        cellClass = 'ui-g-5';
      } else if(value['label'].length > 50) {
        cellClass = 'ui-g-6';
      } else {
        cellClass = 'ui-g-1';
      }
    } else {
      cellClass = ' ' + value['width'];
    }
    if(value['classList'] != null && value['classList'].length !== 0) {
      cellClass += ' ' + value['classList'];
    }
    if(cellClass.indexOf('header-row') === -1) {
//      if(index%2 === 1) {
//        cellClass += ' odd-row';
//      } else {
//        cellClass += ' even-row';
//      }
    }
    return cellClass;
  }
}
