<div class="confirmation-dialog-header">
	  {{title}}
</div>

<table style="width:100%">
  <tr><td class="message-style">
    {{message}}
  </td></tr>

  <tr><td class="control-strip-style">
    <button mat-button (click)="onDismiss()"
            class="dialog-button">
      OK
    </button>
  </td></tr>
</table>
