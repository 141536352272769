import { AppFunction } from './app-function';

export class AppFunctionGroup {
    active:boolean;
    appFunctions:Array<AppFunction>;
    id:number;
    name:string;
}

export const APP_FUNCTION_GROUP_DATA: AppFunctionGroup[] = [
  {id: 1, name: 'Legal Group', appFunctions: [
      {applicationCode: 'ABC123', description: 'Access to the ABC Function', feature: 'feature 1', id: 1},
      {applicationCode: 'XYZ123', description: 'Access to the XYZ Function', feature: 'feature 1', id: 2},
      {applicationCode: 'DEF123', description: 'Access to the DEF Function', feature: 'feature 1', id: 3},
      {applicationCode: 'KLA123', description: 'Access to the KLA Function', feature: 'feature 1', id: 4},
      {applicationCode: 'DEL123', description: 'Access to the Delete bla bla ', feature: 'feature 1', id: 5},
      {applicationCode: 'ELS123', description: 'Access to something else', feature: 'feature 1', id: 6} ], active: true},
  {id: 2, name: 'Tech Support Group', appFunctions: [
      {applicationCode: 'ABC123', description:  'Access to the ABC Function', feature: 'feature 1', id: 1},
      {applicationCode: 'XYZ123', description:  'Access to the XYZ Function', feature: 'feature 1', id: 2},
      {applicationCode: 'DEF123', description:  'Access to the DEF Function', feature: 'feature 1', id: 3},
      {applicationCode: 'KLA123', description:  'Access to the KLA Function', feature: 'feature 1', id: 4},
      {applicationCode: 'DEL123',  description: 'Access to the Delete bla bla ', feature: 'feature 1', id: 5},
      {applicationCode: 'ELS123', description:  'Access to something else', feature: 'feature 1', id: 6} ], active: true},
  {id: 3, name: 'Sales Group', appFunctions: [
    {applicationCode: 'ABC123', description:  'Access to the ABC Function', feature: 'feature 1', id: 1},
    {applicationCode: 'XYZ123', description:  'Access to the XYZ Function', feature: 'feature 1', id: 2},
    {applicationCode: 'DEF123',  description: 'Access to the DEF Function', feature: 'feature 1', id: 3},
    {applicationCode: 'KLA123', description:  'Access to the KLA Function', feature: 'feature 1', id: 4},
    {applicationCode: 'DEL123', description:  'Access to the Delete bla bla ', feature: 'feature 1', id: 5},
    {applicationCode: 'ELS123',  description: 'Access to something else', feature: 'feature 1', id: 6} ], active: true},
  {id: 4, name: 'Finance Team', appFunctions: [
    {applicationCode: 'ABC123', description:  'Access to the ABC Function', feature: 'feature 1', id: 1},
    {applicationCode: 'XYZ123',  description: 'Access to the XYZ Function', feature: 'feature 1', id: 2},
    {applicationCode: 'DEF123', description:  'Access to the DEF Function', feature: 'feature 1', id: 3},
    {applicationCode: 'KLA123',  description: 'Access to the KLA Function', feature: 'feature 1', id: 4},
    {applicationCode: 'DEL123', description:  'Access to the Delete bla bla ', feature: 'feature 1', id: 5},
    {applicationCode: 'ELS123', description:  'Access to something else', feature: 'feature 1', id: 6} ], active: true}
]
