/**
 *  Filename ....: qasupplier.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/27/23
 **/
import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {Suppliers} from '../../../../models/suppliers';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {SuppliersService} from '../../../../services/suppliers.service';
import {Settings} from '../../../../services/settings';

@Component({
    selector: 'app-qasupplier',
    templateUrl: './qasupplier.component.html',
    styleUrls: ['./qasupplier.component.css']
})

export class QuickAddSupplierComponent implements OnInit {

    public supplier: Suppliers;
    public strErr: string = '';

    public companyName: string | null;
    public webSiteAddress: string | null;
    public supplierType: string | null;

    /** Services **/
    public supplierService: SuppliersService;

    @Output() cancelQASupplier: EventEmitter<any>;
    @Output() submittedQASupplier: EventEmitter<any>;
    @Input('supplierList') supplierList: any;

    constructor(sService: SuppliersService) {
        this.supplierService = sService;
        this.cancelQASupplier = new EventEmitter();
        this.submittedQASupplier = new EventEmitter();
    }

    ngOnInit() { }

    doShow(): void {
        this.supplier = new Suppliers();
        this.companyName = '';
        this.supplierType = '';
        this.webSiteAddress = '';
        DwUtils.setFocus('companyName');
    }
    setRecord(): void {
        this.supplier = new Suppliers();
        this.supplier.id = 0;
        this.supplier.companyName = DwUtils.capitalize(this.companyName);
        this.supplier.logoImage = '';
        this.supplier.webSiteAddress = DwUtils.checkWWWAddress(this.webSiteAddress);
        this.supplier.supplierType = this.supplierType;
        this.supplier.activeStatus = 'Active';
    }

    submitRecord(): void {

        this.strErr = '';
        this.setRecord();

        const vr = this.supplier.validateRecord();
        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return;
        }
        /** Add formatting to the web-site **/
        if (this.webSiteAddress.length > 0) {
            this.webSiteAddress = DwUtils.checkWWWAddress(this.webSiteAddress);
            this.supplier.webSiteAddress = this.webSiteAddress;
        }
        /** duplicate supplier check **/

        if (this.supplierList.indexOf(this.supplier.companyName) !== -1) {
            this.strErr = 'Error: This supplier already exists';
            DwUtils.setFocus('companyName');
            return;

        }

        this.supplierService.createSuppliers(this.supplier).subscribe (
            (data) => {
                    this.supplier.id = data.identifiers[0].id;
                    this.submittedQASupplier.emit(this.supplier);
                  },
            (error) => {
                Settings.getInstance().handleError(error, 'in Quick Add');
            });

    }
    cancelSubmitRecord(): void {
        this.cancelQASupplier.emit();
    }
}


