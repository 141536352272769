<div class="report-container-div">
<div class="ui-g">
	<div class="ui-g-2 h-100 d-inline-block">
		<div class="h-50 d-inline-block">
			<p-table #reportsGrid id="reports-report-list" [columns]="cols" [value]="reports" (onRowSelect)="onRowSelect($event)" [(selection)]="CurrentReportSelection" [resizableColumns]="true" selectionMode="single" scrollHeight="300px" [scrollable]="true" [loading]="loading1">
			    <ng-template pTemplate="colgroup" let-columns>
			        <colgroup>
			            <col *ngFor="let col of columns" [style.width]="col.width">
			        </colgroup>
			    </ng-template>
			    <ng-template pTemplate="header" let-columns>
			        <tr style="height: 47px;">
			            <th *ngFor="let col of columns" pResizableColumn>
			                {{col.header}}
			            </th>
			        </tr>
			    </ng-template>
			    <ng-template pTemplate="body" let-rowData let-columns="columns">
			        <tr [pSelectableRow]="rowData">
			            <td *ngFor="let col of columns">
			                {{rowData[col.field]}}		                 
			            </td>
			        </tr>
			    </ng-template>
			</p-table>
		</div>
		<div class="w-100 h-50 d-inline-block">&nbsp;</div>
		<div class="w-100 h-50 d-inline-block">
			<div class="float-right">
				<!-- <p-button label="RUN" style="width:80px;" (onClick)="RunClickHandler()"></p-button>  -->
				<button mat-button class="command-button" style="width:80px;" (click)="RunClickHandler()"> 
	      			<mat-icon>directions_run</mat-icon>RUN
	   			</button>
			</div>
		</div>
		<div class="w-100 h-50 d-inline-block">&nbsp;</div>
		<div class="w-100 h-50 d-inline-block">
			<div *ngIf="CurrentReportSelection!== undefined && CurrentReportSelection!=null" class="ui-g h-100">
				{{CurrentReportSelection.Description}}
			</div>			
		</div>
	</div>
	<div class="ui-g-10 h-100 d-inline-block">
		<p-panel >
		    <p-header style="width:100%">
		        <div class="ui-g">
					<div class="ui-g-8 ui-md-8">
						<div class="ui-g h-100">
							<span class="header-name">{{headerLHSReportName}}</span>
							<span class="filter-box">{{headerLHSData}}</span>
						</div>
					</div>
					<div class="ui-g-4 ui-md-4 right">
						<span style="padding-right:10px;">{{headerRHSData}}</span>
						<div class="dropdown" style="margin-right: 8px;">
							  <p-button  sty1e="width: 90px" (click)="excelClickHandler()" [disabled]="!disableExportButtons">EXPORT</p-button>
							  <div class="dropdown-content">
							    <a (click)="excelClickHandler()">Excel</a>
							    <a (click)="exportAsTextClickHandler()">Text</a>
							  </div>
							</div>
						<!-- 						
						<p-button label="EXCEL" (onClick)="excelClickHandler()" [disabled]="disableExportButtons"></p-button>&nbsp;&nbsp;
						 -->
						 <p-button sty1e="width: 90px" label="&nbsp;&nbsp;PRINT&nbsp;&nbsp;" (onClick)="printClickHandler()" [disabled]="!disableExportButtons"></p-button>
					</div>
				</div>
			</p-header>
			<div *ngIf="noDataFound" style="text-align: center" ><label>No Data Found</label></div>
			<div style="text-align: center" ><p-progressSpinner *ngIf="loading2" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="animationDuration"></p-progressSpinner></div>
			<app-report-renderer id="reportPane" class="report-data" [ReportData]="ReportData" [HasHeader]="selectedReport?.HasHeader"></app-report-renderer>
			<div class="footer report-footer">
				<div class="row" style="padding-top:10px;">
					<div class="col-xs-6 col-sm-6">
						<span >Reports Renderer by Dayworks</span>		
					</div>
				</div>
			</div>
		</p-panel>
	</div>
</div>
</div>
<p-dialog #ParamsDialog header="Report Parameters" [(visible)]="displayParamsWindow" [modal]="false" [responsive]="true"  [minY]="70" [contentStyle]="{'overflow':'hidden'}" [resizable]="false">
	<app-report-params (SubmitClicked)="reportParamsRunClickHandler()" (CloseClicked)="reportParamsCancelClickHandler()" [ParamSpecs]="ReportParams" [parent]="ParamsDialog"></app-report-params>
	<!--<p-footer>
	    <button type="button" pButton icon="pi pi-check" (click)="reportParamsRunClickHandler()" label="RUN" style="width: 90px" ></button>
	    <button type="button" pButton icon="pi pi-times" (click)="reportParamsCancelClickHandler()" label="CANCEL" style="width: 90px" ></button>
	</p-footer>-->
</p-dialog>
