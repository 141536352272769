import { Component, ChangeDetectorRef } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-dw-stepper',
  templateUrl: './dw-stepper.component.html',
  styleUrls: ['./dw-stepper.component.css'],
  providers: [{ provide: CdkStepper, useExisting: DwStepperComponent }],
})
export class DwStepperComponent extends CdkStepper {

  constructor(dir: Directionality, changeDetectorRef: ChangeDetectorRef) {
      super(dir, changeDetectorRef,null);
  }

}
