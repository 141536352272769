import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { NavbarEventManager} from '../../_common/NavbarEventManager';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router, private naveventmgr: NavbarEventManager) {
	this.authService.authorizePromise = null;
}

	/*
	canActivate(): Observable<boolean> {
		return this.authService.isAuthenticated$.pipe(
			switchMap((isAuthenticated) => {
				if (!isAuthenticated) {
					if(this.authService.sessionLoadAttempted == true) {
						this.router.navigate(['/login']);
					}
					this.naveventmgr.hideNavBar.emit(true);
				}
				else {
					this.naveventmgr.showNavBar.emit(true);
				}
				return this.authService.isAuthenticated$;
			})
		);
	}
	*/
	canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
		return this.authService.authorize().then(result => {
			if (!result) {
				this.router.navigate(['/login']);
				this.naveventmgr.hideNavBar.emit(true);
				return false;
			}
			this.naveventmgr.showNavBar.emit(true);
			return true;
		});
	}
}
