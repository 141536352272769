<div class="ui-g" class="profile-container" >

	<div class="ui-g-3 profile-avatar-container">
	
		<div #avatar id="avatar" style="margin-left: 20px;" (click)="fileInput.click()"></div>
		<input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileInputChange($event)"
			   accept="image/*"/>
		<div style="margin-left: 0px; margin-top: 2px;">
			<button style="background: none;" (click)="fileInput.click()" mat-button><mat-icon>camera_alt</mat-icon></button>
			<button style="background: none;" (click)="clearAvatar($event)" mat-button><mat-icon>cancel</mat-icon></button>
		</div>
		<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8"  *ngIf="loading"></p-progressSpinner>
	</div>
	
	<div class="ui-g-9 profile-data-container" >
		<div class="ui-g" style="padding-top: 5px; padding-bottom: 5px;">
			<div class="ui-g-4 ui-md-4">
				<label	style="color:Gray;">Full Name:</label>
			</div>
			<div class="ui-g-8 ui-md-8">
				<label class="label-data-override">{{this.fullName}}</label>
			</div>
		</div>
		<div class="ui-g" style="padding-bottom: 5px;" >
			<div class="ui-g-4 ui-md-4">
				<label	style="color:Gray">User Name:</label>
			</div>
			<div class="ui-g-8 ui-md-8">
				<label class="label-data-override">{{this.userName}}</label>
			</div>
		</div>
		<div class="ui-g" style="padding-bottom: 5px;">
			<div class="ui-g-4 ui-md-4">
				<label	style="color:Gray">Last Login:</label>
			</div>
			<div class="ui-g-8 ui-md-8">
				<label class="label-data-override">{{this.lastLogin}}</label>
			</div>
		</div>
	</div>
</div>	
<hr>
<div class="buttonHolder">
		<button mat-button  style="width: 85px;" class="dialog-button" matTooltip="Close window" (click)="doCancel()">CLOSE</button>
</div>
