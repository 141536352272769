<div class="login-background-container">
<mat-card id="loginCard">
  <mat-card-title><span style="font-size: 24px;font-weight: 500;">Please Login</span></mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p>

          <input class="input-class" #username id="username" type="text" matInput placeholder="User name"
                 formControlName="username" autofocus
                 (keyup)="clearErrorMessage()" >
      </p>

      <p>
         <input #password class="input-class" id="password" type="password" matInput placeholder="Password"
                 formControlName="password"
                 (keyup)="clearErrorMessage()" >
      </p>
      <div style="text-align: right;"><span style="text-align: right;"> <a routerLink="/recovery">Forgot Password?</a></span></div>
 	  <div style="height: 18px;">
      	<p *ngIf="error" class="error">Error: {{ error }} </p>
      </div>

 	  <div style="height: 8px;">
 	  	<span *ngIf="processingLogin" >
 	  		<p-progressBar color="green" mode="indeterminate" [style]="{height: '0.2rem', padding: '0px', overflow: 'hidden'}"></p-progressBar>
 	  	</span>
      </div>
       <div style="height: 10px;">
       <mat-checkbox id="remember-me" name="rememberUser" binary="true" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}">Remember Me</mat-checkbox>
       </div>

      <div class="button">
        <button id="login-button" type="submit" mat-button>Login</button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
</div>
