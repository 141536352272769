import { Component, Input, OnInit } from '@angular/core';
import { Bpr } from 'src/app/models/bpr';
import * as printJS from 'print-js';
import { Stations } from 'src/app/models/stations';

@Component({
	selector: 'app-bpr',
	templateUrl: './bpr.component.html',
	styleUrls: ['./bpr.component.css']
})

export class BprComponent implements OnInit {

	record: Bpr;
	currentStepBPRId: any;
	currentStepBPRStatus: any;
	displayBulkItemsDlg = false;
	stations: Stations[];

	@Input() set selectedFormingRecord(value: any) {
		if (value) {
			this.record = value['bpr'];
			//console.log(this.record);
			this.populateValues();
		} else {
			//console.log('Empty');
		}
	}

	@Input() set stationsList(value: any) {
		if (value) {
			this.stations = value;

		} else {
			this.stations = [];
		}
	}
	constructor() {

	}

	ngOnInit() {

	}
	populateValues() {
		this.currentStepBPRId = this.record.id;
		this.currentStepBPRStatus = this.record.activeStatus;
	}

	openBulkIemsDialog() {
		this.displayBulkItemsDlg = true;
	}
	closeBulkIemsDialog() {
		this.displayBulkItemsDlg = false;
	}

	printPage() {
		const printWindow = window.open('', '_blank');

		// Clone the sectiontoprint element
		const printContent = document.getElementById('sectiontoprint').cloneNode(true) as HTMLElement;

		// Remove the view buttons from the cloned content
		const viewButtons = printContent.getElementsByClassName('view-button');
		for (const button of Array.from(viewButtons)) {
			button.remove();
		}

		// Get the full explanation text
		const fullExplanation = this.record.explanation || ''; // If explanation is null, use an empty string

		// Update the clipped explanation element to display the full explanation text for printing
		const clippedExplanationElement = printContent.getElementsByClassName('value-col-explanation');
		if (clippedExplanationElement) {
			clippedExplanationElement[0].innerHTML = fullExplanation;
		}

		// Generate the print window content
		const printHTML = `
    <html>
      <head>
        <!-- ... -->
      </head>
      <body>
        ${printContent.outerHTML}
      </body>
    </html>
  `;

		// Write the content to the print window
		printWindow.document.open();
		printWindow.document.write(printHTML);
		printWindow.document.close();

		// Wait for the print window to load and then trigger printing
		printWindow.onload = () => {
			setTimeout(() => {
				printWindow.print();
				printWindow.close();
			}, 1000); // Adjust the timeout value as needed
		};
	}
	isStorageTempZero(): boolean {
		return +this.record.storageTemp === 0 || this.record.storageTemp.toString() === '0.00';
	}
	isStorageHumidityZero(): boolean {
		return (
			this.record.storageHumidity === undefined ||
			+this.record.storageHumidity === 0 ||
			this.record.storageHumidity.toString() === '0.00'
		);
	}
	isPackageTemperatureZero(): boolean {
		return (
			this.record.packageTemperature === undefined ||
			+this.record.packageTemperature === 0 ||
			this.record.packageTemperature.toString() === '0.00'
		);
	}
	isPrepStationCleanZero(): boolean {
		return (
			this.record.prepStationClean === undefined ||
			+this.record.prepStationClean === 0 ||
			this.record.prepStationClean.toString() === '0.00'
		);
	}

	isPrepApprovePrecDirZero(): boolean {
		return (
			this.record.prepApproveDirPrec === undefined ||
			+this.record.prepApproveDirPrec === 0 ||
			this.record.prepApproveDirPrec.toString() === '0.00'
		);
	}

	isPrepLabelImgVerifyZero(): boolean {
		return (
			this.record.prepLabelImgVerify === undefined ||
			+this.record.prepLabelImgVerify === 0 ||
			this.record.prepLabelImgVerify.toString() === '0.00'
		);
	}
	isPrepDosageImgVerifyZero(): boolean {
		return (
			this.record.prepDosageImgVerify === undefined ||
			+this.record.prepDosageImgVerify === 0 ||
			this.record.prepDosageImgVerify.toString() === '0.00'
		);
	}
	isDosesRequiredZero(): boolean {
		return (
			this.record.dosesRequired === undefined ||
			+this.record.dosesRequired === 0 ||
			this.record.dosesRequired.toString() === '0.00'
		);
	}

	isDosesSelectedZero(): boolean {
		return (
			this.record.dosesSelected === undefined ||
			+this.record.dosesSelected === 0 ||
			this.record.dosesSelected.toString() === '0.00'
		);
	}

	isDosesRemainingZero(): boolean {
		return (
			this.record.dosesRemaining === undefined ||
			+this.record.dosesRemaining === 0 ||
			this.record.dosesRemaining.toString() === '0.00'
		);
	}

	isPackageHumidityZero(): boolean {
		return (
			this.record.packageHumidity === undefined ||
			+this.record.packageHumidity === 0 ||
			this.record.packageHumidity.toString() === '0.00'
		);
	}

	isUnitsOrderedZero(): boolean {
		return (
			this.record.unitsOrdered === undefined ||
			+this.record.unitsOrdered === 0 ||
			this.record.unitsOrdered.toString() === '0.00'
		);
	}

	isDosesPerUnitZero(): boolean {
		return (
			this.record.dosesPerUnit === undefined ||
			+this.record.dosesPerUnit === 0 ||
			this.record.dosesPerUnit.toString() === '0.00'
		);
	}

	isDosesRemovedZero(): boolean {
		return (
			this.record.dosesRemoved === undefined ||
			+this.record.dosesRemoved === 0 ||
			this.record.dosesRemoved.toString() === '0.00'
		);
	}

	isLabelsRequiredZero(): boolean {
		return (
			this.record.labelsRequired === undefined ||
			+this.record.labelsRequired === 0 ||
			this.record.labelsRequired.toString() === '0.00'
		);
	}

	isVisualLabelMatchZero(): boolean {
		return (
			this.record.visualLabelMatch === undefined ||
			+this.record.visualLabelMatch === 0 ||
			this.record.visualLabelMatch.toString() === '0.00'
		);
	}

	isDosesATSActualZero(): boolean {
		return (
			this.record.dosesAtsActual === undefined ||
			+this.record.dosesAtsActual === 0 ||
			this.record.dosesAtsActual.toString() === '0.00' ||
			this.record.dosesAtsActual.toString() === '0'
		);
	}

	isDosesVarianceZero(): boolean {
		return (
			this.record.dosesVariance === undefined ||
			+this.record.dosesVariance === 0 ||
			this.record.dosesVariance.toString() === '0.00'
		);
	}

	isExplanationEmpty(): boolean {
		return this.record.explanation === null || this.record.explanation.trim() === '';
	}

	isItemNumberValidationZero(): boolean {
		return (
			this.record.itemNumberValidation === undefined ||
			+this.record.itemNumberValidation === 0 ||
			this.record.itemNumberValidation.toString() === '0.00'
		);
	}

	isSeqNumberRangeVerifyZero(): boolean {
		return (
			this.record.seqNumberRangeVerify === undefined ||
			+this.record.seqNumberRangeVerify === 0 ||
			this.record.seqNumberRangeVerify.toString() === '0.00'
		);
	}

	isReleaseApprovalZero(): boolean {
		return (
			this.record.releaseApproval === undefined ||
			+this.record.releaseApproval === 0 ||
			this.record.releaseApproval.toString() === '0.00'
		);
	}

	isVerifiedUnitsZero(): boolean {
		return (
			this.record.verifiedUnits === undefined ||
			+this.record.verifiedUnits === 0 ||
			this.record.verifiedUnits.toString() === '0.00'
		);
	}

	isApproveToShipZero(): boolean {
		return (
			this.record.approveToShip === undefined ||
			+this.record.approveToShip === 0 ||
			this.record.approveToShip.toString() === '0.00'
		);
	}

	isShippingCompletedZero(): boolean {
		return (
			this.record.shippingCompleted === undefined ||
			+this.record.shippingCompleted === 0 ||
			this.record.shippingCompleted.toString() === '0.00'
		);
	}

	isStepHighlighted(stepNumber: number): boolean {
		return this.record.currentStep === stepNumber;
	}

	getYesNo(value: number): string {
		return value === 0 ? 'No' : 'Yes';
	}

	getStationName(value: number): string {
		if (!this.stations) {
			return ''; // Return an empty string if stations array is undefined
		}

		const station = this.stations.find(station => station.id === value);
		return station ? station.handle : ''; // Return the station description if found, otherwise an empty string
	}
	getClippedExplanation(str: string): string {

		if(str === null) {
			return '';
		}
		if (str.length <= 10) {
			return str;
		}
		return str.substr(0, 10) + '...';
	}

}
