import { Component, OnInit, Input } from '@angular/core';
import { DwUtils } from 'src/app/components/utils/dw-utils';
import { FormularyMedication } from 'src/app/models/formulary-medication';
import { PrecautionDirection } from 'src/app/models/precautiondirection';
import { FormularyService } from 'src/app/services/formulary.service';
import { MedicationService } from 'src/app/services/medication.service';
import { Settings } from 'src/app/services/settings';
import * as string_decoder from 'string_decoder';

@Component({
	selector: 'app-medication-properties',
	templateUrl: './medication-properties.component.html',
	styleUrls: ['./medication-properties.component.css']
})
export class MedicationPropertiesComponent implements OnInit {

	isEditing = false;
	_medicationFamilyId: any;
	medicationDirPre: PrecautionDirection;
	defaultMedicationDirPre: PrecautionDirection;
	viewClass = 'main-content-area';
	fieldClass = 'properties-field';

	valPrecautions: string;
	valAltPrecautions: string;
	valDirections: string;
	valAltDirections: string;
	_view = '';
	_formularyMedicationId: number;
	_formularyMedication: FormularyMedication;

	@Input() set MedicationFamilyId(value: any) {
		this.clear;
		if (value && value !== 0) {
			this._medicationFamilyId = value;
		}
		if (this._view) {
			this.queryRecords();
		}
	}
	@Input() set View(value: any) {
		this._view = value;
		if (this._view === 'Formulary') {
			this.viewClass = 'main-content-area-window-formulary';
			this.fieldClass = 'properties-field-window-formulary';
		} if (this._view === 'MedicationFamily') {
			this.viewClass = 'main-content-area';
			this.fieldClass = 'properties-field';
		}

		if (this._medicationFamilyId || this._formularyMedicationId) {
			this.queryRecords();
		}
	}
	@Input() set FormularyMedicationId(value: any) {
		this._formularyMedicationId = value;
		if (this._view) {
			this.queryRecords();
		}
	}

	constructor(private medicationService: MedicationService, private formularyService: FormularyService,) {

	}
	ngOnInit() {

	}

	queryRecords() {
		if (!this._view) {
			return;
		}
		if (this._view === 'Formulary' && (!this._formularyMedicationId || !this._medicationFamilyId)) {
			return;
		}
		if (this._view === 'MedicationFamily' && !this._medicationFamilyId) {
			return;
		}
		this.medicationService.getMedicationDirectionsPrecautionsByMedicationFamilyId(this._medicationFamilyId).subscribe(
			(result) => {
				if (result === undefined || result === null) {
					return;
				}
				if (this._view === 'MedicationFamily') {
					this.medicationDirPre = result[0];
					this.updateDisplay();
				} else if (this._view === 'Formulary') {
					this.defaultMedicationDirPre = result[0];
					this.formularyService.getFormularyMedication(this._formularyMedicationId).subscribe(  // Need to fetch formulary specifuc directions and precautions
						(result: FormularyMedication) => {
							if (result === undefined || result === null) {
								return;
							}
							this._formularyMedication = result;
							this.valDirections = result.directions;
							this.valAltDirections = result.altDirections;

							this.valPrecautions = result.precautions;
							this.valAltPrecautions = result.altPrecautions;
						},
						(error) => {
						}
					);
				}
			},
			(error) => {
			}
		);

	}
	clear() {
		this.valPrecautions = '';
		this.valAltPrecautions = '';
		this.valDirections = '';
		this.valAltDirections = '';
	}
	updateDisplay() {
		if (this.medicationDirPre === undefined) {
			this.clear();
			return;
		}

		this.valPrecautions = this.medicationDirPre.precautions;
		this.valAltPrecautions = this.medicationDirPre.altPrecautions;
		this.valDirections = this.medicationDirPre.directions;
		this.valAltDirections = this.medicationDirPre.altDirections;
	}
	editClickHandler() {
		this.isEditing = true;
		DwUtils.setFocus('precautions');
		Settings.getInstance().isEditing = true;
	}
	saveClickHandler() {
		if (this._view === 'MedicationFamily') {
			if (this.medicationDirPre === undefined) {
				this.medicationDirPre = new PrecautionDirection();
				this.medicationDirPre.id = 0;
			}
			if (this.medicationDirPre.id === undefined) {
				this.medicationDirPre.id = 0;
			}
			this.medicationDirPre.precautions = this.valPrecautions.toUpperCase();
			this.medicationDirPre.altPrecautions = this.valAltPrecautions.toUpperCase();
			this.medicationDirPre.directions = this.valDirections.toUpperCase();
			this.medicationDirPre.altDirections = this.valAltDirections.toUpperCase();
			this.medicationDirPre.active = 1;
			this.medicationDirPre.medicationFamilyId = this._medicationFamilyId;
			delete this.medicationDirPre['medicationFamily'];
			if (this.medicationDirPre.id === 0) {
				this.medicationService.createMedicationDirectionsPrecaution(this.medicationDirPre).subscribe(
					(result) => {
						this.isEditing = false;
						Settings.getInstance().isEditing = false;;
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			} else {
				this.medicationService.updateMedicationDirectionsPrecaution(this.medicationDirPre).subscribe(
					(result) => {
						this.isEditing = false;
						Settings.getInstance().isEditing = false;;
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		} else if (this._view === 'Formulary') {
			/***  Note we need to remove the two elements from the formularyMedication record ***/
			/***  So it will insert/ update correctly! - we'll add them back afterwards in the ***/
			/***  proper event ***/

			const medDescription = this._formularyMedication['medicationDescription'];
			const itemNumber = this._formularyMedication['itemNumber'];

			this._formularyMedication.directions = this.valDirections.toUpperCase();
			this._formularyMedication.altDirections = this.valAltDirections.toUpperCase();

			this._formularyMedication.precautions = this.valPrecautions.toUpperCase();
			this._formularyMedication.altPrecautions = this.valAltPrecautions.toUpperCase();

			delete this._formularyMedication['customerFormulary'];
			delete this._formularyMedication['medicationDescription'];
			delete this._formularyMedication['itemNumber'];

			this.formularyService.updateFormularyMedication(this._formularyMedication).subscribe(
				(data2) => {
					this.isEditing = false;
					this._formularyMedication['medicationDescription'] = medDescription;
					this._formularyMedication['itemNumber'] = itemNumber;
					Settings.getInstance().isEditing = false;
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		}
	}
	cancelClickHandler() {
		this.isEditing = false;
		Settings.getInstance().isEditing = false;;
		if (this._view === 'MedicationFamily') {
			this.updateDisplay();
		}
		else if (this._view === 'Formulary') {
			this.valDirections = this._formularyMedication.directions;
			this.valAltDirections = this._formularyMedication.altDirections;

			this.valPrecautions = this._formularyMedication.precautions;
			this.valAltPrecautions = this._formularyMedication.altPrecautions;
		}

	}
	defaultDirectionsClicked() {
		this.valDirections = this.defaultMedicationDirPre.directions;
	}
	defaultAltDirectionsClicked() {
		this.valAltDirections = this.defaultMedicationDirPre.altDirections;
	}
	defaultPrecautionsClicked() {
		this.valPrecautions = this.defaultMedicationDirPre.precautions;
	}
	defaultAltPrecautionsClicked() {
		this.valAltPrecautions = this.defaultMedicationDirPre.altPrecautions;
	}

}
