import { Component, OnInit, Input, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { SupplierMedication } from 'src/app/models/supplier-medication';
import { MedicationService } from 'src/app/services/medication.service';
import { Settings } from 'src/app/services/settings';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-medication-suppliers',
	templateUrl: './medication-suppliers.component.html',
	styleUrls: ['./medication-suppliers.component.css']
})
export class MedicationSuppliersComponent {

	suppliersList: any[];
	openSuppliersList: any[];
	medicationSuppliers: SupplierMedication[];
	displayedColumns: string[];
	displayedColumnsEditable: string[];
	selectedRecord: SupplierMedication;
	Idx = -1;

	isEditing = false;
	_medication: any;
	selectedSupplierMedication: SupplierMedication;
	pagination: string;

	renderedData = Array<SupplierMedication>();
	dataSource: MatTableDataSource<SupplierMedication>;

	BASE_URL = Settings.getInstance().BASE_URL;
	isAdd = false;
	isEdit = true;
	displayAddEditSupplier = false;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('table', { static: false }) table;

	get Medication(): any {
		return this._medication;
	}
	@Input() set Medication(value: any) {
		this._medication = value;
		this.queryRecords();
	}

	constructor(private medicationService: MedicationService, private suppliersService: SuppliersService, private dialog: MatDialog) {
		this.displayedColumns = ['supplierName', 'costPerDose', 'leadTimeDays', 'lastUpdated', 'delete'];
		this.displayedColumnsEditable = ['supplierName', 'costPerDose', 'leadTimeDays', 'delete'];
	}

	queryRecords() {
		if (this._medication === undefined || this._medication.id === undefined) {
			return;
		}
		this.suppliersService.getAllSuppliers().subscribe(
			(data) => {
				this.suppliersList = data;
				this.medicationService.getMedicationSuppliersByMedicationId(this._medication.id).subscribe(
					(result) => {
						if (result === undefined || result.length === undefined) {
							return;
						}
						this.medicationSuppliers = result;
						for (const rec of this.medicationSuppliers) {
							for (const supplier of this.suppliersList) {
								if (rec.supplierId === supplier.id) {
									rec.supplierName = supplier.companyName;
								}
							}
						}
						this.reviseOpenSuppliersList();
						this.dataSource = new MatTableDataSource(this.medicationSuppliers);
						this.dataSource.sort = this.sort;
						this.setPagination(-1, this.dataSource.data.length);
					},
					(error) => {
					}
				);

			},
			(error) => {
				Settings.getInstance().handleError(error, 'Error: unable to retrieve data from the service');
			}
		);
	}
	
	selectRow() {
		if (this.selectedRecord === undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = undefined;
		this.setPagination(this.Idx, this.dataSource.data.length);
	}
	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}
		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
			}
		}
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}

	reviseOpenSuppliersList() {
		this.openSuppliersList = [];
		{
			for (const rec of this.suppliersList) {
				let found = false;
				for (const mapping of this.medicationSuppliers) {
					if (rec.id === mapping.supplierId && mapping.medicationId === this._medication.id) {
						found = true;
						break;
					}
				}
				if (!found) {
					this.openSuppliersList.push(rec);
				}
			}
		}
	}
	deleteSupplierMedication(medSupplierMedication: SupplierMedication) {
		const message = 'Remove this Supplier - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {

				this.medicationService.deleteMedicationonSupplier(medSupplierMedication.id).subscribe(
					(data) => {
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}

	lastUpdatedLabeler(data): string {
		if (data === null || data === undefined)
			return 'NA';
		let d = new Date(parseInt(data, 10));
		const ds = d.toString();
		return d.toLocaleDateString();
	}
	
	addRecord(){
		this.selectedRecord = undefined;
		this.showDialogAddEditSupplier();
	}

	showDialogAddEditSupplier() {
		this.displayAddEditSupplier = true;
	}
	cancelClickedFromAddeditSupplier() {
		this.displayAddEditSupplier = false;
	}
	submitClickedFromAddeditSupplier() {
		this.displayAddEditSupplier = false;
		this.queryRecords();
	}
	onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
	}
}
