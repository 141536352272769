<mat-drawer-container class="mat-elevation-z2 settings-container">
	<mat-drawer disableClose="true" mode="side" opened style="width:165px;">
		<mat-action-list>
		  <span><span class="highlight-selection" *ngIf="visibleUserManagement">|</span><button #UserLinkBtn *ngIf="visibleUserLink" matTooltipPosition="right" matTooltip="Manage users and access" class="button-menu" mat-list-item (click)="showUserManagement()"  > User Management </button></span>
		  <span><span class="highlight-selection" *ngIf="visibleGroupManagement">|</span><button #GroupLinkBtn *ngIf="visibleGroupLink" matTooltipPosition="right" matTooltip="Define and maintain security groups" class="button-menu" mat-list-item (click)="showGroupManagement()" > Group Management </button></span>
		  <hr width="90%">
		  <span><span class="highlight-selection" *ngIf="visibleEmailSettings">|</span><button #EmailLinkBtn *ngIf="visibleEmailLink" matTooltipPosition="right" matTooltip="Manage E-mail settings" class="button-menu" mat-list-item (click)="showEmailSettings()" > E-mail </button></span>
		  <span><span class="highlight-selection" *ngIf="visibleGeneralSettings">|</span><button #GeneralLinkBtn *ngIf="visibleGeneralLink" matTooltipPosition="right" matTooltip="Manage General system settings" class="button-menu" mat-list-item (click)="showGeneralSettings()" > General Settings </button></span>
		</mat-action-list>
	</mat-drawer>

	<mat-drawer-content class="mat-drawer-content-container">
	
		<app-usermanagement *ngIf="visibleUserManagement===true"> </app-usermanagement>
		<app-groupmanagement *ngIf="visibleGroupManagement===true"> </app-groupmanagement>
		<app-generalsettings *ngIf="visibleGeneralSettings===true"> </app-generalsettings>
		<app-project-settings *ngIf="visibleProjectsettings===true"> </app-project-settings>
		
		<app-organizations *ngIf="visibleOrganizations===true"> </app-organizations>
		<app-propertydefinitions *ngIf="visibleProperties===true"> </app-propertydefinitions>
		<app-emailsettings *ngIf="visibleEmailSettings===true"> </app-emailsettings>
	
	</mat-drawer-content>
</mat-drawer-container>
