<!--
    file type .....: HTML file: add-contact.component.html
    Created By ....: ianday
    Date ..........: 2/24/23
-->
<div class="add-contact-container">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;"
             (keyup)="this.strErr = ''">

            <!----------------------->
            <!-- Full Name        --->
            <!----------------------->
            <div class="dwform-row">
                <label class="label-field">Full Name:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" tabindex="0" id="fullName"
                       [(ngModel)]="fullName"
                       style="text-transform: capitalize"
                       placeholder="<Full Name>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Position / Title --->
            <!----------------------->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Position / Title:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" tabindex="1" id="positionTitle"
                       [(ngModel)]="positionTitle"
                       style="text-transform: capitalize"
                       placeholder="<Position / Title>"
                       type="text">
            </div>
            <!-- Telephone --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Telephone</label>
                <span class="form-required"></span>
                <p-inputMask class="input-field" tabindex="2"  id="telephone"
                       mask="(999) 999-9999"
                       [(ngModel)]="telephone" placeholder="<Telephone>"
                       type="text">
                    </p-inputMask>
            </div>

            <!-- Cell Phone --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Cell Phone:</label>
                <span class="form-required"></span>
                <p-inputMask class="input-field" tabindex="3" id="cellPhone" mask="(999) 999-9999"
                             placeholder="<Cell Phone>"
                       [(ngModel)]="cellPhone">
                </p-inputMask>
            </div>

            <!-- Email Address  --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Email Address:</label>
                <span class="form-required"></span>
                <input class="input-field" tabindex="4"  id="emailAddress"
                       [(ngModel)]="emailAddress"
                       placeholder="<Email Address>"
                       type="text">
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button" tabindex="5"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button" tabindex="6"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
