<!------------------------------------->
<!-- List tab detail layout.
 * File Name ..: medication-family.component.html
 * Generated by: Toji Mathew
 * Date .......: 3/21/2023
--------------------------------------->
<div class="medication-family-form-container">
    <div class="query-strip-container mat-elevation-z2">
    <table height="100%">
        <TR>
            <TD class="table-title-cell">
                <i class="fa fa-medkit fa-fw table-icon-header"
                   style="width: 2em"></i>{{formTitle}}
            </TD>
            
            <TD align="center" style="min-width: 400px;">
                <input text id="txtFilter" #txtFilter
                       class="qs_filter-field" [disabled]="this.isEditing"
                       (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                <button mat-button matSuffix mat-icon-button
                        matTooltip="Clear the filter" aria-label="Clear"
                        (click)="txtFilter.value=''; clearFilter();">
                    <mat-icon>close</mat-icon>
                </button>
             </TD>
            <TD class="qs-label-cell" style="width: 60px; text-align: center;"><!-- Status: --></TD>
            <TD class="table-pagination-cell" align="right">{{pagination}} Record(s) ...</TD>
        </TR>
    </table>
</div>

<mat-drawer-container autosize >
    <!-------------------------->
    <!-- Right hand drawer    -->
    <!-- Open state           -->
    <!-------------------------->
    <mat-drawer position="end" opened="true" class="mat-elevation-z2"
                style="border: .5px solid #e1e1e1" #drawer
             mode="side" disableClose="true">
        <div style="max-width: 550px; padding: 2px;"  >

            <table [hidden]="!propContainerState"
                   class="prop-notes-outer-container">
                <TR>
                    <TD align="left" style="width: 35px; padding: 0;">
                        <button id="medication-family-toggle-drawer-close-btn"  mat-icon-button
                            (click)="onPropertiesToggle()">
                            <mat-icon>arrow_right</mat-icon>
                        </button>
                    </TD>
                    <TD width="100%" style="text-align: left;">
                        <b>Additional Properties</b>
                    </TD>
                    <TD width="35px"></TD>
                </TR>
            </table>
            <div [hidden]="!propContainerState"
                 class="prop-menu-container" style="width:545px;">
                 <mat-tab-group class="prop-tab-group" style="height: calc(100% - 2px)">
                 	<mat-tab
						label="Properties"> <app-medication-properties
						[MedicationFamilyId]="selectedRecord.id" [View]="'MedicationFamily'"></app-medication-properties> 
					</mat-tab> 
					 <mat-tab label="Ingredients" *ngIf="visibleNotesTab">
                         <app-medication-ingredients style="border-top: .5px solid #e1e1e1; "
                             [Medication]="selectedRecord" [View]="'MedicationFamily'"> 
                         </app-medication-ingredients>
                    </mat-tab>
                    <mat-tab label="Notes" *ngIf="visibleNotesTab">
                         <app-notes style="border-top: .5px solid #e1e1e1; "
                              [callingComponent]="'medication-family'"
                              [parentId]="selectedRecord?.id">
                         </app-notes>
                    </mat-tab>
                </mat-tab-group>
              </div>
           </div>
        <div>
        <!----------------------->
        <!-- Right hand Drawer -->
        <!-- retracted state ---->
        <!----------------------->
        <div [hidden]="propContainerState" width="100%">
            <button id="medication-family-toggle-drawer-open-btn" mat-icon-button (click)="onPropertiesToggle()" >
                <mat-icon>arrow_left</mat-icon>
            </button>
        </div>

        <div class="rotate-text" [hidden]="propContainerState">
            Additional Properties
        </div>
      </div>
    </mat-drawer>
        <!--------------------------->
        <!-- RECORDS TABLE         -->
        <!--------------------------->
        <div #tb1 [ngClass]="contentClassName" class="table-container mat-elevation-z1"
            (keydown)="tableKeyDown($event)" tabindex="-1">
            <table mat-table [dataSource]="dataSource" matSort
                (matSortChange)="onSortChange($event)">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on
                  the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style">Id</th>
                    <td mat-cell style="width:8% !important;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.id}}</td>
                </ng-container>

                <ng-container matColumnDef="itemPrefix" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style">Item Prefix</th>
                    <td mat-cell style="width:10% !important;"
                        *matCellDef="let element">{{element.itemPrefix}}</td>
                </ng-container>

                <ng-container matColumnDef="description" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style">Description</th>
                    <td mat-cell style="width:40% !important;"
                        *matCellDef="let element">{{element.description}}</td>
                </ng-container>

                <ng-container matColumnDef="invWarningThreshold" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style">Threshold Level</th>
                    <td mat-cell style="width:20% !important;text-align: left;"
                        *matCellDef="let element">{{element.invWarningThreshold}}%</td>
                </ng-container>

                <ng-container matColumnDef="expirationGraceDays" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="mat-table-header-style">Grace Days</th>
                    <td mat-cell style="width:20% !important;"
                        *matCellDef="let element">{{element.expirationGraceDays}} day(s)</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                    [ngClass]="{'row-selected': this.selectedRecord.id == row.id}"
                    (click)="onRowClicked(row)">
                </tr>
            </table>
        </div>
        <!-------------------------------------->
        <!-- Control Strip                    -->
        <!-------------------------------------->
        <div class="mat-elevation-z2 control-strip">
            <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
                    matTooltipPosition="above"
                    class="command-button" matTooltip="Add a new record"
                    (click)="addModifyRecord(true)" mat-button>
                <mat-icon>add_circle_outline</mat-icon>
                Add New
            </button>
            <button *ngIf="visibleModify" mat-button class="command-button"
                    style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
                    matTooltipPosition="above"
                    matTooltip="Update a selected record" (click)="addModifyRecord(false)"
                    mat-button>
                <mat-icon>edit</mat-icon>
                Update
            </button>
        </div>
        <!------------------------------->
        <!--  Detail panel             -->
        <!------------------------------->
        <div id="medication-family-details-panel"
             class="medication-family-detail-container mat-elevation-z2"  >
          <mat-tab-group animationDuration="0ms" (selectedTabChange)="doTabChange($event)"
                            [(selectedIndex)]=selectedTabIndex>
                <mat-tab label="Details" [disabled]="isEditing">
                    <div class="sub-tab-container-class detail-tab-additional">
                        <div class="dwform" style="padding-right: 15px;padding-left: 5px;">

                            <div class="dwform-cell-nopadding" style="min-width: 500px;">
                                <div class="dwform-row-double-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field">ID:</label> <span
                                        class="form-required"> </span>
                                    <input class="input-field-short" style="width:80px" disabled="true"
                                           [(ngModel)]="id"
                                           type="text">
                                </div>
                                <div class="dwform-row-gap"></div>
  								<div class="dwform-row">
                                    <label class="label-field">Item Prefix:</label>
                                    <span class="form-required">*</span>
                                    <input id="itemPrefix" class="input-field" style="width:150px" [disabled]="!isEditing"
                                           [(ngModel)]="itemPrefix" (keyup)="addLeadingZero($event)"
                                           placeholder="<Item Prefix>"
                                           type="number" maxlength="4">
								</div>
								<div class="dwform-row-gap"></div>
                                <div class="dwform-row">
                                <label class="label-field">Description:</label>
                                <span class="form-required">*</span>
                                <textarea id="description" class="text-area-input text-area-override"

                                       [disabled]="!isEditing"
                                       [(ngModel)]="description"
                                       placeholder="<Description>"
                                       type="text">
                                    </textarea>
                                </div>
                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field">Threshold Level:</label>
                                    <span class="form-required">*</span>
                                    <input id="inv_warning_threshold" class="input-field-shortest"
                                           style="width:40px;"
                                           [disabled]="!isEditing"
                                           matTooltip="Show a warning when inventory reaches this level"
                                           [(ngModel)]="invWarningThreshold" maxlength="60"
                                           placeholder="<Number>"
                                           type="number" max="100" min="0"><span>%</span>
                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Grace Days:</label>
                                    <span class="form-required">*</span>
                                    <input id="expiration_grace_days" class="input-field-shortest"
                                           style="width:40px;"
                                           matTooltip="Disallow medication when this number of days from the expiration date is reached"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="expirationGraceDays" maxlength="60"
                                           placeholder="<Number>"
                                           type="number" max="100" min="0">
                                </div>
                                <div class="dwform-row-gap"></div>
                            </div>
                        </div>
                        <!----------------------->
                        <!-- ERROR LINE --------->
                        <!----------------------->
                        <div class="error-line" [hidden]='!strErr.length'
                            style="text-align:left">{{strErr}}</div>
                        <!----------------------->
                        <!-- SAVE STRIP        -->
                        <!----------------------->
                        <div class="save-close-strip" style="bottom: 3px;">

                            <button mat-button class="command-button" *ngIf="this.isEditing"
                                    (click)="submitRecord()" matTooltip="Submit the selected record"
                                    mat-button>
                                <mat-icon>check_circle_outline</mat-icon>
                                Submit
                            </button>
                            <button mat-button class="command-button" *ngIf="this.isEditing"
                                    (click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
                                <mat-icon>highlight_off</mat-icon>
                                Cancel
                            </button>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="History" [disabled]="isEditing">
                    <div class="sub-tab-container-class detail-tab-additional">
                       <transaction-history #history> </transaction-history>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-drawer-container>
</div>
