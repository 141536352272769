/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: suppliers-inv.components.ts
 * Generated By : ianday on 2/17/23
 * Date ........: 2/17/23
 /************/
import {Component, ElementRef, HostListener, OnInit, ViewChild, AfterContentInit} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import {Settings} from '../../../services/settings';
import { SuppliersService } from '../../../services/suppliers.service';
import {Suppliers} from '../../../models/suppliers';
import {ValidationResult} from '../../../models/validation-result';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {MatSort} from '@angular/material/sort';
import { TransactionHistoryService} from '../../../services/transactionhistory.service';
import { TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import { DwUtils } from '../../../components/utils/dw-utils';

@Component({
    selector: 'app-suppliers-inv',
    templateUrl: './suppliers-inv.component.html',
    styleUrls: ['./suppliers-inv.component.css']

})
export class SuppliersInvComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'Suppliers';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: Suppliers[];
    public selectedRecord: Suppliers;
    public historyRecord: Suppliers;
    public Idx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Suppliers>;
    public renderedData: Array<any>;
    public disabledFlag: boolean | null = true;
    public result: boolean | null = false;
    public activeCellId: string | null = '';

    /**** Query strip filter settings ***/
    statusFilterList = [];
    selectedStatusFilter: any;
    statusFilterIdx: number | null = -1;

    public selectedViewFilter: string | null = 'List';
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['id', 'companyName', 'webSiteAddress', 'supplierType', 'activeStatus'];
    public displayedColumnsCard: string[] = ['id'];

    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: any | null = '';
    public companyName: any | null = '';
    public logoImage: any | null = '';
    public webSiteAddress: any | null = '';
    public supplierType: any | null = '';
    public activeStatus: any | null = '';

    /***** Side drawer ****/
    public propContainerState: boolean | null = false;               // flip to false when finished.
    public visiblePropertiesTab: boolean | null = true;
    public visibleNotesTab: boolean | null = true;

    @ViewChild(MatSort, { static: false }) sort: MatSort ;
    @ViewChild('history', { static: true }) history: TransactionHistoryComponent;

    constructor(private suppliersService: SuppliersService, private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Suppliers();
        this.statusFilterList = new Array();
        this.statusFilterList.push({id: 'Active', description: 'Active'});
        this.statusFilterList.push({id: 'InActive', description: 'InActive'});
        this.statusFilterList.push({id: 'OnHold', description: 'On Hold'});
        this.statusFilterList.push({id: 'All', description: 'All Items'});
        this.selectedStatusFilter = this.statusFilterList[0];
        this.statusFilterIdx = 0;
    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = false;             // for this implementation going to hide delete.
    }
    ngAfterContentInit() {

        this.queryRecords();

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
    }

    /*** Query Records from the Service ***/
    queryRecords() {
        this.suppliersService.getAllSuppliers().subscribe(
            (result) => {
                this.records = result;
                this.dataSource = new MatTableDataSource(this.records);
                this.dataSource.sort = this.sort;

                this.onChangeStatusFilterValue(this.selectedStatusFilter);
                this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    public onStatusFilterKeyDown(event: any): void {
        const len: number = this.statusFilterList.length;
        if (event.key === 'ArrowDown') {
            if (this.statusFilterIdx < (len - 1)) {
                this.selectedStatusFilter = this.statusFilterList[++this.statusFilterIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.statusFilterIdx > 0) {
                this.selectedStatusFilter = this.statusFilterList[--this.statusFilterIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedStatusFilter = this.statusFilterList[this.statusFilterIdx];
            this.onChangeStatusFilterValue(this.selectedStatusFilter);
        }
    }
    /*** Display the number of records from the total ***/
    setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }

    onChangeStatusFilterValue(event: any) {

        const dataTemp: Suppliers[] = [];

        if (event.value !== undefined) {
            this.selectedStatusFilter = event.value;
        }
        this.clearFilter();

        for (const rec of this.records) {
            if (rec.activeStatus === this.selectedStatusFilter.id || this.selectedStatusFilter.id === 'All') {
                dataTemp.push(rec);
            }
        }
        this.dataSource = new MatTableDataSource(dataTemp);
        this.setPagination(-1, this.dataSource.filteredData.length);
    }
    onStatusChange() {

         this.suppliersService.updateSuppliers(this.selectedRecord).subscribe(
            (data) => {
                // no user feedback, just apply the filter ...
                this.onChangeStatusFilterValue(this.selectedStatusFilter);
            });
    }
    doTabChange(event): void {

    }
    @HostListener('matSortChange', ['$event'])
    onSortChange(event) {

        // this.dataSource.sort = this.sort;

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        }
        else
        {
            this.records.sort ((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
        // console.log(e);
    }
    clearFilter() {

        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
        this.dataSource.filter = '';
        this.selectRow();
        this.setPagination(-1, this.dataSource.data.length);
    }

    applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    selectRow() {

        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);

    }
    unSelectRow() {
        /* if (this.isEditing) return; */
        this.Idx = -1;
        this.selectedRecord = new Suppliers();
        this.setPagination(this.Idx, this.dataSource.data.length);
        this.displayDetail(this.selectedRecord);

    }
    displayDetail(record: Suppliers) {

        if (record === undefined || record.id === 0) {
            return;
        }

        this.id = record.id;
        this.companyName = record.companyName;
        this.webSiteAddress = record.webSiteAddress;
        this.supplierType = record.supplierType;
        this.activeStatus = record.activeStatus;

        this.history.onShowHandler('suppliers', 'id', record.id.toString());
    }

    clear() {
        this.id = '';
        this.companyName = '';
        this.webSiteAddress = '';
        this.logoImage = '';
        this.supplierType = '';
        this.activeStatus = '';
    }
    setFocus(element: string) {

        setTimeout(() => {
            document.getElementById(element).focus();
        });
    }
    addModifyRecord(isAdd: boolean) {
        this.isAdd = isAdd;
        this.isEditing = true;
        this.selectedTabIndex = 0;
        if (this.isAdd) {
            this.clear();
            this.id = 0;
            this.activeStatus = 'Active';

        }
        else {
            // store the history record
            this.historyRecord = this.selectedRecord;
        }
        this.setFocus('companyName');
    }

    removeRecord() {
        const message = 'Remove Company: "' + this.selectedRecord.companyName + '" - Are you sure?';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            if (this.result === true) {
                this.suppliersService.deleteSuppliers(this.selectedRecord.id).subscribe(
                    (data) => {
                        // remove the row from the table.

                        this.records.splice(this.Idx, 1);
                        this.dataSource.data = this.records;
                        this.clear();
                    },
                    (error) => {
                        Settings.getInstance().handleError(error);
                    }
                );
            }
        });
    }

    onPropertiesToggle() {
        this.propContainerState = !this.propContainerState;
    }

    tableKeyDown(event: KeyboardEvent) {

        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }

    validateFields(): boolean {

        this.strErr = '';
        const vr: ValidationResult = this.selectedRecord.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            this.setFocus(vr.focus_field);
            return false;
        }
        return true;
    }

    submitRecord() {

        this.selectedRecord = new Suppliers();
        this.selectedRecord.id = this.id;
        this.selectedRecord.companyName = this.companyName;
        this.selectedRecord.logoImage = this.logoImage;
        this.selectedRecord.webSiteAddress = DwUtils.checkWWWAddress(this.webSiteAddress);
        this.selectedRecord.supplierType = this.supplierType;
        this.selectedRecord.activeStatus = this.activeStatus;
        this.strErr = '';

        if (!this.validateFields()) {
            return;
        }
        if (this.isAdd === true ) {
            this.suppliersService.createSuppliers(this.selectedRecord).subscribe (
                (data) => {
                    this.selectedRecord.id = data.identifiers[0].id;
                    this.isEditing = false;
                    /*** add a transaction history record ***/
                    this.txService.createTransactionHistory('suppliers', this.selectedRecord.id);
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Adding a new supplier record');
                    return;
                }
            );
        }
        else {

          this.suppliersService.updateSuppliers(this.selectedRecord).subscribe(
              (data) => {
                  this.isEditing = false;
              },
              (error) => {
                  this.strErr = 'Error: The server could not update this record';
                  this.selectedRecord = this.historyRecord;
                  return;
              }
          );

            /*** add a transaction history record ***/
          this.txService.updateTransactionHistory(this.historyRecord,
                this.selectedRecord, 'suppliers', this.selectedRecord.id);
        }

        this.updateSelectedRow();
    }

    updateSelectedRow(): void
    {
        if (this.isAdd === true) {
            // add the new record to the top.
            this.records.unshift(this.selectedRecord);
            this.dataSource.data = this.records;
            this.Idx = 0;
        }
        else {
            const i: number = this.records.findIndex(r => r.id === this.selectedRecord.id);
            this.records[i] = this.selectedRecord;
            this.dataSource.data = this.records;

        }
        this.dataSource.sort = this.sort;
       // this.onChangeStatusFilterValue();
    }
    onRowClicked(selected: any) {

        if (this.isEditing === true) {
            return;
        }

        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
        this.displayDetail(this.selectedRecord);
    }

    cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
        this.displayDetail(this.selectedRecord);
    }
}
