export interface CustomerFormulary {
	id: number;
	name: string;
	activeStatus: number | null;
	defaultFlag: number | null;
	customerId: number;
}

export class CustomerFormulary {

	constructor() {
		this.id = 0;
	}
}
