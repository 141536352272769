<div #topDiv>
	<div class="ui-g" *ngFor="let param of ParamSpecs; let i = index" >
		<div class="ui-g-5 ui-md-5" style="padding-top:17px;">{{param.label}}<label style="text-align: right" class="required" *ngIf="param.name==='UserUid' && param.isRequired===true"></label></div>
		<div class="ui-g-7 ui-md-7" [ngSwitch]="param.name">
			<ng-container *ngSwitchCase="'UserUid'">
				<div class="ui-g-8 ui-md-8">					
					<label style="font-weight: bold">{{valResourceFullName}}</label>
				</div>
				<div class="ui-g-4 ui-md-4">
					<button type="button" pButton label="Select" (click)="showDialogEmployee()" [disabled]="formDisableFlag" style="width: 70px" ></button>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'OrganizationId'">
				<div class="ui-g-8 ui-md-8">					
					<label style="font-weight: bold">{{strSelectedOrganizationName}}</label>
					&nbsp;&nbsp;<button *ngIf="strSelectedOrganizationName !== '' " class="link-button" (click)="clearOrganizationSelection()">X</button>
				</div>
				<div class="ui-g-4 ui-md-4">
					<button type="button" pButton label="Select" (click)="showDialogOrganization()" [disabled]="formDisableFlag" style="width: 70px" ></button>
				</div>
			</ng-container>
			<!-- <label class="required" *ngIf="param.IsRequired===true && param.Name!='SkipTeamTotal'"></label> -->
			<!-- <ng-container *ngSwitchCase="'StartDate'"><dw-calendar #startDate [hideOnDateTimeSelect]="true" [(ngModel)]="valStartDate" [inputStyle]="{'width':'100px'}" [showIcon]="true" [showOnFocus]="false" [disabled]="formDisableFlag" [appendTo]="parent" [placeholder]="'MM/DD/YYYY'"></dw-calendar></ng-container>  -->
			<ng-container *ngSwitchCase="'StartDate'"><p-calendar #startDate [(ngModel)]="valStartDate" [inputStyle]="{'width':'100px'}" [showIcon]="true" [showOnFocus]="false" [disabled]="formDisableFlag" [appendTo]="parent" [placeholder]="'MM/DD/YYYY'"></p-calendar></ng-container>
			<!--  <ng-container *ngSwitchCase="'EndDate'"><dw-calendar #endDate [hideOnDateTimeSelect]="true" [(ngModel)]="valEndDate" [inputStyle]="{'width':'100px'}" [showIcon]="true" [showOnFocus]="false" [disabled]="formDisableFlag" [appendTo]="parent" [placeholder]="'MM/DD/YYYY'"></dw-calendar> </ng-container>  -->
			<ng-container *ngSwitchCase="'EndDate'"><p-calendar #endDate [(ngModel)]="valEndDate" [inputStyle]="{'width':'100px'}" [showIcon]="true" [showOnFocus]="false" [disabled]="formDisableFlag" [appendTo]="parent" [placeholder]="'MM/DD/YYYY'"></p-calendar></ng-container>
			<ng-container *ngSwitchCase="'DateFieldTarget'"><p-dropdown id="comboDateCategory"	name="dateCategory" placeholder="- Please Select -"  [options]="DateCategoryList" [(ngModel)]="selectedDateCategory" [style]="{'width':'192px'}"  optionLabel="label" [showClear]="true"></p-dropdown></ng-container>
			<ng-container *ngSwitchCase="'Location'"><p-dropdown id="comboLocation"	name="location" placeholder="- All Values -"  [options]="locationList" [(ngModel)]="selectedLocation" [style]="{'width':'192px'}"  optionLabel="LocationDescription" [showClear]="true"></p-dropdown></ng-container>
			<ng-container *ngSwitchCase="'Program'"><p-dropdown id="comboProg"	[scrollHeight]="'200px'" name="program" placeholder="- All Values -"  [options]="programList" [(ngModel)]="selectedProgram" [style]="{'width':'192px'}"  optionLabel="ProgramName" [showClear]="true"></p-dropdown></ng-container>
			<ng-container *ngSwitchCase="'SkipTeamTotal'"><p-checkbox name="group1" value="1" [(ngModel)]="valSkipTeamTotal" inputId="Yes"></p-checkbox></ng-container>
			<ng-container *ngSwitchCase="'ImportId'">
			 <p-dropdown  placeholder="- Please Select -" appendTo="body" [options]="importList"
				[(ngModel)]="selectedBillingImportRecord" [style]="{'width':'192px'}" optionLabel="dateRange"
				[hideTransitionOptions]="'50ms ease-in'" [showTransitionOptions]="'50ms ease-out'" [showClear]="false"
				></p-dropdown>
			</ng-container>
		</div>		
	</div>    
	<label [ngClass]="lblMessageColor">{{lblMessage}}</label>
</div>
<br>
<style>
.buttonHolder {
	text-align: center;
}
</style>
<div class="buttonHolder">
	<button type="button" pButton (click)="doRun()" label="GENERATE REPORT"></button>&nbsp;&nbsp;
	<button type="button" pButton (click)="this.close()" label="&nbsp;CLOSE&nbsp;"></button>
</div>
<br>
<p-dialog header="Select User" (onShow)="se.setFocusOnSearch()" [(visible)]="displayEmployeeList" [modal]="false" [responsive]="true" [style]="{width: '400px'}" [minY]="75" [resizable]="false" [closable]="false" >
	<app-selectuser #se (resourceSelected)="setResource($event)" (cancelClicked)="CancelClickedfromSelectEmployee()" ></app-selectuser> 
</p-dialog>
