export interface Notes
{
    id: number;
    foreignKeyId: number;
    foreignKeyTable: string;
    commentText: string | null;
    dateAdded: string | null;
    userUid: string | null;
    userFullName: string | null;
}

export class Notes {

    constructor() {
        this.id = 0;
        this.foreignKeyId = 0;
        this.foreignKeyTable = '';
        this.commentText = '';
        this.dateAdded = '';
        this.userUid = '';
        this.userFullName = '';
    }
}
