/************
 * Model Class
 * File Name ...: supplierlicenses.components.ts
 * Generated By : tojimahew on 3/7/23
 * Date ........: 3/7/23
 /************/
import {ValidationResult} from './validation-result';
import {DwDates} from "../components/utils/dw-dates";

export interface SupplierLicenses {
    id: number | null;
    supplierId: number | null;
    licenseType: string | null;
    licenseNumber: string | null;
    expirationDate: string | null;
    documentPath: string | null;
    practiceState: string | null;
}

export class SupplierLicenses {
    constructor() {
        this.id = 0;
        this.supplierId = 0;
        this.licenseNumber = '';
        this.licenseType = '';
        this.expirationDate = '';
        this.documentPath = '';
        this.practiceState = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.licenseType.length === 0) {
            vr.focus_field = 'licenseType';                     /* this should be the id value of the
             field */
            vr.error = 'Error: a license type is required';
            return vr;
        }
        if (this.licenseNumber.length === 0) {
            vr.focus_field = 'licenseNumber';                     /* this should be the id value of the
             field */
            vr.error = 'Error: a license number is required';
            return vr;
        }
        if (this.expirationDate.length === 0) {
            vr.focus_field = 'expirationDate';                     /* this should be the id value of the
             field */
            vr.error = 'Error: an expiration date is required';
            return vr;
        }
        if (DwDates.chkValidDate(this.expirationDate) === false) {
            vr.focus_field = 'expirationDate';
            vr.error = 'Error: Invalid date format (use: YYYY-MM-DD)';
            return vr;
        }
        if (DwDates.chkDateIsInThePast(this.expirationDate) === true) {
            vr.focus_field = 'expirationDate';
            vr.error = 'Error: The specified date is in the past (expired)';
            return vr;
        }


        if (this.practiceState.length === 0) {
            vr.focus_field = 'practiceState';                     /* this should be the id value of the
             field */
            vr.error = 'Error: a practice state is required';
            return vr;
        }
        return vr;
    }
}
