/************
 * Service Class
 * File Name ...: orders.service.ts
 * Generated By : ianday on 6/14/23
 * Date ........: 6/14/23
 /************/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {Orders} from '../models/orders';
import {Settings} from './settings';
import {OrderNumbers} from '../models/ordernumbers';
import {OrderItems} from '../models/orderitems';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    public URLPath = Settings.getInstance().BASE_URL + 'orders/orders';
    public URLItemsPath = Settings.getInstance().BASE_URL + 'orders/orderitems';

    constructor(private http: HttpClient) {
    }

    public getAllOrders(): Observable<any> {
        return this.http.get<any>(this.URLPath)
            .pipe(
                retry(1)
            );
    }
    public getOrderByOrderId(id: number): Observable<any> {
        return this.http.get<any>(this.URLPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    createOrderNumber(record: OrderNumbers): Observable<any> {
        return this.http.post(this.URLPath + '/ORNum/', record)
            .pipe(
                retry(1)
            );
    }
    public getAllOrdersByForeignKey(fkTable: string, fkId: number): Observable<any> {
        return this.http.get<any>(this.URLPath + '/' + fkTable + '/' + fkId)
            .pipe(
                retry(1)
            );
    }
    public getOrderItemsByOrderId(orderId: number): Observable<any> {
        return this.http.get<any>(this.URLItemsPath + '/' + orderId)
            .pipe(
                retry(1)
            );
    }

    public getOrderItemsById(id: number): Observable<any> {
        return this.http.get<any>(this.URLItemsPath + '/oi/' + id)
            .pipe(
                retry(1)
            );
    }

    public createOrders(record: Orders): Observable<any> {
        return this.http.post(this.URLPath, record)
            .pipe(
                retry(1)
            );
    }

    public updateOrders(record: Orders): Observable<any> {
        return this.http.put(this.URLPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }
    public attemptCloseOrder(id: number): Observable<any> {
        return this.http.get(this.URLPath + '/close/' + id)
            .pipe(
                retry(1)
            );
    }
    public deleteOrders(id: number): Observable<any> {
        return this.http.delete(this.URLPath + '/' + id)
            .pipe(
                retry(1)
            );
    }

    public createOrderItem(record: OrderItems): Observable<any> {
        return this.http.post(this.URLItemsPath, record)
            .pipe(
                retry(1)
            );
    }

    public updateOrderItem(record: OrderItems): Observable<any> {
        return this.http.put(this.URLItemsPath, record)
            .pipe(
                retry(1)
            );
    }

    public removeOrderItem(id: number): Observable<any> {
        return this.http.delete(this.URLItemsPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    
    public sendOrderByEmail(record: Orders): Observable<any> {
		return this.http.post(this.URLPath + '/send/email/' + record.id,null)
			.pipe(
				retry(1)
			);
	}
}
