/**
 *  Filename ....: add-catalogues.component.ts
 *  Created by ..: ianday
 *  on Date .....: 3/16/23
 **/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Stations} from '../../../../../models/stations';
import {SitesService} from '../../../../../services/sites.service';
import {DwUtils} from '../../../../../components/utils/dw-utils';
import {ValidationResult} from '../../../../../models/validation-result';

@Component({
    selector: 'app-add-station',
    templateUrl: './add-station.component.html',
    styleUrls: ['./add-station.component.css']
})

export class AddStationComponent implements OnInit {

    public record: Stations;
    public _parentId: number;
    public id = 0;
    public handle = '';
    public description = '';
    public activeStatus = '';
    public sitesService: SitesService;
    public strErr = '';
    public addEditFlag: boolean;

    @Output() cancelAddStation = new EventEmitter();
    @Output() submitAddStation = new EventEmitter<Stations>();

    @Input() set parentId(value: number) {
        this._parentId = value;
    }

    get parentId(): number {
        return this._parentId;
    }

    constructor(ssvc: SitesService) {
        this.sitesService = ssvc;
    }

    ngOnInit() {

    }
    initializeRecord(c: Stations): void {

        this.id = c.id;
        this.handle = c.handle;
        this.description = c.description;
        this.activeStatus = c.activeStatus;
    }
    afterOpenDialog(inputRec: Stations, aeFlag: boolean): void
    {
        this.addEditFlag = aeFlag;
        if(aeFlag === true) {
            inputRec.activeStatus = 'Active';
        }
        this.initializeRecord(inputRec);

        setTimeout(() => {
            DwUtils.setFocus('stationHandle');
        }, 300);

    }
    doCancel() {
        this.cancelAddStation.emit();
    }
    setRecord(): void {
        this.record = new Stations();
        this.record.id = this.id;
        this.record.siteId = this.parentId;
        this.record.handle = DwUtils.capitalize(this.handle).trim();
        this.record.description = this.description.trim();
        this.record.activeStatus = this.activeStatus;

        this.record.dumpRecord();

    }
    doSubmit() {
        // this is where we stuff the new address and emit it back.
        this.setRecord();

        this.strErr = '';
        const vr: ValidationResult = this.record.validateRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return;
        }
        // add the record ...
        if (this.addEditFlag === true) {
            this.sitesService.createStations(this.record).subscribe(
                (data) => {
                    this.record.id = data.id;        // get the Id ...
                    this.submitAddStation.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to create / save this record ';
                    return;
                });
        }
        else {
            this.sitesService.updateStations(this.record).subscribe(
                (data) => {
                    this.submitAddStation.emit(this.record);
                },
                (error) => {
                    this.strErr = 'Unable to update this record ';
                    return;
                });
        }
    }
}


