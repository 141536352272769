/************
 * Service Class
 * File Name ...: forming.service.ts
 * Generated By : ianday on 7/7/23
 * Date ........: 7/7/23
 /************/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {Bpr} from '../models/bpr';
import {BprBulkItems} from '../models/bprbulkitems';
import {Settings} from './settings';
import {get_settings} from '../app.module';
import {FinishedForms} from '../models/finishedforms';
import {FormingQBE} from '../models/forming-qbe';

@Injectable({
    providedIn: 'root'
})
export class FormingService {

    public URLPath: string = Settings.getInstance().BASE_URL + 'forming/bpr';
    public URLPathQBE: string = Settings.getInstance().BASE_URL + 'forming/qbe/';
    public URLPathItems: string = Settings.getInstance().BASE_URL + 'forming/bpritems';
    public URLFinishedForms: string = Settings.getInstance().BASE_URL + 'forming/finishedforms';

    constructor(private http: HttpClient) {
    }

    getBprStepSummary(): Observable<any> {
        return this.http.get<any>(this.URLPath + 'stepsummary')
            .pipe(
                retry(1)
            );
    }
    getAllBpr(): Observable<any> {
        return this.http.get<any>(this.URLPath)
            .pipe(
                retry(1)
            );
    }

    getAllBprById(Id: number): Observable<any> {
        return this.http.get<any>(this.URLPath + '/' + Id)
            .pipe(
                retry(1)
            );
    }
    getByQueryByExample(formingQbE: FormingQBE): Observable<any> {

        return this.http.post<any>(this.URLPathQBE, formingQbE )
            .pipe(
                retry(1)
            );
    }
    getAllCompletedFormsByDaysOld(daysOld: number): Observable<any> {
        return this.http.get<any>(this.URLPath + '/completed/' + daysOld)
            .pipe(
                retry(1)
            );
    }
    getAllFormingByActiveStep(currStep: number): Observable<any> {
        return this.http.get<any>(this.URLPath + '/' + currStep)
            .pipe(
                retry(1)
            );
    }
    createBpr(record: Bpr): Observable<any> {
        return this.http.post(this.URLPath, record)
            .pipe(
                retry(1)
            );
    }

    updateBpr(record: Bpr): Observable<any> {
        return this.http.put(this.URLPath + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteBpr(id: number): Observable<any> {
        return this.http.delete(this.URLPath + '/' + id)
            .pipe(
                retry(1)
            );
    }
    /*** BPR Items ***/
    getAllBprBulkItemsByBprId(bprId: number): Observable<any> {
        return this.http.get<any>(this.URLPathItems + '/' + bprId)
            .pipe(
                retry(1)
            );
    }
    createBprItem(record: BprBulkItems): Observable<any> {
        return this.http.post(this.URLPathItems, record)
            .pipe(
                retry(1)
            );
    }

    updateBprItem(record: BprBulkItems): Observable<any> {
        return this.http.put(this.URLPathItems + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteBprItem(id: number): Observable<any> {
        return this.http.delete(this.URLPathItems + '/' + id)
            .pipe(
                retry(1)
            );
    }
    /*** Finished Forms ***/
    getAllFinishedFormsByBprId(bprId: number): Observable<any> {
        return this.http.get<any>(this.URLFinishedForms + '/' + bprId)
            .pipe(
                retry(1)
            );
    }
    createFinishedForm(record: FinishedForms): Observable<any> {
        return this.http.post(this.URLFinishedForms, record)
            .pipe(
                retry(1)
            );
    }

    updateFinishedForm(record: FinishedForms): Observable<any> {
        return this.http.put(this.URLFinishedForms + '/' + record.id, record)
            .pipe(
                retry(1)
            );
    }

    deleteFinishedForm(id: number): Observable<any> {
        return this.http.delete(this.URLFinishedForms + '/' + id)
            .pipe(
                retry(1)
            );
    }
    sendImportFileByEmail(id: number): Observable<any> {
        return this.http.post(this.URLFinishedForms + '/send/email/' + id, null)
            .pipe(
                retry(1)
            );
    }
}
