import { Component, OnInit, ViewChild } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { AppComponent } from '../../app.component';
import { HomeRendererComponent } from './home-renderer/home-renderer.component';
import { ChartDetails, CHART_DETAILS_LIST } from '../../models/chart-details';
import { MetricCard } from '../../models/metric-card';
import { Settings } from 'src/app/services/settings';
import * as dashboardData from '../../../config/dashboard.json';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	constructor(private homeService: HomeService) { }
	public chartsMetaData: any;
	public metricCardArr: Array<MetricCard>;
	public chartArr: Array<ChartDetails>;
	public selectedTabIndex;
	visibleDashboardTab = false;
	visibleReportsTab = false;

	@ViewChild('dashboard_renderer') AppHomeRenderer: HomeRendererComponent;

	ngOnInit() {
		/* Temporary override - remove when ready
		this.selectedTabIndex = 1;
		return;
  
		End temporary override */

		if (Settings.getInstance().hasPrivilege('NAV_DASHBOARD')) {
			this.visibleDashboardTab = true;
		}
		if (Settings.getInstance().hasPrivilege('NAV_REPORTS')) {
			this.visibleReportsTab = true;
		}

		this.loadPreRequisiteData();
	}
	loadPreRequisiteData() {

		if (AppComponent.staticDataSource === true) {
			this.chartArr = CHART_DETAILS_LIST;
		} else {
			this.loadHomeData();
		}
	}
	onTabChangeEvent(event) {
		// when the tab changes back to the dashboard, the charts don't redraw correctly
		// this forces a reset.

		if (this.selectedTabIndex === 0) {
			this.AppHomeRenderer.resetCharts();
		}
	}

	loadHomeData() {
		// To-Do
		this.chartsMetaData = dashboardData;
		this.metricCardArr = this.chartsMetaData.dashboard.metric_cards;
		this.chartArr = this.chartsMetaData.dashboard.charts;
	}

}
