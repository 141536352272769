/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: preparation.components.ts
 * Generated By : ianday on 7/5/23
 * Date ........: 7/5/23
 /************/

import {Component, OnInit, ViewChild, HostListener, AfterContentInit, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Settings} from '../../../services/settings';
import {OrderItems} from '../../../models/OrderItems';
import {ValidationResult} from '../../../models/validation-result';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TransactionHistoryService} from '../../../services/transactionhistory.service';
import {TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import {DwUtils} from '../../../components/utils/dw-utils';
import {OrdersService} from '../../../services/orders.service';
import {SitesService} from '../../../services/sites.service';
import {Sites} from '../../../models/sites';
import {Stations} from '../../../models/stations';
import {FormingService} from '../../../services/forming.service';
import {Forming} from '../../../models/forming';
import {DwDates} from '../../../components/utils/dw-dates';
import {Orders} from '../../../models/orders';
import { ChangeAssignmentsComponent } from '../preparation/changeassignments/changeassignments.component';
import { FinishBatchComponent } from './finishbatch/finishbatch.component';
import {Bpr} from '../../../models/bpr';

@Component({
    selector: 'app-inprocess',
    templateUrl: './inprocess.component.html',
    styleUrls: ['./inprocess.component.css']
})
export class InProcessComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'In Process';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: Forming[];
     public selectedRecord: Forming;
    public historyRecord: Forming;
    public Idx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Forming>;
    public renderedData: Array<any>;
    public result: boolean | null = false;
    public disabledFlag: boolean | null = true;
    public activeCellId: string | null = '';

    /**** Query strip filter settings ***/

    public selectedViewFilter: string | null = 'List';
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';

    public selectedSitesFilter: string;
    public sitesList: any[];
    public selectedSite: Sites = new Sites();
    public siteIdx = 0;
    public selectedStationsFilter: string | null = 'All';
    public stationsList: any[];
    public stationIdx = 0;
    public selectedStation: Stations = new Stations();
    public selectedOrders: Orders[];
    public selectedOrder: Orders;
    public selectedBPR: Bpr;
    public selectedBPRs: Bpr[];

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['customerName', 'itemPrefix', 'description', 'schedule', 'quantity', 'station', 'daysInStatus', 'itemStatus'];

    /*** Dialogs ***/
    public displayChangeAssignmentsDlg = false;
    public displayFinishBatchDlg = false;

    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: any | null = '';
    public description: any | null = '';
    public activeStatus: any | null = '';

    public currentStep: number | null = 2;

    /***** Side drawer ****/
    public propContainerState: boolean | null = false;
    public visiblePropertiesTab: boolean | null = true;
    public visibleNotesTab: boolean | null = true;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;
    @ViewChild('finishBatch', {static: true}) finishBatch: FinishBatchComponent;
    @ViewChild('changeAssignments', {static: true}) changeAssignments: ChangeAssignmentsComponent;

    @Output() updateCounters = new EventEmitter();

    constructor(private formingService: FormingService,
                public sitesService: SitesService,
                public orderService: OrdersService,
                private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Forming();
        this.selectedOrder = new Orders();
    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = true;
    }
    ngAfterContentInit() {

        this.querySites();

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
    }
    /*** Support tables ***/
    public querySites(): void {

        this.sitesService.getAllSites().subscribe (
            (data) => {
                this.sitesList = [];
                this.sitesList = data.filter(x => x.activeStatus === 'Active');
             //   this.sitesList.unshift({id: 0, handle: 'All Sites'});
                this.selectedSite = this.sitesList[this.siteIdx];
                this.queryStations();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Retrieving sites for finish');
            });
    }
    public queryStations(): void {
        this.sitesService.getAllStations(this.selectedSite.id).subscribe (
            (data) => {
                this.stationsList = [];
                this.stationsList = data.filter(x => x.activeStatus === 'Active');
                this.stationsList.unshift({id: 0, handle: 'All Stations'});
                this.selectedStation = this.stationsList[this.stationIdx];
                this.queryRecords();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Retrieving stations for finish');
            });
    }
    /*** Query Records from the Service ***/
    public queryRecords() {

        this.records = new Array();
        this.formingService.getAllFormingByActiveStep(this.currentStep).subscribe(
            (result) => {

                for (let i = 0; i < result.length; i++) {

                    if (result[i].siteId !== this.selectedSite.id) {
                        continue;
                    }
                    // this way we will always see unassigned items.
                    if (result[i].stationId !== this.selectedStation.id) {
                        if (this.selectedStation.id > 0) {
                            continue;
                        }
                    }
                    const frm = result[i];
                    frm.id = i + 1;
                    frm.station = this.stationsList.find (x => x.id === frm.stationId).handle;

                    if (frm.stationId === 0) {
                        frm.station = 'Unassigned';
                    }
                    this.records.push(frm);
                }

                this.dataSource = new MatTableDataSource(this.records);

                this.dataSource.sort = this.sort;
                this.queryOpenOrders();
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    /*** Display the number of records from the total ***/
    public queryOpenOrders(): void {

        this.selectedOrders = new Array();
        this.orderService.getAllOrders().subscribe(
            (data) => {
                for (let i = 0; i < data.length; i++) {
                    data[i].dateSubmitted = DwUtils.formatDTFromDate(data[i].dateSubmitted);
                    data[i].dateCreated = DwUtils.formatDTFromDate(data[i].dateCreated);
                    data[i].dateClosed = DwUtils.formatDTFromDate(data[i].dateClosed);
                    this.selectedOrders.push(data[i]);
                }
                this.selectedOrders = data;
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching order record by id');
            });
    }
    public setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    /*** utilities ***/
    public onChangeSitesFilterValue(event: any) {
        this.selectedSite = this.sitesList.find(x => x.id === event.value.id);
        this.clearFilter();
        this.queryStations();
    }
    public onChangeStationsFilterValue(event: any): void {
        this.selectedStation = this.stationsList.find(x => x.id === event.value.id);
        this.clearFilter();
        this.queryRecords();
    }
    public doTabChange(event): void {

    }
    public onPropertiesToggle() {
        this.propContainerState = !this.propContainerState;
    }
    public tableKeyDown(event: KeyboardEvent) {
        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.setSelectedOrder();
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.setSelectedOrder();
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public lbDaysFmt(inp: Date): number {

        return DwUtils.getDaysFromDate(inp);
    }
    @HostListener('matSortChange', ['$event'])
    public onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
       // this.dataSource.filter = '';
        this.selectRow();
      //  this.setPagination(-1, this.dataSource.data.length);
    }
    public applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public selectRow() {
        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.history.onShowHandler('bpr', 'id', this.selectedRecord.bprId.toString());
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
        this.setSelectedOrder();
    }
    public setSelectedOrder(): void {
        if (this.selectedRecord.id === undefined) {
            return;
        }
        this.selectedOrder = this.selectedOrders.find(x => x.id === this.selectedRecord.orderId);
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = new Forming();
        this.selectedOrder = new Orders();
     //   this.setPagination(this.Idx, this.dataSource.data.length);

    }
    public clear() {
        this.id = '';
        this.description = '';
        this.activeStatus = '';

    }
    /*** Dialogs ***/
    public changeAssignmentsClicked(): void {
        this.displayChangeAssignmentsDlg = true;
        this.changeAssignments.doInitialize(this.selectedRecord.stationId, this.selectedSite);
    }
    public changeAssignmentsSubmitted(event: any): void {
        this.displayChangeAssignmentsDlg = false;

        /*** set the record with the correct site and station assignment ***/
        this.selectedRecord.bpr.siteId = event.siteId;
        this.selectedRecord.bpr.stationId = event.stationId;

        /*** now we must update the BPR record with the updated information. ***/
        this.formingService.updateBpr(this.selectedRecord.bpr).subscribe (
            (result) => {
                this.queryRecords();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'updating the BPR with new station and site');
            });

    }
    public finishBatchClicked(): void {
        this.displayFinishBatchDlg = true;
        this.finishBatch.doInitialize();
    }
    public finishBatchSubmitted(): void {
        this.displayFinishBatchDlg = false;
        this.txService.createTransactionHistory('bpr', this.selectedRecord.bprId, 'Update', 'Forming / Fill process completed');
        this.updateCounters.emit();
        this.queryRecords();
    }
    /*** rows and filters ***/
    public onRowClicked(selected: any) {

        if (this.isEditing === true) {
            return;
        }
        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public onRowDoubleClicked(selected: any) {
        this.onRowClicked(selected);
        this.finishBatchClicked();
    }
    public cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
    }
}
