	<div class="med-suppliers-container">
		<div style="width: 100%; margin-top: 15px; margin-bottom: 25px;padding-left: 20px;padding-right: 20px;">
			<button mat-button style="float: right;" [disabled]="this._medication===undefined || this._medication.id===0"
				class="command-button" matTooltip="Add a new record"
				(click)="addRecord()" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add Supplier
			</button>
		</div>

		<div class="med-suppliers-table-container" (keydown)="tableKeyDown($event)" tabindex="-1">
			<table *ngIf=!isEditing #table id="medication-links-table"
				   mat-table [dataSource]="medicationSuppliers" matSort style="width: 100%">

				<ng-container matColumnDef="supplierName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style="padding-left: 2px!important; width: 220px; border-bottom: .5px solid #e1e1e1!important;
						font-size: 9pt; z-index: 99999;background-color: white;"> Supplier </th>
					<td mat-cell *matCellDef="let medSupplier"
						style="padding-left:2px!important; width:220px; font-size: 9pt">
						{{medSupplier.supplierName}}
					</td>
				</ng-container>

				<ng-container matColumnDef="costPerDose">`
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style="width: 45px;font-size: 9pt; border-bottom: .5px solid #e1e1e1!important;
						z-index: 99999;background-color: white;"> Cost </th>
					<td mat-cell *matCellDef="let medSupplier" fxLayout="column wrap"  style=";
					font-size: 9pt;width: 45px">
					<span>${{medSupplier.costPerDose}}</span></td>
				</ng-container>

				<ng-container matColumnDef="leadTimeDays">`
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="
					width: 60px;font-size: 9pt; border-bottom: .5px solid #e1e1e1!important;
					z-index: 99999;background-color: white;"> Lead Time </th>
					<td mat-cell *matCellDef="let medSupplier" fxLayout="column wrap"  style=";
					font-size: 9pt; width: 60px">
						<span>{{medSupplier.leadTimeDays}} day(s)</span></td>
				</ng-container>

				<ng-container matColumnDef="lastUpdated">`
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style="width: 60px;    border-bottom: .5px solid #e1e1e1!important;
						font-size: 9pt;	z-index: 99999;background-color: white;"> Last Updated </th>
					<td mat-cell *matCellDef="let medSupplier" fxLayout="column wrap"
						style=" font-size: 9pt; width:60px">
					<span>{{lastUpdatedLabeler(medSupplier.lastUpdated)}}</span></td>
				</ng-container>

				<ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style=" background-color: white;z-index: 99999;
						   border-bottom: .5px solid #e1e1e1!important;"> </th>
					<td mat-cell *matCellDef="let medSupplier" minWidth="15px;" style="min-width:50px;
					text-align: right;padding-right: 6px!important;">
						<span>
							<mat-icon class="edit-icon-suppliers" (click)="this.showDialogAddEditSupplier()">edit</mat-icon>
							<img style="width: 12px;cursor: pointer" class="edit-delete-button" alt="remove" src="assets/delete-icon.png"
								 (click)="deleteSupplierMedication(medSupplier)">
						</span></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
					<tr mat-row
						*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
						[ngClass]="{'row-selected': tableRowIdx == Idx}"
						(click)="onRowClicked(row)">
				</tr>
			</table>
		</div>
	</div>

<p-dialog header="Add/Edit Supplier"
          [(visible)]="displayAddEditSupplier"
          *ngIf="displayAddEditSupplier"
          [modal]="true" [responsive]="true"
          [style]="{width: '605px', height: '275px'}"
          [minY]="75"
          appendTo="body"
          [resizable]="false" [closable]="true">
   <app-addeditmedicationsupplier #addEditSupplier [medication]="_medication" [selectedRecord]="selectedRecord"
								  [suppliersList]="suppliersList" [medicationSuppliers]="medicationSuppliers"
         (cancelClicked)="cancelClickedFromAddeditSupplier()"
         (submitClicked)="submitClickedFromAddeditSupplier()">
   </app-addeditmedicationsupplier>
</p-dialog>
