/************
 * Model Class
 * File Name ...: bulkunits.components.ts
 * Generated By : ianday on 4/3/23
 * Date ........: 4/3/23
 /************/
import {ValidationResult} from './validation-result';
import {DwDates} from '../components/utils/dw-dates';

export interface BulkUnits {

    id: number;
    foreignKeyTable: string;
    foreignKeyId: number;
    cataloguesId: number;
    itemNumber: string;
    location: string;
    serialNumber: string | null;
    inventoryStatus: string | null;
    lotNumber: string | null;
    expirationDate: string | null;
    orderId: number | null;
    ndcNumber: string | null;
    icn: string | null;
    gtin: string | null;
    unitCost: string | null;
    costBase: string | null;
    awp: string | null;
    containerStatus: string | null;
    originalDoses: number | null;
    remainingDoses: number | null;
    defectiveDoses: number | null;
    containerUrl: string | null;
    tabletUrl: string | null;
    invoiceDate: string | null;
    invoiceNumber: string | null;
    checkedStatus: string | null; // new
    supplierId: number | null;
    select: boolean | null;
    purchaseOrderItemId: number;
    purchaseOrderId: number;
}

export class BulkUnits {
    constructor() {
        this.id = 0;
        this.foreignKeyTable = '';
        this.foreignKeyId = 0;
        this.cataloguesId = 0;
        this.itemNumber = '';
        this.location = '';
        this.serialNumber = '';
        this.inventoryStatus = '';
        this.lotNumber = '';
        this.expirationDate = '';
        this.orderId = 0;
        this.ndcNumber = '';
        this.icn = '';
        this.gtin = '';
        this.unitCost = '';
        this.costBase = '';
        this.awp = '';
        this.containerStatus = '';
        this.originalDoses = 0;
        this.remainingDoses = 0;
        this.defectiveDoses = 0;
        this.containerUrl = '';
        this.tabletUrl = '';
        this.invoiceDate = '';
        this.invoiceNumber = '';
        this.checkedStatus = '';
        this.supplierId = 0;
        this.select = false;
        this.purchaseOrderItemId = 0;
        this.purchaseOrderId = 0;
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.itemNumber.length === 0) {
            vr.focus_field = 'itemNumber';                              /* this should be the id value of the field */
            vr.error = 'Error: an Item Number / value is required';
            return vr;
        }

        return vr;
    }
    validateBulkCheckinRecord(medMaterial: string): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.supplierId === 0) {
            vr.focus_field = 'supplierId';                              /* this should be the id value of the field */
            vr.error = 'Error: a Supplier Id is required';
            return vr;
        }

        if (this.invoiceNumber.length === 0) {
            vr.focus_field = 'invoiceNumber';                              /* this should be the id value of the field */
            vr.error = 'Error: an Invoice Number is required';
            return vr;
        }

        if (this.invoiceDate.length === 0) {
            vr.focus_field = 'invoiceDate';                              /* this should be the id value of the field */
            vr.error = 'Error: an Invoice Date is required';
            return vr;
        }
        if (DwDates.chkValidDate(this.invoiceDate) === false) {
            vr.focus_field = 'invoiceDate';                              /* this should be the id value of the field */
            vr.error = 'Error: Invoice Date - has an incorrect date format (use YYYY-MM-DD)';
            return vr;
        }
        if (this.serialNumber.length === 0) {
            vr.focus_field = 'serialNumber';                              /* this should be the id value of the field */
            vr.error = 'Error: a Serial Number is required';
            return vr;
        }

        if (this.ndcNumber.length === 0) {
            vr.focus_field = 'ndcNumber';                              /* this should be the id value of the field */
            if (medMaterial === 'Medications') {
                vr.error = 'Error an NDC Number is required';
            }
            else {
                vr.error = 'Error: a Part Number is required';
            }
            return vr;
        }

        if (this.lotNumber.length === 0) {
            vr.focus_field = 'lotNumber';                              /* this should be the id value of the field */
            vr.error = 'Error: a Lot Number is required';
            return vr;
        }
        if(medMaterial === 'Medications') {
            if (DwDates.chkValidDate(this.expirationDate) === false) {
                vr.focus_field = 'expirationDate';                              /* this should be the id value of the field */
                vr.error = 'Error: Expiration Date - has an incorrect date format (use YYYY-MM-DD)';
                return vr;
            }
            if (this.expirationDate.length === 0) {
                vr.focus_field = 'expirationDate';                              /* this should be the id value of the field */
                vr.error = 'Error: an Expiration Date is required';
                return vr;
            }
            if (DwDates.chkDateIsInThePast(this.expirationDate) === true) {
                vr.focus_field = 'expirationDate';                              /* this should be the id value of the field */
                vr.error = 'Error: This product has expired';
                return vr;
            }
        }

        return vr;
    }
}
