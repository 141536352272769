import { Component, OnInit } from '@angular/core';
import { Settings } from '../../services/settings'; 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    
  displayAbout = false;
  strVersion='';
  settings;
  
  constructor() { }

  ngOnInit() {
     // this.strVersion=Settings.getInstance().version;
     this.settings = Settings.getInstance();
  }
  showDialogAbout() {
     this.displayAbout = true;
  }
  doCancelFromAbout() {
      this.displayAbout = false;
  }
}
