/**
 *  Filename ....: trans-history-detail.component.ts
 *  Created by ..: ianday
 *  on Date .....: 3/20/23
 **/
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { DwUtils } from '../../utils/dw-utils';
import {Settings} from '../../../services/settings';

@Component({
    selector: 'app-trans-history-detail',
    templateUrl: './trans-history-detail.component.html',
    styleUrls: ['./trans-history-detail.component.css']
})

export class TransHistoryDetailComponent implements OnInit {

    private _selectedRecord: any;

    public id;

    @Input() set selectedRecord(value: any) {
        this._selectedRecord = value;
    }

    get selectedRecord(): any {
        return this._selectedRecord;
    }

    @Output() cancelTxDetail = new EventEmitter();
    @Output() AttemptToFetchPrevious = new EventEmitter();
    @Output() AttemptToFetchNext = new EventEmitter();

    constructor() {

    }
    attemptFetchPreviousRecord(): void {
        this.AttemptToFetchPrevious.emit();
    }
    attemptFetchNextRecord():void {
        this.AttemptToFetchNext.emit();
    }
    ngOnInit() {

    }
    doClose(): void
    {
        this.cancelTxDetail.emit();
    }
    retDTF(inpStr: string): string {
        return DwUtils.formatDTFromString(inpStr);
    }
    tableFunctionShow(tbl: string): string {
        if(tbl === undefined)
            return '';
        const ret = DwUtils.capitalize(tbl);
        return ret;
    }
    returnFullName(inpUser: string): string {
        if(inpUser === undefined)
            return '';

        let ret = Settings.getInstance().usersList.find(x => x.username === inpUser).name;
        return ret;

    }
}


