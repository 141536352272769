import { Component, OnInit, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { User } from '../../../../models/user';
import { UserService } from '../../../../services/user.service';
import { Settings } from '../../../../services/settings';
import { DatePipe } from '@angular/common';
import {MatTableDataSource} from "@angular/material/table";

@Component({
	selector: 'app-send-invite',
	templateUrl: './send-invite.component.html',
	styleUrls: ['./send-invite.component.css']
})
export class SendInviteComponent implements OnInit {

	public data: any;
	public selectedUsers: any;
	public searchString: string = '';
	public loading = false;
	public userListOriginal: any;
	public showNotInvitedFlag = false;
	public buttonsDisabled = false;
	public strErr: String = '';
	public Idx:number = -1;
	public displayedColumns: any = ['select', 'name', 'email','invitationStatus', 'LastActivityDate'];
	public dataSource: MatTableDataSource<any>;
	public records: any;
	public selectedRecord: any;

	@Input() set users(value: any) {

		this.userListOriginal = value;
		this.records = new Array();
		this.userListOriginal = value;

		for (let i = 0; i < this.userListOriginal.length; i++) {
			let row = this.userListOriginal[i];
			row['select'] = false;
			let jsonDate = row['lastInvitationDate'];
			if (jsonDate === null || jsonDate === '' || jsonDate === undefined) {
				row['LastActivityDate'] = '';
				continue;
			}
			let gt = (new Date());
			gt.setTime(row['lastInvitationDate']);
			row['LastActivityDate'] = this.datePipe.transform(gt, 'MM/dd/yyyy hh:mm a');

		}
		this.dataSource = new MatTableDataSource(this.userListOriginal);
		this.applyFilter(this.searchString);
	}

	public get users(): User[] {
		return this.userListOriginal;
	}

	@ViewChild('searchbox', { static: true }) searchbox: ElementRef;

	@Output() intitationsSent = new EventEmitter();
	@Output() cancelClicked = new EventEmitter();

	constructor(private userService: UserService, private datePipe: DatePipe) { }

	public ngOnInit(): void {}
	public doInitialize(recs: any): void {
		this.users = recs;
	}
	public toggleNotInvitedCheckBox(event: any): void {

		if (event.checked === true) {
			this.records = this.userListOriginal.filter(s => s.invitationStatus === 0 && s.invitationCount===0);
		}
		else {
			this.records = this.userListOriginal;
		}
		this.dataSource = new MatTableDataSource(this.records);
		this.dataSource.filter = this.searchString.toLowerCase();

	}
	public toggleAllSelected(event: any): void {

		if (this.dataSource === undefined) {
			return;
		}

		for (const item of this.dataSource.filteredData) {
			item.select = event.checked;
		}

		if (event.checked === true) {
			this.buttonsDisabled = true;
		}
		else {
			this.buttonsDisabled = false;
		}
	}
	public onRowClicked(row: any): void {
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === row.id);
		this.selectedRecord = this.dataSource.filteredData[this.Idx];
	}
	public runChecked(event: any): void {

		if (event.checked === true) {
			this.buttonsDisabled = true;
		}
		else {
			this.dataSource.filteredData.filter(x => x.select === true).length > 0 ? this.buttonsDisabled = true : this.buttonsDisabled = false;
		}
	}
	public invitationStatusLabeler(row): string {
		return Settings.invitationStatusLabeler(row);
	}
	public setFocusOnSearch() {
		this.searchbox.nativeElement.focus();
	}
	public applyFilter(str: string) {
	    this.dataSource.filter = str.trim().toLowerCase();
		this.unSelectRow(false);
	}
	public unSelectRow(withZero: boolean) {

		this.Idx = -1;
		this.selectedRecord = null;

	}
	public tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
			}
		}
		//this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	public sendInvite() {
		const userIdList = [];
		this.strErr = '';
		this.buttonsDisabled=false;

		this.selectedUsers = this.dataSource.filteredData.filter(s => s.select === true);
		if(this.selectedUsers === undefined)  {
			this.strErr = 'Error: No users have been selected';
			this.buttonsDisabled = false;
			return;
		}
		else if(this.selectedUsers.length === 0){
			this.strErr = 'Error: No users have been selected';
			this.buttonsDisabled = false;
			return;
		}

		for(let item of this.selectedUsers) {
			userIdList.push(item.id);
		}

		this.userService.sendUserInvitations(userIdList).subscribe(
			(data) => {
				this.buttonsDisabled = false;
				this.intitationsSent.emit(this.selectedUsers);
			},
			(error) => {
				Settings.getInstance().handleError(error,'Unable to process invitation delivery');
			}
		);
	}
	public doCancel() {
		this.cancelClicked.emit();
	}

}
