<label class="control-label text-align-right" for="listSearch">Filter by Name:&nbsp;</label>
<input pInputText #searchbox id="listSearch" type="text"  (keyup)="onKeyPress(searchbox.value)" [autofocus]="true" />&nbsp;
<button type="button" id="btnClear" (click)="searchbox.value='';onKeyPress('');"></button>	
<br>
<div class="resource-grid-pane">
    <p-table [columns]="cols" [value]="resourceList" selectionMode="single" [(selection)]="selectedEmployee" (onRowSelect)="onRowSelect($event)" dataKey="username" scrollHeight="32vh" [scrollable]="true" [loading]="loading">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" pResizableColumn>
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
            
                <td *ngFor="let col of cols" >
                    <img class="grid-icon-img" *ngIf="col.field==='fullName' && rowData['online']===true" src="/assets/green_status.png" alt="Online" style="width: 10px;" />
                    <img class="grid-icon-img" *ngIf="col.field==='fullName' && rowData['online']===false" src="/assets/grey_status.png" alt="Online" style="width: 10px;" />
                    <span [ngClass]="rowData['stringColor']">  &nbsp; {{(col.labelFunction === undefined || col.labelFunction === null) ? rowData[col.field] : col['labelFunction'](rowData, col)}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<br>

<div class="select-user-button-holder">
	<button mat-button class="command-button" (click)="doSelect()" matTooltip="Select" mat-button>
		<mat-icon>done</mat-icon>
		Select
	</button>
	<button mat-button class="command-button" (click)="doCancel()" matTooltip="Cancel" mat-button>
		<mat-icon>highlight_off</mat-icon>
		Cancel
	</button>
<!--     <button type="button" pButton  (click)="doSelect()" label="SELECT" style="height: 28px"></button> -->
<!--     <button type="button" pButton (click)="doCancel()"  label="CANCEL" style="height: 28px"></button> -->
</div>