/************
 * Model Class
 * File Name ...: purchaseorderitems.components.ts
 * Generated By : ianday on 5/15/23
 * Date ........: 5/15/23
 /************/
import { SupplierItems } from './supplieritems';

export interface PurchaseOrderItems {
	id: number | null;
	purchaseOrderId: number | null;
	foreignKeyTable: string | null;
	foreignKeyId: number | null;
	ndcNumber: string | null;
	itemDescription: string | null;
	quantity: number | null;
	quantityReceived: number | null;
	packageSize: number | null;
	costBase: number | null;
	leadTimeDays: number | null;
	uom: string | null;
	unitCost: number | null;
	extendedCost: number | null;
	itemStatus: string | null;
	schedule: string | null;
}

export class PurchaseOrderItems {
	constructor() {
		this.id = 0;
		this.purchaseOrderId = 0;
		this.foreignKeyTable = '';
		this.foreignKeyId = 0;
		this.ndcNumber = '';
		this.itemDescription = '';
		this.quantity = 0;
		this.quantityReceived = 0;
		this.packageSize = 0;
		this.costBase = 0;
		this.leadTimeDays = 0;
		this.uom = '';
		this.unitCost = 0;
		this.extendedCost = 0;
		this.itemStatus = '';
		this.schedule = '';
	}
	public setItemsFromSupplierMeds(rec: SupplierItems, poId: number): void {
		this.id = 0;  // was rec.id;
		this.purchaseOrderId = poId;
		this.foreignKeyTable = 'medications';
		this.foreignKeyId = rec.itemId;
		this.ndcNumber = rec.origNdcNumber;
		this.itemDescription = rec.description;
		this.quantity = rec.quantity;
		this.packageSize = rec.packageSize;
		this.costBase = rec.costBase;
		this.leadTimeDays = rec.leadTimeDays;
		this.uom = rec.unitOfMeasure;
		this.unitCost = rec.unitPrice;
		this.extendedCost = rec.extended;
		this.itemStatus = 'Draft';
		this.schedule = rec.schedule;

	}
}
