 <input type="file" id="imgupload" style="display:none"
		[accept]="file_types"
		(change)="fileInputChange($event)"/>
		<label for='imgupload'> <button id="OpenImgUpload" style="display: none"></button>
           <div #imageLoader dwDragDrop id="imageLoader" (onFileDropped)="onFileDropped($event)"
			class="file-selector-outer-container">
			<div style="font-size: 16px;">{{_header}}</div>
			<div>{{_file_types}} files are allowed</div>
			<div style="margin: 1rem 3rem; height: 75%; box-shadow: inset 0px 0px 20px 0px rgb(238 242 243 / 20%); border-radius: 5px;">
				<div><mat-icon style="font-size: 4rem; width: 100%; height: fit-content; color: lightgrey;">cloud_upload</mat-icon></div>
				<div style="margin-top: 1rem">Drag and drop or click to choose a file</div>
			</div>			
			</div>
		</label>
<div>
<span><label *ngIf="this.fileName" style="font-weight: 300;margin-right: 5px; margin-left: 10px;">File name: </label><label>{{fileName}}</label>
	<img class="green-tick" *ngIf="fileName!==''" src="/assets/greenTick.png">
</span>
</div>
