import { AppFunctionGroup } from './app-function-group';
import { AppFunction } from './app-function';
import { User } from './user';

export interface UserEntity {
	id: number;
	username: string;
	password: string;
	name: string;
	lastLogin: string;
	email: string;
	status: number;
	invitation_count: number;
	last_invitation_date: string;
	invitation_status: number;
	invitation_token: string;
	app_functions: Array<string>;
	dashboard_settings: string;
}

export class UserEntity {
	constructor(rec: User) {
		this.id=rec.id;
		this.username=rec.username;
		this.password=rec.password;
		this.name=rec.name;
		this.lastLogin=rec.lastLogin;
		this.email=rec.email;
		this.status=rec.status;
		this.invitation_count=rec.invitation_count;
		this.last_invitation_date=rec.last_invitation_date;
		this.invitation_status=rec.invitation_status;
		this.invitation_token=rec.invitation_token;
		this.dashboard_settings=rec.dashboard_settings;
	}
}
