<!--
    file type .....: HTML file: purchaseorders.component.html
    Created By ....: ianday
    Date ..........: 5/12/23 
-->
<div class="purchase-orders-container">

    <!----------------->
    <!-- QUERY STRIP -->
    <!----------------->
    <div class="query-strip-container mat-elevation-z2">
        <table height="100%; width:100%" >
            <TR>
                <TD class="qs-label-cell" style="width: 240px; text-align: center;">
                    Time Frame:&nbsp;&nbsp;
                    <mat-select
                        disableOptionCentering
                        [(ngModel)]="selectedInterval"
                        floatLabel="never"
                        (keydown)="doIntervalDropDownKeyDown($event)"
                        (selectionChange)="doIntervalValueChange($event)"
                        style="font-size: 10pt;width: 120px;"
                        panelClass="local-drop-down-panel" class="dw-mat-select">
                    <mat-option *ngFor="let interval of intervalList"
                                [value]="interval">{{interval.description}}</mat-option>
                </mat-select>
                </TD>
                <TD style="width: 120px;">
                <TD align="center" style="min-width: 340px;">
                    <input text id="txtFilter" #txtFilter
                           class="qs_filter-field" [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </TD>
                <TD class="qs-label-cell" style="min-width: 70px; text-align: center;">
                    Status:</TD>
                <TD style="width: 150px;">
                    <mat-select disableOptionCentering
                                [(ngModel)]="selectedStatusFilter" #cbStatus floatLabel="never"
                                style="font-size: 10pt;width: 120px;"
                                panelClass="local-drop-down-panel" class="dw-mat-select"
                                (selectionChange)="onChangeStatusFilterValue($event)"
                                (keydown)="onStatusFilterKeyDown($event)">
                        <mat-option *ngFor="let item of statusFilterList"
                                    [value]="item">{{item.description}}
                        </mat-option>
                    </mat-select>
                </TD>
                <TD class="qs-pagination-cell" align="right" >{{pagination}} Record(s) ...</TD>
            </TR>
        </table>
    </div>

    <!------------------>
    <!-- ORDERS TABLE -->
    <!------------------>
    <div class="table-container"
        (keydown)="tableKeyDown($event)" tabindex="-1">
        <table mat-table [dataSource]="dataSource" matSort
               style="width:100%; font-size: 9pt;outline: none;" tabindex="-1"
               (matSortChange)="onSortChange($event)">

              <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on
              the row definition" -->

            <ng-container matColumnDef="poNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:120px" >PO Number
                </th>
                <td mat-cell style="width:120px!important;font-size: 9pt;"
                    *matCellDef="let element">{{element.poNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="supplierName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="width:18%"
                    class="mat-table-header-style">Supplier
                </th>
                <td mat-cell style="width:18%!important;font-size: 9pt;"
                    *matCellDef="let element">{{element.supplierName}}</td>
            </ng-container>

            <ng-container matColumnDef="orderStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:120px">PO Status
                </th>
                <td mat-cell style="width:120px!important;font-size: 9pt;"
                    *matCellDef="let element">{{element.orderStatus}}</td>
            </ng-container>

            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:120px">Created
                <td mat-cell style="width:120px!important;font-size: 9pt;"
                    *matCellDef="let element">{{element.dateCreated}}</td>
            </ng-container>

            <ng-container matColumnDef="dateSubmitted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:120px">Submitted
                <td mat-cell style="width:120px!important;font-size: 9pt;"
                    *matCellDef="let element">{{element.dateSubmitted}}</td>
            </ng-container>

            <ng-container matColumnDef="dateClosed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style"
                    style="width:10%"
                >Closed
                </th>
                <td mat-cell style="width:10% !important;font-size: 9pt;"
                    *matCellDef="let element">{{element.dateClosed}}</td>
            </ng-container>
            <ng-container matColumnDef="grandTotal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style"
                    style="width:10%">Grand Total
                </th>
                <td mat-cell style="width:10% !important;font-size: 9pt;"
                    *matCellDef="let element">${{element.grandTotal}}</td>
            </ng-container>
            <ng-container matColumnDef="shippingService">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="width:10%"
                    class="mat-table-header-style">Shipping
                </th>
                <td mat-cell style="width:10% !important;font-size: 9pt;"
                    *matCellDef="let element">{{element.shippingService}}</td>
            </ng-container>
            <ng-container matColumnDef="orderedByName" class="inventories-data-columns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style">Ordered By</th>
                <td mat-cell style="font-size: 9pt;"
                    *matCellDef="let element">{{element.orderedByName}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (click)="onRowClicked(row)" (dblclick)="doDoubleClickRow(row)">
            </tr>
        </table>
    </div>

    <!----------------------->
    <!-- ORDER ITEMS TABLE -->
    <!----------------------->
    <div class="purchase-order-item-container"
         style="overflow:hidden!important;
                  padding-bottom: 40px;">
        <!-------------------------------------->
        <!-- Control Strip                    -->
        <!-------------------------------------->
        <div class="mat-elevation-z2 control-strip" >
            <button *ngIf="visibleAdd" mat-button
                    matTooltipPosition="above" style="margin-top: 5px;"
                    class="command-button" matTooltip="Check in bulk items"
                    (click)="createNewPO()" mat-button>
                <mat-icon>add_circle_outline</mat-icon>
               Create PO
            </button>
            <button *ngIf="visibleAdd" mat-button
                    [disabled]="disableUpdateButton()"
                    matTooltipPosition="above" style="margin-top: 5px;"
                    class="command-button" matTooltip="Create Suggested Order"
                    (click)="updateExistingPO()"
                    mat-button>
                <mat-icon>update</mat-icon>
                Update PO
            </button>
            <button *ngIf="visibleAdd" mat-button
                    [disabled]="disableCancelButton()"
                    matTooltipPosition="above" style="margin-top: 5px;"
                    class="command-button" matTooltip="Create Suggested Order"
                    (click)="cancelSelectedPO()"
                    mat-button>
                <mat-icon>cancel</mat-icon>
                Cancel PO
            </button>
             <button *ngIf="visibleResendBtn" mat-button
                    [disabled]="false"
                    matTooltipPosition="above" style="margin-top: 5px;"
                    class="command-button" matTooltip="Resend Suggested Order"
                    (click)="resendSelectedPO()"
                    mat-button>
                <mat-icon>email</mat-icon>
                Resend PO
            </button>
        </div>

        <!------------------------------->
        <!--  Detail panel             -->
        <!------------------------------->
        <mat-tab-group animationDuration="0ms" tabindex="-1"
                       style="position: relative;height:310px;"
                       (selectedTabChange)="doTabChange($event)"
                       [(selectedIndex)]=selectedTabIndex>
            <mat-tab label="Details" [disabled]="isEditing">

                <div style="border: .5px solid silver; height: 280px;width:100%">

                    <!------------------>
                    <!-- DETAIL TABLE -->
                    <!------------------>

                    <table mat-table [dataSource]="detailDataSource"
                           matSort style="font-size: 9pt;width:100%;">

                        <ng-container matColumnDef="ndcNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mat-table-header-style" style="width:15%" >Part/NDC number
                            </th>
                            <td mat-cell style="width:15%!important;font-size: 9pt;"
                                *matCellDef="let element">{{element.ndcNumber}}</td>
                        </ng-container>

                        <ng-container matColumnDef="itemDescription">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="width:45%"
                                class="mat-table-header-style">Item
                            </th>
                            <td mat-cell style="width:45%!important;font-size: 9pt;"
                                *matCellDef="let element">{{element.itemDescription}}</td>
                        </ng-container>

                        <ng-container matColumnDef="schedule">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="width:8%"
                                class="mat-table-header-style">Schedule
                            </th>
                            <td mat-cell style="width:8%!important;font-size: 9pt;"
                                *matCellDef="let element">{{element.schedule}}</td>
                        </ng-container>


                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="width:8%"
                                class="mat-table-header-style">Qty
                            </th>
                            <td mat-cell style="width:8%!important;font-size: 9pt;"
                                *matCellDef="let element">{{element.quantity}}</td>
                        </ng-container>

                        <ng-container matColumnDef="unitCost">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="width:10%"
                                class="mat-table-header-style">Unit
                            </th>
                            <td mat-cell style="width:10%!important;font-size: 9pt;"
                                *matCellDef="let element">${{element.unitCost}}</td>
                        </ng-container>

                        <ng-container matColumnDef="extendedCost">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mat-table-header-style"
                                style="width:10%">Extended
                            </th>
                            <td mat-cell style="width:10%!important;font-size: 9pt;"
                                *matCellDef="let element">${{element.extendedCost}}</td>
                        </ng-container>
                        <ng-container matColumnDef="itemStatus">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mat-table-header-style" style="width:18%">Status
                            </th>
                            <td mat-cell style="width:18%!important;font-size: 9pt;"
                                *matCellDef="let element">{{element.itemStatus}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedDetlColumns; sticky: true"></tr>
                        <tr mat-row
                            *matRowDef="let row; columns: displayedDetlColumns; let tableRowIdx = index;">
                        </tr>
                    </table>
                </div>

            </mat-tab>
            <mat-tab label="History" [disabled]="isEditing"
                     class="sub-tab-style">
                <transaction-history #history >
                </transaction-history>

            </mat-tab>
        </mat-tab-group>

        <!------------------>
        <!-- CLOSE STRIP --->
        <!------------------>
        <div class="dialog-control-strip-style">
            <button mat-button (click)="doClosePurchaseOrders()">Close</button>
        </div>
    </div>
</div>
<p-dialog header="Add/Edit Purchase Order"
          [appendTo]="'body'"
          [(visible)]="displayAddUpdatePurchaseOrdersDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '1000px', height: '720px'}"
          [resizable]="false" [closable]="true">
       <app-addpurchaseorder #addpurchaseorders
                [siteId]="selectedSite.id"
                (submitAddPOEvent)="doPurchaseOrderAddedUpdated()"
                (cancelAddPOEvent)="displayAddUpdatePurchaseOrdersDialog = false">
       </app-addpurchaseorder>
</p-dialog>
