import { ValidationResult } from './validation-result';

export interface MedicationFamily {
	id: number;
	description: string | null;
	itemPrefix: string | null;
	invWarningThreshold: number | null;
	expirationGraceDays: number | null;
	active: number | null;
	activeStatus: string;
}

export class MedicationFamily {
	constructor() {

		this.id = 0;
		this.description = '';
		this.itemPrefix = '';
		this.invWarningThreshold = 0;
		this.expirationGraceDays = 0;
		this.activeStatus = '';
	}
	validateRecord(): ValidationResult {

		const vr: ValidationResult = new ValidationResult();

		if (this.itemPrefix.length === 0) {
			vr.focus_field = 'itemPrefix';
			vr.error = 'Error: an item prefix is required';
			return vr;
		}
		
		if (this.description.length === 0) {
			vr.focus_field = 'description';
			vr.error = 'Error: a description is required';
			return vr;
		}
		if(this.invWarningThreshold === 0) {
			vr.focus_field = 'inv_warning_threshold';
			vr.error = 'Error: a warning percentage level is required (recommend: 20%)';
			return vr;
		}

		if(this.expirationGraceDays === 0) {
			vr.focus_field = 'expiration_grace_days';
			vr.error = 'Error: a number of days required (recommend: 30)';
			return vr;
		}
		if(this.expirationGraceDays > 100) {
			vr.focus_field = 'expiration_grace_days';
			vr.error = 'Error: expiration grace days is too high (recommend: 30)';
			return vr;
		}
		return vr;
	}

}

