import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
	providedIn: 'root'
})
export class ExcelExportService {

	constructor() {
	}

	static toExportFileName(excelFileName: string): string {
		return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
	}

	public exportArrayOfArrayAsExcelFile(arrayofarray: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arrayofarray);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		XLSX.writeFile(workbook, ExcelExportService.toExportFileName(excelFileName));
	}

	public exportArrayOfObjectsAsExcelFile(arrayofobjects: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arrayofobjects);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		XLSX.writeFile(workbook, ExcelExportService.toExportFileName(excelFileName));
	}

	public exportDataGridAsExcelFile(dg: any, excelFileName: string): void {
		const columns: Array<any> = dg.columns;
		const rows: Array<any> = dg.value;
		const data = new Array<any>();
		let obj = new Array<string>();
		for (let i = 0; i < columns.length; i++) { //Processing header
			//console.log(columns[i].header);
			obj.push(columns[i].header);
		}
		data.push(obj);
		for (let i = 0; i < rows.length; i++) {
			obj = new Array<string>();
			for (let j = 0; j < columns.length; j++) {
				if (columns[j].labelFunction === undefined || columns[j].labelFunction === null) {
					obj.push(rows[i][columns[j].field]);
				} else {
					obj.push(columns[j]['labelFunction'](rows[i], columns[j]));
				}
			}
			data.push(obj);
		}
		this.exportArrayOfArrayAsExcelFile(data, excelFileName);
	}
	public exportDataGridAsExcelFileCustomisedForRegisters(dg: any, excelFileName: string): void {
		const columns: Array<any> = dg.columns;
		const rows: Array<any> = dg.value;
		const data = new Array<any>();
		let obj = new Array<string>();
		for (let i = 0; i < columns.length; i++) { //Processing header
			//console.log(columns[i].header);
			obj.push(columns[i].header);
		}
		data.push(obj);
		for (let i = 0; i < rows.length; i++) {
			obj = new Array<string>();
			for (let j = 0; j < columns.length; j++) {
				if (columns[j].labelFunction === undefined || columns[j].labelFunction === null) {
					obj.push(rows[i][columns[j].field]);
				} else {
					if (columns[j].field === 'status') {
						obj.push(this.registerEntryStatusLabelFunction(rows[i][columns[j].field]));
					} else {
						obj.push(columns[j]['labelFunction'](rows[i], columns[j]));
					}
				}
			}
			data.push(obj);
		}
		this.exportArrayOfArrayAsExcelFile(data, excelFileName);
	}
	public registerEntryStatusLabelFunction(status): string {

		switch (status) {
			case 1:
				return 'Open';
			case 2:
				return 'On-Hold';
			case 3:
				return 'Cancelled';
			case 0:
				return 'Closed';
			default:
				return '';
		}
	}
}
