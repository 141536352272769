/************
 * Model Class
 * File Name ...: finishedforms.components.ts
 * Generated By : ianday on 8/1/23
 * Date ........: 8/1/23
 /************/
import {ValidationResult} from './validation-result';
import {Forming} from './forming';
import {FormularyMedicationLabel} from './formulary-label';
import {BprBulkItems} from './bprbulkitems';
import {FormularyMedication} from './formulary-medication';

export interface FinishedForms {

    id: number;
    bprId: number;
    customerId: number;
    formularyMedicationId: number;
    orderId: number;
    orderItemId: number;
    itemNumber: string;
    rxNumber: string;
    expirationDate: string;
    lotNumber: string;
    ndcNumber: string;
    directions: string;
    directionsAlt: string;
    precautions: string;
    precautionsAlt: string;
    unitPrice: number;
    retailPrice: number;
    labelFileId: number;
}

export class FinishedForms {
    constructor() {
        this.id = 0;
        this.bprId = 0;
        this.customerId = 0;
        this.formularyMedicationId = 0;
        this.orderId = 0;
        this.orderItemId = 0;
        this.itemNumber = '';
        this.rxNumber = '';
        this.expirationDate = '';
        this.lotNumber = '';
        this.ndcNumber = '';
        this.directions = '';
        this.directionsAlt = '';
        this.precautions = '';
        this.precautionsAlt = '';
        this.unitPrice = 0;
        this.retailPrice = 0;
        this.labelFileId = 0;
    }

    public setFinishedForm(fm: FormularyMedication,
                           form: Forming,
                           bpri: BprBulkItems,
                           labelId: number): void {
        this.id = 0;
        this.bprId = bpri.bprId;
        this.customerId = fm.customerId;
        this.formularyMedicationId = fm.id;
        this.orderId = form.orderId;
        this.orderItemId = form.itemId;
        this.rxNumber = '';
        this.expirationDate = bpri.expirationDate;
        this.lotNumber = bpri.lotNumber;
        this.ndcNumber = bpri.ndcNumber;
        this.directions = fm.directions;
        this.directionsAlt = fm.altDirections;
        this.precautions = fm.precautions;
        this.precautionsAlt = fm.altPrecautions;
        this.retailPrice = 0;
        this.unitPrice = 0;
        this.labelFileId = labelId;

    }
    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        return vr;
    }
}
