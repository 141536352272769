<div style="overflow: hidden">
  <div class="confirmation-dialog-header">
        {{title}}
  </div>
  <div mat-dialog-content class="custom-dialog-container"
      [innerHtml]="message">
  </div>
  <div *ngIf="DoNotShowAgainKey!= ''" class="dont-show-again-strip">
    <mat-checkbox *ngIf="DoNotShowAgainKey!= ''"
       [(ngModel)]="DoNotShowAgainFlag">Do not show this dialog box again</mat-checkbox>
  </div>
  <div class="control-buttons-dialog-strip">
    <button mat-button class="dialog-button dialog-button-override" (click)="onDismiss()" >No</button>
    <button mat-button class="dialog-button dialog-button-override" (click)="onConfirm()">Yes</button>
  </div>
</div>
