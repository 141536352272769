<!--
    file type .....: HTML file: returnmanu.component.html
    Created By ....: ianday
    Date ..........: 4/19/23 
-->
<div class="invtransfer-container">
    <table style="width:100%">
        <tr>
            <td style="width:30%;">
                <div class="dwform" style="padding-right: 15px; font-size: 12px;" tabindex="-1">

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">{{medOrMat}}:</label>
                        <span class="form-required"></span>
                        <label class="label-field label-field-display-data"
                               style="font-weight: bold">{{InventoryItem}}</label>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Number of Units:</label>
                        <span class="form-required"></span>
                        <label class="label-field label-field-display-data">{{numUnits}}</label>
                    </div>
                    <div class="dwform-row-gap"></div>

                    <mat-divider></mat-divider>
                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Reason:</label>
                        <span class="form-required">*</span>
                        <mat-select
                                disableOptionCentering
                                [(value)]="selectedReasonId" #cbReason floatLabel="never"
                                class="dw-mat-select"
                                style="font-size: 10pt;width: 260px;"
                                panelClass="local-drop-down-panel"
                                placeholder="- Please Select -"
                                (selectionChange)="onChangeReason($event)">
                            <mat-option *ngFor="let reason of reasons"
                                        [value]="reason.id">{{reason.desc}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field-text-area">Comments:</label>
                        <span class="form-required">*</span>
                        <textarea #selectCat class="input-field-long" id="ta01id"
                                  [(ngModel)]="returnComment"
                            prompt="<Enter comment>"
                            style="height: 120px;font-family: Roboto;resize: none;"></textarea>
                    </div>

                    <!-- ends the form -->
                    <div [hidden]='!strErr.length' style="left: 10px; bottom: 40px;"
                         class="error-line">{{strErr}}</div>
                    <!-- save strip -->
                    <div class="save-close-strip" style="bottom: 5px;">

                        <button mat-button class="command-button" [disabled]="!(selectedReasonId > 0)"
                                (click)="submitReturnItems()" matTooltip="Mark items as returned."
                                mat-button>
                            <mat-icon>check_circle_outline</mat-icon>
                            Submit
                        </button>
                        <button mat-button class="command-button"
                                (click)="cancelSubmitRecord()" matTooltip="Cancel transaction" mat-button>
                            <mat-icon>highlight_off</mat-icon>
                            Cancel
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
