
export interface MedicationImage {
  id: number;
  medicationId: number;
  imageType: string | null;
  imageUrl: string | null;
  dateUploaded: number | null;
  uploadedBy: string | null;
  activeFlag: number | null;
  defaultFlag: number | null;
  fullUrl: string | null;
}

export class MedicationImage {

}


