import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SidenavService } from './services/sidenav.service';
import { AuthService } from './services/authservice/auth.service';
import { HttpClient } from '@angular/common/http';
import { Settings } from './services/settings';
import { GlobalService } from './services/global-service';
import { UserService } from './services/user.service';
import { GeneralSetting } from './models/generalsetting';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  static myApp;

  title = 'rpp';
  name = 'Angular';
  enableMessageBanner = Settings.getInstance().enableMessageBanner;
  strBannerText = Settings.getInstance().strBannerText;
  bgStyleString = '';
  contentClassWithBanner = 'main-content';
  contentClassWithoutBanner = 'main-content-without-header';
  contentClassName = 'main-content';

  /*** Set this variable to false to interact with web-services.
   * when this is true it should use the data files stored in the
   * models folder
   ****/
  static staticDataSource = false;
  private IsAuthenticated: boolean;
  public onSideNavChange: boolean;
  public fullScreen = false;

  messageDlgDisplay = false;
  messageDialogData: any;

  ngOnInit() {
	this.fullScreen = false;
    AppComponent.myApp = this;
    this.globalService.globalEvent.subscribe((event) => {this.refreshSettings(); });

	if (Settings.getInstance().enableMessageBanner) {
        this.contentClassName = this.contentClassWithBanner;
    } else {
        this.contentClassName = this.contentClassWithoutBanner;
    }

     Settings.getInstance().loginRequestEvent.subscribe(() => {
        this.router.navigate(['login']);
    });

	Settings.getInstance().showMessageEvent.subscribe((data) => {
		if(this.messageDlgDisplay)
			return;

		data.showCancel = false;
		data.showOk = false;
		data.showNo = false;
		data.showYes = false;
		data.showConfirm = false;
		data.showClose = true;
		this.messageDlgDisplay = true;
		this.messageDialogData = data;

        this.router.navigate(['login']);
	});
  }
  constructor(private sidenavService: SidenavService, private globalService: GlobalService, private userService: UserService,
              private router: Router, private route: ActivatedRoute, private authService: AuthService, private http: HttpClient, private datePipe: DatePipe) {
        this.sidenavService.sideNavState$.subscribe( res => {
          this.onSideNavChange = res;
          this.authService.isAuthenticated$.subscribe(value => {this.IsAuthenticated = value; });
        });
        this.getConfigXml();
    }
  settingsLoaded() {
      this.enableMessageBanner = Settings.getInstance().enableMessageBanner;
      if (!Settings.getInstance().enableMessageBanner) {
          this.contentClassName = this.contentClassWithoutBanner;
          return;
      } else {
          this.contentClassName = this.contentClassWithBanner;
      }
      if (Settings.getInstance().strBannerText === '' || Settings.getInstance().strBannerText === undefined) {
          const date = new Date();
          this.strBannerText = this.datePipe.transform(date, 'MM/dd/yyyy');
      } else {
          this.strBannerText = Settings.getInstance().strBannerText;
      }
      let bgColorSetting: GeneralSetting = Settings.getInstance().getGeneralSettingByKey('bannerbgcolor');
      if (bgColorSetting === null) {
          bgColorSetting = new GeneralSetting();
          bgColorSetting.key = 'bannerbgcolor';
          bgColorSetting.value = '#f3f3f3';
      }
      (async () => { 
          await this.delay(1000);
          document.getElementById('bannerDiv').style.background = bgColorSetting.value;
      })();
  }
  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }
  refreshSettings() {
      this.userService.getAllSettings().subscribe(
              ( data2 ) => {
                 Settings.getInstance().generalSettingsList = data2;
                 this.enableMessageBanner = Settings.getInstance().enableMessageBanner;
                 if (!Settings.getInstance().enableMessageBanner) {
                     this.contentClassName = this.contentClassWithoutBanner;
                     return;
                 } else {
                     this.contentClassName = this.contentClassWithBanner;
                 }
                 if (Settings.getInstance().strBannerText === '' || Settings.getInstance().strBannerText === undefined) {
                     const date = new Date();
                     this.strBannerText = this.datePipe.transform(date, 'MM/dd/yyyy');
                 } else {
                     this.strBannerText = Settings.getInstance().strBannerText;
                 }
                 let bgColorSetting: GeneralSetting = Settings.getInstance().getGeneralSettingByKey('bannerbgcolor');
                 if (bgColorSetting === null) {
                     bgColorSetting = new GeneralSetting();
                     bgColorSetting.key = 'bannerbgcolor';
                     bgColorSetting.value = '#f3f3f3';
                 }
                 (async () => {
                     await this.delay(2000);
                     document.getElementById('bannerDiv').style.background = bgColorSetting.value;
                 })();
              },
              ( error2 ) => {}
      );
  }
  getConfigXml() {
	/*
      this.http.get('./config/config.xml', { responseType: 'text' })
       .subscribe(data => {
           if(data) {
               const parser = new DOMParser();
               const xmlData = parser.parseFromString(data, 'application/xml');
               Settings.getInstance().BASE_URL =  xmlData.children[0].getElementsByTagName('BASE_URL')[0].textContent;
			   if(Settings.getInstance().BASE_URL.indexOf('{HOST_NAME}') != -1) {
				Settings.getInstance().BASE_URL = Settings.getInstance().BASE_URL.replace('{HOST_NAME}', window.location.hostname); 
			   }
               Settings.getInstance().version =  xmlData.children[0].getElementsByTagName('Version')[0].textContent;
               Settings.getInstance().release =  xmlData.children[0].getElementsByTagName('Release')[0].textContent;
               Settings.getInstance().lastUpdated =  xmlData.children[0].getElementsByTagName('LastUpdated')[0].textContent;

               this.authService.getLoggedinUser();
           }
       });
	*/
     }
  messageCloseHandler(event) {
		this.messageDlgDisplay = false;
	}
}
