import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  DoNotShowAgainFlag = false;
  DoNotShowAgainKey: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.DoNotShowAgainKey = data.DoNotShowAgainKey;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    localStorage.setItem(this.DoNotShowAgainKey, this.DoNotShowAgainFlag?"1":"0");
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    //localStorage.setItem(this.DoNotShowAgainKey, this.DoNotShowAgainFlag?"1":"0");
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string, public DoNotShowAgainKey: string = "") {
  }
}
