export interface FormularyMedicationLabel {
	id: number;
	labelFilePath: string | null;
	uploadedDate: Date | null;
	uploadedBy: string | null;
	defaultFlag: number | null;
	customerId: number;
	formularyMedicationId: number;
	
	labelURL: string;
}
export class FormularyMedicationLabel {
	constructor() {
		this.id = 0;
	}
}
