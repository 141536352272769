import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-row',
  templateUrl: './report-row.component.html',
  styleUrls: ['./report-row.component.css']
})
export class ReportRowComponent implements OnInit {

  @Input() rowData: any[];
  constructor() { }

  ngOnInit() {
  }
  getCellClass(value:object, index:number): string {
    let cellClass = 'ui-g-1';
    if(value['width'] === 'auto') {
      if(value['label'].length > 10) {
        cellClass = 'ui-g-2';
      } else if(value['label'].length > 20) {
        cellClass = 'ui-g-3';
      } else if(value['label'].length > 30) {
        cellClass = 'ui-g-4';
      } else if(value['label'].length > 40) {
        cellClass = 'ui-g-5';
      } else if(value['label'].length > 50) {
        cellClass = 'ui-g-6';
      } else {
        cellClass = 'ui-g-1';
      }
    } else {
      cellClass = ' ' + value['width'];
    }
    if(value['classList'] != null && value['classList'].length !== 0) {
      cellClass += ' ' + value['classList'];
    }
    if(cellClass.indexOf('header-row') === -1) {
//      if(index%2 === 1) {
//        cellClass += ' odd-row';
//      } else {
//        cellClass += ' even-row';
//      }
    }
    return cellClass;
  }
}
