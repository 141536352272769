/**
 *  Filename ....: returnmanu.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/19/23
 **/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BulkUnitsService} from '../../../../../../services/bulkunits.service';
import {TransactionHistoryService} from '../../../../../../services/transactionhistory.service';
import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {Settings} from '../../../../../../services/settings';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DwUtils} from '../../../../../../components/utils/dw-utils';

@Component({
    selector: 'app-returnmanu',
    templateUrl: './returnmanu.component.html',
    styleUrls: ['./returnmanu.component.css']
})

export class ReturnManuComponent implements OnInit {

    public strErr = '';
    public numUnits: number;
    public _invItem: string;
    public _selectedItems: any;
    public _medOrMat = 'Medication';
    public reasons: any;
    public selectedReasonId: number | null = 0;
    public returnComment: string;
    public Itr: number;
    public bulkUnitsService: BulkUnitsService;
    public txService: TransactionHistoryService;

    @Input() set medOrMat(val: any) {
        this._medOrMat = val;
    }
    get medOrMat(): any {
        return this._medOrMat;
    }
    @Input() set InventoryItem(val: any) {
        this._invItem = val;
    }
    get InventoryItem(): string {
        return this._invItem;
    }
    @Input() set selectedItems(val: any) {
        if (val === '' || val.length === 0) {
            return;
        }
        this._selectedItems = val;
        this.numUnits = this._selectedItems.length;
    }
    get selectedItems(): any {
        return this._selectedItems;
    }

    @Output() returnInvItems = new EventEmitter();
    @Output() cancelReturnInvItems = new EventEmitter();

    constructor(buService: BulkUnitsService,
                private dialog: MatDialog,
                tService: TransactionHistoryService) {
        this.bulkUnitsService = buService;
        this.txService = tService;
        this.setReasons();
    }

    ngOnInit() {
        this.returnComment = '';
    }

    setReasons(): void
    {
       this.reasons = [ { id: 1, desc: 'Medication nearing grace period'},
        { id: 2, desc: 'Medication has expired'},
        { id: 3, desc: 'Medication has been recalled'},
        { id: 4, desc: 'Medication container is damaged or opened'},
        { id: 5, desc: 'Other - Please see comments'} ];
    }
    onChangeReason(event: any): void {

    }
    setValues() {
        // empty
    }
    validateRecord(): boolean {

        if (this.returnComment.length === 0) {
            this.strErr = 'Error: A comment is required';
            DwUtils.setFocus('ta01id');
            return false;
        }
        return true;
    }
    submitReturnItems(): void {

        if(this.validateRecord() === false) {
            return;
        }
        let result;
        const message = 'Return selected items to the Manufacturer <br /> Are you sure? ';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            result = dialogResult;
            if (result === true) {
                this.Itr = 0;
                this.performReturnItem();
            }
        });
    }
    performReturnItem(): void {

        if (this.Itr <= this.selectedItems.length - 1) {
            this.updateRecords();
        }
        else {
            this.returnInvItems.emit();
        }
    }
    updateRecords(): void {

        this.selectedItems[this.Itr].containerStatus = 'Returned';
        this.bulkUnitsService.updateBulkUnits(this.selectedItems[this.Itr]).subscribe(
            (data) => {
                this.createTxRecord(this.selectedItems[this.Itr].id);
                this.Itr++;
                this.performReturnItem();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Returning item to Manufacturer');
            });
    }
    createTxRecord(keyId: number): void {

        let newVal = 'Reason: ' + this.reasons.find(x => x.id === this.selectedReasonId).desc;
        newVal += ' - ' + this.returnComment;
        this.txService.createTransactionHistory('bulkunits', keyId, 'Return', 'Returned Item To Manufacturer', newVal);
    }
    cancelSubmitRecord(): void {
        this.cancelReturnInvItems.emit();
    }
}


