<!--
    file type .....: HTML file: add-licensestateurl.component.html
    Created By ....: ianday
    Date ..........: 3/10/23 
-->
<div class="add-licensestateurl-container" tabindex="-1">
    <div class="dwform" style="padding-right: 15px;" tabindex="-1">

        <div class="dwform-cell-nopadding"
             (keyup)="this.strErr = ''"
             style="min-width: 500px;" tabindex="-1">

            <!-- License Type ---->
            <div class="dwform-row">
                <label class="label-field">License Type:</label>
                <span class="form-required">*</span>
                <label class="label-field" style="width: 300px">{{licenseType}}</label>
            </div>

            <!-- Practice State --->
            <div class="dwform-row-gap"></div>

            <div class="dwform-row">
                <label class="label-field">Practice State:</label>
                <span class="form-required">*</span>
                <label class="label-field" style="width: 300px">{{practiceState}}</label>
            </div>

            <!-- add a dividing line for visual separation -->
            <div class="dwform-row-gap"></div>
            <mat-divider> </mat-divider>

            <!-- New URL --->

            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Default URL:</label>
                <span class="form-required">*</span>
                <input class="input-field-long" id="url"
                       [(ngModel)]="url"
                       placeholder="<Paste your URL>"
                       type="text">
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
