<!--
    file type .....: HTML file: addressbook.component.html
    Created By ....: ianday
    Date ..........: 2/22/23 
-->
<div class="addressbook-container">
<as-split direction="vertical">
    <!-- addresses -->
    <as-split-area [size]="50"  style="min-height: 40px">
        <app-addresses [callingComponent]="callingComponent" [parentId]="parentId" [maxAddresses]="maxAddresses"
        (deleteMessage)="showDeleteMessage($event)">

        </app-addresses>
    </as-split-area>

    <!-- contacts -->
    <as-split-area [size]="50" >
        <app-contacts [callingComponent]="'addresses'"
        (deleteMessage)="showDeleteMessage($event)">
        </app-contacts>
    </as-split-area>

</as-split>
</div>
<!------------------------------>
<!-- DIALOGS and POPUPS       -->
<!------------------------------>

<p-dialog header="Delete Message"
          [(visible)]="displayErrorDlg"
          [modal]="true"
          appendTo="body" [responsive]="true"
          [style]="{width: '305px', height: '162px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">

    <div style="width:100%; text-align:center;padding-bottom: 10px;">

        <b>{{this.displayMessage}}</b>

        <div style="width:100%; padding-top:15px; text-align: center">
            <button mat-button class="command-button" (click)="displayErrorDlg=false">
                <mat-icon>highlight_off</mat-icon>
                Close</button>
        </div>
    </div>
</p-dialog>
