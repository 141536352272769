<!--
    file type .....: HTML file: imgverify.component.html
    Created By ....: ianday
    Date ..........: 4/28/23 
-->
<div class="imgverify-container">
    <span>Image Verification for NDC:
       <span class="span-ndc-bold">{{ndcNumber}}</span>
    </span>

    <div class="table-container" (keydown)="tableKeyDown($event)"
         style="outline: none!important;"
         tabIndex="-1">
        <table id="medication-images-table" mat-table tabindex="-1" [dataSource]="medicationImages"
               (mouseleave)="doMouseOutImage()"
               style="width: 100%;padding-left: 4px; padding-right:4px;">
            <!--  Column -->
            <ng-container matColumnDef="validated">
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"
                    style="text-align:left">Match</th>
                <td mat-cell *matCellDef="let element;let tableRowIdx = index;"
                    style="width:10%;text-align:center;">
                <mat-checkbox (click)="$event.stopPropagation()"
                    [value]="element.select" [(ngModel)]="element.select" >
                </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="imageType">
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"
                    style="width: 100%; text-align:center">Type</th>
                <td mat-cell *matCellDef="let medImage" minWidth="50px;"
                    style="width: 100%;text-align:center;"><span>
                    {{medImage.imageType}} </span></td>
            </ng-container>

            <ng-container matColumnDef="imageUrl">`
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"
                    style="width: 60%">Image</th>
                <td mat-cell *matCellDef="let medLink" fxLayout="column wrap"
                    style="width: 60%;">
                    <img style="width:100px;height: 70px;"
                         (mouseover)="doMouseOverImage($event)"
                         [src]="BASE_URL+'medications/medication-images/file/'+medLink.id"> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"

                (click)="onRowClicked(row)">
            </tr>
        </table>
    </div>

    <div [hidden]='!strErr.length'
         class="error-line" style="left: 18px; bottom: 38px;">{{strErr}}</div>
    <!--save strip -->
    <div class="save-close-strip" style="bottom: 5px;">

        <button mat-button class="command-button"
                [disabled]="!hasCheckedItems()"
                (click)="doSubmitImgVerify()"
                matTooltip="Submit the selected record"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Verified
        </button>
        <button mat-button class="command-button"
                (click)="doCancelImgVerify()"
                matTooltip="Cancel submission" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>

<p-dialog header="Add Image"
          [showHeader]="false"
          [(visible)]="dispLargeImage"
          [modal]="false"
          [style]="{position: 'absolute', right: '80px'}"
          appendTo="body" [responsive]="true"
          [resizable]="false" [closable]="false">
    <img src="{{largeImgUrl}}" height="250px" width="600px" alt="something"  >
</p-dialog>
