import {Input, Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { AppComponent } from '../../app.component';
import { ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../../services/authservice/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  processingLogin = false;
  public rememberMe = false;
  userNameStored = '';
  @Input() error: string | boolean;
  @ViewChild('username', { static: true }) username: ElementRef;
  @ViewChild('password', { static: true }) password: ElementRef;
  constructor(private authService: AuthService, private route: ActivatedRoute ) {
  }

 form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });

  ngOnInit() {
	  this.error = '';
	  this.userNameStored = this.route.snapshot.queryParams['u'];
	  if(this.userNameStored != null && this.userNameStored != undefined && this.userNameStored.length > 0) {
		this.form.get('username').setValue(this.userNameStored);
		this.password.nativeElement.focus();
	  } else {
	      const userNameStored = localStorage.getItem('ppms_user_name');
	      if (userNameStored !== undefined && userNameStored !== null && userNameStored !== '') {
	          this.userNameStored = userNameStored;
	          this.form.get('username').setValue(userNameStored);
	          this.rememberMe = true;
	          this.password.nativeElement.focus();
	      } else {
	        this.rememberMe = false;
	      }
	  }

      this.authService.authenticationError$.subscribe((error) => {
          if (error === 'Unauthorized') {
              this.error = 'Incorrect user name or password';
          } else if (error === undefined  || error === false) {
              this.error = '';
          } else if (error === 'Unknown Error') {
              this.error = 'Server connection error';
          } else {
              this.error = error.toString(); // 'Incorrect user name or password';
          }
          this.processingLogin = false;
    });
      this.authService.isAuthenticated$.subscribe((data) => {
        this.processingLogin = false;
      });
  }
 submit() {

      if (!this.chkEdits()) {
        return;
      }

      if (this.rememberMe) {
          localStorage.setItem('ppms_user_name', this.form.value.username);
      } else {
          localStorage.removeItem('ppms_user_name');
      }
      if (AppComponent.staticDataSource === true) {
          this.processingLogin = true;
          this.authService.login_static(null, null);
      } else {
          this.processingLogin = true;
          this.authService.login(this.form.value.username, this.form.value.password);
      }
  }
  chkEdits(): boolean  {

     if ( this.form.value.username.length === 0) {
       this.error  = 'A user name is required';
       this.username.nativeElement.focus();
       return false;
     }

     if (this.form.value.password.length === 0) {
         this.error = 'A password is required';
         this.password.nativeElement.focus();
         return false;
     }
     return true;
  }

 clearErrorMessage() {
     this.error = '';
 }
}
