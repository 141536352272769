<div class="group-container">
<div class="query-strip-container mat-elevation-z2" >
  <table height="100%"  width="100%" >
    <TR> <TD class="table-title-cell">
      <i class="fa fa-address-card fa-fw table-icon-header" style="width: 2em"></i> {{formTitle}}</TD>
  <TD align="center">

    <input text id="txtFilter" #txtFilter class="qs_filter-field" [disabled]="this.isEditing"
           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
    <button mat-button matSuffix mat-icon-button matTooltip="Clear the filter" aria-label="Clear"
            (click)="txtFilter.value=''; clearFilter();">
      <mat-icon>close</mat-icon>
    </button>
    <TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
    <TD class="qs-title-cell" style="width:150px">
      <mat-select [(value)]="selectedFilterStatus"
                  disableOptionCentering
                  style="font-size:10pt; width: 150px;"
                  #cbStatus floatLabel="never"
                  class="dw-mat-select"
                  panelClass="local-drop-down-panel"
                  (selectionChange)="onChangeStatusFilterValue()">
        <mat-option value=2>All</mat-option>
        <mat-option value=1>Active</mat-option>
        <mat-option value=0>Inactive</mat-option>
      </mat-select>
  </TD>
      <TD class="table-pagination-cell" align="right">
        {{pagination}} Record(s) ...
      </TD>
  </TR>
  </table>
</div>

<!-- table -->
<div [ngClass]="contentClassName"  class="mat-elevation-z2" (keydown)="tableKeyDown($event)" tabindex="-1">
  <table mat-table [dataSource]="dataSource" (matSortChange)="onSortChange($event)" matSort >

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style"> Id </th>
      <td mat-cell *matCellDef="let element;let tableRowIdx = index;"> {{element.id}} </td>
    </ng-container>


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style"> Group Description </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

      <!-- active state Column -->
      <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style"> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.displayStatus}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
        [ngClass]="{'row-selected': tableRowIdx == Idx}"
        (click)="onRowClicked(row)">
    </tr>
  </table>
</div>

<!-- Control strip -->
<div class="mat-elevation-z2 control-strip">
  <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing" class="command-button"

          matTooltip="Add a new record"
          (click)="addModifyRecord(true)" mat-button>
    <mat-icon>add_circle_outline</mat-icon>
    Add New</button>
  <button *ngIf="visibleModify" mat-button class="command-button"
          style="margin-top: 5px;"
          [disabled]="(Idx < 0|| this.isEditing)" matTooltip="Update a selected record"
          (click)="addModifyRecord(false)" mat-button>
    <mat-icon>edit</mat-icon>
    Update
  </button>
  <button *ngIf="visibleDelete" mat-button class="command-button"
          style="margin-top: 5px;"
         [disabled]="(Idx < 0 || this.isEditing || enableDelete === false)"  matTooltip="Remove a selected record"
          (click)="removeRecord()" mat-button>
    <mat-icon>remove_circle_outline</mat-icon>
    Remove
  </button>
</div>

<!-- detail container -->
<div class="group-mgmt-container">
  <div class="dwform" style="padding-right:15px;" [formGroup]="formContent">
    <div class="dwform-cell" style="min-width:480px;">
      <div class="dwform-row-double-gap"></div>

        <div class="dwform-row">
          <label class="label-field">Record ID:</label>
          <span class="form-required">*</span>
          <input class="input-field-short" placeholder="<Id>" formControlName="id" type="text">
        </div>

      <div class="dwform-row-gap"></div>

      <div class="dwform-row">
        <label class="label-field">Description:</label>
        <span class="form-required">*</span>
        <input class="input-field" #groupname placeholder="<Group Description>" formControlName="groupname" type="text">
      </div>

      <div class="dwform-row-gap"></div>

      <div class="dwform-row">
        <label class="label-field">Active Status:</label>
        <span class="form-required">*</span>

        <mat-select
                #cbDetailStatus
                disableOptionCentering
                placeholder="- Please Select -"
                class="dw-mat-select"
                panelClass="local-drop-down-panel"
                formControlName="active"
                style="width: 150px;font-size: 10pt;" [style.backgroundColor]="disabledFlag?'whitesmoke':'white'" >
          <mat-option value="Active">Active</mat-option>
          <mat-option value="InActive">InActive</mat-option>
        </mat-select>


      </div>

	</div>

    <div class="dwform-cell " style="min-width:520px;">
      <label class="label-field-free">App Functions List:</label>
      <input class="filter-box qs_filter-field" [ngModelOptions]="{standalone: true}" [(ngModel)]="appFunctionSearchStr" placeholder=" - Search - " (ngModelChange)="appFunctionFilterChangehandler($event)"/>
      <label class="label-field-free" style="float: right;margin-right: 2rem;">Read Only</label>
      <mat-selection-list id="groupAppFunctionsList" [disabled]="disabledFlag" style="width:500px;">
        <mat-list-item *ngFor="let appFunction of filteredAppFunctionsList" [value]="appFunction.id">
          <div style="width: 100%">          	
          	<mat-checkbox [(ngModel)]="appFunction.selected" [ngModelOptions]="{standalone: true}" [style]="{'color': 'darkgray'}" [disabled]="disabledFlag" (change)="onAppFunctionSelectionChange(appFunction, $event)">{{appFunction.label}}</mat-checkbox>
          	<mat-checkbox [(ngModel)]="appFunction.readOnly" [ngModelOptions]="{standalone: true}" [style]="{'float': 'right', 'color': 'darkgray'}" [disabled]="disabledFlag || !appFunction?.selected"  (change)="onAppFunctionReadOnlyChange(appFunction, $event)"></mat-checkbox>
          </div>
        </mat-list-item>
      </mat-selection-list>
    </div>
    <div class="dwform-cell" style="width:100%;"></div>
  </div>


  <div [hidden]='!strErr.length' style="left:10px;" class="error-line">{{strErr}}</div>

    <div class="save-close-strip" style="bottom:5px;">

      <button mat-button class="command-button"
              *ngIf="this.isEditing"
              (click)="submitRecord()" matTooltip="Submit the selected record" mat-button>
        <mat-icon>check_circle_outline</mat-icon>Submit</button>
      <button mat-button class="command-button"
              *ngIf="this.isEditing"
              (click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
        <mat-icon>highlight_off</mat-icon>Cancel</button>

    </div>
  </div>
</div>
