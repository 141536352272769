import { Injectable,  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppMapComponent} from '../app-map.component';
import { Settings} from '../services/settings';

@Injectable({providedIn: 'root'})

export class AppMapService {

  constructor(private http: HttpClient, private appMap: AppMapComponent) {
  }

  public async getSettings()  {
      await this.getApplicationConfigurationFile();
  }

  public async getApplicationConfigurationFile() {
    const jsonFile = `./assets/application_map/application.json`;

    const promise: Promise<any> = this.http.get(jsonFile)
      .toPromise()
      .then(settings => {

			/* set the application title */
			const appKey = 'application_name';
			this.appMap.applicationName = settings[appKey];
			this.appMap.appTitle = this.appMap.applicationName;

			this.appMap.navItems.push({id: 'nav-home', name: 'Home', link: '/home', mouseover: 'Home', icon: 'home'});
            this.appMap.navItems.push({id: 'nav-forming', name: 'Forming', link: '/forming', mouseover: 'Forming Workspace', icon: 'storage'});
            this.appMap.navItems.push({id: 'nav-inventory', name: 'Inventory', link: '/inventory', mouseover: 'Inventory Portal', icon: 'library_books'});
			this.appMap.navItems.push({id: 'nav-settings', name: 'Settings', link: '/settings', mouseover: 'Manage Settings', icon: 'settings'});
      });
    return promise;
  }


}
