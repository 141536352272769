import { Component, OnInit } from '@angular/core';
import { UserOTP } from 'src/app/models/user-otp';
import { UserService } from 'src/app/services/user.service';
import { CodeInputComponent } from 'angular-code-input';

@Component({
	selector: 'app-forgotpassword',
	templateUrl: './forgotpassword.component.html',
	styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
	showEmailPrompt = true;
	showOTPPrompt = false;
	strEmail: string;
	strOTP: string;
	strPassword1: string;
	strPassword2: string;
	strMessage: string;
	processing = false;
	resentOTPFlag = false;
	constructor(private userService: UserService) { }

	ngOnInit() {
		this.strMessage = '';
	}
	sendOtp() {
		this.strMessage = '';
		this.processing = true;
		this.userService.initiatePasswordReset(this.strEmail).subscribe(
			(data) => {
				this.processing = false;
				if (data['message'] !== undefined && data['message'].includes('Recovery email sent')) {
					if (this.showOTPPrompt === true) {
						this.resentOTPFlag = true;
					}
					this.showOTPPrompt = true;
					this.showEmailPrompt = false;
				} else {
					this.strMessage = 'Error: ' + data['message'];
				}
			},
			(error) => {
				this.strMessage = 'Error: Please enter your email';
				this.processing = false;
			}
		);
	}
	onCodeChanged(evt: any) {
		// nothing.
	}
	onCodeCompleted(evt: any) {
		this.strOTP = evt;
	}
	sendPasswordResetRequest() {
		this.strMessage = '';
		if (this.strOTP === undefined || this.strOTP === '') {
			this.strMessage = 'Error: Please enter OTP';
			return;
		}
		if (this.strPassword1 === undefined || this.strPassword1 === '') {
			this.strMessage = 'Error: Please enter new password';
			return;
		}
		if (this.strPassword2 === undefined || this.strPassword2 === '') {
			this.strMessage = 'Error: Please re-type password';
			return;
		}
		if (this.strPassword1 !== this.strPassword2) {
			this.strMessage = 'Error: Passwords do not match!';
			return;
		}
		let userOTPRecord: UserOTP;
		userOTPRecord = new UserOTP();
		userOTPRecord.email = this.strEmail;
		userOTPRecord.password = this.strPassword1;
		userOTPRecord.otp = this.strOTP.trim();
		this.processing = true;
		this.userService.setNewPasswordWithOTP(userOTPRecord).subscribe(
			(data) => {
				this.processing = false;
				if (data['message'] !== undefined && data['message'].includes('Password reset successfull')) {
					this.showEmailPrompt = false;
					this.showOTPPrompt = false;
				} else {
					this.strMessage = 'Error: ' + data['message'];
					this.processing = false;
				}
			},
			(error) => {
				this.processing = false;
			}
		);
	}
}
