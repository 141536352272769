<div class="query-strip-container mat-elevation-z2">
	<table height="100%" width="100%">
		<TR>
			<TD class="table-title-cell"><i
				class="fa fa-cog table-icon-header" style="width: 2em"></i>{{formTitle}}</TD>
		</TR>
	</table>
</div>

<div style="margin-left: 20px; margin-bottom: 20px;">
	<div class="dwform">

		<div class="dwform-row-gap"></div>
		<div class="dwform-row">

			<label class="label-field">SMTP Host:</label> <input
				class="input-field" style="width: 400px" type="text"
				[(ngModel)]="valSMTPHost" [disabled]="!editMode">
			<div class="dwform-row-gap"></div>

			<label class="label-field">SMTP Port:</label> <input
				class="input-field" style="width: 100px" type="text"
				[(ngModel)]="valSMTPPort" [disabled]="!editMode">
			<div class="dwform-row-gap"></div>

			<label class="label-field">User name:</label> <input
				class="input-field" style="width: 400px" type="text"
				[(ngModel)]="valSMTPUsername" [disabled]="!editMode">
			<div class="dwform-row-gap"></div>

			<label class="label-field">Password:</label> <input
				class="input-field" type="password" style="width: 400px"
				[(ngModel)]="valSMTPPassword" [disabled]="!editMode">
			<div class="dwform-row-gap"></div>

			<div class="dwform-row-gap"></div>
			<div class="dwform-row-gap"></div>
			<div class="dwform-row-gap"></div>
			<button mat-button class="command-button" style="width: 150px"
				(click)="doTestConnection()" matTooltip="Test Connection" mat-button>Test Connection</button>
			<div class="dwform-row-gap"></div>

		</div>
	</div>
	<div *ngIf="loading"
		style="display: flex; justify-content: center; align-items: center; background: #fafafa;">
		<table>
			<tr>
				<td style="text-align: -webkit-center;">
					<mat-progress-spinner color="primary" mode="indeterminate" diameter="40"> Please wait
					</mat-progress-spinner>
					<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8"  *ngIf="loading"></p-progressSpinner>
				</td>
			</tr>
			<tr>
				<td style="text-align: center;">
					<span>Please wait</span>
				</td>
			</tr>
		</table>
		</div>
	</div>

	<div style="bottom: 400px; position: absolute;">
		<label style="color: blue">{{strMessage}}</label>
	</div>
	
	   <div class="error-line" [hidden]='!strErr.length'
        style="text-align:left">{{strErr}}</div>
	<div *ngIf="editMode" class="save-close-strip" style="bottom: 20px;">

		<button mat-button class="command-button" (click)="doSubmit()"
			matTooltip="Submit data" mat-button [disabled]="!editMode">
			<mat-icon>check_circle_outline</mat-icon>
			Submit
		</button>
		<button mat-button class="command-button" (click)="doCancel()"
			matTooltip="Cancel changes" mat-button [disabled]="!editMode">
			<mat-icon>highlight_off</mat-icon>
			Cancel
		</button>
	</div>
	<div *ngIf="!editMode" class="save-close-strip" style="bottom: 20px;">

		<button mat-button class="command-button" (click)="enterEditMode()"
			matTooltip="Edit data" mat-button>
			<mat-icon>edit</mat-icon>
			Modify
		</button>
	</div>

	<p-dialog header="Settings" [(visible)]="displaySavedMessage"
		[modal]="true" [style]="{width: '20vw'}" appendTo="body"
		[draggable]="false" [resizable]="false"> <app-message-box
		[data]="{message: 'Settings has been saved!', showOk: true}"
		(onClose)="displaySavedMessage=false"> </app-message-box> </p-dialog>