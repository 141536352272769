
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { MedicationService } from '../../../services/medication.service';
import { Settings } from '../../../services/settings';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ElementRef } from '@angular/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { MedicationImage } from 'src/app/models/medicationimage';
import { DwUtils } from 'src/app/components/utils/dw-utils';
import { TransactionHistoryService } from 'src/app/services/transactionhistory.service';
import { TransactionHistoryComponent } from 'src/app/components/transaction-history/transaction-history';
import { MedicationFamily } from 'src/app/models/medicationfamily';
import { Material } from 'src/app/models/material';
import { MaterialService } from 'src/app/services/material.service';

@Component({
	selector: 'app-materials-inv',
	templateUrl: './materials-inv.component.html',
	styleUrls: ['./materials-inv.component.css']
})
export class MaterialsInvComponent {

	queryStripGroup: FormGroup;
	customDataViewFlag = false;
	isEditing = false;
	isAdd = false;
	propContainerState = false;
	pagination: string;
	visiblePropertiesTab = true;
	visibleImagesTab = true;
	visibleLinksTab = true;
	visibleSuppliersTab = true;
	selectedTabIndex = 0;

	statusFilterList = [];
	selectedStatusFilter: any;
	statusFilterIdx: number | null = -1;

	public result: boolean | null = false;

	contentClassWithBanner = 'dwtable-container';
	contentClassWithoutBanner = 'dwtable-container-without-header';
	contentClassName = 'dwtable-container';

	disabledFlag = true;

	formTitle = 'Materials';
	strErr = '';
	selectedFilterStatus = '1';
	BASE_URL = Settings.getInstance().BASE_URL;
	recordsOriginal: Material[];
	selectedRecord: Material;
	records: Material[];;
	historyRecord: Material;
	renderedData = Array<Material>();
	displayedColumns: string[] = ['id', 'partNumber', 'handle', 'serialnumber', 'type', 'packageSize', 'awp', 'activeStatus'];
	itemTypeList: any;
	dataSource: MatTableDataSource<Material>; // = new MatTableDataSource(ELEMENT_DATA);

	visibleAdd = false;
	visibleModify = false;
	visibleDelete = false;
	formContent: UntypedFormGroup;
	Idx = -1;
	valPartNumber: any;
	valHandle: any;
	valDesc: any;
	valPkgSize: any;
	valSerialNumber: any;
	valItemType: any;
	valCostBase: any;
	valAWP: any;
	valStatus: any;
	valId: any;
	valInvWarningThreshold: number;
	sortField: string;
	sortDir: string;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('history', { static: true }) history: TransactionHistoryComponent;

	constructor(private materialService: MaterialService, private dialog: MatDialog, private txService: TransactionHistoryService) {

		this.statusFilterList = new Array();
		this.statusFilterList.push({id: 'Active', description: 'Active'});
		this.statusFilterList.push({id: 'InActive', description: 'InActive'});
		this.statusFilterList.push({id: 'Deleted', description: 'Deleted'});
		this.statusFilterList.push({id: 'All', description: 'All Items'});
		this.selectedStatusFilter = this.statusFilterList[0];
		this.statusFilterIdx = 0;
	}

	ngOnInit() {
		// Now let's set the access privilege level
		if (Settings.getInstance().hasPrivilege('MANAGE_MATERIALS', false)) {
			this.visibleAdd = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_MATERIALS', false)) {
			this.visibleModify = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_MATERIALS', false)) {
			this.visibleDelete = true;
		}
		if (Settings.getInstance().enableMessageBanner) {
			this.contentClassName = this.contentClassWithBanner;
		} else {
			this.contentClassName = this.contentClassWithoutBanner;
		}
		this.dataSource = new MatTableDataSource();
		this.itemTypeList=[{type:'Equipment',eg:'Computers, counting machines, printers etc.'},
		{type:'Containers',eg:'Round containers / vials / MPACs etc.'},
		{type:'Consumables',eg:'Desiccants, glues, solutions for cleaning, paper towels, disinfectant etc.'},
		{type:'Paper',eg:'Labels � paper, forms etc.'},
		{type:'Other ',eg:'Anything else'},];
		this.queryRecords();

	}

	applyStatusFilter() {
		this.records = [];
		for (const rec of this.recordsOriginal) {
			if ((this.selectedStatusFilter.id === 'InActive')) {
				if (rec.activeStatus === 'InActive') {
					this.records.push(rec);
				}
			} else if ((this.selectedStatusFilter.id === 'Active')) {
				if (rec.activeStatus === 'Active') {
					this.records.push(rec);
				}
			} else if ((this.selectedStatusFilter.id === 'Deleted')) {
				if (rec.activeStatus === 'Deleted') {
					this.records.push(rec);
				}
			} else {
				this.records.push(rec);
			}
		}
		this.buildDataSource();
	}
	public onStatusFilterKeyDown(event: any): void {
		const len: number = this.statusFilterList.length;
		if (event.key === 'ArrowDown') {
			if (this.statusFilterIdx < (len - 1)) {
				this.selectedStatusFilter = this.statusFilterList[++this.statusFilterIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.statusFilterIdx > 0) {
				this.selectedStatusFilter = this.statusFilterList[--this.statusFilterIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedStatusFilter = this.statusFilterList[this.statusFilterIdx];
			this.onChangeStatusFilterValue(this.selectedStatusFilter);
		}
	}
	queryRecords() {
		this.materialService.getAllMaterials().subscribe(
			(result) => {
				this.recordsOriginal = result;
				this.records = result;
				this.applyStatusFilter();
				this.setPagination(-1, this.records.length);
				if (this.isAdd === true) {
					this.selectedRecord = this.dataSource.data[this.dataSource.data.length - 1];
					this.displayDetail(this.selectedRecord);
				}
			},
			(error) => {
			}
		);
	}
	buildDataSource() {
		let data = [];
		this.dataSource.data = this.records;
		this.setPagination(-1, this.records.length);
	}

	rowIsNested(handle: string): string {

		if (handle.substring(0, 3) === ' - ') {
			return 'nested-row';
		}
		return '';
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	applyFilter(filterValue: string) {
		if (this.isEditing) return;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		this.unSelectRow();
		this.setPagination(-1, this.dataSource.filteredData.length);
	}

	@HostListener('matSortChange', ['\$event'])
	onSortChange(event: any) {

		if (event.direction === 'asc') {
			this.records.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.records.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSource.data = this.records;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	selectRow() {
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		// console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = new Material();
		this.setPagination(this.Idx, this.records.length);
		this.displayDetail(this.selectedRecord);

	}
	clearFilter() {
		if (this.isEditing) {
			return;
		}
		this.dataSource.filter = '';

		this.unSelectRow();
		this.setPagination(-1, this.records.length);

	}
	displayDetail(record: Material) {
		if (record === undefined || record.id === 0) {
			return;
		}
		this.valPartNumber = record.partNumber;
		this.valHandle = record.handle.toUpperCase();
		this.valPkgSize = record.packageSize;
		this.valDesc = record.description;
		this.valId = record.id;
		this.valPkgSize = this.selectedRecord.packageSize;
		this.valSerialNumber = this.selectedRecord.serialNumber;
		this.valItemType = this.selectedRecord.itemType;
		this.valInvWarningThreshold = this.selectedRecord.invWarningThreshold;

		if (this.selectedRecord.activeStatus === 'Active') {
			this.valStatus = 'Active';
		} else if (this.selectedRecord.activeStatus === 'InActive') {
			this.valStatus = 'InActive';
		} else {
			this.valStatus = 'Deleted';
		}

		this.valAWP = this.selectedRecord.awp;


		this.history.onShowHandler('Materials', 'id', record.id.toString());
	}
	clear() {
		this.valPartNumber = '';
		this.valHandle = '';
		this.valPkgSize = '';
		this.valDesc = '';
		this.valId = '';
		this.valPkgSize = '';
		this.valStatus = '';
		this.valAWP = '';
		this.valCostBase = '';
		this.valSerialNumber = '';
		this.valItemType = '';
		this.valInvWarningThreshold = 0;
	}

	dataViewFilterChanged(event) {
		console.log(event);
		//this.selectedViewFilter = changedValue;
	}

	onChangeStatusFilterValue(event: any) {
		if (event.value !== undefined) {
			this.selectedStatusFilter = event.value;
		}

		this.applyStatusFilter();
		this.setPagination(-1, this.records.length);
	}

	onPropertiesToggle() {
		this.propContainerState = !this.propContainerState;
	}

	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		}
		this.setPagination(this.Idx, this.records.length);
	}

	doTabChange(event): void {

	}
	addModifyRecord(isAdd: boolean) {
		this.isAdd = isAdd;
		this.isEditing = true;
		if (this.selectedTabIndex === 2) {
			this.selectedTabIndex = 0;
		}
		if (this.isAdd) {
			this.clear();
			this.valStatus = 'Active';
		} else {
			this.historyRecord = new Material();
			for (const prop in this.selectedRecord) {
				this.historyRecord[prop] = this.selectedRecord[prop];
			}
		}
		DwUtils.setFocus('omNDCNumber');
	}
	validateFields() {
		if(this.valPartNumber === undefined || this.valPartNumber.length === 0) {
			this.strErr = 'Error: a Part Number is required';
			DwUtils.setFocus('partNumber');
			return false;
		}
		if (this.valHandle === undefined || this.valHandle.length === 0) {
			this.strErr = 'Please enter Handle';
			DwUtils.setFocus('handle');
			return false;
		}

		if (this.valStatus === undefined || this.valStatus.length === 0) {
			this.strErr = 'Please select Status';
			DwUtils.setFocus('status');
			return false;
		}
		if (this.valPkgSize === undefined || this.valPkgSize.length === 0) {
			this.strErr = 'Please enter Package size';
			DwUtils.setFocus('packagesize');
			return false;
		}
		return true;
	}
	submitRecord() {
		this.strErr = '';
		if (!this.validateFields()) {
			return;
		}

		if (this.isAdd) {
			this.selectedRecord = new Material();
			this.selectedRecord .handle = this.valHandle.toUpperCase();
			this.selectedRecord .description = this.valDesc;
			this.selectedRecord .packageSize = this.valPkgSize;
			this.selectedRecord .serialNumber = this.valSerialNumber;
			this.selectedRecord .itemType = this.valItemType;
			this.selectedRecord .activeStatus = this.valStatus;
			this.selectedRecord .awp = this.valAWP;
			this.selectedRecord .invWarningThreshold = this.valInvWarningThreshold;
			this.selectedRecord .partNumber = this.valPartNumber;

			this.materialService.createMaterial(this.selectedRecord).subscribe(
				(data2) => {
					/*** add a transaction history record ***/
					this.selectedRecord.id = data2.id;
					this.txService.createTransactionHistory('Materials', data2.id);
					this.queryRecords();
					this.strErr = '';
					this.isEditing = false;
				},
				(error) => {
					Settings.getInstance().handleError(error, 'adding a record to the materials table');
				}
			);

		} else {

			this.selectedRecord.description = this.valDesc;
			this.selectedRecord.packageSize = this.valPkgSize;
			this.selectedRecord.serialNumber = this.valSerialNumber;
			this.selectedRecord.itemType = this.valItemType;
			this.selectedRecord.activeStatus = this.valStatus;
			this.selectedRecord.awp = this.valAWP;
			this.selectedRecord.invWarningThreshold = this.valInvWarningThreshold;
			this.selectedRecord.partNumber = this.valPartNumber;

			this.materialService.updateMaterial(this.selectedRecord).subscribe(
				(data2) => {
					this.isEditing = false;
					this.strErr = '';
					this.txService.updateTransactionHistory(this.historyRecord, this.selectedRecord, 'Materials', this.selectedRecord.id);
				},
				(error) => {
					Settings.getInstance().handleError(error, 'updating a record in the materials table');
				}
			);
		}
		this.updateSelectedRow();
	}
	updateSelectedRow(): void
	{
		if (this.isAdd === true) {
			// add the new record to the top.
			this.records.unshift(this.selectedRecord);
			this.dataSource.data = this.records;
			this.Idx = 0;
		}
		else {
			const i: number = this.records.findIndex(r => r.id === this.selectedRecord.id);
			this.records[i] = this.selectedRecord;
			this.dataSource.data = this.records;

		}
		this.dataSource.sort = this.sort;
		// this.onChangeStatusFilterValue();
	}
	onRowClicked(SR: Material) {
		if (this.isEditing) return;
		this.selectedRecord = SR;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
		this.setPagination(this.Idx, this.records.length);
		this.displayDetail(this.selectedRecord);
	}
	cancelSubmitRecord() {
		this.isEditing = false;
		this.displayDetail(this.selectedRecord);
		this.strErr = '';
	}
	removeRecord() {
		const message = 'Remove Material: ' + this.selectedRecord.handle + ' - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {
				this.selectedRecord.activeStatus = 'Deleted';
				this.materialService.updateMaterial(this.selectedRecord).subscribe(
					(data2) => {
						this.isEditing = false;
						this.txService.updateTransactionHistory(this.historyRecord, this.selectedRecord, 'Materials', this.selectedRecord.id);
						this.queryRecords();
					},
					(error) => {
					}
				);
			}
		});
	}
}
