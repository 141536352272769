<!--
    file type .....: HTML file: preparebatch.component.html
    Created By ....: ianday
    Date ..........: 7/14/23 
-->
<div class="preparebatch-container">

   <div class="prepare-batch-form-content">
           <!-- COLUMN 1 -->
           <div class="dwform">
               <!-- ORDER NUMBER -->
               <label class="label-field section-head" style="vertical-align: top;">REQUIRED:</label>
               <span class="form-required"> </span>
               <textarea class="text-area-override"
                         [readonly]="true">{{lblDescription()}}</textarea>
           </div>
   </div>

   <div class="prepare-batch-table-content mat-elevation-z2">
        <div class="dwform">

            <!-- SCAN ICN -->
            <div class="dwform-row-gap-half" > </div>
            <label class="label-field" style="padding-left: 3px;">Scan (ICN):</label>
            <span class="form-required">*</span>
            <input class="input-field-short" id="icnId" (keydown.enter)="onClickAddBulkItem($event)"
                   [formControl]="icnControl"
                   ngDefaultControl placeholder="<Scan ICN>"
                   type="text">
            <button mat-button
                    (click)="onClickAddBulkItem($event)" style="scale: 80%">
                Add
            </button>

            <div style="display: inline-block; width:60%; text-align: center;">
                Doses Selected:

                <span style="padding-left:20px;">
                    <label class="label-field-data" style="font-weight: bold;">
                        {{selectedForm.bpr.dosesSelected}}
                    </label>
                    /
                    <label class="label-field-data" style="font-weight: bold">
                        {{selectedForm.bpr.dosesRequired}}
                    </label>
                </span>

                <span style="padding-left: 30px; ">
                Doses Balance:
                <label class="label-field-data" style="font-weight: bold">
                        <span *ngIf="dosesBalance <= 0" style="color: green;">
                            {{dosesBalance * -1}} (over)
                        </span>
                    <span *ngIf="dosesBalance > 0" style="color: red;">
                            {{dosesBalance}} (Req'd)
                        </span>
                </label>
                </span>
            </div>
        </div>
        <!-- ICN -->

        <div class="dwform-row-gap-half" > </div>
        <mat-divider></mat-divider>
        <div class="dwform-row-gap"></div>
        <div (keydown)="tableKeyDown($event)" tabindex="-1"
             class="table-bulk-list-container">

            <table mat-table [dataSource]="dataSource"
                   class="table-bulk-items-class"  style="border:none;">

                <!-- Inventory Control Number -->
                <ng-container matColumnDef="icn">
                    <th mat-header-cell *matHeaderCellDef
                        class="mat-table-header-style" style="width:40px">ICN
                    </th>
                    <td mat-cell [hidden]="false"
                        style="font-size: 9pt; width:40px; vertical-align: middle; text-align: left;"
                         *matCellDef="let element;let tableRowIdx = index;">
                        {{element.icn}}</td>
                </ng-container>

                <!-- NDC Number -->
                <ng-container matColumnDef="ndcNumber">
                    <th mat-header-cell *matHeaderCellDef
                        class="mat-table-header-style" style="width:60px">NDC Number
                    </th>
                    <td mat-cell
                        [hidden]="false"
                        style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.ndcNumber}}</td>
                </ng-container>

                <!-- Expiration Date -->
                <ng-container matColumnDef="expirationDate">
                    <th mat-header-cell *matHeaderCellDef
                        class="mat-table-header-style" style="width:60px">Expiration Date
                    </th>
                    <td mat-cell
                        [hidden]="false"
                        style="font-size: 9pt; vertical-align: middle;
                                text-align: left; "
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{lbFmtDate(element.expirationDate)}}</td>
                </ng-container>

                <!-- Description -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef
                        class="mat-table-header-style" style="width:200px">Description
                    </th>
                    <td mat-cell
                        [hidden]="false"
                        style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.description}}</td>
                </ng-container>

                <!-- Description -->
                <ng-container matColumnDef="dosesLeft">
                    <th mat-header-cell *matHeaderCellDef
                        class="mat-table-header-style" style="width:60px">Remaining Doses
                    </th>
                    <td mat-cell
                        [hidden]="false"
                        style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.returning}}</td>
                </ng-container>

                <!-- DELETE -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header-style" style="width: 20px;"> </th>
                    <td mat-cell *matCellDef="let element;let tableRowIdx = index;" class="button-cells-edit-delete">
                <span> <img class="edit-delete-button" matTooltip="Click to remove" alt=""
                           src="../../../../assets/delete-icon.png"
                           (click)="deleteFromBulkUnitItems(tableRowIdx)">
                </span></td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row style="border: .5px solid green"
                    *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                    [ngClass]="{'row-selected': tableRowIdx == Idx}"
                    (click)="onRowClicked(row)">
                </tr>
            </table>
        </div>
   </div>

    <div class="dwform-row-gap"></div>

    <!-- MASTER PRODUCTION RECORD INFORMATION -->
    <label class="label-field section-head" >MASTER PRODUCTION RECORD:</label>

       <div style="width: 100%; height: 175px;">

            <div class="directions-precautions-label-div">Precautions: </div>
            <div class="directions-precautions-label-div" style="text-align: right;padding-right: 13px;">
                <button mat-button style="scale: 80%"
                (mouseover)="showAltPrecautions(1)" (mouseout)="showAltPrecautions(0)">Alt Precautions</button>
           </div>

           <div class="directions-precautions-label-div">Directions: </div>
           <div class="directions-precautions-label-div" style="text-align: right;padding-right: 13px;">
               <button mat-button style="scale: 80%;"
                (mouseover)="showAltDirections(1)" (mouseout)="showAltDirections(0)">Alt Directions</button>
           </div>

           <div class="text-area-field-div">
               <textarea class="directions-precautions-textarea-override" [readonly]="true"
                [(ngModel)]="precautions"></textarea>
           </div>
           <div class="text-area-field-div">
               <textarea class="directions-precautions-textarea-override" [readonly]="true"
               [(ngModel)]="directions"></textarea>
           </div>

           <div class="dwform-row-gap"></div>

           <mat-checkbox id="prepApproveDirPrec" name="approveDirPrecautions" binary="true"
                         [(ngModel)]="prepApproveDirPrec"
                         [ngModelOptions]="{standalone: true}">
               Approve Directions and Precautions?
           </mat-checkbox>

           <mat-divider style="margin-top: 10px;"></mat-divider>

       </div>

       <!--IMAGE VERIFICATION SECTION -->
    <label class="label-field section-head" >IMAGE VERIFICATION:</label>

       <div style="display: block; width: 100%; height: 70px; margin-top:10px; vertical-align: middle;">


           <div class="text-area-field-div" style="display: inline-flex;">
               <div class="prepare-images"
                    (mouseover)="doMouseOverImage($event)"
                    (mouseleave)="doMouseOutImage()"
                    [ngStyle]="{ 'background-image': 'url(' + containerImage + ')'}">
               </div>

               <div style="display: inline-flex;width:330px;">
                   <mat-checkbox id="labelVerifiedChk" id="prepLabelImgVerify" name="labelVerifiedChk" binary="true"
                                 style="padding-top:15px; padding-left: 25px"
                                 [(ngModel)]="prepLabelImgVerify"
                                 (change)="onLabelVerificationCheck($event)"
                                 [ngModelOptions]="{standalone: true}">
                      Label Verified?
                   </mat-checkbox>
               </div>
           </div>

           <div class="text-area-field-div" style="display: inline-flex;">
               <div class="prepare-images"
                    (mouseover)="doMouseOverImage($event)"
                    (mouseleave)="doMouseOutImage()"
                    [ngStyle]="{ 'background-image': 'url(' + dosageImage + ')'}" >
               </div>

               <div style="display: inline-flex; width:330px;">
                   <mat-checkbox id="doseVerifiedChk" id="prepDosageImgVerify"
                                 name="doseVerifiedChk" binary="true"
                                 style="padding-top:15px; padding-left: 70px"
                                 [(ngModel)]="prepDosageImgVerify"
                                 (change)="onDosesVerificationCheck($event)"
                                 [ngModelOptions]="{standalone: true}">
                   Dosage Verified?
                   </mat-checkbox>
               </div>
           </div>
           <mat-divider style="margin-top: 10px;"></mat-divider>

       </div>

       <div class="dwform-row-gap" style="padding-bottom: 7px;" > </div>
       <label class="label-field section-head" >PREPARATION:</label>

       <div class="dwform">
           <!-- Temperature -->
           <div class="dwform-row-gap" > </div>
           <label class="label-field">Temperature:</label>
           <span class="form-required">*</span>
           <input class="input-field-short" id="storageTemp"
                  [formControl]="storageTempControl"
                  ngDefaultControl
                  min="0" max="100"
                  type="number">
           <span>Degrees Celsius</span>

           <!-- Humidity -->
           <div class="dwform-row-gap" > </div>
           <label class="label-field">Humidity:</label>
           <span class="form-required">*</span>
           <input class="input-field-short" id="storageHumidity"
                  [formControl]="storageHumidityControl"
                  ngDefaultControl
                  min="0" max="100"
                  type="number">
           <span>Percent</span>
           <!-- Station Drop down -->
           <div class="dwform-row-gap"></div>

           <label class="label-field">Station:</label>
           <span class="form-required">*</span>
           <mat-select id="stationId"
                       disableOptionCentering
                       [(ngModel)]="selectedStation" #cbStation floatLabel="never"
                       class="dw-mat-select"
                       style="font-size: 10pt;width: 200px;"
                       panelClass="local-drop-down-panel"
                       (selectionChange)="onChangeStations($event)">
               <mat-option *ngFor="let station of stationsList"
                           [value]="station">{{station.handle}}</mat-option>
           </mat-select>

           <div class="dwform-cell" style="width:300px;">
               <div class="dwform-row-gap" > </div>
               <!-- Station Cleaning Completed -->
               <mat-checkbox id="prepStationClean" name="prepStationClean" binary="true"
                             [(ngModel)]="prepStationClean"
                             (change)="onChangePrepStationClean($event)"
                             [ngModelOptions]="{standalone: true}">
                   Station Cleaning Completed?
               </mat-checkbox>

               <!-- Print the labels Now? -->
               <div class="dwform-row-double-gap"> </div>
               <mat-checkbox id="printLabelsId" name="printLabels" binary="true"
                             [(ngModel)]="printLabels"
                             [checked]="true"
                             (change)="onPrintLabelsChange($event)"
                             [ngModelOptions]="{standalone: true}">
                   Print Container Labels Now?
               </mat-checkbox>
       </div>
    </div>
    <!----------------------->
    <!-- ERROR LINE --------->
    <!----------------------->
    <div class="error-line" [hidden]='!strErr.length'
         style="text-align:left; bottom: 56px; left: 15px;">{{strErr}}</div>
    <!----------------------->
    <!-- SAVE STRIP        -->
    <!----------------------->
    <div style="text-align:center; margin-top: 35px;">
        <button mat-button class="command-button"
                (click)="onStartClicked()" matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Start
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
<app-labelgen #labelGen
   [bprId]="selectedForm.bpr.id"
   (finishedLabels)="doFinishedFromLabels($event)" >
</app-labelgen>

<!-- CONFIRMATION DIALOG -->
<p-dialog header="Please Confirm"
                 [(visible)]="shortBulkItemsDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '525px', height: '240px'}"
          [minY]="75"
          [resizable]="false" [closable]="false">

    <div style="display:table-row; width:100%;padding-left: 5px;padding-top:5px;">
        <label style="display:table-row; padding-bottom:10px;font-weight:bold ">
            Warning: Not enough doses have been provided to fill this batch.  Please choose one of the following:
        </label>
        <mat-radio-group class="rb-options-style"
                         ngDefaultControl
                         [(ngModel)]="valSubmitOption" >
            <mat-radio-button
                    ngDefaultControl
                    [(ngModel)]="valSubmitOption"
                    (change)="radioButtonChange($event)"
                    [checked]="true" [value]=1>Partially fill this batch and create another batch item for the
                remainder.
            </mat-radio-button>
            <mat-radio-button ngDefaultControl
                              [(ngModel)]="valSubmitOption"
                              (change)="radioButtonChange($event)"
                              [value]=2>Adjust the totals for this batch and ignore the remaining items needed.
            </mat-radio-button>
            <mat-radio-button ngDefaultControl
                              [(ngModel)]="valSubmitOption"
                              (change)="radioButtonChange($event)"
                              [value]=3>Just save the batch - do not attempt to balance yet.
            </mat-radio-button>
        </mat-radio-group>
            <div>
            <div class="save-close-strip">
                <button mat-button class="command-button"
                        (click)="onSubmitBatchClicked()">
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="onCancelSubmitBatchClicked()">
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</p-dialog>
<p-dialog header="Large Image View"
          [showHeader]="false"
          [(visible)]="displayLargeImage"
          [modal]="false"
          [style]="{position: 'absolute', top: '8%', left: '25%'}"
          appendTo="body" [responsive]="true"
          [resizable]="false" [closable]="false">
    <img src="{{largeImgUrl}}" height="430px" width="840px" alt="-Image Not Found"  >
</p-dialog>
