import { Component, HostListener, ViewChild, OnInit, AfterViewInit, Input, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIChart  } from 'primeng/chart'; // 'primeng/primeng';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
	selector: 'app-graph',
	templateUrl: './graph.component.html',
	styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit, AfterViewInit {

	@ViewChild('chart') chart: UIChart;

	public divId;
	public _options: any;
	public cols: any[];
	public chartStyles = {};
	_selectedSite: any;


	plugins = {
		legend: {
			position: 'right',
			align: 'left'
		},
		labels: {
			render: 'percentage',
			precision: 2
		}
	}

	@Input() CurrentChart: any;
	@Input() data: any;

	@Input() set options(value: any) {
		this._options = value;
	}
	
	@Input() set selectedSite(value: any) {
        this._selectedSite = value;
    }

	get options () {

		return this._options;
	}
	constructor() {

	}

	ngOnInit() {}

	ngAfterViewInit() {}

	setPieOptionValues(legendPosition: string, dataLabelsVisibility: boolean, chartTitle: string) {
		this.chart.plugins = [ChartDataLabels];
		this.options = {
			responsive: false,

			layout: {
				padding: '10'
			},
			plugins: {
				datalabels: {
					formatter: (value, ctx) => {
						let sum = 0;
						const dataArr = ctx.chart.data.datasets[0].data;
						dataArr.map(data => {
							sum += data;
						});
						const percentage = (value * 100 / sum).toFixed(2) + '%';
						if(value > 0)
							return percentage + '\n(' + value + ')';
						else
							return '';
					},
					color: '#fff',
					display: dataLabelsVisibility,
				},
				title : {
					display: true,
					align: 'start',
					text: chartTitle
				},
				legend: {
					labels: {
						usePointStyle: true,
					},
					pointStyle: '',
					display: true,
					position: legendPosition
				}
			}
		}
	}
	setBarOptionValues(legendPosition: string, dataLabelsVisibility: boolean, chartTitle: string, displayLegend: boolean = true) {
		this.chart.plugins = [ChartDataLabels];
		this.options = {
			layout: {
				padding: '5'
			},
			plugins: {
				datalabels: {
					formatter: (value, ctx) => {
						if (value > 0)
							return '(' + value + ')';
						else
							return '';
					},
					color: '#fff',
					display: dataLabelsVisibility,
				},
				title : {
					display: true,
					align: 'start',
					text: chartTitle
				},
				legend: {
					labels: {
						usePointStyle: true,
					},
					display: displayLegend,
					position: legendPosition
				}
			},
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true
				}
			}
		}
	}
	captureChart()
	{

	}
}
