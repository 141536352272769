import { Component, Output, OnInit, EventEmitter, Input, ViewChild, QueryList, ElementRef, OnChanges } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { GraphComponent } from '../graph/graph.component';
import { UIChart} from 'primeng/chart';
import { HomeService } from '../../services/home.service';

import {Settings} from '../../services/settings';
import {retry} from 'rxjs/operators';
import {Card} from 'primeng/card';

@Component({

    selector: 'card-component',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css']
})

export class CardComponent implements OnInit, OnChanges {

    private _selectedChart: any;

    data: any;
    options: any;
	_selectedSite: any;

    titlePositionOptions = [{name: 'Left', code: 'left'}, {name: 'Center', code: 'center'}, {name: 'Right', code: 'flex-end'}];
    legendPositionOptions = [{name: 'Left', code: 'left'}, {name: 'Top', code: 'top'}, {name: 'Right', code: 'right'}, {name: 'Bottom', code: 'bottom'}];
    chartDataOptions = [{name: 'Show', code: true}, {name: 'Hide', code: false}];
    timeLineOptions = [{name: 'Monthly', code: 'monthly'}, {name: 'Quarterly', code: 'quarterly'}, {name: 'Yearly', code: 'yearly'}];
    timeFrameOptions = [{name: 'Current Year', code: 'currentyear'}, {name: 'Last Year', code: 'lastyear'}, {name: 'Two Years', code: 'twoyears'}];

    selectedTitlePosition = this.titlePositionOptions[0];
    selectedLegendPosition = this.legendPositionOptions[2];
    selectedChartData = this.chartDataOptions[0];
    selectedTimeLine = this.timeLineOptions[0];
    selectedTimeFrame = this.timeFrameOptions[0];

    @ViewChild('chart') graph: GraphComponent;
    @ViewChild('cardContent') cardContent;
    @ViewChild('configure') configure: OverlayPanel;

    @Input() private refreshEvent: EventEmitter<boolean>;

    @Input() set selectedChart(value: any) {
        this._selectedChart = value;
        //this.loadChartDetails();
    }

	@Input() set selectedSite(value: any) {
        this._selectedSite = value;
        //this.loadChartDetails();
    }
    get selectedChart(): any
    {
        return this._selectedChart;
    }
    constructor(private homeService: HomeService) {

    }

    ngOnInit() {
        if(this.refreshEvent) {
            this.refreshEvent.subscribe(data => {
                this.doApplyProperties();
            });
        }
    }
	
	ngOnChanges() {
		this.loadChartDetails();
	}

    loadChartDetails() {
		if(this.selectedSite == null || this.selectedChart === undefined || this.selectedChart.dataSource === '') {
			return;
		}
       /*this.homeService.getChart(this.selectedChart.dataSource, this._selectedPortfolio, this._selectedFiscalYear).subscribe(
			(data) => {
				this.data = data;
				this.setOptions();
			},
			(error) => {
				Settings.getInstance().handleError(error);
			}
       ); */
    }

    setOptions(): void{
        let chartTitle = '';
        /* just get the webmethod name (not parameters) */
        const switchParam = this.selectedChart.dataSource.split('/');

        switch (switchParam[0]) {
            case 'getProjectCountByStatus':
                chartTitle = 'Showing Relative Health Percentages';
                this.graph.setPieOptionValues(this.selectedLegendPosition.code, this.selectedChartData.code, chartTitle);
                break;
            case 'getProjectCountByStatusAndPhase':
                chartTitle = 'Showing Project Health by Phase';
                this.graph.setBarOptionValues(this.selectedLegendPosition.code, this.selectedChartData.code, chartTitle);
                break;
            case 'getCapExConsumption':
                chartTitle = 'Showing Cap/Ex consumption ($1K)';
                this.graph.setBarOptionValues(this.selectedLegendPosition.code, this.selectedChartData.code, chartTitle, false);
                break;
            case 'getResourceHoursConsumption':
                chartTitle = 'Showing Internal/External hours consumption';
                this.graph.setBarOptionValues(this.selectedLegendPosition.code, this.selectedChartData.code, chartTitle, false);
                break;
            case 'getProjectRoadmap':
                break;
            default:
                break;

        }
        // this.selectedChart.plugins = this.graph.plugins;
        // this.selectedChart.options.layout = {padding: '20'};
        // this.selectedChart.options.plugins.labels = {render: 'percentage'};
    }

    onResizeWindow($event) {
        if (this.graph === undefined) {
            return;
        }

       // this.graph.resizeChild(this.cardContent.nativeElement.offsetWidth,
        // this.cardContent.nativeElement.offsetHeight);
    }

    onLaunchConfigureSettings(event): boolean {
        this.configure.toggle(event);
        return false;
    }
    onLegendPositionChange(event){
        if (event === undefined)
            return;

        this.selectedLegendPosition = this.legendPositionOptions.find(({code}) => code === event.value);
    }

    onDataLabelPlacementChange(event) {
        if (event === undefined)
            return;

        this.selectedChartData = this.chartDataOptions.find(({code}) => code === event.value);
    }

    onApplyHandler(event) {
        /* apply the changes to the chart */
        this.doApplyProperties();
    }

    doApplyProperties() {

        switch (this.selectedChart.type) {

            case 'gantt':
                break;
        }
       this.setOptions();
       this.configure.hide();
    }

    onResetHandler(event) {
        this.selectedLegendPosition = this.legendPositionOptions.find(({code}) => code === 'right');
        this.selectedChartData = this.chartDataOptions.find(({code}) => code === true);
        this.setOptions();
        this.configure.hide();
    }
    onCancelHandler(event) {
        this.configure.hide();
    }
    onCaptureClickHandler(event) {
        this.graph.captureChart();
    }

    onTimelineChange(event) {
        this.selectedTimeLine.name = event.value;
    }

    onTimeFrameChange(event) {
        this.selectedTimeFrame = this.timeFrameOptions.find(s => s.code === event.value);
    }
}
