import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MedicationService } from '../../../../../../services/medication.service';
import { DwUtils } from '../../../../../../components/utils/dw-utils';
import { Medication } from 'src/app/models/medication';
import { Settings } from 'src/app/services/settings';
import { Suppliers } from 'src/app/models/suppliers';
import { Ingredients } from 'src/app/models/ingredients';
import { MedicationFamily } from 'src/app/models/medicationfamily';

@Component({
	selector: 'app-addeditmedicationingredients',
	templateUrl: './addeditmedicationingredients.component.html',
	styleUrls: ['./addeditmedicationingredients.component.css']
})
export class AddeditmedicationingredientsComponent {
	public record: Ingredients;
	public _selectedRecord: Ingredients;
	public _medication: MedicationFamily;
	public id = 0;
	valIngredient: string;
	valUnit: string;
	valQuantity: number;
	valCode: string;
	strErr = '';
	stateControl = new FormControl('');


	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();


	@Input() set selectedRecord(value: any) {
		this._selectedRecord = value;
		this.onShowHandler();
	}

	get selectedRecord(): any {
		return this._selectedRecord;
	}

	get Medication(): any {
		return this._medication;
	}
	@Input() set Medication(value: any) {
		this._medication = value;
	}


	constructor(private medicationService: MedicationService) {

	}

	ngOnInit() {

	}
	onShowHandler() {
		DwUtils.setFocus('ingredient');
		if (this._selectedRecord) {
			this.valIngredient = this._selectedRecord.ingredient;
			this.valQuantity = this._selectedRecord.quantity;
			this.valUnit = this._selectedRecord.uom;
			this.valCode = this._selectedRecord.abcxyiCode;
		}
	}
	doCancel() {
		this.cancelClicked.emit();
	}

	setRecord(): void {
		this.record = new Ingredients();
		if (this._selectedRecord !== undefined) {
			this.record.id = this._selectedRecord.id;
			this.record.listSequence = this._selectedRecord.listSequence;
		} else {
			this.record.id = 0;
			this.record.listSequence = 9999;
		}
		this.record.ingredient = this.valIngredient;
		this.record.medicationFamilyId = this._medication.id;
		this.record.uom = this.valUnit;
		this.record.quantity = this.valQuantity;
		this.record.abcxyiCode = this.valCode;
	}
	validate() {
		if (!this.valIngredient) {
			this.strErr = 'Please enter Ingredient name';
			DwUtils.setFocus('ingredient');
			return false;
		}
		if (!this.valQuantity) {
			this.strErr = 'Please enter quantity';
			DwUtils.setFocus('quantity');
			return false;
		}
		if (!this.valUnit) {
			this.strErr = 'Please enter unit';
			DwUtils.setFocus('unit');
			return false;
		}
		if (!this.valCode) {
			this.strErr = 'Please select code';
			DwUtils.setFocus('code');
			return false;
		}

		return true;
	}
	doSubmit() {
		// this is where we stuff the new address and emit it back.
		this.setRecord();

		this.strErr = '';
		if (!this.validate()) {
			return;
		}

		if (this.record.id === 0) {
			this.medicationService.createIngredients(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		} else {
			delete this.record['medicationFamily'];
			this.medicationService.updateIngredients(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			)
		}

	}
}


