<!--
    file type .....: HTML file: rleasebatch.component.html
    Created By ....: ianday
    Date ..........: 8/4/23 
-->
<div class="dialog-container">
    <div class="dwform">
        <div class="dwform-row-gap" > </div>
        <!-- Station Cleaning Completed -->

        <mat-checkbox id="visualLabelMatchId"
                      name="visualLabelMatch" binary="true"
                      [(ngModel)]="verifiedUnits"
                      [ngModelOptions]="{standalone: true}">
            Visual Label Match Completed?
        </mat-checkbox>

        <!-- Print the labels Now? -->
        <div class="dwform-row-double-gap"> </div>
        <mat-checkbox id="itemNumberValidationId"
                      name="itemNumberValidation" binary="true"
                      [(ngModel)]="approveToShip"
                      [checked]="true"
                      [ngModelOptions]="{standalone: true}">
            Item Number Validation Completed?
        </mat-checkbox>
    </div>

</div>
<!----------------------->
<!-- ERROR LINE --------->
<!----------------------->
<div class="error-line" [hidden]='!strErr.length'
     style="text-align:left; bottom: 50px; left: 15px;">{{strErr}}</div>
<!----------------------->
<!-- SAVE STRIP        -->
<!----------------------->
<div style="text-align:center; margin-top: 28px;">
    <button mat-button class="command-button"
            (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
            mat-button>
        <mat-icon>check_circle_outline</mat-icon>
        Submit
    </button>
    <button mat-button class="command-button"
            (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
        <mat-icon>highlight_off</mat-icon>
        Cancel
    </button>
</div>
