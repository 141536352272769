<!--
    file type .....: HTML file: invtransfer.component.html
    Created By ....: ianday
    Date ..........: 4/18/23 
-->
<div class="invtransfer-container">
    <table style="width:100%">
        <tr>
            <td style="width:30%;">
                <div class="dwform" style="padding-right: 15px; font-size: 12px;" tabindex="-1">

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">{{medOrMat}}:</label>
                        <span class="form-required"></span>
                        <label class="label-field label-field-display-data"
                               style="font-weight: bold">{{InventoryItem}}</label>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Number of Units:</label>
                        <span class="form-required"></span>
                        <label class="label-field label-field-display-data">{{numUnits}}</label>
                    </div>
                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Transfer From:</label>
                        <span class="form-required"></span>
                        <label class="label-field  label-field-display-data">{{selectedSite}} / {{selectedCatalogue}}</label>
                    </div>

                    <div class="dwform-row-gap"></div>
                    <mat-divider></mat-divider>
                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Transfer To (Site):</label>
                        <span class="form-required">*</span>
                        <mat-select
                                disableOptionCentering
                                [(value)]="transferSiteId" #cbSite floatLabel="never"
                                class="dw-mat-select"
                                style="font-size: 10pt;width: 220px;"
                                panelClass="local-drop-down-panel"
                                (selectionChange)="onChangeSiteItem($event)">
                            <mat-option *ngFor="let site of sites"
                                        [value]="site.id">{{site.handle}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">To (Catalogue):</label>
                        <span class="form-required">*</span>
                        <mat-select #selectCat
                                disableOptionCentering
                                [(value)]="transferCataloguesId" floatLabel="never"
                                class="dw-mat-select"
                                style="font-size: 10pt;width: 220px;"
                                panelClass="local-drop-down-panel"
                                placeholder="- Please Select -"
                                (selectionChange)="onChangeCatalogueItem($event)">
                            <mat-option *ngFor="let cat of filteredCatalogues"
                                        [value]="cat.id">{{cat.handle}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- ends the form -->
                    <div [hidden]='!strErr.length' style="left: 10px;"
                         class="error-line">{{strErr}}</div>
                    <!-- save strip -->
                    <div class="save-close-strip" style="bottom: 5px;">

                        <button mat-button class="command-button" [disabled]="!(transferCataloguesId > 0)"
                                (click)="transferRecords()" matTooltip="Transfer selected items"
                                mat-button>
                            <mat-icon>check_circle_outline</mat-icon>
                            Submit
                        </button>
                        <button mat-button class="command-button"
                                (click)="cancelSubmitRecord()" matTooltip="Cancel transaction" mat-button>
                            <mat-icon>highlight_off</mat-icon>
                            Cancel
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
