/**
 *  Filename ....: preparebatch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 7/14/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Stations} from '../../../../models/stations';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {BulkUnitsService} from '../../../../services/bulkunits.service';
import {FormingService} from '../../../../services/forming.service';
import {Forming} from '../../../../models/forming';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {BprBulkItems} from '../../../../models/bprbulkitems';
import {Settings} from '../../../../services/settings';
import {BulkUnits} from '../../../../models/bulkunits';
import {TransactionHistoryService} from '../../../../services/transactionhistory.service';
import {DwDates} from '../../../../components/utils/dw-dates';
import {Bpr} from '../../../../models/bpr';
import {OrdersService} from '../../../../services/orders.service';
import {OrderItems} from '../../../../models/orderitems';
import {LabelGenComponent} from '../../../../components/label-gen/label-gen';
import {FormularyService} from '../../../../services/formulary.service';
import {FormularyMedication} from '../../../../models/formulary-medication';
import {FinishedForms} from '../../../../models/finishedforms';
import {FormularyMedicationLabel} from '../../../../models/formulary-label';
import {MedicationService} from '../../../../services/medication.service';
import {MedicationFamily} from '../../../../models/medicationfamily';
import { PrecautionDirection } from '../../../../models/precautiondirection';
import {PreparationComponent} from '../preparation.component';
import {CustomerFormulary} from '../../../../models/customer-formulary';
import {MedicationImage} from '../../../../models/medicationimage';

@Component({
    selector: 'app-preparebatch',
    templateUrl: './preparebatch.component.html',
    styleUrls: ['./preparebatch.component.css']
})

export class PrepareBatchComponent implements OnInit {

    public strErr: string;
    public selectedStation: Stations;
    public _stationsList: any;
    public _selectedForm: Forming;
    public precautions: string;
    public directions: string;

    public dosageImage: string;
    public containerImage: string;

    public largeImgUrl: string;
    public selectedDosesImage: string;
    public selectedLabelImage: string;
    public displayLargeImage = false;

    public ordNumber: string;
    public records: Array<BprBulkItems>;
    public selectedBulkUnit: BulkUnits;
    public selectedBPRItem: BprBulkItems;

    /*** Finished form stuffs ***/
    public formularyMedication: FormularyMedication;
    public lastSequenceNumber: number;
    public itemNumber: string;
    public finishedForm: FinishedForms;
    public labelId: number;
    public rxPrefix: string;

    /*** Form Controls ***/
    public storageTempControl: FormControl;
    public storageHumidityControl: FormControl;
    public icnControl: FormControl;
    public prepApproveDirPrec: boolean;
    public prepLabelImgVerify: boolean;
    public prepDosageImgVerify: boolean;
    public prepStationClean: boolean;
    public printLabels: boolean;
    public labelVerifyCheck: boolean;
    public dosesVerifyCheck: boolean;

    public labelIterator: number;
    public valSubmitOption: number;
    public orderItem: OrderItems;
    public pkgSize: number | null;
    public defaultLabelId = 0;
    public iterator: number | null = 0;

    /*** Bulk Units Table ***/
    public displayedColumns = ['icn', 'ndcNumber', 'expirationDate', 'description', 'dosesLeft', 'delete'];
    public dataSource: MatTableDataSource<BprBulkItems>;
    public Idx = -1;

    public shortBulkItemsDlg = false;
    public dosesBalance: number | null;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @HostListener ('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }
    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
    }
    get selectedForm(): Forming {
        return this._selectedForm;
    }
    @Input() set stationsList(inp: any) {
        if (inp === undefined) {
            this._stationsList = new Array();
            return;
        }
        this._stationsList = inp.filter(x => x.id > 0);
    }
    get stationsList(): any {
        return this._stationsList;
    }
    @Input() set orderNumber(inp: string) {
        this.ordNumber = inp;
    }

    @ViewChild('labelGen', {static: true}) labelGen: LabelGenComponent;

    constructor(public bulkUnitsService: BulkUnitsService,
                public txHistory: TransactionHistoryService,
                public ordersService: OrdersService,
                public medicationsServices: MedicationService,
                public formularyService: FormularyService,
                public formingService: FormingService) {
        this.strErr = '';
        this.storageHumidityControl = new FormControl();
        this.storageTempControl = new FormControl();
        this.icnControl = new FormControl();

    }
    ngOnInit() {

    }
    public doInitialize(): void {

        this.strErr = '';
        this.dosesBalance = 0;
        this.printLabels = true;
        this.dosageImage = null;
        this.containerImage = null;

        this.pkgSize = Number(DwUtils.returnPackageSizeCode(this.selectedForm.itemPrefix));

        this.records = new Array();
        this.dataSource = new MatTableDataSource<BprBulkItems>();
        this.storageTempControl.setValue(this.selectedForm.bpr.storageTemp);
        this.storageHumidityControl.setValue(this.selectedForm.bpr.storageHumidity);
        this.selectedStation = this.stationsList.find(x => x.id === this.selectedForm.stationId);
        if ((this.selectedStation === undefined) && (this.stationsList.length === 1))
        {
            this.selectedStation = this.stationsList[0];
        }

        this.selectedForm.bpr.prepStationClean === 1 ? this.prepStationClean = true : this.prepStationClean = false;
        this.selectedForm.bpr.prepApproveDirPrec === 1 ? this.prepApproveDirPrec = true : this.prepApproveDirPrec = false;
        this.selectedForm.bpr.prepLabelImgVerify === 1 ? this.prepLabelImgVerify = true : this.prepLabelImgVerify = false;
        this.selectedForm.bpr.prepDosageImgVerify === 1 ? this.prepDosageImgVerify = true : this.prepDosageImgVerify = false;

        /*** fetch the formulary medication for dir / precautions ***/
        this.queryPrecautionsDirections();

        /*** set up the basic calculations ***/
        this.queryBprItems();

        DwUtils.setFocus('icnId');
    }
    /*** queries ***/
    public queryBprItems(): void {

        this.dataSource = new MatTableDataSource<BprBulkItems>();
        this.records = new Array();

        this.formingService.getAllBprBulkItemsByBprId(this.selectedForm.bprId).subscribe(
            (data) => {
                this.records = data;
                this.dataSource = new MatTableDataSource(this.records);

                /*** note if there are Bulk Items already specified for this batch
                 * we can find the ICN to get the med id to get the images!
                 * Bit of a long way round but there you go!
                 ***/

                if (data.length > 0) {
                    this.queryMedicationImages(data[0].icn);
                }
                this.calcDosages();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching BPR Items');
        });
    }
    public queryBulkItems(icn: string): void {

        this.selectedBulkUnit = new BulkUnits();

        this.bulkUnitsService.getBulkUnitsByICN(icn).subscribe (
            (data) => {
                if (data === null) {
                    this.strErr = 'Error: Bulk item with that ICN number was not found';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if (data.checkedStatus === 'Checked-Out') {
                    this.strErr = 'Error: This item is currently checked-out ';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if (data.checkedStatus === 'Spent') {
                    this.strErr = 'Error: This item is spent - with no remaining doses ';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if (data.foreignKeyTable !== 'medications') {
                    this.strErr = 'Error: Non-medication item, please select a medication item';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if (DwDates.chkDateIsInThePast(data.expirationDate) === true) {
                    this.strErr = 'Error: This item is past its expiration date';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if (DwUtils.returnItemPrefix(data.itemNumber)
                    !== DwUtils.returnItemPrefix(this.selectedForm.itemPrefix)) {
                    this.strErr = 'Error: Item Prefix does not match what was expected';
                    DwUtils.highlightFocus('icnId');
                    return;
                }
                if ( this.records !== undefined) {
                    if (this.records.findIndex(x => x.icn === icn) > -1) {
                        this.strErr = 'Error: This item already exists in the list';
                        DwUtils.highlightFocus('icnId');
                        return;
                    }
                }
                else {
                    this.records = new Array<BprBulkItems>();
                }

                this.selectedBulkUnit = data;
                const bui: BprBulkItems = new BprBulkItems();
                bui.setFromBulkUnits(this.selectedForm.bprId, this.selectedBulkUnit, this.selectedForm.description);

                /*** we will try to query to get the images in case we don't have any
                 * if we do then we just return from the method ***/

                this.queryMedicationImages(bui.icn);

                this.insertBulkItems(bui);
            },
            (error) => {
                Settings.getInstance().handleError(error,'Fetching bulk item for fill');
            });
    }
    public queryPrecautionsDirections(): void {

        if ((this.selectedForm === undefined) || (this.selectedForm.formularyMedicationId === null)) {
            this.precautions = '[Not Available]';
            this.directions = '[Not Available]';
            return;
        }

        this.formularyService.getFormularyMedication(this.selectedForm.formularyMedicationId).subscribe(
            (result) => {
                this.formularyMedication = result;
                this.precautions = this.formularyMedication.precautions;
                this.directions = this.formularyMedication.directions;

            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching Directions and Precautions from the Formulary');
            });
    }
    public queryMedicationImages(icn: string): void {

        if (this.containerImage !== null) { return; }

        if (this.dosageImage !== null) { return; }

        this.bulkUnitsService.getBulkUnitsByICN(icn).subscribe(
            (result) => {
                const medId = result.foreignKeyId;

                this.medicationsServices.getMedicationImagesByMedicationId(medId).subscribe(
                    (result2) => {
                        const baseUrl = Settings.getInstance().BASE_URL + 'medications/medication-images/file/';
                        this.containerImage = baseUrl + result2.find(s => s.imageType === 'Container').id;
                        this.dosageImage = baseUrl + result2.find(s => s.imageType === 'Tablet').id;
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Fetching Bulk Unit by ICN');
                    });


            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching Bulk Unit by ICN');
            });
    }
    public showAltDirections(toggleVal: number): void {

        if (this.formularyMedication === undefined) {
            return;
        }

        if (toggleVal === 1) {
            this.directions = this.formularyMedication.altDirections;
        }
        else {
            this.directions = this.formularyMedication.directions;
        }
    }
    public showAltPrecautions(toggleVal: number): void {

        if (this.formularyMedication === undefined) {
            return;
        }

        if (toggleVal === 1) {
            this.precautions = this.formularyMedication.altPrecautions;
        }
        else {
            this.precautions = this.formularyMedication.precautions;
        }
    }
    public insertBulkItems(bui: BprBulkItems): void {

        this.formingService.createBprItem(bui).subscribe(

            (data) => {
                bui.id = data.id;
                this.records.push(bui);
                this.updateCheckedStatus(this.selectedBulkUnit.id, 'Checked-Out');
                this.dataSource = new MatTableDataSource(this.records);
                this.calcDosages();

             },
            (error) => {
                    Settings.getInstance().handleError(error, 'Insert new BPR Items record');
             });
    }
    public updateCheckedStatus(id: number, checkInStatus: string): void {

        /*** Now update the status to reflect that it is checked out ***/
        this.bulkUnitsService.updateBulkUnitsCheckInStatus(id, checkInStatus).subscribe(
            (data) => {
                // create a transaction history record for this container.
                this.txHistory.createTransactionHistory('bulkunits', id, checkInStatus,
                    'Container ' + checkInStatus + ' - Order: ' + this.ordNumber);

                // clear out the field and place the cursor back

                this.icnControl.setValue('');
                DwUtils.setFocus('icnId');
            },
            (error) => {
                Settings.getInstance().handleError(error, 'updating checked in/out status');
            });
    }
    public deleteFromBulkUnitItems(row: any): void {

        this.selectedBPRItem = this.records[row];


           this.formingService.deleteBprItem(this.selectedBPRItem.id).subscribe(
               (data) => {
                    this.updateCheckedStatus(this.selectedBPRItem.bulkUnitId, 'Checked-In');
                },
               (error) => {
                   Settings.getInstance().handleError(error, 'Removing a BPR item');
               });

        this.records.splice(row, 1);
        this.dataSource = new MatTableDataSource(this.records);
        this.calcDosages();
    }
    /*** utilities ***/
    public tableKeyDown(event: any): void {

    }
    public lbFmt(inp: string): string {
        return DwUtils.formatDTFromString(inp);
    }
    public lbFmtDate(inp: string): string {
        return DwUtils.formatDateFromString(inp);
    }
    public lblDescription(): string {

        return this.selectedForm.quantity + ' X [' + this.selectedForm.itemPrefix + '] ' + this.selectedForm.description;
    }
    public onRowClicked(selected: any): void {

        this.selectedBPRItem = selected;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedBPRItem.icn === '') {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.icn === this.selectedBPRItem.icn);

    }
    public unSelectRow() {
        this.Idx = -1;
        this.selectedBPRItem = new BprBulkItems();
    }
    public doMouseOverImage(event: any): void {

        if (event.currentTarget !== undefined) {
            const backImg = event.currentTarget.style.backgroundImage
                .replace('url\(\"', '')
                .replace('\"\)', '');

            if (backImg.length > 0) {
                this.largeImgUrl = backImg;
                this.displayLargeImage = true;
            }
        }
    }
    public doMouseOutImage(): void {
        if (this.displayLargeImage === true) {
            this.displayLargeImage = false;
        }
    }
    public onLabelVerificationCheck(event: any): void {

    }
    public onDosesVerificationCheck(event: any): void {

    }
    public onChangePrepStationClean(event: any) {

    }
    public onPrintLabelsChange(event: any): void {

    }
    public onChangeStations(event: any): void {

    }
    public onClickAddBulkItem(event: any): void {

        const icnNumber = this.icnControl.value;

        if ((icnNumber === null) || (icnNumber.length === 0)) {
            this.strErr = 'Error: Please enter (or scan) an ICN Number';
            DwUtils.highlightFocus('icnId');
            return;
        }

        this.queryBulkItems(icnNumber);
    }
    public setPreparationRecord(): void {

        this.selectedForm.bpr.storageHumidity = this.storageHumidityControl.value;
        this.selectedForm.bpr.storageTemp = this.storageTempControl.value;
        this.selectedForm.bpr.stationId = this.selectedStation.id;

        this.prepApproveDirPrec === true ? this.selectedForm.bpr.prepApproveDirPrec = 1 : this.selectedForm.bpr.prepApproveDirPrec = 0;
        this.prepLabelImgVerify === true ? this.selectedForm.bpr.prepLabelImgVerify = 1 : this.selectedForm.bpr.prepLabelImgVerify = 0;
        this.prepDosageImgVerify === true ? this.selectedForm.bpr.prepDosageImgVerify = 1 : this.selectedForm.bpr.prepDosageImgVerify = 0;
        this.prepStationClean === true ? this.selectedForm.bpr.prepStationClean = 1 : this.selectedForm.bpr.prepStationClean = 0;
    }
    public onStartClicked(): void {
        this.strErr = '';
        this.setPreparationRecord();

        /*** Have any bulk items been selected? ***/
        if (this.records.length < 1) {
            this.strErr = 'Error: No bulk units have been selected to fill this batch';
            DwUtils.setFocus('icnId');
            return;
        }

        const bpr: Bpr | null = new Bpr();
        bpr.setBpr(this.selectedForm.bpr);
        const vr = bpr.validatePreparationRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            DwUtils.setFocus(vr.focus_field);
            return;
        }


        /*** Check to see if we have enough doses selected ***/
        /*** if the condition is that we have a positive balance ***/
        /*** then we need to figure out how we treat the unmet dosage quantity ***/

        if (this.dosesBalance > 0) {
            this.valSubmitOption = 1;
            this.shortBulkItemsDlg = true;
        }
        else {
            /*** there are more doses than are required.  this is the more common scenario ***/
            /*** Move along to the next step in the process ***/
            // this.updateBatchProductionRecord(2);
            this.validateFinishedForms();
        }
    }
    /*** treating the overage ***/
    public radioButtonChange(event): void {

    }
    public onSubmitBatchClicked(): void {

        this.shortBulkItemsDlg = false;

        if (this.valSubmitOption === 3) {
            /*** just save the BPR do not attempt to balance ***/
            /*** Keep this at step #1 ***/
            this.updateBatchProductionRecord(1);
        } else {
            /*** re-balance the order item details ***/
            this.doAdjustOrderItems();
        }
    }
    public doAdjustOrderItems(): void {

        /*** this is the start of the daisy chain that deals with realigning
        /*** the net result of the prep batch ***/
        /*** First. fetch the order item record ***/
        /*** Next. update the order item with the new quantity (which is 'selected / pkg size') ***/
        /*** Then update the BPR record with the new quantity and doses required ... ***/
        /*** Third. if we need to create a duplicate Order Item and attach to the order ***/
        /*** We will adjust the result set - zero out the id and insert another row. ***/
        /*** Fourthly we will create a new BPR for the overflow order Item (if that is needed) ***/
        /*** LASTLY, we need to create the FinishedForms which will act as the final product for the repacking ***/
        /*** then update the formulary medication with the last sequence number used ***/
        /*** Simple! ***/

        this.ordersService.getOrderItemsById(this.selectedForm.itemId).subscribe(
            (data) => {

                this.orderItem = data;
                const newqty: number | null = Math.round(this.selectedForm.bpr.dosesSelected / this.pkgSize);
                const remaining: number | null = Number(this.selectedForm.quantity - newqty);

                /*** adjust the item's quantity and unit price ***/
                this.orderItem.quantity = newqty;
                const extended: number = Number(this.orderItem.unitPrice) * Number(this.orderItem.quantity);
                this.orderItem.extendedPrice = extended;

                this.ordersService.updateOrderItem(this.orderItem).subscribe(
                    (orderItemData) => {
                        /*** Make sure the BPR record is updated with the corrected adjustments ***/
                        /*** set stuff here ... ***/
                        this.selectedForm.bpr.quantity = this.orderItem.quantity;
                        this.selectedForm.bpr.dosesRequired = Number(this.selectedForm.bpr.quantity) * Number(this.pkgSize);

                        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
                            (bprData) => {

                                /*** do we need to create another order item with the remainder? (this is #3) ***/
                                if (this.valSubmitOption === 1) {
                                    this.orderItem.id = 0;

                                    /*** adjust the item's quantity and unit price ***/
                                    this.orderItem.quantity = remaining;
                                    this.orderItem.extendedPrice = Number(this.orderItem.unitPrice) * Number(this.orderItem.quantity);

                                    this.ordersService.createOrderItem(this.orderItem).subscribe(
                                        (newOrderItem) => {
                                            /*** Now add a batch Production Record for this order item ***/
                                            const localBpr: Bpr | null = new Bpr();
                                            localBpr.setBpr(this.selectedForm.bpr);
                                            localBpr.duplicateSetOrderItem(newOrderItem, this.pkgSize);

                                            this.formingService.createBpr(localBpr).subscribe(
                                                (newBpr) => {
                                                    this.txHistory.createTransactionHistory('orders', this.orderItem.orderId, 'Adjust',
                                                        this.orderItem.itemPrefix + ': Adjusted quantity to existing item and added a new item for the' +
                                                        ' remainder');
                                                    this.validateFinishedForms();
                                                    },
                                                (error) => {
                                                    Settings.getInstance().handleError(error, 'creating a BPR for the new order item');
                                                });
                                        },
                                        (error) => {
                                            Settings.getInstance().handleError(error, 'creating remainder order item');
                                        });
                                } else {
                                    this.txHistory.createTransactionHistory('orders', this.orderItem.orderId, 'Adjust',
                                        this.orderItem.itemPrefix + ': Adjusted quantity to match in-stock supply');

                                    this.validateFinishedForms();
                                }
                            },
                            (error) => {
                                Settings.getInstance().handleError(error, 'Updating the BPR record with new qty and doses');
                            });
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Adjusting quantity for the existing order item');
                });
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching order item for adjustment');
        });
    }
    public validateFinishedForms(): void {
        /*** check to see if there are finished forms defined for this BPR ***/

        this.formingService.getAllFinishedFormsByBprId(this.selectedForm.bprId).subscribe(
            (result) => {
                if (result.length === 0) {
                    this.createFinishedForms();
                }
                else {

                }
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Validating the existence of finished forms');
            }
        );
    }
    public createMedicationFormulary(): void {

        let cf: CustomerFormulary = new CustomerFormulary();
        let mf: MedicationFamily = new MedicationFamily();
        let mfdp: PrecautionDirection = new PrecautionDirection();
        /** step 1 get the customer formulary ID ***/
        this.formularyService.getCustomerFormularyByCustomerId(this.selectedForm.bpr.customerId).subscribe(
            (result) => {
                cf = result[0];

                /*** now fetch the medication Family ***/
                const ip = DwUtils.returnItemPrefix(this.selectedForm.itemPrefix);
                this.medicationsServices.getMedicationFamilyByItemPrefix(ip).subscribe (
                    (result1) => {
                        if (result1 === null) {
                            this.strErr = 'Error: Medication Family has not been specified for item: \"' + ip + '\"';
                            return;
                        }
                        mf = result1;

                        this.medicationsServices.getMedicationDirectionsPrecautionsByMedicationFamilyId(mf.id).subscribe(
                            (result2) => {

                                if (result2?.id > 0) {
                                    mfdp = result2;
                                }

                                /*** add the customer formulary medication item ***/
                                const fm: FormularyMedication = new FormularyMedication();
                                fm.setFormularyMedicationFromPrepare(cf, mf, mfdp, this.pkgSize);

                                /*** Insert it and get the newly created ID back again ... ***/
                                this.formularyService.createFormularyMedication(fm).subscribe(
                                    (data) => {
                                        if (data !== undefined) {
                                            /*** now get the newly created id and
                                             * call createFinishedForms again ... ***/
                                            this.selectedForm.formularyMedicationId = data.id;
                                            this.createFinishedForms();
                                        }
                                        else {
                                            this.strErr = 'Error: Unable to create a formulary medication with this id';
                                        }
                                    },
                                    (error) => {
                                        Settings.getInstance().handleError(error, 'creating a new formulary medication record');
                                    });
                            },
                            (error) => {
                                Settings.getInstance().handleError(error, 'Fetching Directions and precautions');
                            });

                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Fetching Med family by Item Id');
                    });
            },
            (error) => {
               Settings.getInstance().handleError(error, 'Fetching customer Formulary');
            });

    }
    public createFinishedForms(): void {

        this.finishedForm = new FinishedForms();
        this.rxPrefix = Settings.getInstance().platformId + this.selectedForm.siteId + this.selectedForm.stationId;

        /*** get the last sequence ID from the formulary ***/
        /*** if there is no formulary item that exists for this product ***/
        /*** then create one ***/

        if (this.selectedForm.formularyMedicationId === null) {
          this.createMedicationFormulary();
          return;
        }
        else {
            this.formularyService.getFormularyMedication(this.selectedForm.formularyMedicationId).subscribe(
                (result) => {
                    this.formularyMedication = result;
                    this.lastSequenceNumber = this.formularyMedication.seqNumber;

                    /*** Get elements of the formulary medication label ***/
                    this.formularyService.getFormularyMedicationLabelsByFormularyMedicationId(this.selectedForm.formularyMedicationId).subscribe(
                        (data) => {

                            if ((data === undefined) || (data.length === 0)) {
                                this.labelId = this.defaultLabelId;
                            }
                            else {
                                this.labelId = data[0].id;
                            }
                            /*** set the finished form base elements (note we will add in the item number
                             * when we loop through and insert the finished forms in the db ...
                             ***/
                            this.finishedForm.setFinishedForm(this.formularyMedication,
                                this.selectedForm, this.records[0], this.labelId);

                            /*** Now insert the finished Forms ***/
                            this.iterator = 0;
                            this.runInsertProcessForFinishedForms();

                        },
                        (error) => {
                            Settings.getInstance().handleError(error, 'Fetching Label Template');
                        });
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Fetching Formulary Medication');
                });
        }
    }
    public runInsertProcessForFinishedForms(): void {

        if (this.iterator <= this.selectedForm.quantity) {
            if (this.iterator === 0 ) {
                this.itemNumber = 'SAMPLE';
            }
            else {
                this.lastSequenceNumber++;
                this.itemNumber = this.selectedForm.itemPrefix + '-' + this.lastSequenceNumber;
            }
            this.insertFinishedForms();
        }
        else {
            /*** Update the customer Formulary to set the last sequence number ***/
            this.formularyMedication.seqNumber = this.lastSequenceNumber;
            this.formularyService.updateFormularyMedication(this.formularyMedication).subscribe(
                (result) => {
                    this.updateBatchProductionRecord(2);
                    //this.printContainerLabels();
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Setting the sequence in the customer formulary');
                });
        }
    }
    public insertFinishedForms(): void {

        this.finishedForm.itemNumber = this.itemNumber;
        this.finishedForm.id = 0;
        this.formingService.createFinishedForm(this.finishedForm).subscribe(
            (result) => {
                const id = result.id;
                const currRxNumber = this.rxPrefix + id;
                this.finishedForm.id = id;
                this.finishedForm.rxNumber = currRxNumber;

                /*** update the RxNumber for the finished Form ***/
                /*** must be unique ***/

                this.formingService.updateFinishedForm(this.finishedForm).subscribe(
                    (result2) => {
                        this.iterator++;
                        this.runInsertProcessForFinishedForms();
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Updating Rx number for finished form Record');
                    });
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Creating Finished Form Record');
            });
    }
    public updateBatchProductionRecord(stepToUpdate: number): void {

        /*** Set the next step in the process ***/

        this.selectedForm.bpr.currentStep = stepToUpdate;

        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
            (data) => {
                /*** If print container labels checkbox is selected ***/
                // this.validateFinishedForms();
                this.printContainerLabels();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Updating the batch production record');
            });
    }
    public printContainerLabels(): void {

        if (this.printLabels === true) {
           this.labelGen.doInitialize(false);
        }
        else {
           /*** all done ***/
           this.submitClicked.emit();
        }
    }
    /*** event re-entry ****/
    public doFinishedFromLabels(event: any): void {
       this.submitClicked.emit();
    }
    public onCancelSubmitBatchClicked(): void {
        this.shortBulkItemsDlg = false;
        this.onCancelClicked();
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
    /*** calculations ***/
    public calcDosages(): void {

        this.selectedForm.bpr.dosesRequired = this.selectedForm.quantity * this.pkgSize;
        this.selectedForm.bpr.dosesSelected = this.summaryDosesSelected();
        this.dosesBalance = Number(this.selectedForm.bpr.dosesRequired) - Number(this.selectedForm.bpr.dosesSelected);
    }
    public summaryDosesSelected(): number {
        let tSelected: number | null = 0;

        for (const item of this.records) {
            tSelected += Number(item.returning);
        }
        return Number(tSelected);
    }
}
