/************
 * Model Class
 * File Name ...: addressentries.components.ts
 * Generated By : ianday on 2/23/23
 * Date ........: 2/23/23
 /************/


export interface LicenseEntries {
    id: number;
    description: string;
    edit: string;
    delete: string;
}

export class LicenseEntries {
    public fieldToFocus: string | null = '';

    constructor() {
        this.id = 0;
        this.description = '';
        this.edit = '';
        this.delete = '';
    }


}
