<div style="min-width:1150px;"> <!-- was 1150px -->
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChangeEvent($event)">
        <mat-tab id="home-tab-dashboard" label="Dashboard" >
            <app-home-renderer #dashboard_renderer
                  [totalMetricCardArr]="metricCardArr"
                  [totalChartArr]="chartArr"></app-home-renderer>
        </mat-tab>
        <mat-tab id="home-tab-project-statuses"  label="Reports" >
            <ng-template matTabContent>
               <app-reportsmain></app-reportsmain>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
