<div class="workspace-container">
	<div class="query-strip-container mat-elevation-z2">
		<table height="100%" width="100%">
			<TR>
				<TD class="table-title-cell">
					<i class="fa  fa-medkit fa-fw table-icon-header" style="width: 2em"></i>{{formTitle}}
				</TD>
				<TD style="width: 250px; min-width: 250px;">Formulary &nbsp;&nbsp;&nbsp;&nbsp;
					<mat-select disableOptionCentering (selectionChange)="onChangeFormularyFilter()"
						[(value)]="selectedFormularyFilter" #cbFormulary floatLabel="never"
						style="font-size: 10pt;width: 120px;"
						panelClass="local-drop-down-panel" class="dw-mat-select">
						 <mat-option *ngFor="let item of customerForlumaryList" [value]="item" [matTooltip]="item.id">
	                				{{item.name}} 
	              		</mat-option>   
						</mat-select>
				</TD>
				<TD align="left" style="min-width: 400px;">
					<input text id="txtFilter" #txtFilter class="qs_filter-field"
					[disabled]="this.isEditing"
					(keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
					<button mat-button matSuffix mat-icon-button
						matTooltip="Clear the filter" aria-label="Clear"
						(click)="txtFilter.value=''; clearFilter();">
						<mat-icon>close</mat-icon>
					</button></TD>
				<TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
				<TD style="width: 150px"><mat-select disableOptionCentering
						[(ngModel)]="selectedStatusFilter" #cbStatus floatLabel="never"
						style="font-size: 10pt;width: 120px;"
						panelClass="local-drop-down-panel" class="dw-mat-select"
    					(keydown)="onStatusFilterKeyDown($event)"
						(selectionChange)="onChangeStatusFilterValue($event)">
					<mat-option *ngFor="let status of statusFilterList"
								[value]="status">{{status.description}}</mat-option>
				</mat-select>
				</TD>
				<TD class="table-pagination-cell" align="right">{{pagination}}
					Record(s) ...</TD>
			</TR>
		</table>
	</div>

	<mat-drawer-container autosize> <mat-drawer
		position="end" opened="true" class="mat-elevation-z2 drawer-form"
		style="border: .5px solid #e1e1e1" #drawer mode="side"
		disableClose="true">
	<div style="max-width: 455px; padding: 2px;">
		<!-- tab closed state -->
		<table [hidden]="!propContainerState"
			class="prop-notes-outer-container">
			<TR>
				<TD align="left" style="width: 35px; padding: 0;">
					<button id="contract-toggle-drawer-close-btn" mat-icon-button
						(click)="onPropertiesToggle()">
						<mat-icon>arrow_right</mat-icon>
					</button>
				</TD>
				<TD width="100%" style="text-align: left;"><b>Additional
						Properties</b></TD>
				<TD width="35px"></TD>
			</TR>
		</table>
		<!-- tab open State -->
		<div [hidden]="!propContainerState"	style="width: 450px; ">
			<mat-tab-group class="prop-tab-group"> 
					<mat-tab label="Properties"> 
			 				<app-medication-properties [MedicationFamilyId]="_mfId"
			 				[FormularyMedicationId]="_formularyMedicationId" 
			 				[View]="'Formulary'">
							</app-medication-properties>
						</mat-tab> 
					 <mat-tab label="Notes">
                         <app-notes style="border-top: .5px solid #e1e1e1"
                              [callingComponent]="'formulary'" [View]="'Window'"
                              [parentId]="selectedRecord?.id">
                         </app-notes>
                    </mat-tab>
			</mat-tab-group>
		</div>
	</div>
	<div>
		<!-- retracted state -->
		<div [hidden]="propContainerState" width="100%">
			<button id="contract-toggle-drawer-open-btn" mat-icon-button
				(click)="onPropertiesToggle()">
				<mat-icon>arrow_left</mat-icon>
			</button>
		</div>

		<div class="rotate-text" [hidden]="propContainerState">
			Additional Properties</div>
	</div>
	</mat-drawer>

	<div class="main-client-area-formulary">
		<div #tb1 class="table-container mat-elevation-z1"
			(keydown)="tableKeyDown($event)" tabindex="-1">
			<table mat-table
				[dataSource]="dataSource" (keydown)="tableKeyDown($event)"
				(matSortChange)="onSortChange($event)" matSort>

				<!-- Note that these columns can be defined in any order.
		          The actual rendered columns are set as a property on
		          the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style"
					style="background-color: white; z-index: 99999">Id</th>
				<td mat-cell style="width: 5% !important;font-size: 9pt;"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.id}}</td>
				</ng-container>

				<ng-container matColumnDef="itemNumber">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style"
					style="background-color: white; z-index: 99999">Item Number</th>
				<td mat-cell style="width: 6% !important;font-size: 9pt;"
					*matCellDef="let element">{{element.itemNumber}}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style"
						style="background-color: white; z-index: 99999">Medication Description</th>
					<td mat-cell style="width: 15%;font-size: 9pt;"
						*matCellDef="let element">{{element.medicationDescription}}</td>
				</ng-container>

				<ng-container matColumnDef="packageSize">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style"
					style="background-color: white; z-index: 99999">Pkg size</th>
				<td mat-cell style="width: 8%; text-align: left;font-size: 9pt;"
					*matCellDef="let element">{{element.packageSize}}</td>
				</ng-container>

				<ng-container matColumnDef="approvalStatus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style"
					style="background-color: white; z-index: 99999">Status</th>
				<td mat-cell style="width: 5%;font-size: 9pt; text-align: left;"
					*matCellDef="let element">{{element.approvalStatus}}</td>
				</ng-container>

				<ng-container matColumnDef="createdate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style"
						style="background-color: white; z-index: 99999">Created Date</th>
					<td mat-cell style="width: 10%;font-size: 9pt" *matCellDef="let element">{{element.createdDate |
							date:
							'mm/dd/yyyy'}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">
				</tr>
			</table>
			
		</div>
		<div class="mat-elevation-z2 control-strip">
			<button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
				class="command-button" matTooltip="Add a new record" matTooltipPosition="above"
				(click)="addModifyRecord(true)" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add New
			</button>
			<button *ngIf="visibleModify" mat-button class="command-button"
				style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
				matTooltip="Update a selected record" matTooltipPosition="above"
				(click)="addModifyRecord(false)" mat-button>
				<mat-icon>edit</mat-icon>
				Update
			</button>
			<button *ngIf="visibleDelete" mat-button class="command-button"
				style="margin-top: 5px;" [disabled]="(Idx < 0 || this.isEditing)" matTooltipPosition="above"
				matTooltip="Remove a selected record" (click)="removeRecord()"
				mat-button>
				<mat-icon>remove_circle_outline</mat-icon>
				Remove
			</button>
		</div>
		<!-- details pane -->
		<div id="medication-details-panel"
			class="medication-inv-container mat-elevation-z2"
			style="margin-bottom: 2px;">
			<mat-tab-group animationDuration="0ms"
				(selectedTabChange)="doTabChange($event)"
				[(selectedIndex)]=selectedTabIndex>
				<mat-tab label="Details" [disabled]="isEditing">
				<div class="sub-tab-container-class detail-tab-additional">
					<div class="dwform" style="padding-right: 15px; padding-left: 10px;">

						<div class="dwform-cell-nopadding" style="min-width: 500px;">
							<div class="dwform-row-double-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Record ID:</label> <span
									class="form-required"> </span> <input class="input-field-short"
									style="width: 80px" disabled="true" [(ngModel)]="valId"
									type="text">
							</div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Medication:</label> <span></span>
								<mat-select [disabled]="!isEditing || (isEditing && !isAdd)" [(ngModel)]="valMedicationFamily"
									id="medfamily" #medfamily placeholder="- Please Select -"
									disableOptionCentering floatLabel="never"
									panelClass="local-drop-down-panel" class="dw-mat-select"
									style="width: 357px;font-size: 10pt;margin-left:8px;"
									(selectionChange)="familySelectionChanged();generateItemNumber()"
									[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
									 <mat-option *ngFor="let item of medicationFamilyList" [value]="item" [matTooltip]="item.itemPrefix">
										{{item.description}}
									 </mat-option>
								</mat-select>
							</div>

							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Pkg Size:</label> <span
									class="form-required">*</span>
									<p-autoComplete id="pkgSize" #pkgSize
									[disabled]="!isEditing" appendTo="body"
									forceSelection="true"
									[suggestions]="filteredPkgSizesList"
									[(ngModel)]="valPkgSize"
									(onBlur)="pkgSizeSelectionchanged()"
									(completeMethod)="filterPackageSizesList($event)"
									(keydown.enter)="pkgSize.hide();"
									(onSelect)="pkgSizeSelectionchanged()"
									(keydown.backspace)="filterPackageSizesList($event)"
									(onFocus)="pkgSize.show()"
									(click)="filterPackageSizesList($event)"> </p-autoComplete>
							</div>

							<div class="dwform-row-gap"></div>
						<div class="dwform-row">
								<label class="label-field">Sequence Number:</label> <span
									class="form-required"></span>
									<label>
									{{valSeqNumber}}
									</label>
							</div>
						<div class="dwform-row-gap"></div>
						<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Item Number:</label> <span
									class="form-required"></span>
									<label class="item-number">
									{{valItemNumber}}
									</label>
							</div>
						</div>
					</div>
					<!--ends the form -->
					<div [hidden]='!strErrLine.length' style="left: 10px;"
						class="error-line" style="bottom: 68px;">{{strErrLine}}</div>
					<!--save strip -->
					<div class="save-close-strip" style="bottom: 68px;">

						<button mat-button class="command-button" *ngIf="this.isEditing"
							(click)="submitRecord()" matTooltip="Submit the selected record"
							mat-button>
							<mat-icon>check_circle_outline</mat-icon>
							Submit
						</button>
						<button mat-button class="command-button" *ngIf="this.isEditing"
							(click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
							<mat-icon>highlight_off</mat-icon>
							Cancel
						</button>
					</div>
				</div>
				</mat-tab>
				<mat-tab label="Status" [disabled]="isEditing">
					<div class="sub-tab-container-class detail-tab-additional">
						<table style="width: 100%">
							<tr>
								<td style="width: 30%">
									Current Status: <label style="font-weight:bold;margin-left: 10px;">{{currentStatus}}</label>
								</td>
								<td style="width: 70%; text-align: right;">
									<button mat-button class="command-button" [disabled]="this.isEditing || !this.selectedRecord"
										(click)="changeStatus()" matTooltip="change status of the formulary medication workflow"
										mat-button>
										Change Status
									</button>
								</td>
							</tr>
						</table>
						<div #txtbl12 class="mat-elevation-z1 tx-table-div" id="txTableStatusId"
							 style="outline:none!important; padding-right:6px;"
							 (keydown)="tableKeyDownStatuses($event)" tabindex="-1">
							<table mat-table [dataSource]="dataSourceStatus" matSort
								   style="outline: none!important;"
								   class="tx-table-properties"
								   (keydown)="tableKeyDownStatuses($event)"
								   (matSortChange)="onSortChangeStatuses($event)">

								<ng-container matColumnDef="statusDate">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Date</th>
									<td mat-cell style="width:8% !important; "
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										{{element.statusDate | date: 'MM/dd/yyyy'}}</td>
								</ng-container>

									<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Status</th>
									<td mat-cell style="width:8% !important; "
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										{{element.status}}</td>
								</ng-container>

								<ng-container matColumnDef="statusBy">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">By</th>
									<td mat-cell style="width:8% !important;"
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
									   {{element.statusBy}}</td>
								</ng-container>

								<ng-container matColumnDef="statusComment">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Comment</th>
									<td mat-cell style="width:8% !important;"
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										{{element.statusComment}}</td>
								</ng-container>

								 <ng-container matColumnDef="fileURL">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">File</th>
									<td mat-cell style="width:8% !important;"
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										<a *ngIf="element.documentURL" [href]="element.documentURL" target="_blank">Open</a></td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumnsStatus; sticky: true"></tr>
								<tr mat-row style="font-size: 8pt;"
									*matRowDef="let row; columns: displayedColumnsStatus; let tableRowIdx = index;"
									[ngClass]="{'row-selected': tableRowIdx == IdxStatuses}"
									(click)="onRowClickedStatuses(row)"
									>
								</tr>
							</table>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Material" [disabled]="isEditing">
				<div class="sub-tab-container-class detail-tab-additional">
					<div class="sub-tab-container-class detail-tab-additional">
						<table style="width: 100%">
							<tr>
								<td style="width: 30%">

								</td>
								<td style="width: 70%; text-align: right;">
									<button mat-button class="command-button" [disabled]="this.isEditing"
										(click)="doAddNewMaterial()" matTooltip="add new component"
										mat-button>
										Add Material
									</button>
								</td>
							</tr>
						</table>
						<div #txtbl1 class="mat-elevation-z1 tx-table-div" id="txTableMaterialsId"
							 style="outline:none!important; padding-right:6px;"
							 (keydown)="tableKeyDownMaterials($event)" tabindex="-1">
							<table mat-table [dataSource]="dataSourceMaterial" matSort
								   style="outline: none!important;"
								   class="tx-table-properties"
								   (keydown)="tableKeyDownMaterials($event)"
								   (matSortChange)="onSortChangeMaterials($event)">


								<ng-container matColumnDef="id">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Id</th>
									<td mat-cell style="width:8% !important; "
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
									   {{element.id}}</td>
								</ng-container>


								<ng-container matColumnDef="description">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Description</th>
									<td mat-cell style="width:48% !important; "
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										{{element.description}}</td>
								</ng-container>

								  <ng-container matColumnDef="itemType">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="background-color: white; z-index:99999">Item Type</th>
									<td mat-cell style="width:48% !important; "
										class="tx-data-properties"
										*matCellDef="let element;let tableRowIdx = index;">
										{{element.itemType}}</td>
								</ng-container>

								 <ng-container matColumnDef="delete">
									<th mat-header-cell *matHeaderCellDef mat-sort-header
										style="width: 20%; min-width: 100px;background-color: white;z-index: 99999;"> </th>
									<td mat-cell  *matCellDef="let element;let tableRowIdx = index;" minWidth="30px;" style="width: 15%; min-width: 60px;
									text-align: right;">
										<span>
											<img style="width: 12px;cursor: pointer"
												 class="edit-delete-button"
												 alt="remove" src="assets/delete-icon.png"
												 (click)="deleteMaterial(element)">
										</span></td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumnsMaterials; sticky: true"></tr>
								<tr mat-row style="font-size: 8pt;"
									*matRowDef="let row; columns: displayedColumnsMaterials; let tableRowIdx = index;"
									[ngClass]="{'row-selected': tableRowIdx == IdxMaterials}"

									(click)="onRowClickedMaterials(row)">
								</tr>
							</table>
						</div>
					</div>
				</div>
				</mat-tab>
				<mat-tab label="Labels" [disabled]="isEditing">
					<div class="sub-tab-container-class detail-tab-additional">
							<table style="width: 100%">
								<tr>
									<td style="width: 30%">

									</td>
									<td style="width: 70%; text-align: right;">
										<button mat-button class="command-button" [disabled]="this.isEditing"
											(click)="doAddNewLabel()" matTooltip="add new label"
											mat-button>
											Add Label
										</button>
									</td>
								</tr>
							</table>
							
							<table style="width: 100%;">
								<tr style="width: 100%">
									<td style="width: 80%;height: 100%;vertical-align: baseline;">
									<div #tb4 class="formulary-tab-table-container mat-elevation-z1"
											(keydown)="tableKeyDownLabels($event)" tabindex="-1"
										 style="outline:none!important;">
										 <table mat-table [dataSource]="dataSourceLabel" matSort
											   style="outline: none!important;"
											   class="tx-table-properties"
											   (keydown)="tableKeyDownLabels($event)"
											   (matSortChange)="onSortChangeLabels($event)">


											<ng-container matColumnDef="id">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="background-color: white; z-index:99999">Id</th>
												<td mat-cell style="width:8% !important; "
													class="tx-data-properties"
													*matCellDef="let element;let tableRowIdx = index;">
												   {{element.id}}</td>
											</ng-container>


											<ng-container matColumnDef="uploadedDate">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="background-color: white; z-index:99999">Date</th>
												<td mat-cell style="width:8% !important; "
													class="tx-data-properties"
													*matCellDef="let element;let tableRowIdx = index;">
													{{element.uploadedDate | date: 'MM/dd/yyyy'}}</td>
											</ng-container>

											<ng-container matColumnDef="uploadedBy">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="background-color: white; z-index:99999">By</th>
												<td mat-cell style="width:8% !important;"
													class="tx-data-properties"
													*matCellDef="let element;let tableRowIdx = index;">
													{{element.uploadedBy}}</td>
											</ng-container>

											<ng-container matColumnDef="default">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="background-color: white; z-index:99999">Default</th>
												<td mat-cell style="width:8% !important;"
													class="tx-data-properties"
													*matCellDef="let element;let tableRowIdx = index;">
													{{element.defaultFlag}}</td>
											</ng-container>

											  <ng-container matColumnDef="labelURL">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="background-color: white; z-index:99999">File</th>
												<td mat-cell style="width:50% !important;"
													class="tx-data-properties"
													*matCellDef="let element;let tableRowIdx = index;">
													 <a [href]="element.labelURL" target="_blank">{{element.labelFilePath}}</a></td>
											</ng-container>

											 <ng-container matColumnDef="download"
												   style="text-align: center!important;">
												 <th mat-header-cell *matHeaderCellDef mat-sort-header
													 style="width: 20%;
													 min-width: 100px;background-color: white;z-index: 99999;">
												 </th>
												 <td mat-cell  *matCellDef="let element;let tableRowIdx = index;"
													 style="width: 15%; min-width: 60px;
													 text-align:right;">
													<span>
														<img style="width: 20px;cursor: pointer"
															 matTooltip="Download file"
															 alt="download"
															 src="assets/download.png" (click)="downloadLabel(element)">
													</span></td>
											 </ng-container>

											<ng-container matColumnDef="delete">
												<th mat-header-cell *matHeaderCellDef mat-sort-header
													style="min-width: 100px;background-color: white;z-index: 99999;"> 												</th>
												<td mat-cell  *matCellDef="let element;let tableRowIdx = index;" minWidth="30px;" 
													style="width: 15%; min-width: 60px;	text-align: right;">
													<span>
														<img style="width: 12px;cursor: pointer"
															 matTooltip="Delete Record"
															 class="edit-delete-button" alt="remove" src="assets/delete-icon.png"
															 (click)="deleteLabel(element)">
													</span></td>
											</ng-container>

											<tr mat-header-row *matHeaderRowDef="displayedColumnsLabel; sticky: true"></tr>
											<tr mat-row style="font-size: 8pt;"
												*matRowDef="let row; columns: displayedColumnsLabel; let tableRowIdx = index;"
												[ngClass]="{'row-selected': tableRowIdx == IdxLabels}"
												(click)="onRowClickedLabels(row)">
											</tr>
										</table>
										</div>
									</td>
									<td style="width: 80%;height: 100%;vertical-align: middle;">
										<div class="label-image"
											 (mouseover)="doMouseOverImage($event)"
											 (mouseleave)="doMouseOutImage()"
											 [ngStyle]="{ 'background-image': 'url(' + selectedImageURL + ')'}">
										</div>
									</td>
								</tr>
							 </table>
				</div>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
	</mat-drawer-container>
</div>

<!-- save strip -->
	<div class="save-close-strip" style="bottom: 5px;padding: 0px!important">
		<button mat-button class="command-button"
			(click)="doClose()" matTooltip="Close"
			mat-button>
			<mat-icon>highlight_off</mat-icon>
			Close
		</button>
</div>

<p-dialog header="Change Status"
          [(visible)]="displayChangeStatusWindow"
          *ngIf="displayChangeStatusWindow"
          [modal]="true" 
           appendTo="body"
           [responsive]="true"
          [style]="{width: '600px', height: '600px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
   <app-change-status #changeStatus
          [(formularyMedication)]="this.selectedRecord"
					  (cancelClicked)="this.cancelClickedFromChangeStatus()" (submitClicked)="this.submitClickedFromChangeStatus()">
   </app-change-status>
</p-dialog>

<p-dialog header="New Label"
          [(visible)]="displayAddLabelWindow"
          [modal]="true"
           appendTo="body"
           [responsive]="true"
          [style]="{width: '440px', height: '450px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
   <app-add-label #addlabel
         [(formularyMedication)]="this.selectedRecord"
		(cancelClicked)="this.cancelClickedFromAddLabel()"
		(submitClicked)="this.submitClickedFromAddLabel()">
   </app-add-label>
</p-dialog>

<p-dialog header="New Material"
          [(visible)]="displayAddMaterialWindow"
          [modal]="true"
           appendTo="body"
           [responsive]="true"
          [style]="{width: '540px', height: '300px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
   <app-add-material #addMaterial
         [(formularyMedication)]="this.selectedRecord" [formularyMaterialsList]="formualryMedicationMaterialsList" [materialsList]="this.materialsList" (cancelClicked)="this.cancelClickedFromAddMaterial()" (submitClicked)="this.submitClickedFromAddMaterial()">
   </app-add-material>
</p-dialog>

<p-dialog header="Large Image View"
		  [showHeader]="false"
		  [(visible)]="displayLargeImage"
		  [modal]="false"
		  [style]="{position: 'absolute', left: '50%'}"
		  appendTo="body" [responsive]="true"
		  [resizable]="false" [closable]="false">
	<img src="{{largeImgUrl}}" height="650px" width="280px" alt="something"  >
</p-dialog>
