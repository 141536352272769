<div [ngClass]="viewClass" >
	<div>
		<div  class="label-div-text">Precautions:<span>
			<button mat-button class="default-button"
					*ngIf="isEditing&&_view==='Formulary'" (click)="defaultPrecautionsClicked()">Default</button></span></div>
		<!-- YUK! No better way to do this :( unless you are a CSS genius -->
		<textarea matInput class="properites_field_window" [disabled]="!isEditing"
				  *ngIf="_view==='Formulary'"
				  [(ngModel)]="valPrecautions"></textarea>
		<textarea matInput class="properites_field" [disabled]="!isEditing"
				  *ngIf="_view!=='Formulary'"
				  [(ngModel)]="valPrecautions"></textarea>

	</div>
	<div>
		<div  class="label-div-text">Alt. Precautions:<span><button
				mat-button class="default-button"
			*ngIf="isEditing&&_view==='Formulary'" (click)="defaultAltPrecautionsClicked()">Default</button></span></div>

		<textarea matInput class="properites_field_window" [disabled]="!isEditing"
				  *ngIf="_view==='Formulary'"
				  [(ngModel)]="valAltPrecautions"></textarea>
		<textarea matInput class="properites_field" [disabled]="!isEditing"
				  *ngIf="_view!=='Formulary'"
				  [(ngModel)]="valAltPrecautions"></textarea>

	</div>
	<div>
		<div class="label-div-text">Directions
			:<span><button 	mat-button class="default-button"
			*ngIf="isEditing&&_view==='Formulary'" (click)="defaultDirectionsClicked()">Default</button></span></div>

		<textarea matInput class="properites_field_window" [disabled]="!isEditing"
				  *ngIf="_view==='Formulary'"
				  [(ngModel)]="valDirections"></textarea>
		<textarea matInput class="properites_field" [disabled]="!isEditing"
				  *ngIf="_view !== 'Formulary'"
				  [(ngModel)]="valDirections"></textarea>

	</div>
	<div>
		<div  class="label-div-text">Alt. Directions:<span><button mat-button
			class="default-button" *ngIf="isEditing&&_view==='Formulary'"
				(click)="defaultAltDirectionsClicked()">Default</button></span></div>

		<textarea matInput class="properites_field_window" [disabled]="!isEditing"
				  *ngIf="_view==='Formulary'"
				  [(ngModel)]="valAltDirections"></textarea>
		<textarea matInput class="properites_field" [disabled]="!isEditing"
				  *ngIf="_view!=='Formulary'"
				  [(ngModel)]="valAltDirections"></textarea>

	</div>
	<div class="modify-strip">
	<span>
		<button id="update-button_id" *ngIf="!isEditing" mat-button	[disabled]="this._medicationFamilyId===undefined || this.__medicationFamilyId===0" class="command-button" style="margin-top: 5px;"
				matTooltip="Edit Properties" (click)="editClickHandler()">
			<mat-icon>edit</mat-icon>
			Modify
		</button>
		<button id="save-button_id" *ngIf="isEditing" mat-button class="command-button" style="margin-top: 5px;"
				matTooltip="Save Properties" (click)="saveClickHandler()">
			<mat-icon>done</mat-icon>
			Save
		</button>
		<button id="cancel-button_id" *ngIf="isEditing" mat-button class="command-button" style="margin-top: 5px;"
				matTooltip="Cancel Edit" (click)="cancelClickHandler()">
			<mat-icon>clear</mat-icon>
			Cancel
		</button>
	</span>
	</div>
</div>
