import { Component, OnInit } from '@angular/core';
import { onSideNavChange, animateText } from '../animations/animations';
import { SidenavService } from '../services/sidenav.service';
import { AuthService } from '../services/authservice/auth.service';
import { NavbarEventManager } from '../_common/NavbarEventManager';
import { AppMapComponent } from '../app-map.component';
import { Settings } from '../services/settings';
import { __assign } from 'tslib';

interface Page {
	link: string;
	name: string;
	icon: string;
	mouseover: string;
	id: string;
}

@Component({
	selector: 'app-left-menu',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.css'],
	animations: [onSideNavChange, animateText]
})
export class NavigationComponent implements OnInit {

	public sideNavState: boolean = false;
	public linkText: boolean = false;
	public IsAuthenticated: boolean = false;
	private showNavBar: boolean = false;
	public featurelist: Page[] = [];

	public pages: Page[] = this.appMap.navItems;

	constructor(private authService: AuthService, public sidenavService: SidenavService, public appMap: AppMapComponent, private navEventMgr: NavbarEventManager) {

		this.authService.isAuthenticated$.subscribe(value => { this.IsAuthenticated = value; });
		this.navEventMgr.showNavBar.subscribe((mode: any) => {
			this.showNavBar = mode;
			if (this.showNavBar === true) {
				this.featurelist = [];
				for (let i = 0; i < this.pages.length; i++) {
					const rec = this.pages[i];
					switch (rec.id) {
						case 'nav-home':
							this.featurelist.push(rec);
							break;
						case 'nav-settings':
							if (Settings.getInstance().hasPrivilege('NAV_SETTINGS')) {
								this.featurelist.push(rec);
							}
							break;
						case 'nav-inventory':
							if (Settings.getInstance().hasPrivilege('NAV_INVENTORY')) {
								this.featurelist.push(rec);
							}
							break;
						case 'nav-forming':
							if (Settings.getInstance().hasPrivilege('NAV_FORMING')) {
								this.featurelist.push(rec);
							}
							break;
					}
				}
				window.dispatchEvent(new Event('resize'));
			}
		});

		this.navEventMgr.hideNavBar.subscribe((mode: any) => {
			this.showNavBar = false;
			this.featurelist = [];
		});
	}

	ngOnInit() {

	}

	onSidenavToggle() {
		this.sideNavState = !this.sideNavState;

		setTimeout(() => {
			this.linkText = this.sideNavState;
		}, 200);
		this.sidenavService.sideNavState$.next(this.sideNavState);
	}

}
