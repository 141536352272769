import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, retryWhen, mergeMap, scan, tap, delay, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { UserOTP } from '../models/user-otp';
import { AppFunction } from '../models/app-function';
import { AppFunctionGroup } from '../models/app-function-group';
import { AppFunctionGroupMap } from '../models/app-function-group-map';
import { UserAppFunctionMap } from '../models/user-app-function-map';
import { UserAppFunctionGroupMap } from '../models/user-app-function-group-map';
import { Settings } from './settings';
import { GeneralSetting } from '../models/generalsetting';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) { }

	getUser(userName): Observable<User> {
		return this.http.get<User>(Settings.getInstance().BASE_URL + 'users/username/' + userName)
			.pipe(
				retry(1)
			);
	}
	getUserById(paramId): Observable<User> {
		return this.http.get<User>(Settings.getInstance().BASE_URL + 'users/' + paramId)
			.pipe(
				retry(1)
			);
	}
	getLoggerInUser(): Observable<User> {
		return  this.http.get<User>(Settings.getInstance().BASE_URL + 'users/me').pipe(
				retryWhen(error => {
				    return error.pipe(
				        mergeMap(error => {
				            if (error.status == 404) {
				                return this.http.get<User>(Settings.getInstance().BASE_URL + 'users/me');
				            }
				            return throwError(error);
				        }),
				        scan((acc, error) => {
				            if (acc > 2) throw error;
				            return acc + 1;
				        }, 0),
				        tap((c) => console.log(`retrying ${c}`)),
				        delay(2000),
				    );
				})
		);
	}
	login(userName: string, password: string, errorHandler = null): Observable<any> {
		//return this.getUser(userName);
		//const headers = new HttpHeaders({authorization : 'Basic ' + btoa(userName + ':' + password)});
		let user = new User()
		user.username = userName;
		user.password = password;
		return this.http.post<User>(Settings.getInstance().BASE_URL + 'users/login', user)
			.pipe(
				retry(1)
			);
	}
	logout(): Observable<void> {
		return this.http.get<void>(Settings.getInstance().BASE_URL + 'users/logout')
			.pipe(
				retry(0)
			);
	}
	getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(Settings.getInstance().BASE_URL + 'users')
			.pipe(
				retry(1)
			);
	}
	getAllAppFunctionGroups(): Observable<AppFunctionGroup[]> {
		return this.http.get<AppFunctionGroup[]>(Settings.getInstance().BASE_URL + 'general/appFunctionGroups')
			.pipe(
				retry(1)
			);
	}
	getAllAppFunctionGroupMaps(): Observable<AppFunctionGroupMap[]> {
		return this.http.get<AppFunctionGroupMap[]>(Settings.getInstance().BASE_URL + 'general/appFunctionGroupMaps')
			.pipe(
				retry(1)
			);
	}
	getAppFunctionGroupMapsByAppFunctionGroupId(id: number): Observable<AppFunctionGroupMap[]> {
		return this.http.get<AppFunctionGroupMap[]>(Settings.getInstance().BASE_URL + 'general/appFunctionGroupMaps/' + id)
			.pipe(
				retry(1)
			);
	}
	getAllAppFunctions(): Observable<AppFunction[]> {
		return this.http.get<AppFunction[]>(Settings.getInstance().BASE_URL + 'general/appFunctions')
			.pipe(
				retry(1)
			);
	}
	createUser(user: any): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users', user)
			.pipe(
				retry(1)
			);
	}
	updateUser(user: any): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'users/' + user.id, user)
			.pipe(
				retry(1)
			);
	}
	updateUserLastLogin(user: User): Observable<any> {
		const strLastLogin = new Date().getTime();
		return this.http.put(Settings.getInstance().BASE_URL + 'users/update/' + user.username + '/' + strLastLogin, null)
			.pipe(
				retry(1)
			);
	}
	updateUserDashboardSetup(user: User, dashboardSetup: any): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'users/update/dashboardsettings/' + user.username, JSON.stringify(dashboardSetup))
			.pipe(
				retry(1)
			);
	}
	createAppFunctionGroups(appFunctionGroups: AppFunctionGroup): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'general/appFunctionGroups', appFunctionGroups)
			.pipe(
				retry(1)
			);
	}
	updateAppFunctionGroups(appFunctionGroups: AppFunctionGroup): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'general/appFunctionGroups/', appFunctionGroups)
			.pipe(
				retry(1)
			);
	}
	createAppFunctionGroupMaps(appFunctionGroupId: number, appFunctionGroupMapList: any[]): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'general/appFunctionGroupMaps/' + appFunctionGroupId, appFunctionGroupMapList)
			.pipe(
				retry(1)
			);
	}
	createUserAppFunctionGroupMaps(userId: number, userAppFunctionGroupMapList: UserAppFunctionGroupMap[]): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/userAppFunctionGroupMaps/' + userId, userAppFunctionGroupMapList)
			.pipe(
				retry(1)
			);
	}
	createUserAppFunctionMaps(userId: number, userAppFunctionMapList: any[]): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/userAppFunctionMaps/' + userId, userAppFunctionMapList)
			.pipe(
				retry(1)
			);
	}
	getUserAppFunctionGroupMapsByUserId(id: number): Observable<UserAppFunctionGroupMap[]> {
		return this.http.get<UserAppFunctionGroupMap[]>(Settings.getInstance().BASE_URL + 'users/userAppFunctionGroupMaps/' + id)
			.pipe(
				retry(1)
			);

	}
	getUserAppFunctionMapsByUserId(id: number): Observable<UserAppFunctionMap[]> {
		return this.http.get<UserAppFunctionMap[]>(Settings.getInstance().BASE_URL + 'users/userAppFunctionMaps/' + id)
			.pipe(
				retry(1)
			);
	}
	getUserAppFunctionGroupMapsByGroupId(groupId: number): Observable<UserAppFunctionGroupMap[]> {
		return this.http.get<UserAppFunctionGroupMap[]>(Settings.getInstance().BASE_URL + 'users/userAppFunctionMaps/mapping/' + groupId)
			.pipe(
				retry(1)
			);
	}
	deleteUser(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'users/' + id)
			.pipe(
				retry(1)
			);
	}
	deleteAppFunctionGroup(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'general/appFunctionGroups/' + id)
			.pipe(
				retry(1)
			);
	}
	initiatePasswordReset(email: string): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/initiatePasswordRecovery/' + email, null)
			.pipe(
				retry(1)
			);
	}
	setNewPasswordWithOTP(userOTPRecord: UserOTP): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/password/reset', userOTPRecord)
			.pipe(
				retry(1)
			);
	}
	setPasswordForNewUser(userOTPRecord: UserOTP): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'new/', userOTPRecord)
			.pipe(
				retry(1)
			);
	}
	getUserProfileImage(userId: any): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'users/profile/image/' + userId, { responseType: 'blob' as 'json' })
			.pipe(
				retry(0)
			);
	}
	
	removeProfileImage(userId: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'users/profile/image/' + userId)
			.pipe(
				retry(1)
			);
	}
	updateUserProfileImage(userId: any, profileImage: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('profile', profileImage, profileImage.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'users/profile/image/' + userId, formData)
			.pipe(
				retry(0)
			);
	}
	getUserDetailsUsingInvitationToken(token: string): Observable<User> {
		return this.http.get<User>(Settings.getInstance().BASE_URL + 'users/fetchUserByInvitation/' + token);
	}
	updateUserProfileImageByToken(token: string, profileImage: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', profileImage, profileImage.name);
		return this.http.put<any>(Settings.getInstance().BASE_URL + 'users/profile/image/' + token, formData)
			.pipe(
				retry(0)
			);
	}
	setNewPasswordWithToken(token: string, userOTPRecord: UserOTP): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'users/invitation/update/password', userOTPRecord)
			.pipe(
				retry(1)
			);
	}

	sendUserInvitations(userIDList: number[]): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/invite', userIDList)
			.pipe(
				retry(1)
			);
	}
	getAllSettings(): Observable<GeneralSetting[]> {
		return this.http.get<GeneralSetting[]>(Settings.getInstance().BASE_URL + 'general/settings')
			.pipe(
				retry(1)
			);
	}
	createUpdateSetting(record: GeneralSetting): Observable<any> {
		let param: GeneralSetting[] = Array<GeneralSetting>();
		param = [];
		param.push(record);
		if (record.id === 0) {
			return this.http.post(Settings.getInstance().BASE_URL + 'general/settings', param)
				.pipe(
					retry(1)
				);
		} else {
			return this.http.post(Settings.getInstance().BASE_URL + 'general/settings', param)
				.pipe(
					retry(1)
				);
		}
	}
	testEmailConnection(host: string, port: number, username: string, password: string, tls: boolean): Observable<any> {
		/*const formData: FormData = new FormData();
		formData.append('host', '' + host);
		formData.append('port', '' + port);
		formData.append('username', '' + username);
		formData.append('password', '' + password);
		formData.append('tls', '' + true);
		*/
		
		let data={'host':host,'port':port,'username':username,'password':password,'tls':tls}
		
		return this.http.post<any[]>(Settings.getInstance().BASE_URL + 'general/email/test/', data)
			.pipe(
				retry(1)
			);
	}
	updateOrganizationUsers(organizationId_param: number, userIdList_param: number[]): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'users/updateOrganizationUsers/' + organizationId_param, userIdList_param)
			.pipe(
				retry(1)
			);
	}
}

