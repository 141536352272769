<div class="med-image-container">

		<div style="width: 100%; margin-top: 15px; margin-bottom: 5px;padding-left: 20px;padding-right: 20px;">
			<button mat-button style="float: right;" [disabled]="this._medication===undefined || this._medication.id===0"
				class="command-button" matTooltip="Add a new image"
				(click)="addImage()" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add Image
			</button>
		</div>

	    <!-- Image table -->
		<div class="table-container" (keydown)="tableKeyDown($event)"
			 style="outline: none!important;"
			 tabIndex="-1">
			<table id="medication-images-table" mat-table tabindex="-1" [dataSource]="medicationImages" matSort
				   (mouseout)="doMouseOut()"
				   style="width: 100%;padding-left: 3px; padding-right: 3px;">
				<!--  Column -->
				<ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef
						style="width: 20%; min-width: 100px;
						background-color: white; z-index:99999;"> </th>
					<td mat-cell *matCellDef="let medImage" minWidth="30px;"
						style="width: 20%; min-width: 30px; text-align: right;">
						<span> <img style="width: 12px;" class="edit-delete-button" alt="remove" src="assets/delete-icon.png" (click)="deleteImage(medImage)"> </span></td>
				</ng-container>

				<ng-container matColumnDef="imageType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style="width: 70%; min-width: 100px;background-color: white; z-index:99999;"> Type	</th>
					<td mat-cell *matCellDef="let medImage" minWidth="100px;" style="width: 70%; min-width: 100px"><span> {{medImage.imageType}} </span></td>
				</ng-container>

				<ng-container matColumnDef="defaultFlag">
					<th mat-header-cell *matHeaderCellDef
						mat-sort-header
						style="width: 70%; min-width: 100px; background-color: white; z-index:99999;"> Default </th>
					<td mat-cell *matCellDef="let medImage" minWidth="100px;" style="width: 70%; min-width: 100px">
						<span>  <mat-checkbox class="example-margin" binary="true" [(ngModel)]="medImage.defaultFlag"
											  (change)="defaultFlagCheckBoxClicked(medImage, $event)"></mat-checkbox> </span>
					</td>
				</ng-container>

				<ng-container matColumnDef="image">`
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						style="width: 30%; min-width: 100px;background-color: white; z-index:99999;"> Image </th>
					<td mat-cell *matCellDef="let medLink" fxLayout="column wrap"  style="width: 30%; min-width: 100px">
						<img style="width:50px; min-height: 30px;"
							 (mouseover)="doMouseOver($event)"
							 [src]="BASE_URL+'medications/medication-images/file/'+medLink.id"> </td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
					<tr mat-row
						*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
						[ngClass]="{'row-selected': tableRowIdx == Idx}"
						(click)="onRowClicked(row)">
				</tr>
			</table>
		</div>
	</div>

<p-dialog header="Add Image"
          [(visible)]="displayAddImage"
          *ngIf="displayAddImage"
           [modal]="true" 
           appendTo="body" [responsive]="true"
          [style]="{width: '410px', height: '480px'}"
          [resizable]="false" [closable]="false">
		<app-addimage #addimage [medication]="_medication" [selectedRecord]="selectedRecord"
			 (cancelClicked)="cancelClickedFromAddImage()"
			 (submitClicked)="submitClickedFromAddImage()">
	   </app-addimage>
</p-dialog>

<p-dialog header="Add Image"
		  [showHeader]="false"
		  [(visible)]="dispLargeImage"
		  [modal]="false"
		  [style]="{position: 'absolute', right: '450px'}"
		  appendTo="body" [responsive]="true"
		  [resizable]="false" [closable]="false">
	<img src="{{largeImgUrl}}" height="250px" width="600px" alt="something"  >
</p-dialog>
