export interface Medication {
	id: number;
	itemPrefix: string | null;
	lotPrefix: string | null;
	handle: string | null;
	description: string | null;
	origNdcNumber: string | null;
	altNdcNumber: string | null;
	schedule: string | null;
	lastUnitNumber: number | null;
	dataAdded: number | null;
	activeStatus: string | null;
	daysSupply: string | null;
	genericBrand: string | null;
	packageSize: number | null;
	unitOfMeasure: string | null;
	awp: string | null;
	compareTo: string | null;
	medicationFamilyId: number;
	containerURL: string;
	tabletURL: string;
	manufacturedBy: string;
	manufacturedFor: string;
	storageInstructions: string;
	ingredients: string;
	costBase: number | null;
	containerImagePresent: boolean;
	tabletImagePresent: boolean;
	medicationFamilyName: string;
}


export class Medication {

}
