import {SupplierItems} from './supplieritems';

export interface SupplierMaterial
{
     id: number;
	 costPerPiece: number;
	 materialId: number;
	 supplierId: number;
	 supplierName: string;
	 lastUpdated: number;
	 leadTimeDays: number;
	 unitPrice: number;
}
export class SupplierMaterial {

	public setFromSupplierItems(rec: SupplierItems, costPerDose: number ): void {
		this.id = rec.id;
		this.costPerPiece = costPerDose;
		this.materialId = rec.itemId;
		this.supplierId = rec.supplierId;
		this.lastUpdated = new Date().getTime();
		this.leadTimeDays = rec.leadTimeDays;
		this.unitPrice = rec.unitPrice;
	}
}
