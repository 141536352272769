import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SupplierLicenses } from '../../../../models/supplierlicenses';
import { SuppliersService } from '../../../../services/suppliers.service';
import { MatTableDataSource } from '@angular/material/table';
import { LicenseEntries } from '../../../../models/licenseentries';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Settings } from '../../../../services/settings';
import { AddSupplierlicenseComponent } from './add-supplierlicense/add-supplierlicense.component';
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
@Component({
	selector: 'app-supplierlicenses',
	templateUrl: './supplierlicenses.component.html',
	styleUrls: ['./supplierlicenses.component.css']
})
export class SupplierlicesesComponent implements OnInit {

	public _parentId: number;
	public visibleAdd = true;
	public addEditFlag: boolean;
	public supplierService: SuppliersService;
	public records: any;
	public dataSource: MatTableDataSource<LicenseEntries>;
	public displayedColumns = ['id', 'description', 'edit', 'delete'];
	public assetPath = '../../../../../assets';
	public Idx: number | null = -1;
	public selectedRecord = new LicenseEntries();
	public displayAddLicense = false;

	@ViewChild(AddSupplierlicenseComponent)
	public addLicense: AddSupplierlicenseComponent;

	constructor(clsvc: SuppliersService, public dialog: MatDialog) {
		this.supplierService = clsvc;
	}

	ngOnInit() {

	}
	@Input() set parentId(value: number) {

		if (value === undefined || value <= 0) {
			return;
		} else {
			this._parentId = value;
			this.queryRecords();
			// this.disabledFlag = false;
		}
	}

	get parentId(): number {
		if (this._parentId === undefined)
			return -1;
		return this._parentId;
	}

	queryRecords(): void {

		this.supplierService.getAllSupplierLicenses(this.parentId).subscribe(
			(result) => {
				this.records = result;
				this.selectedRecord = new LicenseEntries();
				this.setDataSource();
			},
			(error) => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			});
	}
	setDataSource(): void {
		this.dataSource = new MatTableDataSource(this.translate(this.records));
	}

	translate(inpRecords: any): any {
		const retRecords: any = new Array<LicenseEntries>();
		if (inpRecords.length === 0) {
			return retRecords;
		}

		for (let i = 0; i < inpRecords.length; i++) {
			const src: SupplierLicenses = inpRecords[i];
			const expiration = inpRecords[i].expirationDate.split('T')[0];
			const ae: LicenseEntries = new LicenseEntries();
			ae.id = inpRecords[i].id;
			inpRecords[i]['file_url'] = Settings.getInstance().BASE_URL + 'suppliers/supplierlicenses/filerender/' + inpRecords[i].id;
			if (inpRecords[i].documentPath === null || inpRecords[i].documentPath === 'null') {
				inpRecords[i].displayPath = '';
			} else {
				inpRecords[i].displayPath = inpRecords[i].documentPath;
			}

			ae.description = '<table width="100%"><tr><td width="60%"><b>' + inpRecords[i].licenseType + '</b></td>';
			ae.description += '<td>State: <b>' + inpRecords[i].practiceState + '</b></td></tr>';

			ae.description += '<tr><td width=150px>ID: ' + '<b>' + inpRecords[i].licenseNumber + '</b></td>';
			ae.description += '<td width="150px"> Expires: ' + '<b>' + expiration + '</b></td></tr>';
			ae.description += '<tr><td style="height: 30px; vertical-align: middle;">' + this.returnImageSrc(expiration) + '</b></td>';
			if (inpRecords[i].documentPath.length > 0) {
				ae.description += '<td style=\"width: 50%;\">' + '<a href=\"' + inpRecords[i]['file_url'] +'  \" target="_blank">';
				ae.description += '<img src="assets/pdf-icon.png" width="30px" height="30px">&nbsp;' + inpRecords[i].documentPath + '</a></td></tr></table>';
			}
			ae.edit = '';
			ae.delete = '';

			retRecords.push(ae);
		}
		return retRecords;
	}
	returnImageSrc(dateVal: string): string {
		const expDate = new Date(dateVal);
		const today = new Date();
		let fn = 'na.png';

		if (expDate < today) {
			// its expired!
			fn = 'expired.png';
		}
		else {
			// its good!
			fn = 'active.png';
		}

		const retVal = '<img src="' + this.assetPath + '/' + fn + '">';
		return retVal;
	}
	onRowClicked(idx: number): void {
		this.Idx = idx;                     // sets the row.
		this.selectedRecord = this.dataSource.data[idx];
	}
	tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.data.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.data[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.data[--this.Idx];
			}
		}
	}
	selectRow() {

		if (this.selectedRecord.id === 0) {
			return;
		}

		for (let i = 0; i < this.dataSource.data.length; i++) {
			if (this.selectedRecord.id === this.dataSource.data[i].id) {
				// set this record's index in the table.
				this.Idx = i;
				return;
			}
		}
	}
	addNewRecord(): void {
		this.displayAddLicense = true;
		this.addEditFlag = true;
		this.addLicense.afterOpenDialog(new SupplierLicenses(), this.addEditFlag);

	}
	editRecord(idx: number): void {
		this.onRowClicked(idx);
		this.displayAddLicense = true;
		this.addEditFlag = false;
		this.addLicense.afterOpenDialog(this.records[this.Idx], this.addEditFlag);
	}
	deleteRecord(idx: number): void {

		this.Idx = idx;
		const localRecord = this.records[idx];
		const message = 'Remove Address for: "' + localRecord.licenseType + '"<br /><b>Are You Sure?</b>';
		const dialogData = new ConfirmDialogModel('Please Confirm', message, "");
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '400px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {

				this.supplierService.deleteSupplierLicense(localRecord.id).subscribe(
					(data) => {
						const localIdx = this.records.findIndex(r => r.id === localRecord.id);
						this.records.splice(localIdx, 1);
						this.setDataSource();
						this.selectedRecord = new LicenseEntries();
						this.Idx = -1;              // unset selected records.
					},
					(error) => {
						Settings.getInstance().handleError('Error: unable to remove this record.')
					});
			}
		});
	}
	newLicenseCreated(event: any): void {

		if (this.addEditFlag === true) {
			// push the new record onto the top of the stack.
			this.records.unshift(event as SupplierLicenses);
		}
		else {
			this.records[this.Idx] = event as SupplierLicenses;
		}
		this.setDataSource();
		this.Idx = 0;       // sets the currently selected row
		this.displayAddLicense = false;
	}
	newLicenseCanceled(event: any): void {
		this.displayAddLicense = false;
	}

}


