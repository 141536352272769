/************
 * Model Class
 * File Name ...: contactentries.components.ts
 * Generated By : ianday on 2/24/23
 * Date ........: 2/24/23
 /************/

export interface ContactEntries {
    id: number | null;
    contact: string | null;
    edit: string | null;
    delete: string | null;
}

export class ContactEntries {
    public fieldToFocus: string | null = '';

    constructor() {
        this.id = 0;
        this.contact = '';
        this.edit = '';
        this.delete = '';
    }

}
