<!--
    file type .....: HTML file: finishbatch.component.html
    Created By ....: ianday
    Date ..........: 8/4/23 
-->
<div class="dialog-container">
    <div class="dialog-batch-form-content">
        <!-- COLUMN 1 -->
        <div class="dwform">

            <!-- ORDER NUMBER -->
            <label class="label-field" style="vertical-align: top;">Required:</label>
            <span class="form-required"> </span>
            <textarea class="text-area-override-description"
                      [readonly]="true">{{lblDescription()}}</textarea>

            <!-- Temperature -->
            <div class="dwform-row-gap" > </div>
            <label class="label-field">Temperature:</label>
            <span class="form-required">*</span>
            <input class="input-field-short" id="packageTemp"
                   [formControl]="packageTempControl"
                   ngDefaultControl
                   min="0" max="100"
                   type="number">
            <span>Degrees Celsius</span>

            <!-- Humidity -->
            <div class="dwform-row-gap" > </div>
            <label class="label-field">Humidity:</label>
            <span class="form-required">*</span>
            <input class="input-field-short" id="packageHumidity"
                   [formControl]="packageHumidityControl"
                   ngDefaultControl
                   min="0" max="100"
                   type="number">
            <span>Percent</span>
            <!-- Station Drop down -->
            <div class="dwform-row-gap"></div>

            <label class="label-field">Station:</label>
            <span class="form-required">*</span>
            <mat-select id="stationId"
                        disableOptionCentering
                        [(ngModel)]="selectedStation" #cbStation floatLabel="never"
                        class="dw-mat-select"
                        style="font-size: 10pt;width: 200px;"
                        panelClass="local-drop-down-panel"
                        (selectionChange)="onChangeStations($event)">
                <mat-option *ngFor="let station of stationsList"
                            [value]="station">{{station.handle}}</mat-option>
            </mat-select>

            <!--COLUMN 2 -->
            <div class="dwform-cell" style="width:400px;">

                <!-- Customer -->
                <div class="dwform-row-gap"> </div>
                <label class="label-field">Customer:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{selectedForm.customerName}}
                </label>

                <!-- Order Number -->
                <div class="dwform-row-gap" style="height: 22px;"> </div>
                <label class="label-field">Order Number:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{ordNumber}}
                </label>

                <!-- Date Created  -->
                <div class="dwform-row-gap" style="height: 22px;"> </div>
                <label class="label-field">Submit Date:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{lbFmt(selectedForm.bpr.createdDate)}}
                </label>

                <!-- Days Remaining -->
                <div class="dwform-row-gap" style="height: 22px;"> </div>
                <label class="label-field">Days Remaining:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{showDaysRemaining()}} Day(s)
                </label>
            </div>
        </div>
    </div>

<mat-divider></mat-divider>
<div class="dwform-row-gap"></div>
<div (keydown)="tableKeyDown($event)" tabindex="-1"
     class="dialog-batch-table-content mat-elevation-z2">

    <table mat-table [dataSource]="dataSource"
           class="table-bulk-items-class"  style="border:none;">

        <!-- Inventory Control Number -->
        <ng-container matColumnDef="icn">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:40px">ICN
            </th>
            <td mat-cell [hidden]="false"
                style="font-size: 9pt; width:40px; vertical-align: middle; text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.icn}}</td>
        </ng-container>

        <!-- NDC Number -->
        <ng-container matColumnDef="ndcNumber">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">NDC Number
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.ndcNumber}}</td>
        </ng-container>

        <!-- Expiration Date -->
        <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Expiration Date
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left; "
                *matCellDef="let element;let tableRowIdx = index;">
                {{lbFmtDate(element.expirationDate)}}</td>
        </ng-container>

        <!-- Required -->
        <ng-container matColumnDef="ordered">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Ordered
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.ordered}}</td>
        </ng-container>

        <!-- Available -->
        <ng-container matColumnDef="starting">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Available
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.starting}}</td>
        </ng-container>
        <!-- Used-->
        <ng-container matColumnDef="used">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Used
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.used}}</td>
        </ng-container>
        <!-- Discarded-->
        <ng-container matColumnDef="discarded">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Discarded
            </th>
            <td mat-cell style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">

                <input id="inputDiscardedId"
                       class="input-field-shortest"
                       style="width: 35px; border: .5px solid silver;"
                    *ngIf="selectedBPRItem?.icn === element.icn"
                    [ngModel]="selectedBPRItem.discarded"
                       (click)="doSetFocus($event)"
                    (change)="doInputChange($event)"
                       type="text">

            <span *ngIf="selectedBPRItem?.icn !== element.icn">
                {{element.discarded}}
            </span>

            </td>
        </ng-container>

        <!-- Remaining -->
        <ng-container matColumnDef="returning">
            <th mat-header-cell *matHeaderCellDef
                class="mat-table-header-style" style="width:60px">Returning
            </th>
            <td mat-cell
                [hidden]="false"
                style="font-size: 9pt; vertical-align: middle;
                                text-align: left;"
                *matCellDef="let element;let tableRowIdx = index;">
                {{element.returning}}</td>
        </ng-container>

        <!-- DELETE -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="mat-table-header-style" style="width: 20px;"> </th>
            <td mat-cell *matCellDef="let element;let tableRowIdx = index;" class="button-cells-edit-delete">
              <!--  <span> <img class="edit-delete-button" matTooltip="Click to remove" alt=""
                            src="../../../../assets/delete-icon.png"
                            (click)="deleteFromBulkUnitItems(tableRowIdx)">
                </span></td>
               -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row style="border: .5px solid green"
            *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
            [ngClass]="{'row-selected': tableRowIdx == Idx}"
            (click)="onRowClicked(row)">
        </tr>
    </table>
</div>
    <div class="prepare-batch-summary-content">
        <!-- COLUMN 1 -->
        <div class="dwform">

            <div class="dwform-row-gap" > </div>
            <!-- Station Cleaning Completed -->
            <div id="dvarianceId">

                <div class="dwform-row-gap"> </div>
                <label class="label-field">Doses Discarded:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{totalDiscarded}}
                </label>

                <div class="dwform-row-gap"> </div>
                <label class="label-field">Variance:</label>
                <span class="form-required"> </span>
                <label class="label-field-data" style="font-weight: bold">
                    {{calculateVariance()}}  %
                </label>

                <!-- Variance Reason -->
                <div *ngIf="showExplanation === true">
                    <div class="dwform-row-gap"> </div>
                <label class="label-field" style="vertical-align: top;">Variance Reason:</label>
                <span class="form-required">*</span>

                <textarea class="input-field text-area-override" id="varianceReasonId"
                          [formControl]="varianceReasonControl"
                          [disabled]="false"
                          [(ngModel)]="selectedForm.bpr.explanation" type="text">
                </textarea>
                </div>
            </div>
            <div class="dwform-row-double-gap"> </div>

            <!--COLUMN 2 -->
            <div class="dwform-cell" style="width:300px;">

            <!-- Doses Required -->
            <label class="label-field">Doses Ordered:</label>
            <span class="form-required"> </span>
            <label class="label-field-data" style="font-weight: bold">
                {{selectedForm.bpr.dosesRequired}}
            </label>

            <!-- Doses Selected -->
            <div class="dwform-row-gap" style="height: 22px;"> </div>
            <label class="label-field">Doses Used:</label>
            <span class="form-required"> </span>
            <label class="label-field-data" style="font-weight: bold">
                {{selectedForm.bpr.dosesSelected}}
            </label>

            <!-- Doses Balance  -->
            <div class="dwform-row-gap" style="height: 22px;"> </div>
            <label class="label-field">Doses RTS:</label>
            <span class="form-required"> </span>
            <label class="label-field-data" style="font-weight: bold">
                <span *ngIf="dosesBalance <= 0" style="color: green;">
                    {{dosesBalance * -1}} (over)
                </span>
                <span *ngIf="dosesBalance > 0" style="color: red;">
                    {{dosesBalance}} (Req'd)
                </span>
            </label>
        </div>
    </div>
</div>

<!----------------------->
<!-- ERROR LINE --------->
<!----------------------->
<div class="error-line"  [hidden]='!strErr.length'
     style="text-align:left; bottom: 48px; left: 15px;">{{strErr}}</div>
</div>

<!----------------------->
<!-- SAVE STRIP        -->
<!----------------------->
<div style="text-align:center; margin-top: 55px;">
    <button mat-button class="command-button"
            (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
            mat-button>
        <mat-icon>check_circle_outline</mat-icon>
        Submit
    </button>
    <button mat-button class="command-button"
            (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
        <mat-icon>highlight_off</mat-icon>
        Cancel
    </button>
</div>

