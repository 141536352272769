<!------------------------------------->
<!-- List tab detail layout.
 * File Name ..: inventories-inv.component.html
 * Generated by: ianday
 * Date .......: 4/3/23
--------------------------------------->
<div class="inventories-inv-form-container">
    <div class="query-strip-container mat-elevation-z2">
        <table height="100%" >
            <TR>
                <TD class="qs-label-cell" style="width: 60px; text-align: center;">
                    Site:</TD>
                <TD class="table-title-cell">
                    <mat-select
                            disableOptionCentering
                            [(ngModel)]="selectedSite" #cbSite floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 9pt;width: 170px;"
                            panelClass="local-drop-down-panel"
                            (keydown)="onSiteDropDownKeyDown($event)"
                            (selectionChange)="strErr = '';txtFilter.value=''; onChangeSitesValue($event)">
                        <mat-option *ngFor="let site of sites" [value]="site">{{site.handle}}</mat-option>
                    </mat-select>

                </TD>
                <TD class="qs-label-cell" style="width: 60px; text-align: center;">
                    Catalogue:</TD>
                <TD style="width: 120px;">
                    <mat-select
                            disableOptionCentering id="cbCatalogue"
                            [(ngModel)]="selectedCatalogue" #cbCatalogue floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 9pt;width: 170px;"
                            panelClass="local-drop-down-panel"
                            (keydown)="onCatalogueDropDownKeyDown($event)"
                            (selectionChange)="strErr = '';txtFilter.value=''; onChangeCatalogueValue($event)">
                        <mat-option *ngFor="let catalogue of catalogues"
                                    [value]="catalogue">{{catalogue.handle}}</mat-option>
                    </mat-select>
                </TD>

                <TD align="center" style="min-width: 340px;">
                    <input text id="txtFilter" #txtFilter
                           class="qs_filter-field" [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="strErr = '';txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </TD>
                <TD class="qs-label-cell" style="min-width: 70px; text-align: center;">Type:</TD>
                <TD style="width: 150px;">
                    <mat-select
                            disableOptionCentering
                            [(ngModel)]="selectedInvType" #cbStatus floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 9pt;width: 120px;"
                            panelClass="local-drop-down-panel"
                            (keydown)="onInvTypesDropDownKeyDown($event)"
                            (selectionChange)="txtFilter.value=''; onChangeInvTypeValue($event)">
                        <mat-option *ngFor="let type of invTypes"
                                    [value]="type">{{type.description}}</mat-option>
                    </mat-select>
                </TD>
                <TD class="qs-pagination-cell" align="right" >{{pagination}} Record(s) ...</TD>
            </TR>
        </table>
    </div>

    <as-split direction="vertical">
        <as-split-area [size]="50" style="min-height: 40px" >
            <!--------------------------->
            <!-- RECORDS TABLE         -->
            <!--------------------------->
             <div [ngClass]="contentClassName" class="table-container"
             (keydown)="tableKeyDown($event)" tabindex="-1">
                <table mat-table [dataSource]="dataSource"
                       matSort style="font-size: 9pt;"
                       (matSortChange)="onSortChange($event)">

                    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on
                  the row definition" -->

                    <ng-container matColumnDef="siteHandle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Site
                        </th>
                        <td mat-cell style="width:15% !important;font-size: 9pt;"
                            *matCellDef="let element">{{element.siteHandle}}</td>
                    </ng-container>

                    <ng-container matColumnDef="catalogueHandle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Catalogue
                        </th>
                        <td mat-cell style="width:15% !important;font-size: 9pt;"
                            *matCellDef="let element">{{element.catalogueHandle}}</td>
                    </ng-container>

                    <ng-container matColumnDef="itemPrefix">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Item Number
                        </th>
                        <td mat-cell style="width:10% !important;font-size: 9pt;"
                            *matCellDef="let element">{{element.itemPrefix}}</td>
                    </ng-container>

                    <ng-container matColumnDef="itemHandle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Item Description
                        </th>
                        <td mat-cell style="width:30% !important;font-size: 9pt;"
                            *matCellDef="let element">{{element.itemHandle}}</td>
                    </ng-container>

                    <ng-container matColumnDef="itemType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Item Type
                        </th>
                        <td mat-cell style="width:10% !important;font-size: 9pt;"
                            *matCellDef="let element">{{element.itemType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="onHand" class="inventories-data-columns">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">On Hand</th>
                        <td mat-cell style="font-size: 9pt;"
                            *matCellDef="let element">{{decimalFmtValue(element.onHand)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="onOrder" class="inventories-data-columns">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">On Order</th>
                        <td mat-cell style="font-size: 9pt;"
                            *matCellDef="let element">{{decimalFmtValue(element.onOrder)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="reserved" class="inventories-data-columns">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="mat-table-header-style">Reserved</th>
                        <td mat-cell style="font-size: 9pt;"
                            *matCellDef="let element">{{decimalFmtValue(element.reserved)}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                        [ngClass]="{'row-selected': tableRowIdx == Idx}"
                        (click)="onRowClicked(row)">
                    </tr>
                </table>
             </div>
         </as-split-area>

         <as-split-area [size]="50" >
             <div class="inventories-inv-detail-container"
                  style="overflow:hidden!important;
                  padding-bottom: 40px;">
                <!-------------------------------------->
                <!-- Control Strip                    -->
                <!-------------------------------------->
                <div class="mat-elevation-z2 control-strip" >
                    <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
                            matTooltipPosition="above" style="margin-top: 5px;"
                            class="command-button" matTooltip="Check in bulk items"
                            (click)="importBulkMedication(true)" mat-button>
                        <mat-icon>import_export</mat-icon>
                        Check In
                    </button>
                    <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
                            matTooltipPosition="above" style="margin-top: 5px;"
                            class="command-button" matTooltip="Create Suggested Order"
                            (click)="managePurchaseOrders()"
                            mat-button>
                        <mat-icon>shopping_cart</mat-icon>
                        POs
                    </button>
                </div>

                <!------------------------------->
                <!--  Detail panel             -->
                <!------------------------------->
                 <mat-tab-group animationDuration="0ms"
                                style="position:relative; height:100%;
                                position: relative;"
                                (selectedTabChange)="doTabChange($event)"
                                [(selectedIndex)]=selectedTabIndex>
                        <mat-tab label="Details" [disabled]="isEditing"
                            class="sub-tab-style">
                             <app-bulkunits
                                 [sites]="sites"
                                 [catalogues]="catalogues"
                                 #bulkunits>
                             </app-bulkunits>

                        </mat-tab>
                        <mat-tab label="History" [disabled]="isEditing"
                            class="sub-tab-style">
                             <transaction-history #history >

                             </transaction-history>

                        </mat-tab>
                    </mat-tab-group>
                </div>
        </as-split-area>
    </as-split>
</div>

<p-dialog header="Check In New Items"
          [appendTo]="'body'"
          [(visible)]="displayImportDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '1450px', height: '620px'}"
          [minY]="75" [resizable]="false" [closable]="false">
    <app-bulkcheckin #bulkcheckin
        [selectedCatalogue]="selectedCatalogue"
        (cancelBulkCheckInEvent)="displayImportDialog=false"
        (submitBulkCheckInEvent)="submitBulkCheckInEvent()"></app-bulkcheckin>
</p-dialog>

<p-dialog header="Purchase Orders for: {{selectedSite.handle}}"
          [appendTo]="'body'"
          [(visible)]="displayPurchaseOrdersDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '1150px', height: '710px'}"
          [minY]="75" [resizable]="false" [closable]="false">
    <app-purchaseorders #purchaseorders
          [selectedSite]="selectedSite"
          (closePurchaseOrderEvent)="displayPurchaseOrdersDialog= false">
    </app-purchaseorders>
</p-dialog>
