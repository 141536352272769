import {Component, OnInit, ViewChild} from '@angular/core';
import { Settings } from '../../services/settings';
import { TransactionHistoryService } from '../../services/transactionhistory.service';
import { TransactionHistory } from '../../models/transactionhistory';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { User } from '../../models/user';

@Component( {
    selector: 'transaction-history',
    templateUrl: './transaction-history.html',
    styleUrls: ['./transaction-history.css'],
} )
export class TransactionHistoryComponent implements OnInit {

    public records: TransactionHistory[];
    public loading = false;
    public Idx = -1;
    public selectedRecord: TransactionHistory;
    public isEditing = false;
    public dataSource: MatTableDataSource<TransactionHistory>;
    public fullDisplayColumns = ['transactionDate', 'userUid', 'txAction', 'columnName', 'oldValue', 'newValue'];
    public actionDisplayColumns = ['transactionDate', 'userUid', 'txAction'];
    public displayedColumns: any;
    public pagination: string;
    public tableName: string;
    public tableKey: string;
    public keyVal: string;
    public displayTxDetail = false;

    public intervalList = [];
    public intervalIdx: number| null = -1;
    public selectedInterval: any;


    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private txService: TransactionHistoryService) {
        this.setFullDisplayColumns();
        this.intervalList = new Array();
        this.intervalList.push({id: '10', description: '10 days'});
        this.intervalList.push({id: '30', description: '30 days'});
        this.intervalList.push({id: '90', description: '90 days'});
        this.intervalList.push({id: '0', description: 'All time'});
        this.selectedInterval = this.intervalList[0];
        this.intervalIdx = 0;
    }
    setFullDisplayColumns(): void {
        this.displayedColumns = this.fullDisplayColumns;
    }
    setActionDisplayColumns(): void {
        this.displayedColumns = this.actionDisplayColumns;
    }
    ngOnInit(): void {
        this.selectedRecord = new TransactionHistory();
    }
    doTimeFrameSelectionChanged(event: any): void {
        this.queryRecords();
    }
    onShowHandler(tableName: string, tableKey: string, keyVal: string) {

        this.loading = true;
        this.tableName = tableName;
        this.tableKey = tableKey;
        this.keyVal = keyVal;
        this.queryRecords();
    }
    queryRecords(): void {

        if ((this.tableName === undefined) || (this.tableKey === undefined) || (this.keyVal === undefined)) { return; }

        this.txService.getAllTransactionHistoryByForeignKey(this.tableName,
            this.tableKey, this.keyVal, this.selectedInterval.id)
        .subscribe(
            data => {
                if (data.length === 0) {
                    this.records = new Array<TransactionHistory>();
                }

                this.records = data;
                this.dataSource = new MatTableDataSource(this.records);
                this.dataSource.sort = this.sort;
                this.loading = false;
                this.setPagination(-1, this.records.length);
            },
            (error) => {
                Settings.getInstance().handleError(error);
                this.loading = false;
            });
    }
    applyFilter(filterValue: string) {
        if (this.isEditing) return;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(-1, this.dataSource.filteredData.length);
    }
    clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = '';

        this.unSelectRow();
        this.setPagination(-1, this.dataSource.filteredData.length);

    }

    public doIntervalDropDownKeyDown(event: any): void {
        const len: number = this.intervalList.length;
        if (event.key === 'ArrowDown') {
            if (this.intervalIdx < (len - 1)) {
                this.selectedInterval = this.intervalList[++this.intervalIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.intervalIdx > 0) {
                this.selectedInterval = this.intervalList[--this.intervalIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedInterval = this.intervalList[this.intervalIdx];
            this.doValueChange(this.selectedInterval);
        }
    }

    doValueChange(event: any) {
        if(event.value !== undefined) {
            this.selectedInterval = event.value;
        }

        this.queryRecords();
    }

    setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    unSelectRow(): void {
        this.Idx = -1;
        this.selectedRecord = new TransactionHistory();

    }
    onRowClicked(selected: any): void {
        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    onRowDoubleClicked(selected: any): void {
        this.onRowClicked(selected);
        this.displayTxDetail = true;
    }
    closeTxDetail(event:any): void {
        this.displayTxDetail = false;
    }
    selectRow(): void {
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
    }
    onSortChange(event): void {

    }
    evaluateLength(inpValue): string {
		if(inpValue === undefined || inpValue === null){
			return;
		}
        let retVal = inpValue;

        if (inpValue.length > 100) {
            retVal = '[Value too large to show]';
        }

        return retVal;

    }
    formatDateTime(value): string {
        const dateSplit = value.split(' ');
        const timePortion = dateSplit[1].substring(0, 5);
        const finalVal = dateSplit[0] + ': ' + timePortion;
        return finalVal;
    }
    formatUserFN(value): string {

        const user: User = Settings.getInstance().usersList.find(u => u.username === value);
        const fullName = user.name + ' (' + value + ')';
        return fullName;
    }
    tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    doFetchNextRecord(): void {
        const len: number = this.dataSource.filteredData.length;
        if (this.Idx < (len - 1)) {
            this.selectedRecord = this.dataSource.filteredData[++this.Idx];
            this.onRowClicked(this.selectedRecord);
            //this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces, this.selectedRecord.id);
        }
        else {
            return;
        }
        // this.bulkUnitDtl.doSetAndShowHistory(this.dosesPieces);
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    doFetchPreviousRecord(): void {
        if (this.Idx > 0) {
            this.selectedRecord = this.dataSource.filteredData[--this.Idx];
            this.onRowClicked(this.selectedRecord);
        }
        else {
            return;
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
}
