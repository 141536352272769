import {
	Component,
	HostListener,
	Input,
	OnInit,
	SecurityContext,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { AuthService } from '../../services/authservice/auth.service';
import { DatePipe } from '@angular/common';
import { NotesService } from '../../services/notes.service';
import { Notes } from '../../models/notes';
import { Settings } from '../../services/settings';
import { UserService } from '../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '../../models/user';
import { NotesHelper } from './notes.helper';
import { DwUtils } from '../utils/dw-utils';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.css'],
	providers: [DatePipe],
	encapsulation: ViewEncapsulation.None,
})

export class NotesComponent implements OnInit {

	private _parentId: number;
	private _callingComponent: any;
	private _heightAdjust = '350';
	private _width = '350';
	private submitDate: string;
	public fullName;
	public taNoteText: string;
	public taNoteHistoryTxt: string;
	public disabledFlag = true;
	noteList: any;
	avatarImageURL = '' + Settings.getInstance().BASE_URL + 'users/profile/image/';
	loggedInUser: User;
	_dialog_mode = false;
	containerHeight = '';
	private TEXT_AREA_ELEMENT_ID = 'ta-note-text';
	isEmojiPickerVisible = false;
	users: Array<User>;
	helper: NotesHelper;
	htmlDoc: any;
	rowIndex = -1;
	editFlag = false;
	editedNoteIdx = -1;
	viewClass = 'rendered-notes-container';
	_view = '';

	public mentionConfig: any;

	@ViewChild('taNote') taNote: any;

	@Input() set parentId(value: number) {
		this._parentId = value;

		if (value === undefined || value <= 0) {
			this.disabledFlag = true;
			this.taNoteText = '';
			this.taNoteHistoryTxt = '';
			return;
		} else {
			this.fetchNotes();
			this.disabledFlag = false;
		}
	}

	get parentId(): number {
		return this._parentId;
	}

	@Input() set callingComponent(value: string) {
		this._callingComponent = value;
	}
	get callingComponent(): string {
		return this._callingComponent;
	}

	@Input() set heightAdjust(value: any) {
		this._heightAdjust = value;
		if (this._dialog_mode && value === 700) {
			this.containerHeight = '230px';
		} else {
			this.containerHeight = 'calc(100vh - ' + this.heightAdjust + 'px)';
		}
	}
	get heightAdjust(): string {
		return this._heightAdjust;
	}

	@Input() set width(value: string) {
		this._width = value;
	}
	get width(): string {
		return this._width;
	}

	@Input() set View(value: any) {
		this._view = value;
		if (this._view === 'Window') {
			this.viewClass = 'rendered-notes-container-window';
		} else {
			this.viewClass = 'rendered-notes-container';
		}
	}

	constructor(private datePipe: DatePipe,
		private authService: AuthService,
		private notesService: NotesService,
		private userService: UserService,
		private sanitizer: DomSanitizer) {

		this.fullName = this.authService.loggedInUser;   // uncomment me
		this.helper = new NotesHelper();

	}

	selectEnd() {
		let range;
		let selection;

		range = document.createRange();

		this.htmlDoc = document.getElementById(this.TEXT_AREA_ELEMENT_ID);
		range.selectNodeContents(this.htmlDoc);
		range.collapse(false);
		selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
	}
	ngOnInit() {
		this.taNoteText = '';
		this.taNoteHistoryTxt = '';
		this.loggedInUser = Settings.getInstance().loggedInUser;

	}
	setInitialFocus() {
		this.helper.setFocus(this.TEXT_AREA_ELEMENT_ID);

	}

	messageMouseOver(event: any, idx: any): void {

		if (event.target.tagName === 'SPAN') {
			this.rowIndex = idx;

			// we have either a mention or a hashtag.
			const textKey = event.target.textContent;
			// check to see if this is an at mention.
		}
	}

	messageMouseOut(event: any): void {

		if (event.target.tagName === 'SPAN') {
			this.rowIndex = -1;
		}
	}
	fetchNotes() {

		if (this.parentId === 0) {
			return;
		}

		this.notesService.getNotesByForeignKeyId(this.parentId, this.callingComponent).subscribe(
			(data) => {
				this.noteList = data;
				this.noteList = this.noteList.reverse();
				this.taNoteHistoryTxt = '';
				for (let i = this.noteList.length - 1; i >= 0; i--) {

					// format the date time from ISOString ....
					const tempDate: string = DwUtils.formatDTFromString(this.noteList[i].dateAdded);
					this.noteList[i].dateAdded = tempDate;

					const noteRec: Notes = this.noteList[i];
					if (noteRec.commentText === undefined) {
						noteRec.commentText = '';
					}
					this.noteList[i].commentText = noteRec.commentText.replace(/(?:\r\n|\r|\n)/g, '<br>');
				}
			},
			(error) => {
				// To-Do
			}
		);
	}
	getSanitizedURL(userUid: string): string {

		let retURLVal = '';
		const id = Settings.getInstance().usersList.find(u => u.username === userUid).id;
		retURLVal = this.avatarImageURL + id;
		return retURLVal;
	}

	submitNewNote() {

		if (this.validateNewTextNote() === false)
			return;

		if (document.getElementById(this.TEXT_AREA_ELEMENT_ID).textContent.length > 0) {
			this.addNote();
		}
		//  this.taNoteText = '';
		document.getElementById(this.TEXT_AREA_ELEMENT_ID).focus();
	}
	addNote() {

		if (this.editFlag === false) {
			const newNote = new Notes();
			newNote.id = 0;
			newNote.foreignKeyTable = this.callingComponent;
			newNote.dateAdded = DwUtils.returnCurrentDateTime();
			newNote.foreignKeyId = this.parentId;
			newNote.commentText = this.prepComment(document.getElementById(this.TEXT_AREA_ELEMENT_ID).innerHTML);
			newNote.userUid = Settings.getInstance().loggedInUser.username;
			newNote.userFullName = Settings.getInstance().loggedInUser.name;

			this.notesService.createNote(newNote).subscribe(
				(data) => {
					this.noteList.unshift(newNote);
				},
				(error) => {
					// To-Do
				}
			);

		} else {
			this.noteList[this.editedNoteIdx].commentText =
				this.prepComment(document.getElementById(this.TEXT_AREA_ELEMENT_ID).innerHTML);

			this.notesService.updateNote(this.noteList[this.editedNoteIdx]).subscribe(
				(data) => {
					//nothing
					(error) => {
						// nothing
					}
				}
			);
			this.editFlag = false;
			this.editedNoteIdx = -1;

		}
		// clear the text area.
		document.getElementById(this.TEXT_AREA_ELEMENT_ID).textContent = '';
	}

	prepComment(inpStr: string): string {

		let htmlText = '';

		if (this.editFlag === true) {
			htmlText = inpStr + this.helper.editBanner;
		}
		else {
			htmlText = inpStr;
		}

		const safeHtml = this.sanitizer.sanitize(SecurityContext.HTML, htmlText);
		return safeHtml;
	}
	validateNewTextNote(): boolean {

		this.taNoteText = document.getElementById(this.TEXT_AREA_ELEMENT_ID).textContent;
		return true;
	}
	editDisplayedMessage(idx: number): void {

		if (idx < 0) {
			return;
		}
		//   this.taNoteText = '';
		// Sanitize to remove security context messages
		let htmlTextContent = this.sanitizer.sanitize(SecurityContext.HTML, this.noteList[idx].commentText);
		// strip off any previous edit banner: '(Edited)'
		htmlTextContent = htmlTextContent.replace(this.helper.editBanner, '');

		// put the selected message back in the edit box
		document.getElementById(this.TEXT_AREA_ELEMENT_ID).innerHTML = htmlTextContent.toString();

		// set the edit flag to true ...
		// and capture the index ..
		this.editFlag = true;
		this.editedNoteIdx = idx;

		// set focus back to the text field and move the
		// i-beam position to the end of the text.
		this.helper.setFocus(this.TEXT_AREA_ELEMENT_ID);
		this.selectEnd();

	}
	deleteDisplayedMessage(idx: number): void {

		this.notesService.deleteNote(this.noteList[idx].id).subscribe(
			(data) => {
				this.noteList.splice(idx, 1);
			},
			(error) => {
				console.log('cannot delete note at position: ' + idx);
			}
		);


		// send a delete to the server.
	}
	cancelEditClicked(event: any): void {
		this.editFlag = false;
		this.editedNoteIdx = -1;
		document.getElementById(this.TEXT_AREA_ELEMENT_ID).textContent = '';
		this.helper.setFocus(this.TEXT_AREA_ELEMENT_ID);
	}
	validateEditNote(username: string): boolean {
		// Only show the menu if the author of the note is the
		// person logged in

		if (username === this.loggedInUser.username)
			return true;

		return false;
	}
}
