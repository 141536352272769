<div id="{{this.divId}}" >
	<p-chart  *ngIf="CurrentChart.type === 'pie' || CurrentChart.type === 'bar'"
			  class="half-size-client-area"
			  #chart [width]='220'  [height]='250' [type]="CurrentChart.type"
			 [data]="data"
			 [options]="_options"
	></p-chart>
	<app-gantt-chart-control *ngIf="CurrentChart.type=='gantt-control'" [rows]='data'
							 #ganttChartControl></app-gantt-chart-control>
	<!--<app-gantt-chart *ngIf="CurrentChart.type == 'gantt'" #ganttChart [fixedColumns]="cols"
					  [startDateField]="'startDate'" [endDateField]="'endDate'" [value]="data"
					 [ganttStyles]="chartStyles" [options]="_options"></app-gantt-chart> -->
	<app-milestones-calendar *ngIf="CurrentChart.type == 'milestone-calendar'" #milestoneCalendar [selectedPortfolio]="_selectedPortfolio" [selectedFiscalYear]="_selectedFiscalYear"></app-milestones-calendar>
</div>