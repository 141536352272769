/************
 * Service Class
 * File Name ...: transactionhistory.service.ts
 * Generated By : ianday on 2/18/23
 * Date ........: 2/18/23
 /************/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {TransactionHistory} from '../models/transactionhistory';
import {Settings} from './settings';
import {isNumber} from 'chart.js/helpers';

@Injectable({
    providedIn: 'root'
})
export class TransactionHistoryService {

    public URLPath: string = Settings.getInstance().BASE_URL + 'general/transactionHistory/';

    constructor(private http: HttpClient) {

    }

    createTransactionHistory(tableVal: string, keyId: number,
                             txType: string = 'Create',
                             txAction: string = 'Adding New Record',
                             newVal: string = ''): void{
        const txHist: TransactionHistory = new TransactionHistory();
        txHist.setRecord(txType, tableVal, keyId, txAction, newVal);
        this.postTxHistoryRecord(txHist).subscribe(
        		(data) => {
                    // nothing
                },
            	(error) => {
                    alert(error);
                }
    		);
    }

    updateTransactionHistory(stHist: object, stNew: object, tableVal: string, keyId: number): void {

        /************
         * evaluate the structures together, we will assume that they are the same.
         * the st1 structure was the original, st2 structure is the new structure.
         ***********/

        for (const prop in stHist) {
            if (!stHist.hasOwnProperty(prop))
                continue;

            try {
                if (stHist[prop] !== stNew[prop]) {

                    /*** solve for  "51.50" === 51.5  ***/
                    if (isNumber(stHist[prop]) === true) {
                        if (Number(stHist[prop]) === stNew[prop]) {
                            continue;
                        }
                    }

                    // something has changed
                    const txHist: TransactionHistory = new TransactionHistory();
                    txHist.setRecord('Edit', tableVal, keyId, '');
                    txHist.valueChange(stHist[prop], stNew[prop], prop);
                    this.postTxHistoryRecord(txHist).subscribe(
                        (data) => {
                            // nothing
                        },
                    (error) => {
                            alert(error);
                        }
                    );
                }
            }
            catch {}
        }
    }

    getAllTransactionHistoryByForeignKey(fkTable: string, fKey: string, entityVal: string, timeFrame: string): Observable<any> {
        return this.http.get<any>(this.URLPath + fkTable + '/' + fKey + '/' + entityVal + '/' + timeFrame)
            .pipe(
                retry(1)
            );
    }

    postTxHistoryRecord(record: TransactionHistory): Observable<any> {
        return this.http.post(this.URLPath, record)
            .pipe(
                retry(1)
            );
    }

}
