import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
    
    @Input() data:any;
    
    @Output() onClose = new EventEmitter();
    
    constructor() { }

    ngOnInit() {
        
    }
    
    onCancelClick() {
        this.onClose.emit("CANCEL");
    }
    
    onOkClick() {
        this.onClose.emit("OK");
    }
    
    onNoClick() {
        this.onClose.emit("NO");
    }
    
    onYesClick() {
        this.onClose.emit("YES");
    }
    
    onConfirmClick() {
        this.onClose.emit("CONFIRM");
    }
	onCloseClick() {
		this.onClose.emit("CLOSE");
	}

}
