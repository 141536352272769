import { Component, OnInit } from '@angular/core';
import { MatLegacyListItem as MatListItem } from '@angular/material/legacy-list'
import { Settings } from '../../services/settings';
import { AppComponent } from '../../app.component';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

	visibleProducts = false;
	visibleOrganizations = true;
	visibleRegisters = false;
	visibleUserManagement = false;
	visibleGroupManagement = false;
	visibleGeneralSettings = false;
	visibleEmailSettings = false;
	visibleWorkflowSettings = false;
	visibleInvestmentDefs = false;
	visibleUserLink = false;
	visibleRegistersLink = false;
	visibleGroupLink = false;
	visibleGeneralLink = false;
	visibleProductsLink = false;
	visibleProjectsettingsLink = false;
	visibleProjectsettings = false;
	visibleOrganizationsLink = false;
	visiblePropertiesLink = false;
	visibleProperties = false;
	visibleEmailLink = false;
	visibleWorkflowLink = true;
	visibleInvestmentDefsLink = false;

	constructor() { }

	ngAfterViewInit() {

	}

	ngOnInit() {
		if (Settings.getInstance().hasPrivilege('MANAGE_USERS')) {
			this.visibleUserLink = true;
		}
		if (Settings.getInstance().hasPrivilege('ALL_ORGANIZATIONS') || Settings.getInstance().hasPrivilege('GET_ORGANIZATION') || Settings.getInstance().hasPrivilege('GET_ALL_ORGANIZATIONS') || Settings.getInstance().hasPrivilege('UPDATE_ORGANIZATION')) {
			this.visibleOrganizationsLink = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_ACCESS_GROUPS') || Settings.getInstance().hasPrivilege('ADMIN') || Settings.getInstance().hasPrivilege('MANAGE_APP_FUNCTIONS')) {
			this.visibleGroupLink = true;
		}
		if (Settings.getInstance().hasPrivilege('ADMIN')) {
			this.visibleGeneralLink = true;
		}
		if (Settings.getInstance().hasPrivilege('ADMIN')) {
			this.visibleEmailLink = true;
		}
		
		if (!Settings.getInstance().EnableInvestments) {
			this.visibleInvestmentDefsLink = false;
		}
		if (AppComponent.staticDataSource === true) {
			this.visibleOrganizationsLink = true;
			this.visibleProductsLink = true;
			this.visibleUserLink = true;
			this.visibleGroupLink = true;
			this.visibleGeneralLink = true;
			this.visiblePropertiesLink = true;
		}
	}
	showUserManagement() {
		this.hideAllChildComponents();
		this.visibleUserManagement = true;
	}
	showGroupManagement() {
		this.hideAllChildComponents();
		this.visibleGroupManagement = true;
	}
	showPropertySettings() {
		this.hideAllChildComponents();
		this.visibleProperties = true;
	}
	showGeneralSettings() {
		this.hideAllChildComponents();
		this.visibleGeneralSettings = true;
	}
	showEmailSettings() {
		this.hideAllChildComponents();
		this.visibleEmailSettings = true;
	}
	showWorkflowSettings() {
		this.hideAllChildComponents();
		this.visibleWorkflowSettings = true;
	}
	showProducts() {
		this.hideAllChildComponents();
		this.visibleProducts = true;
	}
	showOrganizations() {
		this.hideAllChildComponents();
		this.visibleOrganizations = true;
	}
	showRegistersSettings() {
		this.hideAllChildComponents();
		this.visibleRegisters = true;
	}
	showProjectSettings() {
		this.hideAllChildComponents();
		this.visibleProjectsettings = true;
	}
	showInvestmentDefsSettings() {
		this.hideAllChildComponents();
		this.visibleInvestmentDefs = true;
	}
	hideAllChildComponents() {
		this.visibleGroupManagement = false;
		this.visibleUserManagement = false;
		this.visibleGeneralSettings = false;
		this.visibleOrganizations = false;
		this.visibleProperties = false;
		this.visibleProducts = false;
		this.visibleEmailSettings = false;
		this.visibleWorkflowSettings = false;
		this.visibleRegisters = false;
		this.visibleProjectsettings = false;
		this.visibleInvestmentDefs = false;
	}

}

