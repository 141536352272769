import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MedicationService } from '../../../../../../services/medication.service';
import { Link } from '../../../../../..//models/link';
import { DwUtils } from '../../../../../../components/utils/dw-utils';
import { Medication } from 'src/app/models/medication';
import { Settings } from 'src/app/services/settings';

@Component({
	selector: 'app-addeditlink',
	templateUrl: './addeditlink.component.html',
	styleUrls: ['./addeditlink.component.css']
})

export class AddeditlinkComponent implements OnInit {

	public record: Link;
	public _selectedRecord: Link;
	public _medication: Medication;
	public id = 0;
	public valDescription = '';
	public valURL = '';
	strErr = '';
	stateControl = new FormControl('');
	public addEditFlag: boolean;

	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter<Link>();


	@Input() set selectedRecord(value: any) {
		this._selectedRecord = value;
		this.onShowHandler();
	}

	get selectedRecord(): any {
		return this._selectedRecord;
		this.onShowHandler();
	}

	@Input() set medication(value: any) {
		this._medication = value;
	}

	get medication(): any {
		return this._medication;
	}

	constructor(private medicationService: MedicationService) {

	}

	ngOnInit() {

	}
	onShowHandler() {
		DwUtils.setFocus('desc');
		if (this._selectedRecord !== undefined) {
			this.valDescription = this._selectedRecord.linkDescription;
			this.valURL = this._selectedRecord.linkUrl;
		}
	}
	doCancel() {
		this.cancelClicked.emit();
	}

	setRecord(): void {
		this.record = new Link();
		if (this._selectedRecord !== undefined) {
			this.record.id = this._selectedRecord.id;
		} else {
			this.record.id = 0;
		}
		this.record.linkDescription = this.valDescription;
		this.record.linkUrl = this.valURL;
		this.record.active = 1;
		this.record.medicationId = this._medication.id;
	}
	validate() {
		if(this.valDescription===undefined|| this.valDescription.length===0){
			this.strErr='Please enter Description';
			return false;
		}
		if(this.valURL===undefined|| this.valURL.length===0){
			this.strErr='Please enter URL';
			return false;
		}
		return true;
	}
	doSubmit() {
		// this is where we stuff the new address and emit it back.
		this.setRecord();

		this.strErr = '';
		if (!this.validate()) {
			return;
		}

		if (this.record.id === 0) {
			this.medicationService.createMedicationLink(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		} else {
			delete this.record['medication'];
			this.medicationService.updateMedicationLink(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			)
		}

	}
	urlFoucOutHandler(){
		//Revisit the url formating since it returns incompatible prefixes
		//this.valURL=DwUtils.checkWWWAddress(this.valURL);
	}
}


