/**
     *  Filename ....: contacts.component.ts
     *  Created by ..: ianday
     *  on Date .....: 2/22/23
     **/

    import {Component, Input, ViewChild, OnInit, Output, EventEmitter,} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Contacts } from '../../../models/contacts';
import { ContactEntries } from '../../../models/contactentries';
import { Settings } from '../../../services/settings';
import { MatSort } from '@angular/material/sort';
import { AddContactsComponent } from './add-contact/add-contact.component';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ContactsService} from '../../../services/Common/Contacts.service';
import {DwUtils} from '../../utils/dw-utils';


@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css']
})

export class ContactsComponent implements OnInit {

    public visibleAdd = true;
    public addEditFlag: boolean;
    public dataSource: MatTableDataSource<ContactEntries>;
    public displayedColumns = ['id', 'contact', 'edit', 'delete'];
    private _parentId: number;
    private _callingComponent: any;
    private _addressId: number;
    public contactsService: ContactsService;
    public Idx: number | null = -1;
    public records: any;
    public selectedRecord: any;
    public displayAddContact = false;

    @Output() deleteMessage: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatSort, { static: true }) sort: MatSort ;
    @ViewChild(AddContactsComponent)
    public addContact: AddContactsComponent;

    @Input() set parentId(value: number) {
        this._parentId = value;

        if (value === undefined || value <= 0) {
            return;

        } else {
            this.Idx = -1;

        }
    }

    get parentId(): number {
        return this._parentId;
    }

    @Input() set addressId(value: number) {

        if (value === undefined) {
            return;
        }
        else {
            this._addressId = value;
            if (value >  0) {
                this.queryRecords();
            }
            else {
                // it is zero and we should just clear the table ...
               this.initializeDataSource();

            }
            return;
        }
    }
    get addressId(): number {
        return this._addressId;
    }

    @Input() set callingComponent(value: string) {
        this._callingComponent = value;
    }
    get callingComponent(): string {
        return this._callingComponent;
    }

    constructor(asvc: ContactsService, public dialog: MatDialog) {
        this.contactsService = asvc;
    }

    ngOnInit() {

    }
    initializeDataSource(): void
    {
        this.dataSource = new MatTableDataSource<ContactEntries>();
        this.records = new Contacts();
        this.Idx = -1;
    }
    queryRecords() {

        this.contactsService.getContactsByFK(this.callingComponent, this.addressId).subscribe(
            (result) => {

                if (result === null) {
                    return;
                }
                this.records = result;
                this.setDataSource();
                this.dataSource.sort = this.sort;

                // this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    setDataSource(): void
    {
        this.dataSource = new MatTableDataSource(this.translate(this.records));
    }
    translate(inpRecords: any): any {

        const retRecords: any = new Array<ContactEntries>();

        if (inpRecords.length === 0) {
            return retRecords;
        }

        for (let i = 0; i < inpRecords.length; i++) {
            const src: Contacts = inpRecords[i];
            const ce: ContactEntries = new ContactEntries();
            ce.id = inpRecords[i].id;
            ce.contact = '<b>' + inpRecords[i].fullName + '</b><br>'
                + inpRecords[i].positionTitle + ',<br>';
            ce.contact += inpRecords[i].telephone + ' cell: ' + inpRecords[i].cellPhone + '<br>';
            ce.contact += DwUtils.formClickableEmailAddress(inpRecords[i].emailAddress);
            ce.edit = '';
            ce.delete = '';

            retRecords.push(ce);
        }
        return retRecords;
    }
    addNewRecord(): void {
        this.displayAddContact = true;
        this.addEditFlag = true;
        this.addContact.afterOpenDialog(new Contacts(), this.addEditFlag);
    }
    editRecord(idx: number): void {
        this.onRowClicked(idx);
        this.displayAddContact = true;
        this.addEditFlag = false;
        this.addContact.afterOpenDialog(this.records[this.Idx], this.addEditFlag);
    }
    newContactCreated(event: any): void {

        if (this.addEditFlag === true) {
           // push the new record onto the top of the stack.
           this.records.unshift(event as Contacts);
        }
        else {
            this.records[this.Idx] = event as Contacts;
        }
        this.setDataSource();
        this.dataSource.sort = this.sort;
        this.Idx = 0;       // sets the currently selected row

        this.displayAddContact = false;
    }
    newContactCanceled(event): void {
        this.displayAddContact = false;
    }
    deleteRecord(idx: number): void {

        this.Idx = idx;
        const localRecord = this.records[idx];
        const message = 'Remove contact record for: "' + localRecord.fullName + '"<br><b>Are You Sure?</b>';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const result = dialogResult;
            if (result === true) {

                this.contactsService.removeContacts(localRecord.id).subscribe(
                    (data) => {

                        if (data.hasOwnProperty('severity') === true) {
                            // error the delete failed
                            if (data.severity === 'ERROR') {
                                const strMessage = 'Error: Cannot delete this record due to existing relationships';
                                this.deleteMessage.emit(strMessage);
                                return;
                            }
                        }
                        const localIdx = this.records.findIndex(r => r.id === localRecord.id);
                        this.records.splice(localIdx, 1);
                        this.setDataSource();
                        this.selectedRecord = new ContactEntries();
                        this.Idx = -1;              // unset selected records.
                    },
                    (error) => {
                        Settings.getInstance().handleError('Error: unable to remove this record.');
                    });
            }
        });
    }
    onRowClicked(idx: number): void {
        this.Idx = idx;                     // sets the row.
        this.selectedRecord = this.dataSource.data[idx];
    }
    tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.data.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.data[++this.Idx];
                // this.displayDetail(this.selectedRecord);
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.data[--this.Idx];
                // this.displayDetail(this.selectedRecord);
            }
        }
    }
    selectRow() {

        if (this.selectedRecord.id === 0) {
           return;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.selectedRecord.id === this.dataSource.data[i].id) {
                // set this record's index in the table.
                this.Idx = i;
                return;
            }
        }
    }
}


