import { Component, OnInit, Input, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { MedicationService } from 'src/app/services/medication.service';
import { Settings } from 'src/app/services/settings';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Ingredients } from 'src/app/models/ingredients';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MedicationFamily } from 'src/app/models/medicationfamily';

@Component({
	selector: 'app-medication-ingredients',
	templateUrl: './medication-ingredients.component.html',
	styleUrls: ['./medication-ingredients.component.css']
})
export class MedicationIngredientsComponent {

	medicationIngredients: Ingredients[];
	displayedColumns: string[];
	selectedRecord: Ingredients;
	Idx = -1;

	isEditing = false;
	_medication: MedicationFamily;
	pagination: string;

	renderedData = Array<Ingredients>();
	dataSource: MatTableDataSource<Ingredients>;
	@ViewChild('table') table: MatTable<Ingredients>;
	displayAddEditIngredient = false;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	get Medication(): any {
		return this._medication;
	}
	@Input() set Medication(value: any) {
		this._medication = value;
		this.queryRecords();
	}

	constructor(private medicationService: MedicationService, private dialog: MatDialog) {
		this.displayedColumns = ['listSequence', 'ingredient', 'quantity', 'uom', 'abcxyiCode', 'delete'];
	}

	ngOnInit() {

	}

	queryRecords() {
		if (this._medication === undefined || this._medication.id === undefined) {
			return;
		}
		this.medicationService.getIngredientsByMedicationFamilyId(this._medication.id).subscribe(
			(result) => {
				if (result === undefined || result.length === undefined) {
					return;
				}
				this.medicationIngredients = result;
				this.medicationIngredients.sort(function(a, b) { return a.listSequence - b.listSequence })
				this.dataSource = new MatTableDataSource(this.medicationIngredients);
				this.dataSource.sort = this.sort;
				this.setPagination(-1, this.dataSource.data.length);
			},
			(error) => {
			}
		);
	}

	selectRow() {
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = undefined;
		this.setPagination(this.Idx, this.dataSource.data.length);
	}
	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}
		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
			}
		}
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}

	deleteIngredient(record: Ingredients) {
		const message = 'Remove this ingredient - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {

				this.medicationService.deleteIngredients(record.id).subscribe(
					(result) => {
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}
	editClickHandler() {
		this.isEditing = true;
	}
	saveClickHandler() {
		this.isEditing = false;
	}
	cancelClickHandler() {
		this.isEditing = false;
		//this.updateDisplay();
	}


	addRecord() {
		this.selectedRecord = undefined;
		this.showDialogAddEditRecord();
	}

	showDialogAddEditRecord() {
		this.displayAddEditIngredient = true;
	}
	cancelClickedFromAddeditRecord() {
		this.displayAddEditIngredient = false;
	}
	submitClickedFromAddeditRecord() {
		this.displayAddEditIngredient = false;
		this.queryRecords();
	}
	onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
	}

	dropTable(event: CdkDragDrop<Ingredients[]>) {
		const prevIndex = this.dataSource.data.findIndex((d) => d === event.item.data);
		moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);
		this.table.renderRows();
		for (let i = 0; i < this.dataSource.data.length; i++) {
			let rec: Ingredients = this.dataSource.data[i];
			rec.listSequence = i + 1;
			delete rec['medicationFamily'];
			if (!rec || rec.id === 0) {
				return;
			}
			this.medicationService.updateIngredients(rec).subscribe(
				(result) => {
				},
				(error) => {
				}
			)
		}
	}
}
