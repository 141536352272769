<div #txtbl1 class="mat-elevation-z1 tx-table-div" id="txTableId"
        (keydown)="tableKeyDown($event)"  tabindex="-1">
        <table mat-table [dataSource]="dataSource" matSort
               style="outline: none!important;"
               class="tx-table-properties"
               (matSortChange)="onSortChange($event)">

            
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="background-color: white; z-index:99999">Id</th>
                <td mat-cell style="width:8% !important; "
                    class="tx-data-properties"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.id}}</td>
            </ng-container>
			 <ng-container matColumnDef="bprId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            style="background-color: white; z-index:99999">BprId</th>
        <td mat-cell style="width:8% !important; "
            class="tx-data-properties"
            *matCellDef="let element;let tableRowIdx = index;">
            {{ element.bprId }}</td>
		    </ng-container>
		
		    <ng-container matColumnDef="bulkUnitId">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">BulkUnitId</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.bulkUnitId }}</td>
		    </ng-container>
		
		    <ng-container matColumnDef="icn">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">ICN</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.icn }}</td>
		    </ng-container>
		
		    <ng-container matColumnDef="ordered">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Ordered</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.ordered }}</td>
		    </ng-container>
		    
		    
		     <ng-container matColumnDef="used">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Ordered</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.used }}</td>
		    </ng-container>
		    
		     <ng-container matColumnDef="checkedStatus">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Checked Status</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.checkedStatus }}</td>
		    </ng-container>
		    
		     <ng-container matColumnDef="remaining">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Doses remaining</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.remaining }}</td>
		    </ng-container>
		    
		     <ng-container matColumnDef="variance">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Ordered</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.variance }}</td>
		    </ng-container>
		    
		     <ng-container matColumnDef="varianceReason">
		        <th mat-header-cell *matHeaderCellDef mat-sort-header
		            style="background-color: white; z-index:99999">Ordered</th>
		        <td mat-cell style="width:8% !important; "
		            class="tx-data-properties"
		            *matCellDef="let element;let tableRowIdx = index;">
		            {{ element.varianceReason }}</td>
		    </ng-container>
			
			<ng-container matColumnDef="varianceComments">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">Variance Comments</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.varianceComments }}</td>
			</ng-container>
			
			<ng-container matColumnDef="itemPrefix">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">Item Prefix</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.itemPrefix }}</td>
			</ng-container>
			
			<ng-container matColumnDef="ndcNumber">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">NDC Number</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.ndcNumber }}</td>
			</ng-container>
			
			<ng-container matColumnDef="description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">Description</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.description }}</td>
			</ng-container>
			
			<ng-container matColumnDef="expirationDate">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">Expiration Date</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.expirationDate | date: 'MM/dd/yyyy' }}</td>
			</ng-container>
			
			<ng-container matColumnDef="lotNumber">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header
			        style="background-color: white; z-index:99999">Lot Number</th>
			    <td mat-cell style="width:8% !important; "
			        class="tx-data-properties"
			        *matCellDef="let element;let tableRowIdx = index;">
			        {{ element.lotNumber }}</td>
			</ng-container>
            
          

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row style="font-size: 8pt;"
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
>
            </tr>
        </table>
    </div>
<!-- save strip -->
	<div class="save-close-strip" style="bottom: 5px;padding: 0px!important">
		<button mat-button class="command-button"
			(click)="doClose()" matTooltip="Close"
			mat-button>
			<mat-icon>highlight_off</mat-icon>
			Close
		</button>
</div>
