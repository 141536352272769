import {AfterViewInit, OnInit, Component} from '@angular/core';
import { Settings } from 'src/app/services/settings';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

	visibleInventories = false;
	visibleMedications = false;
	visibleMaterials = false;
	visibleSuppliers = false;
	visibleSites = false;
	visibleMedicationFamily = false;

	visibleInventoriesLink = false;
	visibleMedicationsLink = false;
	visibleMaterialsLink = false;
	visibleSuppliersLink = false;
	visibleSitesLink = false;
	visibleMedicationFamilyLink = false;

constructor() { }

	ngOnInit() {

		if (Settings.getInstance().hasPrivilege('MANAGE_MEDICATIONS')) {
			this.visibleInventoriesLink = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_MEDICATIONS')) {
			this.visibleMedicationsLink = true;
			this.visibleMedicationFamilyLink = true;
		}
		
		if (Settings.getInstance().hasPrivilege('MANAGE_MATERIALS')) {
			this.visibleMaterialsLink = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_SUPPLIERS')) {
			this.visibleSuppliersLink = true;
		}
		
		if (Settings.getInstance().hasPrivilege('MANAGE_SITES')) {
			this.visibleSitesLink = true;
		}
	}
	showInventories() {
		this.hideAllChildComponents();
		this.addClassToItem('invItem');
		this.visibleInventories = true;
	}
	showMedications() {
		this.hideAllChildComponents();
		this.addClassToItem('medsItem');
		this.visibleMedications = true;
	}
	showMedicationFamily() {
		this.hideAllChildComponents();
		this.addClassToItem('familyItem');
		this.visibleMedicationFamily = true;
	}
	showSites() {
		this.hideAllChildComponents();
		this.addClassToItem('sitesItem');
		this.visibleSites = true;
	}
	showMaterials() {
		this.hideAllChildComponents();
		this.addClassToItem('materialsItem');
		this.visibleMaterials = true;
	}
	showSuppliers() {
		this.hideAllChildComponents();
		this.addClassToItem('suppliersItem');
		this.visibleSuppliers = true;
	}
	hideAllChildComponents() {

		this.visibleInventories = false;
		this.visibleSites = false;
		this.visibleMaterials = false;
		this.visibleMedications = false;
		this.visibleSuppliers = false;
		this.visibleMedicationFamily = false;
		if (this.visibleInventoriesLink) {
			this.removeClassFromItems('invItem');
		}
		if (this.visibleMedicationFamilyLink) {
			this.removeClassFromItems('familyItem');
		}
		if (this.visibleMedicationsLink) {
			this.removeClassFromItems('medsItem');
		}
		if(this.visibleMaterialsLink) {
			this.removeClassFromItems('materialsItem');
		}
		if(this.visibleSuppliersLink) {
			this.removeClassFromItems('suppliersItem');
		}
		if(this.visibleSitesLink) {
			this.removeClassFromItems('sitesItem');
		}
	}
	removeClassFromItems(itemId: string): void {
		if (document.getElementById(itemId).classList.contains('selectedMenuItem') === true)
			document.getElementById(itemId).classList.remove('selectedMenuItem');

	}
	addClassToItem(itemId: string): void {
		document.getElementById(itemId).classList.add('selectedMenuItem');
	}
	
}
