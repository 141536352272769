/************
 * Model Class
 * File Name ...: suppliermeds.components.ts
 * Generated By : ianday on 5/22/23
 * Date ........: 5/22/23
 /************/

import {Suppliers} from './suppliers';
import {PurchaseOrders} from './purchaseorders';
import {PurchaseOrderItems} from './purchaseorderitems';

export class SupplierItems {
    public id: number;
    public companyName: string;
    public supplierId: number;
    public supplierType: string;
    public itemId: number;
    public description: string;
    public origNdcNumber: string;
    public packageSize: number;
    public unitOfMeasure: string;
    public unitPrice: number;
    public costBase: number;
    public leadTimeDays: number;
    public quantity: number;
    public extended: number;
    public lastUpdated: string;
    public schedule: string;

    constructor() {
        this.id = 0;
        this.companyName = '';
        this.supplierId = 0;
        this.itemId = 0;
        this.description = '';
        this.origNdcNumber = '';
        this.packageSize = 0;
        this.unitOfMeasure = '';
        this.unitPrice = 0;
        this.costBase = 0;
        this.leadTimeDays = 0;
        this.quantity = 0;
        this.extended = 0;
        this.lastUpdated = '';
        this.schedule = '';
    }
    public setSupplier(selectedSupplier: Suppliers): void {
        this.id = 0;
        this.supplierId = selectedSupplier.id;
        this.companyName = selectedSupplier.companyName;
        this.supplierType = selectedSupplier.supplierType;
        this.itemId = 0;
        this.description = '';
        this.origNdcNumber = '';
        this.packageSize = 0;
        this.unitOfMeasure = '';
        this.unitPrice = 0;
        this.costBase = 0;
        this.quantity = 0;
        this.extended = 0;
        this.leadTimeDays = 0;
        this.lastUpdated = '';
        this.schedule = '';
    }
    public setFamilyMeds(rec: any, supplierId: number, id: number = 0): void {
        this.id = id;
        this.supplierId = supplierId;
        this.supplierType = 'Medications';
        this.itemId = rec.itemId;
        this.description = rec.description;
        this.origNdcNumber = rec.origNdcNumber;
        this.packageSize = rec.packageSize;
        this.unitOfMeasure = rec.unitOfMeasure;
        this.costBase = rec.costBase;
        this.unitPrice = rec.unitPrice;
        this.quantity = 0;
        this.extended = 0;
        this.leadTimeDays = rec.leadTimeDays;
        this.lastUpdated = rec.lastUpdated;
        this.schedule = rec.schedule;
    }
    public setFamilyMaterials(rec: any, supplierId: number, id: number = 0): void {
        this.id = id;
        this.supplierId = supplierId;
        this.supplierType = 'Materials';
        this.itemId = rec.itemId;
        this.description = rec.description;
        this.origNdcNumber = rec.origNdcNumber;
        this.packageSize = rec.packageSize;
        this.unitOfMeasure = rec.unitOfMeasure;
        this.costBase = rec.costBase;
        this.unitPrice = rec.unitPrice;
        this.quantity = 0;
        this.extended = 0;
        this.leadTimeDays = rec.leadTimeDays;
        this.lastUpdated = rec.lastUpdated;
    }
    setItemsFromPurchaseOrders(rec: PurchaseOrders, recItems: PurchaseOrderItems): void {
        this.id = recItems.id;
        this.companyName = rec.supplierName;
        this.supplierId = rec.supplierId;
        this.supplierType = rec.supplierType;
        this.itemId = recItems.foreignKeyId;
        this.description = recItems.itemDescription;
        this.origNdcNumber = recItems.ndcNumber;
        this.packageSize = recItems.packageSize;
        this.unitOfMeasure = recItems.uom;
        this.unitPrice = recItems.unitCost;
        this.costBase = Number(recItems.costBase);
        this.leadTimeDays = recItems.leadTimeDays;
        this.quantity = recItems.quantity;
        this.extended = recItems.extendedCost;
        this.lastUpdated = '';
        this.schedule = recItems.schedule;
    }
}
