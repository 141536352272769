import {ValidationResult} from "./validation-result";

export interface Addresses
{
    id: number;
    foreignKeyId: number;
    foreignKeyTable: string;
    entityDescription: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

export class Addresses {
    constructor() {
        this.id = 0;
        this.foreignKeyId = 0;
        this.foreignKeyTable = '';
        this.entityDescription = '';
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.state = '';
        this.zipCode = '';
    }

    public setDefaultValues(comp: string, fkId:number):void {
        this.id = 0;
        this.foreignKeyId = fkId;
        this.foreignKeyTable = comp;
        this.entityDescription = 'Default Address - please update';
        this.address1 = '[Address Required]';
        this.address2 = '';
        this.city = '[City Required]';
        this.state = '[St]';
        this.zipCode = '[Zip]';
    }
    validateRecord(): ValidationResult {

        const vr: ValidationResult = new ValidationResult();

        if (this.entityDescription.length === 0) {
            vr.focus_field = 'entityDescription';
            vr.error = 'Error: a description is required';
            return vr;
        }

        if (this.address1.length === 0) {
            vr.focus_field = 'address1';
            vr.error = 'Error: a street address is required';
            return vr;
        }
        if (this.city.length === 0) {
            vr.focus_field = 'city';
            vr.error = 'Error: a city is required';
            return vr;
        }

        if (this.state.length === 0) {
            vr.focus_field = 'state';
            vr.error = 'Error: a state is required';
            return vr;
        }

        if (this.zipCode.length === 0) {
            vr.focus_field = 'zipCode';
            vr.error = 'Error: a zip code is required';
            return vr;
        }
        return vr;
    }
}



