<div class="add-address-container" tabindex="0">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;">

            <div class="dwform-row">
                <label class="label-field">Description:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="desc"
                       [(ngModel)]="valDescription"
                       placeholder="<description>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Address Section ---->
            <!----------------------->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">URL:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="url"
                       [(ngModel)]="valURL"  (focusout)="urlFoucOutHandler()"               
                       placeholder="<url>"
                       type="url">
            </div>
            
            <div class="dwform-row-gap"></div>
           

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
