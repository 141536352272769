	<div class="mat-suppliers-container">
		<div style="width: 100%; margin-top: 15px; margin-bottom: 25px;padding-left: 20px;padding-right: 20px;">
			<button mat-button style="float: right;" [disabled]="this._material===undefined || this._material.id===0"
				class="command-button" matTooltip="Add a new record"
				(click)="addRecord()" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add Supplier
			</button>
		</div>
	<div class="mat-suppliers-table-container" (keydown)="tableKeyDown($event)" tabindex="-1">	
		<table *ngIf=!isEditing #table id="mat-suppliers-table" mat-table [dataSource]="materialSuppliers" matSort
			   style="width: 100%; font-size: 9pt; background-color:white; z-index: 99999;
			   border-bottom: .5px solid #e1e1e1!important;">
			<ng-container matColumnDef="supplierName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 60%; min-width: 100px;border-bottom: .5px solid #e1e1e1!important;"> Supplier </th>
				<td mat-cell *matCellDef="let medSupplier" minWidth="100px;"
					style="width: 70%; min-width: 100px; font-size: 9pt;"><span>
					{{medSupplier.supplierName}}	
				</span>
				</td>
			</ng-container>


			<ng-container matColumnDef="costPerPiece">`
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					style="width: 20%; min-width: 100px; font-size:9pt;background-color: white; z-index:99999;
					border-bottom: .5px solid #e1e1e1!important;">
					Cost
				</th>
				<td mat-cell *matCellDef="let medSupplier" fxLayout="column wrap"
					style="width: 20%; min-width: 100px; font-size:9pt;">
				<span>{{medSupplier.costPerPiece}}</span></td>
			</ng-container>
			<ng-container matColumnDef="lastUpdated">`
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					style="width: 10%; min-width: 80px; font-size:9pt;background-color: white; z-index:99999;
    				border-bottom: .5px solid #e1e1e1!important;">
					Last Updated </th>
				<td mat-cell *matCellDef="let medSupplier" fxLayout="column wrap"
					style="width: 10%; min-width: 80px; font-size: 9pt">
				<span>{{lastUpdatedLabeler(medSupplier.lastUpdated)}}</span></td>
			</ng-container>
			
			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					style="width: 10%; min-width: 80px; font-size:9pt;background-color: white; z-index:99999;
    				border-bottom: .5px solid #e1e1e1!important;"> </th>
				<td mat-cell *matCellDef="let medSupplier" minWidth="30px;"
					style="width: 10%; min-width: 80px; font-size: 9pt; text-align: right;">
					<span> 
						<mat-icon class="edit-icon-suppliers" (click)="this.showDialogAddEditSupplier()">edit</mat-icon>
						<img style="width: 12px;cursor: pointer" class="edit-delete-button" alt="remove"
							 src="assets/delete-icon.png" (click)="deleteSupplierMaterial(medSupplier)">
					</span></td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">

			</tr>
		</table>
	 </div>
	</div>

<p-dialog header="Add/Edit Supplier"
          [(visible)]="displayAddEditSupplier"
          *ngIf="displayAddEditSupplier"
           [modal]="true" 
           appendTo="body" [responsive]="true"
          [style]="{width: '605px', height: '230px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
   <app-addeditmaterialsupplier #addeditSupplier [material]="_material" [selectedRecord]="selectedRecord" [suppliersList]="suppliersList" [materialSuppliers]="materialSuppliers"
         (cancelClicked)="cancelClickedFromAddeditSupplier()"
         (submitClicked)="submitClickedFromAddeditSupplier()">
   </app-addeditmaterialsupplier>
</p-dialog>
