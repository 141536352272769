import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { CustomerFormulary } from '../models/customer-formulary';
import { FormularyMedication } from '../models/formulary-medication';
import { Settings } from './settings';
import { SupplierMaterial } from '../models/supplier-material';
import { FormularyMedicationStatus } from '../models/formulary-medication-status';
import { FormularyMaterials } from '../models/formulary-material';
import { FormularyMedicationLabel } from '../models/formulary-label';


@Injectable({
	providedIn: 'root'
})
export class FormularyService {

	constructor(private http: HttpClient) { }

	getCustomerFormularyByCustomerId(customerId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/customers-formulary-by-customer/' + customerId)
			.pipe(
				retry(1)
			);
	}
	createFormulary(record: CustomerFormulary): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'formulary/customers-formulary', record)
			.pipe(
				retry(1)
			);
	}
	getFormularyMedication(id: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications/' + id)
			.pipe(
				retry(1)
			);
	}
	getFormularyMedicationByCustomerId(customerId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-by-customer/' + customerId)
			.pipe(
				retry(1)
			);
	}
	getFormularyMedicationsByCustomerFormularyId(customerFormularyId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-by-customer-formulary/' + customerFormularyId)
			.pipe(
				retry(1)
			);
	}
	createFormularyMedication(record: FormularyMedication): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'formulary/formulary-medications', record)
			.pipe(
				retry(1)
			);
	}
	updateFormularyMedication(record: FormularyMedication): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'formulary/formulary-medications/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteFormularyMedication(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'formulary/formulary-medications/' + id)
			.pipe(
				retry(1)
			);
	}
	
	getFormularyMedicationStatusByFormularyMedicationId(formularyMedicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-status-by-formulary-medication/' + formularyMedicationId)
			.pipe(
				retry(1)
			);
	}
	
	createFormularyMedicationStatus(record: FormularyMedicationStatus): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-status', record)
			.pipe(
				retry(1)
			);
	}
	
	updateFormularyMedicationStatus(record: FormularyMedicationStatus): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-status/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteFormularyMedicationStatus(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-status/' + id)
			.pipe(
				retry(1)
			);
	}
	
	getFormularyMaterialsByFormularyMedicationId(formularyMedicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-materials-by-formulary-medication/' + formularyMedicationId)
			.pipe(
				retry(1)
			);
	}
	
	createFormularyMaterial(record: FormularyMaterials): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'formulary/formulary-materials', record)
			.pipe(
				retry(1)
			);
	}
	
	updateFormularyMaterial(record: FormularyMaterials): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'formulary/formulary-materials/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteFormularyMaterial(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'formulary/formulary-materials/' + id)
			.pipe(
				retry(1)
			);
	}
	
	getFormularyMedicationLabelsByFormularyMedicationId(formularyMedicationId: number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels-by-formulary-medication/' + formularyMedicationId)
			.pipe(
				retry(1)
			);
	}
	
	createFormularyMedicationLabel(record: FormularyMedicationLabel): Observable<any> {
		return this.http.post(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels', record)
			.pipe(
				retry(1)
			);
	}
	
	updateFormularyMedicationLabel(record: FormularyMedicationLabel): Observable<any> {
		return this.http.put(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deleteFormularyMedicationLabel(id: number): Observable<any> {
		return this.http.delete(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels/' + id)
			.pipe(
				retry(1)
			);
	}
	
	uploadformularyMedicationLabel(record: FormularyMedicationLabel, image: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('label', image, image.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels/file/' + record.id, formData)
			.pipe(
				retry(0)
			);
	}
	uploadFormularyMedicationStatusDocument(record: FormularyMedicationStatus, image: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('formularyStatusDocument', image, image.name);
		return this.http.post<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-status/file/' + record.id, formData)
			.pipe(
				retry(0)
			);
	}
	getLastFormularyMedicationsByCustomerIdMedicationFamilyAndPackageSize(customerId:number, medicationFamilyId:number, packageSize:number): Observable<any> {
		return this.http.get<any>(Settings.getInstance().BASE_URL + 'formulary/formulary-medications-by-customer-and-medication-family-and-package-size/' + customerId+ '/' + medicationFamilyId + '/' + packageSize)
			.pipe(
				retry(1)
			);
	}

}
