/************
 * Model Class
 * File Name ...: siteentries.components.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/
import {ValidationResult} from './validation-result';

export interface CatalogueEntries {
    id: number | null;
    description: string | null;
    activeStatus: string | null;
    visibility: string | null;
    defaultFlag: string | null;
    edit: string;
    delete: string;
}

export class CatalogueEntries {
    constructor() {
        this.id = 0;
        this.description = '';
        this.activeStatus = '';
        this.visibility = '';
        this.defaultFlag = '';
        this.edit = '' ;
        this.delete = '';
    }
}
