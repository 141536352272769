<div class="add-address-container" tabindex="0">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;">

            <div class="dwform-row">
                <label class="label-field">Supplier:</label>
                <span class="form-required">*</span>
                 <mat-select placeholder="Select" style="width: 230px" #supplier id="supplier"
					  		panelClass="local-drop-down-panel"
                            class="dw-mat-select"
                            [(ngModel)]="valSelectedSupplierId">
						    <mat-option *ngFor="let supplier of suppliersList" [value]="supplier.id" >{{ supplier.companyName }}</mat-option>
				 </mat-select>
            </div>

            <!----------------------->
            <!-- Cost Per Piece ---->
            <!----------------------->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Cost per piece:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" #cost id="costPerPieceId" style="width: 230px"
                       [(ngModel)]="valcost"  (focusout)="costFocusOutHandler()"            
                       placeholder="<cost>"
                       min="0"
                       type="number">
            </div>
            
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Unit Price:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" #cost id="unitPriceId" style="width: 230px"
                       [(ngModel)]="valUnitPrice"  (focusout)="unitPriceFocusOutHandler()"
                       placeholder="<unitPrice>"
                       min="0"
                       type="number">
            </div>

            <div class="dwform-row-gap"></div>
            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
