<div id="side-nav-container"
     class="sidenav_container" *ngIf="IsAuthenticated"
     [@onSideNavChange]="sideNavState ? 'open' : 'close'">
  <div fxLayout="column" style="height: 100%;" >
    <div>
      <mat-nav-list>
        <a mat-list-item class="hyperlink-group"
           *ngFor="let feature of featurelist" id="{{feature?.id}}">
          <div class="feature-item">
              <mat-icon *ngIf="feature.id!=='nav-inventory'" id="icon-{{feature?.id}}" routerLink="{{feature?.link}}"
                        routerLinkActive="active-list-item">{{feature?.icon}}</mat-icon>
			   <mat-icon *ngIf="feature.id==='nav-inventory'" id="icon-{{feature?.id}}" routerLink="{{feature?.link}}"
                        routerLinkActive="active-list-item"><img style="width: 25px" src="./assets/barcode.png"></mat-icon>

              <span class="feature-text" routerLink="{{feature?.link}}"
                    routerLinkActive="active-list-item"
                    [@animateText]="linkText ? 'hide' : 'show'" >{{ feature?.name }} </span>
          </div>
        </a>
      </mat-nav-list>
    </div>
  </div>
</div>
