
import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { MedicationService } from '../../../../../services/medication.service';
import { Settings } from '../../../../..//services/settings';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ElementRef } from '@angular/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { MedicationImage } from 'src/app/models/medicationimage';
import { DwUtils } from 'src/app/components/utils/dw-utils';
import { TransactionHistoryService } from 'src/app/services/transactionhistory.service';
import { TransactionHistoryComponent } from 'src/app/components/transaction-history/transaction-history';
import { MedicationFamily } from 'src/app/models/medicationfamily';
import { Material } from 'src/app/models/material';
import { MaterialService } from 'src/app/services/material.service';
import { EventEmitter } from '@angular/core';
import { FormularyService } from 'src/app/services/formulary.service';
import { CustomerFormulary } from 'src/app/models/customer-formulary';
import { FormularyMedication } from 'src/app/models/formulary-medication';
import { FormularyMedicationStatus } from 'src/app/models/formulary-medication-status';
import { FormularyMedicationLabel } from 'src/app/models/formulary-label';
import { MatTableDataSource } from '@angular/material/table';
import { FormularyMaterials } from 'src/app/models/formulary-material';
import { PrecautionDirection } from 'src/app/models/precautiondirection';
import { GeneralService } from 'src/app/services/general.services';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Component({
	selector: 'app-formulary-main',
	templateUrl: './formulary-main.component.html',
	styleUrls: ['./formulary-main.component.css']
})
export class FormularyMainComponent {

	queryStripGroup: FormGroup;
	customDataViewFlag = false;
	isEditing = false;
	isAdd = false;
	propContainerState = false;
	pagination: string;
	visiblePropertiesTab = true;
	visibleImagesTab = true;
	visibleLinksTab = true;
	visibleSuppliersTab = true;
	selectedTabIndex = 0;
	newlyAddedMedicationId = -1;

	public result: boolean | null = false;
	selectedImageURL = '';

	public statusFilterList = [];
	public statusListIdx: number | null = -1;
	public selectedStatusFilter;

	contentClassWithBanner = 'dwtable-container';
	contentClassWithoutBanner = 'dwtable-container-without-header';
	contentClassName = 'dwtable-container';

	disabledFlag = true;

	formTitle = 'Medications';
	strErrLine = '';

	BASE_URL = Settings.getInstance().BASE_URL;
	selectedFormularyFilter: CustomerFormulary;
	customerForlumaryList: CustomerFormulary[];
	recordsOriginal: FormularyMedication[];
	selectedRecord: FormularyMedication;
	selectedLabelRecord: FormularyMedicationLabel;
	selectedMaterialRecord: FormularyMaterials;
	selectedStatusRecord: FormularyMedicationStatus;
	records: FormularyMedication[];
	recordsStatuses: FormularyMedicationStatus[];
	recordsLabels: FormularyMedicationLabel[];
	recorsMaterials: FormularyMaterials[];
	medicationFamilyList: MedicationFamily[];
	materialsList: Material[];
	historyRecord: FormularyMedication;
	renderedData = Array<FormularyMedication>();
	renderedDataLabels = Array<FormularyMedicationLabel>();
	renderedDataStatusess = Array<FormularyMedicationStatus>();
	renderedDataMaterials = Array<FormularyMaterials>();
	displayedColumns: string[] = ['id', 'itemNumber', 'description', 'packageSize', 'approvalStatus', 'createdate'];
	displayedColumnsStatus: string[] = ['statusDate', 'status', 'statusBy', 'statusComment', 'fileURL'];
	displayedColumnsLabel: string[] = ['id', 'uploadedDate', 'uploadedBy', 'default', 'labelURL', 'download', 'delete'];
	displayedColumnsMaterials: string[] = ['id', 'description', 'itemType', 'delete'];
	itemTypeList: any;
	dataSource: MatTableDataSource<FormularyMedication>;
	dataSourceStatus: MatTableDataSource<FormularyMedicationStatus>;
	dataSourceLabel: MatTableDataSource<FormularyMedicationLabel>;
	dataSourceMaterial: MatTableDataSource<FormularyMaterials>;

	visibleAdd = false;
	visibleModify = false;
	visibleDelete = false;
	formContent: UntypedFormGroup;
	Idx = -1;
	IdxLabels = -1;
	IdxStatuses = -1;
	IdxMaterials = -1;


	valHandle: any;
	valDesc: any;
	valPkgSize: number;
	valSerialNumber: any;
	valItemType: any;
	valItemNumber = 'XXXX - XX - XXXX';
	valMedicationFamily: MedicationFamily;
	valPrecautions: any;
	valAltPrecautions: any;
	valDirections: any;
	valAltDirections: any;
	valSeqNumber: number;

	valCostBase: any;
	valAWP: any;
	valStatus: any;
	valId: any;
	sortField: string;
	sortDir: string;

	currentStatus = 'DRAFT';
	pkgSizesList = Settings.getInstance().packageSizes;
	filteredPkgSizesList: number[];
	formualryMedicationStatusList: FormularyMedicationStatus[];
	formualryMedicationLableList: FormularyMedicationLabel[];
	formualryMedicationMaterialsList: FormularyMaterials[];
	_customerId: number;
	_mfId: number;
	_formularyMedicationId: number;

	displayAddLabelWindow = false;
	displayChangeStatusWindow = false;
	displayAddMaterialWindow = false;
	displayLargeImage = false;

	largeImgUrl: any;

	@Output() cancelClicked = new EventEmitter();
	@Input() set CustomerId(value: any) {
		this._customerId = value;
		if (this._customerId) {
			this.queryRecords();
		}
	}

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('history', { static: true }) history: TransactionHistoryComponent;

	constructor(private medicationService: MedicationService, private formularyService: FormularyService, private materialService: MaterialService,
		private httpClient: HttpClient, private dialog: MatDialog, private txService: TransactionHistoryService, private generalService: GeneralService) {
		this.statusFilterList = new Array();
		this.statusFilterList.push({ id: 'All', description: 'All Statuses' });
		this.statusFilterList.push({ id: 'Draft', description: 'Draft' });
		this.statusFilterList.push({ id: 'Sent', description: 'Sent' });
		this.statusFilterList.push({ id: 'Approved', description: 'Approved' });
		this.statusFilterList.push({ id: 'Changed', description: 'Changed' });
		this.selectedStatusFilter = this.statusFilterList[0];
		this.statusListIdx = 0;

	}

	ngOnInit() {
		// Now let's set the access privilege level
		if (Settings.getInstance().hasPrivilege('MANAGE_CUSTOMERS', false)) {
			this.visibleAdd = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_CUSTOMERS', false)) {
			this.visibleModify = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_CUSTOMERS', false)) {
			this.visibleDelete = true;
		}
		if (Settings.getInstance().enableMessageBanner) {
			this.contentClassName = this.contentClassWithBanner;
		} else {
			this.contentClassName = this.contentClassWithoutBanner;
		}
		this.visibleAdd = true;
		this.visibleModify = true;
		this.visibleDelete = true;

		this.dataSource = new MatTableDataSource();
		this.dataSourceStatus = new MatTableDataSource();
		this.dataSourceLabel = new MatTableDataSource();
		this.dataSourceMaterial = new MatTableDataSource();
		this.itemTypeList = [{ type: 'Equipment', eg: 'Computers, counting machines, printers etc.' },
		{ type: 'Containers', eg: 'Round containers / vials / MPACs etc.' },
		{ type: 'Consumables', eg: 'Desiccants, glues, solutions for cleaning, paper towels, disinfectant etc.' },
		{ type: 'Paper', eg: 'Labels � paper, forms etc.' },
		{ type: 'Other ', eg: 'Anything else' },];

		//this.queryRecords();

	}
	public onStatusFilterKeyDown(event: any): void {
		const len: number = this.statusFilterList.length;
		if (event.key === 'ArrowDown') {
			if (this.statusListIdx < (len - 1)) {
				this.selectedStatusFilter = this.statusFilterList[++this.statusListIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.statusListIdx > 0) {
				this.selectedStatusFilter = this.statusFilterList[--this.statusListIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedStatusFilter = this.statusFilterList[this.statusListIdx];
			this.onChangeStatusFilterValue(this.selectedStatusFilter);
		}
	}
	applyStatusFilter() { // revisit to check status
		this.records = [];
		for (const rec of this.recordsOriginal) {
			if (this.selectedStatusFilter.id === 'All' || rec.approvalStatus === this.selectedStatusFilter.id)
				this.records.push(rec);
		}
		this.buildDataSource();
	}
	onChangeFormularyFilter() {
		this.loadMedicationsList();
	}

	queryRecords() {
		this.formularyService.getCustomerFormularyByCustomerId(this._customerId).subscribe(
			(result) => {
				this.customerForlumaryList = result;
				if (this.customerForlumaryList.length > 0) {
					this.selectedFormularyFilter = this.customerForlumaryList[0];
					this.medicationService.getAllMedicationFamily().subscribe(
						(result2) => {
							this.medicationFamilyList = result2;
							this.materialService.getAllMaterials().subscribe(
								(result3) => {
									this.materialsList = result3;
									this.generalService.getAllPackageSizes().subscribe(
										(result4) => {
											if (result4) {
												this.pkgSizesList = [];
												for (const rec of result4) {
													this.pkgSizesList.push(rec.value);
												}
												Settings.getInstance().packageSizes = this.pkgSizesList;
											}
											this.loadMedicationsList();
										},
										(error4) => {
										}
									);
								},
								(error) => {
								}
							);

						},
						(error) => {
							Settings.getInstance().handleError('Error: unable to retrieve data from the service');
						}
					);
				} else {
					let newRec: CustomerFormulary = new CustomerFormulary();
					newRec.customerId = this._customerId;
					newRec.activeStatus = 1;
					newRec.defaultFlag = 1;
					newRec.name = 'Default';
					this.formularyService.createFormulary(newRec).subscribe(
						(result4) => {
							this.formularyService.getCustomerFormularyByCustomerId(this._customerId).subscribe(
								(result) => {
									this.customerForlumaryList = result;
									if (this.customerForlumaryList.length > 0) {
										this.selectedFormularyFilter = this.customerForlumaryList[0];
										this.medicationService.getAllMedicationFamily().subscribe(
											(result2) => {
												this.medicationFamilyList = result2;
												this.materialService.getAllMaterials().subscribe(
													(result3) => {
														this.materialsList = result3;
														this.generalService.getAllPackageSizes().subscribe(
															(result4) => {
																if (result4) {
																	this.pkgSizesList = [];
																	for (const rec of result4) {
																		this.pkgSizesList.push(rec.value);
																	}
																	Settings.getInstance().packageSizes = this.pkgSizesList;
																}
																this.loadMedicationsList();
															},
															(error4) => {
															}
														);
													},
													(error) => {
													}
												);

											},
											(error) => {
												Settings.getInstance().handleError('Error: unable to retrieve data from the service');
											}
										);
									}

								},
								(error) => {
									Settings.getInstance().handleError('Error: unable to retrieve data from the service');
								}
							);
						},
						(error4) => {
						}
					);
				}

			},
			(error) => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			}
		);
	}
	loadMedicationsList() {
		if (!this.selectedFormularyFilter) {
			return;
		}
		this.formularyService.getFormularyMedicationsByCustomerFormularyId(this.selectedFormularyFilter.id).subscribe(
			(result: FormularyMedication[]) => {
				for (const rec of result) {
					if (!rec.approvalStatus || rec.approvalStatus === '') {
						rec.approvalStatus = 'Draft';
					}
					const medFamily = this.getMedicationFamilyById(rec.medicationFamilyId);
					if (medFamily) {
						this._mfId = medFamily.id;
						rec.medicationDescription = medFamily.description;
						let str = '';
						str = medFamily.itemPrefix + '-' + this.pad(rec.packageSize, 0);
						str = str + '-' + this.pad(rec.seqNumber, 4);
						rec.itemNumber = str;
					} else {
						rec.medicationDescription = 'NA';
					}
					rec.customerFormularyId = this.selectedFormularyFilter.id;
				}
				this.recordsOriginal = result;
				this.records = result;
				this.applyStatusFilter();
				this.setPagination(-1, this.records.length);
				if (this.isAdd === true) {
					this.selectedRecord = this.dataSource.data[this.dataSource.data.length - 1];
					this.displayDetail(this.selectedRecord);
					this.selectRow();
				}
			},
			(error) => {
			}
		);
	}
	buildDataSource() {
		let data = [];
		this.dataSource.data = this.records;
		this.setPagination(-1, this.records.length);
	}


	rowIsNested(handle: string): string {

		if (handle.substring(0, 3) === ' - ') {
			return 'nested-row';
		}
		return '';
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	applyFilter(filterValue: string) {
		if (this.isEditing) return;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		this.unSelectRow();
		this.setPagination(-1, this.dataSource.filteredData.length);
	}

	@HostListener('matSortChange', ['\$event'])
	onSortChange(event: any) {

		if (event.direction === 'asc') {
			this.records.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.records.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSource.data = this.records;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
	}
	selectRow() {
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		//console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
		if(this.Idx>0){
			this._formularyMedicationId = this.selectedRecord.id;
		}

	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = new FormularyMedication();
		this.setPagination(this.Idx, this.records.length);
		this.displayDetail(this.selectedRecord);

	}
	clearFilter() {
		if (this.isEditing) {
			return;
		}
		this.dataSource.filter = '';

		this.unSelectRow();
		this.setPagination(-1, this.records.length);

	}
	getMedicationFamilyById(paramId: number) {
		for (const rec of this.medicationFamilyList) {
			if (rec.id === paramId) {
				return rec;
			}
		}
	}
	displayDetail(record: FormularyMedication) {
		if (record === undefined || record.id === 0) {
			return;
		}

		this.valId = record.id;
		this.valPrecautions = record.precautions;
		this.valAltPrecautions = record.altPrecautions;
		this.valDirections = record.directions;
		this.valAltDirections = record.altDirections;
		this.valPkgSize = record.packageSize;
		this.valSeqNumber = record.seqNumber;
		this.valMedicationFamily = this.getMedicationFamilyById(this.selectedRecord.medicationFamilyId);
		this._mfId = this.valMedicationFamily.id;
		this._formularyMedicationId = this.selectedRecord.id;

		this.valItemNumber = this.valMedicationFamily.itemPrefix + '-' + this.pad(this.valPkgSize, 0);

		this.valItemNumber = this.valItemNumber + '-' + this.valSeqNumber;

		this.formularyService.getFormularyMedicationStatusByFormularyMedicationId(this.selectedRecord.id).subscribe(
			(result) => {

				this.formualryMedicationStatusList = result;
				for (const rec of this.formualryMedicationStatusList) {
					if (rec.approvalFileLocation && rec.approvalFileLocation.length > 0) {
						rec.documentURL = this.BASE_URL + 'formulary/formulary-medications-status/file/' + rec.id;
					}
				}
				this.dataSourceStatus = new MatTableDataSource(this.formualryMedicationStatusList);
				//this.dataSourceStatus.sort = this.sort;
			},
			(error) => {
			}
		);

		this.formularyService.getFormularyMedicationLabelsByFormularyMedicationId(this.selectedRecord.id).subscribe(
			(result) => {
				this.formualryMedicationLableList = result;
				for (const rec of this.formualryMedicationLableList) {
					if (rec.labelFilePath) {
						rec.labelURL = this.BASE_URL + 'formulary/formulary-medications-labels/file/' + rec.id;
					}
				}
				this.dataSourceLabel = new MatTableDataSource(this.formualryMedicationLableList);
			},
			(error) => {
			}
		);

		this.formularyService.getFormularyMaterialsByFormularyMedicationId(this.selectedRecord.id).subscribe(
			(result) => {
				this.formualryMedicationMaterialsList = result;
				this.dataSourceMaterial = new MatTableDataSource(this.formualryMedicationMaterialsList);
				for (const rec of this.formualryMedicationMaterialsList) {
					const matRecord = this.getMaterialById(rec.materialId)
					if (matRecord) {
						rec.description = matRecord.handle;
						rec.itemType = matRecord.itemType;
					}
				}
			},
			(error) => {
			}
		);
	}
	getMaterialById(paramId: number) {
		for (const rec of this.materialsList) {
			if (rec.id === paramId) {
				return rec;
			}
		}
		return undefined;
	}
	clear() {
		this.valPkgSize = 0;
		this.valDesc = '';
		this.valId = '';
		this.valStatus = '';
		this.valAltPrecautions = '';
		this.valAltPrecautions = '';
		this.valPrecautions = '';
		this.valDirections = '';
		this.valAltDirections = '';
		this.valItemNumber = 'XXXX - XX - XXXXXX';
		this.valMedicationFamily = undefined;
	}

	dataViewFilterChanged(event) {
		console.log(event);
		//this.selectedViewFilter = changedValue;
	}

	onChangeStatusFilterValue(event: any) {
		if (event.value !== undefined) {
			this.selectedStatusFilter = event.value;
		}
		this.applyStatusFilter();
		this.setPagination(-1, this.records.length);
	}

	onPropertiesToggle() {
		this.propContainerState = !this.propContainerState;
	}

	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		}
		this.setPagination(this.Idx, this.records.length);
	}

	doTabChange(event): void {

	}
	addModifyRecord(isAdd: boolean) {
		this.isAdd = isAdd;
		this.isEditing = true;
		if (this.selectedTabIndex > 0) {
			this.selectedTabIndex = 0;
		}
		if (this.isAdd) {
			this.clear();
			this.valStatus = 'Active';
		} else {
			this.historyRecord = new FormularyMedication();
			for (let prop in this.selectedRecord) {
				this.historyRecord[prop] = this.selectedRecord[prop];
			}
		}
	}
	validateFields() {
		if (!this.valMedicationFamily) {
			this.strErrLine = 'Please select medication';
			DwUtils.setFocus('medfamily');
			return false;
		}
		if (this.valPkgSize === undefined || this.valPkgSize === 0) {
			this.strErrLine = 'Please enter Package size';
			DwUtils.setFocus('packagesize');
			return false;
		}
		if (this.isDuplicateMedication(this.valMedicationFamily, this.valPkgSize)) {
			this.strErrLine = 'This item has already been defined';
			DwUtils.setFocus('packagesize');
			return false;
		}
		return true;
	}
	isDuplicateMedication(medFamily: MedicationFamily, pkgSize: number): boolean {
		if (this.isAdd) {
			for (const rec of this.recordsOriginal) {
				if (rec.medicationFamilyId === medFamily.id && rec.packageSize === this.valPkgSize) {
					return true;
				}
			}
		} else {
			for (const rec of this.recordsOriginal) {
				if (rec.id != this.selectedRecord.id && rec.medicationFamilyId === medFamily.id && rec.packageSize === this.valPkgSize) {
					return true;
				}
			}
		}
		return false;
	}
	submitRecord() {
		if (!this.selectedFormularyFilter) {
			this.selectedFormularyFilter = this.customerForlumaryList[0];
		}
		this.strErrLine = '';
		if (!this.validateFields()) {
			return;
		}
		if (this.isAdd) {
			let newRecord = new FormularyMedication();
			newRecord.customerFormularyId = this.selectedFormularyFilter.id;
			newRecord.customerId = this.selectedFormularyFilter.customerId;
			newRecord.packageSize = this.valPkgSize;
			newRecord.itemNumber = this.valItemNumber;
			newRecord.directions = this.valDirections;
			newRecord.altDirections = this.valAltDirections;
			newRecord.precautions = this.valPrecautions;
			newRecord.altPrecautions = this.valAltPrecautions;
			newRecord.medicationFamilyId = this.valMedicationFamily.id;
			newRecord.seqNumber = this.valSeqNumber;

			this.formularyService.createFormularyMedication(newRecord).subscribe(
				(data2) => {
					let status = new FormularyMedicationStatus();
					this.newlyAddedMedicationId = data2.id
					status.formularyMedicationId = this.newlyAddedMedicationId;
					status.status = 'Draft';
					status.statusBy = Settings.getInstance().loggedInUser.username;
					status.statusComment = 'Created';
					status.statusDate = new Date();
					this.formularyService.createFormularyMedicationStatus(status).subscribe(
						(data2) => {
							this.queryRecords();
							this.strErrLine = '';
							this.isEditing = false;
						},
						(error) => {
						}
					);
				},
				(error) => {
				}
			);

		} else {
			delete this.selectedRecord['customerFormulary'];
			this.selectedRecord.packageSize = this.valPkgSize;
			this.selectedRecord.itemNumber = this.valItemNumber;
			this.selectedRecord.directions = this.valDirections;
			this.selectedRecord.altDirections = this.valAltDirections;
			this.selectedRecord.precautions = this.valPrecautions;
			this.selectedRecord.altPrecautions = this.valAltPrecautions;
			this.selectedRecord.seqNumber = this.valSeqNumber;
			this.formularyService.updateFormularyMedication(this.selectedRecord).subscribe(
				(data2) => {
					this.isEditing = false;
					this.strErrLine = '';
					//this.queryRecords();
				},
				(error) => {
				}
			);
		}
	}

	onRowClicked(SR: FormularyMedication) {
		this.selectedImageURL = '';
		this.IdxLabels = -1;
		if (this.isEditing) return;
		this.selectedRecord = SR;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
		this.setPagination(this.Idx, this.records.length);
		this.displayDetail(this.selectedRecord);
	}
	cancelSubmitRecord() {
		this.isEditing = false;
		this.displayDetail(this.selectedRecord);
		this.strErrLine = '';
	}
	removeRecord() {
		const message = 'Remove Medication - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {
				this.formularyService.deleteFormularyMedication(this.selectedRecord.id).subscribe(
					(data2) => {
						this.isEditing = false;
						this.queryRecords();
					},
					(error) => {
					}
				);
			}
		});
	}
	doMouseOverImage(event: any): void {

		if (event.currentTarget !== undefined) {
			let backImg = event.currentTarget.style.backgroundImage
				.replace('url\(\"', '')
				.replace('\"\)', '');

			if (backImg.length > 0) {
				this.largeImgUrl = backImg;
				this.displayLargeImage = true;
			}
		}
	}
	doMouseOutImage(): void {
		if (this.displayLargeImage === true) {
			this.displayLargeImage = false;
		}
	}
	downloadLabel(record: any): void {

		const imgUrl = record.labelURL;
		const imgName = record.labelFilePath;

		this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' })

			.subscribe((res: any) => {
				const file = new Blob([res], { type: res.type });

				const blob = window.URL.createObjectURL(file);
				const link = document.createElement('a');
				link.href = blob;
				link.download = imgName;

				// Version link.click() to work at firefox
				link.dispatchEvent(new MouseEvent('click', {
					bubbles: true,
					cancelable: true,
					view: window
				}));

				setTimeout(() => { // firefox
					window.URL.revokeObjectURL(blob);
					link.remove();
				}, 100);
			});
	}
	deleteLabel(record: FormularyMedicationLabel) {
		if (!record) {
			return;
		}
		const message = 'Remove ' + record.labelFilePath + '  - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				this.formularyService.deleteFormularyMedicationLabel(record.id).subscribe(
					(data2) => {
						this.displayDetail(this.selectedRecord);
					},
					(error) => {
					}
				);
			}
		});
	}
	deleteMaterial(record: FormularyMaterials) {
		if (!record) {
			return;
		}
		const message = 'Remove ' + record.description + '  - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				this.formularyService.deleteFormularyMaterial(record.id).subscribe(
					(data2) => {
						this.displayDetail(this.selectedRecord);
					},
					(error) => {
					}
				);
			}
		});
	}
	doClose() {
		this.cancelClicked.emit('');
	}
	pad(num: number, size: number): string {
		let s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	}
	generateItemNumber() {
		if (!this.valMedicationFamily) {
			return;
		}
		if (!this.valPkgSize) {
			return;
		}
		if (this.isAdd) {
			this.formularyService.getLastFormularyMedicationsByCustomerIdMedicationFamilyAndPackageSize(this.selectedFormularyFilter.customerId, this.valMedicationFamily.id, this.valPkgSize).subscribe(
				(data2) => {
					const result: FormularyMedication = data2;
					if (!result) {
						this.valItemNumber = this.valMedicationFamily.itemPrefix + '-' + this.pad(this.valPkgSize, 0);

						/** don't pad the sequence # **/
						this.valItemNumber = this.valItemNumber + '-1';

						//this.valItemNumber = this.valItemNumber + '-' + this.pad(1, 4);
						this.valSeqNumber = 1;
					} else {
						this.valItemNumber = this.valMedicationFamily.itemPrefix + '-' + this.pad(this.valPkgSize, 0);
						this.valItemNumber = this.valItemNumber + '-' + result.seqNumber;
						this.valSeqNumber = result.seqNumber + 1;
					}
				},
				(error) => {
				}
			);


		} else {
			this.valItemNumber = this.valMedicationFamily.itemPrefix + '-' + this.pad(this.valPkgSize, 0);
			this.valItemNumber = this.valItemNumber + '-' + this.selectedRecord.seqNumber;
		}
	}
	getLastContainerNumber() {
		let retVal = 0;
		for (const rec of this.recordsOriginal) {
			if (rec.seqNumber && rec.seqNumber > retVal) {
				retVal = rec.seqNumber;
			}
		}
		return retVal;
	}
	filterPackageSizesList(event) {
		const filtered: any[] = [];
		//this.filteredfamilyStringList = null;
		if (event.query === undefined || event.query === '') {
			this.filteredPkgSizesList = this.pkgSizesList;
			return;
		}
		for (let i = 0; i < this.pkgSizesList.length; i++) {
			const rec = this.pkgSizesList[i];
			if (rec === null || rec === undefined) {
				continue;
			}
			if (rec.toString().toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
				filtered.push(rec);
			}
		}
		this.filteredPkgSizesList = filtered;
	}
	familySelectionChanged() {
		if (!this.valMedicationFamily) {
			return;
		}
		this.medicationService.getMedicationDirectionsPrecautionsByMedicationFamilyId(this.valMedicationFamily.id).subscribe(
			(result) => {
				if (!result) {
					return;
				}
				const medicationDirPre: PrecautionDirection = result[0];
				this.valAltDirections = medicationDirPre.altDirections;
				this.valDirections = medicationDirPre.directions;
				this.valAltPrecautions = medicationDirPre.altPrecautions;
				this.valPrecautions = medicationDirPre.precautions;
			},
			(error) => {
			}
		);
	}
	pkgSizeSelectionchanged() {
		this.generateItemNumber();
	}
	changeStatus() {
		this.displayChangeStatusWindow = true;
	}
	doAddNewLabel() {
		this.displayAddLabelWindow = true;
	}
	doAddNewMaterial() {
		this.displayAddMaterialWindow = true;
	}

	cancelClickedFromChangeStatus() {
		this.displayChangeStatusWindow = false;
	}

	cancelClickedFromAddLabel() {
		this.displayAddLabelWindow = false;
	}
	submitClickedFromChangeStatus() {
		this.displayChangeStatusWindow = false;
		this.displayDetail(this.selectedRecord);
	}
	cancelClickedFromAddMaterial() {
		this.displayAddMaterialWindow = false;
	}
	submitClickedFromAddMaterial() {
		this.displayAddMaterialWindow = false;
		this.displayDetail(this.selectedRecord);
	}
	submitClickedFromAddLabel() {
		this.displayAddLabelWindow = false;
		this.displayDetail(this.selectedRecord);
	}

	onRowClickedLabels(SR: FormularyMedicationLabel) {
		if (this.isEditing) return;
		this.selectedLabelRecord = SR;
		this.renderedDataLabels = this.dataSourceLabel.connect().value;
		this.IdxLabels = this.renderedDataLabels.indexOf(this.selectedLabelRecord);
		this.selectedImageURL = this.selectedLabelRecord.labelURL;
	}
	onRowClickedStatuses(SR: FormularyMedicationStatus) {
		if (this.isEditing) return;
		this.selectedStatusRecord = SR;
		this.renderedDataStatusess = this.dataSourceStatus.connect().value;
		this.IdxStatuses = this.renderedDataStatusess.indexOf(this.selectedStatusRecord);

	}
	onRowClickedMaterials(SR: FormularyMaterials) {
		if (this.isEditing) return;
		this.selectedMaterialRecord = SR;
		this.renderedDataMaterials = this.dataSourceMaterial.connect().value;
		this.IdxMaterials = this.renderedDataMaterials.indexOf(this.selectedMaterialRecord);

	}
	tableKeyDownLabels(event: KeyboardEvent) {
		const len: number = this.dataSourceLabel.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.IdxLabels < (len - 1)) {
				this.selectedLabelRecord = this.dataSourceLabel.filteredData[++this.IdxLabels];
				this.onRowClickedLabels(this.selectedLabelRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.IdxLabels > 0) {
				this.selectedLabelRecord = this.dataSourceLabel.filteredData[--this.IdxLabels];
				this.onRowClickedLabels(this.selectedLabelRecord);
			}
		}
		this.setPagination(this.Idx, this.records.length);
	}
	tableKeyDownMaterials(event: KeyboardEvent) {
		const len: number = this.dataSourceMaterial.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.IdxMaterials < (len - 1)) {
				this.selectedMaterialRecord = this.dataSourceMaterial.filteredData[++this.IdxMaterials];
				this.onRowClickedMaterials(this.selectedMaterialRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.IdxMaterials > 0) {
				this.selectedMaterialRecord = this.dataSourceMaterial.filteredData[--this.IdxMaterials];
				this.onRowClickedMaterials(this.selectedMaterialRecord);
			}
		}
		this.setPagination(this.Idx, this.records.length);
	}
	tableKeyDownStatuses(event: KeyboardEvent) {
		const len: number = this.dataSourceStatus.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.IdxStatuses < (len - 1)) {
				this.selectedStatusRecord = this.dataSourceStatus.filteredData[++this.IdxStatuses];
				this.onRowClickedStatuses(this.selectedStatusRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.IdxStatuses > 0) {
				this.selectedStatusRecord = this.dataSourceStatus.filteredData[--this.IdxStatuses];
				this.onRowClickedStatuses(this.selectedStatusRecord);
			}
		}
		this.setPagination(this.Idx, this.records.length);
	}
	@HostListener('matSortChange', ['\$event'])
	onSortChangeStatuses(event: any) {
		if (event.direction === 'asc') {
			this.formualryMedicationStatusList.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.formualryMedicationStatusList.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSourceStatus.data = this.formualryMedicationStatusList;
		this.setPagination(this.IdxStatuses, this.dataSourceStatus.filteredData.length);
	}

	@HostListener('matSortChange', ['\$event'])
	onSortChangeLabels(event: any) {
		if (event.direction === 'asc') {
			this.formualryMedicationLableList.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.formualryMedicationLableList.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSourceLabel.data = this.formualryMedicationLableList;
		this.setPagination(this.IdxLabels, this.dataSourceLabel.filteredData.length);

	}

	@HostListener('matSortChange', ['\$event'])
	onSortChangeMaterials(event: any) {

		if (event.direction === 'asc') {
			this.formualryMedicationMaterialsList.sort((a, b) =>
				Number.isInteger(a[event.active]) ? a[event.active] - b[event.active] : a[event.active].localeCompare(b[event.active])
			);
		}
		else {
			this.formualryMedicationMaterialsList.sort((a, b) =>
				Number.isInteger(b[event.active]) ? b[event.active] - a[event.active] : b[event.active].localeCompare(a[event.active])
			);
		}

		this.dataSourceMaterial.data = this.formualryMedicationMaterialsList;
		this.setPagination(this.IdxMaterials, this.dataSourceMaterial.filteredData.length);

	}
}
