import {Injectable} from '@angular/core';
import * as string_decoder from 'string_decoder';
import {Settings} from '../../services/settings';

@Injectable({providedIn: 'root'})
export class DwUtils  {

  public static returnFocus(code): string  {
     let retVal = '';

     switch (code) {
        case 'C':
            retVal = 'Customer';
            break;

        case 'S':
            retVal = 'Supplier';
            break;
      }

     return retVal;
  }
  public static returnCurrentDateTime(justDate: boolean = false): string {

      const isoDateString = new Date().toISOString();
      const datePart = isoDateString.split('T')[0];
      const timePart = isoDateString.split('T')[1].substring(0, 5);

      if (justDate === true) {
          return datePart;
      }

      return datePart + ' ' + timePart;
  }
  public static boolToInt(inp: any): number {

      if (inp === null) {
          return null;
      }

      if ((inp === true) || (inp === 1)) {
          return 1;
      }

      return 0;
  }
  public static getDaysFromDate(inpDt: Date): number {

      let retDays = 0;
      const nowDate = new Date();

      if (inpDt === undefined || inpDt === null) {
          return 0;
      }

      const differenceInTime = nowDate.getTime() - inpDt.getTime();
      retDays = differenceInTime / (1000 * 3600 * 24);

      return retDays;
  }
  public static showDaysRemaining(inpDate: string): string {

      const formingDaysAllowed = Settings.getInstance().formingDaysAllowed;

      if (inpDate.length === 0) {
          return '0';
      }

      const daysFromDate = this.getDaysFromDate(new Date(inpDate));

      if (daysFromDate > formingDaysAllowed) {
          return '0';
      }

      return Math.round((Number(formingDaysAllowed) - Number(daysFromDate))).toString();

  }
  public static returnItemPrefix(inpStr: string): string {
      if (inpStr === undefined || inpStr === '')
          return inpStr;

      const delim = '-';

      if (! inpStr.includes(delim)) {
          return inpStr;
      }
      const itemPref = inpStr.split(delim)[0];
      return itemPref;
  }
  public static returnPackageSizeCode(inpStr: string): string {
        if (inpStr === undefined || inpStr === '')
            return inpStr;

        const delim = '-';

        if (! inpStr.includes(delim)) {
            return inpStr;
        }
        const itemPref = inpStr.split(delim)[1];
        return itemPref;
    }
  public static formatDTFromDate(inpDt: Date): string {

      if (inpDt === undefined || inpDt === null) {
          return '';
      }

      const dt1 = new Date(inpDt).toISOString();
      const retStr = DwUtils.formatDTFromString(dt1);
      return retStr;
  }
  public static formatDTFromString(inpStr: string): string   {
      if (inpStr === undefined || inpStr === '')
          return inpStr;

      let delim = 'T';
      if (! inpStr.includes('T')) {
        delim = ' ';
      }

      const datePart = inpStr.split(delim)[0];
      const timePart = inpStr.split(delim)[1].substring(0, 5);

      return datePart + ' ' + timePart;
  }
  public static formatDateFromString(inpStr: string): string {
      if (inpStr === undefined || inpStr === '')
          return inpStr;

      let delim = 'T';
      if (! inpStr.includes('T')) {
          delim = ' ';
      }
      const datePart = inpStr.split(delim)[0];
      return datePart;
  }
  public static formatDecimal = (value, decimal) => {
        return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
            'en-IN',
            {
                useGrouping: true,
            }
        );
  }
  public static formatCurrency(value, decimal): string {
      const USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
      });
      return USDollar.format(value);
  }
  public static capitalize(inp: string): string   {
      return inp.replace(/^\w|\s\w/g, t => t.toUpperCase());
  }
  public static setFocus(element: string) {

      if (element === null) {
          return;
      }

      setTimeout(() => {
        //console.log('element: ' + element.toString());
        document.getElementById(element).focus();
    }, 300);

  }
  public static highlightFocus(element: string) {

      if (element === null) {
          return;
      }

      setTimeout(() => {
          const val = document.getElementById(element) as HTMLInputElement;
          val.focus();
          val.select();
      }, 300);
  }
  public static formClickableEmailAddress(inpAddress: string): string {

        let retVal = '';

        if (inpAddress.length === 0) {
            return retVal;
        }

        retVal = '<a href="mailTo:' + inpAddress + '">' + inpAddress + '</a>';
        return retVal;
  }
  public static returnDecimalFromString(inp: string): number {

      if (inp === undefined) {
          return 0;
      }
      const retVal = inp.replace('$','');
      const newRetVal = retVal.replace('\,','');

      return Number(newRetVal);
  }
  public static returnZeroForNull(inp: string): number {
      if (inp === undefined) {
          return 0;
      }
      return Number(inp);
  }
  public static checkWWWAddress(inpAddress): string  {
      let retVal = '';

      if (inpAddress.length === 0) {
          return retVal;
      }

      if (inpAddress.substring(1, 4) === 'http') {
          return inpAddress;
      }
      else if (inpAddress.substring(1, 3) === 'www') {
          retVal = 'https://' + inpAddress;
      }
      else
      {
          retVal = 'https://www.' + inpAddress;
      }
      return retVal;

  }
  public static decimalToWhole(inp: string): string {

      if (inp.length === 0) {
          return '';
      }

      return Math.floor(Number(inp)).toString();
  }
  public static returnMaxShippingDays(inp: string): number {

      if ((inp === null) || (inp.length === 0)) {
          return 0;
      }
      if (inp === 'Priority Shipping [3-4 business days]') {
          return 4;
      }
      if (inp === 'Standard Shipping [4-5 business days]') {
          return 5;
      }
      /*** has to be a rush ***/
      return 2;

    }
  public static evaluateCoalesce(inpVal: string, replaceVal: string): string {
      if (inpVal.length === 0)
          return replaceVal;

      return inpVal;
  }
  public static decimalFmtValue(inp: string): string {
        return parseFloat(inp).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

}
