/************
 * Model Class
 * File Name ...: addressentries.components.ts
 * Generated By : ianday on 2/23/23
 * Date ........: 2/23/23
 /************/


export interface AddressEntries {
    id: number;
    address: string;
    edit: string;
    delete: string;
}

export class AddressEntries {
    public fieldToFocus: string | null = '';

    constructor() {
        this.id = 0;
        this.address = '';
        this.edit = '';
        this.delete = '';
    }


}
