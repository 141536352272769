<!--
    file type .....: HTML file: addpoitem.component.html
    Created By ....: ianday
    Date ..........: 5/24/23 
-->
<div class="addpoitem-container">

    <div class="dwform">
        <div class="dwform-row">

            <label class="label-field">Product Search:</label>
            <span class="form-required"> </span>
            <input class="input-field" id="matFamilyId" matInput [readonly]="!addEditFlag"
                   [formControl]="mfControl"
                   [matAutocomplete]="autoMatFamily"
                   style="text-transform: capitalize; width:300px;"
                   placeholder="<Product>" type="text">
            <mat-autocomplete autoActiveFirstOption
                              (optionSelected)="itemFamilySelectVal($event)"
                              class="supplier-auto-complete"
                              #autoMatFamily="matAutocomplete" >
                <mat-option [ngClass]="'mat-option-class'"
                            *ngFor="let mf of filteredMFOptions | async "
                            [value]="mf">{{mf}}
                </mat-option>
            </mat-autocomplete>

            <button mat-button matToolTip="clear Search Area"
                [disabled]="!addEditFlag"
                (click)="doClearItem()" style="scale: 80%">
                <mat-icon>highlight_off</mat-icon></button>

            <button mat-button matToolTip="Quick Add Medication" style="scale: 80%"
                [disabled]="!addEditFlag"
                (click)="doAddNewMaterialItem();">
                <mat-icon>add_circle_outline</mat-icon></button>

            <mat-divider style="width: 100%"> </mat-divider>
            <div class="dwform-row-gap"></div>

            <!-- Copy Description and NDC Number -->
            <label class="label-field">Product Selected:</label>
            <span class="form-required"> </span>
            <label class="label-field" style="width:300px; font-weight: bold;">
                {{ndcDescription}}</label>
            <label></label>

            <div class="dwform-row-gap"></div>

            <!-- Cost Base -->
            <label class="label-field">Cost Base:</label>
            <span class="form-required">*</span>
            <p-inputNumber [inputStyleClass]="'input-field-shortest'"
                   [(ngModel)]="selectedItemFamily.costBase" mode="currency" currency="USD"
                   placeholder="<Cost Base>"
                   type="text">
            </p-inputNumber>
            <div class="dwform-row-gap"></div>

            <!-- Unit Price Per Dose -->
            <label class="label-field">Unit Price:</label>
            <span class="form-required">*</span>
            <p-inputNumber [inputStyleClass]="'input-field-shortest'"
                   [(ngModel)]="selectedItemFamily.unitPrice" mode="currency" currency="USD"
                   placeholder="<Unit Price>">
            </p-inputNumber>
            <label>Cost/Dose:  ${{costPerDose}}</label>
            <!-- Quantity -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Quantity:</label>
            <span class="form-required">* </span>
            <input class="input-field-shortest" id="PoQtyId"
                   [(ngModel)]="selectedItemFamily.quantity"
                   (keyup)="doQuantityChange($event)"
                   placeholder="<Qty>"
                   type="number">

            <div class="dwform-row-gap"></div>

            <!-- Extended -->

            <label class="label-field">Extended:</label>
            <span class="form-required">*</span>
            <p-inputNumber [inputStyleClass]="'input-field-shortest'" [readonly]="true"
                   [inputStyle]="{'color':'gray'}"
                   [(ngModel)]="selectedItemFamily.extended" mode="currency" currency="USD"
                   placeholder="<Extended>">
            </p-inputNumber>
            <!-- Lead Time -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Lead Time:</label>
            <span class="form-required">* </span>
            <input class="input-field-shortest"
                   [(ngModel)]="selectedItemFamily.leadTimeDays"
                   placeholder="<lead Time>"
                   type="text"> day(s)

            <!-- Lead Time -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Last Updated:</label>
            <span class="form-required"> </span>
            <label class="label-field label-field-bold">
                {{selectedItemFamily.lastUpdated.split('T')[0]}}
            </label>

            <div class="dwform-row-gap"></div>
            <mat-divider style="width: 100%"> </mat-divider>

        </div>
    </div>
    <div class="error-line" style="bottom:41px; margin-left: 13px;" *ngIf="strErr.length > 0">
        {{strErr}}
    </div>
    <div class="save-close-strip">
        <button mat-button class="command-button" [disabled]="(selectedItemFamily.extended===0)"
                (click)="onSubmitPOItem()">
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelPOItem()">
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
