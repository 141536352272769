/************
 * Service Class
 * File Name ...: bulkunits.service.ts
 * Generated By : ianday on 4/3/23
 * Date ........: 4/3/23
 /************/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Inventories } from '../models/inventories';
import { BulkUnits } from '../models/bulkUnits';
import { Settings } from './settings';
import { InventoryControlNumbers } from '../models/icn';
import { PurchaseOrderNumbers } from '../models/purchaseordernumbers';
import { PurchaseOrders } from '../models/purchaseorders';
import { PurchaseOrderItems } from '../models/purchaseorderitems';

@Injectable({
	providedIn: 'root'
})
export class BulkUnitsService {

	public inventoriesURLPath: string = Settings.getInstance().BASE_URL + 'bulkunits/inventories';
	public bulkUnitsURLPath: string = Settings.getInstance().BASE_URL + 'bulkunits/bulkunits';
	public purchaseOrdersURLPath: string = Settings.getInstance().BASE_URL + 'bulkunits/purchaseorders';
	public purchaseOrderItemsURLPath: string = Settings.getInstance().BASE_URL + 'bulkunits/purchaseorderitems';

	constructor(private http: HttpClient) {
	}

	getAllInventoryItemsFiltered(siteId: number, categoryId: number, itemType: string): Observable<any> {
		return this.http.get<any>(this.inventoriesURLPath + '/' + siteId + '/' + categoryId + '/' + itemType)
			.pipe(
				retry(1)
			);
	}
	createICN(record: InventoryControlNumbers): Observable<any> {
		return this.http.post(this.inventoriesURLPath + '/icn/', record)
			.pipe(
				retry(1)
			);
	}
	getBulkUnits(id: number): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	getBulkUnitsByForeignKey(fKeyTable: string, fKeyId: number, catId: number, filterKeyId: number): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/' + fKeyTable + '/' + fKeyId + '/' + catId + '/' + filterKeyId)
			.pipe(
				retry(1)
			);
	}
	getBulkUnitsByICN(icn: string): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/icn/' + icn)
			.pipe(
				retry(1)
			);
	}
	getBulkUnitsForCatalogueId(catId: number): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/catalogues/' + catId)
			.pipe(
				retry(1)
			);
	}
	createBulkUnits(record: BulkUnits): Observable<any> {
		return this.http.post(this.bulkUnitsURLPath, record)
			.pipe(
				retry(1)
			);
	}
	updateBulkUnits(record: BulkUnits): Observable<any> {
		return this.http.put(this.bulkUnitsURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	updateBulkUnitsCheckInStatus(id: number, checkedInStatus: string): Observable<any> {
		return this.http.get(this.bulkUnitsURLPath + '/' + id +  '/' + checkedInStatus )
			.pipe(
				retry(1)
			);
	}
	bulkUnitChkUniqueSerialNumber(serialNumber: string): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/chk-serial-num/' + serialNumber)
			.pipe(
				retry(1)
			);
	}
	fetchInvLocation(catId: number, ndc: string): Observable<any> {
		return this.http.get<any>(this.bulkUnitsURLPath + '/inv-location/' + catId + '/' + ndc)
			.pipe(
				retry(1)
			);
	}
	deleteBulkUnits(id: number): Observable<any> {
		return this.http.delete(this.bulkUnitsURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	/******* Purchase Orders and Order Items *****/
	getPurchaseOrdersBySiteAndStatus(siteId: number, orderStatus: string, timeFrameVal: string): Observable<any> {
		return this.http.get<any>(this.purchaseOrdersURLPath + '/' + siteId + '/' + orderStatus + '/' + timeFrameVal)
			.pipe(
				retry(1)
			);
	}
	getPurchaseOrdersBySupplierIdAndStatus(supplierId: number, orderStatus: string): Observable<any> {
		return this.http.get<any>(this.purchaseOrdersURLPath + '/' + supplierId + '/' + orderStatus)
			.pipe(
				retry(1)
			);
	}
	getPurchaseOrderItemsForPO(purchOrderId:number): Observable<any> {
		return this.http.get<any>(this.purchaseOrdersURLPath + '/' + purchOrderId)
			.pipe(
				retry(1)
			);
	}
	createPurchaseOrderNumber(record: PurchaseOrderNumbers): Observable<any> {
		return this.http.post(this.purchaseOrdersURLPath + '/PONum/', record)
			.pipe(
				retry(1)
			);
	}
	createPurchaseOrder(record: PurchaseOrders): Observable<any> {
		return this.http.post(this.purchaseOrdersURLPath + '/', record)
			.pipe(
				retry(1)
			);
	}
	updatePurchaseOrder(record: PurchaseOrders): Observable<any> {
		return this.http.put(this.purchaseOrdersURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}
	deletePurchaseOrder(id: number): Observable<any> {
		return this.http.delete(this.purchaseOrdersURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}
	createPurchaseOrderItem(record: PurchaseOrderItems): Observable<any> {
		return this.http.post(this.purchaseOrderItemsURLPath + '/', record)
			.pipe(
				retry(1)
			);
	}
	updatePurchaseOrderItem(record: PurchaseOrderItems): Observable<any> {
		return this.http.put(this.purchaseOrderItemsURLPath + '/' + record.id, record)
			.pipe(
				retry(1)
			);
	}

	deletePurchaseOrderItem(id: number): Observable<any> {
		return this.http.delete(this.purchaseOrderItemsURLPath + '/' + id)
			.pipe(
				retry(1)
			);
	}

	sendPurchaseOrderByEmail(record: PurchaseOrders): Observable<any> {
		return this.http.post(this.purchaseOrdersURLPath + '/send/email/' + record.id,null)
			.pipe(
				retry(1)
			);
	}
}
