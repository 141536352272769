/**
 *  Filename ....: imgverify.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/28/23
 **/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MedicationService} from '../../../../../services/medication.service';
import {MedicationImage} from '../../../../../models/medicationimage';
import {Settings} from '../../../../../services/settings';
import {MatTableDataSource} from '@angular/material/table';

export class ImageUrls {
    tabletUrl: string;
    containerUrl: string;
}
export class ImgVerify {
    select: boolean;
    imageType: string;
    imageUrl: string;
    id: number;
}
@Component({
    selector: 'app-imgverify',
    templateUrl: './imgverify.component.html',
    styleUrls: ['./imgverify.component.css']
})

export class ImgVerifyComponent implements OnInit {

    public tabletUrl: string;
    public containerUrl: string;
    public medicationService: MedicationService;
    public ndcNumber: string;
    public imageList: any;
    public BASE_URL = Settings.getInstance().BASE_URL;
    public strErr = '';
    public medicationImages: MatTableDataSource<ImgVerify>;
    public displayedColumns = ['validated', 'imageType', 'imageUrl'];
    public Idx = -1;
    public dispLargeImage: boolean | null = false;
    public largeImgUrl: any;

    @Output() cancelImgVerify: EventEmitter<any>;
    @Output() submittedImgVerify: EventEmitter<any>;

    constructor(medSvc: MedicationService) {
        this.medicationService = medSvc;
        this.imageList = [];
        this.medicationImages = new MatTableDataSource<ImgVerify>();
        this.submittedImgVerify = new EventEmitter();
        this.cancelImgVerify = new EventEmitter();
    }

    ngOnInit() {

    }
    doShow(NDC: string): void {
        this.ndcNumber = NDC;
        this.loadImageRecords();
    }
    loadImageRecords(): void {

        this.medicationService.getMedicationImagesByNDC(this.ndcNumber).subscribe (
            (data) => {

                if (data.length === 0) {
                    this.medicationImages = new MatTableDataSource<ImgVerify>();
                    return;
                }

                this.imageList = new Array();

                for (let i = 0; i < data.length; i++) {
                    const img = new ImgVerify();
                    img.imageType = data[i].imageType;
                    img.imageUrl = data[i].imageUrl;
                    img.id = data[i].id;
                    this.imageList.push(img);
                }
                this.medicationImages = new MatTableDataSource<ImgVerify>(this.imageList);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'getMedicationImagesByNDC - doSelectImage()');
            });
    }
    tableKeyDown(event: any): void {

    }
    onRowClicked(row: any): void {

    }
    setRecord(): void {

    }
    doMouseOverImage(event: any): void {

        if (event.currentTarget !== undefined) {
            this.largeImgUrl = event.currentTarget.src;
            this.dispLargeImage = true;
        }
    }
    doMouseOutImage(): void {
        if (this.dispLargeImage === true) {
            this.dispLargeImage = false;
        }
    }
    hasCheckedItems(): boolean {

        if (this.medicationImages === undefined) { return; }

        let checkCount = 0;
        checkCount = this.medicationImages.data.filter(x => x.select === true).length;
        return checkCount > 0 ? true : false;
    }
    doSubmitImgVerify(): void {
        const obj = new ImageUrls();
        obj.tabletUrl = this.returnURL('Tablet');
        obj.containerUrl = this.returnURL('Container');
        this.submittedImgVerify.emit(obj);
    }
    returnURL(imageType: string): string {

        const medImages =
            this.medicationImages.data.find(x => x.imageType === imageType && x.select === true);

        if (medImages === undefined) { return '';}

        return medImages.imageUrl;
    }
    doCancelImgVerify(): void {

        this.cancelImgVerify.emit();
    }
}


