import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Settings } from './settings';
import { PackageSizes } from '../models/Packagesizes';



@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	constructor(private http: HttpClient) {
    }
	
	public getAllPackageSizes(): Observable<any> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/packagesizes')
            .pipe(
                retry(1)
            );
    }


}
