import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Addresses } from '../../models/addresses';
import { retry, catchError } from 'rxjs/operators';
import { Settings } from '../settings';

@Injectable({
    providedIn: 'root'
})

export class AddressesService {
    constructor(private http: HttpClient) {}

    public getAddressById(id: number): Observable<any> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/addresses/' + id)
            .pipe(
                retry(1)
            );
    }
    public getAddressesByFK(foreignKeyTable: string, foreignKeyId: number): Observable <Addresses[]> {
        return this.http.get<any>(Settings.getInstance().BASE_URL + 'general/addresses/' + foreignKeyTable + '/' + foreignKeyId)
            .pipe(
                retry(1)
            );
    }
    public createAddress(record: Addresses): Observable <any> {
        return this.http.post(Settings.getInstance().BASE_URL + 'general/addresses', record)
            .pipe(
                retry(1)
            );
    }
    public updateAddress(record: Addresses): Observable <any> {
        return this.http.put(Settings.getInstance().BASE_URL + 'general/addresses/' + record.id, record)
            .pipe(
                retry(1)
            );
    }
    public removeAddress(id: number): Observable<any> {
        return this.http.delete(Settings.getInstance().BASE_URL + 'general/addresses/' + id)
            .pipe(
                retry(1)
            );
    }
}
