import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MaterialService } from '../../../../../../services/material.service';
import { DwUtils } from '../../../../../../components/utils/dw-utils';
import { Material } from 'src/app/models/material';
import { Settings } from 'src/app/services/settings';
import { SupplierMaterial } from 'src/app/models/supplier-material';
import { Suppliers } from 'src/app/models/suppliers';

@Component({
  selector: 'app-addeditmaterialsupplier',
  templateUrl: './addeditmaterialsupplier.component.html',
  styleUrls: ['./addeditmaterialsupplier.component.css']
})

export class AddeditmaterialsupplierComponent implements OnInit {

	public record: SupplierMaterial;
	public _selectedRecord: SupplierMaterial;
	public _material: Material;
	public id = 0;
	public valSelectedSupplierId = 0;
	valcost = 0.00;
	valUnitPrice = 0.00;
	strErr = '';
	stateControl = new FormControl('');
	public addEditFlag: boolean;
	_suppliersList: Suppliers[];
	_materialSuppliers: SupplierMaterial[];

	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();


	@Input() set selectedRecord(value: any) {
		this._selectedRecord = value;
		this.onShowHandler();
	}

	get selectedRecord(): any {
		return this._selectedRecord;
		this.onShowHandler();
	}

	@Input() set material(value: any) {
		this._material = value;
	}

	get material(): any {
		return this._material;
	}

	@Input() set suppliersList(value: any) {
		this._suppliersList = value;
		this.onShowHandler();
	}

	get suppliersList(): any {
		return this._suppliersList;
	}

	@Input() set materialSuppliers(value: any) {
		this._materialSuppliers = value;
		this.onShowHandler()
	}

	get materialSuppliers(): any {
		return this._materialSuppliers;
	}


	constructor(private materialService: MaterialService) {

	}

	ngOnInit() {

	}
	onShowHandler() {
		DwUtils.setFocus('desc');
		if (this._selectedRecord !== undefined && this._material !== undefined && this._suppliersList !== undefined) {
			this.valSelectedSupplierId = this._selectedRecord.supplierId;
			this.valcost = this._selectedRecord.costPerPiece;
			this.valUnitPrice = this._selectedRecord.unitPrice;
		}
	}
	doCancel() {
		this.cancelClicked.emit();
	}

	setRecord(): void {
		this.record = new SupplierMaterial();
		if (this._selectedRecord !== undefined) {
			this.record.id = this._selectedRecord.id;
		} else {
			this.record.id = 0;
		}
		this.record.supplierId = this.valSelectedSupplierId;
		this.record.materialId = this._material.id;
		this.record.costPerPiece = this.valcost;
		this.record.unitPrice = this.valUnitPrice;
	}
	validate() {
		if (this.valSelectedSupplierId === undefined || this.valSelectedSupplierId === 0) {
			this.strErr = 'Please select Supplier';
			DwUtils.setFocus('supplier');
			return false;
		}
		if (this.valcost === undefined) {
			this.strErr = 'Please enter Cost';
			DwUtils.setFocus('cost');
			return false;
		}
		return true;
	}
	doSubmit() {
		// this is where we stuff the new address and emit it back.
		this.setRecord();

		this.strErr = '';
		if (!this.validate()) {
			return;
		}

		if (this.record.id === 0) {
			this.materialService.createMaterialSupplier(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			);
		} else {
			delete this.record['material'];
			this.materialService.updateMaterialSupplier(this.record).subscribe(
				(result) => {
					this.submitClicked.emit();
				},
				(error) => {
					Settings.getInstance().handleError(error);
				}
			)
		}

	}
	costFocusOutHandler() {
		if (this.valcost !== undefined && this.valcost < 0) {
			this.valcost = 0;
		}
	}
	unitPriceFocusOutHandler() {
		if (this.valUnitPrice !== undefined && this.valUnitPrice < 0) {
			this.valUnitPrice = 0;
		}
	}
}


