<!--
    file type .....: HTML file: add-license.component.html
    Created By ....: ianday
    Date ..........: 2/23/23
-->
<div class="add-license-container" tabindex="-1">
    <div class="dwform" style="padding-right: 15px;" tabindex="-1">

        <div class="dwform-cell-nopadding"
             (keyup)="this.strErr = ''"
             style="min-width: 500px;" tabindex="-1">

            <!-- License Type ---->
            <div class="dwform-row" style="width: 100%;">
                <label class="label-field">License Type:</label>
                <span class="form-required">*</span>
                <input class="input-field" id="licenseType" matInput
                       [formControl]="licenseControl"
                       [matAutocomplete]="autoLicense"
                       style="text-transform: capitalize"
                       placeholder="<License Type>" type="text">
                <mat-autocomplete autoActiveFirstOption
                                  class="state-license-auto-complete"
                                  [classList]="['state-license-auto-complete']"
                                  #autoLicense="matAutocomplete" >
                    <mat-option *ngFor="let type of filteredLicenseTypeOptions | async " [value]="type">
                        {{type}}
                    </mat-option>
                </mat-autocomplete>

            </div>

            <!-- Practice State --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Practice State:</label>
                <span class="form-required">*</span>
                <input class="input-field" id="practiceState" matInput
                       [formControl]="stateControl"
                       [matAutocomplete]="autoState"
                       style="width: 160px!important; text-transform: capitalize"
                       placeholder="<State>" type="text">
                <mat-autocomplete autoActiveFirstOption
                                  class="state-auto-complete"
                                  [classList]="['state-license-auto-complete']"
                                  #autoState="matAutocomplete" >
                    <mat-option *ngFor="let state of filteredStateOptions | async " [value]="state">
                        {{state}}
                    </mat-option>
                </mat-autocomplete>

                <!-- License lookup button -->
                <button mat-button class="command-button"
                        style="font-size: 9pt; width:98px; background-color: whitesmoke;"
                        (click)="doBrowseInternet()" matTooltip="Browse the Internet for this license">
                    <mat-icon>public</mat-icon>
                    Browse
                </button>

                <button mat-button class="command-button" *ngIf="(licenseControl.value.length > 0 &&
                stateControl.value.length > 0)"
                        style="font-size: 9pt; width:110px; background-color: whitesmoke;"
                        (click)="doSaveURLValue()" matTooltip="Save the URL for future use">
                    <mat-icon>save_outline</mat-icon>
                    Set URL
                </button>
            </div>

            <!-- add a dividing line for visual separation -->
            <div class="dwform-row-gap"></div>
            <mat-divider> </mat-divider>

            <!-- License Number ---->

            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">License Number:</label>
                <span class="form-required">*</span>
                <input class="input-field" id="licenseNumber"
                       [(ngModel)]="licenseNumber"
                       style="text-transform: capitalize"
                       placeholder="<License Number>"
                       type="text">
            </div>

            <!-- Expiration Date --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Expiration Date:</label>
                <span class="form-required">*</span>
                <p-inputMask class="input-field-short"
                             mask="9999-99-99"
                             id="expirationDate"
                       [(ngModel)]="expirationDate"
                       placeholder="<Format YYYY-MM-DD>"
                       type="text">
                </p-inputMask>
            </div>

            <!-- add a dividing line for visual separation -->
            <div class="dwform-row-gap"></div>
            <mat-divider> </mat-divider>

            <!-- drag drop for upload -->
            <!-- goes here -->



            <!-- Document Path --->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Attach:</label>
                <span class="form-required">*</span>
                <!-- <input class="input-field-longest" id="documentPath"
                       [(ngModel)]="documentPath"
                       placeholder="<Document Path>"
                       type="text">
                       -->
                   <table style="width:100%; padding-left:100px; width:500px;height:100px;">
                   <tr style="width:100%;">
                   	<td style="width: 100%;">
                   		 <app-file-selector #fileSelector
                                 style="padding-left: 30px; float:right" [file_types]="'.pdf'"
                                            [header]="'Upload License'"
                                            (onFileError)="onFileErrorHandler($event)"
                                            (onFileSelected)="onFileSelectedHandler($event)">
				  		 </app-file-selector>
                    </td>
                   	<td>
                   		<div  *ngIf="this.documentPath!=='' && this.documentPath!==null" style="margin-bottom: 30px">
                   			<a style="cursor: pointer;text-decoration: underline;color: blue;" (click)="openLicensePDF()">Open</a>
                   		</div>
                   		<div *ngIf="this.documentPath!=='' && this.documentPath!==null">
                   			<a style="cursor: pointer;text-decoration: underline;color: blue;" (click)="deleteLicenceFile()">Remove</a>
                   		</div>
                   	</td>
                   </tr>
                   </table>
                 
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry">
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" >
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>


<p-dialog header="Save Default URL" id="addLicenseURL"
          [(visible)]="quickSaveURL"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '600px', height: '202px'}"
          [minY]="75"
          [resizable]="false" [closable]="false">
    <app-add-licensestateurl
                     (cancelAddLicenseStateURL)="newLicenseStateURLCanceled($event)"
                     (submitAddLicenseStateURL)="newLicenseStateURLCreated($event)">
    </app-add-licensestateurl>
</p-dialog>
