import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MedicationImage } from 'src/app/models/medicationimage';
import { MedicationService } from 'src/app/services/medication.service';
import { Settings } from 'src/app/services/settings';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import {DwUtils} from "../../../../../components/utils/dw-utils";

@Component({
	selector: 'app-medication-images',
	templateUrl: './medication-images.component.html',
	styleUrls: ['./medication-images.component.css']
})
export class MedicationImagesComponent {

	public medicationImages: MedicationImage[];
	public displayedColumns: string[];
	public isEditing = false;
	public _medication: any;
	public selectedImageType: any;
	public pagination: string;
	public Idx = -1;
	public renderedData = Array<MedicationImage>();
	public dataSource: MatTableDataSource<MedicationImage>;
	public BASE_URL = Settings.getInstance().BASE_URL;
	public displayAddImage = false;
	public selectedRecord: MedicationImage;
	public selectedImageURL = '';
	public dispLargeImage = false;
	public largeImgUrl: any;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	get Medication(): any {
		return this._medication;
	}
	@Input() set Medication(value: any) {
		this._medication = value;
		this.selectedImageURL = '';
		this.queryRecords();
	}

	constructor(private medicationService: MedicationService, private dialog: MatDialog) {
		this.displayedColumns = ['image', 'imageType', 'defaultFlag', 'delete'];
	}
	ngOnInit() {

	}
	queryRecords() {
		if (this._medication === undefined || this._medication.id === undefined) {
			return;
		}
		this.medicationService.getMedicationImagesByMedicationId(this._medication.id).subscribe(
			(result) => {
				if (result === undefined || result.length === undefined) {
					return;
				}
				this.medicationImages = result;
				this.dataSource = new MatTableDataSource(this.medicationImages);
				this.dataSource.sort = this.sort;
				this.setPagination(-1, this.dataSource.data.length);
			},
			(error) => {
			}
		);
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	deleteImage(medImage: MedicationImage) {
		const message = 'Remove this Image" - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {
				this.medicationService.deleteMedicationImage(medImage.id).subscribe(
					(result) => {
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}
	addImage() {
		this.displayAddImage = true;
	}
	cancelClickedFromAddImage() {
		this.displayAddImage = false;
	}
	submitClickedFromAddImage() {
		this.displayAddImage = false;
		this.queryRecords();
	}
	doMouseOver(event: any): void {

		if (event.currentTarget !== undefined) {
			this.largeImgUrl = event.currentTarget.src;
			this.dispLargeImage = true;
		}
	}
	doMouseOut(): void {
		if (this.dispLargeImage === true) {
			this.dispLargeImage = false;
		}
	}
	selectRow() {
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = undefined;
		this.setPagination(this.Idx, this.dataSource.data.length);
	}
	onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
		this.selectedImageURL = this.BASE_URL + 'medications/medication-images/file/' + this.selectedRecord.id;
	}
	tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.data.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.data[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.data[--this.Idx];
			}
		}
	}
	defaultFlagCheckBoxClicked(image: MedicationImage, event) {
		console.log(event);
		delete image['medication'];
		this.medicationService.updateMedicationImage(image).subscribe(
			(result) => {
				//this.queryRecords();
				if (image.defaultFlag) {
					for (let rec of this.medicationImages) {
						if (rec.id === image.id) {
							continue;
						}
						if ((image.imageType === 'Container' && rec.imageType === 'Container') || (image.imageType === 'Dose' && rec.imageType === 'Dose')) {
							rec.defaultFlag = 0;
							delete rec['medication'];
							this.medicationService.updateMedicationImage(rec).subscribe(
								(result) => {
								},
								(error) => {
								}
							);
						}
					}
				}
			},
			(error) => {
				Settings.getInstance().handleError(error);
			}
		);
	}

}
