/**
 *  Filename ....: catalogues.component.ts
 *  Created by ..: ianday
 *  on Date .....: 3/16/23
 **/
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SitesService} from '../../../../services/sites.service';
import {MatSort} from '@angular/material/sort';
import { CatalogueEntries } from '../../../../models/catalogueentries';
import { Catalogues} from '../../../../models/catalogues';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Settings} from '../../../../services/settings';

import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {AddCataloguesComponent} from './add-catalogues/add-catalogues.component';
import {StationEntries} from '../../../../models/stationentries';
import {BulkUnitsService} from '../../../../services/bulkunits.service';


@Component({
    selector: 'app-catalogues',
    templateUrl: './catalogues.component.html',
    styleUrls: ['./catalogues.component.css']
})

export class CataloguesComponent implements OnInit {


    public visibleAdd = true;
    public addEditFlag: boolean;
    public dataSource: MatTableDataSource<CatalogueEntries>;
    public displayedColumns = ['id', 'description', 'defaultFlag', 'edit', 'delete'];
    private _parentId: number;
    public sitesService: SitesService;
    public bulkUnitsService:BulkUnitsService;
    public Idx: number | null = -1;
    public records: any;
    public selectedRecord: any;
    public displayAddCatalogues = false;
    public assetPath = '../../../../../assets';

    @ViewChild(MatSort, { static: true }) sort: MatSort ;

    @ViewChild(AddCataloguesComponent)
    public addCataloguesComponent: AddCataloguesComponent;

    @Input() set parentId(value: number) {
        this._parentId = value;

        if (value === undefined || value <= 0) {
            return;

        } else {
            this.Idx = -1;
            this.queryRecords();
        }
    }
    get parentId(): number {
        return this._parentId;
    }

    constructor(ssvc: SitesService, bkService: BulkUnitsService, public dialog: MatDialog) {
        this.sitesService = ssvc;
        this.bulkUnitsService = bkService;
    }

    ngOnInit() {

    }
    initializeDataSource(): void {
        this.dataSource = new MatTableDataSource<CatalogueEntries>();
        this.records = new Catalogues();
        this.Idx = -1;
    }
    queryRecords() {

        this.sitesService.getAllCataloguesBySiteId(this.parentId).subscribe(
            (result) => {

                if (result === null) {
                    return;
                }
                this.records = result.filter(x => x.activeStatus !== 'Deleted');
                this.setDataSource();
                this.dataSource.sort = this.sort;

                // this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching Catalogue Records: getAllCataloguesBySiteId');
            }
        );
    }
    setDataSource(): void     {
        this.dataSource = new MatTableDataSource(this.translate(this.records));
    }
    translate(inpRecords: any): any {

        const retRecords: any = new Array<CatalogueEntries>();

        if (inpRecords.length === 0) {
            return retRecords;
        }

        for (let i = 0; i < inpRecords.length; i++) {
            const src: Catalogues = inpRecords[i];
            const ce: CatalogueEntries = new CatalogueEntries();
            ce.id = inpRecords[i].id;
            ce.description = '<b>' + inpRecords[i].handle;
            ce.description += '</b>' + this.returnVisibility(inpRecords[i].visibility) + '<br>';

            ce.description += inpRecords[i].description + ',<br>';
            ce.description += this.returnImageSrc(inpRecords[i].activeStatus);
            ce.defaultFlag = inpRecords[i].defaultFlag;
            ce.activeStatus = inpRecords[i].activeStatus;
            ce.edit = '';
            ce.delete = '';

            retRecords.push(ce);
        }
        return retRecords;
    }
    returnVisibility(code: number): string {

        switch(code) {
            case 1:
                return ' - [Public]';
            case 2:
                return ' - [Private]';
        }
        return '';
    }
    returnImageSrc(inp: string): string {

        let fn = '';
        switch (inp) {
            case 'InActive':
                fn = 'inactive.png';
                break;
            case 'Active':
                fn = 'active.png';
                break;
        }

        const retVal = '<img src="' + this.assetPath + '/' + fn + '">';
        return retVal;
    }
    doDefaultChange(event: any, Idx: number) {

        const currCheck = this.records[Idx].defaultFlag;
        const eventCheckVal = event.checked ? 1 : 0;

        if (currCheck !== eventCheckVal) {
            const newRec = this.records[Idx];
            newRec.defaultFlag = eventCheckVal;

            this.sitesService.updateCatalogues(newRec).subscribe(
                (result) => {

                    // now update the remaining items to set them unchecked if they are checked.
                    for (let i = 0; i < this.records.length; i++) {

                        if (i === Idx) {
                            continue;
                        }
                        const reflex = this.records[i];

                        if(reflex.defaultFlag > 0) {
                            reflex.defaultFlag = 0;
                            this.dataSource.data[i].defaultFlag = '0';

                            this.sitesService.updateCatalogues(reflex).subscribe(
                                (resultA) => {
                                    // nothing.
                                },
                                (errorA) => {
                                    Settings.getInstance().handleError(errorA, 'Inner: Updating doDefaultChange()');
                                });
                        }
                    }
                    this.setDataSource();
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'Outer: Updating doDefaultChange()');
                });
        }
    }
    trueOrFalse(inpNum: number): boolean {

        if (inpNum > 0) {
            return true;
        }

        return false;
    }
    addNewRecord(): void {
        this.displayAddCatalogues = true;
        this.addEditFlag = true;
        this.addCataloguesComponent.afterOpenDialog(new Catalogues(), this.addEditFlag);
    }
    editRecord(idx: number): void {
        this.onRowClicked(idx);
        this.displayAddCatalogues = true;
        this.addEditFlag = false;
        this.addCataloguesComponent.afterOpenDialog(this.records[this.Idx], this.addEditFlag);
    }
    newCataloguesCreated(event: any): void {

        if (this.addEditFlag === true) {
            // push the new record onto the top of the stack.
            this.records.unshift(event as Catalogues);
        }
        else {
            this.records[this.Idx] = event as Catalogues;
        }
        this.setDataSource();
        this.dataSource.sort = this.sort;
        this.Idx = 0;       // sets the currently selected row

        this.displayAddCatalogues = false;
    }
    newCataloguesCanceled(event): void {
        this.displayAddCatalogues = false;
    }
    deleteRecord(idx: number): void {

        this.Idx = idx;
        const localRecord = this.records[idx];
        const message = 'Remove contact record for: "' + localRecord.handle + '"<br><b>Are You Sure?</b>';
        const dialogData = new ConfirmDialogModel('Please Confirm', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const result = dialogResult;
            if (result === true) {
                localRecord.activeStatus = 'Deleted';

                this.sitesService.updateCatalogues(localRecord).subscribe(
                    (data) => {
                        this.queryRecords();
                        this.selectedRecord = new CatalogueEntries();
                        this.Idx = -1;              // unset selected records.
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Error: unable to remove this catalogues record.');
                    });
            }
        });
    }

    onRowClicked(idx: number): void {
        this.Idx = idx;                     // sets the row.
        this.selectedRecord = this.dataSource.data[idx];
    }
    tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.data.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.data[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.data[--this.Idx];
            }
        }
    }
    selectRow() {

        if (this.selectedRecord.id === 0) {
            return;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.selectedRecord.id === this.dataSource.data[i].id) {
                // set this record's index in the table.
                this.Idx = i;
                return;
            }
        }
    }
}


