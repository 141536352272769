/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: preparation.components.ts
 * Generated By : ianday on 7/5/23
 * Date ........: 7/5/23
 /************/

import {Component, OnInit, ViewChild, HostListener, AfterContentInit, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Settings} from '../../../services/settings';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TransactionHistoryService} from '../../../services/transactionhistory.service';
import {TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import {DwUtils} from '../../../components/utils/dw-utils';
import {OrdersService} from '../../../services/orders.service';
import {SitesService} from '../../../services/sites.service';
import {Sites} from '../../../models/sites';
import {Stations} from '../../../models/stations';
import {FormingService} from '../../../services/forming.service';
import {Forming} from '../../../models/forming';
import {Orders} from '../../../models/orders';
import {GlobalSearchComponent} from './globalsearch/globalsearch.component';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-completedorders',
    templateUrl: './completedorders.component.html',
    styleUrls: ['./completedorders.component.css']
})
export class CompletedordersComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'Completed Orders';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: any[];
    public selectedRecord: any;
    public historyRecord: any;
    public Idx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Forming>;
    public renderedData: Array<any>;
    public result: boolean | null = false;
    public disabledFlag: boolean | null = true;
    public activeCellId: string | null = '';

    /**** Query strip filter settings ***/

    public selectedViewFilter: string | null = 'List';
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';
    public sitesList: any[];
    public selectedSite: Sites = new Sites();
    public stationsList: any[];
    public selectedStation: Stations = new Stations();
    public selectedOrders: Orders[];
    public selectedOrder: Orders;

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['customerName', 'itemPrefix', 'description', 'schedule', 'quantity', 'dateCompleted', 'daysInStatus', 'itemStatus'];

    /*** Dialogs ***/
    public displayGlobalSearch = false;

    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;
    public viewList: any;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: any | null = '';
    public description: any | null = '';
    public activeStatus: any | null = '';
    public selectedView: number;
    public currentStep: number | null = 5;

    /***** Side drawer ****/
    public propContainerState: boolean | null = false;
    public visiblePropertiesTab: boolean | null = true;
    public visibleNotesTab: boolean | null = true;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;
    @ViewChild('globalSearch', {static: true}) globalSearch: GlobalSearchComponent;
    @ViewChild('cbView', {static: true}) cbView: MatSelect;

    @Output() updateCounters = new EventEmitter();

    constructor(private formingService: FormingService,
                public sitesService: SitesService,
                public orderService: OrdersService,
                private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Forming();
        this.selectedOrder = new Orders();

        this.viewList = new Array();
        this.viewList.push({id: 10, description: '10 days'});
        this.viewList.push({id: 30, description: '30 days'});
        this.viewList.push({id: 90, description: '90 days'});
        this.viewList.push({id: 180, description: '6 Months'});
        this.viewList.push({id: 365, description: '1 Year'});
        this.viewList.push({id: 730, description: '2 Years'});

        this.selectedView = 10;
    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = true;
    }
    ngAfterContentInit() {

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
        this.queryRecords();
    }
    /*** Query Records from the Service ***/
    public queryRecords() {

        this.records = new Array();
        this.formingService.getAllCompletedFormsByDaysOld(this.selectedView).subscribe(
            (result) => {
                this.records = result;
                this.dataSource = new MatTableDataSource(this.records);
                this.dataSource.sort = this.sort;
                this.queryOpenOrders();
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    /*** Display the number of records from the total ***/
    public queryOpenOrders(): void {

        this.selectedOrders = new Array();
        this.orderService.getAllOrders().subscribe(
            (data) => {
                for (let i = 0; i < data.length; i++) {
                    data[i].dateSubmitted = DwUtils.formatDTFromDate(data[i].dateSubmitted);
                    data[i].dateCreated = DwUtils.formatDTFromDate(data[i].dateCreated);
                    data[i].dateClosed = DwUtils.formatDTFromDate(data[i].dateClosed);
                    this.selectedOrders.push(data[i]);
                }
                this.selectedOrders = data;
            },
            (error) => {
                Settings.getInstance().handleError(error, 'fetching order record by id');
            });
    }
    public setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    /*** utilities ***/
    public doTabChange(event): void {

    }
    public onPropertiesToggle() {
        this.propContainerState = !this.propContainerState;
    }
    public tableKeyDown(event: KeyboardEvent) {
        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.setSelectedOrder();
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.setSelectedOrder();
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }

    public viewChangeHandler(event: any): void {
        this.selectedView = event.value;
        this.queryRecords();
    }
    @HostListener('matSortChange', ['$event'])
    public onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.records;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
       // this.dataSource.filter = '';
        this.selectRow();
      //  this.setPagination(-1, this.dataSource.data.length);
    }
    public applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public selectRow() {
        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.bprId === this.selectedRecord.bprId);
        this.history.onShowHandler('bpr', 'id', this.selectedRecord.bprId.toString());
        this.setSelectedOrder();
    }
    public setSelectedOrder(): void {
        if (this.selectedRecord.id === undefined) {
            return;
        }
        this.selectedOrder = this.selectedOrders.find(x => x.id === this.selectedRecord.orderId);
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = new Forming();
        this.selectedOrder = new Orders();
     //   this.setPagination(this.Idx, this.dataSource.data.length);

    }
    public clear() {
        this.id = '';
        this.description = '';
        this.activeStatus = '';

    }
    /*** Dialogs ***/
    public doGlobalSearch(): void {
        this.displayGlobalSearch = true;
        this.globalSearch.doInitialize();
    }
    public globalSearchSelect(event: any): void {

        if (event.length > 0) {
            this.records = event;
            this.dataSource = new MatTableDataSource(this.records);
            this.Idx = -1;
        }
        this.displayGlobalSearch = false;
    }
    /*** rows and filters ***/
    public onRowClicked(selected: any) {

        if (this.isEditing === true) {
            return;
        }

        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public onRowDoubleClicked(selected: any) {
        this.onRowClicked(selected);
    }
    public cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
    }
}
