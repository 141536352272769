<div class="workspace-container">
	<div class="query-strip-container mat-elevation-z2">
		<table height="100%" width="100%">
			<TR>
				<TD class="table-title-cell"><i
					class="fa  fa-medkit fa-fw table-icon-header" style="width: 2em"></i>{{formTitle}}
				</TD>
				<TD style="width: 250px; min-width: 250px;">
				<!--	<label style="margin-right: 12px;">View</label> <mat-select
						disableOptionCentering [(value)]="selectedViewFilter"
						floatLabel="never" [(ngModel)]="selectedViewFilter"
						style="font-size: 10pt;width: 120px;"
						panelClass="local-drop-down-panel" class="dw-mat-select">
					<mat-option value="List">List</mat-option> <mat-option value="Card">Card</mat-option>

					</mat-select></TD> -->
				<TD align="left" style="min-width: 400px;">
					<input text
					id="txtFilter" #txtFilter class="qs_filter-field"
					[disabled]="this.isEditing"
					(keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
					<button mat-button matSuffix mat-icon-button
						matTooltip="Clear the filter" aria-label="Clear"
						(click)="txtFilter.value=''; clearFilter();">
						<mat-icon>close</mat-icon>
					</button></TD>
				<TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
				<TD style="width: 150px">

					<mat-select disableOptionCentering
						[(ngModel)]="selectedStatusFilter" #cbStatus floatLabel="never"
						style="font-size: 10pt;width: 120px;"
						panelClass="local-drop-down-panel" class="dw-mat-select"
						(selectionChange)="onChangeStatusFilterValue($event)"
						(keydown)="onStatusFilterKeyDown($event)">
						<mat-option *ngFor="let item of statusFilterList"
									[value]="item">{{item.description}}</mat-option>
					</mat-select>
				</TD>


				<TD class="table-pagination-cell" align="right">{{pagination}}
					Record(s) ...</TD>
			</TR>
		</table>
	</div>

	<mat-drawer-container autosize> <mat-drawer
		position="end" opened="true" class="mat-elevation-z2 drawer-form"
		style="border: .5px solid #e1e1e1" #drawer mode="side"
		disableClose="true">
	<div style="max-width: 455px; padding: 2px;">
		<!-- tab closed state -->
		<table [hidden]="!propContainerState"
			class="prop-notes-outer-container">
			<TR>
				<TD align="left" style="width: 35px; padding: 0;">
					<button id="contract-toggle-drawer-close-btn" mat-icon-button
						(click)="onPropertiesToggle()">
						<mat-icon>arrow_right</mat-icon>
					</button>
				</TD>
				<TD width="100%" style="text-align: left;"><b>Additional
						Properties</b></TD>
				<TD width="35px"></TD>
			</TR>
		</table>
		<!-- tab open State -->
		<div [hidden]="!propContainerState"
			style="width: 450px; height: calc(100% - 60px);">
			<mat-tab-group class="prop-tab-group"> 
				<mat-tab
				label="Suppliers" *ngIf="visibleSuppliersTab"> <app-medication-suppliers
				[Medication]="selectedRecord"></app-medication-suppliers> </mat-tab> 
				<mat-tab
				label="Images" *ngIf="visibleImagesTab"> <app-medication-images
				[Medication]="selectedRecord"></app-medication-images> </mat-tab> 
				<mat-tab
				label="Links" *ngIf="visibleLinksTab"> <app-medication-links
				[Medication]="selectedRecord"></app-medication-links> </mat-tab> </mat-tab-group>
		</div>
	</div>
	<div>
		<!-- retracted state -->
		<div [hidden]="propContainerState" width="100%">
			<button id="contract-toggle-drawer-open-btn" mat-icon-button
				(click)="onPropertiesToggle()">
				<mat-icon>arrow_left</mat-icon>
			</button>
		</div>

		<div class="rotate-text" [hidden]="propContainerState">
			Additional Properties</div>
	</div>
	</mat-drawer>

	<div class="main-client-area">
		<div #tb1 class="table-container mat-elevation-z1"
			(keydown)="tableKeyDown($event)" tabindex="-1">
			<table *ngIf="selectedViewFilter === 'List';" mat-table
				[dataSource]="dataSource" (keydown)="tableKeyDown($event)"
				(matSortChange)="onSortChange($event)" matSort>

				<!--- Note that these columns can be defined in any order.
		          The actual rendered columns are set as a property on
		          the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Id</th>
				<td mat-cell style="width: 5% !important;"
					*matCellDef="let element;let tableRowIdx = index;">
					{{element.id}}</td>
				</ng-container>

				<ng-container matColumnDef="itemPrefix">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Item Prefix</th>
				<td mat-cell style="width: 6% !important;" *matCellDef="let element">{{element.itemPrefix}}</td>
				</ng-container>

				<ng-container matColumnDef="manufacturedBy">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style">Manufacturer</th>
					<td mat-cell style="width: 15%;" *matCellDef="let element">{{element.manufacturedBy}}</td>
				</ng-container>

				<ng-container matColumnDef="origNdcNumber">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Orig NDC</th>
				<td mat-cell style="width: 8%;" *matCellDef="let element">{{element.origNdcNumber}}</td>
				</ng-container>

				<ng-container matColumnDef="schedule">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Schedule</th>
				<td mat-cell style="width: 3%; text-align: center;"
					*matCellDef="let element">{{element.schedule}}</td>
				</ng-container>

				<ng-container matColumnDef="lotPrefix">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Lot Number</th>
				<td mat-cell style="width: 8%;" *matCellDef="let element">{{element.lotPrefix}}</td>
				</ng-container>
				<ng-container matColumnDef="packageSize">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Pkg size</th>
				<td mat-cell style="width: 5%; text-align: center;"
					*matCellDef="let element">{{element.packageSize}}</td>
				</ng-container>

				<ng-container matColumnDef="awp">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">AWP</th>
				<td mat-cell style="text-align: right; padding-right: 75px; width: 5%; " *matCellDef="let element">$
					{{element.awp}}</td>
				</ng-container>

				<ng-container matColumnDef="activeStatus">
					<th mat-header-cell *matHeaderCellDef mat-sort-header
						class="mat-table-header-style">Status</th>
					<td mat-cell style="width: 10%;" *matCellDef="let element">{{element.activeStatus}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">
				</tr>

				<!-- Group header cells-->
				<ng-container matColumnDef="groupHeader">
				<td colspan="999" mat-cell *matCellDef="let group"><strong>{{group.groupName}}</strong>
				</td>
				</ng-container>
				<ng-container matColumnDef="groupReducer">
				<td colspan="9" mat-cell *matCellDef="let group"
					style="padding-left: 0px;">
					<div style="display: inline-flex;">
						<mat-icon *ngIf="!group.reduced" (click)="reduceGroup(group)"
							style="cursor: pointer;font-weight: bold;">arrow_drop_down</mat-icon>
						<mat-icon *ngIf="group.reduced" (click)="reduceGroup(group)"
							style="cursor: pointer;font-weight: bold;">arrow_right</mat-icon>
						<span style="padding-top: 5px; font-weight: bold;"
							[ngStyle]="{'marginLeft': group.depth==2 ? '.6rem':'0rem'}">{{group.groupName}}</span>
					</div>
				</td>
				</ng-container>

				<!-- Group line -->
				<tr #matrow mat-row class="clickable group-row"
					*matRowDef="let row; columns: groupColumns; when: isGroup; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					[attr.position]="row.id" (click)="onRowClicked(row)"
					(dblclick)="onRowDoubleClicked(row)">
				</tr>
			</table>
			<table *ngIf="selectedViewFilter === 'Card';" mat-table
				[dataSource]="dataSource" (matSortChange)="onSortChange($event)"
				matSort>

				<!--- Note that these columns can be defined in any order.
		          The actual rendered columns are set as a property on
		          the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header
					class="mat-table-header-style">Id</th>
				<td mat-cell style="width: 100% !important;"
					*matCellDef="let element;let tableRowIdx = index;">
					<div>
						<table>
							<tr>
								<td style="width: 90px;"><img
									*ngIf="element.containerImagePresent" style="width: 75px;"
									[src]="element.containerURL"> <img
									*ngIf="!element.containerImagePresent" style="width: 25px;"
									[src]="element.containerURL"></td>
								<td style="width: 90px;"><img
									*ngIf="element.tabletImagePresent" style="width: 75px;"
									[src]="element.tabletURL"> <img
									*ngIf="!element.tabletImagePresent" style="width: 25px;"
									[src]="element.tabletURL"></td>
								<td>
									<div>
										<table>
											<tr>
												<td style="width: 33%"><label
													style="width: 280px; font-size: 17px; font-weight: bold; margin-right: 5px;">{{element.itemPrefix}}</label>
												</td>
												<td style="width: 33%"><label
													style="margin-right: 6px; font-size: 12px;">Schedule:
												</label><label
													style="font-size: 12px; font-weight: bold; margin-right: 5px">{{element.schedule}}</label>
												</td>
												<td
													style="width: 33%; text-align: right; padding-right: 40px;">
													<mat-select [(ngModel)]="element.activeStatus"
														placeholder="- Please Select -" disableOptionCentering
														floatLabel="never" panelClass="local-drop-down-panel"
														class="dw-mat-select"
														style="width: 120px;font-size: 10pt;">
														<mat-option value="Active">Active</mat-option>
														<mat-option value="InActive">InActive</mat-option>
														<mat-option value="Deleted">Deleted</mat-option> </mat-select>
												</td>
										</table>
									</div>
									<div style="margin-left: 4px;">
										<label style="font-weight: bold; font-size: 14px;">{{element.handle}}</label>
									</div>
									<table>
										<tr>
											<td style="width: 33%"><label
												style="margin-right: 10px;">ORIG NDC:</label><label
												style="font-weight: bold; margin-right: 30px;">{{element.origNdcNumber}}</label>
											</td>
											<td style="width: 33%"></td>
											<td style="width: 33%"><label
												style="margin-right: 10px;">LOT No:</label><label
												style="font-weight: bold; padding-right: 100px;">{{element.lotPrefix}}</label>
											</td>
									</table>
								</td>
							</tr>
						</table>
					</div>

				</td>
				</ng-container>

				<tr mat-header-row
					*matHeaderRowDef="displayedColumnsCard sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumnsCard; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">

				</tr>
			</table>
		</div>
		<div class="mat-elevation-z2 control-strip">
			<button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
				class="command-button" matTooltip="Add a new record"
					matTooltipPosition="above"
				(click)="addModifyRecord(true)" mat-button>
				<mat-icon>add_circle_outline</mat-icon>
				Add New
			</button>
			<button *ngIf="visibleModify" mat-button class="command-button"
				style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
				matTooltip="Update a selected record"
					matTooltipPosition="above"
				(click)="addModifyRecord(false)" mat-button>
				<mat-icon>edit</mat-icon>
				Update
			</button>
			<button *ngIf="visibleDelete" mat-button class="command-button"
				style="margin-top: 5px;" [disabled]="(Idx < 0 || this.isEditing)"
				matTooltip="Remove a selected record" (click)="removeRecord()"
					matTooltipPosition="above"
				mat-button>
				<mat-icon>remove_circle_outline</mat-icon>
				Remove
			</button>
		</div>
		<div id="medication-details-panel"
			class="medication-inv-container mat-elevation-z2">
			<mat-tab-group animationDuration="0ms"
				(selectedTabChange)="doTabChange($event)"
				[(selectedIndex)]=selectedTabIndex> <mat-tab
				label="Details" [disabled]="isEditing">
			<div class="sub-tab-container-class detail-tab-additional">
				<div class="dwform" style="padding-right: 15px;">

					<div class="dwform-cell-nopadding" style="min-width: 500px;">
						<div class="dwform-row-double-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Record ID:</label> <span
								class="form-required"> </span> <input class="input-field-short"
								style="width: 80px" disabled="true" [(ngModel)]="valId"
								type="text"> <label
								style="margin-left: 37px; margin-right: 67px;">Schedule</label>
							<span class="form-required">*</span> <input style="width: 55px;"
								type="number" id="schedule" [(ngModel)]="valSchedule" step="1"
								min="1" max="5" [disabled]="!isEditing">
						</div>

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<!-- 
											<label class="label-field">Item Prefix:</label> <span
												class="form-required">*</span>
												<p-inputMask #ndcNumber id="ndcNumber" [style]="{'width':'100px', 'background': isEditing?'white':'inherit'}" [styleClass]="'input-field'" [disabled]="!isEditing"  [(ngModel)]="valItemPrefix"
													   placeholder="9999-99" mask="9999-99" type="text"></p-inputMask>
												 -->
							<label style="margin-left: 0px; margin-right: 20px;">Orig
								NDC Number:</label> <span class="form-required">*</span>
							<p-inputMask #omNDCNumber id="omNDCNumber"
								[style]="{'background': isEditing?'white':'inherit'}"
								[(ngModel)]="valOMNDCNumber" placeholder="99999-999-99"
								mask="99999-999-99" [disabled]="!isEditing"></p-inputMask>
						</div>

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Medication Family:</label> <span
								class="form-required">*</span>
							<p-autoComplete id="medicationFamily" #medicationFamily
								[disabled]="!isEditing" appendTo="body"
								[suggestions]="filteredfamilyStringList"
								[(ngModel)]="valMedicationFamilyItemNumber"
								(onBlur)="medicationFamilySelectionchanged()"
								(completeMethod)="filtermedicationFamilyList($event)"
								(keydown.enter)="medicationFamily.hide();"
								(onSelect)="medicationFamilySelectionchanged()"
								(keydown.backspace)="filtermedicationFamilyList($event)"
								(click)="filtermedicationFamilyList($event)"> </p-autoComplete>
							<label *ngIf="!itemNumberInvalidFlag"
								style="font-weight: 600; padding-left: 10px">{{this.valShortDesc}}</label>
							<label *ngIf="itemNumberInvalidFlag"
								style="font-weight: 600; padding-left: 10px; color: red">{{this.valShortDesc}}</label>
						</div>
						<div class="dwform-row-gap"></div>


						<div class="dwform-row">
							<label class="label-field">Description:</label> <span
								class="form-required" style="display: table-cell; vertical-align: top"></span>

							<textarea class="input-field" placeholder="<description>"
								[disabled]="!isEditing"
								style="font-family: Roboto; height: 60px; width: 357px; resize: none"
								[(ngModel)]="valDesc"></textarea>
						</div>

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Status:</label> <span
								class="form-required">*</span>

							<mat-select [disabled]="!isEditing" [(ngModel)]="valStatus"
								id="status" placeholder="- Please Select -"
								disableOptionCentering floatLabel="never"
								panelClass="local-drop-down-panel" class="dw-mat-select"
								style="width: 167px;font-size: 10pt;"
								[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
							<mat-option value="Active">Active</mat-option> <mat-option
								value="InActive">InActive</mat-option> </mat-select>
						</div>
						<div class="dwform-row-gap"></div>
					</div>

					<div class="dwform-cell">
						<div class="dwform-row">
							<label class="label-field">Generic/Brand:</label> <span
								class="form-required"></span>
							<mat-select [disabled]="!isEditing"
								placeholder="- Please Select -" disableOptionCentering
								floatLabel="never" [(ngModel)]="valGenricOrBrand"
								panelClass="local-drop-down-panel" class="dw-mat-select"
								style="width: 200px;font-size: 10pt;"
								[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
							<mat-option value="Generic">Generic</mat-option> <mat-option
								value="Brand">Brand</mat-option> </mat-select>
						</div>
						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Pkg Size:</label> <span
								class="form-required">*</span> <input class="input-field"
								[disabled]="!isEditing" id="packagesize" placeholder="<size>"
								[(ngModel)]="valPkgSize" type="text" style="width: 200px">
						</div>
						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Units:</label> <span
								class="form-required"></span>
							<mat-select [disabled]="!isEditing"
								placeholder="- Please Select -" disableOptionCentering
								floatLabel="never" [(ngModel)]="valUnit"
								panelClass="local-drop-down-panel" class="dw-mat-select"
								style="width: 200px;font-size: 10pt;"
								[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
							<mat-option value="Tablets">Tablets</mat-option> <mat-option
								value="Strips">Strips</mat-option> <mat-option value="Strips">Box</mat-option>
							</mat-select>
						</div>
						<div class="dwform-row-gap"></div>
						<div class="dwform-row">
							<label class="label-field">Lot Number:</label> <span
								class="form-required"></span> <input class="input-field"
								[disabled]="!isEditing" placeholder="<lot number>"
								[(ngModel)]="valLotNumber" type="text" style="width: 200px">
						</div>

						<div class="dwform-row-gap"></div>

						<div class="dwform-row">
							<label class="label-field">Compare To:</label> <span
								class="form-required"></span> <input class="input-field"
								style="width: 357px; text-transform: uppercase"
								[disabled]="!isEditing" [(ngModel)]="valCompareTo"
								placeholder="<compare to>" type="text" style="width: 200px">
						</div>

						<div class="dwform-row-gap"></div>
					</div>
				</div>
				<!-- ends the form -->
				<div [hidden]='!strErr.length' style="left: 10px;"
					class="error-line">{{strErr}}</div>
				<!-- save strip -->
				<div class="save-close-strip" style="bottom: 5px;">

					<button mat-button class="command-button" *ngIf="this.isEditing"
						(click)="submitRecord()" matTooltip="Submit the selected record"
						mat-button>
						<mat-icon>check_circle_outline</mat-icon>
						Submit
					</button>
					<button mat-button class="command-button" *ngIf="this.isEditing"
						(click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
						<mat-icon>highlight_off</mat-icon>
						Cancel
					</button>
				</div>
			</div>
			</mat-tab> <mat-tab label="Manufacturer">
			<div class="sub-tab-container-class detail-tab-additional">
				<div class="sub-tab-container-class detail-tab-additional">
					<div class="dwform" style="padding-right: 15px;">
						<div class="dwform-cell">
							<div class="dwform-row-gap"></div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Manufactured By:</label> <span
									class="form-required"></span>

								<mat-select [disabled]="!isEditing"
									id="medicationManufacturedBy" #medicationManufacturedBy
									placeholder="- Please Select -" disableOptionCentering
									floatLabel="never" panelClass="local-drop-down-panel"
									class="dw-mat-select" style="width: 230px;font-size: 10pt;"
									[style.backgroundColor]="disabledFlag?'whitesmoke':'white'"
									[(ngModel)]="valManufacturedBy"> <mat-option
									*ngFor="let manufacturer of manufacturerList"
									[value]="manufacturer">{{ manufacturer }}</mat-option> <mat-option
									[value]="'other'">- Add New -</mat-option> </mat-select>
								<ng-container *ngIf="medicationManufacturedBy.value === 'other'">
								<input type="text" style="width: 230px; font-size: 10pt;"
									(focusout)="newManufacturerAddedHandler($event)"> </ng-container>
							</div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Manufactured For:</label> <span
									class="form-required"></span> <input class="input-field"
									[disabled]="!isEditing" placeholder="<manufactured for>"
									[(ngModel)]="valManufacturedFor" type="text"
									style="width: 200px">
							</div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Storage Instructions:</label> <span
									class="form-required"></span>
								<textarea class="input-field s"
									placeholder="<storage instructions>" [disabled]="!isEditing"
									style="font-family: Roboto; vertical-align:top; height: 60px; width: 357px; resize:
										  none"
									[(ngModel)]="valStorageInstructions"></textarea>
							</div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Cost Base:</label> <span
									class="form-required"></span>
								<p-inputNumber [inputStyleClass]="'input-field'"
											   [disabled]="!isEditing" placeholder="<price>"
											   [(ngModel)]="valCostBase" mode="currency" currency="USD"
											   locale="en-US" type="text"
											   [inputStyle]="{'width': '200px', 'background': isEditing?'white':'inherit'}">

								</p-inputNumber>
							</div>
							<div class="dwform-row-gap"></div>
							<div class="dwform-row">
								<label class="label-field">Avg Wholesale Price:</label> <span
									class="form-required"></span>
								<p-inputNumber [inputStyleClass]="'input-field'"
									[disabled]="!isEditing" placeholder="<price>"
									[(ngModel)]="valAWP" mode="currency" currency="USD"
									locale="en-US" type="text"
									[inputStyle]="{'width': '200px', 'background': isEditing?'white':'inherit'}">

								</p-inputNumber>
							</div>
						</div>
					</div>
					<!-- ends the form -->
					<div [hidden]='!strErr.length' style="left: 10px;"
						class="error-line">{{strErr}}</div>
					<!-- save strip -->
					<div class="save-close-strip" style="bottom: 5px;">

						<button mat-button class="command-button" *ngIf="this.isEditing"
							(click)="submitRecord()" matTooltip="Submit the selected record"
							mat-button>
							<mat-icon>check_circle_outline</mat-icon>
							Submit
						</button>
						<button mat-button class="command-button" *ngIf="this.isEditing"
							(click)="cancelSubmitRecord()" matTooltip="Cancel edit"
							mat-button>
							<mat-icon>highlight_off</mat-icon>
							Cancel
						</button>
					</div>
				</div>
			</div>
			</mat-tab> <mat-tab label="History" [disabled]="isEditing">
			<div class="sub-tab-container-class detail-tab-additional">
				<transaction-history #history></transaction-history>
			</div>
			</mat-tab> </mat-tab-group>
		</div>
	</div>
	</mat-drawer-container>
</div>
