export interface FormularyMedicationStatus {
	id: number;
	statusDate: Date | null;
	statusBy: string | null;
	status: string | null;
	statusComment: string | null;
	followUpDate: Date | null;
	approvalFileLocation: string | null;
	formularyMedicationId: number;
	
	documentURL: string;
}
export class FormularyMedicationStatus {
	constructor() {
		this.id = 0;
	}
}


