<!--
    file type .....: HTML file: addorder.component.html
    Created By ....: ianday
    Date ..........: 6/21/23 
-->
<div class="addorder-container">
    <div class="mat-elevation-z2"
         style="width: 100%; height:222px;padding: 15px;padding-top:2px;">

        <!-- COLUMN 1 -->
       <div class="dwform">
        <!-- ORDER NUMBER -->
        <label class="label-field">Order Number:</label>
        <span class="form-required"> </span>
        <label class="label-field-data" style="font-weight: bold">
            {{selectedOrder.orderNumber}}
        </label>

           <div class="dwform-row-gap" > </div>
            <!-- CUSTOMER NAME -->
            <div *ngIf="this.addEditFlag">
            <label class="label-field">Customer:</label>
            <span class="form-required">*</span>
            <input class="input-field" id="customerId" matInput [readonly]="!this.addEditFlag"
                   [ngModel]="selectedCustomer?.customerName" ngDefaultControl
                   [formControl]="customerControl"
                   [matAutocomplete]="autoCustomer"
                   style="text-transform: capitalize; width:230px;"
                       placeholder="<Customer>" type="text">
                <mat-autocomplete autoActiveFirstOption
                                  (optionSelected)="customerSelectVal($event)"
                                  class="customer-auto-complete"
                                  #autoCustomer="matAutocomplete" >
                    <mat-option [ngClass]="'mat-option-class'"
                                *ngFor="let customer of filteredCustomerOptions | async "
                                [value]="customer">{{customer}}
                    </mat-option>
                </mat-autocomplete>

            <button mat-button style="width: 20px!important;scale: 80%;"
                    (click)="doClearCustomer()"
                    matTooltip="Clear entry"><mat-icon>highlight_off</mat-icon>
            </button>
            </div>

           <div *ngIf="this.addEditFlag===false">
               <label class="label-field">Customer:</label>
               <span class="form-required"></span>
               <label class="label-field-data" style="font-weight: bold;padding-top:5px">
                   {{selectedCustomer?.customerName}}
               </label>

           </div>


           <div class="dwform-row-gap"></div>
           <!-- ORDER BY NAME -->
           <label class="label-field" >Contact:</label>
           <span class="form-required">*</span>
           <mat-select id="contactId" #cbContact
                       [ngModel]="selectedContact" ngDefaultControl
                       [disabled]="this.selectedCustomer?.id === 0"
                       disableOptionCentering
                       style="text-transform: capitalize; width:230px;"
                       panelClass="local-drop-down-panel" class="dw-mat-select"
                       (keydown)="onContactDropDownKeyDown($event)"
                       (selectionChange)="onContactSelected($event)"
                       placeholder="<Contacts>" type="text">
               <mat-option *ngFor="let contact of contactsList"
                           [value]="contact">{{contact.fullName}}
               </mat-option>
           </mat-select>

           <button mat-button style="width: 20px!important; scale: 80%;"
                   [disabled]="this.selectedCustomer?.id === 0"
                   (click)="quickAddContact()"
                   matTooltip="Add a contact"><mat-icon>add_circle_outline</mat-icon></button>

           <div class="dwform-row-gap"></div>
           <label class="label-field" >Email:</label>
           <span class="form-required"> </span>
           <label class="label-field label-field-bold">{{selectedContact?.emailAddress}}</label>

           <div class="dwform-row-gap" style="height: 22px;"></div>

           <label class="label-field" >Telephone:</label>
           <span class="form-required"> </span>
           <label class="label-field" style="width: 280px; font-size: 9pt">
               <b>{{selectedContact?.telephone}}</b> /
               Cell: <b>{{selectedContact?.cellPhone}}</b>
           </label>

           <!--COLUMN 2 -->
           <div class="dwform-cell">

           <div class="dwform-row-gap"> </div>
           <label class="label-field">Status:</label>
           <span class="form-required"> </span>
           <label class="label-field-data" style="font-weight: bold">
               {{selectedOrder.orderStatus}}
           </label>

           <div class="dwform-row-gap" style="height: 22px;"> </div>
           <label class="label-field">Date Created:</label>
           <span class="form-required"> </span>
           <label class="label-field-data" style="font-weight: bold">
                 {{lblDateFmt(selectedOrder.dateCreated)}}
           </label>

            <!-- Customer Reference -->
            <div class="dwform-row-gap" style="height: 22px;"> </div>
            <label class="label-field">Reference:</label>
            <span class="form-required">*</span>
            <input class="input-field" id="customerReference" matInput
                   ngDefaultControl
                   [formControl]="customerReferenceControl"
                   style="text-transform: capitalize; width:300px;"
                   placeholder="<Reference>" type="text">
            <!-- COMMENTS -->
           <div class="dwform-row-gap" style="height: 22px;"> </div>
           <label class="label-field" style="vertical-align: top">Comments:</label>
           <span class="form-required">*</span>
           <TextArea class="input-field" id="orderComments" matInput
                  ngDefaultControl
                  [formControl]="orderCommentsControl"
                  style="text-transform: capitalize; width:300px;font-family: Roboto;
                    resize:none; height: 60px;"
                  placeholder="<Comments>" type="text">
           </TextArea>
            </div>
       </div>
    </div>
    <!-- CONTROL STRIP -->
    <div class="mat-elevation-z2 control-strip">
        <button mat-button [disabled]="selectedContact?.id === 0"
                class="command-button" matTooltip="Add a new record"
                matTooltipPosition="right"
                (click)="lookupItem()" mat-button>
            <mat-icon>search</mat-icon>
            Lookup
        </button>
        <button mat-button class="command-button"
                matTooltipPosition="right"
                style="margin-top: 5px;" [disabled]="(Idx < 0)"
                matTooltip="remove a selected row"
                (click)="removeItem(selectedOrderItem.id)"
                mat-button>
            <mat-icon>remove_circle_outline</mat-icon>
            Remove
        </button>

    </div>
    <!-- ITEMS LIST (was 215)-->
    <div #tb2 style="width:100%; height:215px;overflow: auto"
        class="mat-elevation-z1" (keydown)="tableKeyDown($event)" tabindex="-1">
        <table mat-table [dataSource]="dataSource" matSort style="width: 100%;outline:none!important;"
               (matSortChange)="onSortChange($event)">
            <!-- ITEM NUMBER -->
            <ng-container matColumnDef="itemPrefix">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Item Prefix
                </th>
                <td mat-cell class="mat-table-detail-style"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemPrefix}}</td>
            </ng-container>
            <!-- DESCRIPTION -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:300px;">Description
                </th>
                <td mat-cell class="mat-table-detail-style"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.description}}</td>
            </ng-container>
            <!-- QUANTITY -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:50px;">Qty
                </th>
                <td mat-cell class="mat-table-detail-style"
                    *matCellDef="let element;let tableRowIdx = index;">
                    <input *ngIf="selectedOrderItem?.id == element.id"
                        matInput type="number" id="idQty" min="1"
                        style="width:50px; border: .5px solid gray"
                        (change)="doQtyChange($event, tableRowIdx)"
                        [value]="element.quantity">

                    <span *ngIf="selectedOrderItem?.id !== element.id"
                          style="padding-left: 3px;">{{element.quantity}}</span>
                </td>
            </ng-container>
            <!-- UNIT PRICE -->
            <ng-container matColumnDef="unitPrice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Unit Price
                </th>
                <td mat-cell class="mat-table-detail-style"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{lblCurrencyFmt(element.unitPrice)}}</td>
            </ng-container>
            <!-- EXTENDED PRICE -->
            <ng-container matColumnDef="extendedPrice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Extended Price
                </th>
                <td mat-cell class="mat-table-detail-style" style="padding-left:15px"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{lblCurrencyFmt(element.extendedPrice)}}</td>
            </ng-container>
            <!-- ItemStatus -->
            <ng-container matColumnDef="itemStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true"
                    class="mat-table-header-style" style="width:100px;">Extended Price
                </th>
                <td mat-cell class="mat-table-detail-style" style="padding-left:15px" [hidden]="true"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemStatus}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (click)="onRowClicked(row)">
            </tr>
        </table>
    </div>

    <!-- ADDRESSES / SHIPPING AND TOTALS -->
    <div style="width:100%; height:170px; background-color: #e1e1e1">

        <div class="bottom-section" style="width: 60%; border-right: .5px solid white;">
          <mat-tab-group >
               <mat-tab label="Ship To Address">
                   <div class="dwform" style="height:138px; padding: 8px;">
                       <label class="label-field">Ship To Address: </label>
                       <span class="form-required">*</span>
                       <mat-select id="shipToAddress" #cbShipTo
                                   [ngModel]="selectedShipToAddress" ngDefaultControl
                                   [disabled]="this.selectedCustomer?.id === 0"
                                   disableOptionCentering
                                   style="text-transform: capitalize; width:180px;"
                                   panelClass="local-drop-down-panel" class="dw-mat-select"
                                   (keydown)="onShipToDropDownKeyDown($event)"
                                   (selectionChange)="onShipToSelected($event)"
                                   placeholder="<Addresses>" type="text">
                           <mat-option *ngFor="let ad of addressesList"
                                       [value]="ad">{{ad.entityDescription}}
                           </mat-option>
                       </mat-select>
                       <button mat-button style="width: 20px!important; scale: 80%;"
                               [disabled]="this.selectedCustomer?.id === 0"
                               (click)="quickAddAddress(false)"
                               matTooltip="Add an address"><mat-icon>add_circle_outline</mat-icon></button>

                       <!-- DISPLAY Address -->
                       <div class="dwform-row-gap"></div>
                       <label class="label-field"></label>
                       <span class="form-required"></span>
                       <label class="label-field-data-bold">{{this.selectedShipToAddress?.address1}}</label>

                       <div *ngIf="this.selectedShipToAddress?.address2?.length > 0">
                           <div class="dwform-row-gap"></div>
                           <label class="label-field"></label>
                           <span class="form-required"></span>
                           <label class="label-field-data-bold">{{this.selectedShipToAddress?.address2}}</label>
                       </div>

                       <div class="dwform-row-gap" > </div>
                       <label class="label-field"> </label>
                       <span class="form-required"> </span>
                       <label class="label-field-data-bold">{{this.selectedShipToAddress?.city}}
                           {{this.selectedShipToAddress?.state}}
                           {{this.selectedShipToAddress?.zipCode}}
                       </label>
                   </div>
               </mat-tab>
               <mat-tab label="Bill To Address">
                   <div class="dwform" style="height:138px; padding: 5px; padding-top:8px;">
                       <label class="label-field">Bill To Address: </label>
                       <span class="form-required">*</span>
                       <mat-select id="billToAddress" #cbShipTo
                                   [ngModel]="selectedBillToAddress" ngDefaultControl
                                   [disabled]="this.selectedCustomer?.id === 0"
                                   disableOptionCentering
                                   style="text-transform: capitalize; width:180px;"
                                   panelClass="local-drop-down-panel" class="dw-mat-select"
                                   (keydown)="onBillToDropDownKeyDown($event)"
                                   (selectionChange)="onBillToSelected($event)"
                                   placeholder="<Addresses>" type="text">
                           <mat-option *ngFor="let ad of addressesList"
                                       [value]="ad">{{ad.entityDescription}}
                           </mat-option>
                       </mat-select>
                       <button mat-button style="width: 20px!important; scale: 80%;"
                               [disabled]="this.selectedCustomer?.id === 0"
                               (click)="quickAddAddress(true)"
                               matTooltip="Add an address"><mat-icon>add_circle_outline</mat-icon></button>

                       <!-- DISPLAY Address -->
                       <div class="dwform-row-gap"></div>
                       <label class="label-field"></label>
                       <span class="form-required"></span>
                       <label class="label-field-data-bold">{{this.selectedBillToAddress?.address1}}</label>

                       <div *ngIf="this.selectedBillToAddress?.address2 !== ''">
                           <div class="dwform-row-gap"></div>
                           <label class="label-field"></label>
                           <span class="form-required"></span>
                           <label class="label-field-data-bold">{{this.selectedBillToAddress?.address2}}</label>
                       </div>

                       <div class="dwform-row-gap" > </div>
                       <label class="label-field"> </label>
                       <span class="form-required"> </span>
                       <label class="label-field-data-bold">{{this.selectedBillToAddress?.city}}
                           {{this.selectedBillToAddress?.state}}
                           {{this.selectedBillToAddress?.zipCode}}
                       </label>
                   </div>
               </mat-tab>
               <mat-tab label="Shipping Method">
                   <div class="dwform" style="height:138px; padding: 8px;">
                       <label class="label-field">Shipping Method: </label>
                       <span class="form-required"> </span>
                       <mat-select id="shippingMethod" #cbShipMethod
                                   [ngModel]="selectedShippingMethod" ngDefaultControl
                                   [disabled]="this.selectedCustomer?.id === 0"
                                   disableOptionCentering
                                   style="text-transform: capitalize; width:300px;"
                                   panelClass="local-drop-down-panel" class="dw-mat-select"
                                   (keydown)="onShipMethodDropDownKeyDown($event)"
                                   (selectionChange)="onShipMethodSelected($event)"
                                   placeholder="<Shipping Method>" type="text">
                           <mat-option *ngFor="let sm of shippingMethodsList"
                                       [value]="sm">{{sm.description}}
                           </mat-option>
                       </mat-select>
                   </div>
               </mat-tab>

           </mat-tab-group>
        </div>

        <!-- Grand Totals / Shipping and all the other gumpf! -->
        <div class="dwform bottom-section" style="width: 40%; text-align:right" >

            <!-- ORDER TOTAL-->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Order Total:</label>
            <span class="form-required"> </span>
            <input class="input-field" id="orderTotal" matInput [readonly]="true"
                   [formControl]="orderTotalControl"
                   ngDefaultControl
                   style="width:120px;background-color: #e1e1e1"
                   placeholder="<Order Total>" type="text">

            <!-- SALES TAX -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Sales Tax:</label>
            <span class="form-required"> </span>
            <p-inputNumber [inputStyleClass]="'input-field'" id="salesTax"
                   mode="currency" currency="USD" locale="en-US" type="text"
                   ngDefaultControl
                   [formControl]="salesTaxControl"
                   (onInput)="runSalesTaxInputEvent($event)"
                   [inputStyle]="{'width':'120px'}"
                   placeholder="<Sales Tax>">
            </p-inputNumber>

            <!-- SHIPPING -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Shipping:</label>
            <span class="form-required"> </span>
            <p-inputNumber [inputStyleClass]="'input-field'" id="shipping"
                   mode="currency" currency="USD" locale="en-US" type="text"
                   (onInput)="runShippingTotalInputEvent($event)"
                   ngDefaultControl
                   [formControl]="shippingTotalControl"
                   [inputStyle]="{'width':'120px'}"
                   placeholder="<Shipping>">
            </p-inputNumber>

            <!-- GRAND TOTAL -->
            <div class="dwform-row-gap"></div>
            <label class="label-field">Grand Total:</label>
            <span class="form-required"> </span>
            <input class="input-field" id="gandTotal" matInput
                   [readonly]="true"
                   ngdefaultControl
                   [formControl]="grandTotalControl"
                   style="text-transform: capitalize; width:120px;background-color: #e1e1e1"
                   placeholder="<Grand Total>" type="text">
        </div>

    </div>
    <!------------------------->
    <!---- ERROR LINE --------->
    <!------------------------->
    <div class="error-line" [hidden]='!strErr.length'
         style="text-align:left; bottom: 47px; left:15px;">{{strErr}}</div>
    <!------------------------->
    <!-- SAVE / CANCEL STRIP -->
    <!------------------------->
    <div style="text-align:center; margin-top: 20px;">

        <button mat-button class="command-button"
                (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>

<p-dialog header="New Contact"
          [(visible)]="displayAddContactDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '605px', height: '352px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
    <app-add-contact #addContact
                     [parentId]="selectedCustomer?.id"
                     [callingComponent]="'customers'"
                     (cancelAddContact)="displayAddContactDlg=false"
                     (submitAddContact)="newContactCreated($event)">
    </app-add-contact>
</p-dialog>
<p-dialog header="New Address"
          [(visible)]="displayAddAddressDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '605px', height: '352px'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
    <app-add-address #addAddress
                     [parentId]="selectedCustomer?.id"
                     [callingComponent]="'customers'"
                     (cancelAddAddress)="displayAddAddressDlg=false"
                     (submitAddAddress)="newAddressCreated($event)">
    </app-add-address>
</p-dialog>

<p-dialog header="Look Up Item"
          [(visible)]="displayLookupItemDlg"
          [appendTo]="'body'"
          [modal]="true" [responsive]="true"
          [style]="{width: '850px', height: '700px', overflow: 'hidden'}"
          [minY]="75"
          [resizable]="false" [closable]="true">
    <app-lookupitem #lkupItem
        [customerId]="selectedCustomer?.id"
        (submitClicked)="lookupItemSubmitted($event)"
        (cancelClicked)="displayLookupItemDlg=false">
    </app-lookupitem>
</p-dialog>


