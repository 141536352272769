import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormularyMaterials } from 'src/app/models/formulary-material';
import { FormularyMedication } from 'src/app/models/formulary-medication';
import { FormularyMedicationStatus } from 'src/app/models/formulary-medication-status';
import { Material } from 'src/app/models/material';
import { FormularyService } from 'src/app/services/formulary.service';
import { Settings } from 'src/app/services/settings';
import { FormularyMainComponent } from '../../formulary-main.component';
@Component({
	selector: 'app-add-material',
	templateUrl: './add-material.component.html',
	styleUrls: ['./add-material.component.css']
})
export class AddMaterialComponent {

	_formularyMedication: FormularyMedication;
	_materialsList: Material[];
	_formularyMaterialsList: FormularyMaterials[];
	valMaterial: Material;
	valDescription = '';
	valItemType = '';
	disabledFlag = false;
	strErr = '';
	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter();
	@Input() set formularyMaterialsList(value: any) {
		this._formularyMaterialsList = value;
	}
	@Input() set formularyMedication(value: any) {
		this._formularyMedication = value;
		if (this._formularyMedication) {
			this.onShowHandeler();
		}
	}
	@Input() set materialsList(value: any) {
		this._materialsList = value;
		if (this._formularyMedication) {
			this.onShowHandeler();
		}
	}
	constructor(private formularyService: FormularyService) {

	}
	onShowHandeler() {
		this.valMaterial = undefined;
		this.valDescription = '';
		this.valItemType = '';
	}
	fetchDetails() {
		if (this.valMaterial) {
			this.valDescription = this.valMaterial.description;
			this.valItemType = this.valMaterial.itemType;
		}
	}
	validate() {
		if (!this.valMaterial) {
			this.strErr = 'Please select material';
			return false;
		}
		if (this.isDuplicateMaterial(this.valMaterial)) {
			this.strErr = 'This item has already been selected';
			return false;
		}
		return true;
	}
	isDuplicateMaterial(material: Material): boolean {
		for (const rec of this._formularyMaterialsList) {
			if (rec.materialId === material.id) {
				return true;
			}
		}
		return false
	}

	doSubmit() {
		if (!this.validate()) {
			return;
		}
		let record = new FormularyMaterials();
		record.formularyMedicationId = this._formularyMedication.id;
		record.customerId = this._formularyMedication.customerId;
		record.materialId = this.valMaterial.id;

		this.formularyService.createFormularyMaterial(record).subscribe(
			(data2) => {
				this.strErr = '';
				this.submitClicked.emit();
			},
			(error) => {
			}
		);
	}
	doCancel() {
		this.cancelClicked.emit();
	}
}
