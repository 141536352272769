import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MedicationService } from '../../../../../../services/medication.service';
import { Link } from '../../../../../..//models/link';
import { DwUtils } from '../../../../../../components/utils/dw-utils';
import { Medication } from 'src/app/models/medication';
import { Settings } from 'src/app/services/settings';
import { MedicationImage } from 'src/app/models/medicationimage';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-addimage',
	templateUrl: './addimage.component.html',
	styleUrls: ['./addimage.component.css']
})
export class AddimageComponent implements OnInit {

	public record: Link;
	public _selectedRecord: MedicationImage;
	public _medication: Medication;
	public id = 0;
	public valDescription = '';
	public valURL = '';
	public strErr = '';
	public stateControl = new FormControl('');
	public addEditFlag: boolean;
	public selectedImage: MedicationImage;
	public selectedFile: any;
	public previewSRC: any;

	selectedImageType: any;

	@Output() cancelClicked = new EventEmitter();
	@Output() submitClicked = new EventEmitter<Link>();
	@Input() set selectedRecord(value: any) {
		this._selectedRecord = value;
		this.onShowHandler();
	}

	get selectedRecord(): any {
		return this._selectedRecord;
		this.onShowHandler();
	}

	@Input() set medication(value: any) {
		this._medication = value;
	}

	get medication(): any {
		return this._medication;
	}

	constructor(private medicationService: MedicationService, private sanitizer: DomSanitizer) {

	}

	ngOnInit() {

	}
	onShowHandler() {

	}
	doCancel() {
		this.cancelClicked.emit();
	}
	doSubmit() {

		if (this.selectedImageType === undefined || this.selectedImageType === '') {
			this.strErr = 'Error: Please select Image type';
			return false;
		}
		if (this.selectedFile === undefined || this.selectedFile.name === '') {
			this.strErr = 'Error: Please select Image';
			return false;
		}
		this.medicationService.createMedicationImage(this.selectedImage, this.selectedFile).subscribe(
			(result) => {
				this.submitClicked.emit();
			},
			(error) => {
				Settings.getInstance().handleError(error);
			}
		);
		setTimeout(() => {
			this.submitClicked.emit();
		}, 500);
	}
	onChangeImageType(event) {
		this.selectedImageType = event;
	}
	onFileSelectedHandler(file: any) {
		this.selectedFile = file;
		if (this.selectedFile === undefined || this.selectedFile === null) {
			return;
		}
		const str = URL.createObjectURL(file);
		this.previewSRC = this.sanitizer.bypassSecurityTrustResourceUrl(str);
		if (this.selectedImage === undefined) {
			this.selectedImage = new MedicationImage();
			this.selectedImage.activeFlag = 1;
			this.selectedImage.medicationId = this._medication.id;
			this.selectedImage.activeFlag = 1;
			this.selectedImage.imageType = this.selectedImageType;

		}
	}

}


