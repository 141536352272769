import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { ChartDetails } from '../../../models/chart-details';
import { MetricCard } from '../../../models/metric-card';
import { UserService } from '../../../services/user.service';
import { Settings } from 'src/app/services/settings';
import {SitesService} from '../../../services/sites.service';
import { Sites } from '../../../models/sites';
import {HomeService} from '../../../services/home.service';

@Component( {
    selector: 'app-home-renderer',
    templateUrl: './home-renderer.component.html',
    styleUrls: ['./home-renderer.component.css']
} )
export class HomeRendererComponent implements OnInit {

    @Input() _totalMetricCardArr: Array<MetricCard>;
    @Input() _totalChartArr: Array<ChartDetails>;

    public chartMetaData: any;
    public metricCardArr: Array<MetricCard>;
    public chartArr: Array<ChartDetails>;

    public selectedMetricCards: Array<number>;
    public selectedCharts: Array<number>;
    public siteIdx = 0;
    public panelOpen: boolean | null = false;

    public sitesList: any[];
    public selectedSite: Sites;
    public activeMetricCardList: any[];
    public activeChartList: any[];

    private refreshEvent: EventEmitter<boolean> = new EventEmitter();

    @Input() set totalMetricCardArr( value: Array<MetricCard> ) {
        this._totalMetricCardArr = value;
        this.homeResetHandler( null );
    }

    get totalMetricCardArr(): Array<MetricCard> {
        return this._totalMetricCardArr;
    }

    @Input() set totalChartArr( value: Array<ChartDetails> ) {
        this._totalChartArr = value;
        this.homeResetHandler( null );
    }

    get totalChartArr(): Array<ChartDetails> {
        return this._totalChartArr;
    }

    constructor(private userService: UserService,
                public sitesService: SitesService,
                public homeService: HomeService) {

        this.selectedSite = new Sites();
        this.siteIdx = 0;
    }

    ngOnInit() {

        if (Settings.getInstance().loggedInUser.dashboard_settings === null || Settings.getInstance().loggedInUser.dashboard_settings === undefined) {
			this.activeMetricCardList = null;
			this.activeChartList = null;
		}
        else {

            const dashboardSetup = JSON.parse(Settings.getInstance().loggedInUser.dashboard_settings);
	    	const activeMetricCards = dashboardSetup.active_home_metric_cards;
	    	const activeCharts = dashboardSetup.active_home_charts;

	    	if(activeMetricCards === null || activeMetricCards === undefined) {
	    		this.activeMetricCardList = null;
	    	} else {
	    		this.activeMetricCardList = activeMetricCards.split(',');
	    	}
	    	if(activeCharts === null || activeCharts === undefined) {
	    		this.activeChartList = null;
	    	} else {
	    		this.activeChartList = activeCharts.split(',');
	    	}
    	}
		this.querySiteRecords();
        this.reloadRecords();
    }
    public reloadRecords(): void {
        this.queryOrderMetricCardRecords();
        this.queryPurchaseOrderMetricCardRecords();
    }
    public resetCharts(): void
    {
        // throw an event to be caught in Card.component.ts.
        this.refreshEvent.emit(true);
    }
    public querySiteRecords() {

        this.sitesService.getAllSites().subscribe (
            (data) => {
                this.sitesList = [];
                this.sitesList = data;
                this.sitesList.unshift({id: 0, handle: 'All Sites'});
                this.selectedSite = this.sitesList[this.siteIdx];
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Retrieving sites for the dashboard');
            });
    }
    public queryOrderMetricCardRecords(): void {

        this.homeService.getOrderCountsForMetricCards(this.selectedSite.id).subscribe(
            (results) => {
                if (results.length === 0) {
                    return;
                }

                let totalOrders = 0;

                for (const item of results) {
                    totalOrders += Number(item.numOrders);
                }

                /*** New Orders ***/
                this.totalMetricCardArr[0].current_result = Number(results[0].numOrders);
                if (results.length > 1) {
                    this.totalMetricCardArr[0].previous_result = Number(results[1].numOrders);
                }
                else {
                    this.totalMetricCardArr[0].previous_result = 0;
                }

                /*** total Open Orders ***/
                this.totalMetricCardArr[1].current_result = Number(totalOrders);
                this.totalMetricCardArr[1].current_result = Number(totalOrders);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Getting Metric Cards for the dashboard');
            });
    }
    public queryPurchaseOrderMetricCardRecords(): void {

        this.homeService.getPurchaseOrderCountsForMetricCards(this.selectedSite.id).subscribe(
            (results) => {
                    let totSubmitted = 0;
                    let totBackOrder = 0;

                    for (const item of results) {
                        if (item.orderStatus === 'Submitted') {
                            totSubmitted++;
                        }
                        if (item.orderStatus === 'Backorder') {
                            totBackOrder++;
                        }
                    }
                    this.totalMetricCardArr[2].current_result = totSubmitted;
                    this.totalMetricCardArr[2].previous_result = totSubmitted;

                    this.totalMetricCardArr[3].current_result = totBackOrder;
                    this.totalMetricCardArr[3].previous_result = totBackOrder;
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Getting Metric Cards for the dashboard');
            });
    }
    public doSiteDropDownKeyDown(event: any): void {
        const len: number = this.sitesList.length;
        if (event.key === 'ArrowDown') {
            if (this.siteIdx < (len - 1)) {
                this.selectedSite = this.sitesList[++this.siteIdx];
                this.doValueChange(this.selectedSite);
            }
        }
       else if (event.key === 'ArrowUp') {
            if (this.siteIdx > 0) {
                this.selectedSite = this.sitesList[--this.siteIdx];
                this.doValueChange(this.selectedSite);
            }
       }
       else if (event.key === 'Enter') {
           this.selectedSite = this.sitesList[this.siteIdx];
           this.doValueChange(this.selectedSite);
        }
    }
   public doValueChange(event: any) {

        if (event.value !== undefined) {
            this.selectedSite = event.value;
            this.reloadRecords();
        }
    }
   public homeResetHandler( event ) {
        this.selectedMetricCards = new Array<number>();
        this.selectedCharts = new Array<number>();
        if ( this.totalMetricCardArr != null ) {
            this.totalMetricCardArr.forEach(( item: MetricCard ) => {
            	if (event == null) {
            		if (this.activeMetricCardList == null || this.activeMetricCardList.indexOf( String(item.id) ) != -1) {
            			this.selectedMetricCards.push( item.id );
            		}
            	} else {
            		this.selectedMetricCards.push( item.id );
            	}
                
            } );
        }
        if ( this.totalChartArr != null ) {
            this.totalChartArr.forEach(( item: ChartDetails ) => {
            	if (event == null) {
            		if (this.activeChartList == null || this.activeChartList.indexOf( String(item.id) ) !== -1) {
            			this.selectedCharts.push( item.id );
            		}
            	} else {
                	this.selectedCharts.push( item.id );
            	}
            } );
        }
        this.homeApplyHandler( event );
    }
   public homeCancelHandler( event ) {

   }
   public homeApplyHandler( event ) {
        this.metricCardArr = new Array<MetricCard>();
        if ( this.totalMetricCardArr != null ) {
            this.totalMetricCardArr.forEach(( item: MetricCard ) => {
                if (this.selectedMetricCards.indexOf( item.id ) !== -1 ) {
                    this.metricCardArr.push( item );
                }
            } );
        }
        this.chartArr = new Array<ChartDetails>();
        if ( this.totalChartArr != null ) {
            this.totalChartArr.forEach(( item: ChartDetails ) => {
                if (this.selectedCharts.indexOf( item.id ) !== -1 ) {
                    this.chartArr.push( item );
                }
            } );
        }
        if (event != null) {
        	const dashboardSetup = {active_home_metric_cards: this.selectedMetricCards.toString(), active_home_charts: this.selectedCharts.toString()};
        	Settings.getInstance().loggedInUser.dashboard_settings = JSON.stringify(dashboardSetup);
        	this.userService.updateUserDashboardSetup(Settings.getInstance().loggedInUser, dashboardSetup).subscribe(
        			data => {
        				
        			},
        			(error) => {
        				Settings.getInstance().handleError(error);
        			});
        			
        }
    }
}
