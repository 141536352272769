<!--
<div #reportHeaderContainer *ngIf="HasHeader" class="report-header-pane">
	<app-report-row [rowData]="_HeaderData"></app-report-row>
</div>
<div #reportContainer class="report-pane">
	<app-report-row *ngFor="let row of _ReportData; let i = index" [rowData]="_ReportData[i]" class="{{(i%2)===0?'even-row':'odd-row'}}"></app-report-row>
</div>
-->
<div *ngIf="HasHeader" style="width: 100%;" class="no-print">
	<table style="width: 99%;">
		<thead *ngIf="HasHeader" style="background-color: darkgray">
			<tr *ngIf="HasHeader" class="report-header-pane">
				<td *ngFor="let cell of _HeaderData; let j = index" class="{{getCellClass(cell, j)}}">{{cell.label}}</td>
			</tr>
		</thead>
	</table>
</div>
<div style="width: 100%; height: 73vh !important; overflow-y: auto" class="no-print">
	<table style="width: 100%">
		<tbody>
			<tr *ngFor="let row of _ReportData; let i = index" class="{{(i%2)===0?'even-row':'odd-row'}}">
				<td *ngFor="let cell of row; let j = index" class="{{getCellClass(cell, j)}}">{{cell.label}}</td>
			</tr>
		</tbody>
	</table>
</div>
<div style="width: 100%; height: 100%" class="print-only">
	<table style="width: 100%">
		<thead *ngIf="HasHeader" style="background-color: darkgray">
			<tr *ngIf="HasHeader" class="report-header-pane">
				<td *ngFor="let cell of _HeaderData; let j = index" class="{{getCellClass(cell, j)}}">{{cell.label}}</td>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of _ReportData; let i = index" class="{{(i%2)===0?'even-row':'odd-row'}}">
				<td *ngFor="let cell of row; let j = index" class="{{getCellClass(cell, j)}}">{{cell.label}}</td>
			</tr>
		</tbody>
	</table>
</div>