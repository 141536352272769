/**
 *  Filename ....: addorder.component.ts
 *  Created by ..: ianday
 *  on Date .....: 6/21/23
 **/
import {Component, EventEmitter, OnInit, Input, Output, HostListener, ViewChild} from '@angular/core';
import {Orders} from '../../../../models/orders';
import {OrderItems} from '../../../../models/orderitems';
import {MatTableDataSource} from '@angular/material/table';
import {Customers} from '../../../../models/customers';
import {CustomersService} from '../../../../services/customers.service';
import {SitesService} from '../../../../services/sites.service';
import {Settings} from '../../../../services/settings';
import {Sites} from '../../../../models/sites';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Contacts} from '../../../../models/contacts';
import {ContactsService} from '../../../../services/Common/Contacts.service';
import {ContactsShort} from '../../../inventory/purchaseorders/addpurchaseorder/addpurchaseorder.component';
import {AddContactsComponent} from '../../../../components/addressbook/contacts/add-contact/add-contact.component';
import {OrdersService} from '../../../../services/orders.service';
import {OrderNumbers} from '../../../../models/ordernumbers';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {AddressesService} from '../../../../services/Common/Addresses.service';
import {LookupItemComponent} from '../lookupitem/lookupitem.component';
import {TransactionHistoryService} from '../../../../services/transactionhistory.service';
import {AddAddressComponent} from '../../../../components/addressbook/addresses/add-address/add-address.component';
import {Addresses} from '../../../../models/addresses';


@Component({
    selector: 'app-addorder',
    templateUrl: './addorder.component.html',
    styleUrls: ['./addorder.component.css']
})

export class AddOrderComponent implements OnInit {

    public _selectedOrder: Orders;
    public _selectedOrderItems: OrderItems[];
    public selectedOrderItem: OrderItems;
    public selectedCustomer: Customers;
    public selectedContact: ContactsShort;
    public customers: Customers[];
    public customersList: any;
    public contactsList:any;

    /*** History records ***/
    public orderHistory: Orders;

    /*** addresses ***/
    public addresses: any;
    public addressesList: any;
    public selectedShipToAddress: any;
    public selectedBillToAddress: any;
    public selectedShippingMethod: any;

    public shipToIdx = -1;
    public billToIdx = -1;
    public shipMethIdx = -1;
    public sitesList: Sites[];
    public strErr: string | null = '';

    filteredCustomerOptions: Observable<string[]>;
    public Idx: number | null = -1;
    public contactIdx = -1;
    public itemIterator = -1;

    /*** Field / form control ***/
    public customerControl = new FormControl();
    public customerReferenceControl = new FormControl();
    public orderCommentsControl = new FormControl();
    public orderTotalControl = new FormControl();
    public shippingTotalControl = new FormControl();
    public salesTaxControl = new FormControl();
    public grandTotalControl = new FormControl();

    public dataSource: MatTableDataSource<OrderItems>;
    public displayedColumns = ['itemPrefix', 'description', 'quantity', 'unitPrice', 'extendedPrice', 'itemStatus'];
    public shippingMethodsList: any;
    public addEditFlag = false;
    public billToShipToFlag = false;
    /*** Dialog boxes ***/
    public displayAddContactDlg = false;
    public displayAddAddressDlg = false;
    public displayLookupItemDlg = false;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @ViewChild('addContact') addContact: AddContactsComponent;
    @ViewChild('addAddress') addAddress: AddAddressComponent;
    @ViewChild('lkupItem') lkupItem: LookupItemComponent;

    @Input() set selectedOrder(val: Orders) {
        this._selectedOrder = val;
    }
    get selectedOrder(): Orders {
        return this._selectedOrder;
    }
    @Input() set selectedOrderItems(val: Array<OrderItems>) {
        this._selectedOrderItems = val;
    }
    get selectedOrderItems(): Array<OrderItems> {
        return this._selectedOrderItems;
    }
    @HostListener ('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }
    private _customerFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const strRet = this.customersList.filter(option => option.toLowerCase().includes(filterValue));
        return strRet;
    }
    constructor(public customersService: CustomersService,
                public contactsService: ContactsService,
                public txService: TransactionHistoryService,
                public orderService: OrdersService,
                public addressesService: AddressesService,
                public sitesService: SitesService) {
        this.loadShippingMethods();
    }
    ngOnInit() {

    }
    /*** Initializing - fetching supporting records ***/
    public doInitialize(adEditFlag: boolean): void   {

        this.addEditFlag = adEditFlag;
        this.queryCustomers();

        /*** true if it is an add ***/
        if (this.addEditFlag === true) {
            this.orderHistory = new Orders();
            this.doClearCustomer();
            this.doClearOrderInfo();
            this.selectedOrder = new Orders();
            this.selectedOrder.orderStatus = 'Draft';
            this.selectedOrder.submitMethod = 'Data Entry';
            this.selectedOrder.dateCreated = new Date();
            this.selectedOrder.dateSubmitted = new Date();

            this.orderService.createOrderNumber(new OrderNumbers()).subscribe(
                (data) => {
                    this.selectedOrder.orderNumber = data.orderNumber;
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'generating an order number');
                });
        }
        else {
            /*** shallow copy (by value) for the history ***/
            this.orderHistory = Object.assign({}, this.selectedOrder);
            if (this.customers !== undefined) {
                this.selectedCustomer = this.customers.find(x => x.id === this.selectedOrder.customerId);
            }
            this.queryContacts(true, this.selectedOrder.customerId);
            this.dataSource = new MatTableDataSource(this.selectedOrderItems);
        }
    }
    public loadShippingMethods(): void {

        this.shippingMethodsList = new Array();
        this.shippingMethodsList.push({id: 1, description: 'Standard Shipping [4-5 business days]', cost: 10});
        this.shippingMethodsList.push({id: 2, description: 'Priority Shipping [3-4 business days]', cost: 20});
        this.shippingMethodsList.push({id: 3, description: 'Rush Shipping [1-2 business days]', cost: 0});
    }
    public lblDateFmt(inp: Date): string {
        if (inp === null) {
            return;
        }
        return DwUtils.formatDTFromString(new Date(inp).toISOString());
    }
    public lblCurrencyFmt(inp: string): string {
        if (inp === undefined) {
            return '';
        }
        return DwUtils.formatCurrency(inp, 2);
    }
    /*** Customer Information ***/
    public queryCustomers(): void {
        this.customersList = new Array();
        this.selectedCustomer = new Customers();
        this.customersService.getActiveCustomers().subscribe(
            (data) => {
                this.customers = data;
                for (const item of this.customers) {
                    this.customersList.push(item.customerName);
                }
                // set up the filter options
                this.filteredCustomerOptions = this.customerControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._customerFilter(value || '')),
                );
                this.querySites();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'loading customers');
            });
    }
    public customerSelectVal(event: any): void {
        this.selectedCustomer =
            this.customers.find(x => x.customerName === this.customerControl.value);

        this.queryContacts(false, this.selectedCustomer.id);
    }
    public querySites(): void {

        this.sitesService.getAllSites().subscribe(
            (data) => {
                this.sitesList = data.filter(x => x.activeStatus === 'Active');

            },
            (error) => {
                Settings.getInstance().handleError(error, 'loading sites');
            });
    }
    /*** Bill To / Ship To Addresses ***/
    /*** this method is the end of the daisy chain ***/
    public queryCustomerAddresses(customerId: number): void {
        this.addressesList = new Array();
        if (customerId === null) {
            this.strErr = 'Error: cannot identify the customer by the Id';
            return;
        }
        this.addressesService.getAddressesByFK('customers', customerId).subscribe(
            (data) => {
                this.addressesList = data;
                if(this.addEditFlag === false) {
                    this.setDataforEditMode();
                }
            },
            (error) => {
                Settings.getInstance().handleError(error, 'getting customer addresses');
        });
    }
    public setDataforEditMode(): void {
        /** Order Header **/
        this.selectedCustomer =
            this.customers.find(x => x.id === Number(this.selectedOrder.subCustomerNumber));

        this.selectedContact =
            this.contactsList.find(x => x.fullName === this.selectedOrder.orderByFullName);

        this.customerReferenceControl.setValue(this.selectedOrder.customerReference);
        (<HTMLInputElement> document.getElementById('orderComments')).value = this.selectedOrder.orderComments;

        /** ship/bill section **/
        this.selectedShipToAddress = this.addressesList.find(x => x.id === this.selectedOrder.shippingAddressId);
        this.selectedBillToAddress = this.addressesList.find(x => x.id === this.selectedOrder.billingAddressId);
        this.selectedShippingMethod = this.shippingMethodsList.find(x => x.description === this.selectedOrder.shippingService);

        /*** Totals / grand totals etc ***/
        this.orderTotalControl.setValue(this.selectedOrder.orderTotal);
        this.salesTaxControl.setValue(this.selectedOrder.salesTax);
        this.shippingTotalControl.setValue(this.selectedOrder.shippingTotal);
        this.grandTotalControl.setValue(this.selectedOrder.grandTotal);
    }
    public onShipToSelected(ev: any): void {
        this.selectedShipToAddress =
            this.addressesList.find(x => x.id === ev.value.id);
    }
    public onBillToSelected(ev: any): void {
        this.selectedBillToAddress =
            this.addressesList.find(x => x.id === ev.value.id);
    }
    public onShipMethodSelected(ev: any): void {
        this.selectedShippingMethod = ev.value;
        this.shippingTotalControl.setValue(this.selectedShippingMethod.cost);

        if(this.selectedShippingMethod.cost === 0){
            this.strErr = 'Note: Actual Cost of shipping charges will be calculated and added to the order at the time of fulfillment';
        }
        else {
            this.runCalculateTotals();
        }
     }
    public onShipToDropDownKeyDown(event: any): void {
        const len: number = this.addressesList.length;
        if (event.key === 'ArrowDown') {
            if (this.shipToIdx < (len - 1)) {
                this.selectedShipToAddress = this.addressesList[++this.shipToIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.shipToIdx > 0) {
                this.selectedShipToAddress = this.addressesList[--this.shipToIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedShipToAddress = this.addressesList[this.shipToIdx];
        }
    }
    public onBillToDropDownKeyDown(event: any): void {
        const len: number = this.addressesList.length;
        if (event.key === 'ArrowDown') {
            if (this.billToIdx < (len - 1)) {
                this.selectedBillToAddress = this.addressesList[++this.billToIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.shipToIdx > 0) {
                this.selectedBillToAddress = this.addressesList[--this.billToIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedBillToAddress = this.addressesList[this.billToIdx];
        }
    }
    public onShipMethodDropDownKeyDown(event: any): void {
        const len: number = this.shippingMethodsList.length;
        if (event.key === 'ArrowDown') {
            if (this.shipMethIdx < (len - 1)) {
                this.selectedShippingMethod = this.shippingMethodsList[++this.shipMethIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.shipMethIdx > 0) {
                this.selectedShippingMethod = this.shippingMethodsList[--this.shipMethIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedShippingMethod = this.shippingMethodsList[this.shipMethIdx];
        }
    }
    public runSalesTaxInputEvent(event: any): void {
        this.salesTaxControl.setValue(event.value);
        this.runCalculateTotals();
    }
    public runShippingTotalInputEvent(event: any): void {
        this.shippingTotalControl.setValue(event.value);
        this.runCalculateTotals();
    }
    public quickAddAddress(billToShipToFlag: boolean): void {
        this.billToShipToFlag = billToShipToFlag;
        this.displayAddAddressDlg = true;
        this.addAddress.afterOpenDialog(new Addresses(), true);
    }
    /*** Contact information ***/
    public queryContacts(setFlag: boolean, customerId: number): void {

        this.contactsService.getContactsForAddressByForeignKey('customers', customerId).subscribe (
            (data) => {

                this.contactsList = data;
                /*** set the current contact record (if it is known). ***/
                if (setFlag === true) {
                    this.selectedContact = this.contactsList.find(x => x.fullName === this.selectedOrder.orderByFullName);

                    if (this.selectedContact === undefined) {
                        this.selectedContact = new Contacts();

                    } else if (this.selectedContact.id > 0) {
                        this.setOrderContactInfo();
                    }
                }
                else {
                    /*** If there is just one contact,  we will just pre-select it ***/
                    if (this.contactsList.length === 1) {
                        this.selectedContact = this.contactsList[0];
                        this.setOrderContactInfo();
                    }
                }
                this.queryCustomerAddresses(customerId);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'getting contacts for addresses for supplier');
            });

    }
    public setOrderContactInfo(): void {
        this.selectedOrder.orderedBy = this.selectedContact.fullName;
        this.selectedOrder.orderByFullName = this.selectedContact.fullName;
    }
    public onContactDropDownKeyDown(event: any): void {
        const len: number = this.contactsList.length;
        if (event.key === 'ArrowDown') {
            if (this.contactIdx < (len - 1)) {
                this.selectedContact = this.contactsList[++this.contactIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.contactIdx > 0) {
                this.selectedContact = this.contactsList[--this.contactIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedContact = this.contactsList[this.contactIdx];
        }
    }
    public onContactSelected(event: any): void {
        this.selectedContact = this.contactsList.find(x => x.id === event.value.id);
        this.setOrderContactInfo();

    }
    public quickAddContact(): void {
        this.displayAddContactDlg = true;
        this.addContact.afterOpenDialog(new Contacts(), true);
    }
    public newContactCreated(event: any): void {

        this.displayAddContactDlg = false;

        const sc: ContactsShort = new ContactsShort();

        sc.id = event.id;
        sc.fullName = event.fullName;
        sc.emailAddress = event.emailAddress;
        sc.telephone = event.telephone;
        sc.cellPhone = event.cellPhone;
        this.contactsList.push(sc);
        this.selectedContact = sc;

    }
    public newAddressCreated(event: any): void {
        this.displayAddAddressDlg = false;
        const ad: Addresses = new Addresses();
        ad.id = event.id;
        ad.foreignKeyId = event.foreignKeyId;
        ad.foreignKeyTable = event.foreignKeyTable;
        ad.entityDescription = event.entityDescription;
        ad.address1 = event.address1;
        ad.address2 = event.address2;
        ad.city = DwUtils.capitalize(event.city);
        ad.state = DwUtils.capitalize(event.state);
        ad.zipCode = event.zipCode;
        this.addressesList.push(ad);

        if (this.billToShipToFlag === true) {
            this.billToIdx = this.addressesList.findIndex(x => x.id === ad.id);
            this.selectedBillToAddress = ad;
        }
        else {
            this.shipToIdx = this.addressesList.findIndex(x => x.id === ad.id);
            this.selectedShipToAddress = ad;
        }
    }
    /*** Functions ***/
    public doClearCustomer(): void {
        this.customerControl.setValue('');
        this.selectedCustomer = new Customers();
        this.selectedContact = new Contacts();
        this.selectedShippingMethod = null;
        this.selectedShipToAddress = null;
        this.selectedBillToAddress = null;
    }
    public doClearOrderInfo(): void {
        this.customerReferenceControl.setValue('');
        this.orderCommentsControl.setValue('');
        this.selectedOrderItems = new Array<OrderItems>();
        this.dataSource = new MatTableDataSource<OrderItems>();
    }
    public tableKeyDown(event: any): void {
        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedOrderItem = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedOrderItem = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    public runCalculateTotals(): void {

        let orderTotal: number | null = 0;
        let grandTotal: number | null = 0;

        for (const item of this.selectedOrderItems) {
            orderTotal += Number(item.extendedPrice);
        }

        grandTotal += Number(orderTotal);

        if (this.salesTaxControl.value !== null) {
            grandTotal += Number(this.salesTaxControl.value);
        }
        if (this.shippingTotalControl.value !== null) {
            grandTotal += Number(this.shippingTotalControl.value);
        }
        this.orderTotalControl.setValue(this.lblCurrencyFmt(orderTotal.toString()));
        this.grandTotalControl.setValue(this.lblCurrencyFmt(grandTotal.toString()));
    }
    @HostListener('matSortChange', ['$event'])
    public onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.selectedOrderItems.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.selectedOrderItems.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.selectedOrderItems;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedOrderItem.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedOrderItem.id);
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedOrderItem = new OrderItems();
    }
    public onRowClicked(row: any): void {

        this.selectedOrderItem = row;
        this.Idx = this.dataSource.filteredData.findIndex(x => x.id === this.selectedOrderItem.id);
        this.selectRow();
    }
    public doQtyChange(event: any, row: any): void {

        this._selectedOrderItems[row].quantity = event.target.value;

        this.selectedOrderItems[row].extendedPrice =
            (this.selectedOrderItems[row].quantity * this.selectedOrderItems[row].unitPrice);

        this.runCalculateTotals();
    }
    /*** Control Strip items ***/
    public lookupItem(): void {
        this.lkupItem.doInitialize();
        this.displayLookupItemDlg = true;
    }
    public lookupItemSubmitted(event: any): void  {

        if (this.selectedOrderItems === undefined) {
            this.selectedOrderItems = new Array<OrderItems>();
        }

        this.selectedOrderItems.push(event);
        this.runCalculateTotals();
        this.dataSource = new MatTableDataSource(this.selectedOrderItems);
        this.displayLookupItemDlg = false;

        // focus on the newly added row and column ...
        this.Idx = this.selectedOrderItems.length - 1;
        this.selectedOrderItem = this.selectedOrderItems[this.Idx];
        DwUtils.highlightFocus('idQty');
    }
    public removeItem(id: number): void {

        if (this.Idx < 0) {
            return;
        }
        /*** note if the ID is less than 1 then it hasn't been persisted yet
         * We just need to remove it from the orderItems list. ***/

        if (id < 1) {
            const idx = this.selectedOrderItems.findIndex(x => x.id === id);
            this.selectedOrderItems.splice(idx, 1);
            this.dataSource = new MatTableDataSource(this.selectedOrderItems);
            return;
        }
        /*** Otherwise we will go ahead and remove it from the DB ***/

        this.orderService.removeOrderItem(id).subscribe(
            (data) => {
                const oi: OrderItems = this.selectedOrderItems[this.Idx];
                this.selectedOrderItems.splice(this.Idx, 1);
                this.txService.createTransactionHistory('orders', this.selectedOrder.id, 'Remove','Removed ' +
                    oi.quantity + ' X ' + oi.description);
                this.dataSource = new MatTableDataSource(this.selectedOrderItems);
                /*** make sure the totals are re-calc'd ***/
                this.runCalculateTotals();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'removing an order item');
        });
    }
    /*** persisting methods ***/
    public setOrderRecord(): void {

            this.selectedOrder.customerReference = this.customerReferenceControl.value;
            this.selectedOrder.orderComments = (<HTMLInputElement> document.getElementById('orderComments')).value;
            this.selectedOrder.customerEmail = this.selectedContact?.emailAddress;
            this.selectedOrder.customerTelephone = this.selectedContact?.telephone;
            this.selectedOrder.shippingService = this.selectedShippingMethod.description;
            this.selectedOrder.orderTotal = DwUtils.returnDecimalFromString(this.orderTotalControl.value);
            this.selectedOrder.salesTax = DwUtils.returnZeroForNull(this.salesTaxControl.value);
            this.selectedOrder.shippingTotal = this.shippingTotalControl.value;
            this.selectedOrder.grandTotal = DwUtils.returnDecimalFromString(this.grandTotalControl.value);
            this.selectedOrder.activeStatus = 'Active';
            this.selectedOrder.processedFlag = '0';
            this.selectedOrder.orderByFullName = this.selectedContact?.fullName;
            this.selectedOrder.orderedBy = this.selectedContact?.fullName;
            this.selectedOrder.billingAddressId = this.selectedBillToAddress.id;
            this.selectedOrder.shippingAddressId = this.selectedShipToAddress.id;
            this.selectedOrder.customerId = this.selectedCustomer.id;
            this.selectedOrder.subCustomerName = this.selectedCustomer.customerName;
            this.selectedOrder.defaultFillSiteId = this.selectedCustomer.fillSiteId;

     }
    public validateOrder(): boolean {

        if (this.selectedCustomer?.id === 0) {
            this.strErr = 'Error: Please start by selecting a customer';
            DwUtils.setFocus('customerId');
            return false;
        }
        if (this.selectedContact === undefined) {
            this.strErr = 'Error: a contact name must be selected or added';
            return false;
        }
        if (this.selectedContact?.id === 0) {
            this.strErr = 'Error: Please select (or add) a contact';
            DwUtils.setFocus('contactId');
            return false;
        }
        if (this.selectedOrderItems.length === 0) {
            this.strErr = 'Error: No items have been selected - please choose \'Lookup\' to add one';
            return false;
        }
        if (this.selectedShipToAddress === null) {
            this.strErr = 'Error: A Ship-To address is required';
            return false;
        }
        if (this.selectedBillToAddress === null) {
            this.strErr = 'Error: A Bill-To address is required';
            return false;
        }
        return true;
    }
    public onSubmitClicked(): void {

        if ( !this.validateOrder()) {
            return;
        }

        this.setOrderRecord();

        // Add/update the order record

        if (this.addEditFlag === true) {
            this.orderService.createOrders(this.selectedOrder).subscribe(
                (data) => {
                    this.selectedOrder.id = data.id;
                    this.itemIterator = 0;
                    this.txService.createTransactionHistory('orders', this.selectedOrder.id, 'Create', 'Added new order');
                    this.createOrderItems();
                },
            (error) => {
                    Settings.getInstance().handleError(error, 'adding a new order');
            });
        }
        else {
            this.orderService.updateOrders(this.selectedOrder).subscribe(
                (data) => {
                    this.itemIterator = 0;
                    this.txService.updateTransactionHistory(this.orderHistory, this.selectedOrder, 'orders', this.selectedOrder.id);
                    this.createOrderItems();
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'updating order');
                });
        }
    }
    public createOrderItems(): void {

        if (this.itemIterator < this.selectedOrderItems.length ) {

            this.persistOrderItems(this.selectedOrderItems[this.itemIterator++]);
        }
        else {
            // all done -
            this.submitClicked.emit(this.selectedOrder.id);
        }
    }
    public persistOrderItems(oi: OrderItems): void {

        if (oi.itemStatus === 'Draft') {

            oi.id = 0;
            oi.orderId = this.selectedOrder.id;
            oi.itemStatus = 'New';

            this.orderService.createOrderItem(oi).subscribe(
                (data) => {
                     this.selectedOrderItem.id = data.id;
                     this.createOrderItems();
                },
                (error) => {
                    Settings.getInstance().handleError(error, 'adding an order item');
                }
            );
        }
        else if (oi.itemStatus === 'New') {
            this.orderService.updateOrderItem(oi).subscribe(
                (data) => {
                    this.createOrderItems();
                },
            (error) => {
                    Settings.getInstance().handleError(error, 'updating an order item ');
                });
        }

    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


