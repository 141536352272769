export interface UserOTP
{
    email: string;
    username: string;
    otp: string;
    password: string;
    token: string;
}
export class UserOTP {

}

