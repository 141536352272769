<div class="medication-links-container">
	<div style="width: 100%; margin-top: 15px; margin-bottom: 5px;padding-left: 20px;padding-right: 20px;">
		<label style="font-weight:500">Internet Links: </label>
		<button mat-button style="float: right;" [disabled]="this._medication===undefined || this._medication.id===0"
			class="command-button" matTooltip="Add a new record"
			(click)="addLink()" mat-button>
			<mat-icon>add_circle_outline</mat-icon>
			Add Link
		</button>
	</div>

	<div class="links-table-container" (keydown)="tableKeyDown($event)" tabindex="-1">
		<table *ngIf=!isEditing #table id="medication-links-table" mat-table
			 [dataSource]="medicationLinks"
			 (matSortChange)="onSortChange($event)" matSort
			 style="width: 100%">
			 <ng-container matColumnDef="delete">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%; min-width: 100px; z-index: 99999"> </th>
			 <td mat-cell *matCellDef="let medLink" style="width: 100%; min-width: 100px;">
				 <div class="link-card">
					<table>
						<tr>
							<td style="width: 90%">{{medLink.linkDescription}}	</td>
							<td> <mat-icon class="edit-icon" (click)="showDialogAddEditLink()">edit</mat-icon></td>
							<td> <img class="edit-delete-button" style="width: 12px;cursor: pointer" alt="remove" src="assets/delete-icon.png" (click)="deleteLink(medLink)"></td>
						</tr>
						<tr>
							<td style="width: 100%;max-width: 320px;overflow: hidden;"><a
									[target]="'new'" [href]="medLink.linkUrl">{{medLink.linkUrl}}</a></td>
							<td></td>
						</tr>
					</table>
				 </div>
			 </td>
			 </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [hidden]="true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
					[ngClass]="{'row-selected': tableRowIdx == Idx}"
					(click)="onRowClicked(row)">

			</tr>
		</table>
	</div>
</div>


<p-dialog header="Add/Edit Link"
          [(visible)]="displayAddEditLink"
          *ngIf="displayAddEditLink"
          [modal]="true" 
           appendTo="body"
           [responsive]="true"
          [style]="{width: '605px', height: '230px'}"
          [minY]="75"
          [resizable]="false" [closable]="false">
   <app-addeditlink #addeditLink [medication]="_medication" [selectedRecord]="selectedRecord"
         (cancelClicked)="cancelClickedFromAddeditLink()"
         (submitClicked)="submitClickedFromAddeditLink()">
   </app-addeditlink>
</p-dialog>
