<div tabindex="0">
    <div class="dwform" style="padding-right: 15px;">
        <div class="dwform-cell">
            <div class="dwform-row-gap"></div>
          <div class="dwform-row-gap"></div>   
          <div class="dwform-row">
          	<span>1. Attach</span><br>
		<div style="margin-left: 30px;margin-top: 8px;">
			<app-file-selector [file_types]="'SVG, PNG, JPEG, GIF, JPG'" [header]="'Upload label'"
                               (onFileSelected)="onFileSelectedHandler($event)" (onFileError)="onFileError($event)">
			</app-file-selector>
		</div>
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
    </div>
</div>
</div>
