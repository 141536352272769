<!--
    file type .....: HTML file: addresses.component.html
    Created By ....: ianday
    Date ..........: 2/22/23 
-->
<!-- <div class="addresses-container"> -->
   <div class="address-header">
      <table style="width: 100%;"><tr>
         <td style="padding: 3px;width:50%">
            <label>Addresses:</label>
         </td>
         <td style="padding: 3px;width:50%; text-align:right;">
            <button *ngIf="visibleAdd" mat-button
                    [disabled]="this.disabledAddFlag"
                 class="command-button command-button-override" matTooltip="Add a new record"
                 (click)="addNewRecord()" mat-button>
            <mat-icon class="local-icon-display">add_circle_outline</mat-icon>
            Add New
            </button>
         </td>
      </tr>
      </table>
   </div>

   <!------------------------------>
   <!-- Main Table for addresses -->
   <!------------------------------>
   <div (keydown)="tableKeyDown($event)" tabindex="-1"
        class="addresses-list-container"
        style="outline: none;" >
      <table mat-table #addressTable [dataSource]="dataSource"
             style="width: 100%;">

         <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >Id</th>
            <td mat-cell
                [hidden]="true"
                style="font-size: 9pt; vertical-align: middle; text-align: left; max-width:30px; !important;"
                *matCellDef="let element;let tableRowIdx = index;">
               {{element.id}}</td>
         </ng-container>

         <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:95%; text-align:left;">Address</th>
            <td mat-cell style="width:90% !important; padding-top: 4px; padding-bottom: 4px;"
                *matCellDef="let element;">
               <div style="padding-left:10px; font-size: 9pt" innerHtml="{{element.address}}"></div></td>
         </ng-container>

         <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef style="width: 60px;"> </th>
            <td mat-cell *matCellDef="let element;let tableRowIdx = index;" class="button-cells-edit-delete">
               <span> <img class="edit-delete-button" matTooltip="Click to edit" alt=""
                           src="../../../../assets/edit-icon.png"
                           (click)="editRecord(tableRowIdx)">
               </span></td>
         </ng-container>

         <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef style="width: 30px;"> </th>
            <td mat-cell *matCellDef="let element;let tableRowIdx = index;" class="button-cells-edit-delete">
               <span> <img class="edit-delete-button" matTooltip="Click to remove" alt=""
                           src="../../../../assets/delete-icon.png"
                           (click)="deleteRecord(tableRowIdx)">
               </span></td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true;"
             [hidden]="true"
             style="width:100%; height: 0px; vertical-align: middle;background-color:whitesmoke;">
         </tr>
         <tr mat-row style="width:100%;"
             *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
             [ngClass]="{'row-selected': tableRowIdx == Idx}"
             (click)="onRowClicked(tableRowIdx)">
         </tr>
      </table>
   </div>
<!-- </div> -->
<!------------------------------>
<!-- DIALOGS and POPUPS       -->
<!------------------------------>

<p-dialog header="New Address"
          [(visible)]="displayAddAddress"
           [modal]="true" 
           appendTo="body" [responsive]="true"
          [style]="{width: '605px', height: '352px'}"
          [minY]="75"
          [resizable]="false" [closable]="false">
   <app-add-address #addAddress [parentId]="parentId" [callingComponent]="callingComponent"
         (cancelAddAddress)="newAddressCanceled($event)"
         (submitAddAddress)="newAddressCreated($event)">
   </app-add-address>
</p-dialog>

