import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Medication } from 'src/app/models/medication';
import { MedicationService } from '../../../services/medication.service';
import { Settings } from '../../../services/settings';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ElementRef } from '@angular/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { MedicationImage } from 'src/app/models/medicationimage';
import { DwUtils } from 'src/app/components/utils/dw-utils';
import { TransactionHistoryService } from 'src/app/services/transactionhistory.service';
import { TransactionHistoryComponent } from 'src/app/components/transaction-history/transaction-history';
import { MedicationFamily } from 'src/app/models/medicationfamily';


@Component({
	selector: 'app-medications-inv',
	templateUrl: './medications-inv.component.html',
	styleUrls: ['./medications-inv.component.css']
})
export class MedicationsInvComponent {

	queryStripGroup: FormGroup;
	customDataViewFlag = false;
	isEditing = false;
	isAdd = false;
	propContainerState = false;
	pagination: string;
	visiblePropertiesTab = true;
	visibleImagesTab = true;
	visibleLinksTab = true;
	visibleSuppliersTab = true;
	selectedTabIndex = 0;
	selectedViewFilter = 'List';

	public result: boolean | null = false;
	itemNumberInvalidFlag = false;

	contentClassWithBanner = 'dwtable-container';
	contentClassWithoutBanner = 'dwtable-container-without-header';
	contentClassName = 'dwtable-container';

	disabledFlag = true;

	formTitle = 'Medications';
	strErr = '';

	BASE_URL = Settings.getInstance().BASE_URL;
	medicationsListOriginal: Medication[];
	medicationsList: Medication[];
	public medicationFamilyList: MedicationFamily[];
	medicationImages: MedicationImage[];
	selectedRecord: Medication;
	historyRecord: Medication;
	renderedData = Array<Medication>();
	displayedColumns: string[] = ['id', 'manufacturedBy', 'origNdcNumber', 'schedule', 'lotPrefix', 'packageSize',  'awp', 'activeStatus'];
	groupColumns: string[] = ['groupReducer'];
	displayedColumnsCard: string[] = ['id'];
	dataSource: MatTableDataSource<Medication>; // = new MatTableDataSource(ELEMENT_DATA);

	visibleAdd = false;
	visibleModify = false;
	visibleDelete = false;
	formContent: UntypedFormGroup;
	Idx = -1;

	statusFilterList = [];
	selectedStatusFilter: any;
	statusFilterIdx: number | null = -1;

	valSchedule: any;
	valOMNDCNumber: any;
	valItemPrefix: any;
	valShortDesc: any;
	valDesc: any;
	valPkgSize: any;
	valGenricOrBrand: any;
	valUnit: any;
	valLotNumber: any;
	valCostBase: any;
	valAWP: any;
	valStatus: any;
	valId: any;
	valCompareTo: any;
	valMedicationFamily: any;
	valMedicationFamilyItemNumber: any;
	valManufacturedBy: any;
	valManufacturedFor: any;
	valStorageInstructions: any;
	valIngredients: any;
	manufacturerList = ['Strides Pharma Science Ltd.', 'Rising Health, LLC'];
	groupingColumn = 'medicationFamilyId';
	reducedGroups = [];
	dataList: any;
	sortField: string;
	sortDir: string;
	familyStringList: string[];
	filteredfamilyStringList: string[];

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('history', { static: true }) history: TransactionHistoryComponent;

	constructor(private medicationService: MedicationService, private dialog: MatDialog, private txService: TransactionHistoryService) {

		this.statusFilterList = new Array();
		this.statusFilterList.push({ id: 'Active', description: 'Active' });
		this.statusFilterList.push({ id: 'InActive', description: 'InActive' });
		this.statusFilterList.push({ id: 'Deleted', description: 'Deleted' });
		this.selectedStatusFilter = this.statusFilterList[0];
		this.statusFilterIdx = 0;
	}

	ngOnInit() {
		// Now let's set the access privilege level
		if (Settings.getInstance().hasPrivilege('MANAGE_MEDICATIONS', false)) {
			this.visibleAdd = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_MEDICATIONS', false)) {
			this.visibleModify = true;
		}
		if (Settings.getInstance().hasPrivilege('MANAGE_MEDICATIONS', false)) {
			this.visibleDelete = true;
		}
		this.queryMedicationFamilyRecords();
		if (Settings.getInstance().enableMessageBanner) {
			this.contentClassName = this.contentClassWithBanner;
		} else {
			this.contentClassName = this.contentClassWithoutBanner;
		}

		this.dataSource = new MatTableDataSource();
		this.dataSource.filterPredicate = function(record, filter) {
			if (record['isGroup'] == true) {
				return true;
			}
			const dataStr = Object.keys(record)
				.reduce((currentTerm: string, key: string) => {
					return currentTerm + (record as { [key: string]: any })[key] + '◬';
				}, '')
				.toLowerCase();
			const transformedFilter = filter.trim().toLowerCase();

			return dataStr.indexOf(transformedFilter) !== -1;
		}
	}
	public onStatusFilterKeyDown(event: any): void {
		const len: number = this.statusFilterList.length;
		if (event.key === 'ArrowDown') {
			if (this.statusFilterIdx < (len - 1)) {
				this.selectedStatusFilter = this.statusFilterList[++this.statusFilterIdx];
			}
		}
		else if (event.key === 'ArrowUp') {
			if (this.statusFilterIdx > 0) {
				this.selectedStatusFilter = this.statusFilterList[--this.statusFilterIdx];
			}
		}
		else if (event.key === 'Enter') {
			this.selectedStatusFilter = this.statusFilterList[this.statusFilterIdx];
			this.onChangeStatusFilterValue(this.selectedStatusFilter);
		}
	}
	queryMedicationFamilyRecords() {
		this.medicationService.getAllMedicationFamily().subscribe(
			(result) => {
				this.medicationFamilyList = result;
				this.familyStringList = [];
				for (const rec of this.medicationFamilyList) {
					this.familyStringList.push(rec.itemPrefix);
				}
				this.queryRecords();
			},
			(error) => {
				Settings.getInstance().handleError('Error: unable to retrieve data from the service');
			}
		);
	}
	applyStatusFilter() {
		this.medicationsList = [];
		for (const rec of this.medicationsListOriginal) {
			if (this.selectedStatusFilter === 'InActive') {
				if (rec.activeStatus === 'InActive') {
					this.medicationsList.push(rec);
				}
			} else if (this.selectedStatusFilter === 'Active') {
				if (rec.activeStatus === 'Active') {
					this.medicationsList.push(rec);
				}
			} else if (this.selectedStatusFilter === 'Deleted') {
				if (rec.activeStatus === 'Deleted') {
					this.medicationsList.push(rec);
				}
			}
			else {
				this.medicationsList.push(rec);
			}
		}
		this.dataList = this.medicationsList;
		this.buildDataSource();
	}

	queryRecords() {
		this.medicationService.getAllMedications().subscribe(
			(result) => {
				this.medicationsListOriginal = result;
				this.medicationsList = result;
				this.applyStatusFilter();
				this.setPagination(-1, this.medicationsList.length);
				if (this.isAdd === true) {
					this.selectedRecord = this.dataSource.data[this.dataSource.data.length - 1];
					this.displayDetail(this.selectedRecord);
				}
				this.medicationService.getAllMedicationImages().subscribe(
					(result2) => {
						if (result === undefined || result.length === undefined) {
							return;
						}
						this.medicationImages = result2;
						for (let rec of this.medicationsList) {
							for (let img of this.medicationImages) {
								if (img.medicationId === rec.id) {
									if (rec.containerURL === undefined || rec.containerURL === '') {
										if (img.imageType === 'Container' && img.defaultFlag === 1) {
											rec.containerURL = this.BASE_URL + 'medications/medication-images/file/' + img.id;
											rec.containerImagePresent = true;
										}
									}
									if (rec.tabletURL === undefined || rec.tabletURL === '') {
										if (img.imageType === 'Dose' && img.defaultFlag === 1) {
											rec.tabletURL = this.BASE_URL + 'medications/medication-images/file/' + img.id;
											rec.tabletImagePresent = true;
										}
									}
								}
							}
							if (rec.containerURL === undefined) {
								rec.containerURL = '/assets/no-image.png';
								rec.containerImagePresent = false;
							}
							if (rec.tabletURL === undefined) {
								rec.tabletURL = '/assets/no-image.png';
								rec.tabletImagePresent = false;
							}
							//To populate medication family names
							for (const family of this.medicationFamilyList) {
								if (family.id === rec.medicationFamilyId) {
									rec.medicationFamilyName = family.description;
								}
							}
						}
					},
					(error) => {
					}
				);
			},
			(error) => {
			}
		);
	}
	buildDataSource() {
		let data = [];
		this.dataList.forEach(rec => {
			rec['groupName'] = undefined;
			rec['value'] = undefined;
			rec['isGroup'] = undefined;
			rec['reduced'] = undefined;

			data.push(rec);
		});
		this.dataSource.data = this.groupBy(this.groupingColumn, this.medicationsList, this.medicationFamilyList, this.reducedGroups);
		this.setPagination(-1, this.medicationsList.length);
	}
	groupBy(column: string, data: any[], medicationFamilies: any[], reducedGroups?: any[]) {
		let collapsedGroups = reducedGroups;
		if (!reducedGroups) collapsedGroups = [];
		let groups = {};
		/**********************************************************************/
		/* Loop round for each medication family that we have in the list     */
		/* And collect the projects underneath ...                            */
		/**********************************************************************/
		medicationFamilies.forEach(medFamily => {
			let groupArr = null;
			let key = medFamily.itemPrefix;
			if (!groups[key]) {
				groupArr = [medFamily];
				groupArr[0]['groupName'] = `${medFamily.itemPrefix} - ${medFamily.description}`;
				groupArr[0]['value'] = medFamily.id;
				groupArr[0]['isGroup'] = true;
				groupArr[0]['depth'] = 1;
				groupArr[0]['reduced'] = collapsedGroups.some((group) => group.value === medFamily.id);
			} else {
				groupArr = groups[key];
			}
			data.forEach(medication => {
				if (medication.medicationFamilyId === medFamily.id) {
					groupArr.push(medication);
				}
			});
			groups[key] = groupArr.sort((obj1, obj2) => {
				if (obj1.isGroup == true) {
					return -1;
				}
				if (obj2.isGroup == true) {
					return 1;
				}

				if (obj1[this.sortField] < obj2[this.sortField]) {
					return -1 * (this.sortDir == 'asc' ? 1 : -1);
				}
				if (obj1[this.sortField] > obj2[this.sortField]) {
					return 1 * (this.sortDir == 'asc' ? 1 : -1);
				}

				return 0;
			});
		});
		let groupArray = Object.keys(groups).map(key => {
			let arr = [];
			groups[key].forEach(row => {
				if (!Array.isArray(row)) {
					arr.push(row);
				}
			});
			groups[key].forEach(row => {
				if (Array.isArray(row)) {
					arr = arr.concat(Object.keys(row).map(key => {
						return row[key];
					}));
				}
			});
			return arr;
		});
		let flatList = groupArray.reduce((a, c) => {
			return a.concat(c);
		}, []);
		/******************************************************************/
		/* now fill up the remaining medications that aren't associated with */
		/* a medication family and give them their own unindented line              */
		/******************************************************************/
		data.forEach(medication => {
			if (medication.medicationFamilyId === 0 || medication.medicationFamilyId == null) {
				flatList.push(medication);
			}
		});
		return flatList.filter((rawLine) => {
			return (rawLine.isGroup && rawLine.medicationFamilyId === 0) ||
				collapsedGroups.every((group) => rawLine['medicationFamilyId'] !== group.value);
		});
	}
	isGroup(index, item): boolean {
		return item.isGroup;
	}

	reduceGroup(row) {
		row.reduced = !row.reduced;
		if (row.reduced) {
			this.reducedGroups.push(row);
			//this.tableDataSource.data	
		}
		else
			this.reducedGroups = this.reducedGroups.filter((el) => el.value != row.value);

		this.buildDataSource();
	}

	rowIsNested(handle: string): string {

		if (handle.substring(0, 3) === ' - ') {
			return 'nested-row';
		}
		return '';
	}
	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}
	applyFilter(filterValue: string) {
		if (this.isEditing) return;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		this.unSelectRow();
		this.setPagination(-1, this.dataSource.filteredData.length);
	}

	onSortChange(event) {
		this.sortField = event.active;
		this.sortDir = event.direction;
		this.buildDataSource();
		/*
		if (event.direction === 'asc') {
			this.medicationsList.sort((a, b) =>
				a[event.active].localeCompare(b[event.active]));
		}
		else {
			this.medicationsList.sort((a, b) =>
				b[event.active].localeCompare(a[event.active]));
		}

		this.dataSource.data = this.medicationsList;
		this.selectRow();
		this.setPagination(this.Idx, this.dataSource.filteredData.length);
		*/
	}
	selectRow() {
		if (this.selectedRecord.schedule === undefined || this.selectedRecord.schedule.length === 0) {
			return;
		}
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		//console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = new Medication();
		this.setPagination(this.Idx, this.medicationsList.length);
		this.displayDetail(this.selectedRecord);

	}
	clearFilter() {
		if (this.isEditing) {
			return;
		}
		this.dataSource.filter = '';

		this.unSelectRow();
		this.setPagination(-1, this.medicationsList.length);

	}
	displayDetail(record: Medication) {
		if (record === undefined || record.id === 0 || record.schedule === undefined) {
			return;
		}
		//this.valShortDesc = record.handle.toUpperCase();
		this.valPkgSize = record.packageSize;
		this.valDesc = record.description;
		this.valId = record.id;
		this.valOMNDCNumber = this.selectedRecord.origNdcNumber;
		//this.valItemPrefix = this.selectedRecord.itemPrefix;
		this.valPkgSize = this.selectedRecord.packageSize;
		this.valStatus = this.selectedRecord.activeStatus;
		this.valGenricOrBrand = this.selectedRecord.genericBrand;
		this.valSchedule = this.selectedRecord.schedule;
		this.valCostBase = this.selectedRecord.costBase;
		this.valAWP = this.selectedRecord.awp;
		this.valUnit = this.selectedRecord.unitOfMeasure;
		this.valLotNumber = this.selectedRecord.lotPrefix;
		this.valMedicationFamily = this.selectedRecord.medicationFamilyId;
		this.valMedicationFamilyItemNumber = this.getMedicationFamily(this.valMedicationFamily).itemPrefix;
		this.valManufacturedBy = this.selectedRecord.manufacturedBy;
		this.valManufacturedFor = this.selectedRecord.manufacturedFor;
		this.valStorageInstructions = this.selectedRecord.storageInstructions;
		this.valIngredients = this.selectedRecord.ingredients;

		this.medicationFamilySelectionchanged();

		if (this.selectedRecord.compareTo !== null && this.selectedRecord.compareTo !== undefined && this.selectedRecord.compareTo.length > 0) {
			this.valCompareTo = this.selectedRecord.compareTo.toUpperCase();
		}
		this.history.onShowHandler('Medications', 'id', record.id.toString());
	}
	clear() {
		this.valShortDesc = '';
		this.valPkgSize = '';
		this.valDesc = '';
		this.valId = '';
		this.valOMNDCNumber = '';
		this.valItemPrefix = '';
		this.valPkgSize = '';
		this.valStatus = '';
		this.valGenricOrBrand = '';
		this.valSchedule = '';
		this.valCostBase = '';
		this.valAWP = '';
		this.valCostBase = '';
		this.valUnit = '';
		this.valLotNumber = '';
		this.valCompareTo = '';
		this.valManufacturedBy = '';
		this.valManufacturedFor = '';
		this.valIngredients = '';
		this.valStorageInstructions = '';
	}

	dataViewFilterChanged(event) {
		console.log(event)
		//this.selectedViewFilter = changedValue;
	}

	onChangeStatusFilterValue(event) {
		if (event.value !== undefined) {
			this.selectedStatusFilter = event.value;
		}
		this.applyStatusFilter();
		this.setPagination(-1, this.medicationsList.length);
	}

	onPropertiesToggle() {
		this.propContainerState = !this.propContainerState;
	}

	tableKeyDown(event: KeyboardEvent) {

		if (this.isEditing) {
			return;
		}

		const len: number = this.dataSource.filteredData.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.filteredData[++this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.filteredData[--this.Idx];
				this.displayDetail(this.selectedRecord);
			}
		}
		this.setPagination(this.Idx, this.medicationsList.length);
	}

	doTabChange(event): void {

	}
	addModifyRecord(isAdd: boolean) {
		this.isAdd = isAdd;
		this.isEditing = true;
		if (this.selectedTabIndex === 2) {
			this.selectedTabIndex = 0;
		}
		if (this.isAdd) {
			this.clear();
			this.valStatus = 'Active';
			this.valSchedule = 0;
		} else {
			this.historyRecord = new Medication();
			for (let prop in this.selectedRecord) {
				this.historyRecord[prop] = this.selectedRecord[prop];
			}
		}
		DwUtils.setFocus('omNDCNumber');
	}
	validateFields() {
		/*
		if (this.valShortDesc === undefined || this.valShortDesc.length === 0) {
			this.strErr = 'Please enter Short Description';
			DwUtils.setFocus('handle');
			return false;
		}
		if (this.valItemPrefix === undefined || this.valItemPrefix.length === 0) {
			this.strErr = 'Please enter Item Number';
			DwUtils.setFocus('ndcNumber');
			return false;
		}*/
		if (this.valMedicationFamily === undefined || this.valMedicationFamily === null || this.valMedicationFamily === 0) {
			this.strErr = 'Please select Medication Family';
			DwUtils.setFocus('medicationFamily');
			return false;
		}
		if (this.itemNumberInvalidFlag) {
			this.strErr = 'Please enter a valid Medication Family';
			DwUtils.setFocus('medicationFamily');
			return false;
		}
		if (this.valOMNDCNumber === undefined || this.valOMNDCNumber.length === 0) {
			this.strErr = 'Please enter Original NDC Number';
			DwUtils.setFocus('omNDCNumber');
			return false;
		}
		if (this.isDuplicateNDCNumber(this.valOMNDCNumber)) {
			this.strErr = 'A Duplicate NDC Number Was Entered';
			DwUtils.setFocus('ndcNumber');
			return false;
		}
		if (this.valSchedule === undefined || this.valSchedule.length === 0) {
			this.strErr = 'Please enter Schedule';
			DwUtils.setFocus('schedule');
			return false;
		}
		if (this.valStatus === undefined || this.valStatus.length === 0) {
			this.strErr = 'Please select Status';
			DwUtils.setFocus('status');
			return false;
		}
		if (this.valPkgSize === undefined || this.valPkgSize.length === 0) {
			this.strErr = 'Please enter Package size';
			DwUtils.setFocus('vackagesize');
			return false;
		}


		return true;
	}
	submitRecord() {
		this.strErr = '';
		if (!this.validateFields()) {
			return;
		}

		if (this.isAdd) {
			let newRecord = new Medication();
			newRecord = new Medication();
			//newRecord.handle = this.valShortDesc.toUpperCase();
			newRecord.description = this.valDesc;
			newRecord.origNdcNumber = this.valOMNDCNumber;
			//newRecord.itemPrefix = this.valItemPrefix;
			newRecord.packageSize = this.valPkgSize;
			newRecord.activeStatus = this.valStatus;
			newRecord.genericBrand = this.valGenricOrBrand;
			newRecord.schedule = this.valSchedule;
			newRecord.costBase = this.valCostBase;
			newRecord.awp = this.valAWP;
			newRecord.unitOfMeasure = this.valUnit;
			newRecord.lotPrefix = this.valLotNumber;
			newRecord.medicationFamilyId = this.valMedicationFamily;
			newRecord.manufacturedBy = this.valManufacturedBy;
			newRecord.manufacturedFor = this.valManufacturedFor;
			newRecord.storageInstructions = this.valStorageInstructions;
			newRecord.ingredients = this.valIngredients;
			newRecord.compareTo = this.valCompareTo.toUpperCase();

			this.medicationService.createMedication(newRecord).subscribe(
				(data2) => {
					/*** add a transaction history record ***/
					this.txService.createTransactionHistory('Medications', data2.id);
					this.queryRecords();
					this.strErr = '';
					this.isEditing = false;
				},
				(error) => {
				}
			);

		} else {
			//this.selectedRecord.handle = this.valShortDesc.toUpperCase();
			delete this.selectedRecord['medicationFamily'];
			this.selectedRecord.description = this.valDesc;
			this.selectedRecord.origNdcNumber = this.valOMNDCNumber;
			//this.selectedRecord.itemPrefix = this.valItemPrefix;
			this.selectedRecord.packageSize = this.valPkgSize;
			this.selectedRecord.activeStatus = this.valStatus;
			this.selectedRecord.genericBrand = this.valGenricOrBrand;
			this.selectedRecord.schedule = this.valSchedule;
			this.selectedRecord.costBase = this.valCostBase;
			this.selectedRecord.awp = this.valAWP;
			this.selectedRecord.unitOfMeasure = this.valUnit;
			this.selectedRecord.lotPrefix = this.valLotNumber;
			this.selectedRecord.medicationFamilyId = this.valMedicationFamily;
			this.selectedRecord.manufacturedBy = this.valManufacturedBy;
			this.selectedRecord.manufacturedFor = this.valManufacturedFor;
			this.selectedRecord.storageInstructions = this.valStorageInstructions;
			this.selectedRecord.ingredients = this.valIngredients;
			if (this.valCompareTo !== undefined && this.valCompareTo.length > 0) {
				this.selectedRecord.compareTo = this.valCompareTo.toUpperCase();
			}

			this.medicationService.updateMedication(this.selectedRecord).subscribe(
				(data2) => {
					this.isEditing = false;
					this.strErr = '';
					//this.queryRecords();
					this.txService.updateTransactionHistory(this.historyRecord, this.selectedRecord, 'Medications', this.selectedRecord.id);
				},
				(error) => {
				}
			);
		}
	}

	onRowClicked(SR: Medication) {
		if (this.isEditing) return;
		if (SR.schedule === undefined) {
			return;
		}
		this.selectedRecord = SR;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
		this.setPagination(this.Idx, this.medicationsList.length);
		this.displayDetail(this.selectedRecord);
	}
	cancelSubmitRecord() {
		this.isEditing = false;
		this.displayDetail(this.selectedRecord);
		this.strErr = '';
		this.itemNumberInvalidFlag = false;
	}
	removeRecord() {
		const message = 'Remove Medication - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result === true) {
				this.selectedRecord.activeStatus = 'Deleted';
				this.medicationService.deleteMedication(this.selectedRecord.id).subscribe(
					(data2) => {
						this.isEditing = false;
						this.queryRecords();
					},
					(error) => {
					}
				);
			}
		});
	}
	isDuplicateNDCNumber(paramNdcNumber) {
		for (const rec of this.medicationsListOriginal) {
			if (this.isAdd) {
				if (rec.origNdcNumber === paramNdcNumber) {
					return true;
				}
			} else {
				if ((rec.id !== this.selectedRecord.id) && rec.origNdcNumber === paramNdcNumber) {
					return true;
				}
			}
		}
		return false;
	}
	newManufacturerAddedHandler(event: any) {
		if (event.target.value === undefined || event.target.value.length === 0) {
			return;
		}
		this.manufacturerList.push(event.target.value);
		this.valManufacturedBy = event.target.value;
	}
	medicationFamilySelectionchanged() {
		this.valShortDesc = '';
		let medFamily = this.getMedicationFamilyByItemNumber(this.valMedicationFamilyItemNumber);
		if (medFamily !== undefined) {
			this.valShortDesc = medFamily.description;
			this.itemNumberInvalidFlag = false;
			this.valMedicationFamily = medFamily.id;
		} else {
			this.itemNumberInvalidFlag = true;
			this.valShortDesc = 'Invalid Item Number';
		}
	}
	getMedicationFamily(paramId: number) {
		for (const rec of this.medicationFamilyList) {
			if (rec.id === paramId) {
				return rec;
			}
		}
		return undefined;
	}
	getMedicationFamilyByItemNumber(paramNumber: string) {
		for (const rec of this.medicationFamilyList) {
			if (rec.itemPrefix === paramNumber) {
				return rec;
			}
		}
		return undefined;
	}
	filtermedicationFamilyList(event) {
		const filtered: any[] = [];
		//this.filteredfamilyStringList = null;
		if (event.query === undefined || event.query === '') {
			this.filteredfamilyStringList = this.familyStringList;
			return;
		}
		for (let i = 0; i < this.familyStringList.length; i++) {
			const rec = this.familyStringList[i];
			if (rec === null || rec === '' || rec === undefined) {
				continue;
			}
			if (rec.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
				filtered.push(rec);
			}
		}
		this.filteredfamilyStringList = filtered;
	}
}
