<!--
    file type .....: HTML file: qasupplier.component.html
    Created By ....: ianday
    Date ..........: 4/27/23 
-->
<div class="qasupplier-container">
    <table style="width:100%; ">
        <tr>
            <td class="field-container">
                <div class="dwform" style="font-size: 12px;"
                     tabindex="-1">

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Company Name:</label>
                        <span class="form-required"></span>
                        <input class="input-field-long" maxlength="80"
                               style="text-transform: capitalize"
                               id="companyName"
                               [(ngModel)]="companyName"
                               placeholder="<Company Name>"
                               type="text">
                    </div>
                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Supplier Type:</label>
                        <span class="form-required">*</span>
                        <mat-select [(ngModel)]="supplierType"
                                    placeholder="- Please Select -"
                                    disableOptionCentering
                                    floatLabel="never"
                                    panelClass="local-drop-down-panel"
                                    class="dw-mat-select"
                                    style="width: 150px;font-size: 10pt;">
                            <mat-option value="Medications">Medication Supplier</mat-option>
                            <mat-option value="Materials">Materials Supplier</mat-option>
                            <mat-option value="General">General Supplier</mat-option>
                        </mat-select>
                    </div>

                    <div class="dwform-row-gap"></div>

                    <div class="dwform-row">
                        <label class="label-field">Web Site:</label>
                        <span class="form-required"></span>
                        <input class="input-field-long" maxlength="80"
                               id="webSiteAddress"
                               [(ngModel)]="webSiteAddress"
                               placeholder="<Include https://www>"
                               type="text">
                    </div>

                    <div class="dwform-row-gap"></div>


                </div>
            </td>
        </tr>
    </table>

    <div [hidden]='!strErr.length'
         class="error-line" style="left: 18px; bottom: 38px;">{{strErr}}</div>
    <!--save strip -->
    <div class="save-close-strip" style="bottom: 5px;">

        <button mat-button class="command-button"
                (click)="submitRecord()"
                matTooltip="Submit the selected record"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="cancelSubmitRecord()"
                matTooltip="Cancel submission" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
