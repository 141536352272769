<div class="ui-g" class="register-user-container">
	<div style="display: block; width: 650px; align-self: center; padding: 2rem;  box-shadow: 5px 10px 10px #888888; background-color: #FAFAFA;">
		<div>
			<h2 style="text-align: center;">Welcome to Re-Packer Platform</h2>
		</div>
		<div style="margin-bottom: 2rem;">
			<p>Please complete your profile by creating a new password. You can also upload a profile image. After
				submitting this form you will be redirected to the login page.
			Please note down your username.
			</p>
		</div>
		<div style="display: flex">
			<div class="ui-g-3 profile-avatar-container">
			
				<div #avatar id="avatar" style="margin-left: 8px; cursor: pointer;" (click)="fileInput.click()"></div>
				<input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileInputChange($event)"
					   accept="image/*"/>
				<div style="margin-left: 0px; margin-top: 0px;">
					<button (click)="fileInput.click()" mat-button><mat-icon>camera_alt</mat-icon></button>
					<button (click)="clearAvatar($event)" mat-button><mat-icon>cancel</mat-icon></button>
				</div>
				<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8"  *ngIf="loading"></p-progressSpinner>
			</div>
			
			<div class="ui-g-9 profile-data-container">
				<div class="ui-g" style="padding-top: 7px; padding-bottom: 7px; height: 40px;">
					<div class="ui-g-4 ui-md-4">
						<label	style="color:Gray;">Full Name:</label>
					</div>
					<div class="ui-g-8 ui-md-8">
						<label>{{fullName}}</label>
					</div>
				</div>
				<div class="ui-g" style="padding-top: 7px; padding-bottom: 7px; height: 40px;" >
					<div class="ui-g-4 ui-md-4">
						<label	style="color:Gray">User Name:</label>
					</div>
					<div class="ui-g-8 ui-md-8">
						<label>{{userName}}</label>
					</div>
				</div>
				<div class="ui-g" style="padding-top: 7px; padding-bottom: 7px; height: 40px;" >
					<div class="ui-g-4 ui-md-4">
						<label	style="color:Gray">New Password:</label>
					</div>
					<div class="ui-g-8 ui-md-8">
						<input id="password" name="password" placeholder="New password" class="form-control btn-block"  type="password" [(ngModel)]="strPassword1">
					</div>
				</div>
				<div class="ui-g" style="padding-top: 7px; padding-bottom: 7px; height: 40px;" >
					<div class="ui-g-4 ui-md-4">
						<label	style="color:Gray">Retype Password:</label>
					</div>
					<div class="ui-g-8 ui-md-8">
						<input id="confirmpassword" name="confirmpassword" placeholder="Retype password" class="form-control btn-block"  type="password" [(ngModel)]="strPassword2">
					</div>
				</div>
				<div style="height: 20px;text-align: initial; margin-top: 10px; margin-left: 1px">
					<p class="error">{{ strMessage }} </p>
					<p *ngIf="srtSuccessMsg?.length > 0" style="color: green">{{ srtSuccessMsg }} </p>
				</div>
		<!-- 		<div class="ui-g" style="padding-bottom: 5px;"> -->
		<!-- 			<div class="ui-g-4 ui-md-4"> -->
		<!-- 				<label	style="color:Gray">Last Login:</label> -->
		<!-- 			</div> -->
		<!-- 			<div class="ui-g-8 ui-md-8"> -->
		<!-- 				<label class="label-data-override">{{this.lastLogin}}</label> -->
		<!-- 			</div> -->
		<!-- 		</div> -->
			</div>
		</div>
		<div class="buttonHolder">
			<button #btnSubmit id="btn-close-profile" mat-raised-button color="blue"
					(click)="doSubmit()" mat-button [disabled]="disableSubmit">Submit</button>
		</div>
	</div>
</div>
