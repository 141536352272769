/**
 *  Filename ....: addressbook.component.ts
 *  Created by ..: ianday
 *  on Date .....: 2/22/23
 **/
import {Component, Input, OnInit, AfterContentInit, ViewChild, AfterViewChecked} from '@angular/core';
import { AddressesComponent } from './addresses/addresses.component';
import {ContactsComponent} from "./contacts/contacts.component";

@Component({
    selector: 'app-addressbook',
    templateUrl: './addressbook.component.html',
    styleUrls: ['./addressbook.component.css']
})

export class AddressBookComponent implements OnInit, AfterViewChecked {
    private _parentId: number;
    private _callingComponent: any;
    private _maxAddresses = 100;                    // initialize with the upper limit
    private lastAddressId: number;

    public displayErrorDlg = false;
    public displayMessage: string | null = '';
    @ViewChild(AddressesComponent)
    public addresses: AddressesComponent;

    @ViewChild(ContactsComponent)
    public contacts: ContactsComponent;

    @Input() set parentId(value: number) {
        this._parentId = value;

        if (value === undefined || value <= 0) {

            return;
        } else {
            // this.queryRecords();
            // this.disabledFlag = false;
        }
    }

    get parentId(): number {
        return this._parentId;
    }

    @Input() set callingComponent(value: string) {
        this._callingComponent = value;
    }
    get callingComponent(): string {
        return this._callingComponent;
    }

    @Input() set maxAddresses(value: number) {
        this._maxAddresses = value;
    }
    get maxAddresses(): number {
        return this._maxAddresses;
    }

    constructor() {
        this.lastAddressId = -1;
    }

    ngOnInit() {

    }
    ngAfterViewChecked() {
        /****** set up the event handler to pass
         * over the addressId to the contacts
         * module ...
         ****************/
        this.addresses.addressRecordSelected.subscribe(
            (data) => {
                if (data !== undefined ) {

                    // nasty little hack to prevent
                    // repetitive calling.
                    if(this.lastAddressId === data) {
                        return;
                    }

                    this.contacts.addressId = data;
                    this.lastAddressId = data;
                }
            });
    }

    splitterResizeEvent(event: any) {

    }
    showDeleteMessage(event: any): void {
        this.displayMessage = event;
        this.displayErrorDlg = true;
    }
}


