export interface FormularyMaterials {
	id: number;
	customerId: number;
	formularyMedicationId: number;
	materialId: number;
	
	description: string;
	itemType: string;
}
export class FormularyMaterials {
	constructor() {
		this.id = 0;
	}
}
