/************************************************************************************************************************
 * Function : Class
 * Purpose:  To generate and print labels.
 * Date:  July 24th 20203
 * By:  Ian Day
 *
 *  Programmer's notes
 *  - this mechanism will print labels for a given selected form record.
 *  - note the sequence number is taken from the formulary and is incremented to provide the next number.  The last number
 *    that is used is then updated in the formulary medication table.
 *    Mergeable fields:
 *      ~ITEMNUMBER~
 *      ~LOTNUMBER~
 *      ~RXNUMBER~
 *      ~EXPIRATIONDATE~
 *      ~NDCNUMBER~
 *      ~DIRECTIONS~
 *      ~DIRECTIONSALT~
 *      ~PRECAUTIONS~
 *      ~PRECAUTIONSALT~
  ***********************************************************************************************************************/
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Settings} from '../../services/settings';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import printJS from 'print-js';
import {FormingService} from '../../services/forming.service';
import {FinishedForms} from '../../models/finishedforms';
import JsBarcode from 'jsbarcode';

@Component({
    selector: 'app-labelgen',
    templateUrl: './label-gen.html'
})

export class LabelGenComponent {

    public mergeFields = ['~ITEMNUMBER~', '~LOTNUMBER~', '~RXNUMBER~', '~EXPIRATIONDATE~', '~NDCNUMBER~',
        '~DIRECTIONS~', '~DIRECTIONSALT~', '~PRECAUTIONS~', '~PRECAUTIONSALT~'];

    public _bprId: any;
    public finishedFormRecords: any;
    public finishedForm: FinishedForms;
    public iterator: number;

    public labelUrl: string;
    public templateContents: string;
    public printContents: string;

    @Output() finishedLabels = new EventEmitter<number>();

    @Input() set bprId(value: any) {
        this._bprId = value;
    }
    get bprId(): any {
        return this._bprId;
    }
    constructor(public formingService: FormingService, private httpClient: HttpClient) {

    }

    public doInitialize(rePrintFlag: boolean = false): void {

        this.templateContents = '';
        this.printContents = '';
        this.iterator = 0;
        this.finishedFormRecords = new Array();
        this.labelUrl = Settings.getInstance().BASE_URL + 'formulary/formulary-medications-labels/file/';

        if ((this.bprId === null) || (this.bprId === undefined)) {
            return;
        }

        this.formingService.getAllFinishedFormsByBprId(this.bprId).subscribe(
            (result) => {
                if (result.length === 0) {
                    document.getElementById('labelImgDiv').innerHTML = 'Error: No label records!';
                    return;
                }
                else {
                    this.finishedFormRecords = result;
                    this.labelUrl += this.finishedFormRecords[0].labelFileId;
                    this.parseFileTemplate();
                }
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Fetching Finished Form Records for printing labels');
            });
    }
    public async parseFileTemplate()  {

        const headers = new HttpHeaders();
        //headers.set('Accept', 'image/svg+xml');
        headers.set('Accept', 'html/text');
        await this.httpClient.get(this.labelUrl, {headers, responseType: 'text'}).toPromise()
                .then( res => {

                    this.templateContents = res;
                    this.printContents = '';
                    this.prepareOutput();
                  });
    }
    public prepareOutput(): void {

        if (this.iterator < this.finishedFormRecords.length) {
            this.finishedForm = this.finishedFormRecords[this.iterator];
            this.mergeOutput();
        }
        else {
            this.printOutput();
        }
    }

    public mergeOutput(): void {

        /*** step #1 take the input and replace the tokens with real values ***/
        let labelSVGSpec = '';

        labelSVGSpec = this.mergeData(this.templateContents);

        this.printContents += '<div style=\"height: 11in; width: 8in; border: .5px solid red;\" id=\"lbl' + this.iterator + '\">' + labelSVGSpec + '</div>';
        this.iterator++;
        this.prepareOutput();
    }

    public mergeData(template: string): string {
        let holdTemp = '';
        let hold2Temp = '';

        holdTemp = template.replace(/~ITEMNUMBER~/g, this.finishedForm.itemNumber);
        hold2Temp = holdTemp.replace( /~LOTNUMBER~/g, this.finishedForm.lotNumber);
        holdTemp = hold2Temp.replace(/~RXNUMBER~/g, this.finishedForm.rxNumber);

        hold2Temp = holdTemp.replace( /~EXPIRATIONDATE~/g, this.finishedForm.expirationDate);
        holdTemp = hold2Temp.replace( /~NDCNUMBER~/g, this.finishedForm.ndcNumber);

        hold2Temp = holdTemp.replace( /~DIRECTIONS~/g, this.finishedForm.directions);
        holdTemp = hold2Temp.replace( /~DIRECTIONSALT~/g, this.finishedForm.directionsAlt);

        hold2Temp = holdTemp.replace( /~PRECAUTIONS~/g, this.finishedForm.precautions);
        holdTemp = hold2Temp.replace( /~PRECAUTIONSALT~/g, this.finishedForm.precautionsAlt);

        hold2Temp = holdTemp.replace( /~ITEMBARCODE~/g,  this.setUpBarCode('itemNumberBarCode' + this.iterator));
        holdTemp = hold2Temp.replace( /~NDCBARCODE~/g,  this.setUpBarCode('ndcBarCode' + this.iterator));
        hold2Temp = holdTemp.replace( /~RXBARCODE~/g,  this.setUpBarCode('rxBarCode' + this.iterator));

        holdTemp = hold2Temp.replace('id=\"svg', 'id=\"svg' + this.iterator);

        return holdTemp;
    }
    public setUpBarCode(inpLabel: string): string {

        if (inpLabel.length === 0) {
            return '';
        }
        const retElem = '<div class=\"barCodeCase\"><img id=\"' + inpLabel + '\" class=\"barCodeClass\" /></div>';

        return retElem;

    }
    public printOutput(): void {

        document.getElementById('labelImgDiv').innerHTML = '<div id=\"labelImgDiv\">' + this.printContents + '</div>';

        for (let i = 0; i < this.finishedFormRecords.length; i++) {
            const itemId = '#itemNumberBarCode' + i;
            const ndcId = '#ndcBarCode' + i;
            const rxId = '#rxBarCode' + i;
            JsBarcode(itemId, this.finishedFormRecords[i].itemNumber.replace(/-/g, ''));
            JsBarcode(ndcId, this.finishedFormRecords[i].ndcNumber.replace(/-/g, ''));
            JsBarcode(rxId, this.finishedFormRecords[i].rxNumber.replace(/-/g, ''));
        }
        /*** now output to the printer ***/

        printJS({
            printable: 'labelImgDiv',
            type: 'html',
            onPrintDialogClose: () => {
                this.finishedLabels.emit();
            }
        });
    }
}
