/**
 *  Filename ....: finishbatch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/4/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Forming} from '../../../../models/forming';
import {LabelGenComponent} from '../../../../components/label-gen/label-gen';
import {FormControl} from '@angular/forms';
import {FormingService} from '../../../../services/forming.service';
import {Bpr} from '../../../../models/bpr';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {Settings} from '../../../../services/settings';

@Component({
    selector: 'app-approvebatch',
    templateUrl: './approvebatch.component.html',
    styleUrls: ['./approvebatch.component.css']
})

export class ApproveBatchComponent implements OnInit {

    public _stationsList: any;
    public _selectedForm: Forming;
    public strErr = '';
    public ordNumber: string;

    public labelsRequired: number | null;
    public visualLabelMatch: number | null;
    public itemNumberValidation: number | null;
    public seqNumberRangeVerify: number | null;
    public releaseApproval: number| null;

    public dosesATSActualControl: FormControl;
    public dosesVarianceControl: FormControl;
    public explanationControl: FormControl;

    public showExplanation: boolean | null;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @HostListener ('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }
    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
    }
    get selectedForm(): Forming {
        return this._selectedForm;
    }
    @Input() set stationsList(inp: any) {
        this._stationsList = inp;
    }
    get stationsList(): any {
        return this._stationsList;
    }
    @Input() set orderNumber(inp: string) {
        this.ordNumber = inp;
    }


    constructor(public formingService: FormingService) {
        this.dosesVarianceControl = new FormControl();
        this.explanationControl = new FormControl();
        this.dosesATSActualControl = new FormControl();
        this.showExplanation = false;
    }

    ngOnInit() {

    }
    public doInitialize(): void {

        /*** flip the dosesAtsActual to be a positive number (not negative) ***/
        this.selectedForm.bpr.dosesAtsActual *= -1;

        this.dosesATSActualControl.setValue(Number(this.selectedForm.bpr.dosesAtsActual));

        this.labelsRequired = this.selectedForm.bpr.labelsRequired;
        this.visualLabelMatch = this.selectedForm.bpr.visualLabelMatch;
        this.itemNumberValidation = this.selectedForm.bpr.itemNumberValidation;
        this.seqNumberRangeVerify = this.selectedForm.bpr.seqNumberRangeVerify;
        this.releaseApproval = this.selectedForm.bpr.releaseApproval;
        this.calculateVariance();
    }
    public calculateVariance(): void {

        if ((this.selectedForm.bpr.dosesVariance >= 0.25) || (this.selectedForm.bpr.dosesRemoved  >= 3)) {
            this.showExplanation = true;
        } else {
            this.showExplanation = false;
        }
    }
    public setBPRItems(): void {

        this.selectedForm.bpr.labelsRequired = this.labelsRequired;
        this.selectedForm.bpr.visualLabelMatch = DwUtils.boolToInt(this.visualLabelMatch);
        this.selectedForm.bpr.itemNumberValidation = DwUtils.boolToInt(this.itemNumberValidation);
        this.selectedForm.bpr.seqNumberRangeVerify = DwUtils.boolToInt(this.seqNumberRangeVerify);
        this.selectedForm.bpr.releaseApproval = DwUtils.boolToInt(this.releaseApproval);
    }
    public onSubmitClicked(): void {
        this.setBPRItems();

        const bpr: Bpr | null = new Bpr();
        bpr.setBpr(this.selectedForm.bpr);
        const vr = bpr.validateQCRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            return;
        }

        this.selectedForm.bpr.currentStep = 4;
        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
            (result) => {
                /*** all good ***/
                this.submitClicked.emit();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Error updating BPR from approve batch')
            });
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


