<!--
    file type .....: HTML file: add-catalogues.component.html
    Created By ....: ianday
    Date ..........: 3/16/23 
-->
<div class="add-station-container">
    <div class="dwform" style="padding-right: 15px;">

        <div class="dwform-cell-nopadding" style="min-width: 500px;"
             (keyup)="this.strErr = ''">

            <!----------------------->
            <!-- ID               --->
            <!----------------------->
            <div class="dwform-row-gap"></div>

            <div class="dwform-row">
                <label class="label-field">ID:</label>
                <span class="form-required">*</span>
                <input class="input-field-short" id="id" [disabled]="true"
                       [(ngModel)]="id"
                       style="text-transform: capitalize"
                       placeholder="<Id>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Station Name     --->
            <!----------------------->
            <div class="dwform-row-gap"></div>

            <div class="dwform-row">
                <label class="label-field">Station Name:</label>
                <span class="form-required">*</span>
                <input class="input-field-longest" id="stationHandle"
                       [(ngModel)]="handle"
                       style="text-transform: capitalize"
                       placeholder="<Station Name>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Description      --->
            <!----------------------->
            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Description:</label>
                <span class="form-required"></span>
                <input class="input-field-longest" id="description"
                       [(ngModel)]="description"
                       style="text-transform: capitalize"
                       placeholder="<Description>"
                       type="text">
            </div>

            <!----------------------->
            <!-- Active Status    --->
            <!----------------------->

            <div class="dwform-row-gap"></div>
            <div class="dwform-row">
                <label class="label-field">Active Status</label>
                <span class="form-required"></span>

                <mat-select disableOptionCentering
                            [(value)]="activeStatus" #cbStatus floatLabel="never"
                            class="dw-mat-select"
                            panelClass="local-drop-down-panel"
                            placeholder="<Status>"
                            style="width:95px!important;">
                    <mat-option value="Active">Active</mat-option>
                    <mat-option value="InActive">InActive</mat-option>
                </mat-select>
            </div>

            <!----------------------->
            <!-- ERROR LINE --------->
            <!----------------------->
            <div class="error-line" [hidden]='!strErr.length'
                 style="text-align:left; bottom:47px; margin-left: 13px">{{strErr}}</div>
            <!----------------------->
            <!-- SAVE STRIP        -->
            <!----------------------->
            <div class="save-close-strip" style="bottom: 10px;">

                <button mat-button class="command-button"
                        (click)="doSubmit()" matTooltip="Submit / save this entry"
                        mat-button>
                    <mat-icon>check_circle_outline</mat-icon>
                    Submit
                </button>
                <button mat-button class="command-button"
                        (click)="doCancel()" matTooltip="Cancel without saving this entry" mat-button>
                    <mat-icon>highlight_off</mat-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
