import { ExcelExportService } from '../../../../services/excel-export.service';
import { ReportsService } from '../../../../services/reports.service';
import { ReportParamsComponent } from '../report-params/report-params.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Settings } from '../../../../services/settings';
import { User, ELEMENT_DATA } from 'src/app/models/user';
import { UserService } from '../../../../services/user.service';
import { MatLegacyListItem as MatListItem } from '@angular/material/legacy-list'

@Component({
	selector: 'app-reportsmain',
	templateUrl: './reportsmain.component.html',
	styleUrls: ['./reportsmain.component.css']
})
export class ReportsmainComponent implements OnInit {

	cols: any[];
	reports: any[];
	CurrentReportSelection: any;
	selectedReport: any;
	ReportData: Array<any>;
	ReportParams: Array<any>;
	displayParamsWindow = false;
	loading1 = false;
	loading2 = false;
	disableExportButtons = true;
	noDataFound = false;
	headerRHSData: string;
	headerLHSData: string;
	headerLHSReportName: string;
	params: Array<any>;
	@ViewChild(ReportParamsComponent, { static: true }) ReportParamsComp: ReportParamsComponent;

	constructor(private reportService: ReportsService, private userService: UserService, private excelExportService: ExcelExportService) {
		this.reportService.reportSpecificationsLoadedEvent$.subscribe(item => this.reportSpecificationsLoadedResultHandler(item));
		this.reportService.reportDataLoadedEvent$.subscribe(item => this.reportResultHandler(item));
		this.reportService.reportResultErrorEvent$.subscribe(item => this.reportResultErrorHandler());
	}

	ngOnInit() {

		this.cols = [
			{ field: 'name', header: 'Report Title', width: '100%' }
		];
		this.loading1 = true;
		this.reportService.getReportSpecifications().subscribe(
			reportList => {
				//this.reportSpecificationsLoadedResultHandler(reportList['result']);
				//this.loading1 = false;

				this.reports = reportList;
				this.loading1 = false;
			},
			(error) => {
				Settings.getInstance().handleError(error);
				this.loading1 = false;
			}
		);
		this.userService.getAllUsers().subscribe(
			userList => {
				Settings.getInstance().userList = userList;
			},
			(error) => {
				Settings.getInstance().handleError(error);
			}
		);
	}
	reportSpecificationsLoadedResultHandler(data: any) {
		this.reports = data;
		this.loading1 = false;
	}
	onRowSelect(event) {
		this.CurrentReportSelection = event.data;
	}
	RunClickHandler() {
		this.noDataFound = false;
		this.headerRHSData = '';
		this.headerLHSData = '';
		this.selectedReport = Object.assign({}, this.CurrentReportSelection);
		this.ReportData = [];
		this.ReportParams = null;
		if (this.selectedReport.reportParamas !== null && this.selectedReport.reportParamas.length > 0) {
			this.displayParamsWindow = true;
			this.ReportParams = this.selectedReport.reportParamas;
		} else {
			this.loading2 = true;
			this.reportService.getReportData(this.selectedReport.service, []);
		}
	}
	reportResultHandler(data: any) {
		this.ReportData = data;
		this.loading2 = false;
		const nowDate = new Date();
		const date = (nowDate.getMonth() + 1) + '/' + nowDate.getDate() + '/' + nowDate.getFullYear();
		this.headerRHSData = 'Run: ' + date.toString();
		this.headerLHSReportName = this.CurrentReportSelection.name;
		this.headerLHSData = 'Filter: ';
		this.disableExportButtons = false;
		if (this.params == null || this.params === undefined) {
			this.params = [];
		}
		for (const obj of this.params) {
			if (obj.name === 'UserUid') {
				if (this.headerLHSData === 'Filter: ') {
					this.headerLHSData = this.headerLHSData + ' ' + Settings.getInstance().getResourceFullName(obj.value) + ' (' + obj.value + ')';
				} else {
					this.headerLHSData = this.headerLHSData + ', ' + Settings.getInstance().getResourceFullName(obj.value) + ' (' + obj.value + ')';
				}
			}
			if (obj.name === 'StartDate') {
				const date1 = new Date(obj.value);
				const strDate = (date1.getMonth() + 1) + '/' + date1.getDate() + '/' + date1.getFullYear();
				if (this.headerLHSData === 'Filter: ') {
					this.headerLHSData = this.headerLHSData + ' Start Date: ' + strDate;
				} else {
					this.headerLHSData = this.headerLHSData + ', Start Date: ' + strDate;
				}
			}
			if (obj.name === 'EndDate') {
				const date1 = new Date(obj.value);
				const strDate = (date1.getMonth() + 1) + '/' + date1.getDate() + '/' + date1.getFullYear();
				this.headerLHSData = this.headerLHSData + ', End Date: ' + strDate;
			}

		}
		if (this.ReportParams === undefined || this.ReportParams === null) {
			this.headerLHSData = 'Filter: NA';
		}

		if (data === undefined || data.length === 0) {
			this.noDataFound = true;
		} else {

		}
	}
	reportResultErrorHandler() {
		this.loading2 = false;
	}
	reportParamsRunClickHandler() {
		this.displayParamsWindow = false;
		this.loading2 = true;

		this.params = new Array<any>();
		for (let i = 0; i < this.selectedReport.reportParamas.length; i++) {
			switch (this.selectedReport.reportParamas[i].name) {
				case 'UserUid': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.valSelectedEmployee }); break;
				case 'StartDate': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.valStartDate.getTime() }); break;
				case 'EndDate': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.valEndDate.getTime() }); break;
				case 'DateFieldTarget': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.selectedDateCategory.data }); break;
				case 'Location': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.selectedLocation == null ? 'ALL' : this.ReportParamsComp.selectedLocation.LocationCode }); break;
				case 'Program': this.params.push({ 'name': this.selectedReport.reportParamas[i].name, 'value': this.ReportParamsComp.selectedProgram == null ? 'ALL' : this.ReportParamsComp.selectedProgram.ProgramCode }); break;
				case 'SkipTeamTotal': this.params.push({
					'name': this.selectedReport.reportParamas[i].name,
					'value': ((this.ReportParamsComp.valSkipTeamTotal !== undefined && this.ReportParamsComp.valSkipTeamTotal.length > 0) ? this.ReportParamsComp.valSkipTeamTotal[0] : '0')
				}); break;
			}
		}
		this.reportService.getReportData(this.selectedReport.service, this.params);
		//console.log(this.params);
	}
	reportParamsCancelClickHandler() {
		this.displayParamsWindow = false;
	}
	excelClickHandler() {
		const data = new Array<any>();
		const obj1 = new Array<string>();
		obj1.push(this.headerLHSData);
		data.push(obj1);

		for (let i = 0; i < this.ReportData.length; i++) {
			const obj = new Array<any>();
			for (let j = 0; j < this.ReportData[i].length; j++) {
				const value = this.ReportData[i][j].label;
				if (isNaN(Number.parseInt(value))) {
					obj.push(value);
				} else {
					if (value.includes('/') || value.includes('-') || value.includes('\\') || value.includes('$')) {
						obj.push(value);
					} else {
						let converted_number: number = +value;
						obj.push(converted_number);
					}
				}
			}
			data.push(obj);
		}
		this.excelExportService.exportArrayOfArrayAsExcelFile(data, this.selectedReport.name);
	}

	exportAsTextClickHandler() {
		let strData = '';

		for (let i = 0; i < this.ReportData.length; i++) {
			for (let j = 0; j < this.ReportData[i].length; j++) {
				const value = this.ReportData[i][j].label;
				strData = strData + ''+ value + '\n';
			}
		}
		let dt: Date = new Date();
		if(this.params && this.params['endDate']){
			dt = new Date(this.params['endDate']);
		}
		let strFileName=this.getQuarterFromDate(dt);
		this.exportAsTextFile(strData, strFileName);
	}
	getQuarterFromDate(date) {
		  const year = date.getFullYear();
		  const month = date.getMonth();
		
		  let quarter;
		  if (month >= 0 && month <= 2) {
		    quarter = "Q1";
		  } else if (month >= 3 && month <= 5) {
		    quarter = "Q2";
		  } else if (month >= 6 && month <= 8) {
		    quarter = "Q3";
		  } else {
		    quarter = "Q4";
		  }
		
		  return `arcos${year}${quarter}`;
		}


	exportAsTextFile(data: string, fileName: string) {
		const blob = new Blob([data], { type: 'text/plain' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}
	printClickHandler() {
		//const divToPrint= document.getElementById('reportPane');
		//let newWin: Window = window.open('');
		//newWin.document.write(divToPrint.outerHTML);
		//newWin.print();
		//newWin.close();
		let printContents, reportName, popupWin;
		printContents = document.getElementById('reportPane').innerHTML;
		reportName = this.CurrentReportSelection.Name;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
      <html>
        <head>
          <title>${reportName}</title>
          <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" />
          <style type="text/css">
              table { page-break-after:auto }
              tr    { page-break-inside:avoid; page-break-after:auto }
              td    { page-break-inside:avoid; page-break-after:auto }
              thead { display:table-header-group }
              tfoot { display:table-footer-group }
              .no-print {
                display : inherit;
              }
              .print-only {
                display : none;
              }
              @media print {
                  .print-only {
                    display : block;
                }
                .no-print {
                  display : none;
                }
              }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
		);
		popupWin.document.close();
	}

}
