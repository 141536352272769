<mat-drawer-container class="mat-elevation-z2 forming-container">
    <mat-drawer disableClose="true" mode="side" opened style="width:165px;">
        <mat-action-list >
            <span>
			  <button id="ordersItem" *ngIf="visibleNewOrdersLink" matTooltipPosition="right"
                       matTooltip="Manage New Orders" class="button-menu"mat-list-item
                      (click)="showNewOrders()"> New Orders
              <span style="padding-left: 30px"></span>
              <span class="circleShape" id="ordersCircleId">{{newOrdersCount}}</span></button>

            </span>
            <hr width="90%">
            <span>
			  <button id="prepareItem" *ngIf="visiblePreparationLink" matTooltipPosition="right"
                       matTooltip="Prepare fulfillment" class="button-menu" mat-list-item
                       (click)="showPreparation()"  > 1. Preparation
                <span style="padding-left: 18px"></span>
                <span class="circleShape" id="prepCircleId">{{preparationCount}}</span></button>
            </span>

            <span>
			  <button id="inProcessItem"  *ngIf="visibleInProcessLink" matTooltipPosition="right"
                       matTooltip="In Process Items" class="button-menu" mat-list-item
                       (click)="showInProcess()"  > 2. In Process
                <span style="padding-left: 24px"></span>
                <span class="circleShape" id="inProcessCircleId">{{inProcessCount}}</span></button>
            </span>

            <span>
			  <button id="qcItem" *ngIf="visibleQCLink" matTooltipPosition="right"
                       matTooltip="Quality Control Steps" class="button-menu" mat-list-item
                       (click)="showQC()"  > 3. Quality Ctrl
                <span style="padding-left: 20px"></span>
                <span class="circleShape" id="qualityCircleId">{{qualityCount}}</span></button>
            </span>
            <span>
              <button id="releaseItem" *ngIf="visibleReleaseLink" matTooltipPosition="right"
                          matTooltip="Release Item for shipping" class="button-menu" mat-list-item
                          (click)="showRelease()"  > 4. Release
                <span style="padding-left: 38px"></span>
                <span class="circleShape" id="releaseCircleId">{{releaseCount}}</span> </button>
            </span>
            <span>
			  <button id="shipItem" *ngIf="visibleShippingLink" matTooltipPosition="right"
                      matTooltip="Ship product" class="button-menu" mat-list-item
                      (click)="showShipping()"  > 5. Shipping
                <span style="padding-left: 35px"></span>
                <span class="circleShape" id="shippingCircleId">{{shippingCount}}</span> </button>
            </span>
            <hr width="90%">

            <span>
			  <button id="completedItem" *ngIf="visibleCompletedOrdersLink" matTooltipPosition="right"
                       matTooltip="View Completed Orders" class="button-menu" mat-list-item
                       (click)="showCompletedOrders()"  > Completed Orders </button>
            </span>

            <hr width="90%">

			<span>
			  <button id="customerItem" *ngIf="visibleCustomerMasterLink" matTooltipPosition="right"
                       matTooltip="Manage Customers" class="button-menu" mat-list-item
                       (click)="showCustomers()"  > Customer Master </button>
            </span>

        </mat-action-list>
    </mat-drawer>

    <mat-drawer-content class="mat-drawer-content-container">
        <app-neworders #newOrders *ngIf="visibleNewOrders"
            (orderCountChangedEvent)="updateNewOrdersCount($event)"
            (countChangedEvent)="fetchOrderCounts()"
        ></app-neworders>
        <app-preparation #prepare *ngIf="visiblePreparation" (updateCounters)="fetchOrderCounts()"></app-preparation>
        <app-inprocess #inProcess *ngIf="visibleInProcess" (updateCounters)="fetchOrderCounts()" ></app-inprocess>
        <app-qualitycontrol #quality *ngIf="visibleQC" (updateCounters)="fetchOrderCounts()"></app-qualitycontrol>
        <app-release #release *ngIf="visibleRelease" (updateCounters)="fetchOrderCounts()"></app-release>
        <app-shipping #shipping *ngIf="visibleShipping" (updateCounters)="fetchOrderCounts()"></app-shipping>
        <app-completedorders *ngIf="visibleCompletedOrders"></app-completedorders>
        <app-customermaster *ngIf="visibleCustomerMaster"></app-customermaster>
    </mat-drawer-content>
</mat-drawer-container>
