<div class="print-button-container">
  <button mat-button style="width: 100px; scale: 85%" [disabled]="false" class="command-button" matTooltip="Print BPR" (click)="printPage()" mat-button>
    <mat-icon>print</mat-icon>
    Print
  </button>
</div>
<div *ngIf="this.record && this.record.id!==0">
	 
	<div class="bpr-container" id="sectiontoprint" #sectiontoprint>
	  	 <div class="header">
	    <span class="bpr"><strong>BPR:</strong> {{currentStepBPRId}}</span>
	    <span class="status"><strong>Status:</strong> {{currentStepBPRStatus}}</span>
	  </div>
	 	
		<div class="line">
		  <hr>
		  <span class="title" [ngClass]="{'highlighted-step': isStepHighlighted(1)}">1. PREPARE</span>
		  <hr>
		</div>
		<table class="entries-table">
		    <tr>
		      <td class="image-column">
	       		 <img  class="image-flag"  *ngIf="!isStorageTempZero()" src="assets/greenTick.png" alt="Completed">
	       		 <img  class="image-flag-small"   *ngIf="isStorageTempZero()" src="assets/delete-icon-small.png" alt="Pending">
	      	  </td>
		      <td class="label-col">Storage Temperature</td>
		     <td class="value-col">{{ record.storageTemp }} degrees</td>
		    </tr>
		   
		    <tr>
		    <td class="image-column">
	       		 <img class="image-flag" *ngIf="!isStorageHumidityZero()" src="assets/greenTick.png" alt="Completed">
	       		 <img class="image-flag-small" *ngIf="isStorageHumidityZero()" src="assets/delete-icon-small.png" alt="Pending">
	      	  </td>
		      <td class="label-col">Storage Humidity</td>
		      <td class="value-col">{{ record.storageHumidity }}%</td>
		    </tr>
		    
		     <tr>
		    <td class="image-column">
	       		 <img class="image-flag" *ngIf="record.stationId !== undefined && record.stationId !== 0" src="assets/greenTick.png" alt="Completed">
	       		 <img class="image-flag-small" *ngIf="record.stationId === undefined || record.stationId === 0" src="assets/delete-icon-small.png" alt="Pending">
	      	  </td>
		      <td class="label-col">Station</td>
		      <td class="value-col">{{getStationName(record.stationId)}}</td>
		    </tr>

			<!-- PREP VERIFY and APPROVE DIRECTIONS AND PRECAUTIONS -->
			<tr>
			  <td class="image-column">
			    <img class="image-flag" *ngIf="!isPrepApprovePrecDirZero()" src="assets/greenTick.png" alt="Completed">
			    <img class="image-flag-small" *ngIf="isPrepApprovePrecDirZero()" src="assets/delete-icon-small.png" alt="Pending">
			  </td>
			  <td class="label-col">Verify Precautions/Directions.</td>
			  <td class="value-col">{{ getYesNo(record.prepApproveDirPrec) }}</td>
			</tr>

			<!-- PREP LABEL IMAGE VERIFIED --->
			<tr>
				<td class="image-column">
					<img class="image-flag" *ngIf="!isPrepLabelImgVerifyZero()" src="assets/greenTick.png" alt="Completed">
					<img class="image-flag-small" *ngIf="isPrepLabelImgVerifyZero()"  src="assets/delete-icon-small.png" alt="Pending">
				</td>
				<td class="label-col">Verify Label Image</td>
				<td class="value-col">{{ getYesNo(record.prepLabelImgVerify) }}</td>
			</tr>

			<!-- PREP DOSAGE IMAGE VERIFIED --->
			<tr>
				<td class="image-column">
					<img class="image-flag" *ngIf="!isPrepDosageImgVerifyZero()" src="assets/greenTick.png" alt="Completed">
					<img class="image-flag-small" *ngIf="isPrepDosageImgVerifyZero()"  src="assets/delete-icon-small.png" alt="Pending">
				</td>
				<td class="label-col">Verify Dosage Image</td>
				<td class="value-col">{{ getYesNo(record.prepDosageImgVerify) }}</td>
			</tr>


		    <tr>
		    <td class="image-column">
	       		 <img class="image-flag" *ngIf="record.storageHumidity !== undefined" src="assets/greenTick.png" alt="Completed">
	       		 <img class="image-flag-small" *ngIf="record.storageHumidity === undefined" src="assets/delete-icon-small.png" alt="Pending">
	      	  </td>
		      <td class="label-col">Bulk Items</td>
		     <td class="value-col">
				    <button class="view-button print-hidden" mat-button (click)="openBulkIemsDialog()">View</button>
				    <button class="view-button print-visible" mat-button (click)="openBulkIemsDialog()">View</button>
			 </td>
		    </tr>
		    
		    
		</table>
		
		<div class="line">
		  <hr>
		  <span class="title" [ngClass]="{'highlighted-step': isStepHighlighted(2)}">2. IN PROCESS</span>
		  <hr>
		</div> 
		
		<table class="entries-table">
			  <tr>
			    <td class="image-column">
			      <img class="image-flag" *ngIf="!isPackageTemperatureZero()" src="assets/greenTick.png" alt="Completed">
			      <img class="image-flag-small" *ngIf="isPackageTemperatureZero()" src="assets/delete-icon-small.png" alt="Pending">
			    </td>
			    <td class="label-col">Package Temperature</td>
			    <td class="value-col">{{record.packageTemperature}} degrees</td>
			  </tr>
			  
			  <tr>
			    <td class="image-column">
			      <img class="image-flag" *ngIf="!isPackageHumidityZero()" src="assets/greenTick.png" alt="Completed">
			      <img class="image-flag-small" *ngIf="isPackageHumidityZero()" src="assets/delete-icon-small.png" alt="Pending">
			    </td>
			    <td class="label-col">Package Humidity</td>
			    <td class="value-col">{{record.packageHumidity}}%</td>
			  </tr>

			 <tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isUnitsOrderedZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isUnitsOrderedZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Units Ordered</td>
				  <td class="value-col">{{ record.unitsOrdered }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isDosesPerUnitZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isDosesPerUnitZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Doses / Unit</td>
				  <td class="value-col">{{ record.dosesPerUnit }}</td>
			</tr>
			  
			 <tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isDosesRequiredZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isDosesRequiredZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Doses Required</td>
				  <td class="value-col">{{ record.dosesRequired }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isDosesRemovedZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isDosesRemovedZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Doses Removed</td>
				  <td class="value-col">{{ record.dosesRemoved }}</td>
				</tr>
			</table>
		
		<div class="line">
		  <hr>
		  <span class="title" [ngClass]="{'highlighted-step': isStepHighlighted(3)}">3. QC</span>
		  <hr>
		</div> 
		
		<table class="entries-table">
			  <tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isLabelsRequiredZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isLabelsRequiredZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Labels Required</td>
				  <td class="value-col">{{ record.labelsRequired }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isVisualLabelMatchZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isVisualLabelMatchZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Visual label match</td>
				  <td class="value-col">{{ getYesNo(record.visualLabelMatch) }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isDosesATSActualZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isDosesATSActualZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Doses ATS actual</td>
				  <td class="value-col">{{ record.dosesAtsActual }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isDosesVarianceZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isDosesVarianceZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Doses Variance</td>
				  <td class="value-col">{{ record.dosesVariance }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isExplanationEmpty()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isExplanationEmpty()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Explanation</td>
				  <td class="value-col-explanation">
					  <span [matTooltip]="record.explanation">{{ getClippedExplanation(record.explanation) }}</span>
				  </td>
				</tr>
	
			 <tr>
			  <td class="image-column">
			    <img class="image-flag" *ngIf="!isItemNumberValidationZero()" src="assets/greenTick.png" alt="Completed">
			    <img class="image-flag-small" *ngIf="isItemNumberValidationZero()" src="assets/delete-icon-small.png" alt="Pending">
			  </td>
			  <td class="label-col">Item Number Validation</td>
			  <td class="value-col">{{ getYesNo(record.itemNumberValidation) }}</td>
			</tr>
			
			<tr>
			  <td class="image-column">
			    <img class="image-flag" *ngIf="!isSeqNumberRangeVerifyZero()" src="assets/greenTick.png" alt="Completed">
			    <img class="image-flag-small" *ngIf="isSeqNumberRangeVerifyZero()" src="assets/delete-icon-small.png" alt="Pending">
			  </td>
			  <td class="label-col">Seq. number range verify</td>
			  <td class="value-col">{{ getYesNo(record.seqNumberRangeVerify) }}</td>
			</tr>
			
			<tr>
			  <td class="image-column">
			    <img class="image-flag" *ngIf="!isReleaseApprovalZero()" src="assets/greenTick.png" alt="Completed">
			    <img class="image-flag-small" *ngIf="isReleaseApprovalZero()" src="assets/delete-icon-small.png" alt="Pending">
			  </td>
			  <td class="label-col">Release Approval</td>
			  <td class="value-col">{{ getYesNo(record.releaseApproval) }}</td>
			</tr>
		</table>
		
		
		<div class="line">
		  <hr>
		  <span class="title" [ngClass]="{'highlighted-step': isStepHighlighted(4)}">4. RELEASE</span>
		  <hr>
		</div> 
		<table class="entries-table">
			  <tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isVerifiedUnitsZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isVerifiedUnitsZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Verified Units</td>
				  <td class="value-col">{{ record.verifiedUnits }}</td>
				</tr>
				
				<tr>
				  <td class="image-column">
				    <img class="image-flag" *ngIf="!isApproveToShipZero()" src="assets/greenTick.png" alt="Completed">
				    <img class="image-flag-small" *ngIf="isApproveToShipZero()" src="assets/delete-icon-small.png" alt="Pending">
				  </td>
				  <td class="label-col">Approve to Ship</td>
				  <td class="value-col">{{ getYesNo(record.approveToShip) }}</td>
				</tr>
	
		</table>
		
		<div class="line">
		  <hr>
		  <span class="title" [ngClass]="{'highlighted-step': isStepHighlighted(5)}">5. SHIP</span>
		  <hr>
		</div> 
	 	<table class="entries-table">
			 <tr>
			  <td class="image-column">
			    <img class="image-flag" *ngIf="!isShippingCompletedZero()" src="assets/greenTick.png" alt="Completed">
			    <img class="image-flag-small" *ngIf="isShippingCompletedZero()" src="assets/delete-icon-small.png" alt="Pending">
			  </td>
			  <td class="label-col">Shipping Completed</td>
			  <td class="value-col">{{ getYesNo(record.shippingCompleted) }}</td>
			</tr>
	
		</table>
	 
	</div>
</div>

<div *ngIf="!this.record || this.record.id===0" style="margin-top: 200px; text-align: center;">
	<span>No record selected</span>
</div>
<p-dialog header="Bulk Item Details"
	          [appendTo]="'body'"
	          [(visible)]="displayBulkItemsDlg"
	          [modal]="true" [responsive]="true"
	          [style]="{position: 'absolute', width: '1200px', height: '900px'}"
	          [minY]="85" [resizable]="false" [closable]="true">
	    <app-bulkitemsview #changeAssignments
	          [bprId]="record.id"
	          (cancelClicked)="closeBulkIemsDialog()">
	    </app-bulkitemsview>
	</p-dialog>
