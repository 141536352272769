<div class="app-container" id="content">
  <app-header *ngIf="!fullScreen" [sidenav]="leftSidenav" (settingsLoaded)="settingsLoaded()" ></app-header>
  <div  id="bannerDiv" class="banner" *ngIf="enableMessageBanner && !fullScreen" [ngStyle]="bgStyleString">{{strBannerText}}</div>
  <mat-sidenav-container>
    <mat-sidenav id="main-side-nav" *ngIf="!fullScreen"  [disableClose]="true"
                 class="sidenav-strip" #leftSidenav mode="side" opened>
      <app-left-menu id="main-left-menu"></app-left-menu>
    </mat-sidenav>
    <mat-sidenav-content >
      <div [ngClass]="contentClassName">
        <div class="inner-main-content">
          <router-outlet id="routerOutlet"></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer class="footer-container" #FooterArea></app-footer>
</div>
<p-dialog styleClass="app-dialog" style="overflow: hidden" modal="true" [appendTo]="'body'" [resizable]="false"
          header="{{messageDialogData?.title}}" [(visible)]="messageDlgDisplay">
	<app-message-box #ConfirmDialog [data]="this.messageDialogData"
            (onClose)="messageCloseHandler($event)" >
    </app-message-box>
</p-dialog>
