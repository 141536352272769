/************
 * Model Class
 * File Name ...: stations.components.ts
 * Generated By : ianday on 3/16/23
 * Date ........: 3/16/23
 /************/
import {ValidationResult} from './validation-result';

export interface Sites {
    id: number | null;
    handle: string | null;
    description: string | null;
    siteCode: string | null;
    sitePrincipal: string | null;
    activeStatus: string | null;
}

export class Sites {
    constructor(sitesParam: string = '') {
        this.id = 0;
        this.handle = sitesParam;
        this.description = '';
        this.siteCode = '';
        this.sitePrincipal = '';
        this.activeStatus = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.handle.length === 0) {
            vr.focus_field = 'handle';                              /* this should be the id value of the field */
            vr.error = 'Error: a short description is required';
            return vr;
        }

        return vr;
    }
}
