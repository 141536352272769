<!------------------------------------->
<!-- List tab detail layout.
 * File Name ..: neworders.component.html
 * Generated by: ianday
 * Date .......: 6/15/23
--------------------------------------->

<div class="neworders-form-container">
    <div class="query-strip-container mat-elevation-z2">
        <table height="100%">
            <TR>
                <TD class="table-title-cell">
                    <i class="	fa fa-cart-plus fa-fw table-icon-header"
                       style="width: 2em"></i>{{formTitle}}
                </TD>

                <TD align="left" style="min-width: 400px;">
                    <input text id="txtFilter" #txtFilter
                           class="qs_filter-field" [disabled]="this.isEditing"
                           (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
                    <button mat-button matSuffix mat-icon-button
                            matTooltip="Clear the filter" aria-label="Clear"
                            (click)="txtFilter.value=''; clearFilter();">
                        <mat-icon>close</mat-icon>
                    </button>
                </TD>
                <TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
                <TD style="width: 150px">
                    <mat-select
                            disableOptionCentering
                            [(value)]="selectedStatusFilter" #cbStatus floatLabel="never"
                            class="dw-mat-select"
                            style="font-size: 10pt;width: 120px;"
                            panelClass="local-drop-down-panel"
                            (selectionChange)="txtFilter.value=''; onChangeStatusFilterValue()">
                        <mat-option *ngFor="let item of statusFilterList"
                                    [value]="item">{{item.description}}
                        </mat-option>
                    </mat-select>
                </TD>
                <TD class="table-pagination-cell" align="right">{{pagination}} Record(s) ...</TD>
            </TR>
        </table>
    </div>

    <div class="main-client-area">
        <!--------------------------->
        <!-- RECORDS TABLE         -->
        <!--------------------------->
        <div #tb1 [ngClass]="contentClassName" class="table-container mat-elevation-z1"
             (keydown)="tableKeyDown($event)" tabindex="-1">
            <table mat-table [dataSource]="dataSource" matSort
                   (matSortChange)="onSortChange($event)">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on
                  the row definition" -->

                <!-- Order Number -->

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 110px;">Order No.
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.orderNumber}}</td>
                </ng-container>

                <!-- Customer Name -->
                <ng-container matColumnDef="subCustomerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 180px;">Customer
                    </th>
                    <td mat-cell class="dw-row-class" *matCellDef="let element;let tableRowIdx = index;">
                        {{element.subCustomerName}}</td>
                </ng-container>

                <!-- Order By Full Name -->
                <ng-container matColumnDef="orderByFullName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 120px;">Created By
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.orderByFullName}}</td>
                </ng-container>

                <!-- Customer Reference -->
                <ng-container matColumnDef="customerReference">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 150px;">Reference
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.customerReference}}</td>
                </ng-container>

                <!-- Order Comments  -->
                <ng-container matColumnDef="orderComments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 190px;">Comments
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.orderComments}}</td>
                </ng-container>

                <!-- Submit Method  -->
                <ng-container matColumnDef="submitMethod">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 120px;">Submit Method
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{element.submitMethod}}</td>
                </ng-container>

                <!-- Date Submitted -->
                <ng-container matColumnDef="dateSubmitted">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 120px;">Date Submitted
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{labelFmtDT(element.dateSubmitted)}}</td>
                </ng-container>

                <!-- Date Closed -->
                <ng-container matColumnDef="dateClosed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 120px;">Date Closed
                    </th>
                    <td mat-cell class="dw-row-class"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{labelFmtDT(element.dateClosed)}}</td>
                </ng-container>

                <!-- Date Closed -->
                <ng-container matColumnDef="grandTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; width: 90px;">Grand Total
                    </th>
                    <td mat-cell class="dw-row-class" style="text-align: right;padding-right: 12px;"
                        *matCellDef="let element;let tableRowIdx = index;">
                        {{formatCurrency(element.grandTotal)}}</td>
                </ng-container>
                <!-- Active Status -->
                <ng-container matColumnDef="activeStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="background-color: white; z-index:99999; min-width: 150px;">Status
                    </th>
                    <td mat-cell class="dw-row-class" *matCellDef="let element">
                        <mat-select *ngIf="selectedRecord?.id == element.id"
                                    disableOptionCentering
                                    [(value)]="element.activeStatus" #cbStatus
                                    floatLabel="never"
                                    class="dw-mat-select"
                                    panelClass="local-drop-down-panel"
                                    style="font-size: 9pt; width:95px!important;"
                                    (selectionChange)="onStatusChange()"
                                    [disabled]="selectedRecord?.id !== element.id">
                         <mat-option *ngFor="let item of selectableStatuses"
                                        [value]="item.id">{{item.description}}</mat-option>
                        </mat-select>

                        <span *ngIf="selectedRecord?.id !== element.id"
                              style="padding-left: 3px;font-size: 9pt;">{{element.activeStatus}}</span>

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                    [ngClass]="{'row-selected': tableRowIdx == Idx}"
                    (click)="onRowClicked(row)" (dblclick)="onRowDoubleClicked(row)">
                </tr>
            </table>
        </div>
        <!-------------------------------------->
        <!-- Control Strip                    -->
        <!-------------------------------------->
        <div class="mat-elevation-z2 control-strip">
            <button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
                    matTooltipPosition="above"
                    class="command-button" matTooltip="Add a new record"
                    (click)="addModifyRecord(true)" mat-button>
                <mat-icon>add_circle_outline</mat-icon>
                Add New
            </button>
            <button *ngIf="visibleModify" mat-button class="command-button"
                    matTooltipPosition="above"
                    style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
                    matTooltip="Update a selected record" (click)="addModifyRecord(false)"
                    mat-button>
                <mat-icon>edit</mat-icon>
                Update
            </button>
            <button *ngIf="visibleModify" mat-button class="command-button"
                    matTooltipPosition="above"
                    style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
                    matTooltip="Accept order items for preparation" (click)="doAcceptOrderItems()"
                    mat-button>
                <mat-icon>check</mat-icon>
                Accept
             </button>
              <button *ngIf="visibleSendBtn" mat-button
                    [disabled]="false" [disabled]="(Idx < 0|| this.isEditing)"
                    matTooltipPosition="above" style="margin-top: 5px;"
                    class="command-button" matTooltip="Send Order Receipt"
                    (click)="sendSelectedOrder()"
                    mat-button>
                <mat-icon>email</mat-icon>
                Send
              </button>
        </div>
        <!------------------------------->
        <!--  Detail panel             -->
        <!------------------------------->
        <div id="neworders-details-panel" class="neworders-detail-container mat-elevation-z2"
             style="margin-bottom: 2px;">
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="doTabChange($event)"
                           [(selectedIndex)]=selectedTabIndex>
                <mat-tab label="Summary" [disabled]="isEditing">
                    <div class="sub-tab-container-class detail-tab-additional">
                        <div class="dwform" style="padding-right: 15px;">

                            <div class="dwform-cell-nopadding" style="min-width: 450px;">

                                <!--order -->
                                <div class="dwform-row-double-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field">Order #:</label> <span
                                        class="form-required"> </span>
                                    <input class="input-field-short" style="width:80px" disabled="true"
                                           [(ngModel)]="selectedRecord.orderNumber"
                                           type="text">
                                </div>

                                <!-- customer id -->
                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field">Customer:</label> <span
                                        class="form-required"> </span>
                                    <input class="input-field-short" style="width:280px" disabled="true"
                                           [(ngModel)]="selectedRecord.subCustomerName"
                                           type="text">
                                </div>

                                <!-- ordered by -->

                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field">Ordered By:</label> <span
                                        class="form-required"> </span>
                                    <input class="input-field-short" style="width:280px" disabled="true"
                                           [(ngModel)]="selectedRecord.orderByFullName"
                                           type="text">
                                </div>

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Reference:</label>
                                    <span class="form-required"> </span>
                                    <input class="input-field" style="width:100px" [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.customerReference" type="text">

                                </div>

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field" style="vertical-align: top">Comments:</label>
                                    <span class="form-required"> </span>
                                    <textarea class="input-field text-area-override"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.orderComments" type="text">
                                    </textarea>
                                    <div class="dwform-row-gap"></div>
                                </div>

                            </div>
                            <div class="dwform-cell-nopadding" style="min-width: 350px;">

                                <div class="dwform-row">
                                    <label class="label-field">Order Status:</label> <span
                                        class="form-required"></span>
                                    <input class="input-field" style="width:120px" [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.orderStatus" type="text">
                                </div>

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Shipping Method:</label>
                                    <span class="form-required"></span>
                                    <input class="input-field" style="width:260px"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.shippingService" type="text">

                                <div class="dwform-row-gap"></div>

                                    <div class="dwform-row">
                                    <label class="label-field">Order Total:</label>
                                    <span class="form-required"></span>
                                    <input class="input-field" style="width:120px;text-align:right;"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="'$'+selectedRecord.orderTotal" type="text">

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Shipping:</label>
                                    <span class="form-required"></span>
                                    <input class="input-field" style="width:120px;text-align:right;"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="'$'+selectedRecord.shippingTotal" type="text">

                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Tax:</label>
                                    <span class="form-required"></span>
                                    <input class="input-field" style="width:120px;text-align:right;"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="'$'+selectedRecord.salesTax" type="text">

                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Grand Total:</label>
                                    <span class="form-required"></span>
                                    <input class="input-field" style="width:120px; text-align:right;"
                                           [disabled]="!isEditing"
                                           [(ngModel)]="'$'+selectedRecord.grandTotal" type="text">
                                     </div>
                                </div>
                            </div>

                            </div>
                            <div class="dwform-cell-nopadding " style="min-width: 300px;">

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Record Status:</label>
                                    <span class="form-required"> </span>
                                    <input class="input-field" style="width:120px" [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.activeStatus" type="text">
                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field">Submit Method:</label>
                                    <span class="form-required"> </span>
                                    <input class="input-field" style="width:120px" [disabled]="!isEditing"
                                           [(ngModel)]="selectedRecord.submitMethod" type="text">
                                </div>

                                <!-- double row bap for the label series -->

                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row" style="height:28px">
                                    <label class="label-field">Date created:</label>
                                    <span class="form-required"> </span>
                                    <label style="width:140px;">
                                        {{labelDateFmtDT(selectedRecord.dateCreated)}}</label>

                                </div>

                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row" style="height:28px;">
                                    <label class="label-field">Date Submitted:</label>
                                    <span class="form-required"> </span>
                                    <label style="width:140px; ">
                                        {{labelDateFmtDT(selectedRecord.dateSubmitted)}}</label>

                                </div>

                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row" style="height:28px;">
                                    <label class="label-field">Date Closed:</label>
                                    <span class="form-required"> </span>
                                    <label style="width:140px; height: 28px;">
                                        {{labelDateFmtDT(selectedRecord.dateClosed)}}</label>
                                </div>
                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row" style="height:28px;">
                                    <label class="label-field">Days Left:</label>
                                    <span class="form-required"> </span>
                                    <label style="width:140px; height: 28px;">
                                        {{'to calculate'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Items">
                    <div class="sub-tab-container-class detail-tab-additional">
                        <div class="item-table-div">
                        <table mat-table [dataSource]="itemDataSource" style="width:100%">

                      <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on
                      the row definition" -->

                      <!-- quantity -->
                      <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef
                                style="background-color: white; z-index:99999; min-width: 50px;">Qty
                            </th>
                            <td mat-cell class="dw-row-class"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.quantity}}</td>
                        </ng-container>
                        <!-- item description -->
                        <ng-container matColumnDef="itemPrefix">
                            <th mat-header-cell *matHeaderCellDef
                                style="background-color: white; z-index:99999; min-width: 250px;">Item
                            </th>
                            <td mat-cell class="dw-row-class"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{element.itemPrefix + ' - '+element.description}}</td>
                        </ng-container>
                        <!-- unit price -->
                        <ng-container matColumnDef="unitPrice">
                            <th mat-header-cell *matHeaderCellDef
                                style="background-color: white; z-index:99999; min-width: 120px;">Unit
                            </th>
                            <td mat-cell class="dw-row-class"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{formatCurrency(element.unitPrice)}}</td>
                        </ng-container>
                        <!-- extended price -->
                        <ng-container matColumnDef="extendedPrice">
                            <th mat-header-cell *matHeaderCellDef
                                style="background-color: white; z-index:99999; min-width: 120px;">Extended
                            </th>
                            <td mat-cell class="dw-row-class"
                                *matCellDef="let element;let tableRowIdx = index;">
                                {{formatCurrency(element.extendedPrice)}}</td>
                        </ng-container>
                            <!-- extended price -->
                            <ng-container matColumnDef="itemStatus">
                                <th mat-header-cell *matHeaderCellDef
                                    style="background-color: white; z-index:99999; min-width: 120px;">Status
                                </th>
                                <td mat-cell class="dw-row-class"
                                    *matCellDef="let element;let tableRowIdx = index;">
                                    {{element.itemStatus}}</td>
                            </ng-container>
                        <tr mat-header-row
                            *matHeaderRowDef="itemDisplayedColumns; sticky: true"
                            style="font-size:9pt"></tr>
                        <tr mat-row
                            *matRowDef="let row; columns: itemDisplayedColumns; let tableRowIdx = index;"
                            [ngClass]="{'row-selected': tableRowIdx == itemIdx}"
                            (click)="onItemRowClicked(row)">
                        </tr>
                    </table>
                    </div>
                        <div class="item-detail-div">
                            <div class="dwform" style="min-width: 300px;">
                                <div class="dwform-row-gap"></div>
                                <div class="dwform-row">
                                    <label class="label-field" style="vertical-align:top">Precautions:</label>
                                    <span class="form-required"> </span>
                                    <textarea class="input-field text-area-override" [disabled]="true"
                                           [(ngModel)]="selectedItemRecord.precautions" type="text">
                                    </textarea>
                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field" style="vertical-align:top">Directions:</label>
                                    <span class="form-required"> </span>
                                    <textarea class="input-field text-area-override" [disabled]="true"
                                           [(ngModel)]="selectedItemRecord.directions" type="text">
                                    </textarea>
                                </div>
                                <div class="dwform-row-gap"></div>

                                <div class="dwform-row">
                                    <label class="label-field" style="vertical-align:top">Comments:</label>
                                    <span class="form-required"> </span>
                                    <textarea class="input-field text-area-override" [disabled]="true"
                                           [(ngModel)]="selectedItemRecord.orderItemComments" type="text">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="History" [disabled]="isEditing">
                    <div class="sub-tab-container-class detail-tab-additional">
                        <transaction-history #history ></transaction-history>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<p-dialog header="Add / Update Order"
          [appendTo]="'body'"
          [(visible)]="displayNewOrderDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '1000px', height: '765px'}"
          [resizable]="false" [closable]="false">
   <app-addorder #addOrder
           [selectedOrder]="selectedRecord"
           [selectedOrderItems]="orderItems"
        (submitClicked)="addUpdateOrderClicked($event)"
        (cancelClicked)="cancelAddUpdateOrderClicked()">
   </app-addorder>
</p-dialog>

<p-dialog header="Accept Items for processing"
          [appendTo]="'body'"
          [(visible)]="displayAcceptDialog"
          [modal]="true" [responsive]="true"
          [style]="{position: 'absolute', width: '900px', height: '465px'}"
          [resizable]="false" [closable]="true">
    <app-acceptorderitems #acceptOrderItems
        [selectedOrderItems]="orderItems"
        [selectedOrder]="selectedRecord"
        (submitClicked)="doAcceptOrderItemsClicked()"
        (cancelClicked)="displayAcceptDialog=false">
    </app-acceptorderitems>
</p-dialog>
