/**
 *  Filename ....: finishedforms.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/10/23
 **/
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Forming} from '../models/forming';
import {FormingService} from '../services/forming.service';
import {Settings} from '../services/settings';
import {MatTableDataSource} from '@angular/material/table';
import { LabelGenComponent } from '../components/label-gen/label-gen';
import {DwUtils} from '../components/utils/dw-utils';

@Component({
    selector: 'app-finishedforms',
    templateUrl: './finishedforms.component.html',
    styleUrls: ['./finishedforms.component.css']
})

export class FinishedFormsComponent implements OnInit {

    private _selectedForm: Forming;
    public selectedRecord: Forming;
    public records: any;
    public dataSource: MatTableDataSource<Forming>;
    public Idx = -1;
    public formCount = 0;
    public showLabelsPrintDialog = false;
    public displayedColumns = ['itemNumber', 'quantity', 'lotNumber', 'expirationDate'];

    @ViewChild('labelGenComponent', { static: true} ) labelGenComponent: LabelGenComponent;
    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
        this.queryFormRecords();
    }
    get selectedForm(): Forming {
        return this._selectedForm;
    }

    constructor(public formingService: FormingService) {

    }

    ngOnInit() {}

    public queryFormRecords(): void {

        if (this.selectedForm.bprId === 0 ) {
            return;
        }

        this.formingService.getAllFinishedFormsByBprId(this.selectedForm.bprId).subscribe(
            (data) => {
                this.records = data;
                this.formCount = this.records.filter(x => x.itemNumber !== 'SAMPLE').length;
                this.dataSource = new MatTableDataSource(this.records);
            },
            (error) => {
                Settings.getInstance().handleError(error, 'Error: Getting finished Forms by BPR');
            });
    }
    public onRowClicked(row: any) {
        this.selectedRecord = row;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedRecord === undefined || this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = undefined;

    }
    public tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    public downloadCSVFile(): void {

        /*** start at record 1 (0 is a sample record) ***/
        if (this.records === undefined) {
            return;
        }
        if (this.records.length < 1) {
            return;
        }

        const impRec = this.returnImportableRecord(this.records[1]);
        const link = document.createElement('a');
        link.download = this.selectedForm.orderNumber + '-' + this.selectedForm.bprId + '.txt';
        const blob = new Blob([impRec], {type: 'text/plain'});
        link.href = URL.createObjectURL(blob);

        link.click();

        URL.revokeObjectURL(link.href);

    }

    public returnImportableRecord(rec: any): string {

        const DELIM = '~';
        let retVal = '';

        /*** 1. Customer Number ***/
        retVal += this.selectedForm.customerNumber + DELIM;
        /*** 2. Prescript Order Number ***/
        retVal += this.selectedForm.orderNumber + DELIM;
        /*** 3. Lot Prefix ***/
        retVal += rec.lotNumber + DELIM;
        /*** 4. Starting Sequence number ***/
        retVal += rec.itemNumber.split('-')[2] + DELIM;
        /*** 5. number of units ***/
        retVal += this.selectedForm.bpr.quantity + DELIM;
        /*** 6. item Prefix ***/
        retVal += this.selectedForm.itemPrefix + DELIM;
        /*** 7. NDC Number ***/
        retVal += rec.ndcNumber + DELIM;
        /*** 8. Expiration Date ***/
        retVal += DwUtils.formatDateFromString(rec.expirationDate) + DELIM;
        /*** 9. Package Size ***/
        retVal += 'PACKAGE SIZE' + DELIM;
        /*** 10. Description ***/
        retVal += this.selectedForm.description + DELIM;
        /*** 11. Recommended Directions 2 ***/
        retVal += rec.directionsAlt + DELIM;
        /*** 12. Recommended Directions ***/
        retVal += rec.directions + DELIM;
        /*** 13. Precautions 2 ***/
        retVal += rec.precautionsAlt + DELIM;
        /*** 14. Precautions  ***/
        retVal += rec.precautions + DELIM;
        /*** 15. Lot Number 1 ***/
        retVal += rec.lotNumber + DELIM;
        /*** 16. Lot Number 2 ***/
        retVal += DELIM;
        /*** 17. Lot Number 3 ***/
        retVal += DELIM;
        /*** 18. Lot Number 4 ***/
        retVal += DELIM;
        /*** 19. Lot Number 5 ***/
        retVal += DELIM;
        /*** 20. Lot Number 6 ***/
        retVal += DELIM;
        /*** 21. Lot Number 7 ***/
        retVal += DELIM;
        /*** 22. Lot Number 8 ***/
        retVal += DELIM;
        /*** 23. schedule ***/
        retVal += this.selectedForm.schedule + DELIM;
        /*** 24.  ***/
        retVal += rec.unitPrice + DELIM;
        /*** 25.  ***/
        retVal += rec.retailPrice + DELIM;

        return retVal;
    }
    public printLabels(): void {
        this.showLabelsPrintDialog = true;
        this.labelGenComponent.doInitialize(true);
    }
    public formatDateLabel(inpStr: string): string {
        return DwUtils.formatDateFromString(inpStr);
    }
    public returnPkgSize(itemNum: string): string {
        let val = DwUtils.returnPackageSizeCode(itemNum);
        if (val === 'SAMPLE') {
            val = '-';
        }
        return val;
    }

}


