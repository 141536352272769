/************
 * Model Class
 * File Name ...: orders.components.ts
 * Generated By : ianday on 6/14/23
 * Date ........: 6/14/23
 /************/
import {ValidationResult} from './validation-result';

export interface Orders {
    id: number | null;
    orderNumber: string | null;
    subCustomerNumber: string | null;
    subCustomerName: string | null;
    customerEmail: string | null;
    customerTelephone: string | null;
    orderByFullName: string | null;
    shipAttention: string | null;
    shippingCustomerTelephone: string | null;
    customerReference: string | null;
    orderStatus: string | null;
    shippingService: string | null;
    orderTotal: number | null;
    shippingTotal: number | null;
    salesTax: number | null;
    grandTotal: number | null;
    dateCreated: Date | null;
    dateSubmitted: Date | null;
    dateClosed: Date | null;
    orderedBy: string | null;
    orderComments: string | null;
    submitMethod: string | null;
    activeStatus: string | null;
    processedFlag: string | null;
    billingAddressId: number | null;
    shippingAddressId: number | null;
    customerId: number | null;
    orderId: number | null;
    defaultFillSiteId: number | null;
    deaForm222: string | null;
}

export class Orders {
    constructor() {
        this.id = 0;
        this.orderNumber = '';
        this.subCustomerNumber = '';
        this.subCustomerName = '';
        this.customerEmail = '';
        this.customerTelephone = '';
        this.orderByFullName = '';
        this.shipAttention = '';
        this.shippingCustomerTelephone = '';
        this.customerReference = '';
        this.orderStatus = '';
        this.shippingService = '';
        this.orderTotal = 0;
        this.shippingTotal = 0;
        this.salesTax = 0;
        this.grandTotal = 0;
        this.dateCreated = null;
        this.dateSubmitted = null;
        this.dateClosed = null;
        this.orderedBy = '';
        this.orderComments = '';
        this.submitMethod = '';
        this.activeStatus = '';
        this.processedFlag = '';
        this.billingAddressId = 0;
        this.shippingAddressId = 0;
        this.customerId = 0;
        this.orderId = 0;
        this.defaultFillSiteId = 0;
        this.deaForm222 = '';
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        return vr;
    }
}
