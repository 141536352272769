<div class="users-container">
<div class="query-strip-container mat-elevation-z2">
	<table height="100%" width="100%">
		<TR>
			<TD class="table-title-cell">
				<i class="fa fa-address-card fa-fw table-icon-header"
				style="width: 2em"></i>{{formTitle}}
			</TD>
			<TD align="center">
				<input text id="txtFilter" #txtFilter
					class="qs_filter-field" [disabled]="this.isEditing"
					(keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
				<button mat-button matSuffix mat-icon-button
					matTooltip="Clear the filter" aria-label="Clear"
					(click)="txtFilter.value=''; clearFilter();">
					<mat-icon>close</mat-icon>
				</button></TD>
			<TD class="qs-label-cell" style="width: 60px; text-align: center;">Status:</TD>
			<TD class="qs-title-cell" style="width: 150px">
				<mat-select
					disableOptionCentering
					[(value)]="selectedFilterStatus" #cbStatus floatLabel="never"
					class="dw-mat-select"
					style="width: 150px;"
					panelClass="local-drop-down-panel"
					(selectionChange)="onChangeStatusFilterValue()"> 
					<mat-option	value=2>All</mat-option>
					<mat-option value=1>Active</mat-option>
					<mat-option	value=0>Inactive</mat-option> 
				</mat-select>
			</TD>
			<TD class="table-pagination-cell" align="right">{{pagination}} Record(s) ...</TD>
		</TR>
	</table>
</div>

<div [ngClass]="contentClassName" class="mat-elevation-z2"
	(keydown)="tableKeyDown($event)" tabindex="-1">
	<table mat-table [dataSource]="dataSource"

		(matSortChange)="onSortChange($event)" matSort>

		<!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on
          the row definition" -->

		<!-- Position Column -->
		<ng-container matColumnDef="id">
		<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">Id</th>
		<td mat-cell style="width:5% !important;"
			*matCellDef="let element;let tableRowIdx = index;">
			{{element.id}}</td>
		</ng-container>

		<!--element_name Column -->
		<ng-container matColumnDef="username" >
		<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">User Name</th>
		<td mat-cell style="width:15% !important;"
			*matCellDef="let element">{{element.username}}</td>
		</ng-container>

		<!-- Full Name Column -->
		<ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">Full Name</th>
		<td mat-cell style="width: 20%;"
			*matCellDef="let element">{{element.name}}</td>
		</ng-container>

		<!-- Email Column -->

		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">Email Address</th>
			<td mat-cell style="width: 20%;"
				*matCellDef="let element">{{element.email}}</td>
		</ng-container>

		<!-- Active state Column -->
		<ng-container matColumnDef="status" style="width: 10% !important;">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">Status</th>
			<!-- <td mat-cell *matCellDef="let element">{{element.displayStatus}}</td> -->
			<td mat-cell *matCellDef="let element" (focus)="statusCellReceivedFocus(element.id)">
				<mat-select *ngIf="selectedRecord?.id == element.id"
							disableOptionCentering
							[(value)]="element.displayStatus" #cbStatus floatLabel="never"
							class="dw-mat-select"
							panelClass="local-drop-down-panel"
							style="width:95px!important;"
							(selectionChange)="onStatusChange()" [disabled]="selectedRecord?.id !== element.id" >
					<mat-option	value="Active">Active</mat-option>
					<mat-option	value="InActive">InActive</mat-option>
				</mat-select>
				<span *ngIf="selectedRecord?.id !== element.id"
					  style="padding-left: 3px;">{{element.displayStatus}}</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="invitation_status" >
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="table-row-header-style">Invitation Status</th>
			<td mat-cell style="width: 15%;"
				*matCellDef="let element">{{invitationStatusLabeler(element)}}</td>
		</ng-container>
		<ng-container matColumnDef="last_login">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
			<td mat-cell style="width: 15%;"
				*matCellDef="let element">{{lastLoginLabeler(element)}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row
			*matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
			[ngClass]="{'row-selected': tableRowIdx == Idx}"
			(click)="onRowClicked(row)">

		</tr>
	</table>
</div>

<!-- Control strip -->
<div class="mat-elevation-z2 control-strip">
	<button *ngIf="visibleAdd" mat-button [disabled]="this.isEditing"
		class="command-button" matTooltip="Add a new record"
		(click)="addModifyRecord(true)" mat-button>
		<mat-icon>add_circle_outline</mat-icon>
		Add New
	</button>
	<button *ngIf="visibleModify" mat-button class="command-button"
		style="margin-top: 5px;" [disabled]="(Idx < 0|| this.isEditing)"
		matTooltip="Update a selected record" (click)="addModifyRecord(false)"
		mat-button>
		<mat-icon>edit</mat-icon>
		Update
	</button>
	<button *ngIf="visibleDelete" mat-button class="command-button"
		style="margin-top: 5px;" [disabled]="(Idx < 0 || this.isEditing)"
		matTooltip="Remove a selected record" (click)="removeRecord()"
		mat-button>
		<mat-icon>remove_circle_outline</mat-icon>
		Remove
	</button>
	<button mat-button class="command-button"
		style="margin-top: 5px; float: right; margin-right: 10px;" [disabled]="(this.isEditing)"
		matTooltip="Send invitation to users" (click)="openInvitationDialog()"
		mat-button>
		<mat-icon>outgoing_mail</mat-icon>
		Invite
	</button>
</div>

<!-- Detail container -->
<div class="usermgmt-container">

	<div class="dwform" style="padding-right: 15px;"
		[formGroup]="formContent">

		<div class="dwform-cell" style="min-width: 480px;">

			<div class="dwform-row-double-gap"></div>

			<div class="dwform-row">
				<label class="label-field">Record ID:</label> <span
					class="form-required"> </span>
					<input class="input-field-short" placeholder="<id>"
					formControlName="id" type="text">
			</div>

			<div class="dwform-row-gap"></div>

			<div class="dwform-row">
				<label class="label-field">User Name:</label> <span
					class="form-required">*</span>
					<input class="input-field" #username
						   placeholder="<User Name>"
					formControlName="username" type="text">
			</div>

			<div class="dwform-row-gap"></div>

			<div class="dwform-row">
				<label class="label-field">Full Name:</label> <span
					class="form-required">*</span>
				<input class="input-field" #fullname
					style="text-transform: capitalize"
					formControlName="name"
					placeholder="<Full Name>"
					type="text"	>
			</div>

			<div class="dwform-row-gap"></div>

			<div class="dwform-row">
				<label class="label-field">Active Status:</label> <span
					class="form-required">*</span>

				<mat-select
						#cbDetailStatus
						placeholder="- Please Select -"
						 disableOptionCentering
		                floatLabel="never"
						class="dw-mat-select"
						panelClass="local-drop-down-panel"
		                formControlName="status"
						style="width: 150px;font-size: 10pt;"
						[style.backgroundColor]="disabledFlag?'whitesmoke':'white'">
					<mat-option value="Active">Active</mat-option>
					<mat-option value="InActive">InActive</mat-option>
				</mat-select>
			</div>
			<div class="dwform-row-gap"></div>

			<div class="dwform-row">
				<label class="label-field">Email:</label> <span
					class="form-required">*</span>
				<input class="input-field" #email
				placeholder="<Email Address>"
					formControlName="email" type="text">
			</div>
			<div class="dwform-row-gap"></div>
			
			</div>

		<!-- this is first select option list -->
		<div class="dwform-cell " style="min-width: 520px;">
			<label class="label-field-free">App Function Group List:</label>

			<mat-selection-list
					[(ngModel)]="assignedAppFunctionGroupsList"
					(selectionChange)="groupSelectionChanged()"
					[ngModelOptions]="{standalone: true}"
					style="width:500px;"
					[disabled]="disabledFlag">
				<mat-list-option
					*ngFor="let appFunctionGroup of availableAppFunctionGroupsList"
					[value]="appFunctionGroup.name">
				{{appFunctionGroup.name}} </mat-list-option> 
			</mat-selection-list>

		</div>

		<!-- this is second select option list -->
		<!-- 
		<div class="dwform-cell" style="min-width: 320px;">
			<label class="label-field-free">App Functions List:</label>
			<label class="label-field-free" style="float: right;margin-right: 2rem;">Read Only</label>
		      <mat-selection-list id="userAppFunctionsList" [disabled]="disabledFlag">
		        <mat-list-item *ngFor="let appFunction of appFunctionsList" [value]="appFunction.id">
		          <div style="width: 100%">          	
		          	<mat-checkbox [(ngModel)]="appFunction.selected" [ngModelOptions]="{standalone: true}" [style]="{'color': 'darkgray'}" [disabled]="disabledFlag">{{appFunction.label}}</mat-checkbox>
		          	<mat-checkbox [(ngModel)]="appFunction.readOnly" [ngModelOptions]="{standalone: true}" [style]="{'float': 'right', 'color': 'darkgray'}" [disabled]="disabledFlag || !appFunction?.selected"></mat-checkbox>
		          </div>
		        </mat-list-item>
		      </mat-selection-list>
		</div>
		 -->

		<div class="dwform-cell" style="width: 100%;"></div>
		<!-- Error line -->
	</div>
	<!-- ends the form -->

	<div [hidden]='!strErr.length' style="left:10px;" class="error-line">{{strErr}}</div>

	<!-- save strip -->
	<div class="save-close-strip" style="bottom: 5px;">

		<button mat-button class="command-button" *ngIf="this.isEditing"
			(click)="submitRecord()" matTooltip="Submit the selected record"
			mat-button>
			<mat-icon>check_circle_outline</mat-icon>
			Submit
		</button>
		<button mat-button class="command-button" *ngIf="this.isEditing"
			(click)="cancelSubmitRecord()" matTooltip="Cancel edit" mat-button>
			<mat-icon>highlight_off</mat-icon>
			Cancel
		</button>
	</div>
</div>
</div>

<p-dialog header="Invite Users" [focusOnShow]="false"
		  [(visible)]="displayInvitationDlg" [modal]="false" [responsive]="true"
		  [style]="{width: '850px', height: '500px'}"
		  appendTo="body"
		  [minY]="35" [resizable]="false" [closable]="true" (onShow)="send_invite.setFocusOnSearch()">
	<app-send-invite #send_invite (cancelClicked)="cancelClickedFromSendInvite()"
					 (intitationsSent)="invitationSentHandler()"></app-send-invite>
</p-dialog>
