/************
 * Model Class
 * File Name ...: orderitems.components.ts
 * Generated By : ianday on 6/14/23
 * Date ........: 6/14/23
 /************/
import {ValidationResult} from './validation-result';

export interface OrderItems {
    id: number | null;
    orderId: number | null;
    itemPrefix: string | null;
    quantity: number | null;
    filled: number | null;
    unitPrice: number | null;
    precautions: string | null;
    directions: string | null;
    precautions2: string | null;
    directions2: string | null;
    description: string | null;
    startingSequenceNumber: number | null;
    extendedPrice: number | null;
    itemStatus: string | null;
    orderItemComments: string | null;
    lowIndicator: string | null;
    schedule: number | null;
    activeStatus: string | null;
    trackingNumber: string | null;
    shippingDueDate: string | null;
}

export class OrderItems {
    constructor() {
        this.id = 0;
        this.orderId = 0;
        this.itemPrefix = '';
        this.quantity = 0;
        this.filled = 0;
        this.unitPrice = 0;
        this.precautions = '';
        this.directions = '';
        this.precautions2 = '';
        this.directions2 = '';
        this.description = '';
        this.startingSequenceNumber = 0;
        this.extendedPrice = 0;
        this.itemStatus = 'Draft';
        this.orderItemComments = '';
        this.lowIndicator = '';
        this.schedule = 0;
        this.activeStatus = 'Active';
        this.trackingNumber = '';
        this.shippingDueDate = null;
    }

    validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        return vr;
    }
}
