import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Settings } from '../../services/settings';
import { AuthService } from '../../services/authservice/auth.service';
import { UserService } from '../../services/user.service';
import { GlobalService } from '../../services/global-service';


@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	public placeHolderURL: string = '/assets/profile-img-generic.png';
	fullName;
	userName;
	userRecord: any;
	lastLogin = '';
	selectedFile: any;
	valFilePath: any;
	base64textStringAvatar: string;
	loading = false;
	imageAvatarFlag = false;
	avatarURL = '';
	@Output() cancelClicked = new EventEmitter();

	constructor(private authService: AuthService, private userService: UserService, private globalService: GlobalService) { }

	ngOnInit() {

	}
	doCancel() {
		this.cancelClicked.emit();
	}
	doLoadData() {
		this.userRecord = Settings.getInstance().loggedInUser;
		this.fullName = Settings.getInstance().loggedInUser.name;
		this.userName = Settings.getInstance().loggedInUser.username;
		this.lastLogin = Settings.getInstance().loggedInUser.lastLogin;
		this.doLoadAvatarImage();
	}
	clearAvatar(evt: Event): void {

		// if this is just the placeholder URL then
		// just return ...
		if (this.avatarURL.includes(this.placeHolderURL) || this.avatarURL === undefined) {
			return;
		}

		this.loading = true;
		this.avatarURL = '';
		this.userService.removeProfileImage(this.userRecord.id).subscribe(
			(data) => {
				this.loading = false;
				this.globalService.globalEvent.next('PROFILE_REFRESH');
				this.doLoadAvatarImage();
			},
			(error) => {
				Settings.getInstance().handleError(error);
				this.loading = false;
			}
		);
	}
	doLoadAvatarImage() {
		this.imageAvatarFlag = false;
		this.userService.getUserProfileImage(this.authService.loggedInUser.id).subscribe(
			(data1) => {
				const tempImagePath: string = URL.createObjectURL(data1);
				this.avatarURL = 'url(' + tempImagePath + ')';
				document.getElementById('avatar').style.backgroundImage = this.avatarURL;
				this.imageAvatarFlag = true;
			},
			(error1) => {
				this.imageAvatarFlag = false;
				this.avatarURL = 'url(' + this.placeHolderURL + ')';
				document.getElementById('avatar').style.backgroundImage = this.avatarURL;
			}
		);
	}
	getUrl() {
		return this.avatarURL;
	}
	fileInputChange(fileInputEvent: any) {
		if (fileInputEvent.target.files[0] === undefined || fileInputEvent.target.files[0] === null) {
			return;
		}
		if (fileInputEvent.target.files[0].size > 409600) {
			Settings.getInstance().handleError('The image file size should not exceed 400KB');
			return;
		}
		this.selectedFile = fileInputEvent.target.files[0];
		this.valFilePath = this.selectedFile.name;
		this.loading = true;
		this.userService.updateUserProfileImage(this.userRecord.id, this.selectedFile).subscribe(
			(data) => {
				this.loading = false;
				this.globalService.globalEvent.next('PROFILE_REFRESH');
				//document.getElementById('avatar').style.backgroundImage = 'url(' + Settings.getInstance().BASE_URL + 'users/profile/image/' + this.userRecord.id;
				this.doLoadAvatarImage();
			},
			(error) => {
				Settings.getInstance().handleError(error);
				this.loading = false;
			}
		);
	}
}
