<!--
    file type .....: HTML file: shipbatch.component.html
    Created By ....: ianday
    Date ..........: 8/4/23 
-->
<div class="dialog-container">
    <div class="dwform">

        <!-- Station Cleaning Completed -->
        <span><b>Note: By clicking 'Submit' you will be completing the item on this order and electronically
            notifying the customer that their item / order has shipped ...</b></span>
        <div class="dwform-row-gap" > </div>
        <div class="dwform-row-gap" > </div>

        <div class="dwform-row" >
            <label class="label-field">Service:</label>
            <span class="form-required"> </span>

            <label class="label-field-data" style="width: 250px;font-weight: bold">
               {{this.shippingService}}
            </label>
        </div>

        <div class="dwform-row-gap" > </div>

        <div class="dwform-row" >
            <label class="label-field">Tracking #:</label>
            <span class="form-required"> </span>

            <input mat-input [disabled]="true" id="trackingNumberId"
                   class="input-field-long" style="width: 250px;"
                   [formControl]="trackingNumberControl" >
        </div>
        <div class="dwform-row-gap" > </div>
        <div class="dwform-row" >
            <label class="label-field">Arrival Due:</label>
            <span class="form-required"> </span>

            <dw-calendar  #TDt [inputStyle]="{'width': '90px'}"
                          hideOnDateTimeSelect="true" [showOnFocus]="false"
                          [showIcon]="true" appendTo="body"
                          [(ngModel)]="shippingDueDate"
                          [placeholder]="'MM/DD/YYYY'">
            </dw-calendar>
        </div>

        <div class="dwform-row-gap" > </div>
        <mat-checkbox id="importFileId"
                      name="visualLabelMatch" binary="true"
                      [(ngModel)]="sendImportFile"
                      [ngModelOptions]="{standalone: true}">
            Send an import file?
        </mat-checkbox>

        <div class="dwform-row-gap" > </div>
        <mat-checkbox id="visualLabelMatchId"
                      name="visualLabelMatch" binary="true"
                      [(ngModel)]="shippingCompleted"
                      [ngModelOptions]="{standalone: true}">
            Release To Ship?
        </mat-checkbox>

    </div>
</div>
<!----------------------->
<!-- ERROR LINE --------->
<!----------------------->
<div class="error-line" [hidden]='!strErr.length'
     style="text-align:left; bottom: 55px; left: 15px;">{{strErr}}</div>
<!----------------------->
<!-- SAVE STRIP        -->
<!----------------------->
<div style="text-align:center; margin-top: 55px;">
    <button mat-button class="command-button"
            (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
            mat-button>
        <mat-icon>check_circle_outline</mat-icon>
        Submit
    </button>
    <button mat-button class="command-button"
            (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
        <mat-icon>highlight_off</mat-icon>
        Cancel
    </button>
</div>
