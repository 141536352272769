/************
 * Model Class
 * File Name ...: forming.components.ts
 * Generated By : ianday on 7/13/23
 * Date ........: 7/13/23
 /************/
import {ValidationResult} from './validation-result';
import {Bpr} from './bpr';
import {DwUtils} from '../components/utils/dw-utils';

export interface Forming {
    id: number;
    customerNumber: number;
    customerName: string;
    orderedBy: string;
    customerEmail: string;
    customerTelephone: string;
    orderId: number;
    orderNumber: string;
    dateSubmitted: string;
    itemId: number;
    bprId: number;
    siteId: number;
    stationId: number;
    formularyMedicationId: number;
    itemPrefix: string;
    description: string;
    quantity: number;
    schedule: number;
    extendedPrice: number;
    daysInStatus: number;
    itemStatus: string;
    currentStep: number;
    bprStatus: string;

    /** other elements **/
    station: string;
    bpr: Bpr;
}

export class Forming {
    constructor() {
        this.id = 0;
        this.customerNumber = 0;
        this.customerName = '';
        this.orderedBy = '';
        this.customerEmail = '';
        this.customerTelephone = '';
        this.orderId = 0;
        this.orderNumber = '';
        this.dateSubmitted = '';
        this.itemId = 0;
        this.bprId = 0;
        this.siteId = 0;
        this.stationId = 0;
        this.formularyMedicationId = 0;
        this.itemPrefix = '';
        this.description = '';
        this.quantity = 0;
        this.schedule = 0;
        this.extendedPrice = 0;
        this.daysInStatus = 0;
        this.itemStatus = '';
        this.currentStep = 0;
        this.bprStatus = '';

        /** other elements **/
        this.station = '';
        this.bpr = new Bpr();
    }

    public validateRecord(): ValidationResult {
        const vr: ValidationResult = new ValidationResult();

        if (this.description.length === 0) {
            vr.focus_field = 'description';                              /* this should be the id value of the field */
            vr.error = 'Error: a description / value is required';
            return vr;
        }

        return vr;
    }

}
