/**
 *  Filename ....: finishbatch.component.ts
 *  Created by ..: ianday
 *  on Date .....: 8/4/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Forming} from '../../../../models/forming';
import {Bpr} from '../../../../models/bpr';
import {Settings} from '../../../../services/settings';
import {DwUtils} from '../../../../components/utils/dw-utils';
import {FormingService} from '../../../../services/forming.service';
import {OrdersService} from '../../../../services/orders.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-shipbatch',
    templateUrl: './shipbatch.component.html',
    styleUrls: ['./shipbatch.component.css']
})

export class ShipBatchComponent implements OnInit {

    public _stationsList: any;
    public _selectedForm: Forming;
    public strErr = '';
    public ordNumber: string;
    public orderClosed: boolean;
    public shippingCompleted: number | null;
    public shippingDueDate: Date;
    public trackingNumberControl: FormControl;
    public shippingService: string;
    public sendImportFile: number | null;

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @HostListener ('keydown', ['$event.target'])
    onKeyDownGlobal($event) {
        this.strErr = '';
    }
    @Input() set selectedForm(inp: any) {
        this._selectedForm = inp;
    }
    get selectedForm(): Forming {
        return this._selectedForm;
    }
    @Input() set stationsList(inp: any) {
        this._stationsList = inp;
    }
    get stationsList(): any {
        return this._stationsList;
    }
    @Input() set orderNumber(inp: string) {
        this.ordNumber = inp;
    }

    constructor(public formingService: FormingService, public orderService: OrdersService) {
        this.orderClosed = false;
        this.trackingNumberControl = new FormControl();
    }

    ngOnInit() {

    }
    public doInitialize(shippingService: string): void {
        this.shippingService = shippingService;
        this.shippingCompleted = this.selectedForm.bpr.shippingCompleted;
        this.shippingDueDate = new Date();
        this.shippingDueDate.setDate(this.shippingDueDate.getDate() + DwUtils.returnMaxShippingDays(this.shippingService));
        this.trackingNumberControl.setValue('');
        this.sendImportFile = 1;
    }
    public setBPRItems(): void {

        this.selectedForm.bpr.trackingNumber = this.trackingNumberControl.value;
        if ((this.shippingDueDate === null) || (this.shippingDueDate === undefined)) {
            this.selectedForm.bpr.shippingDueDate = '';
        }
        else {
            this.selectedForm.bpr.shippingDueDate = this.shippingDueDate.toISOString();

        }
        this.selectedForm.bpr.shippingCompleted = DwUtils.boolToInt(this.shippingCompleted);
    }

    public onSubmitClicked(): void {
        this.setBPRItems();

        const bpr: Bpr | null = new Bpr();
        bpr.setBpr(this.selectedForm.bpr);
        const vr = bpr.validateShippingRecord();

        if (vr.error.length > 0) {
            this.strErr = vr.error;
            return;
        }

        this.selectedForm.bpr.currentStep = 6;
        this.selectedForm.bpr.activeStatus = 'Completed';
        this.formingService.updateBpr(this.selectedForm.bpr).subscribe(
            (result) => {

                this.orderService.getOrderItemsById(this.selectedForm.bpr.itemId).subscribe(

                    (oiResult) => {
                        const oi = oiResult;
                        oi.trackingNumber = this.selectedForm.bpr.trackingNumber;
                        oi.shippingDueDate = this.selectedForm.bpr.shippingDueDate;
                        oi.itemStatus = 'Shipped';
                        oi.activeStatus = 'Completed';

                        this.orderService.updateOrderItem(oi).subscribe (
                            (result1) => {
                                /*** update the order if all items are marked as shipped. ***/

                                this.orderService.attemptCloseOrder(this.selectedForm.bpr.orderId).subscribe (
                                    (result2) => {
                                        if (result2 > 0) {
                                            this.orderClosed = true;

                                            if (this.sendImportFile === 1) {
                                                this.formingService.sendImportFileByEmail(this.selectedForm.bprId).subscribe (
                                                    (result3) => {
                                                        this.submitClicked.emit();
                                                        return;
                                                    },
                                                    (error) => {
                                                        Settings.getInstance().handleError(error, 'attempting to email the import file');
                                                    }
                                                );
                                            }
                                        }
                                        this.submitClicked.emit();
                                    },
                                    (error) => {
                                        Settings.getInstance().handleError(error, 'attempting to close the order');
                                    });
                            },
                            (error1) => {
                                Settings.getInstance().handleError(error1, 'attempting to close the order item');
                            });
                    },
                    (error) => {
                        Settings.getInstance().handleError(error, 'Fetching Order Item for update');
                    });
            },
            (error2) => {
                Settings.getInstance().handleError(error2, 'Error updating BPR from approve batch');
            });
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


