import {EventEmitter, Output} from '@angular/core';
import { throwError } from 'rxjs';
import { User } from '../models/user';
import { AppFunctionGroup } from '../models/app-function-group';
import { GeneralSetting } from '../models/generalsetting';

export class Settings {

	private static instance: Settings;
	private static monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];
	public packageSizes = [5,10,15,20,25,30,40,50,60,70,80,90,100,200,300,400,500];

	public BASE_URL = '/api/';
	public platformId = '0247';
	user: User;
	loggedInUser: User;
	public usersList: User[];
	public completeAppFunctionGroupsList: AppFunctionGroup[];
	public version = 'x.x.x';
	public lastUpdated = 'yyyy/mm/dd';
	public release = 'staging';
	public EnableInvestments = true;
	public generalSettingsList: GeneralSetting[];
	public formingDaysAllowed = 7;

	public timeLineSelectionList: any[];
	public enableMessageBanner = false;
	public strBannerText = '';
	public userList: Array<User>;

	public strErr = '';
	public CURRENCY_SYMBOL = '$';

	public onToggleFullScreen: EventEmitter<boolean>;
	public showMessageEvent: EventEmitter<any>;
	public loginRequestEvent: EventEmitter<any>;
	public _jwtToken: any;
	public location: Location;
	public isEditing = false;
	

	constructor() {

	}
	get jwtToken():any {
		if(this._jwtToken == null || this._jwtToken === undefined || this._jwtToken.length === 0) {
			this._jwtToken = localStorage.getItem('jwt_token');
		}
		return this._jwtToken;
	}
	set jwtToken(value:any) {
		this._jwtToken = value;
		localStorage.setItem('jwt_token', value);
	}
	public static convertJsonDate(jsonDate): Date {
		if (jsonDate === null || jsonDate === '') {
			return null;
		}
		return new Date(jsonDate);
	}
	public static getInstance(): Settings {
		if (Settings.instance == null) {
			Settings.instance = new Settings();
			Settings.instance.timeLineSelectionList = new Array();
			Settings.instance.onToggleFullScreen = new EventEmitter<boolean>();
			Settings.instance.showMessageEvent = new EventEmitter<any>();
			Settings.instance.loginRequestEvent = new EventEmitter<any>();
		}
		return Settings.instance;
	}

	public static dateLabelFunction(rowData: any, column: any): string {
		if (rowData[column.field] === null || rowData[column.field] === undefined) {
			return;
		}
		const retVal = rowData[column.field].substr(0, 10);
		return retVal;
	}
	public static timeLabelFunction(rowData: any, column: any): string {
		if (rowData[column.field] === null || rowData[column.field] === undefined) {
			return;
		}
		const retVal = rowData[column.field].substr(11, 8);
		return retVal;
	}
	public static getMonthCount(startDate: Date, endDate: Date): number {
		if (startDate == null || endDate == null) {
			return 0;
		}
		return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;
	}
	public static returnDateFromString(inpString: string): Date {
		if (inpString == null || inpString.length === 0) {
			return null;
		}
		const dateParts = inpString.split('/');
		const month: number = parseInt(dateParts[0], 10);
		const date: number = parseInt(dateParts[1], 10);
		const year: number = parseInt(dateParts[2], 10);
		return new Date(year, month - 1, date);
	}

	public static filterResourceList(sourceList: any, str: string): any {
		const revisedDataList: Array<any> = [];
		if (str !== undefined && str != null) {
			str = str.toLowerCase();
		}
		for (let i = 0; i < sourceList.length; i++) {
			const obj = sourceList[i];
			if (str === '' || str === undefined || str === null) {
				revisedDataList.push(obj);
			} else {
				if (obj.name.toLowerCase().indexOf(str) >= 0 || obj.username.toLowerCase().indexOf(str) >= 0 || (obj.Reference != null && obj.Reference.toLowerCase().indexOf(str) >= 0)) {
					revisedDataList.push(obj);
				}
			}
		}
		return revisedDataList;
	}

	public static invitationStatusLabeler(row): string {
		if(row['invitationStatus'] == null || row['invitationStatus'] == undefined) {
			return 'Not Invited';
		} else if(row['invitationStatus'] == -1) {
			return '';
		} else if(row['invitationStatus'] == 0 && row['invitationCount'] > 0 && row['lastInvitationDate'] !==undefined) {
			const invitationDate: Date = Settings.returnDateFromString(row['lastInvitationDate']);
			if((new Date()).getTime() - invitationDate.getTime() > (72 * 60 * 60 * 1000)) {
				return 'Invitation Exipred';
			} else {
				return 'Invitation Sent';
			}
		} else if(row['invitationStatus'] == 1 && row['invitationCount'] > 0) {
			return 'Invitation Accepted';
		} else {
			return 'Not Invited';
		}
	}
	public static invitationStatusLabeler1(row): string {
		if(row['invitationStatus'] == null || row['invitationStatus'] === undefined) {
			return 'Not Invited';
		} else if(row['invitationStatus'] === -1) {
			return '';
		} else if(row['invitationStatus'] === 0 && row['invitationCount'] > 0) {
			
			return 'Invitation Sent';
			
		} else if(row['invitationStatus'] === 1 && row['invitationCount'] > 0) {
			return 'Invitation Accepted';
		} else {
			return 'Not Invited';
		}
	}
	public static validateEmail(email: string): boolean {
		const re = /\S+@\S+\.\S+/;
  		return re.test(email);
	}

	public handleError(error, calledFrom= '') {
		let titleStr = 'Error';
		let errorMessage = '';

		// no need to show a dialog box with OK
		if ( String(error).toUpperCase() === 'OK') {
			return;
		}
		/**** If it is a forbidden then just return to the login page ***/
		if(error.status === 403) {
			this.loginRequestEvent.emit();
			return;
		}

		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else if (error === 'Unknown Error') {
			errorMessage = 'Error: unable to communicate with server';

		}
		if(typeof error === 'string' || error instanceof String) {
			errorMessage = error as string;
		}
		else {
			// Get server-side error
			errorMessage = '<div style="fontSize: 13pt; lineHeight: 4px;">';
			errorMessage +=  'Error Code ....: <b>' + error.status + ' - ' + error.statusText + '</b><br />';
			errorMessage += 'Message .......: <b>' + error.message + '</b><br />';
			errorMessage += 'Asserting ......: <b>' + error.url + '</b>';
			if (calledFrom.length > 0) {
				errorMessage += '<br/>Attempt ........: <b>' + calledFrom + '</b>';
			}
			errorMessage += '</div>';
		}

		if(errorMessage.toString().includes('Success')) {
			titleStr = 'Result';
		}

		this.showMessageEvent.emit({title: titleStr, message: errorMessage, showClose: true});
		return throwError(errorMessage);

	}
	hasPrivilege(param: string, readOnly = true): boolean {
		if (this.loggedInUser === undefined || this.loggedInUser.app_functions === undefined) {
			return false;
		}
		for (const rec of this.loggedInUser.app_functions) {
			if (rec === param || rec.role === param) {
				if (readOnly || rec.readOnly === false) {
					return true;
				}
			}
		}

		return false;
	}

	getGeneralSettingByKey(paramKey: string): GeneralSetting {
		if(this.generalSettingsList == null || this.generalSettingsList == undefined) {
			return null;
		}
		try {
			for (const rec of this.generalSettingsList) {
				if (rec.key === paramKey) {
					return rec;
				}
			}
			return null;
		} catch (err) {
			return null;
		}
	}

	getResourceFullName(userid): string {
		if (this.usersList === undefined || this.usersList.length === 0) {
			return '';
		}
		for (const rec of this.usersList) {
			if (rec.username === userid) {
				return rec.name;
			}
		}
		return userid;
	}
}
