import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';
import { Table } from 'primeng/table';

@Directive({
  selector: '[appTurboTableKeys]'
})
export class TurboTableKeysDirective implements OnInit {

    constructor(private table: Table, private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.tabIndex = 1;
    }

    @HostListener('keydown.ArrowUp', ['$event']) ArrowUp($event: KeyboardEvent) {
        this.table.selection = new Array(this.navigateItem(-1));
        this.table.selectionChange.emit(this.table.selection);
        this.table.onRowSelect.emit({originalEvent: $event, data: Array.isArray(this.table.selection)?this.table.selection[0]:this.table.selection, type: 'row'});
        event.preventDefault();
        this.doScroll();
    }

    @HostListener('keydown.ArrowDown', ['$event']) ArrowDown($event: KeyboardEvent) {
        this.table.selection = new Array(this.navigateItem(1));
        this.table.selectionChange.emit(this.table.selection);
        this.table.onRowSelect.emit({originalEvent: $event, data: Array.isArray(this.table.selection)?this.table.selection[0]:this.table.selection, type: 'row'});
        event.preventDefault();
        this.doScroll();
    }

    navigateItem(num) {
        if (!this.table.selection) {
          return;
        }
        //const i = this.table.value.map(function(e) { return e.WorkUnitId; }).indexOf(this.table.selection.WorkUnitId);
        const i = this.table.selection ? this.table.value.findIndex(item => item.id == (Array.isArray(this.table.selection)?this.table.selection[0].id:this.table.selection.id)) : 0;
        const len = this.table.value.length;
        if (num > 0) {
            return this.table.value[(i + num) % len];
        }
        return this.table.value[(i + len + num) % len];
    }

    private doScroll() {
        const index = this.table.selection ? this.table.value.findIndex(item => item.id == (Array.isArray(this.table.selection)?this.table.selection[0].id:this.table.selection.id)) : 0;
        let scrollBodyEl = this.table.el.nativeElement.getElementsByClassName('ui-table-scrollable-body')[0];
        let tbodyEl = scrollBodyEl.getElementsByClassName('ui-table-tbody')[0];
        if (!tbodyEl.children.length) {
          return;
        }
        let rowEl = tbodyEl.children[index];
        if (rowEl.offsetTop < scrollBodyEl.scrollTop) {
          scrollBodyEl.scrollTop = rowEl.offsetTop;
        } else if ((rowEl.offsetTop + rowEl.offsetHeight) > (scrollBodyEl.scrollTop + scrollBodyEl.offsetHeight - 17)) {
          scrollBodyEl.scrollTop += rowEl.offsetTop + rowEl.offsetHeight - scrollBodyEl.scrollTop - scrollBodyEl.offsetHeight + 17;
        }
    }
}
