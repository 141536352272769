
/**
 *  Filename ....: bulkunitsdetail.component.ts
 *  Created by ..: ianday
 *  on Date .....: 4/12/23
 **/
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DwUtils} from '../../../../../../components/utils/dw-utils';
import {BulkUnits} from '../../../../../../models/bulkunits';
import {TransactionHistoryComponent} from '../../../../../../components/transaction-history/transaction-history';
import {TransactionHistoryService} from '../../../../../../services/transactionhistory.service';
import {BulkUnitGraphicsComponent} from './bulkunitgraphics/bulkunitgraphics.component';

@Component({
    selector: 'app-bulkunitsdetail',
    templateUrl: './bulkunitsdetail.component.html',
    styleUrls: ['./bulkunitsdetail.component.css']
})

export class BulkUnitsDetailComponent implements OnInit {

    private _selectedRecord: any;
    private _itemHandle: string;
    private _itemPrefix: string;
    public dosesPieces: string;
    public id;

    labelFmtDate(inpStr): string {
        if (inpStr === null)
            return '';

        return inpStr.split('T')[0];
    }
    @Input() set selectedRecord(value: BulkUnits) {
        this._selectedRecord = value;
        this.graphics.selectedRecord = value;
    }
    get selectedRecord(): BulkUnits {
        return this._selectedRecord;
    }
    @Input() set itemHandle(value: string) {
        this._itemHandle = value;
    }
    get itemHandle(): string {
        return this._itemHandle;
    }
    @Input() set itemPrefix(value: string) {
        this._itemPrefix = value;
    }
    get itemPrefix(): string {
        return this._itemPrefix;
    }

    @Output() AttemptToFetchPrevious = new EventEmitter();
    @Output() AttemptToFetchNext = new EventEmitter();
    @Output() closeBulkItemDetail = new EventEmitter();
    @ViewChild('graphics', {static: true}) graphics: BulkUnitGraphicsComponent;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;

    constructor(private txService: TransactionHistoryService) {
        this.dosesPieces = 'Doses';

    }
    ngOnInit() {
        this.history.setActionDisplayColumns();
    }
    doSetAndShowHistory(dpVal: string, id: number): void {
        this.dosesPieces = dpVal;
        this.graphics.dosesPieces = this.dosesPieces;
        this.graphics.onShowHandler();
        this.history.onShowHandler('bulkunits', 'id', id.toString());
    }
    attemptFetchPreviousRecord(): void {
        this.AttemptToFetchPrevious.emit();
    }
    attemptFetchNextRecord():void {
        this.AttemptToFetchNext.emit();
    }
    onSelectedTabChange(event): void {

        if(event === null)
            return;

        switch (event) {
            case 0:         // main details area
                this.graphics.onShowHandler();
                break;
            case 1:         // transaction history table
                this.history.onShowHandler('bulkunits', 'id', this.selectedRecord.id.toString());
                break;
        }
    }
    doClose(): void {
        this.closeBulkItemDetail.emit();
    }
}


