<!--
    file type .....: HTML file: finishedforms.component.html
    Created By ....: ianday
    Date ..........: 8/10/23 
-->
<div class="finishedforms-container">

    <table style="width:100%;padding: 3px;">
        <TR>
        <TD style="width: 45%;font-size: 9pt;">
            Finished Forms: &nbsp;<b>{{this.formCount}}</b>
        </TD>
        <TD style="width: 20%; text-align: right;">

            <button mat-button style="float: right; width: 100px;scale: 85%"
                    [disabled]="this.selectedForm ===undefined || this.formCount < 1"
                    class="command-button" matTooltip="Download an importable csv file"
                    (click)="downloadCSVFile()" mat-button>
                <mat-icon>import_export</mat-icon>
                CSV File
            </button>
         </TD>
            <TD style="width: 20%;text-align: right;">
            <button mat-button style="float: right; width: 100px;scale: 85%"
                    [disabled]="this.selectedForm ===undefined || this.formCount < 1"
                    class="command-button" matTooltip="Print labels for these finished forms"
                    (click)="printLabels()" mat-button>
                <mat-icon>print</mat-icon>
                Labels
            </button>
        </TD>
        </TR>
    </table>

    <div class="finishedForms-table-container" (keydown)="tableKeyDown($event)"
         tabindex="-1" style="outline: none!important;">
        <table #finishedFormsTable id="finishedFormsTableId"
               mat-table [dataSource]="dataSource" style="width: 100%">

            <ng-container matColumnDef="itemNumber">`
                <th mat-header-cell *matHeaderCellDef class="mat-table-header-style">Item </th>
                <td mat-cell *matCellDef="let form" fxLayout="column wrap"
                    style=" font-size: 9pt; width: 8%; min-width:10px">
                    <span>{{form.itemNumber}}</span></td>
            </ng-container>
            <ng-container matColumnDef="quantity">`
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"> Quantity </th>
                <td mat-cell *matCellDef="let form" fxLayout="column wrap"
                    style="padding-left: 5px; font-size: 9pt; width: 8%; min-width:10px">
                    <span>{{returnPkgSize(form.itemNumber)}}</span></td>
            </ng-container>
            <ng-container matColumnDef="lotNumber">`
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"> Lot Number </th>
                <td mat-cell *matCellDef="let form" fxLayout="column wrap"
                    style=" font-size: 9pt; width: 10%; min-width:10px">
                    <span>{{form.lotNumber}}</span></td>
            </ng-container>
            <ng-container matColumnDef="expirationDate">`
                <th mat-header-cell *matHeaderCellDef
                    class="mat-table-header-style"> Expiration </th>
                <td mat-cell *matCellDef="let form" fxLayout="column wrap"
                    style=" font-size: 9pt; width: 10%; min-width:10px">
                    <span>{{formatDateLabel(form.expirationDate)}}</span></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                (click)="onRowClicked(row)">
            </tr>
        </table>
    </div>
</div>

<p-dialog [appendTo]="'body'"
[(visible)]="showLabelsPrintDialog">
    <app-labelgen #labelGenComponent
        [bprId]="this.selectedForm?.bprId"
        (finishedLabels)="showLabelsPrintDialog=false">
    </app-labelgen>
</p-dialog>
