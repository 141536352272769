<!--
    file type .....: HTML file: lookupitem.component.html
    Created By ....: ianday
    Date ..........: 6/23/23 
-->
<div class="lookupitem-container">

    <!-- HEADER ITEM -->
    <div style="display:inline-block; width: 30%">
        <mat-checkbox id="remember-me" name="rememberUser" binary="true"
                  [(ngModel)]="bUseCustomerFormulary"
                  (change)="changeQuerySource($event)"
                  [ngModelOptions]="{standalone: true}">
        Use Customer's Formulary</mat-checkbox>
    </div>
    <div style="display:inline-block; width:70%; text-align:left">
        <input text id="txtLookupFilter" #txtFilter
               class="qs_filter-field"
               (keyup)="applyFilter($event.target.value)" placeholder="<Filter>">
        <button mat-button matSuffix mat-icon-button
                matTooltip="Clear the filter" aria-label="Clear"
                (click)="txtFilter.value=''; clearFilter();">
            <mat-icon>close</mat-icon>
        </button>

    </div>
   <div class="dwform-row-gap"></div>

    <!-- TABLE OF OPTIONS -->
    <div #tb1 class="table-container mat-elevation-z2" [tabIndex]="-1"
         style="height:560px; overflow:auto"
         (keydown)="tableKeyDown($event)" tabindex="-1">

        <table mat-table [dataSource]="dataSource" matSort tabindex="-1"
               class="lookup-table-style"
               (matSortChange)="onSortChange($event)">

            <!-- ID (hidden) -->
            <ng-container matColumnDef="id" hidden="true">
                <th mat-header-cell *matHeaderCellDef mat-sort-header hidden="true"
                    class="mat-table-header-style" style="width:0px">Id
                </th>
                <td mat-cell style="font-size: 9pt;" hidden="true"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.id}}</td>
            </ng-container>

            <!-- ITEM NUMBER -->
            <ng-container matColumnDef="itemPrefix">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:100px;">Item Prefix
                </th>
                <td mat-cell style="font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.itemPrefix}}</td>
            </ng-container>

            <!-- PACKAGE SIZE -->
            <ng-container matColumnDef="packageSize">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:50px;">Size
                </th>
                <td mat-cell style="font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.packageSize}}</td>
            </ng-container>

            <!-- DESCRIPTION -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style" style="width:280px;">Description
                </th>
                <td mat-cell style="font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.description}}</td>
            </ng-container>

            <!-- In Stock -->
            <ng-container matColumnDef="inStock">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style " style="width:100px">In Stock
                </th>
                <td mat-cell style="  font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{formatDecimalToWhole(element.inStock)}}</td>
            </ng-container>

            <!-- on Order -->
            <ng-container matColumnDef="onOrder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style"
                    style="width:100px;">On Order
                </th>
                <td mat-cell style=" font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{element.onOrder}}</td>
            </ng-container>

            <!-- on Order -->
            <ng-container matColumnDef="awp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="mat-table-header-style"
                    style="width:80px;">AWP
                </th>
                <td mat-cell style="font-size: 9pt;"
                    *matCellDef="let element;let tableRowIdx = index;">
                    {{formatCurrency(element.awp)}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns; let tableRowIdx = index;"
                [ngClass]="{'row-selected': tableRowIdx == Idx}"
                (click)="onRowClicked(row)"
                (dblclick)="onRowDoubleClicked(row)">
            </tr>
        </table>
    </div>

    <!-- SAVE / CONTROL STRIP -->
    <div class="save-close-strip" style="bottom: 10px;">

        <button mat-button class="command-button" [disabled]="selectedMedication === null"
                (click)="onSubmitClicked()" matTooltip="Submit / save this entry"
                mat-button>
            <mat-icon>check_circle_outline</mat-icon>
            Submit
        </button>
        <button mat-button class="command-button"
                (click)="onCancelClicked()" matTooltip="Cancel without saving this entry" mat-button>
            <mat-icon>highlight_off</mat-icon>
            Cancel
        </button>
    </div>
</div>
