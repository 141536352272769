import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-cell',
  templateUrl: './report-cell.component.html',
  styleUrls: ['./report-cell.component.css']
})
export class ReportCellComponent implements OnInit {

  _CellData: object;
  public cellClass: string;
  constructor() { }

  ngOnInit() {

  }

  get CellData(): object {
    return this._CellData;
  }

  @Input()
  set CellData(value: object) {
    this._CellData = value;
    if(value['label'] == null || value['label'] === undefined) {
      this.cellClass = 'ui-g-1';
    } else if(value['label'].length > 10) {
      this.cellClass = 'ui-g-2';
    } else if(value['label'].length > 20) {
      this.cellClass = 'ui-g-3';
    } else {
      this.cellClass = 'ui-g-1';
    }
  }

}
