import {OnInit, Component, ViewChild, Output, EventEmitter} from '@angular/core';
import { Settings } from 'src/app/services/settings';
import {NewOrdersComponent} from './neworders/neworders.component';
import {PreparationComponent} from './preparation/preparation.component';
import {InProcessComponent} from './inprocess/inprocess.component';
import {QualityControlComponent} from './qualitycontrol/qualitycontrol.component';
import {ReleaseComponent} from './release/release.component';
import {ShippingComponent} from './shipping/shipping.component';
import {OrdersService} from '../../services/orders.service';
import {FormingService} from '../../services/forming.service';

@Component({
  selector: 'app-forming',
  templateUrl: './forming.component.html',
  styleUrls: ['./forming.component.css']
})
export class FormingComponent implements OnInit  {

  public visibleCustomerMaster = false;
  public visibleNewOrders = false;
  public visiblePreparation = false;
  public visibleInProcess = false;
  public visibleQC = false;
  public visibleRelease = false;
  public visibleShipping = false;
  public visibleCompletedOrders = false;

  public visibleCustomerMasterLink = false;
  public visibleNewOrdersLink = false;
  public visiblePreparationLink = false;
  public visibleInProcessLink = false;
  public visibleQCLink = false;
  public visibleReleaseLink = false;
  public visibleShippingLink = false;
  public visibleCompletedOrdersLink = false;

  @ViewChild('newOrders', {static: true}) newOrders: NewOrdersComponent;
  @ViewChild('prepare', {static: true}) prepare: PreparationComponent;
  @ViewChild('inProcess', {static: true}) inProcess: InProcessComponent;
  @ViewChild('quality', {static: true}) quality: QualityControlComponent;
  @ViewChild('release', {static: true}) release: ReleaseComponent;
  @ViewChild('shipping', {static: true}) shipping: ShippingComponent;

  public newOrdersCount;
  public preparationCount;
  public inProcessCount;
  public qualityCount;
  public releaseCount;
  public shippingCount;

  constructor(public ordersService: OrdersService, public formingService: FormingService) {

  }
  ngOnInit() {

      const locSettings = Settings.getInstance();

      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_NEW_ORDERS'))) {
        this.visibleNewOrdersLink = true;
      }

      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_PREPARATION'))) {
        this.visiblePreparationLink = true;
      }

      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_INPROCESS'))) {
        this.visibleInProcessLink = true;
      }
      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_QUALITY'))) {
        this.visibleQCLink = true;
      }
      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_RELEASE'))) {
        this.visibleReleaseLink = true;
      }
      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_SHIPPING'))) {
        this.visibleShippingLink = true;
      }

      if ((locSettings.hasPrivilege('NAV_FORMING')) && (locSettings.hasPrivilege('MANAGE_COMPLETED_ORDERS'))) {
        this.visibleCompletedOrdersLink = true;
      }

      if (Settings.getInstance().hasPrivilege('MANAGE_CUSTOMERS')) {
        this.visibleCustomerMasterLink = true;
      }

      this.fetchOrderCounts();
  }
  public showNewOrders() {
    this.hideAllChildComponents();
    this.addClassToItem('ordersItem');
    this.visibleNewOrders = true;
  }
  public showPreparation() {
    this.hideAllChildComponents();
    this.addClassToItem('prepareItem');
    this.visiblePreparation = true;
  }
  public showInProcess() {
    this.hideAllChildComponents();
    this.addClassToItem('inProcessItem');
    this.visibleInProcess = true;
  }
  public showQC() {
    this.hideAllChildComponents();
    this.addClassToItem('qcItem');
    this.visibleQC = true;
  }
  public showRelease() {
    this.hideAllChildComponents();
    this.addClassToItem('releaseItem');
    this.visibleRelease = true;
  }
  public showShipping() {
    this.hideAllChildComponents();
    this.addClassToItem('shipItem');
    this.visibleShipping = true;
  }
  public showCompletedOrders() {
    this.hideAllChildComponents();
    this.addClassToItem('completedItem');
    this.visibleCompletedOrders = true;
  }
  public showCustomers() {
    this.hideAllChildComponents();
    this.addClassToItem('customerItem');
    this.visibleCustomerMaster = true;
  }
  public hideAllChildComponents() {
    this.visibleCustomerMaster = false;
    this.visibleNewOrders = false;
    this.visiblePreparation = false;
    this.visibleInProcess = false;
    this.visibleQC = false;
    this.visibleRelease = false;
    this.visibleShipping = false;
    this.visibleCompletedOrders = false;

    if (this.visibleNewOrdersLink) {
      this.removeClassFromItems('ordersItem');
    }
    if (this.visiblePreparationLink) {
      this.removeClassFromItems('prepareItem');
    }
    if (this.visibleInProcessLink) {
      this.removeClassFromItems('inProcessItem');
    }
    if (this.visibleQCLink) {
      this.removeClassFromItems('qcItem');
    }
    if (this.visibleReleaseLink) {
      this.removeClassFromItems('releaseItem');
    }
    if (this.visibleShippingLink) {
      this.removeClassFromItems('shipItem');
    }
    if (this.visibleCompletedOrdersLink) {
      this.removeClassFromItems('completedItem');
    }
    if (this.visibleCustomerMasterLink) {
      this.removeClassFromItems('customerItem');
    }
  }
  public removeClassFromItems(itemId: string): void {
    if (document.getElementById(itemId).classList.contains('selectedMenuItem') === true)
      document.getElementById(itemId).classList.remove('selectedMenuItem');

  }
  public addClassToItem(itemId: string): void {
    document.getElementById(itemId).classList.add('selectedMenuItem');
  }
  public zeroCounts(): void {
    this.preparationCount = 0;
    this.newOrdersCount = 0;
    this.inProcessCount = 0;
    this.qualityCount = 0;
    this.releaseCount = 0;
    this.shippingCount = 0;
  }
  public fetchOrderCounts(): void {

    this.zeroCounts();

    this.ordersService.getAllOrders().subscribe(
        (result) => {
          this.newOrdersCount = result.filter(x => x.activeStatus === 'Active').length;
          this.fetchBatchCountsByStep();
        },
        (error) => {
            /***  do nothing - no feedback ***/
        });
  }
  public updateNewOrdersCount(newCount: any): void {
    this.newOrdersCount = newCount;
    this.fetchOrderCounts();
  }

  public fetchBatchCountsByStep(): void   {

      this.formingService.getBprStepSummary().subscribe(
          (results) => {
              for (const item of results)  {
                switch (item.currentStep) {
                  case 1:
                    this.preparationCount = item.stepCount;
                    break;
                  case 2:
                    this.inProcessCount = item.stepCount;
                    break;
                  case 3:
                    this.qualityCount = item.stepCount;
                    break;
                  case 4:
                    this.releaseCount = item.stepCount;
                    break;
                  case 5:
                    this.shippingCount = item.stepCount;
                    break;
                }
              }
          },
          (error) => {
            /*** do nothing - no feedback ***/
          });
  }
}
