import { Component, OnInit, Input, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { Link } from 'src/app/models/link';
import { MedicationService } from 'src/app/services/medication.service';
import { Settings } from 'src/app/services/settings';

@Component({
	selector: 'app-medication-links',
	templateUrl: './medication-links.component.html',
	styleUrls: ['./medication-links.component.css']
})
export class MedicationLinksComponent {

	medicationLinks: Link[];
	displayedColumns: string[];

	isEditing = false;
	_medication: any;
	Idx = -1;
	selectedLink: Link;
	pagination: string;
	displayAddEditLink = false;
	selectedRecord: Link;
	renderedData = Array<Link>();
	dataSource: MatTableDataSource<Link>;

	BASE_URL = Settings.getInstance().BASE_URL;
	isAdd = false;
	isEdit = true;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('table', { static: false }) table;

	get Medication(): any {
		return this._medication;
	}
	@Input() set Medication(value: any) {
		this._medication = value;
		this.queryRecords();
	}

	constructor(private medicationService: MedicationService, private dialog: MatDialog) {
		//this.displayedColumns = ['linkDescription', 'linkUrl', 'delete'];
		this.displayedColumns = ['delete'];
	}

	ngOnInit() {

	}

	queryRecords() {
		if (this._medication === undefined || this._medication.id === undefined) {
			return;
		}
		this.medicationService.getMedicationLinksByMedicationId(this._medication.id).subscribe(
			(result) => {
				if (result === undefined || result.length === undefined) {
					return;
				}
				this.medicationLinks = result;
				this.dataSource = new MatTableDataSource(this.medicationLinks);
				this.dataSource.sort = this.sort;
				this.setPagination(-1, this.dataSource.data.length);
			},
			(error) => {
			}
		);
	}
	onSortChange(event) {
		if (event.direction === 'asc') {
			this.medicationLinks.sort((a, b) =>
				a[event.active].localeCompare(b[event.active]));
		}
		else {
			this.medicationLinks.sort((a, b) =>
				b[event.active].localeCompare(a[event.active]));
		}

		this.dataSource.data = this.medicationLinks;
		this.dataSource = new MatTableDataSource(this.medicationLinks);
		this.dataSource.sort = this.sort;
		this.setPagination(-1, this.dataSource.data.length);
		this.selectRow();
	}
	tableKeyDown(event: KeyboardEvent) {

		const len: number = this.dataSource.data.length;
		if (event.key === 'ArrowDown') {
			if (this.Idx < (len - 1)) {
				this.selectedRecord = this.dataSource.data[++this.Idx];
			}
		} else if (event.key === 'ArrowUp') {
			if (this.Idx > 0) {
				this.selectedRecord = this.dataSource.data[--this.Idx];
			}
		}

	}
	selectRow() {
		if (this.selectedRecord == undefined || this.selectedRecord.id === 0) {
			this.unSelectRow();
			return;
		}
		this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);
		console.log('ID: ' + this.selectedRecord.id + ' - AT: ' + this.Idx);
	}
	unSelectRow() {
		/* if (this.isEditing) return; */
		this.Idx = -1;
		this.selectedRecord = undefined;
		this.setPagination(this.Idx, this.dataSource.data.length);
	}

	setPagination(idx: number, total: number) {
		this.pagination = (idx + 1) + '/' + total;
	}

	onChangeLinkType(event) {

	}

	addLink() {
		/*if (this.medicationLinks.length !== 0 && this.medicationLinks[this.medicationLinks.length - 1].id === 0) {
			return;
		}
		let newRec = new Link();
		newRec.id = 0;
		newRec.active = 1;
		newRec.linkDescription = '';
		newRec.linkUrl = '';
		newRec.medicationId = this._medication.id
		this.medicationLinks.push(newRec);
		this.table.renderRows();
		*/
		this.selectedRecord = new Link();
		this.selectedRecord.id = 0;
		this.selectedRecord.active = 1;
		this.selectedRecord.linkDescription = '';
		this.selectedRecord.linkUrl = '';
		this.selectedRecord.medicationId = this._medication.id;
		this.displayAddEditLink = true;
	}

	deleteLink(medLink: Link) {
		const message = 'Remove this Link - Are you sure?';
		const dialogData = new ConfirmDialogModel('Please Confirm', message);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: dialogData,
			panelClass: 'custom-dialog-container'
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			const result = dialogResult;
			if (result === true) {

				this.medicationService.deleteMedicationLink(medLink.id).subscribe(
					(result) => {
						this.queryRecords();
					},
					(error) => {
						Settings.getInstance().handleError(error);
					}
				);
			}
		});
	}
	editClickHandler() {
		this.isEditing = true;
	}
	saveClickHandler() {
		this.isEditing = false;
	}
	cancelClickHandler() {
		this.isEditing = false;
		//this.updateDisplay();
	}
	showDialogAddEditLink() {
		this.displayAddEditLink = true;
	}
	cancelClickedFromAddeditLink() {
		this.displayAddEditLink = false;
	}
	submitClickedFromAddeditLink() {
		this.displayAddEditLink = false;
		this.queryRecords();
	}
	onRowClicked(selected: any) {
		this.selectedRecord = selected;
		this.renderedData = this.dataSource.connect().value;
		this.Idx = this.renderedData.indexOf(this.selectedRecord);
	}
}
