import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NotesHelper {

  public caretPos = 0;
  public editBanner = '<span><i>(edited)</i></span>';

  setFocus(elementId: string): void {
    try {
      setTimeout(() => {
        document.getElementById(elementId).focus();
        document.getElementById(elementId).scrollIntoView();
      });
    }
    catch (error) {  }
  }

  getCaretPosition(node: any) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      let caretPosition;
      const tmp = document.createElement('div');

      preCaretRange.selectNodeContents(node);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      tmp.appendChild(preCaretRange.cloneContents());
      caretPosition = tmp.innerHTML.length;
      this.caretPos = caretPosition;
  }

  setCaretPosition(tag: any, addLen: number) {
    // Creates range object
    const setPos = document.createRange();
    // Creates object for selection
    const setSelection = window.getSelection();
    // Set start position of range
    setPos.setStart(tag.childNodes[0], this.caretPos + addLen);
    // Collapse range within its boundary points
    // Returns boolean
    setPos.collapse(true);
    // Remove all ranges set
    setSelection.removeAllRanges();
    // Add range with respect to range object.
    setSelection.addRange(setPos);
    // Set cursor on focus
    tag.focus();
  }

}
