/************
 * List Tab Detail with right hand properties drawer
 * File Name ...: inventories-inv.components.ts
 * Generated By : ianday on 4/3/23
 * Date ........: 4/3/23
 /************/

import {Component, OnInit, ViewChild, HostListener, AfterContentInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Settings} from '../../../services/settings';
import {Inventories} from '../../../models/Inventories';
import {BulkUnitsService} from '../../../services/bulkunits.service';
import {
    ConfirmDialogComponent,
    ConfirmDialogModel
} from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TransactionHistoryService} from '../../../services/transactionhistory.service';
import {TransactionHistoryComponent} from '../../../components/transaction-history/transaction-history';
import {SitesService} from '../../../services/sites.service';
import {Catalogues} from '../../../models/catalogues';
import {Sites} from '../../../models/sites';
import {BulkUnitsComponent} from './bulkunits/bulkunits.component';
import {BulkCheckInComponent} from './bulkcheckin/bulkcheckin.component';
import {AlertDialogComponent, AlertDialogModel} from '../../../components/shared/alert-dialog/alert-dialog.component';
import {DwUtils} from '../../../components/utils/dw-utils';
import {PurchaseOrdersComponent} from '../purchaseorders/purchaseorders.component';

/* Note: Remove this class when ready to plumb in services */
@Component({
    selector: 'app-inventories-inv',
    templateUrl: './inventories-inv.component.html',
    styleUrls: ['./inventories-inv.component.css']
})
export class InventoriesComponent implements OnInit, AfterContentInit {

    /*** Header / Query Strip Variables ****/
    public formTitle = 'Inventories';
    public pagination: string;

    /*** Editing variables ***/
    public isEditing: boolean | null = false;
    public isAdd: boolean | null = false;
    public records: Inventories[];
    public selectedRecord: Inventories;
    public historyRecord: Inventories;
    public Idx: number | null = -1;
    public strErr: string | null = '';
    public dataSource: MatTableDataSource<Inventories>;

    public sites: Sites[];
    public siteIdx: number | null = -1;
    public catalogues: Catalogues[];
    public catIdx: number | null = -1;
    public invTypes = [];
    public invTypeIdx: number | null = -1;

    public renderedData: Array<any>;
    public result: boolean | null = false;
    public disabledFlag: boolean | null = true;

    /**** Query strip filter settings ***/
    public selectedItemTypeFilter: string | null = 'Active';
    public selectedViewFilter: string | null = 'List';
    public contentClassWithBanner: string | null = 'dwtable-container';
    public contentClassWithoutBanner: string | null = 'dwtable-container-without-header';
    public contentClassName: string | null = 'dwtable-container';

    /*** Record Table / card table  ***/
    public displayedColumns: string[] = ['siteHandle', 'catalogueHandle', 'itemPrefix', 'itemHandle', 'itemType', 'onHand', 'onOrder', 'reserved'];
    public selectedCatalogue: Catalogues;
    public selectedSite: Sites;
    public selectedInvType: any;

    /***** Control Strip Button Visibility ***/
    public visibleAdd: boolean | null = false;
    public visibleModify: boolean | null = false;
    public visibleDelete: boolean | null = false;

    /***** Tab Detail Section *****/
    public selectedTabIndex: number | null = 0;
    public id: number | null = 0;
    public description: string | null;

    /* display dialogs */
    public displayImportDialog = false;
    public displayPurchaseOrdersDialog = false;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('history', {static: true}) history: TransactionHistoryComponent;
    @ViewChild('bulkunits', {static: true}) bulkunits: BulkUnitsComponent;
    @ViewChild('bulkcheckin', {static: true}) bulkcheckin: BulkCheckInComponent;
    @ViewChild('purchaseorders', {static: true}) purchaseorders: PurchaseOrdersComponent;

    constructor(private bulkUnitsService: BulkUnitsService,
                private siteService: SitesService,
                private txService: TransactionHistoryService,
                public dialog: MatDialog) {
        this.selectedRecord = new Inventories();
    }

    ngOnInit() {
        this.visibleAdd = true;
        this.visibleModify = true;
        this.visibleDelete = true;

        this.selectedItemTypeFilter = 'All';
        this.selectedCatalogue = new Catalogues();
        this.selectedSite = new Sites();

        this.invTypes = new Array();
        this.invTypes.push({id: 'All', description: 'All Types'});
        this.invTypes.push({id: 'medications', description: 'Medications'});
        this.invTypes.push({id: 'materials', description: 'Materials'});
        this.selectedInvType = this.invTypes[0];
        this.invTypeIdx = 0;
    }
    ngAfterContentInit() {

        if (Settings.getInstance().enableMessageBanner) {
            this.contentClassName = this.contentClassWithBanner;
        } else {
            this.contentClassName = this.contentClassWithoutBanner;
        }
        this.querySiteRecords();

    }
    decimalFmtValue(inp: string): string {
        return DwUtils.decimalFmtValue(inp);
    }
    querySiteRecords(): void {

        this.sites = new Array<Sites>();
        this.siteService.getAllSites().subscribe(
            (result)  => {
                this.sites.push(...result.filter(s => s.activeStatus === 'Active'));
                this.sites.push(new Sites('All Sites'));
                if (this.sites.length > 0) {
                    this.selectedSite = this.sites[0];
                    this.queryCatalogueRecords();
                }
                else {
                    this.strErr = 'Warning: There are no active sites';
                }
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve sites data from the service');
            });
        }
    queryCatalogueRecords(): void {
        this.catalogues = new Array<Catalogues>();

        this.siteService.getAllCataloguesBySiteId(this.selectedSite.id).subscribe(
            (result) => {
                this.catalogues.push(...result.filter(x => x.activeStatus === 'Active'));
                this.catalogues.push(new Catalogues('All Catalogues'));

                if (this.catalogues.length > 0) {
                    this.selectedCatalogue = this.catalogues[0];
                    this.queryInventoryRecords();
                }
                else {
                        this.strErr = 'Warning: There is no default catalogue found for this site';
                }
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve catalogues data from the service');
            });
    }

    /*** Query Records from the Service ***/
    queryInventoryRecords() {

        this.bulkUnitsService.getAllInventoryItemsFiltered(
                this.selectedSite.id,
                this.selectedCatalogue.id,
                this.selectedInvType.id).subscribe(
            (result) => {
                this.records = result;
                this.dataSource = new MatTableDataSource(this.records);
                this.sort = new MatSort();
                this.dataSource.sort = this.sort;
                this.setPagination(-1, this.dataSource.data.length);
            },
            (error) => {
                Settings.getInstance().handleError('Error: unable to retrieve data from the service');
            }
        );
    }
    /*******************************************/
    /* Change events for the drop down filters */
    /*******************************************/
    public onChangeSitesValue(event: any): void {

        if (event.value !== undefined) {
            this.selectedSite = event.value;
        }
        this.unSelectRow();
        this.queryCatalogueRecords();
    }
    public onSiteDropDownKeyDown(event: any): void {
        const len: number = this.sites.length;
        if (event.key === 'ArrowDown') {
            if (this.siteIdx < (len - 1)) {
                this.selectedSite = this.sites[++this.siteIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.siteIdx > 0) {
                this.selectedSite = this.sites[--this.siteIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedSite = this.sites[this.siteIdx];
            this.onChangeCatalogueValue(this.selectedSite);
        }
    }
    public onChangeCatalogueValue(event: any): void {

        if (event.value !== undefined) {
            this.selectedCatalogue = event.value;
        }
        this.unSelectRow();
        this.queryInventoryRecords();
    }
    public onCatalogueDropDownKeyDown(event: any): void {
        const len: number = this.catalogues.length;
        if (event.key === 'ArrowDown') {
            if (this.catIdx < (len - 1)) {
                this.selectedCatalogue = this.catalogues[++this.catIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.catIdx > 0) {
                this.selectedCatalogue = this.catalogues[--this.catIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedCatalogue = this.catalogues[this.catIdx];
            this.onChangeCatalogueValue(this.selectedCatalogue);
        }
    }
    public onChangeInvTypeValue(event: any): void {

        if (event.value !== undefined) {
            this.selectedInvType = event.value;
        }
        this.unSelectRow();
        this.queryInventoryRecords();
    }
    public onInvTypesDropDownKeyDown(event: any): void {
        const len: number = this.invTypes.length;
        if (event.key === 'ArrowDown') {
            if (this.invTypeIdx < (len - 1)) {
                this.selectedInvType = this.invTypes[++this.invTypeIdx];
            }
        }
        else if (event.key === 'ArrowUp') {
            if (this.invTypeIdx > 0) {
                this.selectedInvType = this.invTypes[--this.invTypeIdx];
            }
        }
        else if (event.key === 'Enter') {
            this.selectedInvType = this.invTypes[this.invTypeIdx];
            this.onChangeCatalogueValue(this.selectedInvType);
        }
    }
    /*** Display the number of records from the total ***/
    public setPagination(idx: number, total: number) {
        this.pagination = (idx + 1) + '/' + total;
    }
    public onChangeStatusFilterValue() {
        const dataTemp: Inventories[] = [];
        this.clearFilter();

        for (const rec of this.records) {
           // if (rec.activeStatus === this.selectedStatusFilter || this.selectedStatusFilter === 'All') {
           //     dataTemp.push(rec);
           // }
        }
        this.dataSource = new MatTableDataSource(dataTemp);
        this.setPagination(-1, this.dataSource.filteredData.length);
    }
    onStatusChange() {

        /* this.bulkUnitsService.updateBulkUnits(this.selectedRecord).subscribe(
            (data) => {
                // no user feedback, just apply the filter ...
                this.onChangeStatusFilterValue();
            });

         */
    }
    public doTabChange(event): void {

    }
    @HostListener('matSortChange', ['$event'])
    onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.records.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.records.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource = new MatTableDataSource(this.records);
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public clearFilter() {
        if (this.isEditing) {
            return;
        }
        this.unSelectRow();
        this.dataSource.filter = '';
        this.selectRow();
        this.setPagination(-1, this.dataSource.data.length);
    }
    public applyFilter(filterValue: string) {
        if (this.isEditing) {
            return;
        }
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.unSelectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public selectRow() {
        if (this.selectedRecord.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedRecord.id);

    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedRecord = new Inventories();
        this.setPagination(this.Idx, this.dataSource.data.length);
        this.clearDetail();

    }
    public clearDetail(): void {
        this.bulkunits.clearBulkUnitDetail();
    }
    public displayDetail(record: Inventories) {
        if (record === undefined) {
            return;
        }
        this.bulkunits.displayBulkUnits(this.selectedRecord.foreignKeyTable,
            this.selectedRecord.foreignKeyId,
            this.selectedRecord.catalogueId,
            this.selectedRecord.itemPrefix,  this.selectedRecord.itemHandle);

    }
    public tableKeyDown(event: KeyboardEvent) {
        if (this.isEditing) {
            return;
        }

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedRecord = this.dataSource.filteredData[++this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedRecord = this.dataSource.filteredData[--this.Idx];
                this.displayDetail(this.selectedRecord);
            }
        }
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
    }
    public onRowClicked(selected: any) {

        if (this.isEditing === true)
            return;

        this.selectedRecord = selected;
        this.selectRow();
        this.setPagination(this.Idx, this.dataSource.filteredData.length);
        this.displayDetail(this.selectedRecord);
    }
    public showError(message: string): void {
        const dialogData = new AlertDialogModel('Error', message);
        const dialogRef = this.dialog.open(AlertDialogComponent, {
           width: '400px',
           data: dialogData,
           panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const result = dialogResult;
            if (result === true) {
               DwUtils.setFocus('cbCatalogue');
            }
        });
    }
    public cancelSubmitRecord() {
        this.strErr = '';
        this.isEditing = false;
        this.displayDetail(this.selectedRecord);
    }
    /*** Bulk Unit methods ***/
    public importBulkMedication(event): void {

        /*** check the catalogues value, it cannot be zero (All Catalogues) ***/
        if (this.selectedCatalogue.id === 0) {
            this.showError('Please select a specific catalogue from the filter bar first');
            return;
        }
        this.displayImportDialog = true;
        this.bulkcheckin.showDialog();
    }
    public submitBulkCheckInEvent(): void {
        this.queryInventoryRecords();
        this.displayImportDialog = false;
    }
    /*** Bulk Orders Methods ***/
    public managePurchaseOrders(): void {
        this.displayPurchaseOrdersDialog = true;
        this.purchaseorders.showDialog();
    }
}
