<!--
    file type .....: HTML file: bulkunitgraphics.component.html
    Created By ....: ianday
    Date ..........: 4/17/23 
-->
<div class="bulkunitgraphics-container" >

	<div class="bar-chart-div">
		<p-chart #usageChart; type="bar" height="383px" width="99%"
		         [data]="barDataSource"
		         [options]="stackedOptions">
		</p-chart>
		<div class="percent_left_style">{{remainingPercentLeft}}%</div>
	</div>
	<div>
		<div class="container_status">
			<span class="text_span">Container Status:&nbsp;</span>
			<img src="{{fetchContainerStatusIcon(selectedRecord?.containerStatus)}}"
			     	style="height: 33px!important; width: 82px; padding-bottom:10px;
					vertical-align: top;" />
		</div>
		<div style="height: calc(100% - 40px);">
			<div class="image-container" style="flex: 1">
				<img src="{{selectedRecord?.containerUrl}}" alt='' style="height: 100%">
			</div>
			<div class="image-container">
			    <img src="{{selectedRecord?.tabletUrl}}" alt='' style="height: 100%">
			</div>
		</div>
	</div>
</div>

