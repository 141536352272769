/**
 *  Filename ....: acceptorderitems.component.ts
 *  Created by ..: ianday
 *  on Date .....: 7/7/23
 **/
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {OrderItems} from '../../../../models/orderitems';
import {OrdersService} from '../../../../services/orders.service';
import {SitesService} from '../../../../services/sites.service';
import {Settings} from '../../../../services/settings';
import {Sites} from '../../../../models/sites';
import {Orders} from '../../../../models/orders';
import {FormingService} from '../../../../services/forming.service';
import {Bpr} from '../../../../models/bpr';
import {TransactionHistoryService} from '../../../../services/transactionhistory.service';

export class ItemsBPR {
    public id: number;
    public select: boolean;
    public quantity: number;
    public itemPrefix: string;
    public description: string;
    public itemStatus: string;
    public siteId: number;

    public setItemsBPR(rec: OrderItems, fillSiteId: number): void {
        this.id = rec.id;
        this.select = false;
        this.quantity = rec.quantity;
        this.itemPrefix = rec.itemPrefix;
        this.description = rec.description;
        this.itemStatus = rec.itemStatus;
        this.siteId = fillSiteId;
    }
}

@Component({
    selector: 'app-acceptorderitems',
    templateUrl: './acceptorderitems.component.html',
    styleUrls: ['./acceptorderitems.component.css']
})
export class AcceptOrderItemsComponent implements OnInit {

    public Idx: number | null = -1;
    public records: any;
    public dataSource: MatTableDataSource<any>;
    public _selectedOrderItems: OrderItems[];
    public _selectedOrder: Orders;
    public selectedSite: Sites;
    public defaultFillSiteId: number;
    public itemsBPR: ItemsBPR[];
    public sitesList: Sites[];
    public iterator: number;
    public selectedOrderItem: any;
    public displayedColumns = ['select', 'quantity', 'itemPrefix', 'description', 'itemStatus', 'sites'];

    @Output() cancelClicked = new EventEmitter();
    @Output() submitClicked = new EventEmitter<number>();

    @Input() set selectedOrderItems(val: Array<OrderItems>) {
        this._selectedOrderItems = val;
    }

    get selectedOrderItems(): Array<OrderItems> {
        return this._selectedOrderItems;
    }

    @Input() set selectedOrder(val: Orders) {
        this._selectedOrder = val;
    }

    get selectedOrder(): Orders {
        return this._selectedOrder;
    }
    constructor(public ordersService: OrdersService,
                public txService: TransactionHistoryService,
                public formingService: FormingService, public sitesService: SitesService) {

    }

    ngOnInit() {

    }
    public querySites(): void {
        this.sitesService.getAllSites().subscribe(
            (data) => {
                /*** just get the active sites ***/
                this.sitesList = new Array<Sites>();
                this.sitesList = data.filter(x => x.activeStatus === 'Active');
                this.setUpItemsArray();
            },
            (error) => {
                Settings.getInstance().handleError(error,'Selecting sites in acceptOrderItems()');
            });
    }
    public doInitialize(fillSiteId: number): void {

        this.defaultFillSiteId = fillSiteId;

        if (this.sitesList === undefined) {
            this.querySites();

        } else {
            this.setUpItemsArray();
        }
    }
    public setUpItemsArray(): void {

        this.itemsBPR = new Array<ItemsBPR>();

        for (const oi of this.selectedOrderItems) {
            const iBPR = new ItemsBPR();
            iBPR.setItemsBPR(oi, this.defaultFillSiteId);
            this.itemsBPR.push(iBPR);
        }
        this.dataSource = new MatTableDataSource(this.itemsBPR);
    }
    public toggleAllSelected(event): void {

        if (this.dataSource === undefined) {
            return;
        }

        for (const item of this.dataSource.filteredData) {
            item.select = event.checked;
        }
    }
    @HostListener('matSortChange', ['$event'])
    public onSortChange(event: any) {

        if (event.direction === 'asc') {
            this.selectedOrderItems.sort((a, b) =>
                a[event.active].localeCompare(b[event.active]));
        } else {
            this.selectedOrderItems.sort((a, b) =>
                b[event.active].localeCompare(a[event.active]));
        }

        this.dataSource.data = this.selectedOrderItems;
        this.selectRow();
    }
    public selectRow() {
        if (this.selectedOrderItem.id === 0) {
            this.unSelectRow();
            return;
        }
        this.Idx = this.dataSource.filteredData.findIndex(s => s.id === this.selectedOrderItem.id);
    }
    public returnSiteHandle(rec: any): string {
        const retHandle = this.sitesList.find(x => x.id === rec.siteId).handle;
        return retHandle;
    }
    public unSelectRow() {

        this.Idx = -1;
        this.selectedOrderItem = new OrderItems();
    }
    public onRowClicked(selected: any): void {
        this.selectedOrderItem = selected;
        this.selectRow();
    }
    public onSiteChange(event: any): void {

    }
    public tableKeyDown(event: KeyboardEvent) {

        const len: number = this.dataSource.filteredData.length;
        if (event.key === 'ArrowDown') {
            if (this.Idx < (len - 1)) {
                this.selectedOrderItem = this.dataSource.filteredData[++this.Idx];
            }
        } else if (event.key === 'ArrowUp') {
            if (this.Idx > 0) {
                this.selectedOrderItem = this.dataSource.filteredData[--this.Idx];
            }
        }
    }
    public onSubmitClicked(): void {

        /*** So what we need to do here is to update the
         * item status record to 'Processing',then create a BPR Record
         * for that order item.
         * Finally we will then need to update the gateway (if the order came from there)
         * (and / or send an email back to the customer?).
         ***/

        this.iterator = 0;
        this.updateRecords();

    }
    public updateRecords(): void {

        if (this.iterator < this.itemsBPR.length) {
            if (this.itemsBPR[this.iterator].select === true) {
                this.createBPRRecord(this.itemsBPR[this.iterator++]);
                return;
            }
            else {
                /*** do a recursive fetch ***/
                this.iterator++;
                this.updateRecords();
            }
        }
        else {
            this.submitClicked.emit();
        }
    }
    public createBPRRecord(rec: ItemsBPR) {

        const oi = this._selectedOrderItems.find(x => x.id === rec.id);
        const bpr = new Bpr();
        bpr.setBprFromOrderItem(oi, this.selectedOrder, rec.siteId);

        this.formingService.createBpr(bpr).subscribe (
            (result) => {
                oi.itemStatus = 'In-Process';
                this.txService.createTransactionHistory('orders', this.selectedOrder.id, 'Create',
                    'Adding BPR record for item: ' + oi.itemPrefix);
                this.updateOrderItemStatus(oi);
            } ,
            (error) => {
                Settings.getInstance().handleError(error, 'creating Bpr record');
            }
        );
    }
    public updateOrderItemStatus(oi: OrderItems) {

        this.ordersService.updateOrderItem(oi). subscribe(
            (data) => {
                this.updateRecords();
            },
            (error) => {
                Settings.getInstance().handleError(error, 'updating order item status');
            });
    }
    public onCancelClicked(): void {
        this.cancelClicked.emit();
    }
}


