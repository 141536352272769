<mat-drawer-container class="mat-elevation-z2 inventory-container">
	<mat-drawer disableClose="true" mode="side" opened style="width:165px;">
		<mat-action-list >
			<span>
			  <button  id="invItem" *ngIf="visibleInventoriesLink" matTooltipPosition="right"
					   matTooltip="Manage Catalogues" class="button-menu" mat-list-item
					   (click)="showInventories()"  > Inventories </button></span>
			<hr width="90%">

			<span>
			  <button id="familyItem" *ngIf="visibleMedicationFamilyLink" matTooltipPosition="right"
			  matTooltip="Manage medication family" class="button-menu"
					  mat-list-item
					  (click)="showMedicationFamily()"  > Medication Family </button></span>
			  
			  <span>
			  <button id="medsItem" *ngIf="visibleMedicationsLink" matTooltipPosition="right"
			  matTooltip="Manage medications inventory" class="button-menu" mat-list-item (click)="showMedications()"  > Medications </button></span>

			<span>
				<button id="materialsItem" *ngIf="visibleMaterialsLink" matTooltipPosition="right"
				 matTooltip="Manage materials" class="button-menu" mat-list-item (click)="showMaterials()" >
			  Materials </button></span>
		  <hr width="90%">

			<span>
				<button id="suppliersItem" *ngIf="visibleSuppliersLink" matTooltipPosition="right"
				 matTooltip="Manage Suppliers" class="button-menu" mat-list-item (click)="showSuppliers()" >
			  Suppliers </button></span>

			<span>
				<button id="sitesItem" *ngIf="visibleSitesLink" matTooltipPosition="right"
						matTooltip="Manage Sites and Stations"
				  class="button-menu" mat-list-item (click)="showSites()" > Sites and Stations </button></span>

		</mat-action-list>
	</mat-drawer>

	<mat-drawer-content class="mat-drawer-content-container">
		<app-inventories-inv *ngIf="visibleInventories"></app-inventories-inv>
		<app-medications-inv *ngIf="visibleMedications"> </app-medications-inv>
		<app-materials-inv *ngIf="visibleMaterials"> </app-materials-inv>
		<app-suppliers-inv *ngIf="visibleSuppliers"> </app-suppliers-inv>
		<app-sites-inv *ngIf="visibleSites"></app-sites-inv>
		<app-locations-inv *ngIf="visibleSites"> </app-locations-inv>
		<app-medication-family *ngIf="visibleMedicationFamily"> </app-medication-family>
	</mat-drawer-content>
</mat-drawer-container>
